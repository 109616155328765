<script>
  import {Input, FormGroup, Label, Button} from "sveltestrap";
  import {_} from "svelte-i18n";
  import SelectDropdown from "../../../../Components/SelectDropdown.svelte";
  import TextAreaAutosize from "../../../../Components/TextAreaAutosize.svelte";

  export let values;
  export let channelStatusIsCloud;

  let variableRegexp = /\{\{\d+\}\}/gm;
  const buttonsTypes = [
    {max: 3, code: 'QUICK_REPLY'},
    {max: 2, code: 'URL'},
    {max: 1, code: 'PHONE_NUMBER'},
  ];
  let buttonType;
  let typeTemplate = 'normal';

  function selectHeader(type) {
    switch (type) {
      case "none":
        values.components = values.components.filter(item => item.type !== "HEADER");
        break;
      case "text":
        values.components = values.components.filter(item => item.type !== "HEADER");
        values.components = [{type: "HEADER", format: "TEXT", text: ""}, ...values.components];
        break;
      case "image":
        values.components = values.components.filter(item => item.type !== "HEADER");
        values.components = [{type: "HEADER", format: "IMAGE", example: {header_handle: [""]}}, ...values.components];
        break;
      case "document":
        values.components = values.components.filter(item => item.type !== "HEADER");
        values.components = [{type: "HEADER", format: "DOCUMENT", example: {header_handle: [""]}}, ...values.components];
        break;
      case "video":
        values.components = values.components.filter(item => item.type !== "HEADER");
        values.components = [{type: "HEADER", format: "VIDEO", example: {header_handle: [""]}}, ...values.components];
        break;
    }
  }

  function addFooter() {
    values.components = [...values.components, {type: "FOOTER", text: ""}];
  }

  function deleteFooter(i) {
    values.components = values.components.filter((item, index) => index !== i);
  }

  function addVal(field = 'text', index, j) {
    if (field !== 'button') {
      let variables = values.components[index][field].match(variableRegexp);
      if (variables && variables.length) {
        values.components[index][field] += `{{${variables.length + 1}}}`
      } else {
        values.components[index][field] += `{{1}}`
      }
    } else {
      let variables = values.components[index].buttons[j].url.match(variableRegexp);
      if (variables && variables.length) {
        values.components[index].buttons[j].url += `{{${variables.length + 1}}}`
      } else {
        values.components[index].buttons[j].url += `{{1}}`
      }
    }
  }

  function addExample(index, field) {
    let data;
    if (field === 'header_text') {
      data = values.components[index]?.example?.[field];
      if (data) {
        data = [...data, ""];
      } else {
        data = [""];
      }
    } else {
      data = values.components[index]?.example?.[field][0];
      if (data) {
        data = [[...data, ""]];
      } else {
        data = [[""]];
      }
    }

    values.components[index].example = {
      [field]: data
    }
  }

  function addExampleBtn(index, j) {
    let data = values.components[index].buttons[j].example;

    if (data) {
      data = [...data, ""];
    } else {
      data = [""];
    }

    values.components[index].buttons[j].example = data;
  }

  function deleteExample(index, j, field) {
    if (field === 'header_text') {
      if (values.components[index].example[field].length === 1) {
        let data = {...values.components[index]};
        delete data.example;
        values.components[index] = data;
      } else{
        values.components[index].example[field] = values.components[index].example[field].filter((item, index) => index !== j);
      }
    } else {
      if (values.components[index].example[field][0].length === 1) {
        let data = {...values.components[index]};
        delete data.example;
        values.components[index] = data;
      } else{
        values.components[index].example[field][0] = values.components[index].example[field][0].filter((item, index) => index !== j);
      }
    }
  }

  function deleteExampleBtn(index, i, j) {
    if (values.components[index].buttons[i].example.length === 1) {
      let data = {...values.components[index].buttons[i]};
      delete data.example;
      values.components[index].buttons[i] = data;
    } else{
      values.components[index].buttons[i].example = values.components[index].buttons[i].example.filter((item, index) => index !== j);
    }
  }

  function addButtons(type) {
    buttonType = type.code;
    let index = values.components.findIndex(item => item.type === "BUTTONS");
    if (index === -1) {
      let buttons = [];
      if (buttonType === "QUICK_REPLY") {
        buttons.push({type: buttonType, text: ""})
      }
      if (buttonType === "URL") {
        buttons.push({type: buttonType, text: "", url: ""})
      }
      if (buttonType === "PHONE_NUMBER") {
        buttons.push({type: buttonType, text: "", phone_number: ""})
      }
      if (buttonType === "CATALOG") {
        buttons.push({type: buttonType, text: "View catalog"})
      }
      values.components = [...values.components, {type: "BUTTONS", buttons}];
    } else {
      if (buttonType === "QUICK_REPLY") {
        values.components[index].buttons = [...values.components[index].buttons, {type: buttonType, text: ""}];
      }
      if (buttonType === "URL") {
        values.components[index].buttons = [...values.components[index].buttons, {type: buttonType, text: "", url: ""}];
      }
      if (buttonType === "PHONE_NUMBER") {
        values.components[index].buttons = [...values.components[index].buttons, {type: buttonType, text: "", phone_number: ""}];
      }
      if (buttonType === "CATALOG") {
        values.components[index].buttons = [...values.components[index].buttons, {type: buttonType, text: "View catalog"}];
      }
    }
    if (values.components[values.components.findIndex(item => item.type === "BUTTONS")].buttons.length === type.max) buttonType = 'max';
  }

  function deleteButtons(index, j) {
    if (values.components[index].buttons.length === 1) {
      values.components = values.components.filter(item => item.type !== "BUTTONS");
    } else {
      values.components[index].buttons = values.components[index].buttons.filter((item, index) => index !== j);
    }
    buttonType =  values.components[index]?.buttons.length > 0 ?  values.components[index].buttons[0].type : false;
  }

  function selectTemplate(type) {
    typeTemplate = type;
    switch (type) {
      case 'normal':
        values.components = [
          {
            text: "",
            type: "BODY"
          },
        ];
      break;
      case 'carousel':
        values.components = [
          {
            text: "",
            type: "BODY"
          },
          {
            type: "CAROUSEL", cards: [
              {
                components: [
                  {
                    type: "HEADER",
                    format: "IMAGE",
                    example: {header_handle: [""]}
                  },
                  {
                    type: "BODY",
                    text: ""
                  },
                  {
                    type: "BUTTONS",
                    buttons: [
                      {
                        type: "QUICK_REPLY",
                        text: ""
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ];
      break;
    }
  }

  function selectCarouselHeader(type) {
    let carouselIndex = values.components.findIndex(item => item.type === "CAROUSEL");
    switch (type) {
      case "image":
        for (let i = 0; i < values.components[carouselIndex].cards.length; i++) {
          values.components[carouselIndex].cards[i].components[0].format = "IMAGE";
        }
        break;
      case "video":
        for (let i = 0; i < values.components[carouselIndex].cards.length; i++) {
          values.components[carouselIndex].cards[i].components[0].format = "VIDEO";
        }
        break;
    }
  }

  function addCarouselVal(field = 'text', cardIndex, j) {
    let carouselIndex = values.components.findIndex(item => item.type === "CAROUSEL");
    if (field !== 'button') {
      let variables = values.components[carouselIndex].cards[cardIndex].components[1][field].match(variableRegexp);
      if (variables && variables.length) {
        values.components[carouselIndex].cards[cardIndex].components[1][field] += `{{${variables.length + 1}}}`;
      } else {
        values.components[carouselIndex].cards[cardIndex].components[1][field] += `{{1}}`;
      }
    } else {
      let variables = values.components[carouselIndex].cards[cardIndex].components[2].buttons[j].url.match(variableRegexp);
      if (variables && variables.length) {
        values.components[carouselIndex].cards[cardIndex].components[2].buttons[j].url += `{{${variables.length + 1}}}`;
      } else {
        values.components[carouselIndex].cards[cardIndex].components[2].buttons[j].url += `{{1}}`;
      }
    }
  }

  function addCarouselExample(cardIndex, field) {
    let carouselIndex = values.components.findIndex(item => item.type === "CAROUSEL");
    let data = values.components[carouselIndex].cards[cardIndex].components[1]?.example?.[field][0];
    if (data) {
      data = [[...data, ""]];
    } else {
      data = [[""]];
    }

    values.components[carouselIndex].cards[cardIndex].components[1].example = {
      [field]: data
    }
  }

  function deleteCarouselExample(cardIndex, j, field) {
    let carouselIndex = values.components.findIndex(item => item.type === "CAROUSEL");
    if (values.components[carouselIndex].cards[cardIndex].components[1].example[field][0].length === 1) {
      let data = {...values.components[carouselIndex].cards[cardIndex].components[1]};
      delete data.example;
      values.components[carouselIndex].cards[cardIndex].components[1] = data;
    } else{
      values.components[carouselIndex].cards[cardIndex].components[1].example[field][0] = values.components[carouselIndex].cards[cardIndex].components[1].example[field][0].filter((item, index) => index !== j);
    }
  }

  function reverseCarouselButton(type, indexBtn) {
    let carouselIndex = values.components.findIndex(item => item.type === "CAROUSEL");
    for (let i = 0; i < values.components[carouselIndex].cards.length; i++) {
      let btn = values.components[carouselIndex].cards[i].components[2].buttons[indexBtn];
      if (type.code === "QUICK_REPLY") {
        btn = {type: type.code, text: ""};
      }
      if (type.code === "URL") {
        btn = {type: type.code, text: "", url: ""};
      }
      if (type.code === "PHONE_NUMBER") {
        btn = {type: type.code, text: "", phone_number: ""};
      }
      values.components[carouselIndex].cards[i].components[2].buttons[indexBtn] = btn;
    }
  }

  function addCarouselButtons() {
    let carouselIndex = values.components.findIndex(item => item.type === "CAROUSEL");
    for (let i = 0; i < values.components[carouselIndex].cards.length; i++) {
      values.components[carouselIndex].cards[i].components[2].buttons = [...values.components[carouselIndex].cards[i].components[2].buttons, {type: "QUICK_REPLY", text: ""}];
    }
  }

  function deleteCarouselButton(i) {
    let carouselIndex = values.components.findIndex(item => item.type === "CAROUSEL");
    for (let j = 0; j < values.components[carouselIndex].cards.length; j++) {
      values.components[carouselIndex].cards[j].components[2].buttons = values.components[carouselIndex].cards[j].components[2].buttons.filter((item, index) => index !== i);
    }
  }

  function addCarouselExampleBtn(cardIndex, j) {
    let carouselIndex = values.components.findIndex(item => item.type === "CAROUSEL");
    let data = values.components[carouselIndex].cards[cardIndex].components[2].buttons[j].example;
    if (data) {
      data = [...data, ""];
    } else {
      data = [""];
    }

    values.components[carouselIndex].cards[cardIndex].components[2].buttons[j].example = data;
  }

  function deleteCarouselExampleBtn(cardIndex, i, j) {
    let carouselIndex = values.components.findIndex(item => item.type === "CAROUSEL");
    if (values.components[carouselIndex].cards[cardIndex].components[2].buttons[i].example.length === 1) {
      let data = {...values.components[carouselIndex].cards[cardIndex].components[2].buttons[i]};
      delete data.example;
      values.components[carouselIndex].cards[cardIndex].components[2].buttons[i] = data;
    } else{
      values.components[carouselIndex].cards[cardIndex].components[2].buttons[i].example = values.components[carouselIndex].cards[cardIndex].components[2].buttons[i].example.filter((item, index) => index !== j);
    }
  }

  function addCarouselCard() {
    let carouselIndex = values.components.findIndex(item => item.type === "CAROUSEL");

    let BUTTONS = [];
    values.components[carouselIndex].cards[0].components[2].buttons.forEach(btn => {
      if (btn.type === "QUICK_REPLY") {
        BUTTONS.push({type: btn.type, text: ""});
      }
      if (btn.type === "URL") {
        BUTTONS.push({type: btn.type, text: "", url: ""});
      }
      if (btn.type === "PHONE_NUMBER") {
        BUTTONS.push({type: btn.type, text: "", phone_number: ""});
      }
    });

    values.components[carouselIndex].cards = [...values.components[carouselIndex].cards, {
      components: [
        {
          type: "HEADER",
          format: values.components[carouselIndex].cards[0].components[0].format,
          example: {header_handle: [""]}
        },
        {
          type: "BODY",
          text: ""
        },
        {
          type: "BUTTONS",
          buttons: BUTTONS
        }
      ]
    }];
  }

  function deleteCarouselCard(i) {
    let carouselIndex = values.components.findIndex(item => item.type === "CAROUSEL");
    values.components[carouselIndex].cards = values.components[carouselIndex].cards.filter((item, index) => index !== i);
  }
</script>

{#if channelStatusIsCloud && (values.category === 'MARKETING' || values.category === 'UTILITY')}
<FormGroup class="d-flex gap-1 flex-wrap">
    <Button type="button" color="success" on:click={() => selectTemplate('normal')} active={typeTemplate === 'normal'}>
        {$_("waba.template.select_type.normal")}
    </Button>
    <Button  type="button" color="success" on:click={() => selectTemplate('carousel')} active={typeTemplate === 'carousel'}>
        {$_("waba.template.select_type.carousel")}
    </Button>
</FormGroup>
{/if}
{#if typeTemplate === 'normal'}
  <FormGroup>
    <Label class="fw-600">HEADER</Label>
    <SelectDropdown type={true} selected="none" change={selectHeader} list={["none", "text", "image", "document", "video"]}/>
  </FormGroup>
  {#each values.components as component, index}
    {#if component.type === 'HEADER'}
      <FormGroup>
        {#if component.format === 'TEXT'}
          <div class="rounded-2 bg-light">
            <TextAreaAutosize classes="border-0 bg-transparent" bind:value={component.text} minRows={4}/>
          </div>
          <button type="button" class="btn btn-success btn-sm mt-2" on:click={() => addVal("text", index)}>+ {$_('waba.template.add_variable')}</button>
          <button type="button" class="btn btn-success btn-sm mt-2" on:click={() => addExample(index, 'header_text')}>+ {$_('waba.template.add_example')}</button>
          {#if component.example}
            <div class="mt-3">
              {#each component.example.header_text as text, j}
                <FormGroup class="d-flex gap-3">
                  <Input bind:value={text} type="text" placeholder={'{{' + (j + 1) + '}}'}/>
                  <button type="button" class="btn addBtn rounded-circle" on:click={() => deleteExample(index, j, 'header_text')}>-</button>
                </FormGroup>
              {/each}
            </div>
          {/if}
        {/if}
        {#if component.format === 'IMAGE' || component.format === 'DOCUMENT' || component.format === 'VIDEO'}
          <Input bind:value={component.example.header_handle[0]} type="url" required/>
        {/if}
      </FormGroup>
    {/if}
    {#if component.type === 'BODY'}
      <FormGroup>
        <Label class="fw-600">BODY</Label>
        <div class="rounded-2 bg-light">
          <TextAreaAutosize classes="border-0 bg-transparent" bind:value={component.text} minRows={4}/>
        </div>
        <button type="button" class="btn btn-success btn-sm mt-2" on:click={() => addVal("text", index)}>+ {$_('waba.template.add_variable')}</button>
        <button type="button" class="btn btn-success btn-sm mt-2" on:click={() => addExample(index, 'body_text')}>+ {$_('waba.template.add_example')}</button>
        {#if component.example}
          <div class="mt-3">
            {#each component.example.body_text[0] as text, j}
              <FormGroup class="d-flex gap-3">
                <Input bind:value={text} type="text" placeholder={'{{' + (j + 1) + '}}'}/>
                <button type="button" class="btn addBtn rounded-circle" on:click={() => deleteExample(index, j, 'body_text')}>-</button>
              </FormGroup>
            {/each}
          </div>
        {/if}
      </FormGroup>
    {/if}
    {#if component.type === 'FOOTER'}
      <div class="d-flex">
        <FormGroup class="w-100 me-3">
          <Label class="fw-600">FOOTER</Label>
          <div class="rounded-2 bg-light">
            <TextAreaAutosize classes="border-0 bg-transparent" bind:value={component.text} minRows={4}/>
          </div>
        </FormGroup>
        <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteFooter(index)}>-</button>
      </div>
    {/if}
  {/each}
  {#if !values.components.find(item => item.type === "FOOTER")}
    <Label class="fw-600">FOOTER</Label>
    <FormGroup>
      <button type="button" class="btn addBtn rounded-circle" on:click={addFooter}>+</button>
    </FormGroup>
  {/if}
  <Label class="fw-600">BUTTONS</Label>
  {#each values.components as component, index}
    {#if component.type === 'BUTTONS'}
      {#each component.buttons as button, i}
        <FormGroup class="d-flex">
          <div class="me-3 w-100">
            <Input bind:value={button.text} type="text" class="mb-3" disabled={button.type === 'CATALOG'}/>
            {#if 'url' in button}
              <Input bind:value={button.url} type="url" />
              <button type="button" class="btn btn-success btn-sm mt-2" on:click={() => addVal("button", index, i)}>+ {$_('waba.template.add_variable')}</button>
              <button type="button" class="btn btn-success btn-sm mt-2" on:click={() => addExampleBtn(index, i)}>+ {$_('waba.template.add_example')}</button>
              {#if button.example}
                <div class="mt-3">
                  {#each button.example as text, j}
                    <FormGroup class="d-flex gap-3">
                      <Input bind:value={text} type="text" placeholder={'{{' + (j + 1) + '}}'}/>
                      <button type="button" class="btn addBtn rounded-circle" on:click={() => deleteExampleBtn(index, i, j)}>-</button>
                    </FormGroup>
                  {/each}
                </div>
              {/if}
            {/if}
            {#if 'phone_number' in button}
              <Input bind:value={button.phone_number} type="tel"/>
            {/if}
          </div>
          <button type="button" class="btn addBtn rounded-circle" on:click={() => deleteButtons(index, i)}>-</button>
        </FormGroup>
      {/each}
    {/if}
  {/each}
  <div class="d-flex gap-2 flex-wrap">
    {#each buttonsTypes as type}
      <button type="button" class="btn btn-success" on:click={() => addButtons(type)} disabled={buttonType && buttonType !== type.code}>
        + {$_('add')} {$_('waba.template.buttons_types.' + type.code.toLowerCase())}
      </button>
    {/each}
    {#if values.category === 'MARKETING' && channelStatusIsCloud}
      <button type="button" class="btn btn-success" on:click={() => addButtons({max: 1, code: 'CATALOG'})} disabled={buttonType && buttonType !== 'CATALOG'}>
        + {$_('add')} {$_('waba.template.buttons_types.' + 'CATALOG'.toLowerCase())}
      </button>
    {/if}
  </div>
{/if}
{#if typeTemplate === 'carousel'}
  {#each values.components as component, index}
    {#if component.type === 'BODY'}
      <FormGroup>
        <Label class="fw-600">BODY</Label>
        <div class="rounded-2 bg-light">
          <TextAreaAutosize classes="border-0 bg-transparent" bind:value={component.text} minRows={4}/>
        </div>
        <button type="button" class="btn btn-success btn-sm mt-2" on:click={() => addVal("text", index)}>+ {$_('waba.template.add_variable')}</button>
        <button type="button" class="btn btn-success btn-sm mt-2" on:click={() => addExample(index, 'body_text')}>+ {$_('waba.template.add_example')}</button>
        {#if component.example}
          <div class="mt-3">
            {#each component.example.body_text[0] as text, j}
              <FormGroup class="d-flex gap-3">
                <Input bind:value={text} type="text" placeholder={'{{' + (j + 1) + '}}'}/>
                <button type="button" class="btn addBtn rounded-circle" on:click={() => deleteExample(index, j, 'body_text')}>-</button>
              </FormGroup>
            {/each}
          </div>
        {/if}
      </FormGroup>
    {/if}
    {#if component.type === 'CAROUSEL'}
      <FormGroup>
        <Label class="fw-600">CAROUSEL</Label>
        <div class="mb-3">
          <Label class="fw-600">header</Label>
          <SelectDropdown type={true} selected="image" change={(type) => selectCarouselHeader(type)} list={["image", "video"]}/>
        </div>
        <Label class="fw-600">buttons</Label>
        {#each component.cards[0].components[2].buttons as btn, i}
          <div class="mb-2">
            <div class="d-flex gap-1 flex-wrap">
              {#each buttonsTypes as type}
                <Button type="button" color="success" class="d-inline-flex align-items-center gap-1"
                  on:click={() => {if(type.code !== btn.type) reverseCarouselButton(type, i)}} active={type.code === btn.type}>
                  {$_('waba.template.buttons_types.' + type.code.toLowerCase())}
                </Button>
              {/each}
              {#if i !== 0}
                <Button type="button" color="danger"class="d-inline-flex" on:click={() => deleteCarouselButton(i)}>
                    <i class="bx bx-trash font-size-16"/>
                </Button>
              {/if}
            </div>
          </div>
        {/each}
        {#if component.cards[0].components[2].buttons.length < 2}
          <Button type="button" color="success" outline class="d-inline-flex align-items-center gap-1" on:click={addCarouselButtons}>
            <i class="bx bx-plus font-size-16 d-none d-sm-inline-block"/> {$_('waba.template.select_type.add_btn')}
          </Button>
        {/if}
      </FormGroup>
      <div class="mb-3 mt-3 line bg-success"/>
      {#each component.cards as card, cardIndex}
        <Label class="fw-600 d-flex gap-2">
          <span>CARD {cardIndex+1}</span>
          {#if cardIndex !== 0}
            <button type="button" class="border-0 p-0 bg-transparent d-flex" on:click={() => deleteCarouselCard(cardIndex)}>
              <i class="bx bx-trash font-size-18 text-danger"/>
            </button>
          {/if}
        </Label>
        {#each card.components as component}
          {#if component.type === "HEADER"}
            <FormGroup>
              <Label class="fw-600">header</Label>
              <Input bind:value={component.example.header_handle[0]} type="url" required/>
            </FormGroup>
          {/if}
          {#if component.type === "BODY"}
            <FormGroup>
              <Label class="fw-600">body</Label>
              <div class="rounded-2 bg-light">
                <TextAreaAutosize classes="border-0 bg-transparent" bind:value={component.text} minRows={4}/>
              </div>
              <button type="button" class="btn btn-success btn-sm mt-2" on:click={() => addCarouselVal("text", cardIndex)}>+ {$_('waba.template.add_variable')}</button>
              <button type="button" class="btn btn-success btn-sm mt-2" on:click={() => addCarouselExample(cardIndex, 'body_text')}>+ {$_('waba.template.add_example')}</button>
              {#if component.example}
                <div class="mt-3">
                  {#each component.example.body_text[0] as text, j}
                    <FormGroup class="d-flex gap-3">
                      <Input bind:value={text} type="text" placeholder={'{{' + (j + 1) + '}}'}/>
                      <button type="button" class="btn addBtn rounded-circle" on:click={() => deleteCarouselExample(cardIndex, j, 'body_text')}>-</button>
                    </FormGroup>
                  {/each}
                </div>
              {/if}
            </FormGroup>
          {/if}
          {#if component.type === 'BUTTONS'}
            {#each component.buttons as btn, i}
              <Label class="fw-600">button</Label>
              <FormGroup>
                <Input bind:value={btn.text} type="text" class="mb-3"/>
                {#if 'url' in btn}
                  <Input bind:value={btn.url} type="url" />
                  <button type="button" class="btn btn-success btn-sm mt-2" on:click={() => addCarouselVal("button", cardIndex, i)}>+ {$_('waba.template.add_variable')}</button>
                  <button type="button" class="btn btn-success btn-sm mt-2" on:click={() => addCarouselExampleBtn(cardIndex, i)}>+ {$_('waba.template.add_example')}</button>
                  {#if btn.example}
                    <div class="mt-3">
                      {#each btn.example as text, j}
                        <FormGroup class="d-flex gap-3">
                          <Input bind:value={text} type="text" placeholder={'{{' + (j + 1) + '}}'}/>
                          <button type="button" class="btn addBtn rounded-circle" on:click={() => deleteCarouselExampleBtn(cardIndex, i, j)}>-</button>
                        </FormGroup>
                      {/each}
                    </div>
                  {/if}
                {/if}
                {#if 'phone_number' in btn}
                  <Input bind:value={btn.phone_number} type="tel"/>
                {/if}
              </FormGroup>
            {/each}
          {/if}
        {/each}
        <div class="mb-3 mt-3 line bg-success"/>
      {/each}
      {#if component.cards.length < 10}
        <FormGroup>
          <button type="button" class="btn addBtn rounded-circle" on:click={addCarouselCard}>+</button>
        </FormGroup>
      {/if}
    {/if}
  {/each}
{/if}

<style>
  .deleteBtn, .addBtn {
    color: #495057 !important;
    width: 40px;
    height: 40px;
    background-color: #EFF2F7;
    box-shadow: none;
  }

  .deleteBtn {
    line-height: 16px;
    font-size: 22px;
    margin-top: 28px;
  }

  .addBtn {
    line-height: 20px;
    font-size: 20px;
  }

  .line {
    height: 2px;
  }
</style>