<script>
  import {Modal, ModalBody} from "sveltestrap";
  import {Navigation} from 'swiper';
  import {Swiper} from 'swiper/svelte';
  import 'swiper/css';
  import SpinnerComponent from "../Spinners/SpinnerComponent.svelte";

  export let show;
  export let title;
  export let breakpoints;
  export let centered = true;
  export let onCloseClick;
  export let contentLoader = true;

  let navigation ={
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  };
</script>

<Modal isOpen={show} toggle={onCloseClick} centered={centered} size='xl' class="slider-modal">
  <ModalBody>
    <div class="text-center fw-bold slider-modal-title">{title}</div>
    {#if !contentLoader}
      <div class="d-flex align-items-center justify-content-center">
        <SpinnerComponent/>
      </div>
    {:else}
      <Swiper slidesPerView={1} spaceBetween={20} {navigation} modules={[Navigation]} {breakpoints}>
        <slot></slot>
        <div slot="container-end" class="slider-modal-button">
          <button class="swiper-button-prev">
            <i class="mdi mdi-chevron-left"/>
          </button>
          <button class="swiper-button-next">
            <i class="mdi mdi-chevron-right"/>
          </button>
        </div>
      </Swiper>
      <slot name="footer"></slot>
    {/if}
  </ModalBody>
</Modal>
