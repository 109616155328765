<script>
  import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardHeader,
    CardBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalHeader,
    ModalBody
  } from "sveltestrap";
  import {Link, navigate} from "svelte-routing";
  import ReplenishBalanceModal from "../../Components/Modals/ReplenishBalanceModal.svelte";

  export let paymentProfile = {};
  export let projects = [];

  let profileInModal = {};

  let showBalanceModal = false;
</script>

{#if showBalanceModal}
  <ReplenishBalanceModal
      profile={profileInModal}
      show={showBalanceModal}
      onCloseClick={() =>{showBalanceModal=false}}
  />
{/if}


<Card class="payment-card">
  <CardHeader>
    <div>
      <small>Баланс #{paymentProfile.number || paymentProfile.id}</small>
      {#if paymentProfile.type === 'balance'}
        <h4>Название баланса</h4>
      {/if}
      {#if paymentProfile.type === 'subscription'}
        <h4>Название подписки или карты</h4>
      {/if}
    </div>
    <i class="bx bx-cog"></i>
  </CardHeader>
  <CardBody>
    {#if paymentProfile.type === 'balance'}
      <div class="d-flex align-items-center justify-content-between my-4">
        <p class="price">{paymentProfile.balance || 0} <span>{paymentProfile.currency || ''}</span></p>
        <Button outline color="success" on:click={()=>{profileInModal = paymentProfile; showBalanceModal = true;}}>
          Пополнить
        </Button>
      </div>
    {/if}
    {#if paymentProfile.type === 'subscription'}
      <div class="subscribe-card-info my-4">
        <div class="input-with-currency">
          <p class="currency">{paymentProfile.currency || ''}</p>
          <div class="input-card-style">
            <span class="dots">.... .... ....</span>
            <span class="numbers">{paymentProfile.cardNumber || '****'}</span>
            <!-- Если visa (т.е. если есть картинка) -->
            <img src={`assets/images/icons/logo-visa.png`} alt="logo">
          </div>
        </div>
        <div class="current-logo"><img src={`/assets/images/icons/${paymentProfile.paymentSystem||'stripe'}-logo.png`}
                                       alt="logo"></div>
      </div>
    {/if}
    <div class="att-to-projects">
      <div class="title">Прикреплена к проектам:</div>
      <!-- 1 прикрепленный проект -->
      {#if projects}
        {#each projects as project}
          <div class="project">
            <div class="project-content">
              <div>
                <p class="name">{project.name || '-'}</p>
                <p class="status text-success">Активный</p>
              </div>
              <!--            <div class="date">до 20.08.22</div>-->
            </div>
            <Dropdown group>
              <DropdownToggle tag="div"><i class="bx bx-dots-vertical-rounded"></i>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem>Изменить</DropdownItem>
                <DropdownItem>Удалить</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        {/each}
      {/if}
    </div>
  </CardBody>
</Card>
