<script>
  import {_, locale} from "svelte-i18n";
  import {alerts, alertWarning} from "../helpers/store";
  import {onDestroy} from "svelte";
  import {addToast} from "../helpers/toast";

  export let subject;
  export let place;

  let lang;
  let alert = false;

  const unsub = alerts.subscribe(value => {
    if(value && value[subject]) {
      alert = value[subject]
    }else{
      alert = false;
    }
  });

  const unsub2 = locale.subscribe(value => {
    if (value) lang = value;
  });

  onDestroy(() => {
    unsub2();
    unsub();
  });

  function isLangPresent(str, lang) {
    if (str.includes('all')) {
        return true;
    }
    const langPattern = new RegExp(`\\b${lang}\\b`, 'i');
    return langPattern.test(str);
}

  $:if (alert) {
    if (alert.isVisible && !$alertWarning) {
      if (isLangPresent(alert.lang, lang)) {
        addToast({
          type: "warning",
          title: alert[place].title[lang],
          message: alert[place].text[lang],
          timeout: false
        });
        alertWarning.set(true);
      }
    }
  }
</script>

<!-- {#if alert?.[place]}
  <div class:d-none={!isLangPresent(alert.lang, lang)} role="alert" class="alert alert-warning">
    <h4 class="alert-heading alert-warning font-size-26">{@html alert[place].title[lang]}</h4>
    <p>{@html alert[place].text[lang]}</p>
  </div>
{/if} -->
