<div class="ListItemSkeleton d-flex pointer list-item mb-1">
  <div class="me-3 list-item-left skeleton-wave">
    <img alt="avatar" class="avatar-sm rounded-circle object-fit-cover"/>
  </div>
  <div class="d-flex align-items-center list-item-right ">
    <div class="flex-grow-1 overflow-hidden">
      <h5 class="w-50 text-truncate text-dark fw-600 font-size-16 mb-1 skeleton-wave">
        asd
      </h5>
      <div class="w-75 text-truncate text-muted font-size-14 mb-0 skeleton-wave">
        asd
      </div>
    </div>
    <div class="h-100 w-10 text-end">
      <div class="font-size-11 mt-1 mb-1 skeleton-wave">
        asd
      </div>
    </div>
  </div>
</div>

<style>
  .ListItemSkeleton.list-item:hover {
    background-color: #fff;
  }

  .ListItemSkeleton .list-item-left {
    padding-left: 9px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .ListItemSkeleton .list-item-right {
    width: calc(100% - 73px);
    padding-right: 9px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #DBDBDD;
  }

  .ListItemSkeleton .skeleton-wave {
    position: relative;
    overflow: hidden;
    background-color: #f1f1f1; /* Skeleton background color */
    z-index: 2; /* Ensures the skeleton is positioned above other elements */
  }

  .ListItemSkeleton .skeleton-wave::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.5), transparent); /* Wave gradient colors */
    animation: wave-animation 1.5s infinite linear;
    z-index: 3; /* Ensures the wave animation is positioned above the underlying content */
  }

  /* Add the following CSS to hide the underlying content */
  .ListItemSkeleton .skeleton-wave::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f1f1f1; /* Skeleton background color */
    z-index: 1; /* Ensures the overlay is positioned above other elements */
  }

  @keyframes wave-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(180%);
    }
  }
</style>
