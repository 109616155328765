<script>
  import {
    Container, Row, Col,
    Card,
    CardBody,
    Button,
    Input, Label,
  } from "sveltestrap";
  import {defaultConst} from "../../common/constants";
  import {user} from "../../helpers/store";
  import {postRequest} from "../../helpers/utils";
  import {addToast} from "../../helpers/toast";
  import {_} from "svelte-i18n";


  let emailOrUid = "tempmoms@yandex.ru"
  let authToken = "";

  async function getAuthLink() {
    let params = {
      userToken: $user.userToken,
    };
    const key = emailOrUid.indexOf("@") !== -1 ? 'email' : 'user_id'
    params[key] = emailOrUid
    postRequest(
      `${defaultConst.functionsUrl}/getTokenForAuthInLK`,
      params
    ).then(async ({result, error}) => {
      if (result?.token) {
        authToken = result.token
      } else if (error) {
        await addToast({title: $_(error)})
      } else {
        await addToast({title: $_('shit happens')})
      }
    }, async (error) => {
      await addToast({title: $_(error.toString())})
    });
  }


</script>
{#if $user.admin}
  <div class="page-content support">
    <Container fluid>
      <Col class="mx-auto" xl="6" xxl="4">
        <Card class="card-support">
          <CardBody>
            <form class="form-horizontal" on:submit|preventDefault={getAuthLink}>
              <div class="mb-3">
                <Label class="form-label" for="email">
                  Email или идшник юзера
                </Label>
                <Input bind:value={emailOrUid}
                       class="form-control mb-3"
                       id="email" type="text"></Input>
                <Button class="btn-eye"
                        on:click={getAuthLink} type="button">Дай ссылку авторизации
                </Button>
                {#if authToken}
                  <a href={`https://my.1msg.io/login?customToken=${authToken}`}>Открой меня в инкогнито</a>
                {/if}
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Container>
  </div>
{/if}
