<script>
  import {Link} from "svelte-routing";
  import {onMount, afterUpdate, onDestroy} from "svelte";
  import moment from "moment";
  import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Row,
    Button,
    Form,
    Input,
    Label,
    InputGroup,
    Modal,
    ModalHeader,
    ModalBody,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
  } from "sveltestrap";
  import {_, date} from "svelte-i18n";
  import {addToast} from "../../helpers/toast";
  import {defaultConst} from "../../common/constants";
  import {user} from "../../helpers/store";
  import {postRequest, uploadProjectAvatar} from "../../helpers/utils";
  import Spinner from "../../Components/Spinners/SpinnerComponent.svelte";
  import ProjectDeleteModal from "../../Components/Modals/ProjectDeleteModal.svelte";

  export let project;
  let inputActive = {};
  let activeUser = {};
  let files;
  let showModal = false;

  let savingName = false;

  const unsub = user.subscribe((value) => {
    activeUser = value;
  });

  onDestroy(() => {
    unsub();
  })
  const startEdit = (name) => {

    inputActive = {
      name: name,
      value: project[name] || "",
    };
  };

  const cancelEdit = () => {
    inputActive = {};
  };

  const changeProp = async () => {
    savingName = true;
    const params = {
      projectId: project.id,
      userToken: activeUser.userToken,
      property: inputActive.name,
    };
    if (inputActive.value) params.value = inputActive.value;

    let result;
    try {
      result = await postRequest(
        `${defaultConst.functionsUrl}/updateProject`,
        params
      );
    } catch (e) {
    }
    savingName = false;
    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong")
      });
    } else {
      addToast({title: $_("saved")});
      cancelEdit();
    }
  };

  const deleteProject = () => {
    showModal=true;
  }


  const changePic = async () => {

    if (files && files[0]) {
      if (files[0].size < 2 * 1_048_576) {
        let result = await uploadProjectAvatar(project.id, files[0]);
        if (!result || result.error) {
          addToast({
            title: $_("error"),
            message: result.error || $_("something_went_wrong"),
            dismissible: true,
            timeout: 4_000,
          });
        } else {
          addToast({title: $_("saved")});
        }
      } else {
        addToast({
          title: $_("error"),
          message: $_("errors.max_size"),
          dismissible: true,
          timeout: 4_000,
        });
      }
    }
  };
  const init = () => {
    if (project) {

      if (project.creationTS)
        project.created = moment(parseInt(project.creationTS)).format('DD.MM.YYYY');

      if (!project.description) project.description = false;
    }
  }

  afterUpdate(() => {
    init();
  })
  onMount(() => {
    init();
  });

  $: if (files) changePic();
  // $: inputActive;

  let OpenSettings = false;
  const OpenSettingsToggle = () => (OpenSettings = !OpenSettings);
</script>

{#if project}
  <Card class="card-project">
    <CardBody>
     <!-- <Dropdown group class="project-action">
        <DropdownToggle tag="div"><i class="bx bx-cog"></i>
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem on:click={deleteProject}>{$_('delete')}</DropdownItem>
        </DropdownMenu>
      </Dropdown>-->
      <div class="d-block d-md-flex align-items-start">
        <div class="avatar-md me-4">
            <span class="avatar-title rounded-circle bg-light text-danger font-size-16">
                <label style="margin: 0">
                  <input type="file" class="d-none" bind:files accept=".jpg, .jpeg, .png"/>
                  {#if project.pic}
                    <img src={project.pic} alt="" class="avatar-sm me-4"/>
                  {:else}
                    <img src="/assets/images/projects/{project.creationTS % 15}.svg" alt="" class="avatar-sm" style="width: 100%; height: 100%"/>
                  {/if}
                </label>
            </span>
        </div>

        <div class="flex-grow-1 overflow-hidden mt-3">
          <p class="subtitle">{$_('project.text')}</p>


          {#if inputActive.name === "name"}
            <form
                class="row row-cols-lg-auto g-3 align-items-center"
                on:submit|preventDefault={changeProp}
            >
              <div class="col-12 col-gt-sm-6">
                <InputGroup>
                  <Input
                      bind:value={inputActive.value}
                      type="text"
                      class="form-control"
                      placeholder={$_("project.name_placeholder")}
                  />
                </InputGroup>
              </div>

              <div class="col-6 col-gt-sm-6">
                {#if savingName}
                  <Spinner/>
                {:else }
                  <Button type="submit" color="success" class="btn-sm">
                    <i class="mdi mdi-check"/>
                  </Button>
                  <Button
                      type="reset"
                      color="danger"
                      class="btn-sm"
                      on:click={cancelEdit}
                  >
                    <i class="mdi mdi-close"/>
                  </Button>
                {/if}
              </div>
            </form>
          {:else}
            <button type="button" class="border-0 p-0 bg-transparent text-start d-block" on:click={() => startEdit("name")}>
              <h5 class="title">{project.name}</h5>
            </button>
          {/if}
          <div class="subtitle font-size-12 mt-1">{$_('created_at')}: {project.created}</div>
          <!--
                    {#if inputActive.name === "description"}
                      <form
                        class="row row-cols-lg-auto g-3 align-items-center"
                        on:submit|preventDefault={changeProp}
                      >
                        <div class="col-12 col-gt-sm-6">
                          <InputGroup>
                            <Input
                              bind:value={inputActive.value}
                              type="text"
                              class="form-control"
                              placeholder={$_("project.description_placeholder")}
                            />
                          </InputGroup>
                        </div>

                        <div class="col-6 col-gt-sm-6">
                          <Button type="submit" color="success" class="btn-sm">
                            <i class="mdi mdi-check" />
                          </Button>
                          <Button
                            type="reset"
                            color="danger"
                            class="btn-sm"
                            on:click={cancelEdit}
                          >
                            <i class="mdi mdi-close" />
                          </Button>
                        </div>
                      </form>
                    {:else}
                      <p
                        class="text-muted"
                        role="button"
                        on:click={() => startEdit("description")}
                      >
                        {project.description || $_("project.description_placeholder")}
                      </p>
                    {/if}
                    -->
          <!--<div class="d-flex align-items-end justify-content-between mt-3">
&lt;!&ndash;            <div>&ndash;&gt;
&lt;!&ndash;              <p class="status text-success">Подписка активна</p>&ndash;&gt;
&lt;!&ndash;              <p class="time">Оплачен до: {project.paidTill}</p>&ndash;&gt;
&lt;!&ndash;            </div>&ndash;&gt;

            <Button color="light" on:click={OpenSettingsToggle}>Управление подпиской</Button>
          </div>-->
        </div>
      </div>
    </CardBody>
  </Card>

  <Modal isOpen={OpenSettings} toggle={OpenSettingsToggle} size="lg" class="modal-add" scrollable centered>
    <ModalHeader toggle={OpenSettingsToggle}>Управление подпиской</ModalHeader>
    <ModalBody>
      <div class="modal-block-title"><i class="bx bx-check text-success"></i> Подписки</div>
      <div class="form-group horizontal mb-4">
        <label for="period">Выбор периода оплаты:</label>
        <select id="period" value="4" class="form-control width-150px">
          <option value="1">Месяц</option>
          <option value="2">Пол года</option>
          <option value="3">Год</option>
          <option value="4">Вечность</option>
        </select>
      </div>
      <Row>
        <Col lg="6">
          <Card class="card-subscribe-check">
            <CardHeader>
              <small>Баланс #1</small>
              <h4>Название карты, данное Сарумоном</h4>
            </CardHeader>
            <CardBody>
              <div class="input-with-currency my-4">
                <p class="currency">$</p>
                <div class="input-card-style">
                  <span class="dots">.... .... ....</span>
                  <span class="numbers">1234</span>
                  <!-- Если visa (т.е. если есть картинка) -->
                  <img src="/assets/images/icons/logo-visa.png" alt="logo">
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <p class="m-0 font-size-14 medium opacity-50">Прикреплена к 2 проектам</p>
                <img src="/assets/images/icons/stripe-logo.png" alt="logo" height="30">
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="6">
          <Card class="card-subscribe-check checked">
            <CardHeader>
              <small>Баланс #1</small>
              <h4>Название карты, данное Сарумоном</h4>
            </CardHeader>
            <CardBody>
              <div class="input-with-currency my-4">
                <p class="currency">$</p>
                <div class="input-card-style">
                  <span class="dots">.... .... ....</span>
                  <span class="numbers">1234</span>
                  <!-- Если visa (т.е. если есть картинка) -->
                  <img src="/assets/images/icons/logo-visa.png" alt="logo">
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <p class="m-0 font-size-14 medium opacity-50">Прикреплена к 2 проектам</p>
                <img src="/assets/images/icons/stripe-logo.png" alt="logo" height="30">
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="6">
          <Card class="card-subscribe-check-add">
            <div class="add-text"><i class="bx bx-plus"></i> Добавить карту</div>
          </Card>
        </Col>
      </Row>

      <div class="modal-block-title">Балансы</div>
      <Row>
        <Col lg="6">
          <Card class="card-subscribe-check">
            <CardHeader>
              <small>Баланс #1</small>
              <h4>Название карты, данное Сарумоном</h4>
            </CardHeader>
            <CardBody>
              <div class="d-flex align-items-center justify-content-between my-4">
                <p class="price">25 <span>$</span></p>
                <Button outline color="success">Пополнить</Button>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <p class="m-0 font-size-14 medium opacity-50">Прикреплена к 2 проектам</p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ModalBody>
  </Modal>

  <ProjectDeleteModal {project} show={showModal} onCloseClick={()=>{showModal=false}}/>

{/if}
