import {get, writable} from 'svelte/store';
import {BasicDAO} from "./BasicDAO";


/**

 Represents a data object with information about a broadcast.
 @typedef {Object} SegmentData
 @property {Object=} segment - The segment information.
 @property {number} segment.id - The ID of the segment.
 @property {Object[]} segment.segment_list_user - The list of segment users.
 @property {number} segment.segment_list_user.id - The ID of the segment user.
 @property {string} segment.segment_list_user.segment_id - The ID of the segment as a string.
 @property {number} segment.segment_list_user.bot_user_id - The ID of the bot user.
 @property {number} segment.shop_id - The ID of the segment shop.
 @property {string} segment.name - The name of the segment.
 @property {Object} segment.state - The state of the segment.
 @property {string} segment.created_at - The creation date of the segment as a string.
 */

/**
 *
 * @type {BasicDAO<SegmentData>}
 */
const SegmentDAO = new BasicDAO(new writable([]), 'api/broadcast/segment_list_user');
const segmentStore = SegmentDAO.store;
export {
  SegmentDAO,
  segmentStore
}
