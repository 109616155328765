import firebase from 'firebase/app';
import 'firebase/auth';
import "firebase/firestore";
import { writable } from 'svelte/store';


const firebaseConfig = {
  apiKey: "AIzaSyCHjak8fbRyHZBlcq1PU4p7P--Ih0BPyXw",
  authDomain: "my.1msg.io",
  databaseURL: "https://msg-37f84-default-rtdb.firebaseio.com",
  projectId: "msg-37f84",
  storageBucket: "msg-37f84.appspot.com",
  messagingSenderId: "686459367370",
  appId: "1:686459367370:web:ccd32c37e597de4768a933"
};

const callbacks = writable({onAuthStateChanged : []});
let callbacksArr = [];

callbacks.subscribe(value => {
  callbacksArr = value;
});


firebase.initializeApp(firebaseConfig);


const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const gitAuthProvider = new firebase.auth.GithubAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();



const auth = firebase.auth();
const loginViaGoogle = () => auth.signInWithPopup(googleAuthProvider)
const loginViaGit = () => auth.signInWithPopup(gitAuthProvider)
const loginViaFacebook = () => auth.signInWithPopup(facebookAuthProvider)
const loginViaTwitter = () => auth.signInWithPopup(twitterAuthProvider)
const loginViaCredentials = (credentials) => {
  let credentialAuthProvider = firebase.auth.GoogleAuthProvider.credential(credentials);

  return firebase.auth().signInWithCredential(credentialAuthProvider)
}

const db = firebase.firestore();
let user = false;

const initializedDB = new Promise(() => db);

auth.onAuthStateChanged(data => {
  user = data || false;

  for (let callback of callbacksArr.onAuthStateChanged){
    callback({user: data})
  }
})

async function changePassword (newPassword){
  if(user){
    return user.updatePassword(newPassword);
  }
}



export {auth, user, db, initializedDB, callbacks, loginViaGoogle,loginViaGit,loginViaFacebook,loginViaTwitter, changePassword,loginViaCredentials}
