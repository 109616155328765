<script>
  import {
    Row, Col,
    Card, CardBody, CardTitle,
    Dropdown, DropdownToggle, DropdownMenu
  } from "sveltestrap";
  import {onMount, onDestroy} from "svelte";
  import {paymentProfiles} from "../../../helpers/store";
  import {_, locale} from "svelte-i18n";
  import {subscribeProcess} from "../../../helpers/subscribe";

  // Pages Components
  import YookassaModal from "../../../Components/Modals/YookassaModal.svelte";
  import {getRates} from "../../../helpers/getRates";
  import ProgressBar from "./ProgressBar.svelte";
  import CorporateInvoiceHostingBtn from "../../../Components/CorporateInvoiceHostingBtn.svelte";

  export let channel;
  export let user;

  const partnerId = "BEBD6pPA";
  const requestedNumber = channel.phone;

  const queryParameters = {
    email: user.email,
    name: user.name,
    state: channel.id,
    // redirect_url: string
    partner: user.id + '_1msg_' + channel.id,
    //next: string
  }

  let activeStep = !!channel.paymentProfileId || !!channel.noAutoCharge, maxStep = 0;
  let loading, lang, yooModal, subscribeParams, pricing = [], paymentProfile, paymentProfilesLocal;

  const baseUrl = 'https://hub.360dialog.com';

  let permissionUrl = requestedNumber
      ? `${baseUrl}/dashboard/app/${partnerId}/permissions?number=${requestedNumber}`
      : `${baseUrl}/dashboard/app/${partnerId}/permissions`;

  if (queryParameters) {
    let values = Object.values(queryParameters)
    Object.keys(queryParameters).forEach((k, idx) => {
      if (idx === 0 && !requestedNumber) {
        permissionUrl = permissionUrl + `?${k}=${values[idx]}`;
      } else {
        permissionUrl = permissionUrl + `&${k}=${values[idx]}`;
      }
    })
  }

  const getUrlParameter = (params, name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(params);
    return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  // const callbackAfterPopup = callbackObj => {
  //   console.log(callbackObj);
  // }

  async function updateProfile() {
    if (channel && channel.paymentProfileId) {
      paymentProfile = paymentProfilesLocal.find((item) => {
        return item.id === channel.paymentProfileId
      });
    }
    if (channel.project) {
      pricing = await getRates(channel.project, channel);
      if (!subscribeParams) {
        changePayment(pricing[lang][0].prices[0])
      }
    }
  }

  onMount(async () => {
    if (localStorage.getItem("I18N_LANGUAGE")) {
      lang = localStorage.getItem("I18N_LANGUAGE");
    }
    const params = window.location.search;
    const client = getUrlParameter(params, "client");
    const channels = getUrlParameter(params, "channels");
    const revokedChannels = getUrlParameter(params, 'revoked');

    if (client && channels) {
      const channelsArray = channels
          .substring(1, channels.length - 1)
          .split(",");

      let callbackObj = {
        client: client,
        channels: channelsArray,
      };

      if (revokedChannels) {
        const revokedChannelsArray = revokedChannels
            .substring(1, revokedChannels.length - 1)
            .split(',');
        callbackObj['revokedChannels'] = revokedChannelsArray;
      }
      // callbackAfterPopup(callbackObj);

      // remove search parameters from URL after fetching them
      // window.history.replaceState(null, "", window.location.pathname);
    }

    // send to parent (opener) window and close small window
    if (window.opener) {
      window.opener.postMessage(params);
      window.close();
    }
  });

  onDestroy(() => {
    unsub();
    unsub2();
  });

  const unsub = locale.subscribe((value) => {
    if (value) lang = value;
  });

  const unsub2 = paymentProfiles.subscribe(async (value) => {
    paymentProfilesLocal = value;
    paymentProfile = {};
    await updateProfile();
  });

  async function subscribe() {
    if (activeStep || channel.paymentProfileId) return;
    if (!subscribeParams || !subscribeParams.provider) return;
    if (loading) return;
    loading = true;
    let params = Object.assign({
      id: channel.id,
      userToken: user.token,
      currency: subscribeParams.currency,
      rate: subscribeParams.rate,
      org: subscribeParams.org,
      provider: subscribeParams.provider,
      success_url: window.location.href
    });

    let result = await subscribeProcess(params);
    window.dataLayer.push({
      'event': 'onboarding_create_subscription',
      'type': subscribeParams.provider,
      'plan': subscribeParams.rate,
      'currency': subscribeParams.currency
    });

    loading = false;
    if (params.provider === "yookassa" && result) {
      return toggleYoomodal(true, result)
    }
  }

  function toggleYoomodal(active = false, yooToken = false) {
    if (active && yooToken) {
      yooModal = true;
      const checkout = new window.YooMoneyCheckoutWidget({
        confirmation_token: yooToken, //Токен, который перед проведением оплаты нужно получить от ЮKassa
        return_url: `https://my.1msg.io/channels-payments`, //Ссылка на страницу завершения оплаты
        error_callback(error) {
          console.log(error);
          //Обработка ошибок инициализации
        }
      });

      //Отображение платежной формы в контейнере
      setTimeout(async () => {
        checkout.render('yookassa-form');
      }, 300);
    } else {
      yooModal = false;
    }
  }

  function changePayment(activePricing) {
    subscribeParams = {
      provider: activePricing.payment.id,
      rate: activePricing.price.rate,
      org: activePricing.price.org,
      currency: activePricing.price.currency
    }
  }
</script>
  <Card class="rounded">
    <div class="position-absolute bg-custom-green end-0 rounded-end d-none d-xl-block" style="width: 47%; height: 100%"/>
    <CardBody class="p-4 p-sm-5">
      <CardTitle class="h5 text-center font-size-28 d-none d-sm-block">{$_('channel.meta.title')}</CardTitle>
      <CardTitle class="h5 text-center font-size-20 d-sm-none">{$_('channel.meta.title')}</CardTitle>
        <Row class="mt-3 mt-sm-5 justify-content-between">
          <Col lg={12} xl={6}>
            {#if !channel.paymentProfileId}
              <div class="card custom-box-shadow card-sm-border-2 p-sm-4">
                <div class="custom-w-75 mx-auto bold text-center font-size-16 mb-4">
                  {#if pricing?.[lang]?.[0].trial}
                    {@html $_('channel.meta.step0.text1_trial')}
                  {:else}
                    {@html $_('channel.meta.step0.text1')}
                  {/if}
                </div>
                <div class="mb-4">
                  {#if pricing && pricing[lang]}
                    {#each pricing[lang] as payment}
                      {#each payment.prices as pricing}
                        <div on:click={() => changePayment(pricing)}
                          class="mb-3 mx-auto d-flex flex-column flex-sm-row align-items-center justify-content-between form-check payment-check {pricing.price.rate == subscribeParams?.rate && pricing.price.org == subscribeParams?.org && 'active'}">
                          <div>
                            <sup style="left: 0.25em;">{$_(pricing.price.currency)}</sup>
                            {pricing.price.value}/<span class="font-size-16">{$_(pricing.duration)}</span>
                            {#if (payment?.trial)}
                              <span style="color: #a7abb0" class="font-size-12 d-block text-center d-sm-inline-block">
                                ({$_('subscription.trial', {values: {trial: payment?.trial}})})
                              </span>
                            {/if}
                          </div>
                          <div class="current-logo text-end col-3">
                            <img class="img-fluid" alt="logo" src={pricing.payment.logo}/>
                          </div>
                        </div>
                      {/each}
                    {/each}
                  {/if}
                </div>
                <div class="text-center">
                  <div class="d-inline-flex flex-column gap-2 align-items-center">
                    <button type="button" on:click={subscribe} class="btn btn-lucky-primary w-100" disabled={loading}>
                      {#if loading}
                        <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                      {/if}
                      {#if pricing?.[lang]?.[0]?.trial}
                        {$_('subscription.start_trial')}
                      {:else}
                        {$_('subscription.subscribe')}
                      {/if}
                    </button>
                    {#if lang === 'ru'}
                      <CorporateInvoiceHostingBtn
                        uid={user.id}
                        rate={subscribeParams?.rate}
                        currency={subscribeParams?.currency}
                        org={subscribeParams?.org}
                        channelId={channel.id}
                        userToken={user.userToken}
                      />
                    {/if}
                  </div>
                </div>
              </div>
            {:else}
              <div class="custom-w-75 mt-5 mx-auto bold text-center">
                {@html $_('channel.meta.step0.text2')}
              </div>
            {/if}
            <Dropdown class="mb-2">
              <DropdownToggle tag="div" class="pointer d-inline-block text-primary text-wrap" caret>
                <i class="mdi mdi-alert-circle-outline"/> {$_('channel.meta.step0.dropdown.text1')}
              </DropdownToggle>
              <DropdownMenu class="p-3 mt-1">
                <div class="text-wrap text-muted">
                  {@html $_('channel.meta.step0.dropdown.text2')}
                </div>
              </DropdownMenu>
            </Dropdown>
            <Dropdown class="mb-2">
              <DropdownToggle tag="div" class="pointer d-inline-block text-primary text-wrap" caret>
                <i class="mdi mdi-alert-circle-outline"/> {$_('channel.meta.step0.dropdown1.text1')}
              </DropdownToggle>
              <DropdownMenu class="p-3 mt-1">
                <div class="text-wrap text-muted">
                  {@html $_('channel.meta.step0.dropdown1.text2')}
                </div>
              </DropdownMenu>
            </Dropdown>
            {#if lang === 'ru'}
              <Dropdown>
                <DropdownToggle tag="div" class="pointer d-inline-block text-primary text-wrap" caret>
                  <i class="mdi mdi-alert-circle-outline"/> {$_('channel.meta.step0.dropdown2.text1')}
                </DropdownToggle>
                <DropdownMenu class="p-3 mt-1">
                  <div class="text-wrap text-muted">
                    {@html $_('channel.meta.step0.dropdown2.text2')}
                  </div>
                </DropdownMenu>
              </Dropdown>
            {/if}
          </Col>
          <ProgressBar/>
        </Row>
    </CardBody>
  </Card>

{#if !channel.paymentProfileId}
  {#if yooModal}
    <YookassaModal show={yooModal} {toggleYoomodal}/>
  {/if}
{/if}

<style>
  .check-bg{
    position: absolute;
    height: 160px;
    bottom: 50px;
    right: -60px;
    opacity: 0.2;
  }

  .bg-custom-green {
    background-color: #daf4eb;
  }

  .custom-w-75 {
    width: 75%;
  }

  @media (max-width: 576px) {
    .custom-w-75 {
      width: 100%;
    }
  }

  @media (min-width: 576px) {
    .custom-box-shadow {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16) !important;
    }
  }
</style>
