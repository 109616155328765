<script>
  import {Modal, ModalBody, ModalFooter, Button} from "sveltestrap";
  import {onMount,  onDestroy} from "svelte";
  import {_, date} from "svelte-i18n";
  import {fade} from "svelte/transition";

  import {channels, projects, user} from "../../helpers/store";
  import {defaultConst} from "../../common/constants";
  import {addToast} from "../../helpers/toast";
  import {postRequest} from "../../helpers/utils";

  import Table, {
    Pagination,
    Search,
    Sort,
  } from "../../Components/Table/Table.svelte";
  import {sortNumber, sortString} from "../../Components/Table/sorting";
  import Spinner from "../Spinners/SpinnerComponent.svelte";


  export let show;
  export let project;
  export let onCloseClick;

  let activeUser = {};

  const unsub = user.subscribe((value) => {
    activeUser = value;
  });

  let userChannels = [];
  let userProjects = [];
  let moveChannel = false;
  let page = 0; //first page
  let addBtn = true; //first page
  let loading = false;

  onMount(() => {

  });
  onDestroy(() => {
    unsub();
    unsub1();
    unsub2();
  })
  const unsub1 = projects.subscribe(async (value) => {
    userProjects = value;
  });

  const unsub2 = channels.subscribe(async (value) => {
    let tmpArr = [];
    let unpaid = false;
    for (let i in value) {
      let channel = value[i];
      if (channel.projectId !== project.id) {
        let channelProject = userProjects.find((item) => {
          return item.id === channel.projectId;
        });
        if (channelProject) channel.projectName = channelProject.name;
        tmpArr.push(channel);
        if (channel.trial > Date.now() || channel.paidTill < Date.now()) {
          unpaid = true;
        }
      }
    }
    userChannels = tmpArr;
    addBtn = !unpaid;
  });

  const moveChannelConfirmed = async () => {
    if (moveChannel) {
      let params = {
        projectId: project.id,
        channelId: moveChannel.id,
        userToken: activeUser.userToken
      };

      let result = await postRequest(
        `${defaultConst.functionsUrl}/moveChannel`,
        params
      );

      if (!result || result.error) {
        addToast({
          title: $_("error"),
          message: result.error || $_("something_went_wrong"),
          dismissible: true,
          timeout: 4_000,
        });
      } else {
        onCloseClick();
        show = false;
        addToast({title: $_("saved")});
      }
    }
  };

</script>

<div class="add-event-modal">
  <Modal
      class="exampleModal"
      isOpen={show}
      fade={true}
      centered={true}
      toggle={onCloseClick}
      header={$_("channels.move_channel_header")}
      size="lg"
  >
    {#if loading}
      <Spinner></Spinner>
    {:else}
      <div class="modal-content">
        <ModalBody>
          {#if moveChannel}
            <div transition:fade>
              <div>
                {$_("channels.move_channel_sure", {
                  values: {
                    channel: moveChannel.name || moveChannel.id,
                    project: project.name || project.id || "-",
                  },
                })}
              </div>
              <div>
                <button
                    type="button"
                    class="btn btn-primary"
                    on:click={moveChannelConfirmed}>{$_("yes")}</button
                >
                <button
                    type="button"
                    class="btn btn-secondary"
                    on:click={() => {
                    moveChannel = false;
                  }}>{$_("cancel")}</button
                >
              </div>
            </div>
          {:else}
            <Table
                {page}
                rows={userChannels}
                let:rows={rows2}
            >
              <thead slot="head" class="table-light">
              <tr>
                <th class="align-middle">{$_("channels.table.id")} </th>
                <th class="align-middle">
                  {$_("channels.table.name")}
                </th>
                <th class="align-middle">{$_('project.text')} </th>
                <th scope="col"/>
              </tr>
              </thead>
              <tbody>
              {#each rows2 as channel}
                <tr>
                  <td>
                    {channel.id}
                  </td>
                  <td>
                    {channel.name || "-"}
                  </td>
                  <td>
                    {channel.projectName || channel.projectId || "-"}
                  </td>
                  <td>
                    <button type="button" class="border-0 p-0 bg-transparent text-start text-primary" on:click={() => moveChannel = channel}>
                      {$_("channels.move_channel")}
                    </button>
                  </td>
                </tr>
              {/each}
              </tbody>
            </Table>

            <!-- <p class="mb-2" transition:fade>
            Product id: <span class="text-primary">#SK2540</span>
          </p>
          <p class="mb-4">
            Billing Name: <span class="text-primary">Neal Matthews</span>
          </p>

          {#if userChannels}
            <div class="table-responsive" transition:fade>
              <Table class="table align-middle table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">{$_("channels.table.id")}</th>
                    <th scope="col">{$_("channels.table.name")}</th>
                    <th scope="col">{$_("channels.table.project")}</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {#each userChannels as channel}
                    <tr>
                      <td>
                        {channel.id}
                      </td>
                      <td>
                        {channel.name}
                      </td>
                      <td>
                        {channel.projectName || channel.projectId}
                      </td>
                      <td on:click={() => (moveChannel = channel)}>
                        {$_("channels.move_channel")}
                      </td>
                    </tr>
                  {/each}
                </tbody>
              </Table>
            </div>
          {/if} -->
          {/if}
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" on:click={onCloseClick}>
            {$_('close')}
          </Button>
        </ModalFooter>
      </div>
    {/if}
  </Modal>
</div>
