<script>
  import {Dropdown, DropdownMenu, DropdownToggle} from "sveltestrap";
  import {onMount} from "svelte";
  import {_} from "svelte-i18n";

  export let error = false;
  export let required = false;
  export let disabled = false;

  /**
   * @param {Object[]} value
   */
  export let value;

  /**
   * @param {Object[]} list
   * @property {number} list.value
   * @property {string} list.label
   */
  export let list;
  export let title;
  export let mainProperty = 'id';
  export let click = () => {};

  let all_selected = false;
  let select_required = true;
  /**
   *
   * @param {Object.<any, number>} checkObject
   */
  let checkObject = {}
  let nameObject = {initiated: false}
  let isOpen = false;

  function calculateChecksAndValues() {
    if (Array.isArray(value)) {
      const newCheckObject = {}
      for (let i = 0; i < value.length; i++) {
        newCheckObject[value[i][mainProperty]] = i
      }
      checkObject = newCheckObject;
    }
    all_selected = value?.length > 1 && value?.length === list?.length
  }

  $:value && calculateChecksAndValues();
  $:nameObject = list && Array.isArray(list) ? list.reduce(
    (result, item) => {
      result[item.value] = item.label
      return result;
    },
    {}
  ) : {};

  const check = (item) => {
    if (checkObject[item.value] !== undefined) {
      value.splice(checkObject[item.value], 1);
    } else {
      value.push({[mainProperty]: item.value})
    }
    value = value
  }

  const selectAll = () => {
    if (all_selected) {
      value = []
    } else {
      const newArray = [];
      for (let i = 0; i < list.length; i++) {
        newArray.push({[mainProperty]: list[i].value})
      }
      value = newArray
    }
    all_selected = !all_selected
  };
</script>

<div class="position-relative SelectDropdownCheckbox">
  {#if required}
    <select class="form-control" required={select_required}/>
  {/if}
  <Dropdown autoClose={false} {isOpen} toggle={() => isOpen = !isOpen}>
    <DropdownToggle caret on:click={() => {if(!isOpen) {click()}}}
                    class="form-control w-100 text-wrap d-flex justify-content-between {disabled ? 'disabled pe-none' : ''} {error ? 'Error' : ''}"
                    color="light" {disabled}>
      {#if value?.length}
        <div class="lh-1_8 text-left overflow-hidden Wrapper">
          {#each value as item,i}

              <span class="badge me-1">
                {nameObject[item[mainProperty]]}
                {#if !disabled}<i class="mdi mdi-close pointer"
                                  on:click={() => {check({value:item[mainProperty]})}}></i>{/if}
              </span>

          {/each}
        </div>
      {:else}
        <span class="lh-1_8 text-truncate Wrapper">{$_(title)}</span>
      {/if}
      <i class="mdi mdi-chevron-down ps-1"></i>
    </DropdownToggle>
    {#if list.length}
      <DropdownMenu class="w-100">
        <div class="scrollbar">
          <div>
            {#if list.length > 1}
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="dropdown-item m-0">
                <div class="form-check text-truncate ps-4">
                  <input class="form-check-input" type="checkbox" checked={all_selected} on:change={()=>{selectAll()}}/>
                  <span class="form-check-label">{$_('SelectDropdownCheckbox.select_all')}</span>
                </div>
              </label>
            {/if}
            {#each list as item}
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="dropdown-item m-0" title={item.label}>
                <div class="form-check text-truncate ps-4">
                  <input class="form-check-input" type="checkbox" checked={checkObject[item.value]!==undefined}
                         on:change={()=>{check(item)}}/>
                  <span class="form-check-label">{item.label}</span>
                </div>
              </label>
            {/each}
          </div>
        </div>
      </DropdownMenu>
    {/if}
  </Dropdown>
</div>

<style>
    select {
        position: absolute;
        top: 0;
    }

    .SelectDropdownCheckbox .scrollbar {
        max-height: 129px;
        overflow-y: auto;
    }

    .SelectDropdownCheckbox .scrollbar::-webkit-scrollbar {
        width: 3px;
        background-color: transparent;
    }

    .SelectDropdownCheckbox .scrollbar::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .lh-1_8 {
        line-height: 1.8;
    }

    .Wrapper {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 5px;
    }

    .text-left {
        text-align: left;
    }

    .SelectDropdownCheckbox .badge {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        padding: 3px 6px 3px 8px;
        color: #23B16D;
        background-color: rgba(35, 177, 109, 0.180392);
    }

    .SelectDropdownCheckbox .mdi-chevron-down {
        line-height: 39px;
    }

    .form-check-input:checked {
        background-color: #23B16D;
        border-color: #23B16D;
    }

    .form-check-input:focus {
        box-shadow: 0 0 0 0.15rem rgba(35, 177, 109, .25);
    }
</style>
