<script>
  import {onMount} from "svelte";

  let min = 30;
  let max = 150;
  let number = min;
  if (localStorage.getItem('ProgressBar_number')) {
    number = Number(localStorage.getItem('ProgressBar_number'));
  } else {
    number = Math.floor(Math.random() * (max - min + 1)) + min;
    localStorage.setItem('ProgressBar_number', number);
  }

  onMount(() => {
    if (number >= max) {
      return;
    }
    setTimeout(() => {
      number += Math.floor(Math.random() * (10));
      localStorage.setItem('ProgressBar_number', number);
    }, 4000);
  });
</script>

<div class="ProgressBar mx-auto mb-3">
  <div class="Bar fw-600" style="width: {(number / (200 - min) * 100).toFixed(0)}%;">{number}</div>
</div>

<style>
  .ProgressBar {
    width: 100%;
    max-width: 185px;
    height: 14px;
    border-radius: 20px;
    background-color: #daf4eb;
  }

  .ProgressBar .Bar {
    height: 14px;
    padding: 0 5px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
    background-color: #23B16D;
    border-radius: 20px;
    transition: 0.5s;
  }

  @media (min-width: 1200px) {
    .ProgressBar {
      background-color: #FFFFFF;
    }
  }
</style>
