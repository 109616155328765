<script>
  import Highlight from "svelte-highlight";
  import language from "svelte-highlight/languages/javascript";
  import "svelte-highlight/styles/atom-one-dark-reasonable.css";
  export let code = '';
</script>

<div class="PrettyfiedCode">
  <Highlight {language} {code}/>
</div>

<style>
  :global(.PrettyfiedCode pre) {
    margin-bottom: 0;
  }

  :global(.PrettyfiedCode pre code) {
    min-height: 220px;
  }
</style>
