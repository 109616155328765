<script>
  import {Modal, ModalBody, Button, Input} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {appClient, chosenChannel, user} from "../../../../helpers/store";
  import {onDestroy} from "svelte";
  import {addToast} from "../../../../helpers/toast";
  import Tags from "./elements/Tags.svelte";
  import ActiveFlows from "./elements/ActiveFlows.svelte";
  import SpinnerComponent from "../../../../Components/Spinners/SpinnerComponent.svelte";
  import {avatarNumber, eventTracking} from "../../../../helpers/utils";

  export let chosenChat;
  export let isOpenUserCard;
  export let toggleUserCard;

  let activeChat;

  let userInfo, userScheme, chosenChannelLocal = null;

  let activeFlows, flowsDefinitions, stepsDefinitions, newName;
  let loader = false;
  let renameUserIsOpen = false;

  const loadUserInfo = async () => {
    if (activeChat?.bot_user_id && chosenChannelLocal) {
      await appClient.getBotUserData(activeChat.bot_user_id).then(data => {
        if (data.status !== 'ok') {
          console.error(data);
          addToast({title: $_("error"), message: $_("something_went_wrong")});
          return
        }
        userInfo = data.result;
        activeFlows = userInfo.store.active_flows;
        newName = userInfo.name;
      })
    }
  };

  const loadUserScheme = async () => {
    if (activeChat?.bot_user_id && chosenChannelLocal) {
      await appClient.getBotUserScheme(activeChat.bot_user_id, chosenChannelLocal).then(data => {
        if (data.status !== 'ok') {
          console.error(data);
          addToast({title: $_("error"), message: $_("something_went_wrong")});
          return
        }
        userScheme = data.result;
        flowsDefinitions = userScheme.schema.definition.flows;
        stepsDefinitions = userScheme.schema.definition.steps;
      })
    }
  };

  const updateUserInfo = (data) => {
    loader = true;
    appClient.patchBotUserData(activeChat.bot_user_id, {shop_id: activeChat.shop_id, ...data}).then(data => {
      if (data.status !== 'ok') {
        console.error(data);
        loader = false;
        addToast({title: $_("error"), message: $_("something_went_wrong")});
        return
      }
      userInfo = data.result;
      activeFlows = userInfo.store.active_flows;
      loader = false;
      eventTracking('inbox_triggered_action_chat', {action_type: 'add_tag'});
      addToast({title: $_("chat_inbox.UserCard.req_success")})
    }, err => {
      console.error(err);
      loader = false;
      addToast({title: $_("error"), message: $_("something_went_wrong")})
    });
    loadUserScheme()
  };

  const updateActiveFlows = (flow_id, action, callback) => {
    loader = true;
    appClient.postSubscribeOrUnsubscribeToFlow({
      bot_user_id: activeChat.bot_user_id,
      shop_id: activeChat.shop_id,
      flow_id
    }, action).then(data => {
      if (data.status !== 'ok') {
        console.error(data);
        loader = false;
        addToast({title: $_("error"), message: $_("something_went_wrong")});
        return
      }
      setTimeout(() => {
        loadUserScheme();
        loadUserInfo();
      }, 2000);
      setTimeout(async () => {
        await loadUserScheme();
        await loadUserInfo();
        if (action === 'subscribe_to_flow') {
          eventTracking('inbox_triggered_action_chat', {action_type: 'start_flow'});
        }
        loader = false;
        addToast({title: $_("chat_inbox.UserCard.req_success")});
        callback && callback()
      }, 5000)
    }, err => {
      console.error(err);
      loader = false;
      addToast({title: $_("error"), message: $_("something_went_wrong")})
    })
  };

  const renameUser = () => {
    loader = true;
    newName = newName.replace(/\s+/g, ' ').trim();
    appClient.patchBotChatUpdate(activeChat.id, {name: newName}).then(data => {
      loader = false;
      if (data.status !== 'ok') {
        console.error(data);
        addToast({title: $_("error"), message: $_("something_went_wrong")});
        return
      }
      userInfo.name = newName;
      eventTracking('inbox_triggered_action_chat', {action_type: 'edit_user'});
      addToast({title: $_("chat_inbox.UserCard.req_success")})
    }, err => {
      console.error(err);
      loader = false;
      addToast({title: $_("error"), message: $_("something_went_wrong")})
    })
  };

  const playOrPauseCurrentFlow = () => {
    loader = true;
    appClient.patchBotChatUpdate(activeChat.id, {is_flow_blocked: !$chosenChat.is_flow_blocked}).then(data => {
      loader = false;
      if (data.status !== 'ok') {
        console.error(data);
        addToast({title: $_("error"), message: $_("something_went_wrong")});
        return
      }
      addToast({title: $_("chat_inbox.UserCard.req_success")})
    }, err => {
      console.error(err);
      loader = false;
      addToast({title: $_("error"), message: $_("something_went_wrong")})
    })
  };

  const unsub3 = chosenChannel.subscribe(async (value) => {
    if (value === null || value === "") {
      chosenChannelLocal = null
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        chosenChannelLocal = value
      }
    }
  })

  const unsubChosenChat = chosenChat.subscribe(async value => {
    if (activeChat?.id !== value.id) {
      loader = true;
      activeChat = value;
      await loadUserInfo();
      if (activeFlows?.length) await loadUserScheme();
      loader = false
    }
  })

  onDestroy(() => {
    unsub3();
    unsubChosenChat()
  });
</script>

<div class="bg-white border-start UserCard h_minus40 {isOpenUserCard ? 'end-0' : ''}">
  <div class="px-3 py-4 h-100 overflow-auto">
    {#if userInfo}
      <div class="text-end">
        <span class="font-size-14 pointer text-success" on:click={() => {
          newName = userInfo.name;
          renameUserIsOpen=true
          if ($user.userHintsHintInbox_9 === false) {
            $user.userHintsHintInbox_9 = true;
          }
        }}>
          {$_("chat_inbox.UserCard.edit")}
        </span>
      </div>
      <div class="text-center mb-4">
        {#if userInfo.avatar_url}
          <img src={userInfo.avatar_url} class="rounded-circle mb-3 avatar object-fit-cover" alt="avatar"/>
        {:else}
          <img src="/assets/images/icons/avatars/{avatarNumber(userInfo.name ? userInfo.name : '0')}.svg"
            class="rounded-circle mb-3 avatar object-fit-cover" alt="avatar"/>
        {/if}
        <h5 class="font-size-18 fw-500 text-truncate px-4 mb-1" title={userInfo.name}>
          {#if userInfo.name}
            {userInfo.name}
          {:else}
            &nbsp;
          {/if}
        </h5>
        <span class="font-size-16 text-muted text-truncate" title={userInfo.messenger_user_id}>
      {#if userInfo.messenger_user_id}
        {userInfo.messenger_user_id}
      {:else}
        &nbsp;
      {/if}
    </span>
      </div>
      <div class="line"/>
      <div class="mb-3 mt-3">
        <div class="font-size-16 fw-500 mb-1">{$_("chat_inbox.UserCard.created_at")}</div>
        <div class="font-size-14 text-muted">{userInfo.created_at}</div>
      </div>
      <div class="mb-3">
        <div class="font-size-16 fw-500 mb-1">{$_("chat_inbox.UserCard.updated_at")}</div>
        <div class="font-size-14 text-muted">{userInfo.updated_at}</div>
      </div>
      <Tags activeTags={userInfo.store.tags}
        listTags={userScheme?.schema.properties.store.properties.tags.items.enum}
        {updateUserInfo} {loadUserScheme}/>
      <ActiveFlows userName={userInfo.name} isFlowBlocked={$chosenChat?.is_flow_blocked}
        {activeFlows} {flowsDefinitions} {stepsDefinitions} {updateActiveFlows}
        {loadUserScheme} {playOrPauseCurrentFlow}/>
    {/if}
  </div>
  {#if loader || !userInfo}
    <div class="position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center bg-white bg-opacity-75">
      <SpinnerComponent/>
    </div>
  {/if}
  <i class="mdi mdi-close position-absolute start-0 top-0 px-2 py-1 pointer font-size-26" on:click={toggleUserCard}/>
</div>

{#if renameUserIsOpen}
  <Modal isOpen={renameUserIsOpen} fade={true} toggle={() => renameUserIsOpen=false} size="md" centered>
    <ModalBody>
      <button type="button" on:click={() => renameUserIsOpen=false}
        class="btn-close position-absolute end-0 top-0 p-2"/>
      <form on:submit|preventDefault={() => {renameUser(); renameUserIsOpen=false}}>
        <div class="pt-4 mb-3">
          <Input type="text" bind:value={newName} required/>
        </div>
        <div class="text-center">
          <Button type="submit" color="success" class="font-size-16 py-1 px-4">
            {$_("save")}
          </Button>
        </div>
      </form>
    </ModalBody>
  </Modal>
{/if}

<style>
  .UserCard {
    position: relative;
    width: 300px;
    height: calc(100vh - 94px);
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  }

  .UserCard .mdi.mdi-close {
    display: none;
  }

  .UserCard .avatar {
    width: 90px;
    height: 90px;
  }

  .UserCard .line {
    height: 1px;
    background: #DBDCDD;
  }

  @media screen and (max-width: 1650px) {
    .UserCard .mdi.mdi-close {
      display: inline-block;
    }

    .UserCard {
      position: absolute;
      right: -314px;
      z-index: 2;
      transition: .2s;
    }
  }

  @media (min-width: 768px) {
    .UserCard {
      width: 314px;
      height: calc(100vh - 132px);
    }
  }

  @media (min-width: 992px) {
    .UserCard {
      height: calc(100vh - 148px);
    }
  }
</style>
