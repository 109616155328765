<script>
    import {onMount, onDestroy, setContext, afterUpdate} from "svelte";
    import {Card, CardBody, Col, Row, Button} from "sveltestrap";
    import {Link} from "svelte-routing";
    import {channels} from "../../helpers/store";
    import {_, date} from "svelte-i18n";
    import firebase from "firebase/app";
    import {addToast} from "../../helpers/toast";
    import {user} from "../../helpers/store";

    import Table, {
        Pagination,
        Search,
        Sort,
    } from "../../Components/Table/Table.svelte";
    import {sortNumber, sortString} from "../../Components/Table/sorting";
    import ChannelsTable from "../../Components/Table/ChannelsTable.svelte";

    export let project;
    // export let isAdmin;

    let orderModal = false;
    let projectChannels = false;
    let page = 0; //first page
    let pageSize = 10; //optional, 10 by default
    let addBtn = true; //first page
    let activeUser;

    let firestoreUnsubscribe = () => {
    };

    setContext("projectChannels", {
        getProjectChannels: () => projectChannels,
    });

    // channels.subscribe((value) => {
    //     if (value && project.id) {
    //         project = value.find((item) => item.id === project.id);
    //     } else {
    //         project = false;
    //     }
    // });

    function onSortString(event) {
        event.detail.rows = sortString(
            event.detail.rows,
            event.detail.dir,
            event.detail.key
        );
    }



    onMount(() => {
        init();
    });
    afterUpdate(() => {
        init();
    })
    const init = () => {
        if (project && project.id) {
            firestoreUnsubscribe = firebase
                .firestore()
                .collection("channels")
                .where("projectId", "==", project.id)
                .onSnapshot((querySnapshot) => {
                    let channelsArr = [];
                    querySnapshot.forEach((doc) => {
                        let value = doc.data();
                        if (value.paidTill)
                            value.paidTill = new Date(parseInt(value.paidTill));
                        channelsArr.push(value);
                    });
                    projectChannels = channelsArr;
                });
        }
    }


    onDestroy(() => {
        firestoreUnsubscribe();
        unsub();
    });

    const unsub = user.subscribe((value) => {
        activeUser = value;
    });

    // При наведении на <tr> всем <td> с классом td-link должен добавляться класс active и удаляться после убирания мышки.

    let hovered = false;

    function toggleHover() {
        hovered = !hovered;
    }

</script>


<Card on:click>
    <CardBody>
        {#if projectChannels}
             <ChannelsTable {project} channels={projectChannels}></ChannelsTable>
        {/if}
    </CardBody>
</Card>
