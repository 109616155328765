<script>
  import {onMount, onDestroy} from "svelte";
  import {paymentRecords, isUserCreatedAfter1July2024} from "../../helpers/store";
  import {_, locale} from "svelte-i18n";
  import ChannelInitFirstStep from "./InitFirstStep/ChannelInitFirstStep.svelte";
  import ChannelInitFirstStepV2 from "./InitFirstStep/ChannelInitFirstStepV2.svelte";
  import ChannelInitFirstStepV3 from "./InitFirstStep/ChannelInitFirstStepV3.svelte";
  import ChannelInitFirstStepV4 from "./InitFirstStep/ChannelInitFirstStepV4.svelte";

  export let channel;
  export let user;

  let activeStep = !!channel.paymentProfileId || !!channel.noAutoCharge, lang;

  onMount(() => {
    if (localStorage.getItem("I18N_LANGUAGE")) {
      lang = localStorage.getItem("I18N_LANGUAGE");
    }
  });

  const unsubLocale = locale.subscribe((value) => {
    if (value) lang = value;
  });

  onDestroy(() => {
    unsubLocale();
  });
</script>

<div class="position-absolute d-none d-lg-flex justify-content-between align-items-end w-100 px-5 pt-5 bg-yellow bg-soft initiate_header">
  <img src="/assets/images/header_girl.svg" alt="header" style="width: 200px"/>
  <img src="/assets/images/header_boy.svg" alt="header" style="width: 220px"/>
</div>
{#if activeStep == 0}
  {#if lang === 'ru' && isUserCreatedAfter1July2024()}
    <ChannelInitFirstStepV3 {channel} {user}/>
  {:else}
    {#if $paymentRecords?.length}
      <ChannelInitFirstStep {channel} {user}/>
    {:else}
      <ChannelInitFirstStepV2 {channel} {user}/>
    {/if}
  {/if}
{:else}
  <ChannelInitFirstStepV4 {channel} {user}/>
{/if}
