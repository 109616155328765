<script>
  import {getContext, onDestroy} from 'svelte';
  import {Collapse} from "sveltestrap";
  import {eventTracking, promisifyStore} from "../../helpers/utils";
  import {user} from "../../helpers/store";
  import {setUserHintDone} from "../../helpers/hints";

  import * as amplitude from "@amplitude/analytics-browser";

  export let header;
  export let autoDoneOnOpen = false;

  /**
   * @type {string} hintName - a name of a hint, propagated from a parent
   */
  const {hintName} = getContext('hintName');

  /**
   * @type {UserHintsHelper} UserHintsHelper
   */
  const UserHintsHelper = getContext('UserHintsHelperContext').UserHintsHelper;

  let indexNumber = UserHintsHelper.addItemToArray();
  let openFlagWritableArray = UserHintsHelper.openFlagWritableArray;
  let doneWritableArray = UserHintsHelper.doneWritableArray;
  let saving = false;
  /**
   * @type {string} flagName - should be manually set only when we are adding more steps in between and do not want to disturb users
   */
  export let flagName = `${hintName}_${indexNumber}`

  $:autoDoneOnOpen && $openFlagWritableArray[indexNumber] && UserHintsHelper.changeDoneArray(indexNumber, true)

  $:$doneWritableArray?.[indexNumber] && promisifyStore(user).then(data => {
    if (!data?.user_hints?.[flagName] && !saving) {
      saving = true
      eventTracking('cabinet_hint_step_done', {hint_name: hintName, step_number: indexNumber})
      const saved = () => saving = false
      setUserHintDone($user.userToken, flagName).then(saved, saved);
      //Do not close current tab if it is autocompleted upon open so that user can actually read it
      !autoDoneOnOpen && UserHintsHelper.changeFlagArray(indexNumber, false);
      //Do not open next tab if it is autocompleted upon open so that there is no "open train"
      !autoDoneOnOpen && UserHintsHelper.changeFlagArray(indexNumber + 1, true);
    }
  }, console.error)

  onDestroy(() => {
    UserHintsHelper && UserHintsHelper.deleteItemFromArray()
  });
</script>

<div class="HintsAccordion-item">
  <button
      class="d-flex align-items-center w-100 justify-content-between p-3 {$openFlagWritableArray[indexNumber] ? 'active' : ''} {$doneWritableArray?.[indexNumber] || $user?.user_hints?.[flagName] ? 'Done' : ''}"
      on:click={() => UserHintsHelper.changeFlagArray(indexNumber)}>
    <div class="text-truncate fw-600 font-size-15 me-3">{header}</div>
    <div class="Chevron">
      <svg fill="none" height="9" viewBox="0 0 12 9" width="12" xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd" d="M1.41 0.700195L6 5.2902L10.59 0.700195L12 2.1202L6 8.1202L0 2.1202L1.41 0.700195Z"
              fill="#495057"
              fill-rule="evenodd"/>
      </svg>
    </div>
  </button>
  <Collapse isOpen={$openFlagWritableArray[indexNumber]}>
    <div class="py-3">
      <slot></slot>
    </div>
  </Collapse>
</div>

<style>
    .HintsAccordion-item .active {
        background-color: #f4f4f4;
    }

    .HintsAccordion-item .active .Chevron {
        transform: rotate(180deg);
    }

    .HintsAccordion-item button {
        color: #495057;
        border: 0;
        background-color: white;
    }

    .HintsAccordion-item button.Done {
        color: #23B16D;
        background-color: #DAF4EB;
    }

    .HintsAccordion-item button.Done svg path {
        fill: #23B16D;
    }
</style>
