<script>
  import {Table, Input} from "sveltestrap";
  import {Link} from "svelte-routing";
  import moment from "moment";
  import {onMount, onDestroy, afterUpdate} from "svelte";
  import {_} from 'svelte-i18n';
  import {channelsAdditionalInfo, getChannelAdditionalInfo, projects, addChannelFirstTimeHelper} from "../../helpers/store";
  import {copyText} from "../../helpers/utils";
  import {addToast} from "../../helpers/toast";
  import ChannelsMoveModal from "../Modals/ChannelsMoveModal.svelte";
  import ChannelsAddModal from "../Modals/ChannelsAddModal.svelte";
  import ChannelsDeleteModal from "../Modals/ChannelsDeleteModal.svelte";

  export let channels;
  export let project;
  export let searchText;
  export let profiles = {};

  export let showSearch = true;
  export let showAddButton = true;

  let showShowAllButton = false;
  let showAllButtonPressed = false;

  const itemsLimit = 25;

  afterUpdate(() => {
    //getAddInfo(); почему то грузит систему аки бог
    if (channels?.length > itemsLimit && !showAllButtonPressed) {
      showShowAllButton = true;
    }
  });

  //параметр нужен чтобы система реагировала на изменение внутренней переменной сама
  const searchFilter = (smth) => {
    let result;
    if (!searchText || searchText.length < 3) {
      result = channels;
    } else if (searchText.length > 2) {
      const regularExpression = new RegExp(`${searchText}`, 'i')
      result = channels.filter(channel => {
        const hasId = channel.id?.match(regularExpression);
        if (hasId) {
          return true
        }
        const hasName = channelsAdditionalInfoLocal?.[channel.id]?.name?.match(regularExpression);
        if (hasName) {
          return true
        }
        const hasPhone = channelsAdditionalInfoLocal?.[channel.id]?.id?.match(regularExpression)
        return !!hasPhone;
      });
    }
    if (!showShowAllButton || showAllButtonPressed) {
      return result;
    } else {
      return result.slice(0, itemsLimit);
    }
  };

  let showModal = false;
  let showDeleteModal = false;
  let showMoveModal = false;
  let activeLink;
  let channelToDelete;
  let channelsAdditionalInfoLocal = {};

  const getAddInfo = () => {
    let infoToUpdate = [];
    if (Array.isArray(channels)) {
      channels.forEach(item => {
        if (!channelsAdditionalInfoLocal.hasOwnProperty(item.id) && item.waba_info && !item.stopped) {
          infoToUpdate.push(item);
        }
      })
      getChannelAdditionalInfo(infoToUpdate);
    }
  };

  onMount(() => {
    getAddInfo();
  });

  const setShowDeleteModal = (status, channelToDeleteParam = null) => {
    channelToDelete = channelToDeleteParam;
    showDeleteModal = status;
  };

  const setShowModal = (status) => {
    showModal = status;
  };

  const unsub = channelsAdditionalInfo.subscribe(value => {
    let reworkedValue = value;
    for (let i in reworkedValue) {
      if (reworkedValue[i].hasOwnProperty('id')) {
        reworkedValue[i].id = reworkedValue[i].id.split("@").shift();
      }
    }
    channelsAdditionalInfoLocal = value;
  });

  onDestroy(() => {
    unsub();
  });

  const copyToClipoard = (text) => {
    copyText(text);
    addToast({title: 'copied', message: text, dismissible: true, timeout: 2_000})
  };
</script>

{#if showAddButton && project}
  <ChannelsAddModal {project} show={showModal} onCloseClick={() => setShowModal(false)}/>
  <ChannelsMoveModal {project} show={showMoveModal} onCloseClick={() => showMoveModal = false}/>
{/if}

<ChannelsDeleteModal channel={channelToDelete} onCloseClick={() => setShowDeleteModal(false)} show={showDeleteModal}/>

{#if showAddButton || showSearch}
  <div class="table-header">
    <div class="ps-0 ps-md-4">
      {#if showSearch}
        <div class="table-search">
          <Input type="text" bind:value={searchText} placeholder={$_('search')}/>
        </div>
      {/if}
      {#if !showSearch && project}
        <small>{$_('project.text')}</small>
        <h4>{project.name}</h4>
      {/if}
    </div>
    {#if showAddButton}
      <div class="table-buttons gap-3 flex-wrap">
        <button type="button" class="btn btn-success btn-lg waves-effect waves-light" on:click={() => addChannelFirstTimeHelper(() => setShowModal(true))}>
          <i class="mdi mdi-plus me-1"/>
          {$_('channels.add_channel')}
        </button>
        {#if $projects?.length>1}
          <button type="button" class="ms-sm-3 btn btn-outline-success waves-effect waves-light" on:click={() => showMoveModal = true}>
            {$_('channels.move_channel')}
          </button>
        {/if}
      </div>
    {/if}
  </div>
{/if}
<div class="table-responsive">
  <Table class="align-middle table-nowrap table-check table-project">
    <thead>
    <tr>
      <!--      <th style="width: 20px;" class="align-middle">-->
      <!--        <div class="form-check font-size-16">-->
      <!--          <input-->
      <!--              class="form-check-input"-->
      <!--              type="checkbox"-->
      <!--              id="checkAll"-->
      <!--          />-->
      <!--          <label class="form-check-label" htmlFor="checkAll"/>-->
      <!--        </div>-->
      <!--      </th>-->
      <th class="align-middle id">{$_("channels.table.id")}</th>
      <th class="align-middle">{$_('channels.table.name')}</th>
      <th class="align-middle">{$_('channels.table.paid_till')}</th>
      <th class="align-middle">{$_('channels.table.authorization_status')}</th>
      <th class="align-middle">{$_('channels.table.status')}</th>
      <th class="align-middle">{$_('channels.table.phone')}</th>
      <th class="align-middle">{$_('channels.table.subscription_status')}</th>
    </tr>
    </thead>
    <tbody>
    {#each searchFilter(channels && searchText && showAllButtonPressed) as channel}
      <tr class={activeLink === channel.id ? '' : 'link-active'}>
        <!--        <td class="actions">-->
        <!--          <div class="form-check font-size-16">-->
        <!--            <input-->
        <!--                class="form-check-input"-->
        <!--                type="checkbox"-->
        <!--                id="orderidcheck01"-->
        <!--            />-->
        <!--            <label-->
        <!--                class="form-check-label"-->
        <!--                htmlFor="orderidcheck01"-->
        <!--            />-->
        <!--          </div>-->
        <!--        </td>-->
        <td class="td-link first">
          <div class="fw-bold text-body whatsapp">
            <i class="bx bxl-whatsapp"/>
            {channel.id}
            <button type="button" class="p-0 border-0 bg-transparent ms-2" on:click={() => copyToClipoard(channel.id)}>
              <i class="bx bx-copy-alt font-size-16 text-muted opacity-75 align-text-top custom-hover-primary"/>
            </button>
          </div>
        </td>
        <td class="td-link">
          <Link to="/channel/{channel.id}">
            {channel.name || channelsAdditionalInfoLocal?.[channel.id]?.name || '-'}
          </Link>
        </td>
        <td class="td-link">
          <Link to="/channel/{channel.id}">
            {channel.activeTill && (channel.noAutoCharge || channel.paymentProfileId || channel.lastPaymentProfileId) ? moment(parseInt(channel.activeTill)).format('DD.MM.YYYY') : '-'}
          </Link>
        </td>
        <td class="td-link">
          <Link to="/channel/{channel.id}">
            {channelsAdditionalInfoLocal?.[channel.id]?.accountStatus ? channelsAdditionalInfoLocal?.[channel.id]?.accountStatus : '-'}
          </Link>
        </td>
        <td class="td-link">
          <Link to="/channel/{channel.id}">
            <span class="badge-status {channel.stopped ? 'danger' : 'success'}">
              {channel.stopped ? 'stopped' : 'active'}
            </span>
          </Link>
        </td>
        <td class="td-link">
          <Link to="/channel/{channel.id}">
            {channelsAdditionalInfoLocal?.[channel.id]?.phone ? "+" + channelsAdditionalInfoLocal?.[channel.id]?.phone : '-'}
          </Link>
        </td>
        <td class="td-link last">
          <Link to="/channel/{channel.id}">
            {#if channel.paymentProfileId && profiles[channel.paymentProfileId]?.subscriptionStatus}
              {$_(`subscription.status.${profiles[channel.paymentProfileId]?.subscriptionStatus}`)}
            {/if}
            <i class="bx bx-right-arrow-alt"/>
          </Link>
        </td>
        <!-- <td class="td-link last" on:mouseenter={() => activeLink = channel.id} on:mouseleave={() => activeLink = null}>
          <div class="d-flex gap-3">
            <Link to="/channel/{channel.id}" class="hover-text-success text-dark">
              <i class="bx bx-pencil font-size-18"/>
            </Link>
            <i class="bx bx-trash font-size-18 hover-text-danger pointer" on:click={() => setShowDeleteModal(true,channel)}/>&ndash;&gt;
          </div>
        </td> -->
      </tr>
    {/each}
    </tbody>
  </Table>
</div>
{#if showShowAllButton && !showAllButtonPressed}
  <div class="text-center mt-3">
    <span class="show-all" on:click={() => showAllButtonPressed = true}>
      {$_('show_all')}
      <i class="bx bx-chevron-down"/>
    </span>
  </div>
{/if}

<style>
  .td-link.first .text-body.whatsapp {
    height: 60px;
    padding: 0 20px;
    line-height: 55px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-radius: 6px 0 0 6px;
    border-left: 1px solid transparent;
  }

  tbody tr:first-child .td-link.first .text-body.whatsapp {
    margin-top: 15px;
  }

  .link-active:hover .td-link.first .text-body.whatsapp {
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    border-left: 1px solid #e4e4e4;
  }
</style>
