<script>
  import Text from "./Text.svelte";
  import {_} from "svelte-i18n";

  export let message;
  export let direction;
  export let scheduled = false;
  export let liteVersio = false;
</script>

<div class={!liteVersio ? "Catalog" : ""}>
  <div class="d-flex align-items-center gap-2 rounded-3 {!liteVersio ? "Catalog-div" : ""} {direction ? (!message.profile_id ? 'Catalog-blue' : (scheduled ? 'text-muted' : 'Catalog-green')) : ''}">
    <i class="bx bxs-grid-alt {!liteVersio ? "font-size-24" : "font-size-22"}"/> <span class="text-truncate {!liteVersio ? "fw-600 font-size-16 " : "font-size-15"}">{$_('chat_inbox.MessageChat.catalog')}</span>
  </div>
</div>

<Text {message} {direction} {scheduled} {liteVersio} classes={!liteVersio ? "pt-0" : "pt-2"}/>

<style>
  .Catalog {
    padding: 6px 12px;
  }

  .Catalog-div {
    padding: 6px 13px 6px 9px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .Catalog-blue {
    color: #0096DE;
    background-color: rgba(0, 150, 222, 0.10);
  }

  .Catalog-green {
    color: #23B16D;
    background-color: rgba(35, 177, 109, 0.10);
  }
</style>
