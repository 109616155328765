<script>
  import {CardTitle, Label, Input, Button, FormGroup, Col, Spinner} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {addToast} from "../../helpers/toast";
  import {postRequest, getRequest} from "../../helpers/utils";
  import TextAreaAutosize from "../../Components/TextAreaAutosize.svelte";

  export let channel;
  export let channelAdditionalInfo;
  export let updateChannelAdditionalInfo;

  let settings, account = {}, websites = [], webhookUrl = [''],
    startSettings = {},
    pristine = true,
    changeAccountLoader = false,
    changeSettingsLoader = false;

  // let regUrl =
  //   "/((https?|ftp):\\/\\/)?([a-z]+[.])?[a-z0-9-]+([.][a-z]{1,4}){1,2}(\\/.*[?].*)?|^(http(s?)://)+((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):[0-9]+(.)+?$/";
  // let regProxy =
  //   "/(d{1,3}.d{1,3}.d{1,3}.d{1,3}:d+)|((?!-)(?:(?:[a-zA-Zd][a-zA-Zd-]{0,61})?[a-zA-Zd].){1,126}(?!d+)[a-zA-Zd]{1,63}):d+/";

  const verticals = [
    'Automotive', 'Beauty, Spa and Salon', 'Clothing and Apparel', 'Education', 'Entertainment',
    'Event Planning and Service', 'Finance and Banking', 'Food and Grocery', 'Public Service',
    'Hotel and Lodging', 'Medical and Health', 'Non-profit', 'Professional Services', 'Shopping and Retail',
    'Travel and Transportation', 'Restaurant', 'Other',
  ];

  const verticalsIsCloud = [
    'OTHER', 'AUTO', 'BEAUTY', 'APPAREL', 'EDU', 'ENTERTAIN', 'EVENT_PLAN', 'FINANCE', 'GROCERY',
    'GOVT', 'HOTEL', 'HEALTH', 'NONPROFIT', 'PROF_SERVICES', 'RETAIL', 'TRAVEL', 'RESTAURANT'
  ];

  const props = ['email', 'vertical', 'about', 'address', 'description']

  const disabledWebhookUrl = [
    {name: 'app.1msg.io', image: 'https://s3.eu-central-1.wasabisys.com/onemessageapp/admin/2023/5/17/images/c5/d4/89cb9413fd394946ab90e334eb8fa28f.png'},
    {name: 'hooks.zapier.com', image: 'https://s3.eu-central-1.wasabisys.com/onemessageapp/admin/2023/12/21/images/07/3a/c8158d7aa72a474da66ca52bae10bde7.jpg'},
  ];


  const checkPristine = () => {
    if (startSettings) {
      pristine = true;
      for (let prop in startSettings) {
        if (settings[prop] != startSettings[prop]) {
          pristine = false;
          break;
        }
      }
    }
  };

  const changeSettings = async () => {
    changeSettingsLoader = true;
    try {
      let result = await postRequest(
        `${channel.apiUrl}settings?token=${channel.token}`,
        {...settings, webhookUrl}
      );
      if (result && !result.error) {
        addToast({title: $_("saved")});
        settings.webhookUrl = webhookUrl;
      } else {
        addToast({title: $_("error")});
      }
    } catch (e) {
      addToast({title: $_("error")});
    }
    changeSettingsLoader = false;
    checkPristine();
  };

  const deleteWebhookUrl = (index) => {
    let arr = [...webhookUrl];
    arr.splice(index, 1);
    webhookUrl = arr;
  };

  const changeAccount = async () => {
    changeAccountLoader = true;
    try {
      let result = await postRequest(`${channel.apiUrl}me?token=${channel.token}`, {...account, websites: websites.filter(val => val != '')});
      if (result && !result.error) {
        updateChannelAdditionalInfo(account);
        addToast({title: $_("saved")});
      } else {
        addToast({title: $_("error")});
      }
    } catch (e) {
      addToast({title: $_("error")});
    }
    changeAccountLoader = false;
    checkPristine();
  };

  $: if (settings) checkPristine();

  const initSettings = async () => {
    if (channel.apiUrl) {
      let requestSettings = await getRequest(`${channel.apiUrl}settings`, {
        token: channel.token,
      });
      if (requestSettings.webhookUrl == null) {
        requestSettings.webhookUrl = '';
      }
      let blocked = ["oldMessagesPeriod"];
      let tmp = {webhookUrl};
      for (let prop in requestSettings) {
        if (blocked.includes(prop)) continue;
        tmp[prop] = requestSettings[prop];
      }
      if (typeof tmp.webhookUrl === 'string') {
        tmp.webhookUrl = [tmp.webhookUrl];
      }
      settings = tmp;
      webhookUrl = tmp.webhookUrl;
      startSettings = Object.assign({}, tmp);
    } else {
      startSettings = {};
      settings = {}
    }
  };

  const initAccount = () => {
    for (const prop of props) {
      if(!account[prop]) account[prop] = null;
      if(channelAdditionalInfo[prop]) account[prop] = channelAdditionalInfo[prop];
    }
    if(channelAdditionalInfo.websites) websites = channelAdditionalInfo.websites;
  };

  $: if (channelAdditionalInfo && channel) {
    initAccount();
  }

  $: if (channel) {
    initSettings();
  };
</script>

{#if settings}
  <CardTitle class="mb-3 font-size-16">
    {$_('channel.blocks.settings.webhookUrl')}
  </CardTitle>
  <!-- <form data-parsley-validate="" on:submit|preventDefault={changeSettings}>
    <FormGroup class="mb-4" row>
      <Label htmlFor="webhook-url" md="2" class="col-form-label">Webhook URL:</Label>
      <Col md="8">
        <input type="text"  disabled={!channelAdditionalInfo?.accountStatus} bind:value={settings.webhookUrl} id="webhook-url" class="form-control"/>
        <div class="invalid-tooltip">
          {$_("errors.wrong_value")}
        </div>
      </Col>
      <Col md="2">
        <div class="text-md-end mt-2 mt-md-0">
          <Button class="btn btn-success btn-lg font-size-15">
            <i class="bx bx-save me-1" />{$_('save')}
          </Button>
        </div>
      </Col>
    </FormGroup>
  </form> -->
  <div class="mb-4 Webhook">
    {#each webhookUrl as url, i}
      <FormGroup class="d-flex align-items-center gap-3">
        <Input type="url" bind:value={url} placeholder="Webhook URL" disabled={disabledWebhookUrl.some(item => settings.webhookUrl[i]?.includes(item.name))}/>
        {#if !disabledWebhookUrl.some(item => settings.webhookUrl[i]?.includes(item.name))}
          <button class="border-0 rounded-pill d-flex align-items-center justify-content-center Webhook-deleteBtn {webhookUrl.length === 1 ? 'Webhook-deleteBtn-disabled' : ''}"
            on:click={() => deleteWebhookUrl(i)} disabled={webhookUrl.length === 1}>
            <i class="bx bx-trash font-size-18"/>
          </button>
        {:else}
          <img src={disabledWebhookUrl.find(item => url.includes(item.name)).image}
            class="rounded-circle Webhook-avatar object-fit-cover" alt="logo"/>
        {/if}
      </FormGroup>
    {/each}
    <div class="d-flex flex-wrap gap-2">
      {#if webhookUrl.length < 5}
        <Button outline color="success" size="lg" class="font-size-15"
          on:click={() => {webhookUrl = [...webhookUrl, ""]}}>
          {$_('+ Add')}
        </Button>
      {/if}
      <Button color="success" size="lg" class="font-size-15 d-flex align-items-center gap-2" on:click={changeSettings} disabled={changeSettingsLoader}>
        {#if changeSettingsLoader}
          <Spinner size="sm"/>
        {:else}
          <i class="bx bx-save d-none d-sm-inline-block"/>
        {/if}
        {$_('save')}
      </Button>
    </div>
  </div>
{/if}
{#if channelAdditionalInfo}
  <CardTitle class="mb-3 font-size-16">
    {$_('channel.blocks.settings.account')}
  </CardTitle>
  <form data-parsley-validate="" on:submit|preventDefault={changeAccount}>
    <FormGroup class="mb-4" row>
      <Label htmlFor="vertical" md="2" class="col-form-label">{$_('waba.vertical')}:</Label>
      <Col md="10">
        <select type="select" name="select" class="form-select form-select-lg" id="vertical" bind:value={account.vertical}>
          {#if channelAdditionalInfo?.isCloud}
            {#each verticalsIsCloud as vertical}
              <option value="{vertical}">{$_('waba.verticals.' + vertical)}</option>
            {/each}
          {:else}
            {#each verticals as vertical}
              <option value="{vertical}">{$_('waba.verticals.' + vertical)}</option>
            {/each}
          {/if}
        </select>
      </Col>
    </FormGroup>
    <FormGroup class="mb-4" row>
      <Label htmlFor="email" md="2" class="col-form-label">{$_('waba.email')}:</Label>
      <Col md="10">
        <input type="text" bind:value={account.email} id="email" class="form-control" maxlength=128/>
      </Col>
    </FormGroup>
    <FormGroup class="mb-4" row>
      <Label htmlFor="about" md="2" class="col-form-label">{$_('waba.about')}:</Label>
      <Col md="10">
        <input type="text" bind:value={account.about} id="about" class="form-control" required maxlength=139/>
      </Col>
    </FormGroup>
    <FormGroup class="mb-4" row>
      <Label htmlFor="address" md="2" class="col-form-label">{$_('waba.address')}:</Label>
      <Col md="10">
        <TextAreaAutosize bind:value={account.address} minRows={3} classes="text-dark" maxlength=256/>
      </Col>
    </FormGroup>
    <FormGroup class="mb-4" row>
      <Label htmlFor="description" md="2" class="col-form-label">{$_('waba.description')}:</Label>
      <Col md="10">
        <TextAreaAutosize bind:value={account.description} minRows={3} classes="text-dark" maxlength=512/>
      </Col>
    </FormGroup>
    <FormGroup class="mb-4" row>
      <Label md="2" class="col-form-label">Websites:</Label>
      <Col md="10">
        <input type="url" bind:value={websites[0]} class="form-control mb-3" maxlength=256/>
        <input type="url" bind:value={websites[1]} class="form-control" maxlength=256/>
      </Col>
    </FormGroup>
    <FormGroup class="mb-4" row>
      <Col md="2"/>
      <Col md="10" md-offset="2">
        <Button type=submit color="success" size="lg" class="d-flex align-items-center gap-2" disabled={changeAccountLoader}>
          {#if changeAccountLoader}
            <Spinner size="sm"/>
          {:else}
            <i class="bx bx-save d-none d-sm-inline-block"/>
          {/if}
          {$_('save')}
        </Button>
      </Col>
    </FormGroup>
  </form>
{/if}

<style>
  .Webhook .Webhook-avatar, .Webhook .Webhook-deleteBtn {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  @media(max-width: 576px) {
    .Webhook .Webhook-avatar, .Webhook .Webhook-deleteBtn {
      width: 35px;
      min-width: 35px;
      height: 35px;
    }
  }

  .Webhook .Webhook-deleteBtn {
    color: #F46A6A;
    background-color: #fde8e8;
  }

  .Webhook .Webhook-deleteBtn:not(.Webhook-deleteBtn-disabled):hover {
    background-color: #fdd4d4;
  }

  .Webhook-deleteBtn-disabled {
    opacity: 0.6;
  }
</style>
