// Authentication related pages
import Login from "../pages/Authentication/Login.svelte";
import Register from "../pages/Authentication/Register.svelte";
import ForgetPwd from "../pages/Authentication/ForgetPassword.svelte";
import Agreement from "../pages/Agreement/Agreement.svelte";
import Verification from "../pages/Verification/VerifyEmail.svelte";
import AccountInfoVerification from "../pages/Verification/AccountInfoVerification.svelte"
import ChatapiMigration from "../pages/ChatapiMigration/ChatapiMigration.svelte";

// Dashboard
import Channel from "../pages/Channel/index.svelte";
import Channels from "../pages/Channels/index.svelte";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index.svelte";
import Project from "../pages/Project/Project.svelte";
import Projects from "../pages/Projects/Projects.svelte";
import Profile from "../pages/Profile/Profile.svelte";
import Payments from "../pages/PaymentProfiles/index.svelte";
import Support from "../pages/Support/index.svelte";
import Upgrade from "../pages/Upgrade/index.svelte";
import Documentation from "../pages/Documentation/index.svelte";
import Documentation1Msg from "../pages/Documentation/1msg.svelte";
import DocumentationMeta from "../pages/Documentation/meta.svelte";
import EditPaymentProfile from "../Components/Modals/PaymentProfileEditModal.svelte";
import ChannelsPayments from "../pages/ChannelsPayments/index.svelte";
import EditChannelPayments from "../pages/ChannelPayment/index.svelte";
import MobileApp from "../pages/MobileApp/index.svelte";
import Flow from "../pages/Automation/Flow/index.svelte";
import Keyword from "../pages/Automation/Keyword/index.svelte";
import AutoReply from "../pages/Automation/AutoReply/index.svelte";
import Contacts from "../pages/Contacts/index.svelte";
import Leads from "../pages/Leads/index.svelte";
import UsersAndRoles from "../pages/UsersAndRoles/index.svelte";
import ChatInbox from "../pages/Inbox/index.svelte";
import Routing from "../pages/Routing/index.svelte";
import Billing from "../pages/Billing/index.svelte";
import TopUpBalance from "../pages/TopUpBalance/index.svelte";
import Trial from "../pages/Trial/index.svelte";
import ResetPasswordApp from "../pages/MobileApp/ResetPasswordApp.svelte";
import Segments from "../pages/Segments/index.svelte";
import EditSegment from "../pages/Segments/EditSegment.svelte";
import CreateSegment from "../pages/Segments/CreateSegment.svelte";
import Broadcast from "../pages/Broadcast/index.svelte";
import EditBroadcast from "../pages/Broadcast/editBroadcast.svelte";
import Layout from "./Layout.svelte";
import LayoutUnauth from "./LayoutUnauth.svelte";
import AdminToolbox from "../pages/AdminToolbox/index.svelte";
import {DEFAULT_ADMINISTRATOR_DASHBOARD, DEFAULT_LOGIN_PAGE, DEFAULT_OPERATOR_DASHBOARD} from "../common/constants";
import userTrialGuard from "../guards/userTrialGuard";
import Sandbox from "../pages/Testing/Layout.svelte";
import SDKGenerator from "../pages/SDKGenerator/index.svelte";
import FlowLibrary from "../pages/FlowLibrary/index.svelte";
import WhatsappLinkGenerator from "../pages/WhatsappLinkGenerator/index.svelte";
import WhatsappLinkGeneratorWrapper from "../pages/WhatsappLinkGenerator/indexWrapper.svelte";
import WhatsappChatButton from "../pages/WhatsappChatButton/index.svelte";
import WhatsappChatButtonWrapper from "../pages/WhatsappChatButton/indexWrapper.svelte";


const authProtectedRoutes = [
    {path: "/", component: ChatInbox, layout: Layout, guards:[userTrialGuard]},
    {path: DEFAULT_ADMINISTRATOR_DASHBOARD, component: ChatInbox, layout: Layout, guards:[userTrialGuard]},
    {path: "/account-info", component: AccountInfoVerification, layout: LayoutUnauth},
    {path: "/admintoolbox", component: AdminToolbox, layout: Layout},
    {path: "/agreement", component: Agreement, layout: LayoutUnauth},
    {path: "/automation/flow", component: Flow, layout: Layout, guards:[userTrialGuard]},
    {path: "/automation/flow-library", component: FlowLibrary, layout: Layout, guards:[userTrialGuard]},
    {path: "/automation/flow/:id", component: Flow, layout: Layout, guards:[userTrialGuard]},
    {path: "/automation/keyword", component: Keyword, layout: Layout, guards:[userTrialGuard]},
    {path: "/automation/auto-reply", component: AutoReply, layout: Layout, guards:[userTrialGuard]},
    {path: "/billing", component: Billing, layout: Layout},
    {path: "/broadcast", component: Broadcast, layout: Layout, guards:[userTrialGuard]},
    {path: "/broadcast/:id", component: EditBroadcast, layout: Layout, guards:[userTrialGuard]},
    {path: "/broadcast/add", component: EditBroadcast, layout: Layout, guards:[userTrialGuard]},
    {path: "/channels", component: Channels, layout: Layout},
    {path: "/channel/:id", component: Channel, layout: Layout},
    {path: "/channels-payments", component: ChannelsPayments, layout: Layout},
    {path: "/channels-payments/:id", component: EditChannelPayments, layout: Layout},
    {path: "/contacts", component: Contacts, layout: Layout, guards:[userTrialGuard]},
    /* {path: "/migration", component: Migration},*/
    {path: "/dashboard", component: Projects, layout: Layout},
    {path: "/documentation", component: Documentation, layout: Layout},
    {path: "/documentation/1msg", component: Documentation1Msg, layout: Layout},
    {path: "/documentation/meta", component: DocumentationMeta, layout: Layout},
    {path: "/ecommerce", component: EcommerceOrders, layout: Layout},
    {path: "/leads", component: Leads, layout: Layout, guards:[userTrialGuard]},
    {path: "/mobile-app", component: MobileApp, layout: Layout},
    {path: "/payments", component: Payments, layout: Layout},
    {path: "/payments/:id", component: EditPaymentProfile, layout: Layout},
    {path: "/profile", component: Profile, layout: Layout},
    {path: "/projects", component: Projects, layout: Layout},
    {path: "/project/:id", component: Project, layout: Layout},
    {path: "/roles", component: UsersAndRoles, layout: Layout, guards:[userTrialGuard]},
    {path: "/routing", component: Routing, layout: Layout, guards:[userTrialGuard]},
    {path: "/segments", component: Segments, layout: Layout, guards:[userTrialGuard]},
    {path: "/segments/edit/:id", component: EditSegment, layout: Layout, guards:[userTrialGuard]},
    {path: "/segments/create", component: CreateSegment, layout: Layout, guards:[userTrialGuard]},
    {path: "/segments/create/:id", component: CreateSegment, layout: Layout, guards:[userTrialGuard]},
    {path: "/support", component: Support, layout: Layout},
    {path: "/upgrade", component: Upgrade, layout: Layout},
    {path: "/sdk-generator", component: SDKGenerator, layout: Layout},
    {path: "/sdk-generator/java", component: SDKGenerator, layout: Layout, params: {key: 'java'}},
    {path: "/sdk-generator/javascript", component: SDKGenerator, layout: Layout, params: {key: 'javascript'}},
    {path: "/sdk-generator/c-sharp", component: SDKGenerator, layout: Layout, params: {key: 'c-sharp'}},
    {path: "/sdk-generator/php", component: SDKGenerator, layout: Layout, params: {key: 'php'}},
    {path: "/sdk-generator/python", component: SDKGenerator, layout: Layout, params: {key: 'python'}},
    {path: "/sdk-generator/c++", component: SDKGenerator, layout: Layout, params: {key: 'c++'}},
    {path: "/sdk-generator/typescript", component: SDKGenerator, layout: Layout, params: {key: 'typescript'}},
    {path: "/sdk-generator/c", component: SDKGenerator, layout: Layout, params: {key: 'c'}},
    {path: "/top-up-balance", component: TopUpBalance, layout: Layout},
    {path: "/trial", component: Trial, layout: Layout},
    {path: "/testing/:param", component: Sandbox, layout: Layout, guards:[userTrialGuard]},
    {path: "/verification", component: Verification, layout: LayoutUnauth},
    {path: "/whatsapp-link-generator", component: WhatsappLinkGeneratorWrapper, layout: Layout},
    {path: "/whatsapp-chat-button", component: WhatsappChatButtonWrapper, layout: Layout}
];

const operatorAllowedRoutes = [
    {path: DEFAULT_OPERATOR_DASHBOARD, component: ChatInbox},
    {path: "/app/acceptInvite", component: ResetPasswordApp},
    {path: "/app/restore", component: ResetPasswordApp},
    {path: "/contacts", component: Contacts},
    {path: "/documentation", component: Documentation},
    {path: "/mobile-app", component: MobileApp, layout: Layout},
    {path: "/profile", component: Profile},
    {path: "/routing", component: Routing},
    {path: "/support", component: Support},
    {path: "/testing/:param", component: Sandbox, layout: Layout},
    {path: "/sdk-generator", component: SDKGenerator, layout: Layout},
    {path: "/sdk-generator/java", component: SDKGenerator, layout: Layout, params: {key: 'java'}},
    {path: "/sdk-generator/javascript", component: SDKGenerator, layout: Layout, params: {key: 'javascript'}},
    {path: "/sdk-generator/c-sharp", component: SDKGenerator, layout: Layout, params: {key: 'c-sharp'}},
    {path: "/sdk-generator/php", component: SDKGenerator, layout: Layout, params: {key: 'php'}},
    {path: "/sdk-generator/python", component: SDKGenerator, layout: Layout, params: {key: 'python'}},
    {path: "/sdk-generator/c++", component: SDKGenerator, layout: Layout, params: {key: 'c++'}},
    {path: "/sdk-generator/typescript", component: SDKGenerator, layout: Layout, params: {key: 'typescript'}},
    {path: "/sdk-generator/c", component: SDKGenerator, layout: Layout, params: {key: 'c'}},
    {path: "/whatsapp-link-generator", component: WhatsappLinkGeneratorWrapper, layout: Layout},
    {path: "/whatsapp-chat-button", component: WhatsappChatButtonWrapper, layout: Layout}

];


const publicRoutes = [
    {path: DEFAULT_LOGIN_PAGE, component: Login, layout: LayoutUnauth},
    {path: "/auth-recoverpw", component: ForgetPwd, layout: LayoutUnauth},
    {path: "/migration",  component: ChatapiMigration, layout: LayoutUnauth},
    {path: "/register", component: Register, layout: LayoutUnauth},
    {path: "/whatsapp-link-generator-free", component: WhatsappLinkGenerator, layout: LayoutUnauth},
    {path: "/whatsapp-chat-button-free", component: WhatsappChatButton, layout: LayoutUnauth}
];


//все руты которые будут начинаться с /апп также будут пытаться открываться в приложении, если оно есть у юзеров
const dualRoutes = [
    {path: "/app/acceptInvite", component: ResetPasswordApp},
    {path: "/app/restore", component: ResetPasswordApp},

];

const MOCK_DATA = {
    authProtectedRoutes,
    publicRoutes,
    dualRoutes,
    operatorAllowedRoutes,
};

export default MOCK_DATA;
