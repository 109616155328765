<script>
  import {createEventDispatcher, onDestroy, onMount} from 'svelte';

  let container;
  let map;
  let dispatch = createEventDispatcher();

  export let initMarker;
  export let enableScrollWheel = false;
  export let enableMapMarkersOnClick = true;
  export let height = "200px";
  export let mapOptions = {
    zoom: 3,
    panControl: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    clickableIcons: true,
    keyboardShortcuts: false,
    scrollwheel: enableScrollWheel,
    disableDoubleClickZoom: false,
    fullscreenControl: true,
    draggableCursor: "pointer",
  };

  let marker;
  let defaultLocation = {lat: 57.8642403, lng: 63.9506995};

  const getBrowserLocation = () => {
    return new Promise((resolve, reject) => {
      if('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            const {latitude: lat, longitude: lng} = pos.coords;
            resolve({lat, lng});
          },
          reject,
        );
      } else {
        reject();
      }
    });
  };

  onMount(() => {
    setTimeout(async () => {
      if (enableScrollWheel) {
        await getBrowserLocation().then((curloc) => {
          initMarker = curloc;
          mapOptions.zoom = 10
        }).catch(() => {});
      }

      map = new google.maps.Map(container, {
        center: initMarker ? initMarker : defaultLocation,
        ...mapOptions
      });

      if (initMarker) {
        marker = new google.maps.Marker({
          position: initMarker,
          map: map,
        });
        dispatch("marker-placed", initMarker);
      }

      if (enableMapMarkersOnClick) {
        // // Add a click event listener to the map
        map.addListener("click", (event) => {
          // Remove the old marker
          if (marker) {
            marker.setMap(null);
          }
          const latLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };

          // Add a new marker
          marker = new google.maps.Marker({
            position: latLng,
            map: map,
          });
          dispatch("marker-placed", latLng);
        })
      }
    }, 100)
  });

  onDestroy(() => {
    // Clean up the marker and map instances
    if (marker) {
      marker.setMap(null);
    }
    if (map) {
      google.maps.event.clearInstanceListeners(map);
      if(marker) google.maps.event.clearInstanceListeners(marker);
      map = null;
    }
  });
</script>

<div style="width: 100%; height: {height}" bind:this={container}/>
