<script>
  import {onDestroy, onMount} from "svelte";
  import {navigate} from "svelte-routing";
  import data from "../layoutes";
  import {DEFAULT_ADMINISTRATOR_DASHBOARD, DEFAULT_LOGIN_PAGE, DEFAULT_OPERATOR_DASHBOARD} from "../common/constants";
  import {UnsubTrain} from "../helpers/utils";

  const agreementPath = "/agreement";


  /**
   * Represents a configuration object with various properties.
   * @typedef {(function(): function())|(function())|(function():boolean)} GuardFunction in a function that returns callback that will be called
   * in onDestroy, void function or a function that return boolean. If a false will be returned - user will be redirectid to his default user page
   */

  /**
   *
   * @type  {GuardFunction[]}
   */
  export let guardFunctions = [];

  let unsubTrain;
  let startAddress = new URL(window.location.href);

  onMount(() => {
    guardCheck();
    checkOtherGuards();
  });

  onDestroy(() => {
    unsubTrain&&unsubTrain.unsub();
  });


  function navigateLocal(path) {
    navigate(path, {replace: true})
  }

  function guardCheck() {
    if (!localStorage.getItem("authUser")) {
      if (startAddress.pathname.indexOf(DEFAULT_LOGIN_PAGE) === 0) return
      navigateLocal(DEFAULT_LOGIN_PAGE);
      // } else if (localStorage.getItem("verificationNeeded")) {
      //   if (startAddress.pathname.indexOf(verificationPath) === 0) return
      //   navigateLocal(verificationPath);
    } else if (localStorage.getItem("agreementNeeded")) {
      if (startAddress.pathname.indexOf(agreementPath) === 0) return
      navigateLocal(agreementPath)
    } else if (localStorage.getItem("isOperator")
      && !data.operatorAllowedRoutes.find(item => (startAddress.pathname.indexOf(item.path) !== -1))) {
      navigateLocal(DEFAULT_OPERATOR_DASHBOARD)
    }
  }

  function checkOtherGuards() {
    for (let i = 0; i < guardFunctions.length; i++) {
      if (typeof guardFunctions[i] === 'function') {
        let result = guardFunctions[i]();
        switch (typeof result) {
          case "boolean":
            if (!result) {
              if (localStorage.getItem("isOperator")) {
                navigateLocal(DEFAULT_OPERATOR_DASHBOARD)
              } else {
                navigateLocal(DEFAULT_ADMINISTRATOR_DASHBOARD)
              }
            }
            break;
          case "function":
            if(!unsubTrain){
              unsubTrain = new UnsubTrain();
            }
            unsubTrain.add = result;
        }
      }
    }

  }


</script>

<slot></slot>

