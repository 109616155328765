<script>
  import {
    Row,
    Col,
    Card,
    InputGroup,
    Input,
    Button,
    CardBody
  } from "sveltestrap";

  import {_, date} from "svelte-i18n";
  import {copyText, postRequest, getCookie, setCookie} from "../../helpers/utils";
  import {updateChannel} from "../../helpers/store";
  import {addToast} from "../../helpers/toast";
  import {defaultConst} from "../../common/constants";
  import Spinner from "../../Components/Spinners/SpinnerComponent.svelte";

  const copyToClipoard = (text) => {
    copyText(text);
    addToast({title: 'copied', message: text, dismissible: true, timeout: 2_000})
  }

  export let channel;
  export let user;
  // export let wabaInfo;
  // export let channelAdditionalInfo;

  let savingName = false;
  let reloadBlock = getCookie(`c_${channel?.id}.reload_block`);
  let inputActive = {};
  const startEdit = (name) => {
    inputActive = {
      name: name,
      value: channel[name] || "",
    };
  };

  const cancelEdit = () => {
    inputActive = {};
  };

  const changeProp = async () => {
    savingName = true;
    try {
      await updateChannel(channel.id, inputActive.name, inputActive.value);
    } catch (e) {
    }
    savingName = false;
    cancelEdit();
  };

  const reloadChannel = async () => {
    if (reloadBlock > Date.now()) {
      addToast({
        title: $_("error"),
        message: $_("channel.reload_block"),
        dismissible: true,
        timeout: 3_000,
      });
      return;
    }
    reloadBlock = Date.now() + 1000 * 60 * 10;
    setCookie(`c_${channel?.id}.reload_block`, reloadBlock, 1000 * 60 * 10);
    let result = await postRequest(`${defaultConst.functionsUrl}/reloadChannel/`, {
      channelId: channel.id,
      userToken: user.userToken
    });

    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
    } else {
      addToast({title: $_("saved")});
    }
  };
</script>

{#if channel}
  <div class="header-title d-flex align-items-center">
    <div class="text-channel-id me-1">{channel.id}</div>
    <div class="text-truncate">
      {#if inputActive.name === "name"}
        <form class="row row-cols-lg-auto g-3 align-items-center" on:submit|preventDefault={changeProp}>
          <div class="col-12 col-gt-sm-6">
            <InputGroup>
              <Input bind:value={inputActive.value} type="text" class="form-control" placeholder={$_("channel.blocks.main.name")} required/>
            </InputGroup>
          </div>
          <div class="col-6 col-gt-sm-6">
            {#if savingName}
              <Spinner/>
            {:else }
              <Button type="submit" color="success" class="btn-sm">
                <i class="mdi mdi-check"/>
              </Button>
              <Button type="reset" color="danger" class="btn-sm" on:click={cancelEdit}>
                <i class="mdi mdi-close"/>
              </Button>
            {/if}
          </div>
        </form>
      {:else}
        <button on:click={() => startEdit("name")} class="font-size-20 fw-600 border-0 p-0 bg-transparent text-start color-inherit">
          {channel.name || '-'}
        </button>
      {/if}
    </div>
  </div>
  <Card>
    <CardBody>
      <button type="button" title={$_("channel.unavailable.reload")}
        class="position-absolute top-0 end-0 p-2 font-size-18 text-muted border-0 bg-transparent"
        class:opacity-75={reloadBlock} on:click={reloadChannel}>
        <i class="bx bx-reset"/>
      </button>
      <Row>
        <Col sm="8">
          <div>
            <span class="bold">API url:</span> {channel.apiUrl}
            <button type="button" class="p-0 border-0 bg-transparent" on:click={() => copyToClipoard(channel.apiUrl)}>
              <i class="bx bx-copy-alt font-size-18 text-muted opacity-75 align-text-top custom-hover-primary"/>
            </button>
          </div>
          <div class="pt-3">
            <span class="bold">API key:</span> {channel.token}
            <button type="button" class="p-0 border-0 bg-transparent" on:click={() => copyToClipoard(channel.token)}>
              <i class="bx bx-copy-alt font-size-18 text-muted opacity-75 align-text-top custom-hover-primary"/>
            </button>
          </div>
        </Col>
        <Col sm="4">
          <div class="font-size-14 pt-1">
            {#if channel.status}
              {$_("channel.blocks.main." + channel.status)}
            {/if}
          </div>
          <div class="bold pt-2">
            {$_("channel.blocks.main.ending_date")}
            {$date(channel.activeTill, {format: "medium"})}
          </div>
        </Col>
      </Row>
      <!-- {#if (channelAdditionalInfo.anons || channelAdditionalInfo.description)}
        <div class="pt-3">
          {#if (channelAdditionalInfo.anons)}
            <p class="text-muted mb-0">{channelAdditionalInfo.anons}</p>
          {/if}
          {#if (channelAdditionalInfo.description)}
            <p class="text-muted mb-0">{channelAdditionalInfo.description}</p>
          {/if}
        </div>
      {/if} -->
    </CardBody>
  </Card>
{/if}

<style>
  .color-inherit {
    color: inherit;
  }
</style>