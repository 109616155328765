<script>
  import {Modal, ModalHeader, ModalBody,Button, Input, Spinner, Label} from "sveltestrap";
  import {channels, channelsAdditionalInfo, getChannelAdditionalInfo, appClient, addProjectUser, getProjectUsers, updateProjectUser} from "../../helpers/store";
  import {onDestroy} from "svelte";
  import {_} from "svelte-i18n";
  import {addToast} from "../../helpers/toast";
  import SelectDropdownCheckbox from "../SelectDropdownCheckbox.svelte";

  export let actionModal = "addUser";

  let title;
  let submitBtn;

  switch (actionModal) {
    case "addUser":
      title = "users.add_modal.title";
      submitBtn = "users.add_modal.add";
      break;
    case "updateChannel":
      title = "users.table.accessing_channels.title"
      submitBtn="users.table.accessing_channels.button"
      break;
  }

  export let showModal;
  export let onCloseClick;

  export let email = "";
  export let role = 2;
  export let is_active;
  export let shops_id = [];

  let loading = false;
  let channelsList = {};
  let channelsAdditionalInfoLocal = {};
  let selectChannelsList = [];
  let clientLocal = appClient;

  const getAddInfo = (channelsListLocal) => {
    let infoToUpdate = [];
    if (Array.isArray(channelsListLocal)) {
      channelsListLocal.forEach(item => {
        if (!channelsAdditionalInfoLocal.hasOwnProperty(item.id)) {
          infoToUpdate.push(item);
        }
      })
      getChannelAdditionalInfo(infoToUpdate);
    }
  };

  const unsub2 = channels.subscribe(async (value) => {
    getAddInfo(value);
    channelsList = Array.isArray(value) && value.length ? value.reduce(
      (result, item) => {
        if (!result[item.projectId]) result[item.projectId] = []
        if (item.appData)
          result[item.projectId].push(item);
        return result;
      },
      {}
    ) : {};
  });

  const unsub = channelsAdditionalInfo.subscribe(value => {
    let reworkedValue = value;
    for (let i in reworkedValue) {
      if (reworkedValue[i].hasOwnProperty('id')) {
        reworkedValue[i].id = reworkedValue[i].id.split("@").shift();
      }
    }
    channelsAdditionalInfoLocal = value;
  });

  $: if (!selectChannelsList.length && Object.keys(channelsList).length && Object.keys(channelsAdditionalInfoLocal).length) {
    let arr = [];
    for (const i in channelsList) {
      for(const j in channelsList[i]) {
        const channel = channelsList[i][j];
        const { shopId } = clientLocal.getCommonVars(channel);
        const checked = !!shops_id.find(id => id === shopId);
        arr.push({
          id: channel.id,
          shopId,
          label: `${channel.name} (${channelsAdditionalInfoLocal?.[channel.id]?.phone ? "+" + channelsAdditionalInfoLocal?.[channel.id]?.phone : '-'})`,
          checked: checked
        });
      }
    }
    selectChannelsList = arr;
  }

  onDestroy(() => {
    unsub();
    unsub2();
  });

  const shopsIdFormatting = () => {
    return selectChannelsList.reduce((acc, item) => {
      if (item.checked) {
        return [...acc, item.shopId];
      }
      return acc;
    }, []);
  }

  const addUser = async () => {
    loading = true;
    const shops_id = shopsIdFormatting();
    const res = await addProjectUser(email, role, shops_id);

    if (!res || res.error) {
      loading = false;
      addToast({
        title: $_("error"),
        message: res.error || $_("something_went_wrong")
      });
    } else if (res.status === 'ok') {
      getProjectUsers();
      loading = false;
      onCloseClick();
      showModal = false;
      addToast({title: $_("users.add_modal.add_user_toast")});
    }
  };

  const updateChannel = async () => {
    loading = true;
    const shops_id = shopsIdFormatting();
    const res = await updateProjectUser({email, role, is_active, shops_id});

    if (!res || res.error) {
      loading = false;
      addToast({
        title: $_("error"),
        message: res.error || $_("something_went_wrong")
      });
    } else if (res.status === 'ok') {
      getProjectUsers();
      loading = false;
      onCloseClick();
      showModal = false;
      addToast({title: $_("users.table.accessing_channels.message")});
    }
  }

  const handleSubmit = () => {
    if (actionModal == "addUser") {
      addUser();
    }

    if (actionModal == "updateChannel") {
      updateChannel();
    }
  };
</script>

<Modal isOpen={showModal} fade={true} toggle={onCloseClick} size="md" centered>
  <ModalHeader toggle={onCloseClick} class="border-bottom-0 pb-0">
    <span class="font-size-20 fw-bold">{$_(title)}</span>
  </ModalHeader>
    <ModalBody class={`d-flex ${!selectChannelsList.length ? 'align-items-center justify-content-center' : ''}`} style="min-height: 240px">
      {#if selectChannelsList.length}
        <form on:submit|preventDefault={handleSubmit} class="w-100 pe-lg-4">
          <div class="form-group mb-3">
            <Label for="email">{$_('users.add_modal.email')}</Label>
            <Input type="email" id="email" bind:value={email} placeholder="mail@example.com" required disabled={actionModal == "updateChannel"}/>
          </div>
          <div class="form-group mb-3">
            <Label>{$_('users.add_modal.channel')}</Label>
            <SelectDropdownCheckbox
              title="SelectDropdownCheckbox.select_a_channel"
              bind:list={selectChannelsList}
              required={true}/>
          </div>
          <Button type="submit" color="success" disabled={loading} class="btn-lg waves-effect waves-light">
            {#if loading}
              <Spinner size="sm" />
            {/if}
            {$_(submitBtn)}
          </Button>
        </form>
        <img src="/assets/images/icons/Twotone.svg" alt="" class="d-none d-lg-inline-block mt-5" style="width: 108px; height: 108px"/>
      {:else}
        <Spinner />
      {/if}
    </ModalBody>
</Modal>
