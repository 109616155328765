<script>
  import {_} from "svelte-i18n";
  import {SwiperSlide} from 'swiper/svelte';
  import SliderModal from "./SliderModal.svelte";
  import {
    appClient,
    channels,
    channelsAdditionalInfo,
    chosenChannel, currentPath,
    getChannelAdditionalInfo, getOnetouchShops, onetouchShops,
    projects, setChosenChannel, showProjectSelectModal, user
  } from "../../helpers/store";
  import {onDestroy} from "svelte";
  import {isUserRegisteredInOneTouch, showShops, UnsubTrain} from "../../helpers/utils";

  let projectsList, channelsList, projectsListFilter = [], channelsListFull, oneTouchShops = [];

  let channelsAdditionalInfoLocal = {};
  let currentChoice;


  let show = false;
  let isChannelsInitialized = false;

  let oneTouchShopsLoading = false;
  let isOneTouchShopsInitialized = false;
  let showOneTouchShops = false;

  let unsubTrain = new UnsubTrain();

  let activeUser;

  let userRegisteredInHightouch = false;

  unsubTrain.add = showProjectSelectModal.subscribe(value => {
    show = value
  });

  unsubTrain.add = chosenChannel.subscribe(value => {
    currentChoice = value;
    if (value === null) {
      showProjectSelectModal.set(true);
    } else {
      showProjectSelectModal.set(false);
    }
  });

  unsubTrain.add = user.subscribe(value => {
    activeUser = value;
    const newFlag = isUserRegisteredInOneTouch(value);
    if(newFlag && !userRegisteredInHightouch){
      loadHightouchShops()
    }
  });

  unsubTrain.add = projects.subscribe(value => {
    projectsList = value;
  });

  unsubTrain.add = onetouchShops.subscribe(value => {
    isOneTouchShopsInitialized = Array.isArray(value) && value.length
    oneTouchShops = Array.isArray(value) ? value.reduce((acc, item) => {
      const name = parseId(item?.trade_name)
      const someVar = {appData: {shop_id: item?.id, dev_shop_id: item?.id}, id: name, name}
      acc.push(someVar);
      return acc
    }, []) : [];
    filterOneTouchShops()
    checkForOneChannel()
  });

  unsubTrain.add = channelsAdditionalInfo.subscribe(value => {
    let reworkedValue = value;
    for (let i in reworkedValue) {
      if (reworkedValue[i].hasOwnProperty('id')) {
        reworkedValue[i].id = reworkedValue[i].id.split("@").shift();
      }
    }
    channelsAdditionalInfoLocal = value;
  });

  unsubTrain.add = channels.subscribe(value => {
    channelsListFull = value;
    filterOneTouchShops();
    getAddInfo(value);
    channelsList = Array.isArray(value) && value.length ? value.reduce(
      (result, item) => {
        if (!result[item.projectId]) result[item.projectId] = []
        if (item.appData)
          result[item.projectId].push(item);
        return result;
      },
      {}
    ) : {};
    isChannelsInitialized = true;
    channelsList = channelsList
    checkForOneChannel()
  });

  unsubTrain.add = currentPath.subscribe(value => {
    showOneTouchShops = showShops(value);
    if (showOneTouchShops) {
      loadHightouchShops();
    } else {
      isOneTouchShopsInitialized = true
    }
  });

  function filterOneTouchShops() {
    if (showOneTouchShops && oneTouchShops?.length && channelsListFull?.length) {
      oneTouchShops = oneTouchShops.filter(item => !Array.isArray(channelsListFull) || !channelsListFull.find(list => appClient.getCommonVars(item)?.shopId === appClient.getCommonVars(list)?.shopId))
    }
  }

  function parseId(tradeName) {
    if (!tradeName) return ''
    const first = tradeName.indexOf('#') + 1;
    const second = tradeName.indexOf('_', first)
    return tradeName.toString().slice(first, second)
  }

  function getAddInfo(channelsListLocal) {
    let infoToUpdate = [];
    if (Array.isArray(channelsListLocal)) {
      channelsListLocal.forEach(item => {
        if (!channelsAdditionalInfoLocal.hasOwnProperty(item.id)) {
          infoToUpdate.push(item);
        }
      })
      getChannelAdditionalInfo(infoToUpdate);
    }
  }

  function setCurrentChannel(channel) {
    setChosenChannel(channel)
  }

  function setCurrentChoice(channel) {
    currentChoice = channel
  }

  function checkForOneChannel() {
    if (isChannelsInitialized && isOneTouchShopsInitialized) {
      if ((!Array.isArray(oneTouchShops) || oneTouchShops.length === 0)) {
        let last;
        for (let channelsListKey in channelsList) {
          const item = channelsList[channelsListKey];
          if (item.length > 1 || last && item.length) {
            break
          } else if (item.length === 1) {
            last = item[0]
          }
        }
        last && setCurrentChannel(last)
      }
    }
  }

  function toggle() {
    if (!Object.keys(channelsList).length || show && activeUser?.appToken && currentChoice?.id) {
      showProjectSelectModal.set(false);
    }
  }

  function loadHightouchShops() {
    if (oneTouchShopsLoading) return;
    oneTouchShopsLoading = true;
    getOnetouchShops().then(() => {
      isOneTouchShopsInitialized = true
      oneTouchShopsLoading = false
    }, () => {
      isOneTouchShopsInitialized = true
      oneTouchShopsLoading = false
    })
  }

  onDestroy(() => {
    unsubTrain.unsub();
    showProjectSelectModal.set(false);
  });

  $: if (projectsList) {
    projectsListFilter = projectsList.filter(project => channelsList[project.id] && channelsList[project.id].length !== 0);
  }
</script>

<SliderModal
  breakpoints={
    showOneTouchShops ?
    ([...projectsListFilter, ...oneTouchShops]?.length >= 3 ? {1170: {slidesPerView: 2}, 1640: {slidesPerView: 3}} :
    [...projectsListFilter, ...oneTouchShops]?.length == 2 ? {1170: {slidesPerView: 2}} : {}) :
    (projectsListFilter?.length >= 3 ? {1170: {slidesPerView: 2}, 1640: {slidesPerView: 3}} :
    projectsListFilter?.length == 2 ? {1170: {slidesPerView: 2}} : {})
  }
  contentLoader={showOneTouchShops ? projectsList && !oneTouchShopsLoading : projectsList}
  onCloseClick={toggle}
  show={show && (projectsListFilter?.length || showOneTouchShops && oneTouchShops?.length) && appClient.getCommonVars().userToken}
  title={$_('choose_the_channel.title')}>
  {#if showOneTouchShops && oneTouchShops?.length}
    <SwiperSlide>
      <div class="card ChooseTheChannel">
        <div class="card-body p-3 p-md-4">
          <div class="d-flex align-items-md-center mb-2 flex-column flex-md-row gap-2">
            <div class="wh-62">
              <span class="avatar-title rounded-circle bg-light">
                <img src="/assets/images/projects/{7}.svg" alt="project avatar"/>
              </span>
            </div>
            <div>
              <div class="mb-2">{$_('project.text')}</div>
              <h5 class="mb-0 fw-bold">{$_('ProjectsDropdown.operator')}</h5>
            </div>
          </div>
          <div class="table-responsive">
            <table class="align-middle table-nowrap table-hover table mb-0">
              <thead>
              <tr>
                <th class="align-middle fw-normal ps-4">ID</th>
                <th class="align-middle">{$_('choose_the_channel.table.thead.channel_name')}</th>
                <th class="align-middle">{$_('choose_the_channel.table.thead.phone_number')}</th>
              </tr>
              </thead>
              <tbody>
              {#each oneTouchShops as channel}
                <tr class="pointer {currentChoice?.id === channel.id ? 'custom-table-active-gray': ''}"
                  on:click={setCurrentChoice(channel)}>
                  <td class="border-0 d-flex align-items-end gap-1 fw-500">
                    <i class="bx bxl-whatsapp text-success font-size-22"/>
                    {channel.id}
                  </td>
                  <td class="border-0 fw-500">
                    {channel.name || '-'}
                  </td>
                  <td class="border-0">
                    {channelsAdditionalInfoLocal?.[channel.id]?.phone ? "+" + channelsAdditionalInfoLocal?.[channel.id]?.phone : '-'}
                  </td>
                </tr>
              {/each}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </SwiperSlide>
  {/if}
  {#if projectsList}
    {#each projectsListFilter as project}
      <SwiperSlide>
        <div class="card ChooseTheChannel">
          <div class="card-body p-3 p-md-4">
            <div class="d-flex align-items-md-center mb-2 flex-column flex-md-row gap-2">
              <div class="wh-62">
                <span class="avatar-title rounded-circle bg-light">
                  {#if false}
                    <img src="#" alt="project avatar"/>
                  {:else}
                    <img src="/assets/images/projects/{7}.svg" alt="project avatar"/>
                  {/if}
                </span>
              </div>
              <div>
                <div class="mb-2">{$_('project.text')}</div>
                <h5 class="mb-0 fw-bold">{project.name || '-'}</h5>
              </div>
            </div>
            {#if channelsList[project.id]}
              <div class="table-responsive">
                <table class="align-middle table-nowrap table-hover table mb-0">
                  <thead>
                  <tr>
                    <th class="align-middle fw-normal ps-4">ID</th>
                    <th class="align-middle">{$_('choose_the_channel.table.thead.channel_name')}</th>
                    <th class="align-middle">{$_('choose_the_channel.table.thead.phone_number')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {#each channelsList[project.id] as channel}
                    <tr class="pointer {currentChoice?.id === channel.id ? 'custom-table-active-gray': ''}"
                      on:click={setCurrentChoice(channel)}>
                      <td class="border-0 d-flex align-items-end gap-1 fw-500">
                        <i class="bx bxl-whatsapp text-success font-size-22"></i>
                        {channel.id}
                      </td>
                      <td class="border-0 fw-500">
                        {channel.name || '-'}
                      </td>
                      <td class="border-0">
                        {channelsAdditionalInfoLocal?.[channel.id]?.phone ? "+" + channelsAdditionalInfoLocal?.[channel.id]?.phone : '-'}
                      </td>
                    </tr>
                  {/each}
                  </tbody>
                </table>
              </div>
            {:else}
              <div class="text-center fw-500">{$_('choose_the_channel.no_channels')}</div>
            {/if}
          </div>
        </div>
      </SwiperSlide>
    {/each}
  {/if}
  <div class="text-center" slot="footer">
    {#if currentChoice && Object.keys(currentChoice)?.length}
      <button on:click={setCurrentChannel(currentChoice)} type="button"
        class="btn btn-success mt-4 font-size-16 fw-500 px-3 px-md-5">{$_('choose_the_channel.btn_success')}</button>
    {/if}
  </div>
</SliderModal>

<style>
  .ChooseTheChannel {
    max-width: 800px;
    margin: 0 auto;
  }

  .card-body {
    padding: 19px;
  }

  .table-responsive {
    overflow-y: auto;
    max-height: 276px;
  }

  .table-responsive::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .table-responsive::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: transparent;
  }

  .table-responsive::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #DBDBDD;
  }

  .wh-62 {
    width: 62px;
    min-width: 62px;
    height: 62px;
  }
</style>
