import {get, writable} from "svelte/store";
import {user} from "../../helpers/store";


/** @typedef {object} UserHintsHelper
 */

export class UserHintsHelper {
  /**
   * The `hintName` variable is used to store the name of a hint or clue.
   * It provides a convenient way to refer to a hint or clue within the code.
   * The value of `hintName` should be a string.
   *
   * @type {string}
   * @default ''
   */
  hintName;
  /**
   * Represents the current number of items.
   *
   * @type {number}
   * @default -1
   */
  currentNumberOfItems = -1;
  /**
   *
   * @type {import('svelte/store').Writable<boolean[]>}
   */
  openFlagWritableArray = writable([])
  /**
   *
   * @type {import('svelte/store').Writable<boolean[]>}
   */
  doneWritableArray = writable([])


  /**
   * @type {string}
   */
  uid = get(user)?.id??''

  /**
   *
   * @param {string} hintName - uniq value for a hint
   */
  constructor(hintName) {
    this.hintName = hintName;
  }

  #addSmthToArray(value, array) {
    let _smthArray = get(array)
    if (this.currentNumberOfItems >= 0 && this.currentNumberOfItems >= _smthArray.length) {
      _smthArray.push(value)
      array.set(_smthArray)
    }
  }

  /**
   *
   * @param index starts from 0
   * @param value
   * @param {import('svelte/store').Writable<boolean[]>} array
   * @returns {boolean}
   */
  #changeSmthInArray(index, value, array) {
    let _smthArray = get(array)
    if (index >= 0 && index < _smthArray.length) {
      _smthArray[index] = value !== null ? value : !_smthArray[index]
      array.set(_smthArray)
      return _smthArray[index]
    }
    return false
  }

  #getLocalKey(index) {
    return `${this.uid}_${this.hintName}_hint_local_values_${index}`
  }

  /**
   *
   * @param {number} index starts from 0
   * @returns {boolean}
   */
  #getLocalUserValue(index) {
    return !!localStorage.getItem(this.#getLocalKey(index))
  }

  /**
   * Sets the value for a local user key in the localStorage.
   *
   * @param {number} index - The index or key to identify the value in the localStorage. starts from 0
   * @param {boolean} value - The value to be set. If value is falsy, an empty string will be used.
   */
  #setLocalUserValue(index, value) {
    localStorage.setItem(this.#getLocalKey(index), value ? `${value}`:'')
  }

  /**
   * Adds an item to an array.
   * @returns {number} The current number of items in the array.
   */
  addItemToArray() {
    this.currentNumberOfItems++;
    this.#addSmthToArray(this.#getLocalUserValue(this.currentNumberOfItems), this.openFlagWritableArray)
    this.#addSmthToArray(false, this.doneWritableArray)
    return this.currentNumberOfItems;
  }

  /**
   * Changes the done array at the specified index with the given value.
   *
   * @param {number} index - The index of the flag array to change. starts from 0
   * @param {*} [value=null] - The value to assign to the flag array. Defaults to toggle option if not provided.
   *
   * @return {void}
   */
  changeDoneArray(index, value = null) {
    this.#changeSmthInArray(index, value, this.doneWritableArray)
  }

  /**
   * Changes the flag array at the specified index with the given value.
   *
   * @param {number} index - The index of the flag array to change. starts from 0
   * @param {*} [value=null] - The value to assign to the flag array. Defaults to toggle option if not provided.
   *
   * @return {void}
   */
  changeFlagArray(index, value = null) {
    let res = this.#changeSmthInArray(index, value, this.openFlagWritableArray)
    this.#setLocalUserValue(index, res)
  }

  /**
   * Deletes an item from the array.
   */
  deleteItemFromArray() {
    this.#changeSmthInArray(this.currentNumberOfItems, false, this.doneWritableArray)
    this.#changeSmthInArray(this.currentNumberOfItems, false, this.openFlagWritableArray)
    this.currentNumberOfItems--;
  }


}
