<script>
  import {getAudioContext} from 'svelte-audio-player';
  import {toggle, toHHMMSS} from 'svelte-audio-player/utils';

  const PLAYBACK_SPEEDS = [1, 1.5, 2];
  const {playing, playbackRate, paused, currentTime, duration} = getAudioContext();

  let speedIndex = 0;
  const handlePlaybackSpeedClick = () => {
    $playbackRate = PLAYBACK_SPEEDS[++speedIndex % PLAYBACK_SPEEDS.length];
  };
</script>

<div class="d-flex align-items-center gap-2 rounded-3 w-100 wrapper">
  <div class="pointer icon" on:click={handlePlaybackSpeedClick}>
    <span class="avatar-title rounded-circle font-size-16 position-relative">
      <i class="bx bx-headphone text-white"></i>
      <span class="position-absolute font-size-11">{$playbackRate}x</span>
    </span>
  </div>
  <div class="w-100">
    <div class="d-flex align-items-center gap-1">
      <div class="pointer font-size-18 ps-1 pe-2" on:click={() => toggle(paused)}>
        {#if $playing}
          <i class="fas fa-pause d-block"></i>
        {:else}
          <i class="fas fa-play d-block"></i>
        {/if}
      </div>
      <div class="w-100 d-flex flex-column">
          <input
            bind:value={$currentTime}
            min={0}
            max={$duration}
            step={0.01}
            type="range"
            class="w-100 mt-2"
            style={`background-size: ${($currentTime * 100) / $duration}% 100%`}
          />
        <div class="d-flex justify-content-between mt-2">
          <span class="font-size-11 time">{toHHMMSS($currentTime)}</span>
          <span class="font-size-11 time">{toHHMMSS($duration)}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .wrapper {
    padding: 10px;
    background-color: #EFF2F7;
  }

  .time {
    color: rgba(0, 0, 0, 0.4);
  }

  .icon {
    min-width: 38px;
    height: 38px;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }

  .icon>span>span {
    visibility: hidden;
  }

  .icon:hover i {
    visibility: hidden;
  }

  .icon:hover>span>span {
    visibility: visible;
  }

  .avatar-title {
    background-color: #0CA970;
  }

  input {
    appearance: none;
    height: 6px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-image: linear-gradient(#495057, #495057);
    background-size: 0 100%;
    background-repeat: no-repeat;
  }

  input::-webkit-slider-thumb {
    appearance: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #495057;
    cursor: ew-resize;
    transition: background .3s ease-in-out;
  }

  input::-moz-range-thumb {
    appearance: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #495057;
    cursor: ew-resize;
    transition: background .3s ease-in-out;
  }

  input::-ms-thumb {
    appearance: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #495057;
    cursor: ew-resize;
    transition: background .3s ease-in-out;
  }

  input::-webkit-slider-thumb:hover {
    background: #495057;
  }

  input::-moz-range-thumb:hover {
    background: #495057;
  }

  input::-ms-thumb:hover {
    background: #495057;
  }

  input::-webkit-slider-runnable-track  {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input::-moz-range-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input::-ms-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
</style>
