<script>
    import {Link, navigate} from "svelte-routing";
    import {
        Container, Row, Col,
        Card,
        CardBody,
        CardTitle,
        CardText,
        Button,
        Input,
        InputGroup,
        Label
    } from "sveltestrap";
    import {_} from "svelte-i18n";

    import {toolbar} from "../../services/loaders";
    import {addToast} from "../../helpers/toast";
    import {defaultConst} from "../../common/constants";
    import {user} from "../../helpers/store";
    import { auth } from '../../helpers/firebase';
    import {postRequest} from "../../helpers/utils";

    import {onDestroy} from "svelte";
    import {copyText} from "../../helpers/utils";

    let activeUser = {};
    let tokenLoading = false;
    let saving = false;
    let agree = false;
    const unsub = user.subscribe((value) => {
        activeUser = value;
    });
    onDestroy(() => {
        unsub();
    })

    const submit = async () => {
        if (saving || !agree) return;
        saving = true;
        toolbar(saving)
        const params = {
            userToken: activeUser.userToken,
            property: 'agreementNeeded',
            value: false
        };

        let result;

        try {
            result = await postRequest(
                `${defaultConst.functionsUrl}/updateUser`,
                params
            );
        } catch (e) {
        }
        saving = false;
        toolbar(saving)
        if (!result || result.error) {
            addToast({
                title: $_("error"),
                message: result.error || $_("something_went_wrong"),
                dismissible: true,
                timeout: 4_000,
            });
        } else {
            addToast({title: $_("saved")});
            setTimeout(() =>{ navigate("/migration");}, 50)


        }
    };
    const handleLogOut = () => {
        auth.signOut().then(
            function () {
                // Sign-out successful.
                setTimeout(() =>{  navigate('/login')}, 50);
            },
            function (error) {
                // An error happened.
                console.warn('error on logout', error.message);
            }
        );
    };

</script>


<div class="page-content support">
    <Container fluid>
        <Col class="mx-auto" xxl="4" xl="6">
            <Card class="card-support">
                <CardBody>
                    <div class="flex-row-line">
                        <div class="avatar">

                            <img src="assets/images/logo-1msg-dark.svg" class="logo" alt="avatar" >
                        </div>
                        <div class="support-content">
                            <div class="head-title">{$_('agreement.header')}</div>
                        </div>
                    </div>
                    <div class="flex-row-line">
                        <div>
                            {@html $_('agreement.text')}
                        </div>
                    </div>
                    <form>
                        <div class="form-check">
                            <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="remember-check"
                                    bind:checked={agree}
                            />
                            <Label
                                    class="form-check-label"
                                    for="remember-check"
                            >
                                {$_('agreement.label')}

                            </Label>
                        </div>

                        <button
                                on:click={()=>{submit()}}
                                type="button"
                                class="btn btn-outline-success mt-4"
                        >
                            {#if saving}
                                <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            {/if}
                            {$_('agreement.ok')}
                        </button>

                        <button
                                on:click={handleLogOut}
                                type="button"
                                class="btn btn-outline-info mt-4"
                        >
                            {$_('agreement.return')}
                        </button>
                    </form>
                </CardBody>
            </Card>
        </Col>
    </Container>
</div>

