<script>
  import {Dropdown, DropdownMenu, DropdownToggle} from "sveltestrap";
  import {_} from "svelte-i18n";

  export let list;
  export let title;
  export let callback = () => {};
  export let outline = true;
  export let color = 'success';

  let select_all = false;

  const check = () => {
    select_all = list.every(item => item.checked === true);
  };

  const toggle = () => {
    list = list.map(item => ({...item, checked: select_all}));
    check();
  };

  $: list && check();
</script>

<div class="SelectDropdownFilter d-inline-block {!list.length ? 'disabled' : ''}">
  <Dropdown autoClose={false}>
    <DropdownToggle type="button" {outline} {color} disabled={!list.length} class="py-2 px-3 d-flex {!list.length ? 'pe-none' : ''}">
      <i class="bx bx-filter me-2 font-size-20"/>
      <span class="font-size-14">{$_(title)}</span>
      <i class="bx bx-chevron-down ms-2 font-size-20"/>
    </DropdownToggle>
    {#if list.length}
      <DropdownMenu end>
        <div class="scrollbar">
          <div>
            {#if list.length > 1}
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="dropdown-item m-0">
                <div class="form-check text-truncate ps-4">
                  <input class="form-check-input" type="checkbox" bind:checked={select_all} on:change={() => {
                    toggle();
                    callback();
                  }}/>
                  <span class="form-check-label">{$_('SelectDropdown.select_all')}</span>
                </div>
              </label>
            {/if}
            {#each list as item}
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label class="dropdown-item m-0" title={item.name}>
                <div class="form-check text-truncate ps-4">
                  <input class="form-check-input" type="checkbox" bind:checked={item.checked} on:change={() => {
                    check();
                    callback();
                  }}/>
                  <span class="form-check-label">{item.name}</span>
                </div>
              </label>
            {/each}
          </div>
        </div>
      </DropdownMenu>
    {/if}
  </Dropdown>
</div>

<style>
  .SelectDropdownFilter .scrollbar {
    width: 245px;
    max-height: calc(100vh - 450px);
    overflow-y: auto;
  }

  .SelectDropdownFilter .scrollbar::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  .SelectDropdownFilter .scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .dropdown-item {
    color: #495057;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  .form-check-input:checked {
    background-color: #23B16D;
    border-color: #23B16D;
  }

  .form-check-input:focus {
    box-shadow: 0 0 0 0.15rem rgba(35,177,109,.25);
  }

  .disabled {
    opacity: .65;
  }

  @media (max-width: 992px) {
    .SelectDropdownFilter .scrollbar {
      width: 100%;
    }
  }
</style>