<script>
  import {onDestroy, onMount, setContext} from "svelte";
  import {checkHintsName, currentOpenHintStore, setAndOpenHintsWindow, deleteHintsName} from "../../helpers/hints";

  /**
   * @param {string} hintName - a name that will represent this hint in db
   */
  export let hintName;

  /**
   * @param {boolean} show - whether to show this component or not. does not lead to immediate show of component
   */
  export let show;

  setContext('hintName', {hintName});

  $:show && checkAndGo()
  const unsub = currentOpenHintStore.subscribe(() => {
    setTimeout(checkAndGo, 500)
  })

  function checkAndGo() {
    show && !$currentOpenHintStore && setAndOpenHintsWindow(hintName)
  }

  onMount(() => {
    setTimeout(()=>checkHintsName(hintName));
  })
  onDestroy(() => {
    unsub();
    deleteHintsName(hintName);
  });
</script>

{#if $currentOpenHintStore === hintName}
  <div>
    <div class="mb-3 rounded overflow-hidden HintsAccordion">
      <slot></slot>
    </div>
  </div>
{/if}
