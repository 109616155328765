<script>
  import {Col, Container, Row} from "sveltestrap";
  import Breadcrumb from "../../common/Breadcrumb.svelte";

  import {onMount, onDestroy, setContext} from "svelte";
  import {layoutLoad, projects, user} from "../../helpers/store";
  // Pages Components
  import ProjectDetail from "./ProjectDetail.svelte";
  import TeamMembers from "./TeamMembers.svelte";
  import Invites from "./Invites.svelte";
  import Channels from "./Channels.svelte";
  import {key} from "./context";
  import {timer} from "../../helpers/utils";

  export let params;
  let project;
  let projectsLocal = [];
  let isAdmin;

  setContext(key, {
    getActiveProject: () => project,
    getIsAdmin: () => isAdmin,
  });

  const updateProject = async () => {

    if (projectsLocal) {
      project = projectsLocal.find((item) => item.id === params.id);

      if (project) isAdmin = project.ownerId === $user.id;
    } else {
      project = false;
      isAdmin = false;
    }
  };

  $: if (params.id) updateProject();

  const unsub = projects.subscribe((value) => {
    projectsLocal = value;
    updateProject();
  });
  onDestroy(() => {
    unsub();
  })
</script>

{#if project}
  <div class="page-content">
    <Container fluid>
      <Row class="position-relative">
        <Col lg="5">
          <ProjectDetail {project}/>
        </Col>

        <!--        <Col class="blured" lg="4">-->
        <!--          <TeamMembers {project} {isAdmin}/>-->
        <!--        </Col>-->

        <!--        <Col class="blured" lg="3">-->
        <!--          <Invites {project} {isAdmin}/>-->
        <!--        </Col>-->
        <!--        <div-->
        <!--            class="position-absolute"-->
        <!--            style=" top: 50%;-->
        <!--        left: 70%;-->
        <!--        font-size: 40px;"-->
        <!--        >-->
        <!--          Coming soon-->
        <!--        </div>-->
      </Row>
      <Row>
        <div class="col-12">
          <Channels {project} {isAdmin}/>
        </div>
      </Row>
    </Container>
  </div>
{/if}
