<script>
  import {Button} from "sveltestrap";
  import {_} from "svelte-i18n";
  import WhatsappTemplatesModal from "../../../../Components/Modals/WhatsappTemplatesModal.svelte";

  /**
   * @type {BroadcastData}
   */
  export let data;
  export let disabled;
  export let required = false;

  let isOpenTemplates = false;

  function deleteChoice() {
    data.waba_template = {};
    data.waba_template_id = null;
  }
</script>

<div class="ChoseTemplate">
{#if !data.waba_template?.id}
  <div class="position-relative d-inline-block">
    {#if required}
      <select class="w-100 h-100" required={true}/>
    {/if}
    <Button outline color="success" type="button" class="py-2 px-3 d-flex align-items-center position-relative"
      on:click={() => isOpenTemplates = true}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2">
        <path fill="#23B16D"
          d="M16 2V4H12V2H16ZM6 2V8H2V2H6ZM16 10V16H12V10H16ZM6 14V16H2V14H6ZM18 0H10V6H18V0ZM8 0H0V10H8V0ZM18 8H10V18H18V8ZM8 12H0V18H8V12Z"/>
      </svg>
      <span class="font-size-14">{$_('broadcast.chose_template')}</span>
    </Button>
  </div>
{:else}
  <div class="badges">
    <span class="badge font-size-13">
      {data.waba_template?.name}
      {#if !disabled}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <i on:click={deleteChoice} class="mdi mdi-close pointer ms-1"/>
      {/if}
    </span>&nbsp;
  </div>
{/if}
</div>

<WhatsappTemplatesModal bind:isOpenTemplates bind:data/>

<style>
  .ChoseTemplate button svg path {
    transition: .15s;
  }

  .ChoseTemplate button:hover svg path {
    fill: #fff;
  }

  .ChoseTemplate .badges {
    line-height: 3;
  }

  .ChoseTemplate .badges .badge {
    line-height: 18px;
    color: #495057;
    padding: 7px 10px;
    background-color: #EFF2F7;
  }

  .ChoseTemplate select {
    position: absolute;
    top: 0;
    opacity: 0;
  }
</style>
