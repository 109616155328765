<script>
  import {Row, Col, FormGroup, Label, Button, Input, Spinner, Modal, ModalBody} from "sveltestrap";
  import {_} from "svelte-i18n";
  import * as amplitude from "@amplitude/analytics-browser";
  import {postRequest} from "../../helpers/utils";
  import {defaultConst} from "../../common/constants";
  import {addToast} from "../../helpers/toast";
  import {user} from "../../helpers/store";
  import {onDestroy} from "svelte";

  let loading = false;
  let loading2 = false;
  let activeUser;
  let name = "";
  let selectedIndustry = "";
  let selectedSize = "";
  let isOpenGeneralCompanyType = false;
  let isOpenVerification = false;

  const unsub = user.subscribe(value => {
    if (value) {
      isOpenGeneralCompanyType = value.loggedIn && value.generalCompanyType === undefined && value.companyName === undefined && value.companySize === undefined && value.companyIndustry === undefined && value.isOperator !== true;
      activeUser = value
    }
  });

  onDestroy(() => {
    unsub();
  });

  async function handleSubmit() {
    if (loading2) return;
    loading2 = true;

    let params = {
      userToken: activeUser?.userToken,
      info: {
        companyName: name,
        companyIndustry: selectedIndustry,
        companySize: selectedSize,
      }
    };

    try {
      params.info.aid = amplitude.getDeviceId();
    } catch (e) {
    }

    let result;
    try {
      result = await postRequest(`${defaultConst.functionsUrl}/userCompanyInfo`, params);
    } catch (e) {
    }

    loading2 = false;

    if (!result || result.error) {
      await addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
    } else {
      amplitude.identify(new amplitude.Identify().set('company', {
        companyName: name,
        companyIndustry: selectedIndustry,
        companySize: selectedSize,
      }));
      isOpenVerification = false;
    }
  }

  const setUserCompanyInfo = async (generalCompanyType) => {
    if (loading) return;
    loading = true;
    isOpenGeneralCompanyType = false;
    window.dataLayer.push({event: "verification_success"});
    if (generalCompanyType === 1 && (!activeUser?.companyIndustry || !activeUser?.companyName || !activeUser?.companySize)) {
      isOpenVerification = true;
    }
    try {
      let result = await postRequest(`${defaultConst.functionsUrl}/userCompanyInfo`, {
        userToken: activeUser?.userToken, generalCompanyType
      });
      if (!result || result.error) {
        if (isOpenVerification) {
          isOpenVerification = false;
        }
        isOpenGeneralCompanyType = true;
        await addToast({
          title: $_("error"),
          message: $_("something_went_wrong"),
          dismissible: true,
          timeout: 4_000,
        });
      } else {
        amplitude.identify(new amplitude.Identify().set('generalCompanyType', generalCompanyType));
      }
      loading = false;
    } catch (e) {
      loading = false;
    }
  };
</script>

<Modal centered isOpen={isOpenGeneralCompanyType && !isOpenVerification && !loading} toggle={() => isOpenGeneralCompanyType = false}>
  <ModalBody class="p-4">
    <div class="font-size-16 fw-500 mb-3 text-center">
      {$_("BusinessTypeSelectionModal.discription")}
    </div>
    <Row>
      <Col class="mb-3 mb-md-0" md={6}>
        <Button class="py-2 px-4 font-size-16 w-100" color="success" on:click={() => setUserCompanyInfo(0)} outline>
          {$_("BusinessTypeSelectionModal.coder")}
        </Button>
      </Col>
      <Col md={6}>
        <Button class="py-2 px-4 font-size-16 w-100" color="success" on:click={() => setUserCompanyInfo(1)} outline>
          {$_("BusinessTypeSelectionModal.business")}
        </Button>
      </Col>
    </Row>
  </ModalBody>
</Modal>

<Modal centered isOpen={isOpenVerification} size='xl' toggle={() => {if (!loading2) {
  isOpenVerification = false
}}}>
  <ModalBody class="p-0">
    <div class="d-flex">
      <div class="bg-soft-green d-none d-xl-flex justify-content-center">
        <img alt="girl sitting at laptop" class="object-fit-contain" src="/assets/images/companyInfo.svg"/>
      </div>
      <div class="p-4 p-lg-5">
        <button aria-label="Close" class="btn-close position-absolute top-0 end-0 p-3" on:click={() => {if (!loading2) {
          isOpenVerification = false
        }}} type="button"/>
        <h2 class="header-title mb-3 mt-3 mt-lg-0">{$_("verification.title")}</h2>
        <p>{$_("verification.subtitle")}</p>
        <form on:submit|preventDefault={handleSubmit}>
          <FormGroup>
            <Label for="name">{$_("verification.label.company")}</Label>
            <Input bind:value={name} class="font-size-13" id="name"
              placeholder={$_("verification.placeholder.title")} required type="text"/>
          </FormGroup>
          <FormGroup>
            <Label for="industry">{$_("verification.label.industry")}</Label>
            <Input bind:value={selectedIndustry} class={!selectedIndustry && "verification-placeholder"}
              id="industry" name="select" required type="select">
              <option value="" disabled selected>{$_("verification.placeholder.select")}</option>
              <option class="verification-text" value="1">{$_("verification.selector.industry.1")}</option>
              <option class="verification-text" value="2">{$_("verification.selector.industry.2")}</option>
              <option class="verification-text" value="3">{$_("verification.selector.industry.3")}</option>
              <option class="verification-text" value="4">{$_("verification.selector.industry.4")}</option>
              <option class="verification-text" value="5">{$_("verification.selector.industry.5")}</option>
              <option class="verification-text" value="6">{$_("verification.selector.industry.6")}</option>
              <option class="verification-text" value="7">{$_("verification.selector.industry.7")}</option>
              <option class="verification-text" value="8">{$_("verification.selector.industry.8")}</option>
              <option class="verification-text" value="9">{$_("verification.selector.industry.9")}</option>
              <option class="verification-text" value="10">{$_("verification.selector.industry.10")}</option>
              <option class="verification-text" value="11">{$_("verification.selector.industry.11")}</option>
              <option class="verification-text" value="12">{$_("verification.selector.industry.12")}</option>
              <option class="verification-text" value="13">{$_("verification.selector.industry.13")}</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="size">{$_("verification.label.size")}</Label>
            <Input bind:value={selectedSize} class={!selectedSize && "verification-placeholder"}
              id="size" name="select" required type="select">
              <option value="" disabled selected>{$_("verification.placeholder.select")}</option>
              <option class="verification-text" value="1">1-10</option>
              <option class="verification-text" value="2">11-20</option>
              <option class="verification-text" value="3">21-50</option>
              <option class="verification-text" value="4">51-100</option>
              <option class="verification-text" value="5">101-500</option>
              <option class="verification-text" value="6">500+</option>
            </Input>
          </FormGroup>
          <div class="d-sm-flex gap-3 pt-3">
            <Button color="success" disabled={loading2} type="submit"
              class="d-flex gap-2 align-items-center justify-content-center px-5 mb-3 mb-sm-0">
              {#if loading2} <Spinner size="sm"/> {/if}
              {$_("save")}
            </Button>
            <Button class="px-4" color="light" type="button"
              disabled={loading2} on:click={() => isOpenVerification = false}>
              {$_("skip")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  </ModalBody>
</Modal>
