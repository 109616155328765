<script>
  import Navbar from "./Navbar.svelte";
  import Header from "./Header.svelte";
  import Rightbar from "../../Components/Rightbar.svelte";
  import Toasts from "../../Components/Toasts/Toasts.svelte";
  import {layoutLoad, trialBar} from "../../helpers/store";
  import {onDestroy} from "svelte";
  import {_} from "svelte-i18n";
  import Alert from "../../Components/Alert.svelte";

  let alert;
  const unsub = trialBar.subscribe((value) => {
    if (value) {
      alert = value;
    }
  });

  onDestroy(() => {
    unsub();
  })

  let open = false;
</script>

<div class:alert_bar={alert?.show} id="layout-wrapper">
  <Header {alert} bind:sidebar={open}/>
  <Navbar/>
  <div class="main-content position-relative" id="maincontent">
    {#if $layoutLoad}
      <div id="state_loader">
        <div id="state_loader__item"/>
        {#if typeof $layoutLoad === 'object' && $layoutLoad?.text}
          <div id="state_loader__text">
            <div class="text-center font-size-20">{$_($layoutLoad?.text)}</div>
          </div>
        {/if}
      </div>
    {/if}
    <slot/>
  </div>
  <Toasts/>
  <Alert place="alertTop" subject="alertChannel"/>
  <Rightbar bind:open layout="vertical"/>
</div>
