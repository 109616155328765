import {get} from "svelte/store";
import {_} from "svelte-i18n";
import {generateRandomString, getRequest, postRequest} from "../utils";
import {defaultConst} from "../../common/constants";

export default class AppClient {
  currentUser;
  currentUserOnetouch;
  availableLanguages = ["en", "ru", "pt"]

  /**
   *
   * @type {Promise<unknown>|null}
   */
  currentUserOnetouchPromise = null;


  constructor(currentUserData) {
    this.currentUser = currentUserData
  }

  acceptInvite(jwtToken) {
    const {url} = this.getCommonVars()
    return this.postRequest(`${url}/api/auth/obtain_operator_token_v2_mobile/`, {jwt_token: jwtToken})
  }

  addFlagUser(flagName, randomRange, randomValue) {
    return postRequest(`${defaultConst.functionsUrl}/addFlagUser`, {
      userToken: this.currentUser.userToken, flagName, randomRange, randomValue
    }).then(({result}) => result);
  }

  blockChatUser(botUserId, channel) {
    const {url, shopId} = this.getCommonVars(channel)
    let data = {
      shop_id: shopId, bot_user_id: botUserId,
    };
    return this.postRequest(`${url}/api/shop/blocked_bot_users/`, data);
  }

  changePassword(password, token) {
    const data = {
      password, token,
    };
    const {url} = this.getCommonVars()
    return this.postRequest(`${url}/api/auth/password_reset/`, data)
  }

  checkAndEditLang(params) {
    if (params?.lang) {
      for (let i = 0; i < this.availableLanguages.length; i++) {
        if (params.lang.toLowerCase().indexOf(this.availableLanguages[i]) !== -1) {
          params.lang = this.availableLanguages[i];
          break;
        }
      }
    }
    return params
  }

  /**
   * Represents a chat message search result.
   *
   * @typedef {Object} ChatMessageSearch
   *
   * @property {?string} video_url - The URL of the video message if present, otherwise null.
   * @property {?string} application - The application related to the message if present, otherwise null.
   * @property {?Object} template_data - Additional template data if available, otherwise null.
   * @property {number} id - The unique identifier of the chat message.
   * @property {string} id_in_messenger - The unique identifier of the chat message in the messenger.
   * @property {?string} file_url - The URL of the file attached to the message if present, otherwise null.
   * @property {number} profile_id - The profile ID associated with the chat message.
   * @property {number} created_at - The timestamp when the message was created in Unix Epoch format.
   * @property {BotUser} bot_user - The bot user associated with the message.
   * @property {string} reply_id_in_messenger - The unique identifier of the message to which this message is a reply, empty string if not a reply.
   * @property {number} chat - The chat identifier associated with the message.
   * @property {number} ts_in_messenger - The timestamp of the message in the messenger in Unix Epoch format.
   * @property {?Object} product - The product information associated with the message if available, otherwise null.
   * @property {?Step} step - The step information associated with the message if available, otherwise null.
   * @property {number} updated_at - The timestamp when the message was last updated in Unix Epoch format.
   * @property {?string} waba_template_id - The WABA (WhatsApp Business API) template ID if available, otherwise null.
   * @property {string} text - The content of the message (e.g., text, caption, etc.).
   * @property {number} status - The status code of the message (e.g., sent, delivered, read, etc.).
   * @property {?string} image_url - The URL of the image attached to the message if present, otherwise null.
   * @property {?Object} broadcast - The broadcast information associated with the message if available, otherwise null.
   * @property {number} direction - The direction of the message (e.g., received or sent).
   * @property {number} content_type - The type of content in the message (e.g., text, image, contact, etc.).
   * @property {number} bot - The identifier of the bot associated with the message.
   * @property {?string} reply_name - The name of the sender being replied to, if this message is a reply, otherwise null.
   * @property {?string} reply_text - The content of the message being replied to, if this message is a reply, otherwise null.
   */

  async deleteRequest(link, params = {}, body = {}, raw = false, endpoint = '') {
    params = this.checkAndEditLang(params)
    try {
      let url = new URL(link);
      url.search = new URLSearchParams(params).toString();
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer  ${this.getUserToken()}`,
        }, body: JSON.stringify(body), method: "DELETE",
      }
      const start = performance.now();
      let rawResponse = await fetch(url, options).then(res => {
        const end = performance.now();
        if (endpoint !== false) {
          this.postServicePrometheus({
            method: "DELETE",
            endpoint: endpoint.length ? endpoint : link,
            latency: parseFloat((end - start).toFixed(2))
          })
        }
        return res;
      });
      if (raw) return rawResponse;
      return rawResponse.json();
    } catch (err) {
      return {error: get(_)("no_request")};
    }
  }

  /**
   *
   * @param filters
   * @param channel
   * @returns {Promise< ChatMessageSearch[] | {error: string} >}
   */
  findMessageBy(filters, channel) {
    const {shopId, url} = this.getCommonVars(channel)
    let data = {
      ...filters, ...{
        shop_id: shopId,
        limit: 20
      }
    }
    return this.getMessages(data, channel).then(data => {
      return data?.result?.results
    }, err => {
    })
  }

  getActiveOperators(channel) {
    const {shopId, url} = this.getCommonVars(channel)
    return this.getRequest(`${url}/api/operators/operators/`, {
      shop_id: shopId, is_active: true
    });
  }

  getBlockedChatUsers(channel) {
    const {url, shopId} = this.getCommonVars(channel)
    const link = `${url}/api/shop/blocked_bot_users/`;
    return this.getRequest(`${link}${shopId}/`, {}, false, `${link}*/`)
  }

  getBotUserData(botUserId) {
    const {url} = this.getCommonVars()
    const link = `${url}/api/bot/bot_users/`;
    return this.getRequest(`${link}${botUserId}/`, {}, false, `${link}*/`);
  }

  // getBotUsers(channel, limit = 20, offset = 0, search = '', tags = []) {
  //   const {url, shopId} = this.getCommonVars(channel);

  //   let lineTags = '';
  //   tags.forEach(tag => {
  //     lineTags += `&tags=${tag}`
  //   });

  //   return this.getRequest(`${url}/api/bot/bot_users/?shop_id=${shopId}&limit=${limit}&offset=${offset}&search=${search}${lineTags}`).then(data => {
  //     return data;
  //   }, err => {
  //     return {error: get(_)('no_request')};
  //   })
  // }

  getBotUserScheme(botUserId, channel) {
    const {shopId, url} = this.getCommonVars(channel)
    let data = {
      shop_id: shopId,
    };
    const link = `${url}/api/bot/bot_users/`;
    return this.getRequest(`${link}${botUserId}/form_schema/`, data, false, `${link}*/form_schema/`);
  }

  getBotUsersTableConfig(channel) {
    const {url, shopId} = this.getCommonVars(channel)
    return this.getRequest(`${url}/api/bot/bot_users/table_config`, {shop_id: shopId}).then(data => {
      return data;
    }, err => {
      return {error: get(_)('no_request')};
    })
  }

  getChatOpenWindow(chatId) {
    const {url} = this.getCommonVars()
    const link = `${url}/api/bot/chats/`;
    return this.getRequest(`${link}${chatId}/get_chat_window/`, {}, false, `${link}*/get_chat_window/`);
  }

  getChats(filters, channel) {
    const {shopId, url} = this.getCommonVars(channel)
    let data = {
      ...filters, ...{
        shop_id: shopId,
      }
    }
    return this.getRequest(`${url}/api/bot/chats_v2/`, data);
  }

  getCommonUrl(channel, pageString) {
    const {url, userToken, shopId} = this.getCommonVars(channel)
    const randomString = generateRandomString(3);
    return `${url}/#/${shopId}/${pageString}/?authToken=${userToken}&activeShopParam=${shopId}&${randomString}=${randomString}`
  }

  getCommonVars(channel = null) {
    const url = import.meta.env.VITE_ONETOUCH_SERVER_ADDRESS ?? defaultConst.appUrl
    const emulatorUrl = import.meta.env.VITE_ONETOUCH_EMULATOR_ADDRESS ?? defaultConst.emulatorUrl
    const userToken = import.meta.env.VITE_ONETOUCH_USER_TOKEN_FIELD ? this.currentUser?.[import.meta.env.VITE_ONETOUCH_USER_TOKEN_FIELD] ?? this.currentUser?.appToken : this.currentUser?.appToken
    let result = {url, userToken, emulatorUrl, shopId: null}
    if (channel) {
      result.shopId = import.meta.env.VITE_ONETOUCH_USER_SHOP_FIELD ? channel?.appData?.[import.meta.env.VITE_ONETOUCH_USER_SHOP_FIELD] ?? channel?.appData?.shop_id : channel?.appData?.shop_id
    }
    return result
  }

  getConstructorUrl(channel, id) {
    if (id === 'settings_tags_create') {
      return this.getCommonUrl(channel, 'flows/settings/tags/create')
    }
    return this.getCommonUrl(channel, id ? `flows/${id}` : 'flows')
  }

  getContactsUrl(channel) {
    return this.getCommonUrl(channel, 'audience/bot_users')
  }

  /**
   *
   * @returns {Promise<Response | any | {error: string} | undefined>|Promise<unknown>}
   */
  getCurrentUserOnetouch() {
    if (this.currentUserOnetouch) {
      return Promise.resolve(this.currentUserOnetouch)
    } else if (!this.currentUserOnetouchPromise) {
      this.currentUserOnetouchPromise = this.getMe().then(({result}) => {
        if (result?.user?.id) {
          this.setCurrentUserOnetouchId(result.user)
          this.currentUserOnetouchPromise = null;
          return result.user
        } else {
          this.setCurrentUserOnetouchId(null)
          this.currentUserOnetouchPromise = null;
          return null
        }
      }, err => {
        this.setCurrentUserOnetouchId(null)
        this.currentUserOnetouchPromise = null;
        return null
      })
    }
    return this.currentUserOnetouchPromise


  }

  getEmulatorUrl(channel) {
    const {emulatorUrl, userToken, shopId} = this.getCommonVars(channel)
    const randomString = generateRandomString(3);
    return `${emulatorUrl}/chats/?authToken=${userToken}&activeShopParam=${shopId}&${randomString}=${randomString}`
  }

  getKeywordUrl(channel) {
    return this.getCommonUrl(channel, 'automation/keywords')
  }

  getLeadsUrl(channel) {
    return this.getCommonUrl(channel, 'audience/leads')
  }

  getMe() {
    const {url} = this.getCommonVars()
    return this.getRequest(`${url}/api/auth/get_me/`)
  }

  getMeProfile() {
    const {url} = this.getCommonVars()
    return this.getRequest(`${url}/api/operators/operators/undefined/get_profile/`)
  }

  getMessages(filters, channel) {
    const {shopId, url} = this.getCommonVars(channel)
    let data = {
      ...filters, ...{
        shop_id: shopId,
      }
    }
    return this.getRequest(`${url}/api/bot/messages/`, data);
  }

  getScheduledMessages(filters, channel) {
    const {shopId, url} = this.getCommonVars(channel)
    let data = {
      ...filters, ...{
        shop_id: shopId,
      }
    }
    return this.getRequest(`${url}/api/bot/messages/scheduled_message/`, data);
  }

  deleteScheduledMessage(id) {
    const {url} = this.getCommonVars();
    return this.deleteRequest(`${url}/api/bot/messages/scheduled_message/${id}/`);
  }

  async getRequest(link, params = {}, raw = false, endpoint = '') {
    params = this.checkAndEditLang(params)
    try {
      let url = new URL(link);
      url.search = new URLSearchParams(params).toString();
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer  ${this.getUserToken()}`,
        }
      };
      const start = performance.now();
      let rawResponse = await fetch(url, options).then(res => {
        const end = performance.now();
        if (endpoint !== false) {
          this.postServicePrometheus({
            method: "GET",
            endpoint: endpoint.length ? endpoint : link,
            latency: parseFloat((end - start).toFixed(2))
          })
        }
        return res;
      })
      if (raw) return rawResponse;
      return rawResponse.json();
    } catch (err) {
      return {error: get(_)('no_request')};
    }
  }

  getRoutingUrl(channel) {
    return this.getCommonUrl(channel, 'settings/live_chat')
  }

  getShopNatsCertificate(channel) {
    const {shopId, url} = this.getCommonVars(channel)
    const link = `${url}/api/shop/shop_nats_tokens/`;
    return this.getRequest(`${link}${shopId}`, {}, false, `${link}*/`).then(response => {
      if (response?.nats_jwt && response?.nats_seed) {
        return {
          shop_id: shopId, nats_jwt: response.nats_jwt, nats_seed: response.nats_seed
        }
      } else {
        return {}
      }
    });
  }

  getShops() {
    const {url} = this.getCommonVars()
    return this.getRequest(`${url}/api/shop/shops/`,).then(data => {
      return data?.result?.results
    }, err => {
    })
  }

  getToken() {
    if (this.currentUser?.userToken) {
      return postRequest(`${defaultConst.functionsUrl}/getAppToken`, {
        userToken: this.currentUser.userToken,
      });
    } else {
      return new Promise((res, rej) => {
        res(this.currentUser.userToken)
      })
    }
  }

  getTokenAndCheckInstances(sendMail = true) {
    return postRequest(`${defaultConst.functionsUrl}/registerUserAndCheckInstances`, {
      userToken: this.currentUser.userToken, sendMail: !!sendMail
    });
  }

  getUserToken() {
    const {userToken} = this.getCommonVars();
    return userToken;
  }

  importBotUsers(channel, file, addTags = [], removeTags = []) {
    const {url, shopId} = this.getCommonVars(channel);

    const postRequest = async (url, params) => {
      try {
        const userToken = this.getUserToken();
        const headers = {};
        if (userToken) {
          headers['Authorization'] = `Bearer  ${userToken}`;
        }
        const options = {
          headers, body: params, method: "POST",
        };
        const start = performance.now();
        let rawResponse = await fetch(url, options).then(res => {
          const end = performance.now();
          this.postServicePrometheus({
            method: "POST",
            endpoint: res.url,
            latency: parseFloat((end - start).toFixed(2))
          })
          return res;
        });
        return rawResponse.json();
      } catch (err) {
        return {error: get(_)("no_request")};
      }
    };

    let formData = new FormData();
    formData.append("shop_id", shopId);
    formData.append("name_1", file);
    formData.append("add_tags", addTags);
    formData.append("remove_tags", removeTags);
    return postRequest(`${url}/api/bot/csv_import_botusers/`, formData).then(data => {
      return data;
    }, err => {
      console.error(err);
      return {error: get(_)('no_request')};
    });
  }

  mergeFlowBrodcast(shop_id_from, broadcasts_copy = false, flows_copy = false, channel) {
    const {url, shopId} = this.getCommonVars(channel)
    let data = {
      shop_id: shopId, // Which store to copy to
      shop_id_from, // * Which store to copy from
      broadcasts_copy, // If you need to copy broadcasts
      flows_copy // If you need to copy the flows
    };
    return this.postRequest(`${url}/api/shop/merge_flow_brodcast/`, data)
  }

  patchBotChatUpdate(botUserId, data) {
    const {url} = this.getCommonVars()
    const link = `${url}/api/bot/chats/`;
    return this.patchRequest(`${link}${botUserId}/`, data, `${link}*/`);
  }

  patchBotUserData(botUserId, data) {
    const {url} = this.getCommonVars()
    const link = `${url}/api/bot/bot_users/`
    return this.patchRequest(`${link}${botUserId}/`, data, `${link}*/`);
  }

  patchOperatorIsOnline(is_online, channel) {
    const {url, shopId} = this.getCommonVars(channel)
    return this.patchRequest(`${url}/api/operators/operators_v2/is_online/`, {
      shop_id: shopId, is_online
    })
  }

  async patchRequest(url, params, endpoint = '') {
    params = this.checkAndEditLang(params)
    try {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer  ${this.getUserToken()}`,
        }, body: JSON.stringify(params), method: "PATCH",
      };
      const start = performance.now();
      let rawResponse = await fetch(url, options).then(res => {
        const end = performance.now();
        if (endpoint !== false) {
          this.postServicePrometheus({
            method: "PATCH",
            endpoint: endpoint.length ? endpoint : res.url,
            latency: parseFloat((end - start).toFixed(2))
          })
        }
        return res;
      });
      return rawResponse.json();
    } catch (err) {
      return {error: get(_)("no_request")};
    }

  }

  async pingTestUrl(url) {
    const start = performance.now();
    fetch(url, {
      method: "GET",
    }).then(res => {
      const end = performance.now();
      this.postServicePrometheus({
        method: "GET",
        endpoint: url,
        latency: parseFloat((end - start).toFixed(2))
      })
    });

  }

  postAddNewContact(data, channel) {
    const {shopId, url} = this.getCommonVars(channel)
    return this.postRequest(`${url}/api/bot/import_botuser/`, {
      shop_id: shopId, ...data
    });
  }

  //params should be form data
  async postFileRequest(url, formData, endpoint = '') {
    try {
      const options = {
        headers: {
          Accept: "application/json, text/plain, */*", Authorization: `Bearer  ${this.getUserToken()}`,
        }, body: formData, method: "POST",
      };
      const start = performance.now();
      let rawResponse = await fetch(url, options).then(res => {
        const end = performance.now();
        if (endpoint !== false) {
          this.postServicePrometheus({
            method: "POST",
            endpoint: endpoint.length ? endpoint : res.url,
            latency: parseFloat((end - start).toFixed(2))
          })
        }
        return res;
      });
      return rawResponse.json();
    } catch (err) {
      return {error: get(_)("no_request")};
    }
  }

  async postRequest(url, params, endpoint = '') {
    params = this.checkAndEditLang(params)
    try {
      const userToken = this.getUserToken();
      const headers = {
        Accept: "application/json", "Content-Type": "application/json",
      };
      if (userToken) {
        headers['Authorization'] = `Bearer  ${userToken}`;
      }
      const options = {
        headers, body: JSON.stringify(params), method: "POST",
      };
      const start = performance.now();
      let rawResponse = await fetch(url, options).then(res => {
        const end = performance.now();
        if (endpoint !== false) {
          this.postServicePrometheus({
            method: "POST",
            endpoint: endpoint.length ? endpoint : res.url,
            latency: parseFloat((end - start).toFixed(2))
          })
        }
        return res;
      });
      return rawResponse.json();
    } catch (err) {
      return {error: get(_)("no_request")};
    }
  }

  async postServicePrometheus(params) {
    const {url} = this.getCommonVars();
    try {
      let rawResponse = await fetch(`${url}/service_prometheus/histogram`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Basic ${btoa(`${import.meta.env.VITE_ONETOUCH_SERVICE_PROMETHEUS_AUTHORIZATION ?? defaultConst.servicePrometheusAuthorization}`)}`,
        },
        body: JSON.stringify(params),
        method: "POST",
      });
      return rawResponse.json();
    } catch (err) {
      return {error: get(_)("no_request")};
    }
  }

  postSubscribeOrUnsubscribeToFlow(data, action) {
    const {url} = this.getCommonVars()
    return this.postRequest(`${url}/api/bot/bot_user/${action}/`, data);
  }

  /**
   * Represents data manipulation for a chat message.
   * @typedef {Object} SendMessagePayload
   * @property {string} text - The text content for the message, defaults to an empty string.
   * @property {string?} image_url - The URL of an associated image.
   * @property {string?} video_url - The URL of an associated video.
   * @property {string?} voice_url - The URL of an associated voice recording.
   * @property {string?} file_url - The URL of an associated file.
   * @property {string?} lng - The lng
   */

  async postUpdateOperatorInformation(username, email, avatarFile) {
    const {url} = this.getCommonVars();
    if (avatarFile) {
      let avatar_url;
      let formData = new FormData();
      formData.append('file', avatarFile);
      await this.uploadToCdn(avatarFile.name, formData).then(({result}) => {
        if (!result.error && result.cdn_filepath) {
          avatar_url = result.cdn_filepath;
        } else {
          throw new Error(JSON.stringify(result.error));
        }
      }, err => {
        throw new Error(JSON.stringify(err));
      })
      await this.postRequest(`${url}/api/operators/operators/undefined/update_avatar_url/`, {avatar_url}).then(data => {
        if (data.status !== 'ok') {
          throw new Error(JSON.stringify(data));
        }
      }, err => {
        throw new Error(JSON.stringify(err));
      })
    }

    if (username) {
      return this.postRequest(`${url}/api/operators/operators/undefined/update_name/`, {username}).then(data => {
        return data;
      }, err => {
        return err;
      })
    } else {
      return {status: 'ok'};
    }
  }

  /**
   *
   * @param {number} chat_id
   * @param {SendMessagePayload} payload
   * @param {string} contentType
   * @property {string?} pregenerated_local_id - the id used to delete fake ids that helps with responsiveness of a chat
   * @returns {Promise<*|{error: string}|undefined>}
   */
  sendMessage(chat_id, payload, contentType, pregenerated_local_id = null, context = '') {
    let data = {
      chat_id,
      payload,
      content_type: contentType,
      profile_id: this.currentUserOnetouch?.id ?? null,
      pregenerated_local_id,
      context
    };

    const {url} = this.getCommonVars()
    return this.postRequest(`${url}/api/bot/messages/send_message/`, data);
  }

  sendWabaTemplateMessage(chat_id, template_id, template_data, channel, pregenerated_local_id = null, context = '') {
    const {url, shopId} = this.getCommonVars(channel)
    let data = {
      shop_id: shopId, chat_id, template_id, template_data, profile_id: this.currentUserOnetouch?.id ?? null,
    };
    if (pregenerated_local_id) data['pregenerated_local_id'] = pregenerated_local_id;
    if (context) data['context'] = context;
    return this.postRequest(`${url}/api/bot/messages/send_waba_template_message_v2/`, data);
  }

  sendScheduledMessage(chat_id, payload, contentType, starts_at) {
    let data = {
      chat_id,
      payload,
      content_type: contentType,
      profile_id: this.currentUserOnetouch?.id ?? null,
      starts_at
    };

    const {url} = this.getCommonVars()
    return this.postRequest(`${url}/api/bot/messages/scheduled_message/`, data);
  }

  sendScheduledWabaTemplateMessage(chat_id, template_id, template_data, channel, starts_at) {
    const {url, shopId} = this.getCommonVars(channel)
    let data = {
      shop_id: shopId,
      chat_id,
      template_id,
      template_data,
      profile_id: this.currentUserOnetouch?.id ?? null,
      starts_at
    };
    return this.postRequest(`${url}/api/bot/messages/scheduled_message/`, data);
  }

  setCurrentUser(data) {
    this.currentUser = data;
  }

  setCurrentUserOnetouchId(data) {
    this.currentUserOnetouch = data;
  }

  unblockChatUser(botUserId, channel) {
    const {url, shopId} = this.getCommonVars(channel)
    const link = `${url}/api/shop/blocked_bot_users/`;
    return this.deleteRequest(`${link}${shopId}/`, {bot_user_id: botUserId}, null, true, `${link}*/`)
  }

  uploadToCdn(fileName, formData) {
    const {url} = this.getCommonVars()
    const link = `${url}/api/cdn/upload/`;
    return this.postFileRequest(`${link}${fileName}/`, formData, `${link}*/`)
  }

  deleteBotUsersList(user_list = [], ignore = false) {
    const {url} = this.getCommonVars()
    return this.deleteRequest(`${url}/api/bot/bot_users_v2/list/`, {}, {user_list, ignore})
  }
}
