import {appClient} from "../store";
import NatsClient from "./NatsClient";

/**
 *
 * @param channel
 * @returns {Promise<T>}
 */
export function startNatsListening(channel) {
  return appClient.getShopNatsCertificate(channel).then((data) => {
    if (data) {
      return NatsClient.changeSettingsAndConnect(data)
    }
  })
}

export function stopNatsListening() {
  return NatsClient.terminate();
}
