<script>
  import {Router, Route, navigate} from "svelte-routing";
  import data from "./layoutes";
  import {globalHistory} from "svelte-routing/src/history";
  import * as amplitude from '@amplitude/analytics-browser';
  import NotFound from "./pages/Errors/NotFound.svelte";

  import {currentPath, projects, user} from './helpers/store';
  import './assets/scss/index.scss';
  import {eventTracking} from "./helpers/utils";



  const apiKey = import.meta.env.VITE_AMPLITUDE_API_KEY ?? "eefff5473463f55cf463421a97184e7e";
  amplitude.init(apiKey);

  export let url = "";

  import {addMessages, init} from "svelte-i18n";

  import en from "../public/lang/en.json";
  import {onDestroy, onMount} from "svelte";
  import AuthedWrapper from "./Components/AuthedWrapper.svelte";
  import UnAuthedWrapper from "./Components/UnAuthedWrapper.svelte";
  import BusinessTypeSelectionModal from "./Components/Modals/BusinessTypeSelectionModal.svelte";
  import TrialRestrictionsModal from "./Components/Modals/TrialRestrictionsModal.svelte";
  import TrialEndedModal from "./Components/Modals/TrialEndedModal.svelte";

  let activeUser;
  let openedSupportWindow = false

  addMessages("en", en);
  init({
    initialLocale: "en",
  });
  listenToTidioEvents();

  function listenToTidioEvents(tryCount = 1) {
    if (document.getElementById("tidio-chat")&&document.getElementById("tidio-chat-iframe")) {
      document.getElementById("tidio-chat-iframe").contentWindow.document.body.onclick = function () {
        if (!openedSupportWindow) {
          eventTracking('openSupportWindow');
          openedSupportWindow = true
        }
      }

      document.getElementById("tidio-chat-iframe").contentWindow.document.getElementById("button-body").onclick = function () {
        eventTracking('writeToSupport');
      }
    } else {
      if (tryCount < 5) {
        setTimeout(() => {
          listenToTidioEvents(tryCount + 1)
        }, 5000)
      }
    }
  }

  let unsub;
  onMount(() => {
    currentPath.set(window.location.href)
    unsub = globalHistory.listen(({location, action}) => {
      if ($currentPath !== location.pathname) {
        eventTracking('pageChange', {location: location.pathname})
      }
      currentPath.set(location.pathname)
    })
  })

  const unsub2 = user.subscribe((value) => {
    activeUser = value;
  });
  onDestroy(() => {
    unsub()
    unsub2()
  });
</script>

<svelte:head>
  <!-- Google Tag Manager -->
  <script>(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-KK4D964');
  </script>
  <script>
    function _get_session_id() {
      try {
        return document.cookie.split('; ').find(item => item.includes('_ga_GCS7SZNS2P=')).split('=')[1].split('.')[2];
      } catch (e) {
        return false;
      }
      ;
    }
  </script>
  <!-- End Google Tag Manager -->

<!--  {#if (activeUser?.creationTS > 1684751636000)}-->
<!--    <script defer type='text/javascript'>-->
<!--      window.smartlook || (function (d) {-->
<!--        var o = smartlook = function () {-->
<!--          o.api.push(arguments)-->
<!--        }, h = d.getElementsByTagName('head')[0];-->
<!--        var c = d.createElement('script');-->
<!--        o.api = new Array();-->
<!--        c.async = true;-->
<!--        c.type = 'text/javascript';-->
<!--        c.charset = 'utf-8';-->
<!--        c.src = 'https://web-sdk.smartlook.com/recorder.js';-->
<!--        h.appendChild(c);-->
<!--      })(document);-->
<!--      smartlook('init', '6bf8c851bdc7f0a684cd46b40e08b2248ac72926', {region: 'eu'});-->
<!--    </script>-->
<!--  {/if}-->

  <script src="https://js.stripe.com/v3/"></script>
  <script src="https://yookassa.ru/checkout-widget/v1/checkout-widget.js"></script>
  <script async defer src="//code.tidio.co/zyvkkrt97zmonyakzdwscrv0ywb1rgrz.js"></script>
  <script defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBRvzTEpqsPqBPROdVuqkPxGTlOTjnPTHw"></script>
</svelte:head>
<Router {url}>
  {#each data.authProtectedRoutes as route}
    <Route path={route.path} let:params>
      <AuthedWrapper guardFunctions={route.guards??[]}>
        <svelte:component this={route.layout}>
          <svelte:component params={{...params, ...route.params}} this={route.component}/>
        </svelte:component>
        <TrialRestrictionsModal/>
        <TrialEndedModal/>
      </AuthedWrapper>
    </Route>
  {/each}

  {#each data.publicRoutes as route}
    <Route path={route.path} let:params>
      <UnAuthedWrapper>
        <svelte:component this={route.layout}>
          <svelte:component params={{...params, ...route.params}} this={route.component}/>
        </svelte:component>
      </UnAuthedWrapper>
    </Route>
  {/each}

  {#each data.dualRoutes as route}
    <Route path={route.path} let:params>
      <svelte:component params={{...params, ...route.params}} this={route.component}/>
    </Route>
  {/each}

  <Route component={NotFound}/>
</Router>

<BusinessTypeSelectionModal/>

<!-- Google Tag Manager (noscript) -->
<noscript>
  <iframe height="0" src="https://www.googletagmanager.com/ns.html?id=GTM-KK4D964"
          style="display:none;visibility:hidden" title="gtm" width="0"></iframe>
</noscript>
<!-- End Google Tag Manager (noscript) -->
