<script>
  import {Modal, ModalHeader, ModalBody, Button, Spinner} from "sveltestrap";
  import {_} from "svelte-i18n";
  import TextAreaAutosize from "../../../../../../Components/TextAreaAutosize.svelte";
  import ModalScheduled from "./ModalScheduled.svelte";

  export let isOpenInteractiveLocationRequest = true;
  export let messageGetter;
  export let scheduledMessageGetter;
  export let dataReplyMessage;
  export let replyMessage;

  let text = '';
  let sendingMessage = false;
  let ModalScheduledIsOpen = false;

  const closeModal = () => {
    isOpenInteractiveLocationRequest = sendingMessage = false;
    text = '';
  };

  const send = () => {
    if (!sendingMessage) {
      sendingMessage = true;
      messageGetter.sendTextMessage(text, "interactive_location_request_message", dataReplyMessage?.message?.id_in_messenger);
      replyMessage(null);
      closeModal();
    }
  };

  const sendScheduled = (startsAt) => {
    if (!sendingMessage) {
      sendingMessage = true;
      scheduledMessageGetter.sendTextMessage(text, "interactive_location_request_message", startsAt)
      closeModal()
    }
  };
</script>

<Modal isOpen={isOpenInteractiveLocationRequest} fade={true} toggle={closeModal} size="md" centered>
  <ModalHeader toggle={closeModal} class="border-bottom-0 pb-0 pt-2 px-2"/>
  <ModalBody class="pt-2">
    <div class="w-100 rounded-2 wrapper mb-3">
      <div class="overflow-auto px-1 textarea-wrapper">
        <TextAreaAutosize
          on:enterpress={send}
          bind:value={text}
          disabled={sendingMessage}
          placeholder={$_('chat_inbox.MessageChat.placeholder_interactive_location_request')}
          minRows={3}
          classes="border-0 rounded-0 p-0 bg-transparent"
        />
      </div>
    </div>
    <div class="d-flex flex-wrap gap-2">
      <Button color="success" class="d-flex align-items-center gap-2 px-3"
        on:click={send} disabled={sendingMessage}>
        <span class="font-size-18 d-none d-sm-inline-block">{$_('send')}</span>
        {#if sendingMessage}
          <Spinner size="sm"/>
        {:else}
          <i class="mdi mdi-send font-size-18 lh-1 d-inline-block d-sm-none"/>
          <i class="mdi mdi-send font-size-22 lh-1 d-none d-sm-inline-block"/>
        {/if}
      </Button>
      <Button color="success" outline class="px-3 text-uppercase d-flex align-items-center"
        on:click={() => {
          ModalScheduledIsOpen = true
          isOpenInteractiveLocationRequest = false
        }} disabled={sendingMessage || !text}>
        <span class="font-size-13 d-none d-sm-inline-block">{$_('scheduled.schedule')}</span>
        <i class="bx bx-hourglass font-size-18 lh-1 d-inline-block d-sm-none"/>
      </Button>
    </div>
  </ModalBody>
</Modal>

<ModalScheduled send={sendScheduled} bind:isOpen={ModalScheduledIsOpen} callback={() => isOpenInteractiveLocationRequest = true}/>

<style>
  .wrapper {
    padding: 9px 11px;
    background-color: #EFF2F7;
  }

  .textarea-wrapper {
    max-height: 200px;
  }

  .textarea-wrapper::-webkit-scrollbar {
    width: 0;
  }
</style>
