<script>
  import {auth} from '../../helpers/firebase';
  import {navigate} from 'svelte-routing';
  import {user} from '../../helpers/store.js';
  import {handleLogin, postRequest, promisifyStore, retryPromiseFunction} from "../../helpers/utils";
  import {loginViaCredentials} from "../../helpers/firebase.js";
  import {Container, Row, Col, Card, CardBody, Button} from 'sveltestrap';
  import SideBlock from "../../Components/SideBlock.svelte";
  import {Link} from 'svelte-routing';
  import {_} from "svelte-i18n";
  import GoogleOauth from "./GoogleOauth.svelte";
  import {onDestroy, onMount} from "svelte";
  import {setupI18n} from "../../services/i18n";
  import {DEFAULT_REGISTER_PAGE, defaultConst} from "../../common/constants";
  import Spinner from "../../Components/Spinners/SpinnerComponent.svelte";
  import {DEFAULT_LOGIN_PAGE} from "../../common/constants";
  import * as amplitude from "@amplitude/analytics-browser";


  let handling = false;

  const errorDuringRegister = error => {
    handling = false;
    error?.message && alert(error.message)
  }
  // Destructuring to obtain email and password from form via Event

  // Destructuring to obtain email and password from form via Event
  let loading = false;
  const handleRegisterForm = ({target: {elements: {email, password}}}) => {
    if (handling) return;

    handling = true;
    auth.createUserWithEmailAndPassword(email.value, password.value).then(data => {
      window.dataLayer.push({'event': 'onboarding_create_account', "type": "regular"});

      promisifyStore(user, user => !!user?.userToken).then(data => {
        handleLogin(false, null, null, data);
      })


    }).catch(errorDuringRegister);
  }

  function handleGoogleOneTap(data) {
    if (data.credential) {
      if (handling) return;
      handling = true;
      loginViaCredentials(data.credential)
      window.dataLayer.push({'event': 'onboarding_create_account', "type": "regular"});
      promisifyStore(user, user => !!user?.userToken).then(data => {
        handleLogin(false, null, null, data);
      })
    }
  }

  // <!--let firebaseUser = auth.currentUser;-->
  //
  // <!--if (firebaseUser) {-->
  // <!--	let { email } = firebaseUser;-->
  // <!--	-->
  // <!--	user.set({ ...$user, loggedIn: true, email });-->
  // <!--	-->
  // 	navigate('/dashboard');


  let agreement = true;

  onMount(async () => {
    setCallbackFunctionFOrGoogle(handleGoogleOneTap)
    let lang = navigator.language || navigator.userLanguage;
    lang = (lang.indexOf('ru') === 0 && 'ru' || lang.indexOf('es') === 0 && 'es' || 'en');
    localStorage.setItem("I18N_LANGUAGE", lang);


    const urlParams = new URLSearchParams(window.location.search);
    let ssp = urlParams.get('ssp')
    let sdv = urlParams.get('sdv')
    if (ssp) {
      localStorage.setItem('ssp', ssp)
    } else {
      localStorage.removeItem('ssp')
    }
    if (sdv) {
      localStorage.setItem('sdv', sdv)
    } else {
      localStorage.removeItem('sdv')
    }
    await setupI18n({withLocale: lang});
  });
  onDestroy(() => {
    setCallbackFunctionFOrGoogle(null)
  })

  window.dataLayer.push({'event': 'onboarding_сame_to_reg_page'});

  postRequest('https://us-central1-msg-37f84.cloudfunctions.net/trackEvent', {
    aid: amplitude.getDeviceId(),
    event: 'onboarding_сame_to_reg_page'
  });

</script>

<div>
  <Container class="p-0" fluid>
    <Row class="g-0">
      <SideBlock text="auth"/>
      <Col xl={5}>
        <div class="account-pages">
          <div class="account-container">
            <div class="logo-block">
              <img alt="logo" class="logo" src="assets/images/logo-1msg-dark.svg">
            </div>
            <form class="form-horizontal" on:submit|preventDefault={handleRegisterForm}>
              <div class="mb-3">
                <label class="form-label" for="email">Email</label>
                <input
                    class="form-control"
                    id="email"
                    name="email"
                    placeholder={$_('auth.enter_email_placeholder')}
                    type="email"
                />
                <div class="invalid-feedback">{$_('auth.enter_email_text')}</div>
              </div>

              <div class="mb-3">
                <label class="form-label" for="password">{$_('auth.password')}</label>
                <input
                    class="form-control"
                    id="password"
                    name="password"
                    autocomplete="on"
                    placeholder={$_('auth.enter_password_placeholder')}
                    type="password"
                />
                <div class="invalid-feedback">{$_('auth.enter_password_text')}</div>
              </div>

              <div class="form-check">
                <input
                    bind:checked={agreement}
                    class="form-check-input"
                    id="agreement-check2"
                    required
                    type="checkbox"
                />
                <label
                    class="form-check-label"
                    for="agreement-check2"
                >
                  {@html $_('chatapi.auth.agreement')}

                </label>
              </div>
              <div class="mt-4 d-grid">
                <button class="btn w-md btn-success btn-lg" type="submit">
                  {#if loading}
                    <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  {/if}
                  {$_('auth.register_btn')}
                  {#if handling}
                    <Spinner class="ssm"/>
                  {/if}
                </button>
              </div>

              <div class="mt-4 text-center">
                <h5 class="font-size-14 mb-3">
                  {$_('auth.sign_up_with')}
                </h5>
                <div data-callback="handleCredentialResponseForGoogle"
                     data-client_id="686459367370-mbf3aosuibbilulsf4nmu31h5njpr9vi.apps.googleusercontent.com"
                     data-context="signup"
                     data-itp_support="true"
                     id="g_id_onload">
                </div>
                <GoogleOauth bind:handling={handling} errorDuringLogin={errorDuringRegister}/>
              </div>

              <!--			<div class="mt-4 text-center">-->
              <!--				<p class="mb-0">-->
              <!--					By registering you agree to the 1msg <Link-->
              <!--						to={"#"}-->
              <!--						class="text-success">Terms of Use</Link-->
              <!--				>-->
              <!--				</p>-->
              <!--			</div>-->
            </form>
            <div class="mt-5 text-center">
              <p>
                {$_('auth.already_have_account')}
                <Link class="fw-medium text-success"
                      to={!handling?DEFAULT_LOGIN_PAGE:DEFAULT_REGISTER_PAGE}>{$_('auth.login')}</Link>
              </p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
</div>
