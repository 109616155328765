<script context="module">
  import Pagination from "../Pagination/Pagination.svelte";
  import Search, { setLabels as _setSearchLabels } from "./TableSearch.svelte";
  import Sort, { setLabels as _setSortLabels } from "./Sort.svelte";
  import { Table, Row, Col } from "sveltestrap";
  export { Pagination, Search, Sort };

  let globalLabels;

  export function setTableLabels(labels) {
    globalLabels = labels;
  }

  export const setSearchLabels = _setSearchLabels;
  export const setSortLabels = _setSortLabels;
</script>

<script>
  import { createEventDispatcher, setContext } from "svelte";
  const dispatch = createEventDispatcher();

  export let loading = false;
  export let addBtn = false;
  export let page = 0;
  export let pageIndex = 0;
  export let pageSize = 10;
  export let rows;
  export let serverSide = false;
  export let labels = {
    empty: "No records available",
    loading: "Loading data",
    ...globalLabels,
  };

  let buttons = [-2, -1, 0, 1, 2];
  let pageCount = 0;

  $: filteredRows = rows;
  $: visibleRows = filteredRows.slice(pageIndex, pageIndex + pageSize);

  setContext("state", {
    getState: () => ({
      page,
      pageIndex,
      pageSize,
      rows,
      filteredRows,
    }),
    setPage: (_page, _pageIndex) => {
      page = _page;
      pageIndex = _pageIndex;
    },
    setRows: (_rows) => (filteredRows = _rows),
  });

  function onPageChange(event) {
    dispatch("pageChange", event.detail);
  }

  function onSearch(event) {
    dispatch("search", event.detail);
  }

  function addNew(event) {
    dispatch("addNew", event.detail);
  }
</script>

<Row class="mb-2">
  <Col sm={4}>
    <slot name="top">
      <svelte:component this={Search} on:search={onSearch} />
    </slot>
  </Col>
  {#if addBtn}
  <Col sm={8}>
    <div class="text-sm-end">
      <button
        on:click={addNew}
        type="button"
        class="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
        ><i class="mdi mdi-plus" /></button
      >
    </div>
  </Col><!-- end col-->
  {/if}
 
</Row>

<div class="table-responsive">
  <Table class={"table " + $$props.class}>
    <slot name="head" />
    {#if loading}
      <tbody>
        <tr>
          <td class="center" colspan="100%">
            <span>
              {@html labels.loading}
            </span>
          </td>
        </tr>
      </tbody>
    {:else if visibleRows.length === 0}
      <tbody>
        <tr>
          <td class="center" colspan="100%">
            <span>
              {@html labels.empty}
            </span>
          </td>
        </tr>
      </tbody>
    {:else}
      <slot rows={visibleRows} />
    {/if}
    <slot name="foot" />
  </Table>

  <slot name="bottom">
    <div class="slot-bottom">
      <svelte:component
        this={Pagination}
        {page}
        {pageSize}
        {serverSide}
        count={filteredRows.length - 1}
        on:pageChange={onPageChange}
      />
    </div>
  </slot>
</div>
