<script>
  import {Modal, ModalBody, Button} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import {chosenChannel, paymentProfiles, getCorporateBillsSettings} from "../../../../../helpers/store";
  import SelectDropdownCheckbox from "../../../../../Components/SelectDropdownCheckbox.svelte";

  export let activeTags;
  export let listTags;
  export let updateUserInfo;
  export let loadUserScheme;

  let isOpen = false;
  let list = [];
  let chosenChannelLocal, paymentProfile;

  const unsubChosenChannel = chosenChannel.subscribe(value => {
    paymentProfile = undefined;
    if (value === null || value === "") {
      chosenChannelLocal = null
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        chosenChannelLocal = value;
        getCorporateBillsSettings(chosenChannelLocal.id).then(value => {
          if (value?.org) {
            paymentProfile = {org: value.org};
          }
        });
      }
    }
  });

  $: if (chosenChannelLocal?.paymentProfileId && $paymentProfiles) {
    paymentProfile = $paymentProfiles.find((item) => {
        return item.id === chosenChannelLocal.paymentProfileId
    });
  }

  onDestroy(() => {
    unsubChosenChannel();
  });

  const creatingListTags = () => {
    list = listTags.map(({id, shop_id, name}) => {
      let checked = !!activeTags.find(item => item.id === id);
      return {id, shop_id, label: name, checked}
    })
  };

  const deleteTag = (id) => {
    let tags = activeTags.filter(tag => tag.id !== id);
    updateUserInfo({store: {tags}})
  };

  const updateTags = () => {
    let tags = [];
    list.forEach(({id, shop_id, label, checked}) => {
      if (checked) tags.push({id, shop_id, name: label});
    });
    updateUserInfo({store: {tags}})
  };
</script>

<div class="mb-3 Tags">
<div class="d-flex align-items-center justify-content-between mb-2">
  <div class="font-size-16 fw-500">{$_("chat_inbox.UserCard.tags")}</div>
  <button class="font-size-13 btn btn-success btn-tags-add" on:click={async () => {
    await loadUserScheme();
    isOpen=true;
    creatingListTags();
  }}>
    {$_("chat_inbox.UserCard.add")}
  </button>
</div>
<div class="badges">
  {#each activeTags as tag}
    <span class="badge">
      {tag.name}
      <i class="mdi mdi-close pointer" on:click={() => deleteTag(tag.id)}/>
    </span>&nbsp;
  {/each}
</div>

{#if isOpen}
<Modal isOpen={isOpen} fade={true} toggle={() => isOpen=false} size="md" centered>
  <ModalBody>
    <button type="button" class="btn-close position-absolute end-0 top-0 p-2" on:click={() => isOpen=false}/>
    <div class="pt-4 mb-3">
      <SelectDropdownCheckbox
        title="SelectDropdownCheckbox.select_a_tags"
        bind:list={list}
        required={true}/>
    </div>
    <div class="d-flex justify-content-center gap-2">
      <Button color="success" class="font-size-16 py-1 px-4" on:click={() => {
        updateTags();
        isOpen=false
      }}>
        {$_("save")}
      </Button>
      {#if paymentProfile?.org !== '1msg_basic'}
        <a href="/automation/flow/settings_tags_create/" target="_blank"
          class="font-size-16 py-1 px-4 btn btn-outline-success" on:click={() => isOpen=false}>
          {$_("chat_inbox.UserCard.create_tags")}
        </a>
      {/if}
    </div>
  </ModalBody>
</Modal>
{/if}
</div>

<style>
  .Tags .btn-tags-add {
    padding: 2px 8px;
    border-radius: 6px;
  }

  .Tags .badges {
    line-height: 1.8;
  }

  .Tags .badges .badge {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 5px 2px 5px;
    color: #495057 ;
    background-color: #EFF2F7;
  }
</style>