<script>
  import {Table, Spinner, Dropdown, DropdownMenu, DropdownToggle, DropdownItem} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {addProjectUser, updateProjectUser, getProjectUsers} from "../../helpers/store";
  import {addToast} from "../../helpers/toast";

  export let data;
  export let projectUserToDelete;
  export let showDeleteModal;
  export let projectUserToUpdate;
  export let showUpdateModal;

  let loading = -1;

  const reInvite = async ({email, role, id, shops_id}) => {
    loading = id;
    const res = await addProjectUser(email, role, shops_id);

    if (!res || res.error) {
      loading = -1;
      addToast({
        title: $_("error"),
        message: res.error || $_("something_went_wrong")
      });
    } else if (res.status === 'ok') {
      loading = -1;
      addToast({title: $_("users.table.re_invite.message")});
    }
  };

  const operatorToggleActivationDeactivation = async ({email, role, is_active, shops_id}) => {
    const res = await updateProjectUser({email, role, is_active, shops_id});

    if (!res || res.error) {
      addToast({
        title: $_("error"),
        message: res.error || $_("something_went_wrong")
      });
      getProjectUsers();
    }
  };
</script>

<div class="table-responsive">
  <Table class="align-middle table-nowrap mb-0 table-hover">
    <thead class="table-light">
    <tr>
      <th style="width: 20%">#</th>
      <th style="width: 25%">{$_('users.table.thead.name')}</th>
      <th style="width: 25%">{$_('users.table.thead.email')}</th>
      <th style="width: 15%">{$_('users.table.thead.role')}</th>
      <th style="width: 15%" class="text-center">{$_('users.table.thead.action')}</th>
    </tr>
    </thead>
    <tbody>
    {#each data as user}
      <tr>
        <td>
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              {#if !user.avatar_url}
                <div class="avatar-xs d-inline-block">
                  <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-14">
                    {user.email.toUpperCase().charAt(0)}
                  </span>
                </div>
              {:else}
                <img src={user.avatar_url}
                  class="rounded-circle avatar-xs object-fit-cover" alt="avatar"/>
              {/if}
            </li>
            {#if 'is_active' in user && user.type !== 'administrators'}
              <li class="list-inline-item px-2">
                <div class="form-check form-switch form-switch-md mb-2">
                  <input type="checkbox" class="form-check-input"
                    bind:checked={user.is_active}
                    on:change={() => operatorToggleActivationDeactivation(user)}/>
                  <!-- svelte-ignore a11y-label-has-associated-control -->
                  <label class="form-check-label">
                    {user.is_active ? $_("users.table.is_active.active") : $_("users.table.is_active.not_active")}
                  </label>
                </div>
              </li>
            {/if}
          </ul>
        </td>
        <td>
          <h5 class="font-size-14 mb-0">
            {#if user.first_name || user.last_name}
              {user.first_name} {user.last_name}
            {:else}
              {user.name}
            {/if}
          </h5>
        </td>
        <td>
          {user.email}
        </td>
        <td>
          {#if user.type === 'invite'}
            <span class="badge font-size-11 badge-soft-warning">
              {$_('users.table.tbody.type.warning')}
            </span>
          {:else if user.type === 'operator'}
            <span class="badge font-size-11 badge-soft-primary">
              {$_('users.table.tbody.type.primary')}
            </span>
          {:else if user.type === 'administrators'}
            <span class="badge font-size-11 badge-soft-success">
              {$_('users.table.tbody.type.success')}
            </span>
          {/if}
        </td>
        <td>
          <ul class="list-inline d-flex justify-content-center font-size-20 mb-0">
            {#if user.type !== 'administrators'}
              <Dropdown direction="left" class="d-flex">
                <DropdownToggle tag="div" class="pointer d-flex" caret>
                    {#if loading === user.id}
                      <Spinner size="sm"/>
                    {:else}
                      <i class="bx bx-dots-horizontal"/>
                    {/if}
                </DropdownToggle>
                {#if loading !== user.id}
                  <DropdownMenu end>
                    {#if user.type === 'invite'}
                      <DropdownItem on:click={() => loading === -1 && reInvite(user)}>
                        {$_("users.table.re_invite.title")}
                      </DropdownItem>
                    {/if}
                    {#if user.type !== 'invite'}
                      <DropdownItem on:click={() => {projectUserToUpdate(user); showUpdateModal();}}>
                        {$_("users.table.accessing_channels.title")}
                      </DropdownItem>
                    {/if}
                    <DropdownItem on:click={() => {projectUserToDelete(user); showDeleteModal();}}>
                      {$_("users.delete_modal.title")}
                    </DropdownItem>
                  </DropdownMenu>
                {/if}
              </Dropdown>
            {/if}
          </ul>
        </td>
      </tr>
    {/each}
    </tbody>
  </Table>
</div>
