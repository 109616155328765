<div class="position-absolute ErrorMessageWrapper w-100 text-center">
  <span class="badge bg-danger p-2 text-wrap font-size-13">
    <slot></slot>
  </span>
</div>

<style>
  .ErrorMessageWrapper {
    top: -15px;
    padding-right: 12px;
  }
</style>