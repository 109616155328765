<script>
  import {_} from "svelte-i18n";
  import MessageInfo from "../MessageInfo.svelte";
  import ModalOrder from "../Modal/ModalOrder.svelte";

  export let message;
  export let direction;

  const jsonObject = typeof message.text === 'string' ? JSON.parse(message.text) : message.text;
  const businessId = jsonObject?.meta?.business_id;
  const catalogId = jsonObject?.meta?.catalog_id;
  const productItems = jsonObject?.meta?.product_items;
  const preliminaryPrice = productItems?.reduce((acc, cur) => acc + cur.item_price, 0);

  let isOpen = false;
</script>

<div class="p-1">
  <div class="d-flex align-items-center gap-2 rounded-3 Wrapper">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.8979 18.2838L19.2847 19.2174V36L33.8116 27.6124V10.8299L20.8979 18.2838Z" fill="#23B16D"/>
      <path d="M23.527 3.23402L17.9403 0L3.01001 8.61909L8.6042 11.8531L23.527 3.23402Z" fill="#23B16D"/>
      <path d="M32.8632 8.61909L26.425 4.95187L11.5022 13.571L12.3536 14.0116L17.9404 17.2382L23.4972 14.034L32.8632 8.61909Z" fill="#23B16D"/>
      <path d="M10.3744 19.7402L7.70051 18.366V14.0938L2.18848 10.9195V27.5826L16.6109 35.9104V19.2473L10.3744 15.6548V19.7402Z" fill="#23B16D"/>
    </svg>
    <div class="text-truncate">
      <div class="d-flex align-items-center gap-2 text-dark">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1259_14593)">
            <path fill="rgba(var(--bs-dark-rgb),var(--bs-text-opacity))"
            d="M4.95825 12.75C4.17909 12.75 3.54867 13.3875 3.54867 14.1667C3.54867 14.9458 4.17909 15.5833 4.95825 15.5833C5.73742 15.5833 6.37492 14.9458 6.37492 14.1667C6.37492 13.3875 5.73742 12.75 4.95825 12.75ZM0.708252 1.41666V2.83332H2.12492L4.67492 8.20957L3.71867 9.94499C3.60534 10.1433 3.54159 10.3771 3.54159 10.625C3.54159 11.4042 4.17909 12.0417 4.95825 12.0417H13.4583V10.625H5.25575C5.15659 10.625 5.07867 10.5471 5.07867 10.4479L5.09992 10.3629L5.73742 9.20832H11.0145C11.5458 9.20832 12.0133 8.91791 12.2541 8.47874L14.7899 3.88166C14.8466 3.78249 14.8749 3.66207 14.8749 3.54166C14.8749 3.15207 14.5562 2.83332 14.1666 2.83332H3.69034L3.0245 1.41666H0.708252ZM12.0416 12.75C11.2624 12.75 10.632 13.3875 10.632 14.1667C10.632 14.9458 11.2624 15.5833 12.0416 15.5833C12.8208 15.5833 13.4583 14.9458 13.4583 14.1667C13.4583 13.3875 12.8208 12.75 12.0416 12.75Z"/>
          </g>
          <defs>
            <clipPath id="clip0_1259_14593">
              <rect width="17" height="17" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <div class="font-size-16 fw-600 text-truncate">
          {productItems?.length} product
        </div>
      </div>
      {#if preliminaryPrice}
        <div class="text-muted font-size-14 text-truncate mt-1">
          {preliminaryPrice} {productItems[0]?.currency}
        </div>
      {/if}
    </div>
  </div>
  <div class="position-relative Info">
    <MessageInfo {message} {direction}/>
  </div>
</div>
<div class="d-flex align-items-center justify-content-center w-100 px-3 py-2 pointer OrderBtn" on:click={() => {if (productItems?.length) isOpen=true}}>
  <span class="text-truncate font-size-16">{$_('chat_inbox.MessageChat.view_order')}</span>
</div>

<ModalOrder bind:isOpen {productItems} {catalogId} {businessId}/>

<style>
  .Wrapper {
    padding: 10px 12px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .Wrapper>svg {
    min-width: 36px;
  }

  .Wrapper .text-dark>svg {
    min-width: 17px;
  }

  .Info {
    height: 20px;
  }

  .OrderBtn {
    color: #0096DE;
    border-top: 0.8px solid #DBDBDD;
  }
</style>