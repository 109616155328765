<script>
  import {Dropdown, DropdownMenu, DropdownToggle, DropdownItem} from "sveltestrap";
  import {_} from "svelte-i18n";

  export let required = false;
  export let disabled = false;
  /**
   * The list of items to populate the dropdown.
   *
   * @typedef {Array<Object>} DropdownItem
   * @property {string} label - The label or display text for the dropdown item.
   * @property {string} value - The value associated with the dropdown item.
   *
   * @type {DropdownItem[]}
   * @description
   * This array should contain objects representing the items to be displayed in the dropdown.
   * Each object should have a `label` property for the item's label or display text, and a `value` property
   * representing the value associated with the item. The `value` property should be unique for each item.
   * @example
   * // Usage example:
   * let list = [
   *   { label: 'Option A', value: 'a' },
   *   { label: 'Option B', value: 'b' },
   *   // ...
   * ];
   */
  export let list;
  export let selected;
  export let title;
  export let callback = () => {};
</script>

<div class="position-relative SelectDropdown">
  {#if required}
    <select class="form-control" required={true}/>
  {/if}
  <Dropdown>
    <DropdownToggle color="light" {disabled} caret
      class="form-control w-100 d-flex align-items-center justify-content-between {disabled ? 'disabled pe-none' : ''}">
      <span class="text-truncate">
        {#if selected}
          <span class="text-uppercase">{selected.httpMethod}</span>
          <span class="badge mx-1">{selected.path}</span>
          {$_(selected.summary)}
        {:else}
          {$_(title)}
        {/if}
      </span>
      <i class="mdi mdi-chevron-down"/>
    </DropdownToggle>
    {#if list}
      <DropdownMenu class="w-100">
        <div class="scrollbar">
          <slot></slot>
          {#each list as resource (resource.name)}
            <div class="text-truncate dropdown-header font-size-15">
              <span class="fw-600">{$_(resource.name)}</span> - {$_(resource.description)}
            </div>
            {#each resource.operations as op (op.id + resource.name)}
              <DropdownItem class="text-truncate {op === selected ? 'active' : ''}" on:click={() => {selected = op; callback()}}>
                <span class="text-uppercase">{op.httpMethod}</span>
                <span class="badge mx-1">{op.path}</span>
                {$_(op.summary)}
              </DropdownItem>
            {/each}
          {/each}
        </div>
      </DropdownMenu>
    {/if}
  </Dropdown>
</div>

<style>
  select {
    position: absolute;
    top: 0;
  }

  .SelectDropdown .scrollbar {
    max-height: 292px;
    overflow-y: auto;
  }

  .SelectDropdown .scrollbar::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  .SelectDropdown .scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .SelectDropdown .dropdown-header {
    color: #495057;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  .badge {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    padding: 3px 6px 3px 8px;
    color: #495057;
    background-color: #EFF2F7;
  }
</style>
