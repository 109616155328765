<script>
  import {auth, loginViaGoogle, loginViaGit, loginViaFacebook, loginViaTwitter} from "../../helpers/firebase.js";
  import SideBlock from "../../Components/SideBlock.svelte";
  import {navigate} from "svelte-routing";
  import {loginWithFallback, user} from "../../helpers/store.js";

  import {addToast} from "../../helpers/toast";
  import {
    Row,
    Col,
    CardBody,
    Card,
    Container,
    Form,
    Label,
    Input,
    Button,
  } from "sveltestrap";
  import {Link} from "svelte-routing";
  import {_} from "svelte-i18n";
  import {onMount} from "svelte";
  import {setupI18n} from "../../services/i18n";
  import {handleLogin, promisifyStore} from "../../helpers/utils";
  import Spinner from "../../Components/Spinners/SpinnerComponent.svelte";
  import GoogleOauth from "./GoogleOauth.svelte";

  let rememberMe = !!localStorage.getItem("remember_me") || false;
  let email = rememberMe ? localStorage.getItem("remember_me_login") : "";
  let password = rememberMe ? localStorage.getItem("remember_me_password") : "";
  let passwordType = "password";

  let handling = false;

  const errorDuringLogin = error => {
    handling = false;
    error?.message && alert(error.message)
  }

  function loginWithCustomToken(token) {
    auth.signInWithCustomToken(token).then(() => {
      promisifyStore(user, user => !!user?.id).then(data => {
        handleLogin(rememberMe, email, password, data)
      }, errorDuringLogin)
    })
  }

  // Destructuring to obtain email and password from form via Event
  const handleLoginForm = () => {
    if (!handling) {
      handling = true;
      auth.signInWithEmailAndPassword(email, password).then(() => {
        promisifyStore(user, user => !!user?.id).then(data => {
          handleLogin(rememberMe, email, password, data)
        }, errorDuringLogin)
      })
        .catch((error) => {
          loginWithFallback(email, password).then(({result}) => {
            if (result?.token) {
              loginWithCustomToken(result.token);
              handling = false;
            } else {
              errorDuringLogin(error)
            }
          }, errorDuringLogin)
        });
    }
  };
  const gitAuth = () => {
    loginViaGit()
      .then(() => {
        navigate("/inbox") // /account-info
      })
      .catch((error) => alert(error.message));
  };
  const facebookAuth = () => {
    loginViaFacebook()
      .then(() => {
        navigate("/inbox") // /account-info
      })
      .catch((error) => alert(error.message));
  };
  const twitterAuth = () => {
    loginViaTwitter()
      .then(() => {
        navigate("/inbox") // /account-info
      })
      .catch((error) => alert(error.message));
  };

  onMount(async () => {
    let lang = navigator.language || navigator.userLanguage;
    lang = (lang.indexOf('ru') === 0 && 'ru' || lang.indexOf('es') === 0 && 'es' || 'en');
    localStorage.setItem("I18N_LANGUAGE", lang);
    await setupI18n({withLocale: lang});

    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get('customToken')){
      handling = true
      loginWithCustomToken(urlParams.get('customToken'))
    }
  });
</script>
<div>
  <Container fluid class="p-0">
    <Row class="g-0">
      <SideBlock text="auth"/>
      <Col xl={5}>
        <div class="account-pages">
          <div class="account-container">
            <div class="logo-block">
              <img src="assets/images/logo-1msg-dark.svg" alt="logo" class="logo">
            </div>
            <form on:submit|preventDefault={handleLoginForm} class="form-horizontal">
              <div class="mb-3">
                <Label for="email" class="form-label"
                >Email</Label
                >
                <Input
                    type="text"
                    class="form-control"
                    id="email"
                    placeholder={$_('auth.enter_email_placeholder')}
                    bind:value={email}
                />
              </div>

              <div class="mb-3">
                <Label class="form-label" for="password">
                  {$_('auth.password')}
                </Label>
                <div class="input-group auth-pass-inputgroup">
                  <Input
                      bind:type={passwordType}
                      class="form-control"
                      id="password"
                      placeholder={$_('auth.enter_password_placeholder')}
                      aria-label="Password"
                      autocomplete="on"
                      aria-describedby="password-addon"
                      bind:value={password}
                  />
                  <Button
                      on:click={()=>{passwordType=passwordType==='password'?'text':'password'}}
                      color="light"
                      type="button"
                      id="password-addon"
                      class="btn-eye"
                  >
                    <i
                        class="mdi mdi-eye-outline"
                    />
                  </Button>
                </div>
              </div>

              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="remember-check"
                    bind:checked={rememberMe}
                />
                <Label
                    class="form-check-label"
                    for="remember-check"
                >
                  {$_('auth.remember_me')}

                </Label>
              </div>

              <div class="mt-3 d-grid">
                <button class="btn btn-success w-md btn-lg" type="submit"
                        on:click={handleLoginForm}>{$_('auth.login_btn')}
                  {#if handling}
                    <Spinner class="ssm"/>
                  {/if}
                </button>

              </div>

              <div class="mt-4 text-center">
                <h5 class="font-size-14 mb-3">
                  {$_('auth.sign_in_with')}
                </h5>
                <GoogleOauth bind:handling={handling} errorDuringLogin={errorDuringLogin}/>
              </div>

              <div class="mt-4 text-center">
                <Link to="auth-recoverpw" class="text-muted">
                  <i class="mdi mdi-lock me-1"/>
                  {$_('auth.forgot')}
                </Link>
              </div>
            </form>
            <div class="mt-5 text-center">
              <p>
                {$_('auth.dont_have')}
                <Link to="register" class="fw-medium text-success">

                  <b>{$_('auth.sign_up')} </b>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
</div>
<!-- <style>
    .small-spinner {
        height: 1em;
        width: 1em;
    }
</style> -->
