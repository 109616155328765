import {appClient, chosenChannel, logoutStoreReadonly} from "../store";
import {derived, get} from 'svelte/store';

/**
 * @template T
 */
export class BasicDAO {
  /**
   * @type {import('svelte/store').Writable<T[]>} store
   */
  #store;
  /**
   * @type {import('svelte/store').Readable<T[]>} store
   */

  store;
  /**
   * @type {string} callback - the prefix callback string for data structure
   */
  callback;

  /**
   *
   * @type {boolean} isFirstLoadHappened - used as a flag to tell system whether we need to load allData on first getorloadall hit
   */
  isFirstLoadHappened = false;

  #chosenChannel;

  /**
   * @type {NodeJS.Timer}
   */
  #reloadInterval;

  /**
   *
   * @param {Writable<T[]>} store
   * @param {string} callback
   * @param {boolean} watchChannel - flag to indicate that requests must include shopId of chose channel, includes automatic data purge
   */
  constructor(store, callback, watchChannel = true) {
    this.#store = store
    this.store = derived(this.#store, $a => $a);
    this.callback = callback
    logoutStoreReadonly.subscribe(() => {
      this.#store.set([])
      this.isFirstLoadHappened = false
    })
    chosenChannel.subscribe(channel => {
      if (channel?.id !== this.#chosenChannel?.id) {
        this.#chosenChannel = channel
        this.#store.set([])
        this.isFirstLoadHappened = false
      }
    })
  }

  #updateStore(id, replace = false) {
    return data => {
      if (data.error || data.errors) return data
      const currentStore = get(this.#store)
      if (currentStore && Array.isArray(currentStore)) {
        const index = currentStore.findIndex(item => item?.id === id)
        currentStore[index] = replace ? data.result : {...currentStore[index], ...data.result};
        this.#store.set(currentStore);
      }
      return data.result
    };
  }


  /**
   *
   *
   * @param id - item id
   * @param {Object} params
   * @param {boolean} raw
   * @returns {Promise<Response | any | {error: string} | undefined>}
   */
  delete(id, params = {}, raw = false) {
    const {url} = appClient.getCommonVars();
    const link = `${url}/${this.callback}/`;
    return appClient.deleteRequest(`${link}${id}/`, {}, params, raw, `${link}*/`).then(data => {
      const currentStore = get(this.#store)
      if (currentStore && Array.isArray(currentStore)) {
        const index = currentStore.findIndex(item => item?.id === id)
        currentStore.splice(index, 1);
        this.#store.set(currentStore);
      }
      return data
    }, err => {
      return err;
    })
  }

  /**
   *
   * @param {number} id - item id
   * @returns {Promise<null|T|Response|any|{error: string}|undefined>}
   */
  async getOrLoad(id) {
    const currentStore = get(this.#store)
    if (currentStore && Array.isArray(currentStore)) {
      const item = currentStore.find(item => item?.id === id)
      if (item) {
        return item
      } else {
        return this.load(id)
      }
    } else {
      return null;
    }
  }


  /**
   *
   * @returns {Promise<T[]|null>}
   */
  async getOrLoadAll(options = {}) {
    if (this.isFirstLoadHappened === false) {
      return this.loadAll(options)
    } else {
      return get(this.#store)
    }

  }

  /**
   *
   * @param {number} id - item id
   * @param {Object} options - additional query for request
   * @returns {Promise<Response | T | {error: string} | undefined>}
   */
  load(id, options = {}) {
    const {url, shopId} = appClient.getCommonVars(this.#chosenChannel);
    if (shopId) {
      options['shop_id'] = shopId
    }
    const link = `${url}/${this.callback}/`;
    return appClient.getRequest(`${link}${id}/`, options, false, `${link}*/`).then(this.#updateStore(id))
  }

  /**
   *
   * @param {Object} options - additional query for request
   * @returns {Promise<Response | T[] | {error: string} | undefined>}
   */
  loadAll(options = {}) {
    const {url, shopId} = appClient.getCommonVars(this.#chosenChannel);
    if (shopId) {
      options['shop_id'] = shopId
    }
    return appClient.getRequest(`${url}/${this.callback}/`, options).then(data => {
      if (data.error || data.errors) return data
      this.#store.set(data.result.results);
      this.isFirstLoadHappened = true;
      return data.result.results
    })
  }

  /**
   *
   * @param {number} id - item id
   * @param {Object} data - data to patch with
   * @returns {Promise<any | {error: string} | undefined>}
   */
  save(data) {
    const {url} = appClient.getCommonVars();
    if (data.id) {
      const link = `${url}/${this.callback}/`;
      return appClient.patchRequest(`${link}${data.id}/`, data, `${link}*/`).then(this.#updateStore(data.id))
    } else {
      return appClient.postRequest(`${url}/${this.callback}/`, data).then(data => {
        if (data.error || data.errors) return data
        const currentStore = get(this.#store)
        currentStore.unshift(data.result)
        this.#store.set(currentStore)
        return data.result
      })
    }

  }

  /**
   * Starts automatic reloading at the specified interval. It is your work not to forget to stop this process
   *
   * @param {number} interval - The interval in milliseconds at which to reload.
   * @param {Object} options - Optional parameters for the loadAll call.
   * @returns {void}
   */
  startAutoReload(interval, options = {}) {
    this.stopAutoReload()
    this.#reloadInterval = setInterval(() => {
      this.loadAll(options)
    }, interval)
  }

  /**
   * Stops the automatic reload of the content.
   *
   * @return {void}
   */
  stopAutoReload() {
    this.#reloadInterval && clearInterval(this.#reloadInterval);
  }
}
