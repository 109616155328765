<script>
  import {Container, Row, Col, Card, Modal, ModalBody, Button, Spinner} from "sveltestrap";
  import {_, locale} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import {avatarNumber} from "../../helpers/utils";
  import {layout} from "../../services/loaders";
  import {addToast} from "../../helpers/toast";
  import {BotLibraryDAO, botLibraryStore, copyBot} from "../../helpers/onetouch/BotLibraryDAO";
  import {chosenChannel} from "../../helpers/store";
  // import SelectDropdownFilter from "../../Components/SelectDropdownFilter.svelte";
  import NoChannelsWrapper from "../../Components/NoChannelsWrapper.svelte";
  import ChooseTheChannel from "../../Components/Modals/ChooseTheChannelModal.svelte";

  let chosenChannelLocal;

  let botLibraryLoader = true;
  let loaderReq = false;
  let search = '';
  let localeCode;
  let selectedBotIndex = null;

  const unsub = chosenChannel.subscribe(value => {
    if (value === null || value === "") {
      chosenChannelLocal = null;
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        botLibraryLoader = true;
        layout(botLibraryLoader);
        search = '';
        chosenChannelLocal = value
        BotLibraryDAO.getOrLoadAll().finally(() => {
          botLibraryLoader = false;
          layout(botLibraryLoader)
        })
      }
    }
  });

  const unsub2 = locale.subscribe(value => localeCode = value);

  onDestroy(() => {
    unsub();
    unsub2();
  });

  function onSearch() {
    return $botLibraryStore.filter(item => item[`title_${localeCode}`]?.toLowerCase()?.includes(search.toLowerCase()) && item.is_active);
  }

  function closeModal() {
    if (loaderReq) {
      return;
    }
    selectedBotIndex = null;
  }

  function checkImage(title) {
    return `/assets/images/icons/bot/${avatarNumber(title ? title : '0', 15)}.svg`
  }

  function req() {
    loaderReq = true;
    copyBot($botLibraryStore[selectedBotIndex].id, chosenChannelLocal).then(data => {
      if (!data || data.error || data.status === 'error') {
        addToast({title: $_("error"), message: $_("something_went_wrong")})
        return;
      }
      addToast({title: $_("BotLibrary.req_success")});
    }).catch(err => {
      console.error(err);
      addToast({title: $_("error"), message: $_("something_went_wrong")})
    }).finally(() => {
      loaderReq = false;
      closeModal();
    })
  }
</script>

<NoChannelsWrapper>
  <div class="page-content BotLibrary px-0 px-sm-2">
    <Container fluid>
      <div class="font-size-20 fw-600 mb-4">{$_('BotLibrary.title')}</div>
      <!-- <div class="text-muted mb-4">{$_('')}</div> -->
      <div class="mb-4">
        <div class="d-flex flex-wrap align-items-center justify-content-end justify-content-md-start gap-3 mb-1">
          <div class="position-relative w-100 Search">
            <input bind:value={search} placeholder={$_('search')} type="text"
              class="border-1 rounded-2 text-muted form-control w-100"/>
            <i class="bx bx-search-alt position-absolute font-size-20 text-muted top-0"/>
          </div>
          <!-- <SelectDropdownFilter title={$_('')} list={[{name: 'text_teg', checked: true}]} outline={false}/> -->
        </div>
        <!-- <div class="badges">
          {#each [{name: 'text_teg', checked: false}] as tag}
            {#if tag.checked}
              <span class="badge">
                {tag.name}
                <i class="mdi mdi-close pointer" on:click={() => tag.checked = false}/>
              </span>&nbsp;
            {/if}
          {/each}
        </div> -->
      </div>
      <Row>
        {#if $botLibraryStore.filter(item => item.is_active).length}
          {#each (search ? onSearch() : $botLibraryStore.filter(item => item.is_active)) as item, i}
            <Col md={6} lg={6} xl={4} xxl={3}>
              <Card>
                <div class="card-body d-flex flex-column justify-content-between">
                  <div>
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <div class="d-flex align-items-center gap-3 mb-3 pointer" on:click={() => selectedBotIndex = i}>
                      <img src={checkImage(item.title_en)}
                        class="rounded-circle bg-light IconBot" alt="bot icon"/>
                      <div class="overflow-hidden">
                        <div class="text-muted mb-1">{$_('BotLibrary.bot')}</div>
                        <div class="fw-600 font-size-16 text-truncate">{item[`title_${localeCode}`]}</div>
                      </div>
                    </div>
                    <div class="text-muted mb-2 Description">{item[`description_${localeCode}`]}</div>
                  </div>
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <span on:click={() => selectedBotIndex = i}
                    class="pointer text-success fw-600 font-size-15">
                    {$_('BotLibrary.use_this')}
                  </span>
                </div>
              </Card>
            </Col>
          {/each}
        {:else}
          <div>{$_('BotLibrary.empty')}</div>
        {/if}
      </Row>

      {#if selectedBotIndex !== null}
        <Modal isOpen={selectedBotIndex !== null} fade={true} toggle={closeModal} size="lg">
          <ModalBody>
            <div class="position-relative BotLibraryModal">
              <button type="button" class="btn-close position-absolute end-0" aria-label="Close" on:click={closeModal}/>
              <div class="d-flex align-items-center gap-3 mb-3">
                <img src={checkImage( $botLibraryStore[selectedBotIndex].title_en)}
                  class="rounded-circle bg-light IconBot" alt="bot icon"/>
                <div class="overflow-hidden">
                  <div class="text-muted mb-1">{$_('BotLibrary.bot')}</div>
                  <div class="fw-600 font-size-16 text-truncate">
                    {$botLibraryStore[selectedBotIndex][`title_${localeCode}`]}
                  </div>
                </div>
              </div>
              {#if $botLibraryStore[selectedBotIndex]?.image_url}
                <div class="text-muted mb-3">
                  <img src={$botLibraryStore[selectedBotIndex].image_url}
                    class="  bg-light  w-100" alt="bot icon"/>
                </div>
              {/if}
              <div class="text-muted mb-3">
                {$botLibraryStore[selectedBotIndex][`description_${localeCode}`]}
              </div>
              <div class="d-flex flex-wrap gap-2">
                <Button type="button" color="success" disabled={loaderReq} on:click={req}
                  class="d-inline-flex align-items-center gap-2 pr-3 pe-4">
                  <i class="bx bx-copy-alt font-size-20 lh-1"/>
                  <span class="font-size-14">
                    {$_('BotLibrary.copy_bot')}
                  </span>
                  {#if loaderReq}
                    <Spinner size="sm"/>
                  {/if}
                </Button>
                <Button outline color="danger" class="px-4" disabled={loaderReq} on:click={closeModal}>
                  <span class="font-size-14">{$_('cancel')}</span>
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      {/if}
    </Container>
  </div>
</NoChannelsWrapper>
<ChooseTheChannel/>

<style>
  @media (min-width: 768px) {
    .BotLibrary .Search {
      max-width: 500px;
    }
  }

  .BotLibrary .Search input {
    padding-left: 40px;
  }

  .BotLibrary .bx-search-alt {
    top: 0;
    left: 11px;
    line-height: 40px;
  }

  .BotLibrary .card-body {
    height: 230px;
  }

  .BotLibrary .IconBot, .BotLibraryModal .IconBot{
    width: 68px;
    height: 68px;
  }

  .BotLibrary .Description {
    height: 65px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  /* .BotLibrary .badges {
    line-height: 2;
  }

  .BotLibrary .badges .badge {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 7px;
    color: #fff;
    background-color: #34ba73;
  } */
</style>
