<script>
  import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Button,
    Input,
    Spinner,
    Card,
    CardBody,
  } from "sveltestrap";
  import {Link, navigate} from "svelte-routing";
  import { _ } from "svelte-i18n";
  import {toolbar} from "../../services/loaders";
  import * as amplitude from "@amplitude/analytics-browser";
  import {postRequest, promisifyStore} from "../../helpers/utils";
  import {DEFAULT_ADMINISTRATOR_DASHBOARD, DEFAULT_OPERATOR_DASHBOARD, defaultConst} from "../../common/constants";
  import {addToast} from "../../helpers/toast";
  import {channels, user} from "../../helpers/store";
  import {onDestroy} from "svelte";

  let loading = false;
  let activeUser = false;
  let name = "";
  let placeholder = $_("verification.placeholder.select");
  let selectedIndustry = "";
  let selectedSize = "";
  let selectedFeedback = "";

  const unsub = user.subscribe((value) => {
    if (value) {
      activeUser = value
    }

  });

  onDestroy(() => {
    unsub();
  })


  async function handleSubmit() {
    if(loading) return;
    // Для теста спиннера:
    loading = true;
    toolbar(loading)

    let code = '';
    let params = {
      userToken: activeUser?.userToken,
      info: {
        companyName: name,
        companyIndustry: selectedIndustry,
        companySize: selectedSize,
      }
    };

    try {
      params.info.aid = amplitude.getDeviceId();
    } catch (e) {
    }

    let result;

    try {
      result = await postRequest(
          `${defaultConst.functionsUrl}/userCompanyInfo`,
          params
      );
    } catch (e) {
    }

    loading = false;
    toolbar(loading)
    dataLayer.push({event: "verification_success"});
    if (!result || result.error) {
      await addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
    } else {
      if (activeUser.isOperator) {
        navigate(DEFAULT_OPERATOR_DASHBOARD);
      } else {
        navigate(DEFAULT_ADMINISTRATOR_DASHBOARD);
      }
    }

  }
</script>

<div class="verification mx-auto d-flex flex-column justify-content-center">
  <div>
    <Container fluid>
      <Card class="px-2 mx-auto">
        <CardBody class="p-0">
          <Row>
            <Col
              md="5"
              class="bg-soft-green p-5 d-none d-md-flex justify-content-center"
            >
              <img
                class="object-fit-contain"
                src="/assets/images/companyInfo.svg"
                alt="girl sitting at laptop"
              />
            </Col>
            <Col md="7" class="p-5">
              <div class="support-content">
                <h2 class="header-title mb-3">
                  {$_("verification.title")}
                </h2>
                <p>
                  {$_("verification.subtitle")}
                </p>
              </div>

              <form on:submit|preventDefault={handleSubmit}>
                <FormGroup>
                  <Label for="name"
                    >{$_("verification.label.company")}</Label
                  >
                  <Input
                    class="font-size-13"
                    type="text"
                    required
                    id="name"
                    bind:value={name}
                    placeholder={$_("verification.placeholder.title")}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="industry"
                    >{$_("verification.label.industry")}</Label
                  >
                  <Input
                    type="select"
                    name="select"
                    id="industry"
                    required
                    bind:value={selectedIndustry}
                    class={!selectedIndustry && "verification-placeholder"}
                  >
                    {#if placeholder}
                      <option value="" disabled selected>{$_("verification.placeholder.select")}</option>
                    {/if}
                    <option value="1" class="verification-text">{$_("verification.selector.industry.1")}</option>
                    <option value="2" class="verification-text">{$_("verification.selector.industry.2")}</option>
                    <option value="3" class="verification-text">{$_("verification.selector.industry.3")}</option>
                    <option value="4" class="verification-text">{$_("verification.selector.industry.4")}</option>
                    <option value="5" class="verification-text">{$_("verification.selector.industry.5")}</option>
                    <option value="6" class="verification-text">{$_("verification.selector.industry.6")}</option>
                    <option value="7" class="verification-text">{$_("verification.selector.industry.7")}</option>
                    <option value="8" class="verification-text">{$_("verification.selector.industry.8")}</option>
                    <option value="9" class="verification-text">{$_("verification.selector.industry.9")}</option>
                    <option value="10" class="verification-text">{$_("verification.selector.industry.10")}</option>
                    <option value="11" class="verification-text">{$_("verification.selector.industry.11")}</option>
                    <option value="12" class="verification-text">{$_("verification.selector.industry.12")}</option>
                    <option value="13" class="verification-text">{$_("verification.selector.industry.13")}</option>

                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="size">{$_("verification.label.size")}</Label>
                  <Input
                    type="select"
                    name="select"
                    id="size"
                    required
                    bind:value={selectedSize}
                    class={!selectedSize && "verification-placeholder"}
                  >
                    {#if placeholder}
                      <option value="" disabled selected>{$_("verification.placeholder.select")}</option>
                    {/if}
                    <option value="1" class="verification-text">1-10</option>
                    <option value="2" class="verification-text">11-20</option>
                    <option value="3" class="verification-text">21-50</option>
                    <option value="4" class="verification-text">51-100</option>
                    <option value="5" class="verification-text">101-500</option>
                    <option value="6" class="verification-text">500+</option>
                  </Input>
                </FormGroup>

                <!-- <FormGroup>
                  <Label for="feedback"
                    >{$_("verification.label.feedback")}
                    <span class="color-green">1msg</span>?</Label
                  >
                  <Input
                    type="select"
                    name="select"
                    id="feedback"
                    bind:value={selectedFeedback}
                    class={!selectedFeedback && "verification-placeholder"}
                  >
                    {#if placeholder}
                      <option value="" disabled selected>{placeholder}</option>
                    {/if}
                    <option value="1" class="verification-text">1</option>
                    <option value="2" class="verification-text">2</option>
                    <option value="3" class="verification-text">3</option>
                    <option value="4" class="verification-text">4</option>
                    <option value="5" class="verification-text">5</option>
                  </Input>
                </FormGroup> -->

                <div class="d-flex gap-3 pt-3">
                  <Button
                    type="submit"
                    color="success"
                    disabled={loading}
                    class="d-flex gap-2 align-items-center px-5"
                  >
                    {#if loading}
                      <Spinner size="sm" />
                    {/if}
                    {$_("save")}
                  </Button>
                  <Button
                    color="light"
                    disabled={loading}
                    class="d-flex gap-2 align-items-center px-4 d-none"
                  >
                    <!-- {#if loading}
                                <Spinner size="sm" />
                            {/if} -->
                    {$_("skip")}
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  </div>
</div>
