<script>
  import MessageInfo from "../MessageInfo.svelte";

  export let message;
  export let classes = '';
  export let direction;
  export let scheduled = false;
  export let liteVersio = false;

  const urlRegex = /\b(https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const checkUrl = /\b(https?:\/\/)/;
  const parts = (text) => {
    return typeof text === "string" ? text?.trim()?.split(urlRegex) : [''];
  };

  const truncatedText = (text) => {
    return (typeof text === "string" ? text?.slice(0, 90) : '') + (text.length > 90 ? '...' : '');
  };
</script>

<div class="{!liteVersio ? 'Wrapper' : ''} {classes}">
  {#if message?.header}
    <div class="font-size-15 fw-600 pb-1 Text" title={scheduled ? message.header : ''}>
      {#if message.text}
        {#each parts(scheduled ? truncatedText(message.header) : message.header) as part}
          {#if checkUrl.test(part)}
            <a href="{part}" target="_blank" rel="nofollow noopener">{part}</a>
          {:else}
            {part}
          {/if}
        {/each}
      {/if}
    </div>
  {/if}
  <div class="font-size-16 Text" title={scheduled ? message.text : ''}>
    {#if message?.text}
      {#each parts(scheduled ? truncatedText(message.text) : message.text) as part}
        {#if checkUrl.test(part)}
          <a href="{part}" target="_blank" rel="nofollow noopener">{part}</a>
        {:else}
          {part}
        {/if}
      {/each}
    {/if}
    {#if !message?.footer}
      <MessageInfo {message} {direction} {scheduled} classes={liteVersio ? 'pe-0' : ''}/>
    {/if}
  </div>
  {#if message?.footer}
    <div class="TextFooter font-size-14 pt-1 Text" title={scheduled ? message.footer : ''}>
      {#each parts(scheduled ? truncatedText(message.footer) : message.footer) as part}
        {#if checkUrl.test(part)}
          <a href="{part}" target="_blank" rel="nofollow noopener">{part}</a>
        {:else}
          {part}
        {/if}
      {/each}
      <MessageInfo {message} {direction} {scheduled} classes={liteVersio ? 'pe-0' : ''}/>
    </div>
  {/if}
</div>

<style>
  .Wrapper {
    padding: 8px 12px;
  }

  .Text {
    white-space: pre-wrap;
    word-break: break-word;
  }

  .TextFooter {
    color: rgba(0, 0, 0, 0.4);
  }
</style>
