<script>
    import {Col, Row, Card, CardBody} from "sveltestrap";
    import {_} from "svelte-i18n";
    import {Link, navigate} from "svelte-routing";
    import ReceiptsModal from "./ReceiptsModal.svelte";

    export let channel;
    export let type = 'card';

    let receiptsModal;
</script>

{#if channel.stopped}
    {#if type === 'card'}
        <Row class="justify-content-center align-items-center">
            <Col md={8} xl={6} xxl={5}>
                <Card>
                    <CardBody>
                        <div class="text-center">
                            <div class="mx-auto">
                                <i class="text-warning mdi mdi-alert-outline fontSize70"/>
                            </div>
                            <div>
                                <h3 class="text-warning">{$_('stopped.title')}</h3>
                                <p class="mb-4 font-size-16">{$_('stopped.' + (channel.stoppedComment || 'default'))}</p>
                            </div>
                            {#if !channel.stoppedComment || channel.stoppedComment == 'no_subscription'}
                                <Link to="billing" class="btn btn-lg btn-outline-success" type="button">
                                    {$_("stopped.to_subscription")}
                                </Link>
                            {:else}
                                <button class="btn btn-lg btn-outline-secondary" on:click={() => receiptsModal = true} type="button">
                                    {$_("stopped.open_receipts")}
                                </button>
                            {/if}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    {:else if type === 'alert'}
        <div role="alert" class="alert alert-warning py-3">
            <h4 class="alert-heading">
                <i class="mdi mdi-alert-outline me-2"/>{$_('stopped.title')}
            </h4>
            <p>{@html $_('stopped.' + (channel.stoppedComment || 'default'))}</p>
            {#if !channel.stoppedComment || channel.stoppedComment == 'no_subscription'}
                <Link to="billing" class="btn btn-lg btn-warning" type="button">
                    {$_("stopped.to_subscription")}
                </Link>
            {:else}
                <button class="btn btn-lg btn-warning" on:click={() => receiptsModal = true} type="button">
                    {$_("stopped.open_receipts")}
                </button>
            {/if}
        </div>
    {/if}

    {#if receiptsModal}
        <ReceiptsModal show={receiptsModal} channel={channel} toggleModal={() => receiptsModal = !receiptsModal}/>
    {/if}

    <style>
        .fontSize70 {
            font-size: 70px
        }
    </style>
{/if}
