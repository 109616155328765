<script>
  import {Input, Modal, ModalHeader, ModalBody, Button, Label, Spinner, Card, CardBody, FormGroup} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {addToast} from "../../helpers/toast";
  import {postRequest} from "../../helpers/utils";
  import {defaultConst} from "../../common/constants";
  import {user, corporate} from "../../helpers/store";
  import {onDestroy} from "svelte";

  export let card = true;
  export let modalBillingDetails = {
    isOpen: false,
    data: null
  };

  let billingDetails = false;
  let toggleBillingDetails = false;
  let loaderUpdateCorp = false;
  let activeUser = false;
  let loaderCorporateInvoice = false;

  const unsub = user.subscribe((value) => {
    if (!activeUser) activeUser = value;
  });

  const unsub1 = corporate.subscribe(value => {
    if (value) billingDetails = value;
  });

  onDestroy(() => {
    unsub();
    unsub1();
  });

  const reqUpdateCorpName = (name) => {
    return new Promise(resolve => {
      postRequest(`${defaultConst.functionsUrl}/updateCorp`, {
        property: "name",
        value: name,
        userToken: activeUser.userToken
      }).then(res => {
        resolve(res.result);
      });
    });
  };

  const reqUpdateCorpInn = (inn) => {
    return new Promise(resolve => {
      postRequest(`${defaultConst.functionsUrl}/updateCorp`, {
        property: "inn",
        value: inn,
        userToken: activeUser.userToken
      }).then(res => {
        resolve(res.result);
      });
    });
  };

  const updateCorp = (event) => {
    const name = event.target.elements.company_name.value;
    const inn = event.target.elements.inn.value;
    loaderUpdateCorp = true;
    Promise.all([reqUpdateCorpName(name), reqUpdateCorpInn(inn)]).then(results => {
      const [resultName, resultInn] = results;
      if (resultName.status === "success" && resultInn.status === "success") {
        addToast({title: $_("saved")});
        billingDetails = {...billingDetails, name, inn};
        toggleBillingDetails = false;
        loaderUpdateCorp = false;
      }
    }).catch(error => {
      console.error(error);
      loaderUpdateCorp = false;
    });
  };

  const corporateInvoice = () => {
    loaderCorporateInvoice = true;
    const {url, params} = modalBillingDetails.data;
    postRequest(url, params, true).then(res => {
      loaderCorporateInvoice = false;
      if (res.ok) {
        res.blob().then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          let filename = res.headers.get('Content-Disposition').split(';').pop().split('"')[1];
          link.download = `corporate-invoice-${filename}`;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
              title: "Успех",
              message: "Счет на оплату создан"
          });
        });
      } else {
        addToast({
            title: $_("error"),
            message: res.error || $_("something_went_wrong"),
            dismissible: true,
            timeout: 4_000
        });
      }
    });
  };
</script>

{#if card}
  <Card>
    <CardBody class="p-3">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="font-size-18 fw-600">
          {$_("billing.billing_info.title")}
        </div>
        <button type="button" class="border-0 p-0 bg-transparent color-inherit" on:click={() => toggleBillingDetails = !toggleBillingDetails}>
          <i class="bx bx-edit-alt font-size-20"/>
        </button>
      </div>
      {#if toggleBillingDetails}
        <form on:submit|preventDefault={updateCorp}>
          <FormGroup>
            <Label class="bold">{$_("billing.billing_info.company_name")}</Label>
            <Input type="text" placeholder={$_("billing.billing_info.company_name")} name="company_name" value={billingDetails.name} required/>
          </FormGroup>
          <FormGroup>
            <Label class="bold">{$_("billing.billing_info.inn")}</Label>
            <Input type="text" placeholder={$_("billing.billing_info.inn")} name="inn" value={billingDetails.inn} required
              minlength="10" maxlength="12"/>
          </FormGroup>
          <Button color="success" class="px-3" disabled={loaderUpdateCorp}>
            {$_("billing.billing_info.save_details")}
            {#if loaderUpdateCorp}
              <Spinner size="sm" class="ms-2"/>
            {/if}
          </Button>
        </form>
      {:else}
        <div class="mb-3">
          <span class="bold">{$_("billing.billing_info.company_name")} : </span>
          <span class="text-muted">{billingDetails.name || "-"}</span>
        </div>
        <div>
          <span class="bold">{$_("billing.billing_info.inn")} : </span>
          <span class="text-muted">{billingDetails.inn || "-"}</span>
        </div>
      {/if}
    </CardBody>
  </Card>
{/if}

{#if modalBillingDetails.isOpen}
  <Modal isOpen={modalBillingDetails.isOpen} fade={true} toggle={() => {if (!loaderUpdateCorp) modalBillingDetails.isOpen = false}} size="md" centered>
    <ModalHeader toggle={() => {if (!loaderUpdateCorp) modalBillingDetails.isOpen = false}} class="border-bottom-0 pb-0">
      <span class="font-size-20 fw-bold">
        {$_("billing.billing_info.title")}
      </span>
    </ModalHeader>
    <ModalBody>
      <form on:submit|preventDefault={updateCorp}>
        <FormGroup class="mb-3">
          <Label class="bold">{$_("billing.billing_info.company_name")}</Label>
          <Input type="text" placeholder={$_("billing.billing_info.company_name")} name="company_name" value={billingDetails.name} required/>
        </FormGroup>
        <FormGroup class="mb-3">
          <Label class="bold">{$_("billing.billing_info.inn")}</Label>
          <Input type="text" placeholder={$_("billing.billing_info.inn")} name="inn" value={billingDetails.inn} required
            minlength="10" maxlength="12"/>
        </FormGroup>
        <div class="d-flex justify-content-between flex-wrap gap-3">
          <Button color="success" class="px-3" disabled={loaderUpdateCorp}>
            {$_("billing.billing_info.save_details")}
            {#if loaderUpdateCorp}
              <Spinner size="sm" class="ms-2"/>
            {/if}
          </Button>
          <Button type="button" color="primary" class="px-3" disabled={!billingDetails || loaderCorporateInvoice} on:click={corporateInvoice}>
            {$_("billing.billing_info.get_an_invoice_for_payment")}
            {#if loaderCorporateInvoice}
              <Spinner size="sm" class="ms-2"/>
            {/if}
          </Button>
        </div>
      </form>
    </ModalBody>
  </Modal>
{/if}
