<script>
  import {Row, Col, Card, Dropdown, DropdownToggle, DropdownMenu} from "sveltestrap";
  import {onMount} from "svelte";
  import {_} from "svelte-i18n";
  import {getRates} from "../../../helpers/getRates";
  import {subscribeProcess} from "../../../helpers/subscribe";
  import YookassaModal from "../../../Components/Modals/YookassaModal.svelte";
  import CorporateInvoiceHostingBtn from "../../../Components/CorporateInvoiceHostingBtn.svelte";

  export let channel;
  export let user;

  let loading, yooModal, pricing = [], discountBool = false;

  onMount(async () => {
    await updateProfile();
  })

  async function updateProfile() {
    if (channel.project) {
      pricing = await getRates(channel.project, channel);
    }
  }

  async function subscribe(subscribeParams) {
    if (channel.paymentProfileId) return;
    if (!subscribeParams || !subscribeParams.payment.id) return;
    let params = Object.assign({
      id: channel.id,
      userToken: user.token,
      currency: subscribeParams.price.currency,
      rate: subscribeParams.price.rate,
      org: subscribeParams.price.org,
      provider: subscribeParams.payment.id,
      success_url: window.location.href
    });

    let result = await subscribeProcess(params);
    window.dataLayer.push({
      event: 'onboarding_create_subscription',
      type: subscribeParams.payment.id,
      plan: subscribeParams.price.rate,
      currency: subscribeParams.price.currency
    });

    loading = false;
    if (params.provider === "yookassa" && result) {
      return toggleYoomodal(true, result)
    }
  }

  function toggleYoomodal(active = false, yooToken = false) {
    if (active && yooToken) {
      yooModal = true;
      const checkout = new window.YooMoneyCheckoutWidget({
        confirmation_token: yooToken, //Токен, который перед проведением оплаты нужно получить от ЮKassa
        return_url: `https://my.1msg.io/channels-payments`, //Ссылка на страницу завершения оплаты
        error_callback(error) {
          console.log(error);
          //Обработка ошибок инициализации
        }
      });

      //Отображение платежной формы в контейнере
      setTimeout(async () => {
        checkout.render('yookassa-form');
      }, 300);
    } else {
      yooModal = false;
    }
  }
</script>

<Card>
  <div class="p-0 card-body">
    <div class="px-4 px-sm-5 py-5 bg-white">
      <h5 class="text-center fw-600 mb-4 card-title">{$_('channel.meta.title')}</h5>
      <div class="text-center mb-5">
        <div class="rounded-pill d-inline-flex align-items-center gap-3 checkboxWrapper">
          <label class="custom-switch">
            <input type="checkbox" class="color-green" bind:checked={discountBool}/>
            <div class="custom-switch-slider">
              <div class="custom-switch-knob"/>
            </div>
          </label>
          <span class="font-size-18 fw-600">
            <span class="d-none d-sm-inline-block">купить со скидкой</span>
            <span class="d-sm-none">скидка</span>
          </span>
        </div>
      </div>
    </div>
    <div class="px-3 px-sm-5 pb-5">
      <Row>
        <Col>
          <div class="row mt_60">
            {#if pricing && pricing.ru}
              {#each pricing.ru as payment, index}
                <Col md={6} class="mb-4 mb-md-0">
                  <div class="rounded-2 h-100 bg-white d-flex flex-column justify-content-between Subscription">
                    <div class="mb-4">
                      <div class="d-flex justify-content-between pt-3 pe-3 mb-4">
                        <div>
                          <div class="font-size-16 fw-600 px-3 py-2 mb-2 me-3 d-inline-flex {payment.active ? 'Subscription-green text-white' : 'Subscription-gray'}">
                            {$_(payment.prices[discountBool ? 1 : 0].title)}
                          </div>
                          <div class="text-muted font-size-12 px-3 text-break">
                            {$_(payment.prices[discountBool ? 1 : 0].rate)}
                          </div>
                        </div>
                        <div>
                          {#if payment.prices[discountBool ? 1 : 0].discount}
                            <span class="border border-success rounded px-2 py-1 font-size-11 text-success fw-600 text-nowrap">
                              {$_(payment.prices[discountBool ? 1 : 0].discount)}
                            </span>
                          {/if}
                        </div>
                      </div>
                      <div class="px-3">
                        <div class="text-center mb-4">
                          <div>
                            <sup class="font-size-18">{$_(payment.prices[discountBool ? 1 : 0].price.currency)}</sup>
                            <span class="font-size-30">{payment.prices[discountBool ? 1 : 0].price.value}/</span>
                            <span class="font-size-16 fw-600">{$_(payment.prices[discountBool ? 1 : 0].duration)}</span>
                          </div>
                          {#if payment.prices[discountBool ? 1 : 0].discount}
                            <div class="text-success font-size-13 Subscription-savings">
                              {Math.round(payment.prices[discountBool ? 1 : 0].price.value / 12)}
                              р / месяц
                            </div>
                          {/if}
                        </div>
                        <ul class="m-0 pt-2 ps-0 pe-3 Subscription-list">
                          {#each payment.features as feature}
                            {#if feature.active}
                              <li class="mb-2 position-relative">
                                <i class="bx bx-check font-size-24 text-success position-absolute start-0"/>
                                <span class="font-size-13">{$_(feature.text)}</span>
                              </li>
                            {/if}
                          {/each}
                        </ul>
                      </div>
                    </div>
                    <div class="d-flex align-items-center flex-column gap-2 pb-4 px-3">
                      <button type="button" class="btn btn-success font-size-16 w-100" on:click={() => {
                        loading = `subscribe_${index}`;
                        subscribe(payment.prices[discountBool ? 1 : 0])
                      }} disabled={loading}>
                        {#if loading === `subscribe_${index}`}
                          <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                        {/if}
                        Оформить подписку
                      </button>
                      <CorporateInvoiceHostingBtn
                        uid={user.id}
                        rate={payment.prices[discountBool ? 1 : 0].price.rate}
                        currency={payment.prices[discountBool ? 1 : 0].price.currency}
                        org={payment.prices[discountBool ? 1 : 0].price.org}
                        channelId={channel.id}
                        userToken={user.userToken}
                        {loading}
                        classes='btn btn-primary font-size-16 w-100'
                        styles='padding: 11px; max-width: 230px;'
                      />
                    </div>
                  </div>
                </Col>
              {/each}
              <YookassaModal show={yooModal} {toggleYoomodal}/>
            {/if}
          </div>
        </Col>
        <Col xxl={4} class="ps-4 pt-4">
          <div class="font-size-16 fw-600 mb-2">Официальный WABA</div>
          <div class="font-size-12 text-muted mb-4">Легальное подключение к WhatsApp Business API без риска блокировки номера</div>
          <div class="font-size-16 fw-600 mb-2">Выделенный менеджер</div>
          <div class="font-size-12 text-muted mb-4">Подключение занимает от 30 минут до 2 дней, мы поможем вам на всех этапах.</div>
          <div class="font-size-16 fw-600 mb-2">Готово к работе</div>
          <div class="font-size-12 text-muted mb-4">Используйте API в готовых интеграциях, разработайте свою или общайтесь с клиентами в нашем сервисе.</div>
          <div class="font-size-16 fw-600 mb-2">Бесплатно 1000 диалогов</div>
          <div class="font-size-12 text-muted mb-5">Первая тысяча входящих диалогов от клиентов бесплатно вне зависимости от тарифа.</div>
          <Dropdown class="mb-2">
            <DropdownToggle tag="div" class="pointer d-inline-block text-primary text-wrap font-size-14 text-decoration-underline" caret>
              <i class="mdi mdi-alert-circle-outline me-1"/>Оплата не доступна в вашей стране?
            </DropdownToggle>
            <DropdownMenu class="p-3 mt-1">
              <div class="text-wrap text-muted">
                {@html $_('channel.meta.step0.dropdown.text2')}
              </div>
            </DropdownMenu>
          </Dropdown>
          <Dropdown class="mb-2">
            <DropdownToggle tag="div" class="pointer d-inline-block text-primary text-wrap font-size-14 text-decoration-underline" caret>
              <i class="mdi mdi-alert-circle-outline me-1"/>{$_('channel.meta.step0.dropdown1.text1')}
            </DropdownToggle>
            <DropdownMenu class="p-3 mt-1">
              <div class="text-wrap text-muted">
                {@html $_('channel.meta.step0.dropdown1.text2')}
              </div>
            </DropdownMenu>
          </Dropdown>
          <Dropdown class="mb-2">
            <DropdownToggle tag="div" class="pointer d-inline-block text-primary text-wrap font-size-14 text-decoration-underline" caret>
              <i class="mdi mdi-alert-circle-outline me-1"/>Как провести оплату по счету?
            </DropdownToggle>
            <DropdownMenu class="p-3 mt-1">
              <div class="text-wrap text-muted">
                {@html $_('channel.meta.step0.dropdown2.text2')}
              </div>
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>
    </div>
  </div>
</Card>

<style>
  .card-title {
    font-size: 25px;
  }

  .card-body {
    background-color: #DAF4EB;
  }
  
  .checkboxWrapper {
    padding: 10px 20px;
    background: #DAF4EB;
  }

  .mt_60 {
    margin-top: -60px;
  }

  .Subscription {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
  }

  .Subscription-gray {
    background: rgba(206, 206, 206, 0.25);
  }

  .Subscription-green {
    background: rgba(35, 177, 109, 0.80);
  }

  .Subscription-savings {
    margin-top:-5px;
    margin-bottom: -14.5px;
  }

  .Subscription-list {
    list-style-type: none;
  }

  .Subscription-list li {
    padding-left: 35px;
  }

  .Subscription-list i {
    top: -3px;
  }

  .Subscription .btn {
    padding: 11px;
    max-width: 230px
  }

  @media (min-width: 576px) {
    .card-title {
      font-size: 32px;
    }
    .checkboxWrapper {
      padding: 13px 30px;
    }
  }
</style>
