<script>
  import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
  } from "sveltestrap";
  import {Link, navigate} from "svelte-routing";
  import {onMount, onDestroy} from "svelte";
  import {paymentProfiles,channels, projects, updateChannels, layoutLoad} from "../../helpers/store";
  import {_} from "svelte-i18n";
  import {timer} from "../../helpers/utils";

  // export let params;
  // export let location;


  let authStatus, phone, userChannels, channel,paymentProfilesLocal = false;

  onMount(async () => {

  });

  onDestroy(() => {
    unsub();
  });

 const unsub = paymentProfiles.subscribe(async (value) => {
    paymentProfilesLocal = value;
  });

</script>

<div class="page-content">
  <Container fluid>
    <Row>

      <Col xl="8">
        <Row>
          <Col xl="6" xxl="4">
            <Card class="channel-mini-card">
              <CardBody>
                <div class="d-flex align-items-start justify-content-between">
                  <div>
                    <p class="subtitle">
                      +++
                    </p>
                  </div>
                  <img src="../assets/images/icons/phone-channel.png" alt="icon">
                </div>
              </CardBody>
            </Card>
          </Col>
          {#if paymentProfilesLocal}
            {#each paymentProfilesLocal as paymentProfile}
              <Col xl="6" xxl="4">
                <Card class="channel-mini-card">
                  <CardBody>
                    <div class="d-flex align-items-start justify-content-between">
                      <div>
                        <p class="subtitle">
                          {paymentProfile.name}
                        </p>
                        {paymentProfile.type}
                        {paymentProfile.currency}
                        {paymentProfile.cardNumber?paymentProfile.cardNumber:''}
                      </div>
                      <img src="../assets/images/icons/folder-channel.png" alt="icon">
                    </div>
                  </CardBody>
                </Card>
              </Col>
            {/each}
          {/if}
        </Row>
      </Col>
    </Row>
  </Container>
</div>
