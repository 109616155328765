<script>

  const documentPath = () => {
    let lang = 'en';
      if (localStorage.getItem("I18N_LANGUAGE")) {
        lang = localStorage.getItem("I18N_LANGUAGE");
      }

    let path = 'https://1msg-docs.web.app/';

    if (lang !== 'en') path += lang;

    if (window.location.href.includes('#')) {
      let parts = window.location.href.split('#');
      path += '#' + parts[1];
    }
    return path;
  };
</script>


<div class="custom-pt70">
  <iframe src={documentPath()} class="d-block border-0 w-100 h_minus40" title="documentation"/>
</div>

<style>
  iframe {
    height: calc(100vh - 70px)
  }
</style>
