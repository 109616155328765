<script>
  import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner} from "sveltestrap";
  import {appClient, chosenChannel, user} from '../../../../helpers/store';
  import {_} from "svelte-i18n";
  import {createEventDispatcher, onDestroy} from 'svelte';

  export let activeOperators;
  export let activeOperator;
  export let disabled;
  export let operatorIdForFilter;
  export let unreadMessagesFilter;
  export let activeTab;

  const dispatch = createEventDispatcher();
  let _listOperators = [];
  let listOperators = [];
  let expectInputReFocus = false;
  let isOpen = false;
  let inputElement;
  let chosenChannelLocal_id;
  let localFilter = '';

  $: isOpen && setTimeout(scrollToEl, 1);
  $:activeOperator && calculateOperatorsToShow();
  $:{
    if (!disabled && expectInputReFocus) {
      expectInputReFocus = false;
      setTimeout(() => {
        inputElement.focus()
      }, 1)
    }
  }

  function calculateOperatorsToShow() {
    if (_listOperators.length && activeOperator) {
      listOperators = _listOperators.filter(({profile}) => activeOperator?.profile?.id !== profile.id);
      operatorIdForFilter = activeOperator?.type === "shop_administrator" ? 0 : activeOperator?.profile?.id;
    }
  }

  const unsub = activeOperators.subscribe(value => {
    _listOperators = value;
    calculateOperatorsToShow(value);
  });

  const pushChange = () => {
    dispatch('change', {
      localFilter: localFilter ?? ''
    });
    expectInputReFocus = true
  };

  function deleteFilter() {
    localFilter = '';
    pushChange()
  }

  const unsub3 = chosenChannel.subscribe(async (value) => {
    if (value === null || value === "" || value === undefined) {
      deleteFilter()
    } else {
      if (chosenChannelLocal_id !== value?.id) {
        chosenChannelLocal_id = value?.id
        deleteFilter()
      }
    }
  });

  onDestroy(() => {
    unsub();
    unsub3();
  });

  function scrollToEl() {
    const container = document.querySelector('.FilterOperators .scrollbar');
    const el = document.getElementById(`FilterOperators-${operatorIdForFilter}`);
    if (el) {
      container.scrollTop = el.offsetTop - container.offsetTop;
    }
  }

  function select(id) {
    operatorIdForFilter = id;
    isOpen = false;
  }

  const clickInput = () => {
    if ($user.userHintsHintInbox_5 === false) {
      $user.userHintsHintInbox_5 = true;
    }
  };
</script>

<div class="d-flex mb-2 mb-lg-3 position-relative Search">
  <div class="w-100 {!activeOperator ? 'skeleton-wave' : ''}">
    <form class="card m-0 d-flex flex-row overflow-hidden" on:submit|preventDefault={pushChange}>
      <div class="w-100 position-relative">
        <input bind:this={inputElement} bind:value={localFilter} class="border-0 text-muted form-control" on:change={() => {if(localFilter.length === 0) pushChange()}}
          {disabled} on:click={clickInput} placeholder={$_('chat_inbox.Search.placeholder')} type="text" minlength="4"/>
        {#if localFilter && !disabled}
          <button type="button" class="position-absolute top-0 bx bx-x font-size-20 text-muted border-0 bg-transparent p-0" on:click={deleteFilter}/>
        {/if}
      </div>
      <button type="submit" disabled={!localFilter.length}
        class="p-0 m-0 d-flex align-items-center justify-content-center border-0 btnSearch {!localFilter.length ? 'bg-white' : 'bg-success'}">
        {#if disabled}
          <Spinner size="sm" color={!localFilter.length ? 'muted' : 'white'}/>
        {:else}
          <i class="bx bx-search-alt font-size-20 {!localFilter.length ? 'text-muted' : 'text-white'}"/>
        {/if}
      </button>
    </form>
  </div>
  {#if activeOperator?.type === "shop_administrator"}
    <div class="d-flex align-items-center ms-2 FilterOperators">
      <Dropdown {isOpen} toggle={() => isOpen=!isOpen}>
        <DropdownToggle tag="div" class="pointer d-flex" caret>
          <i class="bx bx-filter"/>
        </DropdownToggle>
        <DropdownMenu end>
          <div class="scrollbar">
            <!-- <DropdownItem header>{$_('chat_inbox.Search.read')}</DropdownItem>
            <DropdownItem>{$_('chat_inbox.Search.yes')}</DropdownItem>
            <DropdownItem>{$_('chat_inbox.Search.no')}</DropdownItem>-->
            <button type="button"
              class="dropdown-item text-truncate {unreadMessagesFilter ? 'active': ''}"
              on:click={() => {
                unreadMessagesFilter = !unreadMessagesFilter;
                isOpen = false;
              }}>
              {$_('chat_inbox.Tab.unread')}
            </button>
            <DropdownItem divider />
            <DropdownItem header>{$_('chat_inbox.Search.assigned_to')}</DropdownItem>
            <button type="button" id="FilterOperators-{activeOperator.profile.id}"
              class="dropdown-item text-truncate {operatorIdForFilter === activeOperator.profile.id ? 'active': ''}"
              disabled={operatorIdForFilter === activeOperator.profile.id}
              on:click={() => {select(appClient.currentUserOnetouch?.id); activeTab = 1}}>
              {$_('chat_inbox.Tab.my_open')}
            </button>
            <button type="button" id="FilterOperators-{0}"
              class="dropdown-item text-truncate {operatorIdForFilter === 0 ? 'active': ''}"
              disabled={operatorIdForFilter === 0}
              on:click={() => {select(0); activeTab = 1}}>
              {$_('chat_inbox.Tab.all_open')}
            </button>
            {#each listOperators as item}
              <button type="button" id="FilterOperators-{item.profile.id}"
                class="dropdown-item text-truncate {operatorIdForFilter === item.profile.id ? 'active': ''}"
                disabled={operatorIdForFilter === item.profile.id}
                on:click={() => {select(item.profile.id); activeTab = 1}}>
                {item.profile.user.username}
              </button>
            {/each}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  {/if}
</div>

<style>
  .Search {
    z-index: 1;
  }

  .Search input {
    padding-right: 40px;
  }

  .Search .btnSearch {
    width: 46px;
    height: 40px;
  }

  .Search .bx-x {
    right: 10px;
    height: 40px;
  }

  .Search .bx-filter {
    font-size: 30px
  }

  .Search .FilterOperators .scrollbar {
    width: 245px;
    max-height: calc(100vh - 450px);
    overflow-y: auto;
  }

  .Search .FilterOperators .scrollbar::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  .Search .FilterOperators .scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .Search .FilterOperators .scrollbar .dropdown-item {
    color: #495057;
  }

  .Search .FilterOperators .scrollbar .dropdown-item.active {
    background-color: #f8f9fa !important;
  }

  .Search .skeleton-wave {
    position: relative;
    overflow: hidden;
    background-color: #f1f1f1; /* Skeleton background color */
    z-index: 2; /* Ensures the skeleton is positioned above other elements */
  }

  .Search .skeleton-wave::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.5), transparent); /* Wave gradient colors */
    animation: wave-animation 1.5s infinite linear;
    z-index: 3; /* Ensures the wave animation is positioned above the underlying content */
  }

  /* Add the following CSS to hide the underlying content */
  .Search .skeleton-wave::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f1f1f1; /* Skeleton background color */
    z-index: 1; /* Ensures the overlay is positioned above other elements */
  }

  @keyframes wave-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(180%);
    }
  }

  .Search .form-control:disabled {
    background-color: #fff;
  }
</style>
