<script>
import {_} from "svelte-i18n";
import {CardBody, Col, Row} from "sveltestrap";
</script>
<div class="card mb-2 Tips">
  <CardBody class="p-0">
    <Row class="m-0 px-3 pt-3">
      <Col md={8} class="p-0 pb-3">
        <div class="font-size-12 fw-600 text-dark mb-2">
          {$_('hints.knowledgebase_banner.title')}
        </div>
        <div class="font-size-13 text-secondary mb-2">
          {$_('hints.knowledgebase_banner.text')}
        </div>
        <a href="https://help.1msg.io/1msg-knowledge-base/" target="_blank" class="font-size-13 d-inline-flex align-items-center lh-1 gap-2 btn btn-success">
          {$_('hints.knowledgebase_banner.button')} <i class="bx bx-right-arrow-alt font-size-15"/>
        </a>
      </Col>
      <Col md={4} class="p-0 d-none d-md-flex align-items-end">
        <img src="/assets/images/question_man2.svg" alt="question man"/>
      </Col>
    </Row>
  </CardBody>
</div>
<style>
    .Tips {
        background-color: #F2F7C7;
    }
</style>
