import {appClient} from "../store";
import {writable} from 'svelte/store';
import {BasicDAO} from "./BasicDAO";

/**
 * @typedef {Object} BotLibraryData
 * @property {string} name - The name.
 * @property {string} description - The description.
 * @property {?string} image_url - The URL of the image.
 * @property {?string} is_active - The status of the bot.
 * @property {?string} sort_order - The sort order.
 * @property {?string} title_en - The title en.
 * @property {?string} title_es - The title es.
 * @property {?string} title_ru - The title ru.
 */

/**
 * @type {BasicDAO<BotLibraryData>}
 */
const urlBotLibrary = 'api/bot_library';
const BotLibraryDAO = new BasicDAO(new writable([]), urlBotLibrary);
const botLibraryStore = BotLibraryDAO.store;

const copyBot = (template_id, chosenChannel) => {
  const {url, shopId} = appClient.getCommonVars(chosenChannel);
  const data = {
    shop_id: shopId,
    template_id
  };
  return appClient.postRequest(`${url}/${urlBotLibrary}/install/`, data);
};

export {
  BotLibraryDAO,
  botLibraryStore,
  copyBot
}