import {derived, get, writable} from "svelte/store";
import {eventTracking, postRequest, promisifyStore} from "./utils";
import {defaultConst} from "../common/constants";
import {user} from "./store";


/**
 * @type {import('svelte/store').Writable<string>} _store
 */
let _store = new writable('')
/**
 * @type {import('svelte/store').Readable<string>} currentOpenHintStore
 */
let currentOpenHintStore = derived(_store, $a => $a);
/**
 *
 * @type {import('svelte/store').Writable<boolean>} isOpenHintStore
 */
let _isOpenHintStore = new writable()
let isOpenHintStore = derived(_isOpenHintStore, $a => $a)

promisifyStore(user, item => item?.id).then(data => {
  setCurrentOpenHintStore(localStorage.getItem(`CurrentOpenHintStore_${data?.id ?? ''}`) ?? '')
  setIsOpenHintStore(!!localStorage.getItem(`IsOpenHintStore_${data?.id ?? ''}`))
})


/**
 *
 * @type {Map<string, boolean>}
 */
let checkMap = new Map()

/**
 * closes hint window, stores info about manual closing in local database not to open it next time automatically
 */
function closeHintsWindow() {
  localStorage.setItem(_getLocalStorageKeyForSaving(get(_store)), 'closed')
  eventTracking('cabinet_hint_close', {hint_name: get(_store)})
  setCurrentOpenHintStore('');
  minimizeHintsWindow()
}

function _getLocalStorageKeyForSaving(hintName) {
  return `${get(user).id ?? ''}_${hintName}`
}

/**
 *
 * @param {boolean} value
 */
function setIsOpenHintStore(value) {
  _isOpenHintStore.set(value);
  localStorage.setItem(`IsOpenHintStore_${get(user).id ?? ''}`, value ? 'true' : '')
}

/**
 *
 * @param {string} value
 */
function setCurrentOpenHintStore(value) {
  _store.set(value);
  localStorage.setItem(`CurrentOpenHintStore_${get(user).id ?? ''}`, value)
}

/**
 * minimizesHintsWindow
 */
function minimizeHintsWindow() {

  setIsOpenHintStore(false)
  eventTracking('cabinet_hint_minimize', {hint_name: get(_store)})
}

/**
 * opensHintsWindow
 */
function openHintsWindow() {
  setIsOpenHintStore(true)
  eventTracking('cabinet_hint_maximize', {hint_name: get(_store)})
}

/**
 * open hints if it wasnt ignored previously and there is no open hint yet
 * @param {string} windowName
 */
function setAndOpenHintsWindow(windowName) {
  if (!get(_store) && !localStorage.getItem(_getLocalStorageKeyForSaving(windowName)) && windowName) {
    setCurrentOpenHintStore(windowName)
    eventTracking('cabinet_hint_open', {hint_name: get(_store), forcefully: false})
    setTimeout(openHintsWindow)
  }
}


/**
 * closes current hint, opens presented one
 * @param {string} windowName
 */
function openHintsWindowForcefully(windowName) {
  setCurrentOpenHintStore(windowName)
  eventTracking('cabinet_hint_open', {hint_name: get(_store), forcefully: true})
  openHintsWindow()
}

/**
 * checks windowName for uniq value and presents error if something wrong, throws an error
 * @param {string} windowName
 */
function checkHintsName(windowName) {
  if (!checkMap.get(windowName)) {
    checkMap.set(windowName, true)
  } else {
    console.error(`windowName ${windowName} for userHints is not unique or registered twice!`);
  }
}

/**
 * deletes a value from map
 * @param {string} windowName
 */
function deleteHintsName(windowName) {
  checkMap.set(windowName, false)
}

/**
 * updates user and sets a flag in done position
 * @param {string} hintFlagName
 * @param {string} userToken
 * @returns {Promise<*|{error: string}|undefined>}
 */
function setUserHintDone(userToken, hintFlagName) {
  return postRequest(`${defaultConst.functionsUrl}/addUserHintInfo`, {
    flagName: hintFlagName, flagValue: true, userToken
  });
}

export {
  currentOpenHintStore,
  isOpenHintStore,
  closeHintsWindow,
  deleteHintsName,
  checkHintsName,
  minimizeHintsWindow,
  openHintsWindow,
  setAndOpenHintsWindow,
  setUserHintDone,
  openHintsWindowForcefully
}
