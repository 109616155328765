import {appClient} from "../store";
import {unionBy} from "lodash/array";
import natsClient from "./NatsClient";

import {get, writable} from 'svelte/store';
import {findIndex} from "lodash";

export const toMe = 1;
export const fromMe = 2;
const numberOfLastSearchesToSave = 10;
const secondsToReloadAfter = 30;
export default class ChatsDAO {
  chatsStore;
  activeChatStore;
  blockedChatsStore;
  openWindowStore;

  pollInterval = 10000;
  limit = 500;


  interval;
  last_timestamp;
  first_timestamp;
  channel;

  natsStatusSub;
  natsStatus;


  /**
   * Represents a chat object.
   * @typedef {Object} Chat
   * @property {number} id - The ID of the chat.
   * @property {string} shop_id - The ID of the shop.
   * @property {number} type - The type of the chat.
   * @property {BotUsers} bot_user - The bot user associated with the chat.
   * @property {string} id_in_messenger - The ID of the chat in the messenger.
   * @property {string} url_in_messenger - The URL of the chat in the messenger.
   * @property {string} name - The name of the chat.
   * @property {string} avatar_url - The URL of the avatar for the chat.
   * @property {string} last_message_time - The time of the last message in the chat.
   * @property {string} last_message_text - The text of the last message in the chat.
   * @property {boolean} is_thread_open - A flag indicating if the thread is open.
   * @property {number} assigned_to - The ID of the user assigned to the chat.
   * @property {string} created_at - The creation time of the chat.
   * @property {string} updated_at - The update time of the chat.
   * @property {string} last_read_time - The time of the last read message in the chat.
   * @property {?string} message_id - id of a message thas was searched, used during search operations
   * @property {?string} message_direction - id of a message thas was searched, used during search operations
   */


  /**
   * @typedef {Object} SearchResult
   * @property {string} searchKey - The search string.
   * @property {number} searchDate - The search string.
   * @property {ChatMessageSearch[]} result - The search result.
   */

  /**
   *
   * @type {SearchResult[]} lastSearches - contains last X searches made
   */
  lastSearches = [];

  constructor() {
    this.initStores();
  }

  blockUser(uid, channel) {
    return appClient.blockChatUser(uid, channel).then(data => {
      this.setBlockedUser(data.bot_user_id, data.shop_id)

    })
  }

  //переделать на бридж типаж как только натс научится пушить запросы через сокенты на новые данные
  checkAndSetReceivingMessagesType(oldNatsStatus, newNatsStatus) {
    if (oldNatsStatus) {
      this.unsubscribeWithNats();
    } else {
      this.unsubscribeWithHttp();
    }
    if (newNatsStatus) {
      this.subscribeWithNats();
    } else {
      this.subscribeWithHttp();
    }
  }

  clear() {
    this.chatsStore.set([])
    this.blockedChatsStore.set([])
    this.last_timestamp = null;
    this.first_timestamp = null;
    this.activeChatStore.set({})
  }

  deleteBlockedUser(botUserId) {
    let store = get(this.blockedChatsStore)
    store = store.filter(item => item.bot_user_id !== botUserId)
    this.blockedChatsStore.set(store)
  }

  /**
   * @typedef {Object} NatsDeleteData
   *  @property {number} id - The ID of deleted chat
   */
  /**
   *
   * @param {NatsDeleteData[]} data
   */
  deleteChats(data) {
    if (!data) return;
    let store = get(this.chatsStore)
    const map = new Map();
    data.forEach((obj) => {
      map.set(obj.id, true);
    });
    store = store.filter(item => !map.get(item.id))
    this.chatsStore.set(store)
    const currentActiveId = get(this.activeChatStore).id
    if (map.get(currentActiveId)) {
      this.setActiveChat(null);
    }
  }


  /**
   *
   * @param {string} searchText
   * @returns {Promise<ChatMessageSearch[]|{error: string}>}
   */
  async findMessage(searchText) {
    const filteredText = searchText.trim().toLowerCase()
    const activeChatId = this.getActiveChatId();
    const searchKey = `${activeChatId ?? ''}::${filteredText}`;
    let resIndex = this.lastSearches.findIndex(item => item?.searchKey === searchKey)
    if (resIndex !== -1) {
      if (this.lastSearches[resIndex].searchDate + secondsToReloadAfter * 1000 >= new Date().getTime()) {
        return this.lastSearches[resIndex].result
      } else {
        this.lastSearches.splice(resIndex, 1);
      }
    }
    const options = {text__ilike: filteredText}
    if (activeChatId) options['chat'] = activeChatId
    return appClient.findMessageBy(options, this.channel).then(data => {
      if (data && data.error === undefined) {
        this.pushFoundMessage(searchKey, data);
      }
      return data
    }, err => {
      return {error: err.toString()}
    })

  }

  getActiveChatId() {
    const activeChat = get(this.activeChatStore)
    return activeChat?.id ?? false
  }

  getBlockedChats() {
    return appClient.getBlockedChatUsers(this.channel).then(data => {
      if (Array.isArray(data)) {
        this.blockedChatsStore.set(data)
      } else {
        this.blockedChatsStore.set([])
      }
      return data
    })
  }


  getChannelOpenWindow(chatId) {

    const channelWindow = get(this.openWindowStore)

    if (channelWindow[chatId] === undefined) {
      appClient.getChatOpenWindow(chatId).then(({result}) => {

        let resultData;
        if (result?.status && result?.status !== 'closed') {
          resultData = result?.closeTime
          if (resultData < 0) resultData = 0
        } else if (result?.status === 'closed') {
          resultData = 0;
        }
        this.setChannelOpenWindow(chatId, resultData)
      })
    }
  }

  getChatById(chatId) {
    const chats = get(this.chatsStore);
    const idx = findIndex(chats, ['id', chatId])
    return idx === -1 ? null : chats[idx]
  }

  getChats() {
    return appClient.getChats(this.getCommonFilters(), this.channel).then(({result}) => {
      result?.results && this.prepareChats(result.results)
      return result?.results
    })
  }

  getCommonFilters() {
    return {
      // offset: this.lastLoadedPage * this.limit,
    }
  }

  getNatsSubKey = () => {
    return `chatsDao.${this.channel.id}`
  }

  //сюда должна вкладываться команда когда мы захотим глобальные сторы использовать или испльзовать кеш
  initStores() {
    this.activeChatStore = writable({});
    this.chatsStore = writable([]);
    this.blockedChatsStore = writable([]);
    this.openWindowStore = writable({})
  }

  prepareChats(data) {
    //если измененные данные содержаться до слияния - помеячаем что активный чат надо обновить
    let updateActiveChat = false;
    const activeChatId = this.getActiveChatId();
    for (let i = 0; i < data.length; i++) {
      if (!data[i].bot_user_id && data[i].bot_user?.id)
        data[i].bot_user_id = data[i].bot_user?.id
      if (data[i].id === activeChatId)
        updateActiveChat = true
    }
    const newChats = unionBy(data, get(this.chatsStore), item => item.id);
    newChats.sort((left, right) => right?.last_message_time - left?.last_message_time)

    // this.last_timestamp = newMessages[0]?.ts_in_messenger;
    // this.first_timestamp = newMessages[newMessages.length - 1]?.ts_in_messenger;
    this.chatsStore.set(newChats)
    if (activeChatId) {
      this.setActiveChat(newChats.find(item => item.id === activeChatId))
    }
  }

  prepareNatsData(subject, data) {
    if (subject.indexOf('messages') !== -1)
      this.updateChatDataWithMessageInfo(data)
    if (subject.indexOf('chats') !== -1)
      this.prepareChats([data])
    if (subject.indexOf('chat_delete') !== -1) {
      this.deleteChats(Array.isArray(data) ? data : [data])
    }
  }

  /**
   *
   * @param {string} searchFilter
   * @param {ChatMessageSearch[]} result
   */
  pushFoundMessage(searchFilter, result) {
    if (this.lastSearches.length >= numberOfLastSearchesToSave && this.lastSearches.length !== 0) {
      console.log(this.lastSearches.length)
      let min = Infinity;
      let index = 0;
      for (let i = 0; i < this.lastSearches.length; i++) {
        if (this.lastSearches[i].searchDate < min)
          min = this.lastSearches[i].searchDate
        index = i;
      }
      this.lastSearches.splice(index, 1);
    }

    this.lastSearches.push({searchKey: searchFilter, searchDate: new Date().getTime(), result})
  }

  /**
   *
   * @param {Chat|ChatMessageSearch|null} activeChat
   */
  setActiveChat(activeChat) {
    if (activeChat) {
      const chats = get(this.chatsStore);
      const idx = findIndex(chats, ['id', activeChat.id])
      if (idx !== -1) {
        chats[idx].last_read_time = new Date().getTime() / 1000;
      }
      if (activeChat.id === this.getActiveChatId()) {
        this.activeChatStore.set({...get(this.activeChatStore), ...activeChat})
      } else {
        this.activeChatStore.set(activeChat)
      }
      this.getChannelOpenWindow(activeChat.id)
    } else {
      this.activeChatStore.set({})
    }
  }

  setBlockedUser(botUserId, shopId) {


    const store = get(this.blockedChatsStore)
    store.push({bot_user_id: botUserId, shop_id: shopId})
    this.blockedChatsStore.set(store)
  }

  setChannelOpenWindow(chatId, time) {
    const channelWindow = get(this.openWindowStore)
    if (channelWindow[chatId]) {
      let remainingTime = Math.floor((channelWindow[chatId] - Date.now()) / 1000)
      if (remainingTime < 0) remainingTime = 0;
      if (remainingTime) return
    }
    channelWindow[chatId] = time
    this.openWindowStore.set(channelWindow)

  }

  startListening(channel) {
    this.channel = channel;

    this.natsStatus = get(natsClient.getWatchingStore());
    this.natsStatusSub = natsClient.getWatchingStore().subscribe(status => {
      this.checkAndSetReceivingMessagesType(this.natsStatus, status);
      this.natsStatus = status;
    })
    const res = this.getChats();
    this.getBlockedChats();
    this.checkAndSetReceivingMessagesType(this.natsStatus, this.natsStatus);
    return res
  }

  stopListening() {
    this.unsubscribeWithNats();
    this.unsubscribeWithHttp();
    try {
      this.natsStatusSub && this.natsStatusSub()
    } catch (e) {
    }
  }

  subscribeWithHttp = () => {
    this.interval = setInterval(() => {
      this.getChats();
      this.getBlockedChats();
    }, this.pollInterval)
  }

  subscribeWithNats = () => {
    natsClient.addHandler({id: this.getNatsSubKey(), handleObject: this})
  }

  unBlockUser(uid, channel) {
    return appClient.unblockChatUser(uid, channel).then(data => {
      this.deleteBlockedUser(uid)
    })

  }

  unsubscribeWithHttp = () => {
    this.interval && clearInterval(this.interval)
  }

  unsubscribeWithNats = () => {
    natsClient.deleteHandler(this.getNatsSubKey())
  }

  updateChatDataWithMessageInfo(data) {

    const chats = get(this.chatsStore);

    if (Array.isArray(chats)) {
      const idx = findIndex(chats, ['id', data.chat]);

      if (idx !== -1 && idx !== null && idx !== undefined) {
        const updatedChat = chats[idx]
        const currentTime = new Date().getTime() / 1000;

        if (data?.direction === fromMe) {
          updatedChat.last_read_time = currentTime;
        }
        if (data?.direction === toMe) {
          updatedChat.last_message_time = currentTime;
          this.setChannelOpenWindow(updatedChat.id, Date.now() + 24 * 60 * 60 * 1000)
        }

        const activeChatId = this.getActiveChatId();
        if (activeChatId && activeChatId === data.chat) {
          if (data?.direction === toMe && idx !== -1) {
            updatedChat.last_read_time = currentTime + 1;
          }
        }
        this.prepareChats([updatedChat])
      }
    }
  }

}
