<script>
  import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
  } from "sveltestrap";
  // import {Link} from "svelte-routing";
  import {_} from "svelte-i18n";

  // export let params;
  // export let location;

</script>

<div class="page-content support d-lg-flex flex-lg-column justify-content-lg-center px-0 px-sm-2">
  <Card class="card-support mx-auto">
    <CardBody class="p-0">
      <Container fluid>
        <Row>
          <Col md="8" class="p-4 p-sm-5">
            <div class="support-content">
              <p class="head-title">{$_('support.header')}</p>
            </div>
            <div>
              {@html $_('support.text')}
            </div>
            <div class="d-flex mt-4 gap-3 flex-column align-items-center flex-sm-row justify-content-between">
              <a class="d-flex align-items-center gap-2 text-body" href="https://chatting.page/1msg" target="_blank">
                <div class="icon-container p-3 rounded-circle bg-green">
                  <img class="support-icon" src="/assets/images/dialog.svg" alt="dialog">
                </div>
                <div class="d-flex align-items-center">
                  <p class="m-0 px-2 px-xl-3 py-2 shadow-sm rounded">{$_('support.dialog')}</p>
                </div>
              </a>
              <p class="m-0">{$_('support.or')}</p>
              <a class="d-flex align-items-center gap-2 text-body" href="mailto:hello@1msg.io" target="_blank">
                <div class="icon-container p-3 rounded-circle bg-green">
                  <img class="support-icon" src="/assets/images/message.svg" alt="message">
                </div>
                <div class="d-flex flex-column gap-1 justify-content-center">
                  <p class="m-0 px-2 fw-semibold">Email</p>
                  <p class="m-0 px-2">hello@1msg.io</p>
                </div>
              </a>
            </div>
          </Col>
          <Col md="4" class="bg-soft-green p-5 d-none d-md-flex justify-content-center">
            <img src="/assets/images/man-laptop.png" alt="support-man">
          </Col>
        </Row>
      </Container>
    </CardBody>
  </Card>
</div>


<!-- <div class="page-content support">
  <Container fluid>
    <Row>
      <Col xxl="4" xl="6">
        <Card class="card-support">
          <CardBody>
            <div class="flex-row-line">
              <div class="avatar">
                <img src="/assets/images/icons/support-avatar.png" alt="avatar" height="90px">
              </div>
              <div class="support-content">
                <p class="head-title">{$_('support.header')}</p>
              </div>
            </div>
            <div class="flex-row-line">
              <div>
                {@html $_('support.text')}
              </div>
            </div>
            <div class="flex-row-line">
              <div class="avatar">
                <img src="/assets/images/icons/email-bg.png" alt="avatar" height="55px">
              </div>
              <div class="support-content">
                <p class="subtitle lh1">Email</p>
                <a href="mailto:hello@1msg.io" class="title text-success font-size-20 m-0 lh1">hello@1msg.io</a>
              </div>
            </div> -->
            <!--                        <div class="flex-row-line">-->
            <!--                            <div class="avatar">-->
            <!--                                <img src="/assets/images/icons/email-bg.png" alt="avatar" height="55px">-->
            <!--                            </div>-->
            <!--                            <div class="support-content">-->
            <!--                                <p class="subtitle lh1">Email</p>-->
            <!--                                <p class="title text-success font-size-20 m-0 lh1">support@lokitech.io</p>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="flex-row-line">-->
            <!--                            <div class="avatar">-->
            <!--                                <img src="/assets/images/icons/telegram-bg.png" alt="avatar" height="55px">-->
            <!--                            </div>-->
            <!--                            <div class="support-content">-->
            <!--                                <p class="subtitle lh1">Телеграм</p>-->
            <!--                                <p class="title text-success font-size-20 m-0 lh1">@Loki_Support</p>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="flex-row-line">-->
            <!--                            <div class="avatar">-->
            <!--                                <img src="/assets/images/icons/whatsapp-bg.png" alt="avatar" height="55px">-->
            <!--                            </div>-->
            <!--                            <div class="support-content">-->
            <!--                                <p class="subtitle lh1">WhatsApp</p>-->
            <!--                                <div class="d-flex align-items-center flex-wrap">-->
            <!--                                    <Link to="#!1" class="title text-success font-size-20 lh1 my-0 me-4">1854</Link>-->
            <!--                                    <span class="opacity-50 no-wrap font-size-12">(Нажмите, чтобы начать чат)</span>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->
          <!-- </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
  <img src="/assets/images/icons/man-dashboard.png" alt="man" class="man">
</div> -->
