<script>
  import {_} from "svelte-i18n";
  import Bar from "./Bar.svelte";
</script>

<Bar/>
<div class="bold font-size-18 text-center mb-4">
  {$_('channel.meta.step_progressBar.title')}
</div>
<div class="mb-4 font-size-16 ps-sm-5 position-relative Text">
  <img src="/assets/images/ProgressBar_action_icon.svg" class="position-absolute top-0 start-0 d-none d-sm-inline-block" alt="icon"/>
  <div class="mb-2">
    {@html $_('channel.meta.step_progressBar.list.0')}
  </div>
  <div>
    {@html $_('channel.meta.step_progressBar.list.1')}
  </div>
</div>
<div class="font-size-16 ps-sm-5 position-relative Text">
  <img src="/assets/images/ProgressBar_information_icon.svg" class="position-absolute top-0 start-0 d-none d-sm-inline-block" alt="icon"/>
  <div class="mb-2">
    {@html $_('channel.meta.step_progressBar.list2.0')}
  </div>
  <div>
    {@html $_('channel.meta.step_progressBar.list1.1')}
  </div>
</div>

<style>
  .Text {
    color: #74788D;
  }
</style>
