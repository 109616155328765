<script>
  import {Input, FormGroup, Label} from "sveltestrap";

  export let values;

  function addSections() {
    values.action.sections = [...values.action.sections, {
      title: "",
      product_items: [
        {
          product_retailer_id: ""
        }
      ]
    }]
  }

  function deleteSections(i) {
   values.action.sections = values.action.sections.filter((item, index) => index !== i);
  }

  function addProductItems(i) {
    values.action.sections[i].product_items = [...values.action.sections[i].product_items, {
      product_retailer_id: ""
    }]
  }

  function deleteProductItems(i, j) {
   values.action.sections[i].product_items = values.action.sections[i].product_items.filter((item, index) => index !== j);
  }
</script>

<FormGroup>
  <Label class="fw-600">catalog_id</Label>
  <Input type="text" bind:value={values.action.catalog_id}/>
</FormGroup>
<FormGroup>
  <Label class="fw-600">product_retailer_id</Label>
  <Input type="text" bind:value={values.action.product_retailer_id}/>
</FormGroup>
{#each values.action.sections as section, i}
  <div class="d-flex">
    <FormGroup class="w-100 me-3">
      <FormGroup>
        <Label class="fw-600">title</Label>
        <Input type="text" bind:value={section.title}/>
      </FormGroup>
      {#each section.product_items as item, j}
        <div class="d-flex">
          <FormGroup class="w-100 me-3">
            <Label class="fw-600">product_retailer_id</Label>
            <Input type="text" bind:value={item.product_retailer_id}/>
          </FormGroup>
          <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteProductItems(i, j)}>-</button>
        </div>
      {/each}
      <button type="button" class="btn addBtn rounded-circle" on:click={() => addProductItems(i)}>+</button>
    </FormGroup>
    <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteSections(i)}>-</button>
  </div>
  {#if values.action.sections.length !== i+1}
    <div class="mb-3 mt-1 line bg-success"/>
  {/if}
{/each}
<button type="button" class="btn addBtn rounded-circle" on:click={addSections}>+</button>

<style>
  .line {
    height: 2px;
  }

  .deleteBtn, .addBtn {
    color: #495057 !important;
    width: 40px;
    height: 40px;
    background-color: #EFF2F7;
    box-shadow: none;
  }

  .deleteBtn {
    line-height: 16px;
    font-size: 22px;
    margin-top: 28px;
  }

  .addBtn {
    line-height: 20px;
    font-size: 20px;
  }
</style>