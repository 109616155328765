<script>
  import {Modal, ModalBody} from "sveltestrap";
  import {projects, toggleTrialRestrictionsModal, trialRestrictionsModal} from "../../helpers/store";
  import {_} from "svelte-i18n";
  import ChannelsAddModal from "./ChannelsAddModal.svelte";
  import {onDestroy} from "svelte";

  let projectsList, channelsShowModal;
  const unsub = projects.subscribe(async (value) => {
    projectsList = value;
    projectsList = projectsList
  })

  async function createChannel() {
    channelsShowModal = true;
    toggleTrialRestrictionsModal(false)
  }

  onDestroy(() => {
    unsub();
  });
</script>

<style>
    :global(.modal-trial) {
        padding: 40px;
    }

    .divider {
        border-bottom: 1px solid #eff2f7 !important;
    }
</style>

<Modal isOpen={$trialRestrictionsModal} fade={true} role="dialog" autoFocus={true} data-toggle="modal" backdrop={"static"} size="md" scrollable centered>
    <ModalBody class="modal-trial">
        <button type="button" class="close" receipts-dismiss="modal" aria-label="Close" on:click={() => toggleTrialRestrictionsModal(false)}/>
        <div>
            <div class="text-center">
                <img src="/assets/images/rocket-screen.svg" alt="ready to start"/>
                <div class="mt-3">
                    <strong class="fg-bs-gray-700 font-size-20">{@html $_('trial_restrictions.title')}</strong>
                </div>
                <div class="py-4 position-relative divider">
                    <div class="mb-4">{$_('trial_restrictions.text1')}</div>
                    <a href={$_("trial_restrictions.manager_url")} target="_blank" class="btn btn-lg btn-success mb-1">
                        {$_("trial_restrictions.btn1")}
                    </a>
                    <div class="position-absolute top-100 start-50 translate-middle text-secondary font-size-14 p-1 bg-white">{$_('or')}</div>
                </div>
                <div class="pt-4">
                    <div class="mb-4 text-secondary">{$_('trial_restrictions.text2')}</div>
                    <button on:click={createChannel} class="btn btn-lg btn-outline-success" type="button">
                        {$_("trial_restrictions.btn2")}
                    </button>
                </div>
            </div>
        </div>
    </ModalBody>
</Modal>

{#if projectsList}
    <ChannelsAddModal
        project={projectsList[0]}
        show={channelsShowModal}
        onCloseClick={() => channelsShowModal = false}/>
{/if}
