<script>
  import {Modal, ModalHeader, ModalBody, Label, FormGroup, Col, Row, Spinner, Button} from "sveltestrap";
  import {_} from "svelte-i18n";
  import * as amplitude from '@amplitude/analytics-browser';
  import {addToast} from "../../helpers/toast";
  import {defaultConst} from "../../common/constants";
  import {postRequest} from "../../helpers/utils";

  export let show;
  export let channel;
  // export let profile;
  export let toggleModal;

  let unsubscribeComment = '', loading;

  let listUnsubReason = [
    {key: 1, label: "subscription.unsubscribe_list.1", checked: false},
    {key: 2, label: "subscription.unsubscribe_list.2", checked: false},
    {key: 3, label: "subscription.unsubscribe_list.3", checked: false},
    {key: 4, label: "subscription.unsubscribe_list.4", checked: false},
    {key: 5, label: "subscription.unsubscribe_list.5", checked: false},
    {key: 6, label: "subscription.unsubscribe_list.6", checked: false},
    {key: 7, label: "subscription.unsubscribe_list.7", checked: false},
    {key: 8, label: "subscription.unsubscribe_list.8", checked: false},
    {key: 11, label: "subscription.unsubscribe_list.11", checked: false},
    {key: 10, label: "subscription.unsubscribe_list.10", checked: false}
  ];

  $: if (show === false) {
    listUnsubReason = listUnsubReason.map(item => ({...item, checked: false}));
  }

  async function unsubscribe() {
    if (loading) {
      addToast({title: $_("operation_is_running")});
      return;
    }

    loading = true;
    let params = {id: channel.id, profile: channel.paymentProfileId, unsubscribeComment};
    let result = await postRequest(`${defaultConst.paymentUrl}/unsubscribe/${channel.id}`, params);
    loading = false;

    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong")
      });
    } else {
      let unsubReason = [];
      listUnsubReason.map(item => {
        if (item.checked == true) {
          unsubReason.push(item.key);
        }
      });
      amplitude.identify(new amplitude.Identify().set('unsub_reason', unsubReason));
      addToast({title: $_("saved")});
      show = false;
    }
  }
</script>

<Modal centered fade={true} isOpen={show} size="lg">
  <ModalHeader class="border-bottom-0 pb-0" toggle={toggleModal}>
    <span class="font-size-20 fw-bold text-success">
      {$_('subscription.cancel_subscription')}
    </span>
  </ModalHeader>
  <ModalBody>
    <div class="mb-3">
      {@html $_('subscription.unsubscribe_text')}
    </div>
    <FormGroup>
      <Label>{$_('subscription.unsubscribe_comment')}</Label>
      <Row>
        {#each listUnsubReason as item}
          <Col lg={12}>
            <label class="form-check p-3 border rounded mb-2 font-size-14 {item.checked ? 'border-primary' : ''}">
              <input class="form-check-input ms-0 me-2" type="checkbox" bind:checked={item.checked}/>
              {$_(item.label)}
            </label>
          </Col>
        {/each}
      </Row>
      {#if (listUnsubReason.find(item => item.checked == true))}
        <textarea bind:value={unsubscribeComment} class="form-control" id="comment" rows="1"/>
      {/if}
    </FormGroup>
    <div class="d-flex flex-wrap gap-2">
      <Button class="py-2 px-4" color="success" disabled={loading || !listUnsubReason.find(item => item.checked == true) || (listUnsubReason.find(item => item.checked == true)?.key===10 && !unsubscribeComment)}
        on:click={unsubscribe} type="button">
        <span class="font-size-14">
          {$_("send")}
          {#if loading}
            <Spinner size="sm"/>
          {/if}
        </span>
      </Button>
      <Button class="py-2 px-4" color="danger" on:click={toggleModal} outline type="button">
        <span class="font-size-14">{$_('cancel')}</span>
      </Button>
    </div>
  </ModalBody>
</Modal>

<!-- <Modal isOpen={show}
       fade={true}
       size="md"
       class="modal-add channels"
       scrollable
       centered
>
    <ModalHeader on:click={toggleModal}>
        <span class="text-success">{$_('subscription.cancel_subscription')}</span>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                on:click={toggleModal}
        />
    </ModalHeader>
    <ModalBody>
        <div class="container-fluid">
            <div>{@html $_('subscription.unsubscribe_text')}</div>
            <FormGroup class="mb-4">
                <Label htmlFor="comment" class="col-form-label">{$_('subscription.unsubscribe_comment')}:</Label>

            </FormGroup>
        </div>
        <div class="d-flex align-items-center justify-content-between mt-3">
            <button class="btn btn-success btn-lg waves-effect waves-light"
                    on:click={()=>{toggleModal()}}
                    type="button">
                {$_("cancel")}
            </button>
            <button class="btn btn-lg btn-outline-success" on:click={()=>{unsubscribe()}}
                    type="button">
                {#if loading}
                    <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                {/if}
                {$_("confirm")}
            </button>
        </div>
    </ModalBody>
</Modal> -->
