<script>
  import HorizontalLayout from ".//HorizontalLayout/Index.svelte";
  import VerticalLayout from ".//VerticalLayout/Index.svelte";
  import {onDestroy} from "svelte";
  import {user} from "../helpers/store";
  import UserHintsOffcanvasContainer from "../Components/UserHints/UserHintsOffcanvasContainer.svelte";

  let profileUser;

  const unsub = user.subscribe((value) => {
    profileUser = value;
  });

  onDestroy(() => {
    unsub();
  })

  let layoutType = "vertical";

  let component = layoutType == "vertical" ? VerticalLayout : HorizontalLayout;

  if (layoutType == "horizontal") {
    document.body.setAttribute("data-layout", "horizontal");
  }
</script>

{#if profileUser?.userToken}
  <svelte:component this={component}>
    <slot/>
  </svelte:component>
{/if}
