<script>
  import {Card, CardBody, Row, Col} from "sveltestrap";
  import {_} from "svelte-i18n";
  import FormBroadcast from "./Components/elements/FormBroadcast.svelte";
  import NoChannelsWrapper from "../../Components/NoChannelsWrapper.svelte";
  import ChooseTheChannel from "../../Components/Modals/ChooseTheChannelModal.svelte";
  import {onMount, onDestroy} from "svelte";
  import {BroadcastDAO, BroadcastStatus} from "../../helpers/onetouch/BroadcastDAO";
  import {Link, navigate} from "svelte-routing";
  import {appClient, chosenChannel, balance} from "../../helpers/store";
  import {promisifyStore} from "../../helpers/utils";
  import Spinner from "../../Components/Spinners/SpinnerComponent.svelte";
  import MessagePreview from "../../Components/MessagePreview.svelte";
  import {WabaTemplatesDAO, wabaTemplatesStore} from "../../helpers/onetouch/WabaTemplatesDAO";
  import {addToast} from "../../helpers/toast";

  export let params;

  let data = {};
  let error = {};
  let chosenWabaTemplate;

  let loading = true;
  let showStatistics = false;
  let isSavingBroadcast = false;

  let activeBalance;

  const navigateToBroadcast = async (data = {}) => {
    isSavingBroadcast = false
    if (data.error || data.errors) {
      error = data.error || data.errors
      let errorString = '';
      if (typeof error === "string") {
        errorString = error
      } else {
        for (let errorKey in error) {
          if (typeof error[errorKey] === "string")
            errorString += error[errorKey];
        }
      }
      await addToast({
        title: $_("error"),
        message: errorString || $_("something_went_wrong")
      });
    } else {
      navigate('/broadcast')
    }
  }

  const onDraftSave = () => {
    if (isSavingBroadcast) return;
    isSavingBroadcast = true;
    data.status = BroadcastStatus.DRAFT
    BroadcastDAO.save(data).then(navigateToBroadcast)
  }

  const onBroadcastStart = () => {
    if ((activeBalance?.balance?.toFixed(2) || 0) <= 0 && !$chosenChannel.noAutoCharge) {
      addToast({
        title: $_("error"),
        message: $_('billing.balance.insufficient_funds_balance')
      });
      return;
    }
    if (isSavingBroadcast) return;
    isSavingBroadcast = true;
    data.status = BroadcastStatus.PLANNED
    BroadcastDAO.save(data).then(res => {
      data.status = BroadcastStatus.DRAFT
      navigateToBroadcast(res)
    })
  }

  onMount(() => {
    if (params.id) {
      BroadcastDAO.getOrLoad(parseInt(params.id, 10)).then(res => {
        loading = false;
        data = !res || res.error || res.errors ? null : res;
        if (!data) navigateToBroadcast()
        showStatistics = data.status !== BroadcastStatus.DRAFT
      })
    } else {
      loading = false;
      data.name = ''
      data.starts_at = 0
      data.tags = []
      data.status = BroadcastStatus.DRAFT
      promisifyStore(chosenChannel).then(chosenChannelData => {
        data.shop_id = appClient.getCommonVars(chosenChannelData)?.shopId
      })
    }
  });

  const unsub = balance.subscribe(value => {
    activeBalance = value;
  });

  onDestroy(() => {
    unsub();
  });

  $:{
    if (data?.waba_template?.id) {
      chosenWabaTemplate = $wabaTemplatesStore.find(item => {
        return item.id === data.waba_template.id;
      })
    } else {
      chosenWabaTemplate = null;
    }
  }
</script>

<NoChannelsWrapper>
<div class="page-content">
  {#if loading}
    <div class="d-flex align-items-center justify-content-center p-3">
      <Spinner/>
    </div>
  {:else}
    <div class="container-fluid">
      <Row class="mb-4 align-items-center">
        <Col sm={12} md={8} class="mb-2 mb-md-0 font-size-20 fw-bold text-truncate">
          {data?.id ? $_('broadcast.edit') : $_('broadcast.create')}
          {#if isSavingBroadcast}
            <Spinner class="ssm"/>
          {/if}
        </Col>
        <Col sm={12} md={4}>
          <ol class="breadcrumb m-0 p-0 justify-content-md-end">
            <li class="breadcrumb-item">
              <Link to="/broadcast">{$_('broadcast.title')}</Link>
            </li>
            <li class="breadcrumb-item active">
              {data?.id ? $_('broadcast.edit') : $_('broadcast.create')}
            </li>
          </ol>
        </Col>
      </Row>
      <Row>
        <Col xl={7}>
          {#if showStatistics}
            <Row>
              <Col md={4}>
                <Card>
                  <CardBody class="d-flex justify-content-between gap-2">
                    <div>
                      <div class="font-size-14 text-muted mb-2">{$_('broadcast.table.delivered_sent')}</div>
                      <div class="font-size-18 fw-600">{data.delivered_count ?? 0} / {data.sent_count ?? 0}</div>
                    </div>
                    <div class="avatar-sm mt-1">
                      <span class="avatar-title rounded-circle">
                        <i class="bx bx-mail-send font-size-28"/>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={4}>
                <Card>
                  <CardBody class="d-flex justify-content-between gap-2">
                    <div>
                      <div class="font-size-14 text-muted mb-2">{$_('broadcast.table.answer_rate')}</div>
                      <div class="font-size-18 fw-600">{(data.write_count ?? 0)}</div>
                    </div>
                    <div class="avatar-sm mt-1">
                      <span class="avatar-title rounded-circle">
                        <i class="bx bx-chat font-size-28"/>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={4}>
                <Card>
                  <CardBody class="d-flex justify-content-between gap-2">
                    <div>
                      <div class="font-size-14 text-muted mb-2">{$_('broadcast.table.open_rate')}</div>
                      <div class="font-size-18 fw-600">{(data.read_count ?? 0)}</div>
                    </div>
                    <div class="avatar-sm mt-1">
                      <span class="avatar-title rounded-circle">
                        <i class="bx bx-check-double font-size-28"/>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          {/if}
          <Card>
            <CardBody>
              <FormBroadcast bind:data={data} {onDraftSave} {onBroadcastStart} {error}
                isDisabled={data.status !== BroadcastStatus.DRAFT}/>
            </CardBody>
          </Card>
        </Col>
        <Col xl={5}>
          {#if chosenWabaTemplate }
            <MessagePreview template={chosenWabaTemplate}
              data={data.template_data} channel={$chosenChannel}/>
          {/if}
        </Col>
      </Row>
    </div>
  {/if}
</div>
</NoChannelsWrapper>
<ChooseTheChannel/>

<style>
  .avatar-sm {
    min-width: 3rem;
  }

  .avatar-sm .avatar-title {
    background-color: #DAF4EB;
  }

  .bx-mail-send, .bx-chat, .bx-check-double {
    color: #23B16D;
  }

  .bx-mail-send {
    margin-top: 2px;
    margin-right: 2px;
  }

  .bx-chat {
    margin-top: 2px;
  }
</style>
