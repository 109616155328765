<script>
  import {Button, Modal, ModalBody, ModalHeader, Spinner} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import {addToast} from "../helpers/toast";
  import {appClient, chosenChannel, paymentProfiles, getCorporateBillsSettings} from "../helpers/store";
  import {BotUsersDAO} from "../helpers/onetouch/BotUsersDAO";
  import {TagsDAO} from "../helpers/onetouch/TagsDAO";
  import SelectDropdownCheckbox from "./SelectDropdownCheckbox.svelte";

  export let isOpen = false;
  export let botUsersLoader = false;
  export let close = () => {};
  export let selectedBotUsers = [];

  let chosenChannelLocal;
  let file = null;
  let fileTypes = ["text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  let dragging = false;
  let importBotUsersLoader = false;
  let errorImportBotUsers = null;
  let botUsersTags = [];
  let paymentProfile;

  $: if (isOpen) {
    TagsDAO.loadAll().then(data => {
      botUsersTags = data.map(({id, name}) => ({id, label: name, checkedAdd: false, checkedRemove: false}));
    });
  }

  const unsubChosenChannel = chosenChannel.subscribe(value => {
    paymentProfile = undefined;
    if (value === null || value === "") {
      chosenChannelLocal = null
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        chosenChannelLocal = value;
        getCorporateBillsSettings(chosenChannelLocal.id).then(value => {
          if (value?.org) {
            paymentProfile = {org: value.org};
          }
        });
      }
    }
  });

  $: if (chosenChannelLocal?.paymentProfileId && $paymentProfiles) {
    paymentProfile = $paymentProfiles.find((item) => {
        return item.id === chosenChannelLocal.paymentProfileId
    });
  }

  onDestroy(() => {
    unsubChosenChannel();
  });

  const fileCheck = (data) => {
    errorImportBotUsers = null;
    if (!fileTypes.includes(data.type)) {
      addToast({
        title: $_("error"),
        message: $_("import_bot_users_modal.unsuitable_file_type")
      });
      return;
    }
    file = data;
  };

  const handleDrag = (e) => {
    if (e.type === "dragenter") {
      dragging = true
    } else if (e.type === "dragleave") {
      dragging = false
    }
  };

  const handleDrop = (e) => {
    dragging = false
    const {files} = e.dataTransfer;
    if (files && files.length) {
      fileCheck(files[0]);
    }
  };

  const changeInputFile = (e) => {
    const {files} = e.target;
    if (files && files.length) {
      fileCheck(files[0]);
      e.target.value = '';
    }
  };

  const closeModal = () => {
    isOpen = false
    file = null;
    errorImportBotUsers = null;
    botUsersTags = [];
    close();
  };

  const reqImportBotUsers = () => {
    let addTags = [];
    let removeTags = [];
    botUsersTags.map(tag => {
      if (tag.checkedAdd) {
        addTags.push(tag.id);
      }
      if (tag.checkedRemove) {
        removeTags.push(tag.id);
      }
    });

    importBotUsersLoader = true;
    appClient.importBotUsers(chosenChannelLocal, file, addTags, removeTags).then(data => {
      if (data?.error) {
        addToast({
          title: $_("error"),
          message: $_("something_went_wrong")
        });
        importBotUsersLoader = false;
        return
      }

      if (data?.status === 'success' || data?.status === 'not success') {
        if (data?.phone_invalid && Object.keys(data.phone_invalid).join(', ')) {
          if (data?.bot_user_ids.length) {
            addToast({
              title: $_("error"),
              message: $_("import_bot_users_modal.contacts_have_not_been_added") + ": " + Object.keys(data.phone_invalid).join(', ')
            });
            if (data?.status === 'not success') {
              closeModal()
            }
          } else {
            errorImportBotUsers = {
              phone_invalid: $_("import_bot_users_modal.contacts_have_not_been_added") + ": " + Object.keys(data.phone_invalid).join(', ')
            };
          }
        } else {
          closeModal()
        }
      }

      if (data?.count_contact_change_names) {
        addToast({
          title: $_("saved"),
          message: $_("import_bot_users_modal.count_contact_change_names") + " " + data.count_contact_change_names
        });
        closeModal();
      }

      if (data?.status === 'success' && data?.count_created_users) {
        addToast({
          title: $_("saved"),
          message: $_("import_bot_users_modal.contacts_successfully_imported") + " " + data.count_created_users
        });
        closeModal();
      }

      botUsersLoader = true;
      BotUsersDAO.loadAll().then(() => botUsersLoader = false);

      importBotUsersLoader = false;
      let botUserIds = [];
      data?.bot_user_ids.forEach(id => {
        let res = selectedBotUsers.some(obj => {
          return obj.bot_user_id === id;
        });
        if (!res) {
          botUserIds.push({bot_user_id: id})
        }
      });
      selectedBotUsers = [...botUserIds, ...selectedBotUsers];
    });
  };
</script>

{#if isOpen}
  <Modal isOpen={isOpen} fade={true} toggle={closeModal} size="md" centered>
    <ModalHeader toggle={closeModal} class="border-bottom-0 pb-0">
      <span class="fw-600">{$_('import_bot_users_modal.title')}</span>
    </ModalHeader>
    <ModalBody>
      <form on:submit|preventDefault={reqImportBotUsers}>
        <div class="position-relative mb-3 DragDropFile">
          <label
            on:dragover|preventDefault|stopPropagation
            on:dragenter={handleDrag}
            on:dragleave={handleDrag}
            on:drop|preventDefault|stopPropagation={handleDrop}
            class="position-absolute w-100 h-100 rounded-2 pointer m-0 uploader {dragging ? 'dragging' : ''}">
            <input type="file" name="file" class="d-none" accept={fileTypes.join()} on:change={changeInputFile} />
          </label>
          <div class="w-100 text-center px-3 py-5">
            <div class="mb-1">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64">
                <path fill="#263238" fillOpacity=".5"
                  d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"/>
              </svg>
            </div>
            <span>
              {file?.name ? file.name : $_('import_bot_users_modal.to_add_a_file')}
            </span>
          </div>
        </div>
        <div class="mb-3">{$_('import_bot_users_modal.import_new_contacts_description')}</div>
        {#if paymentProfile?.org !== '1msg_basic'}
          <div class="mb-3">{@html $_('import_bot_users_modal.info_tags')}</div>
          <div class="mb-3">
            <SelectDropdownCheckbox title="broadcast.add_tags" bind:list={botUsersTags} checkedKey="checkedAdd" disabledKey="checkedRemove"/>
          </div>
          <div class="mb-3">
            <SelectDropdownCheckbox title="broadcast.remove_tags" bind:list={botUsersTags} checkedKey="checkedRemove" disabledKey="checkedAdd" badgeColor="DangerColor"/>
          </div>
        {/if}
        <div class="d-flex justify-content-start flex-wrap gap-2">
          <Button type="submit" color="success" class="font-size-14 py-2 px-4 d-inline-flex align-items-center gap-2" disabled={!file}>
            {#if importBotUsersLoader}
              <Spinner size="sm"/>
            {/if}
            {$_('upload')}
          </Button>
          {#if paymentProfile?.org !== '1msg_basic'}
            <a href="/automation/flow/settings_tags_create/" target="_blank"
              class="font-size-14 py-2 px-4 btn btn-outline-success" on:click={() => isOpen = false}>
              {$_("chat_inbox.UserCard.create_tags")}
            </a>
          {/if}
        </div>
        {#if errorImportBotUsers?.phone_invalid}
          <div class="text-danger mr-3 mt-3">{errorImportBotUsers?.phone_invalid}</div>
        {/if}
      </form>
    </ModalBody>
  </Modal>
{/if}

<style>
  .DragDropFile .uploader {
    border: 2px dashed #ced4da;
    transition: border 300ms ease;
  }

  .DragDropFile .uploader:hover, .DragDropFile .uploader.dragging {
    border-color: #92989b;
  }
</style>
