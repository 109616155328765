<script>
  import {Button, Spinner, Modal, ModalBody} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import {addToast} from "../../helpers/toast";
  import {appClient, chosenChannel} from "../../helpers/store";
  import {SegmentDAO} from "../../helpers/onetouch/SegmentDAO";
  import TableBotUsers from "./Components/TableBotUsers.svelte";
  import HeaderForm from "./Components/HeaderForm.svelte";
  import ImportBotUsersModal from "../../Components/ImportBotUsersModal.svelte";
  import AddNewContactModal from "../../Components/AddNewContactModal.svelte";

  export let isOpen = false;
  export let newSegment = () => {};
  let isOpenModalImportBotUsers = false;
  let isOpenModalAddNewContact = false;

  let nameSegment = '';
  let segmentListUser = [];
  let createLoader = false;
  let botUsersLoader = false;

  let chosenChannelLocal = null;
  const unsub3 = chosenChannel.subscribe(value => {
    if (value === null || value === "") {
      chosenChannelLocal = null
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        chosenChannelLocal = value
      }
    }
  });

  onDestroy(() => {
    unsub3();
  });

  const closeModal = () => {
    isOpen = false;
    nameSegment = '';
    segmentListUser = [];
  };

  const Create = () => {
    const {shopId} = appClient.getCommonVars(chosenChannelLocal);
    let data = {
      shop_id: shopId,
      name: nameSegment,
      state: {},
      segment_list_user: segmentListUser
    };
    createLoader = true;
    SegmentDAO.save(data).then(data => {
      if (data?.error || data.errors) {
        console.error(data?.error || data.errors);
        addToast({
          title: $_("error"),
          message: $_("something_went_wrong")
        });
        createLoader = false;
        return
      }
      newSegment(data);
      addToast({title: $_("segments.segment_created")});
      createLoader = false;
      closeModal();
    })
  };

  const funcIsOpenModalImportBotUsers = () => {
    isOpen = false;
    isOpenModalImportBotUsers = true
  };

  const funcIsOpenModalCreateSegment = () => {
    isOpen = true
  };

  const funcIsOpenModalAddNewContact = () => {
    isOpen = false;
    isOpenModalAddNewContact = true
  };
</script>

{#if isOpen}
  <Modal {isOpen} fade={true} toggle={closeModal} size="xl" class="ModalCreateSegment">
    <ModalBody>
      <form on:submit|preventDefault={Create} class="position-relative">
        <button type="button" class="btn-close position-absolute end-0" aria-label="Close" on:click={closeModal}/>
        <HeaderForm bind:name={nameSegment} isOpenModalImportContact={funcIsOpenModalImportBotUsers}>
          <Button slot="btnRight" type="submit" color="success" class="py-2 px-4 d-inline-flex align-items-center gap-2" disabled={createLoader}>
            {#if createLoader}
              <Spinner size="sm"/>
            {/if}
            <span class="font-size-14">{$_('segments.create')}</span>
          </Button>
        </HeaderForm>
        <TableBotUsers bind:selectedBotUsers={segmentListUser} bind:botUsersLoader isOpenModalAddContact={funcIsOpenModalAddNewContact}/>
        <div class="mt-3 text-end">
          <Button type="submit" color="success" class="py-2 px-4 d-inline-flex align-items-center gap-2" disabled={createLoader}>
            {#if createLoader}
              <Spinner size="sm"/>
            {/if}
            <span class="font-size-14">{$_('segments.create')}</span>
          </Button>
        </div>
      </form>
    </ModalBody>
  </Modal>
{/if}

<ImportBotUsersModal bind:isOpen={isOpenModalImportBotUsers} bind:botUsersLoader close={funcIsOpenModalCreateSegment} bind:selectedBotUsers={segmentListUser}/>

<AddNewContactModal {chosenChannelLocal} bind:isOpen={isOpenModalAddNewContact} bind:botUsersLoader close={funcIsOpenModalCreateSegment}/>

<style>
  @media (min-width: 1200px) {
    :global(.ModalCreateSegment) {
      max-width: 1240px;
      padding: 0 20px;
    }
  }
</style>