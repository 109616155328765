<script>
  import {Table} from "sveltestrap";
  import {_} from "svelte-i18n";
  import SpinnerComponent from "./Spinners/SpinnerComponent.svelte";

  export let count = 0;
  export let limit = 20;
  export let offset = 0;
  export let callback = () => {};
  export let loader = false;

  const pagination = (action) =>  {
    switch (action) {
      case 'next':
        offset += limit;
        break;
      case 'previous':
        offset -= limit;
        break;
    }
    callback()
  };
</script>

<div class="position-relative">
  {#if loader}
    <div class="position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center bg-white bg-opacity-75">
      <SpinnerComponent/>
    </div>
  {/if}
  <div class="table-responsive">
    <Table class="align-middle table-nowrap mb-0">
      <thead class="table-light">
        <slot name="thead"/>
      </thead>
      <slot name="tbody"/>
    </Table>
  </div>
  {#if count > limit}
    <ul class="list-inline mb-0 d-flex align-items-center justify-content-end mt-3">
      <li class="list-inline-item font-size-15">
        {offset ? offset : 1} - {offset + limit > count ? count : offset + limit} {$_('of')} {count}
      </li>
      <li class="list-inline-item">
        <button class="p-0 shadow-none rounded-circle font-size-22 PaginationBtn btn"
          disabled={!offset} on:click={() => pagination('previous')}>
          <i class="bx bx-chevron-left"/>
        </button>
      </li>
      <li class="list-inline-item">
        <button class="p-0 shadow-none rounded-circle font-size-22 PaginationBtn btn"
          disabled={count - offset <= limit} on:click={() => pagination('next')}>
          <i class="bx bx-chevron-right"/>
        </button>
      </li>
    </ul>
  {/if}
</div>

<style>
  .PaginationBtn {
    color: #495057;
    line-height: 16px;
    width: 35px;
    height: 35px;
    background-color: #EFF2F7;
  }

  .PaginationBtn:hover {
    background-color: #cbced2;
  }
</style>
