<script>
  import {Row, Col, Card, CardBody, Progress, Spinner} from "sveltestrap";
  import {onMount, onDestroy} from "svelte";
  import {_, locale} from "svelte-i18n";
  import {openSignInWindow} from "../open-popup";
  import {paymentProfiles, updateChannel} from "../../../helpers/store";
  import {getRates} from "../../../helpers/getRates";

  export let channel;
  export let user;

  const partnerId = "BEBD6pPA";
  const requestedNumber = channel.phone;

  const queryParameters = {
    email: user.email,
    name: user.name ?? '',
    state: channel.id,
    // redirect_url: string
    partner: user.id + '_1msg_' + channel.id,
    //next: string
  };

  let activeStep = !!channel.paymentProfileId || !!channel.noAutoCharge;
  let lang, subscribeParams, pricing = [], paymentProfile, paymentProfilesLocal;
  let phone = '', phoneFailed = false, seenWithoutPhone = false, phoneSaving = false;
  let subscriptionTranslations;

  const baseUrl = 'https://hub.360dialog.com';

  let permissionUrl = requestedNumber
    ? `${baseUrl}/dashboard/app/${partnerId}/permissions?number=${requestedNumber}`
    : `${baseUrl}/dashboard/app/${partnerId}/permissions`;

  if (queryParameters) {
    let values = Object.values(queryParameters)
    Object.keys(queryParameters).forEach((k, idx) => {
      if (idx === 0 && !requestedNumber) {
        permissionUrl = permissionUrl + `?${k}=${values[idx]}`;
      } else {
        permissionUrl = permissionUrl + `&${k}=${values[idx]}`;
      }
    })
  }

  $: channel && checkPhone();

  function checkPhone() {
    if (!seenWithoutPhone) {
      seenWithoutPhone = !channel?.phone
    }
  }

  function savePhone() {
    if (!phoneSaving && phone && !phoneFailed) {
      phoneSaving = true;
      updateChannel(channel.id, "phone", phone).then(data => {
        phoneSaving = false;
      }, err => {
        phoneSaving = false;
      })
    }
  }

  const getUrlParameter = (params, name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(params);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  // const callbackAfterPopup = callbackObj => {
  //   console.log(callbackObj);
  // }

  onMount(async () => {
    if (localStorage.getItem("I18N_LANGUAGE")) {
      lang = localStorage.getItem("I18N_LANGUAGE");
    }
    const params = window.location.search;
    const client = getUrlParameter(params, "client");
    const channels = getUrlParameter(params, "channels");
    const revokedChannels = getUrlParameter(params, 'revoked');

    if (client && channels) {
      const channelsArray = channels
        .substring(1, channels.length - 1)
        .split(",");

      let callbackObj = {
        client: client,
        channels: channelsArray,
      };

      if (revokedChannels) {
        const revokedChannelsArray = revokedChannels.substring(1, revokedChannels.length - 1).split(',');
        callbackObj['revokedChannels'] = revokedChannelsArray;
      }
      // callbackAfterPopup(callbackObj);

      // remove search parameters from URL after fetching them
      // window.history.replaceState(null, "", window.location.pathname);
    }

    // send to parent (opener) window and close small window
    if (window.opener) {
      window.opener.postMessage(params);
      window.close();
    }
  });

  const unsubLocale = locale.subscribe(value => {
    if (value) lang = value;
  });

  const unsubPaymentProfiles = paymentProfiles.subscribe(async (value) => {
    paymentProfilesLocal = value;
    paymentProfile = {};
    await updateProfile();
  });

  onDestroy(() => {
    unsubLocale();
    unsubPaymentProfiles();
  });

  async function updateProfile() {
    if (channel && channel.paymentProfileId) {
      paymentProfile = paymentProfilesLocal.find((item) => {
        return item.id === channel.paymentProfileId
      });
    }

    if (channel.project) {
      pricing = await getRates(channel.project, channel);
    }

    if (paymentProfile) {
      if (paymentProfile.org === '1msg_full') {
        subscriptionTranslations = {
          title: 'subscription.all_inclusive',
          classes: 'text-white CardPrice-all-inclusive',
        }
      } else if (paymentProfile.org === '1msg_basic') {
        subscriptionTranslations = {
          title: 'subscription.api_only',
          classes: 'CardPrice-api-only',
        }
      }

      if (paymentProfile.rate === '1 year') {
        subscriptionTranslations = {
          ...subscriptionTranslations,
          rate: 'subscription.annual',
          duration: 'subscription.per_year'
        }
      } else if (paymentProfile.rate === '1 month') {
        subscriptionTranslations = {
          ...subscriptionTranslations,
          rate: 'subscription.monthly',
          duration: 'subscription.per_month'
        }
      }

      subscriptionTranslations = {
        ...subscriptionTranslations,
        features: [
          {active: true, text: 'subscription.api_only_and_all_inclusive_pros.1'},
          {active: true, text: 'subscription.api_only_and_all_inclusive_pros.2'},
          {active: true, text: 'subscription.api_only_and_all_inclusive_pros.3'},
          {active: true, text: 'subscription.api_only_and_all_inclusive_pros.4'},
          {active: false, text: 'subscription.api_only_and_all_inclusive_pros.5'},
          {active: false, text: 'subscription.api_only_and_all_inclusive_pros.6'},
          {active: false, text: 'subscription.api_only_and_all_inclusive_pros.7'}
        ]
      }
    }
  }

  function changePayment(activePricing) {
    subscribeParams = {
      provider: activePricing.payment.id,
      rate: activePricing.price.rate,
      org: activePricing.price.org,
      currency: activePricing.price.currency
    }
  }
</script>

<Row class="justify-content-center">
  <Col xs={12}>
    <Card>
      <CardBody class="p-4 p-sm-5">
        <h5 class="text-center fw-600 pb-3 card-title">{$_('channel.meta.title')}</h5>
        <div class="col-md-10 col-lg-7 m-auto">
          <div class="position-relative mx-4 mt-5">
            <Progress value={100 * activeStep} color="light-green" class="fg-light-green" style="height: 2px"/>
            <button class="position-absolute top-0 start-0 translate-middle double_circles_green">
              <i class="bx bx-check font-size-22"/>
            </button>
            {#if seenWithoutPhone}
              {#if !channel?.phone}
                <button class="position-absolute font-size-20 top-0 start-50 translate-middle btn btn-sm rounded-pill btn-primary"
                  style="width: 37px; height: 37px; line-height: 22px">
                  2
                </button>
              {:else}
                <button class="position-absolute top-0 start-50 translate-middle double_circles_green">
                  <i class="bx bx-check font-size-22"/>
                </button>
              {/if}
              <button class="position-absolute font-size-20 top-0 start-100 translate-middle btn btn-sm rounded-pill {channel?.phone ? "btn-primary" : "bg-solitude-grey bg-soft"}"
                style="width: 37px; height: 37px; line-height: 22px">
                3
              </button>
            {:else}
              <button class="position-absolute font-size-20 top-0 start-100 translate-middle btn btn-sm rounded-pill {activeStep == 1 ? "btn-primary" : "bg-solitude-grey bg-soft"}"
                style="width: 37px; height: 37px; line-height: 22px">
                2
              </button>
            {/if}
          </div>
        </div>
        <div>
          <Row class="mt-3 justify-content-between">
            {#if paymentProfile?.org === '1msg_full' || paymentProfile?.org === '1msg_basic'}
              <Col sm={12} md={6} class="pe-md-5">
                {#if subscriptionTranslations?.features}
                  <div class="mt-5 rounded-3 bg-white overflow-hidden CardPrice">
                    <div class="d-flex gap-1 flex-wrap justify-content-between align-items-center p-3 {subscriptionTranslations.classes}">
                      <div class="font-size-16 fw-600">{$_(subscriptionTranslations.title)}</div>
                      <div class="font-size-14">{$_(subscriptionTranslations.rate)}</div>
                    </div>
                    <div class="px-4 py-3">
                      <div class="text-center mb-3">
                        <sup class="font-size-18">{$_(paymentProfile.currency)}</sup>
                        <span class="font-size-30">{paymentProfile.amount}/</span> <span class="font-size-16 fw-600">{$_(subscriptionTranslations.duration)}</span>
                      </div>
                      <ul class="ms-0 ps-0 pe-3 CardPrice-list">
                        {#if paymentProfile.org === '1msg_full'}
                          {#each subscriptionTranslations.features as feature}
                            <li class="mb-2 position-relative">
                              <i class="bx bx-check font-size-24 text-success position-absolute start-0"/>
                              <span class="font-size-14 text-blue-granite">{$_(feature.text)}</span>
                            </li>
                          {/each}
                        {:else if paymentProfile.org === '1msg_basic'}
                          {#each subscriptionTranslations.features as feature}
                            {#if feature.active}
                              <li class="mb-2 position-relative">
                                <i class="bx bx-check font-size-24 text-success position-absolute start-0"/>
                                <span class="font-size-14 text-blue-granite">{$_(feature.text)}</span>
                              </li>
                            {/if}
                          {/each}
                        {/if}
                      </ul>
                    </div>
                  </div>
                {/if}
              </Col>
            {:else}
              <Col sm={12} md={6}>
                {#if !channel.paymentProfileId}
                  <div class="custom-w-75 mt-5 mx-auto bold text-center font-size-16">{@html $_('channel.meta.step0.text1')}</div>
                  <div class="mt-4">
                    {#if (pricing && pricing[lang])}
                      {#each pricing[lang] as payment}
                        {#each payment.prices as pricing}
                          <div class="mb-3 mx-auto d-flex flex-column flex-sm-row align-items-center justify-content-between form-check payment-check {pricing.price.rate == subscribeParams?.rate && pricing.price.org == subscribeParams?.org && 'active'}"
                            on:click={() => changePayment(pricing)}>
                            <div>
                              <sup style="left: 0.25em;"> {$_(pricing.price.currency)}</sup>
                              {pricing.price.value}/<span class="font-size-16">{$_(pricing.duration)}</span>
                            </div>
                            <div class="current-logo text-end col-4">
                              <img class="img-fluid" src={pricing.payment.logo} alt="logo">
                            </div>
                          </div>
                        {/each}
                      {/each}
                    {/if}
                    <div class="mt-5 text-center">
                      <div class="d-inline-flex flex-column gap-2 align-items-center">
                        <button disabled class="btn btn-lucky-primary w-100">
                          {$_('subscription.subscribe')}
                        </button>
                        {#if lang === 'ru'}
                          <button disabled class="btn btn-lucky-primary">
                            {$_('payments.pay_by_invoice')}
                          </button>
                        {/if}
                      </div>
                    </div>
                  </div>
                {:else}
                  <div class="custom-w-75 mt-5 mx-auto bold text-center">
                    {@html $_('channel.meta.step0.text2')}
                  </div>
                {/if}
              </Col>
            {/if}
            <Col sm={12} md={6}>
              {#if seenWithoutPhone && !channel?.phone}
                <div class="custom-w-75 mt-4 mt-sm-5 mx-auto bold text-center font-size-16">
                  {@html $_('channel.meta.provide_phone_first_registration')}
                </div>
                <div class="form-group mb-4 mt-3">
                  <label class="fg-solitude-grey font-size-14 fw-600 w-100" for="phone">
                    {$_('channel.add_modal.phone')}
                  </label>
                  <input bind:value={phone} class="form-control" id="phone"
                    on:change={() => phoneFailed = false} pattern='^\+[0-9]&#123;7,14&#125;$' placeholder="+1" required>
                  {#if phoneFailed}
                    <div class="text-danger font-size-11">{$_('channel.add_modal.phone_check_error')}</div>
                  {/if}
                </div>
                <p class="font-size-11 p-2 rounded bg-soft bg-solitude-grey">{@html $_('channel.add_modal.phone_text')}</p>
                <p class="font-size-12 mx-auto bold">{$_('channel.meta.provide_phone_first_registration_add_info')}</p>
                <div class="mt-3">
                  <a class="font-size-10" target="_blank"
                    href="https://faq.whatsapp.com/605464643328528/?cms_platform=android&locale=en_US">
                    <i class="narrow-i font-size-14 dripicons-question me-2 fg-solitude-grey text-soft"/>{$_('channel.add_modal.delete')}
                  </a>
                </div>
                <div class="mt-1">
                  <a class="font-size-10" target="_blank"
                    href="https://faq.whatsapp.com/196737011380816/?cms_platform=android&locale=en_US">
                    <i class="narrow-i font-size-14 dripicons-information me-2 fg-solitude-grey text-soft"/>{$_('channel.add_modal.backup')}
                  </a>
                </div>
                <button class="btn-meta mt-3" class:bg-solitude-grey={!activeStep} disabled={!phone || phoneSaving} on:click={savePhone}>
                  {$_("channel.meta.provide_phone_first_registration_save_button")}
                  {#if phoneSaving}
                    <Spinner size="sm"/>
                  {/if}
                </button>
              {:else}
                <div class="custom-w-75 mt-5 mx-auto bold text-center font-size-16">
                  {@html $_('channel.meta.step1.text1')}
                </div>
                <div class="custom-w-75 my-4 mx-auto text-center font-size-14">
                  {@html $_('channel.meta.step1.text2')}
                </div>
                <div class="custom-w-75 my-4 mx-auto text-center font-size-14">
                  <p class="font-size-12 mx-auto bold">{$_('channel.meta.complete_registration_add_info')}</p>
                  <a class="font-size-10" target="_blank"
                    href="https://help.1msg.io/1msg-knowledge-base/onboarding/attaching-your-whatsapp-number-to-waba-detailed-guide">
                    <i class="narrow-i font-size-14 dripicons-question me-2 fg-solitude-grey text-soft"/>{$_('channel.meta.complete_registration_add_info_our_guide')}
                  </a>
                </div>
                <div class="mt-3 d-flex justify-content-center">
                  <button class="btn-meta" class:bg-solitude-grey={!activeStep}
                    on:click={() => openSignInWindow(permissionUrl, 'connect-360dialog', window.location.origin)}>
                    {$_('channel.meta.btn')}
                  </button>
                </div>
              {/if}
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  </Col>
</Row>

<style>
  .CardPrice {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
  }

  .CardPrice-all-inclusive {
    background: rgba(35, 177, 109, 0.80);
  }

  .CardPrice-api-only {
    background: #F5F5F5;
  }

  .CardPrice-list {
    list-style-type: none;
  }

  .CardPrice-list li {
    padding-left: 35px;
  }

  .CardPrice-list i {
    top: -3px;
  }

  .card-title {
    font-size: 20px;
  }

  .narrow-i {
    vertical-align: sub;
  }

  .custom-w-75 {
    width: 100%;
  }

  @media (min-width: 576px) {
    .card-title {
      font-size: 28px;
    }

    .custom-w-75 {
      width: 75%;
    }
  }
</style>
