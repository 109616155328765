<script>
  import Time from "svelte-time";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";

  export let broadcast;

  let chosenLang;

  const lang = _.subscribe(() => {
    chosenLang = localStorage.getItem('I18N_LANGUAGE');
  });

  onDestroy(() => {
    lang();
  });

  const formatting = (date) => {
    return new Date(date * 1000);
  };
</script>

{#if !broadcast.starts_at}
  <span class="font-size-13">
    {$_('broadcast.table.created_on')}
    {#if chosenLang === 'ru'}
      <Time timestamp={formatting(broadcast.created_at)} format="D"/>
      {$_(`month.${formatting(broadcast.created_at).getMonth()}`)}
    {:else}
      {$_(`month.${formatting(broadcast.created_at).getMonth()}`)}
      <Time timestamp={formatting(broadcast.created_at)} format="D"/>
    {/if}
  </span>
{/if}
{#if broadcast.starts_at}
  <span class="font-size-13">
    {broadcast.status === 4 ? $_('broadcast.table.sent_on') : $_('broadcast.table.to_be_sent_on')}
    {#if chosenLang === 'ru'}
      <Time timestamp={formatting(broadcast.starts_at)} format="D"/>
      {$_(`month.${formatting(broadcast.created_at).getMonth()}`)}
      <Time timestamp={formatting(broadcast.starts_at)} format="HH:mm"/>
    {:else}
      {$_(`month.${formatting(broadcast.created_at).getMonth()}`)}
      <Time timestamp={formatting(broadcast.starts_at)} format="D HH:mm"/>
    {/if}
  </span>
{/if}