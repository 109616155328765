<script>
  import {Dropdown, DropdownToggle, DropdownMenu} from "sveltestrap";
  import {_} from "svelte-i18n";

  export let title;
  export let disabled = false;
  export let activeUserId;
  export let usersList;
  export let change;
  export let clickDropdownToggle = () => {};

  let isOpen = false;
  $: activeUserData = usersList.find(({id}) => id === activeUserId);

  const scrollToEl = () => {
    const container = document.querySelector('.SelectDropdownUsers .scrollbar');
    const el = document.getElementById(`SelectDropdownUsers-${activeUserData?.id}`);
    if (el) {
      container.scrollTop = el.offsetTop - container.offsetTop;
    }
  };

  const select = (user) => {
    change(user ? user.id : null);
    isOpen=false;
  };

  $: isOpen && setTimeout(scrollToEl, 1);
</script>

<div class="SelectDropdownUsers {disabled ? 'disabled' : ''}">
  <Dropdown {isOpen} toggle={() => isOpen=!isOpen} class="h-100">
    <DropdownToggle color="light" class="d-flex align-items-center h-100 w-100 {disabled ? 'pe-none' : ''}"
      title={activeUserData ? activeUserData.name : $_(title)} {disabled} on:click={clickDropdownToggle} caret>
      {#if activeUserData}
        {#if activeUserData.avatar_url}
          <img src={activeUserData.avatar_url} alt={activeUserData.name}
            class="me-2 rounded-circle avatar object-fit-cover"/>
        {:else}
          <span class="me-2 avatar">
            <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-10">
              {activeUserData.name.toUpperCase().charAt(0)}
            </span>
          </span>
        {/if}
        <span class="text-truncate font-size-13 pe-2 d-none d-sm-inline-block">
          {activeUserData.name}
        </span>
      {:else}
        <span class="me-2 avatar">
          <span class="avatar-title rounded-circle bg-light">
            &nbsp;
          </span>
        </span>
        <span class="text-truncate font-size-13 pe-2 d-none d-sm-inline-block">
          {$_(title)}
        </span>
      {/if}
      <i class="mdi mdi-chevron-down ms-auto"/>
    </DropdownToggle>
    <DropdownMenu class="w-100" end>
      <div class="scrollbar">
        <button type="button" class="dropdown-item d-flex align-items-center {activeUserData === undefined ? 'active': ''}"
          title={$_(title)}
          id="SelectDropdownUsers-undefined"
          disabled={activeUserData === undefined}
          on:click={() => select()}>
          <span class="me-2 avatar">
            <span class="avatar-title rounded-circle bg-light">
              &nbsp;
            </span>
          </span>
          <span class="text-truncate font-size-12">
            {$_(title)}
          </span>
        </button>
        {#each usersList as item}
          <button type="button" class="dropdown-item d-flex align-items-center {activeUserData?.id === item.id ? 'active': ''}"
            title={item.name}
            id="SelectDropdownUsers-{item.id}"
            disabled={activeUserData?.id === item.id}
            on:click={() => select(item)}>
            {#if item.avatar_url}
              <img src={item.avatar_url} alt={item.name}
                class="me-2 rounded-circle avatar object-fit-cover"/>
            {:else}
              <span class="me-2 avatar">
                <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-10">
                  {item.name.toUpperCase().charAt(0)}
                </span>
              </span>
            {/if}
            <span class="text-truncate font-size-12">
              {item.name}
            </span>
          </button>
        {/each}
      </div>
    </DropdownMenu>
  </Dropdown>
</div>

<style>
  .SelectDropdownUsers {
    height: 36px;
  }

  .SelectDropdownUsers .avatar {
    min-width: 24px;
    max-width: 24px;
    height: 24px;
  }

  .SelectDropdownUsers .scrollbar {
    max-height: calc(100vh - 450px);
    overflow-y: auto;
  }

  .SelectDropdownUsers .scrollbar::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  .SelectDropdownUsers .scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .SelectDropdownUsers .dropdown-item {
    color: #495057;
    padding: 5px 10px;
  }

  .SelectDropdownUsers .dropdown-item.active {
    background-color: #f8f9fa !important;
  }

  .disabled {
    opacity: .65;
  }

  @media (min-width: 576px) {
    .SelectDropdownUsers {
      width: 152px;
    }
  }
</style>