<script>
  import {Container, Row, Col, FormGroup, Label, Button, Input, Card, CardBody} from "sveltestrap";
  import {_} from "svelte-i18n";
  import TextAreaAutosize from "../../Components/TextAreaAutosize.svelte";
  import QRCode from "../../Components/Qr.svelte";
  import {Link} from "svelte-routing";
  import {user} from "../../helpers/store.js";
  import {postRequest} from "../../helpers/utils.js";
  import {defaultConst} from "../../common/constants.js";

  export let center = true;

  let whatsAppNumber = '';
  let email = '';
  let welcomeMessage = '';

  let whatsApplink = null;
  let updateQRCode = false;

  function send() {
    whatsApplink = `https://wa.me/${whatsAppNumber}?text=${welcomeMessage}`;
    updateQRCode = true;
    if (email) {
      let request = {email, phone: whatsAppNumber}
      postRequest(`${defaultConst.functionsUrl}/addUknownUserContact`, request);
    }
    setTimeout(() => {
      updateQRCode = false;
    });
  }

  function download() {
    const download = document.getElementById("downloadCanvasButton");
    const image = document.querySelector(".myQrCanvas").querySelector('canvas').toDataURL("image/png").replace("image/png", "image/octet-stream");
    download.setAttribute("href", image);
  }
</script>

<div class="WhatsappLinkGenerator mx-auto d-flex flex-column justify-content-center {center ? 'CustomMH100' : ''}">
  <Container fluid class="px-0 px-sm-2">
    <Card class="my-4">
      <CardBody>
        <div class="fw-600 font-size-20 mb-3">
          {$_('WhatsappLinkGenerator.title')}
        </div>
        <Row>
          <Col class="pe-lg-4" lg={6}>
            <form on:submit|preventDefault={send}>
              <FormGroup>
                <Label class="fw-600">{$_('WhatsappLinkGenerator.number')}</Label>
                <div class="mb-3 font-size-13">
                  {$_('WhatsappLinkGenerator.description_number')}
                </div>
                <Input bind:value={whatsAppNumber} required type="number"/>
              </FormGroup>
              {#if !$user?.email}
                <FormGroup>
                  <Label class="fw-600">{$_('WhatsappLinkGenerator.email')}</Label>
                  <Input bind:value={email} required type="email"/>
                </FormGroup>
              {/if}
              <FormGroup>
                <Label class="fw-600">{$_('WhatsappLinkGenerator.message')}</Label>
                <div class="mb-3 font-size-13">
                  {$_('WhatsappLinkGenerator.description_message')}
                </div>
                <div class="rounded-2 bg-light">
                  <TextAreaAutosize
                      bind:value={welcomeMessage}
                      classes="border-0 bg-transparent font-size-14"
                      minRows={6}
                      placeholder={$_('WhatsappLinkGenerator.description_placeholder')}/>
                </div>
              </FormGroup>
              <Button class="py-2 px-4" color="success" type="submit">
                <span class="font-size-14">{$_('WhatsappLinkGenerator.generate_now')}</span>
              </Button>
            </form>
          </Col>
          {#if whatsApplink}
            <Col lg={6} class="mt-4 mt-lg-0">
              <FormGroup>
                <Label class="fw-600">{$_('WhatsappLinkGenerator.whatsapp_link')}</Label>
                <div class="font-size-15 text-success rounded px-3 py-2 BorderContainer">
                  {whatsApplink}
                </div>
              </FormGroup>
              <div>
                <Label class="fw-600">{$_('WhatsappLinkGenerator.whatsapp_qr')}</Label>
                <div class="d-flex flex-wrap gap-3">
                  {#if !updateQRCode}
                    <div class="rounded p-3 BorderContainer d-inline-flex">
                      {#if window.innerWidth > 300}
                        <QRCode value={whatsApplink} width="160" height="160"/>
                      {/if}
                      {#if window.innerWidth < 300}
                        <QRCode value={whatsApplink} width="120" height="120"/>
                      {/if}
                    </div>
                  {/if}
                  <div>
                    <div>
                      <a href={whatsApplink} target="_blank" class="py-2 px-3 btn btn-outline-success d-inline-flex align-items-center gap-2">
                        <i class="bx bxl-whatsapp font-size-20 d-none d-sm-inline-block"/>
                        <span class="font-size-14">{$_('WhatsappLinkGenerator.open_whatsapp')}</span>
                      </a>
                    </div>
                    <div class="mt-2">
                      <a id="downloadCanvasButton" on:click={download} class="py-2 px-3 btn btn-outline-success d-inline-flex align-items-center gap-2" download="triangle.png">
                        <i class="bx bx-download font-size-20 d-none d-sm-inline-block"/>
                        <span class="font-size-14">{$_('WhatsappLinkGenerator.download')}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {#if !$user?.id}
                <div class="mt-5">
                  <Link to="register" class="fw-medium text-success">
                    <b>{$_('WhatsappLinkGenerator.register')}</b>
                  </Link>
                </div>
              {/if}
            </Col>
          {:else}
            <div class="col-lg-6 bg-soft-green p-5 h-100 position-absolute top-0 end-0 d-none d-lg-flex justify-content-center rounded-end">
              <img src="/assets/images/companyInfo.svg" alt="girl sitting at laptop" class="object-fit-contain"/>
            </div>
          {/if}
        </Row>
      </CardBody>
    </Card>
  </Container>
</div>

<style>
  .WhatsappLinkGenerator {
    max-width: 1040px;
  }

  .CustomMH100 {
    min-height: 100vh;
  }

  .WhatsappLinkGenerator .BorderContainer {
    border: 1px solid #ced4da;
  }

  .WhatsappLinkGenerator .bg-soft-green {
    background-color: rgb(242, 247, 199);
  }
</style>
