<script>
  import {Modal, ModalBody, Button} from "sveltestrap";
  import {_} from "svelte-i18n";

  import {deleteProjectUser, getProjectUsers} from "../../helpers/store";
  import {addToast} from "../../helpers/toast";

  import Spinner from "../Spinners/SpinnerComponent.svelte";

  export let projectUser;
  export let show;
  export let onCloseClick = () => {};

  let loading = false;

  const deleteUser = async () => {
    loading = true;
    const res = await deleteProjectUser(projectUser.email);

    if (!res || res.error) {
      loading = false;
      addToast({
        title: $_("error"),
        message: res.error || $_("something_went_wrong")
      });
    } else if (res.status === 204) {
      getProjectUsers();
      loading = false;
      onCloseClick();
      show = false;
      addToast({title: $_("users.delete_modal.delete_user_toast")});
    }
  };
</script>

<div class="add-event-modal">
  <Modal
      class="exampleModal"
      isOpen={show}
      fade={true}
      centered={true}
      header={$_("users.delete_modal.title")}
  >
      <div class="modal-content">
        <ModalBody>
          {#if loading}
            <div class="d-flex align-items-center justify-content-center p-1">
              <Spinner/>
            </div>
          {:else}
            {$_('users.delete_modal.text')}
            <Button color="success" class="btn-sm" on:click={deleteUser}>
              <i class="mdi mdi-check"/>
            </Button>
            <Button type="reset" color="danger" class="btn-sm" on:click={onCloseClick}>
              <i class="mdi mdi-close"/>
            </Button>
          {/if}
        </ModalBody>
      </div>
  </Modal>
</div>
