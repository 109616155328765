<script>
  import {Modal, ModalBody, ModalFooter, Button} from "sveltestrap";
  import {onMount, getContext, onDestroy} from "svelte";
  import {_, date} from "svelte-i18n";
  import {channels, projects, user} from "../../helpers/store";
  import {defaultConst} from "../../common/constants";
  import {addToast} from "../../helpers/toast";
  import {postRequest} from "../../helpers/utils";

  import Spinner from "../Spinners/SpinnerComponent.svelte";


  export let show;
  export let project;
  export let onCloseClick;

  let activeUser = {};

  const unsub = user.subscribe((value) => {
    activeUser = value;
  });

  let page = 0; //first page
  let loading = false;

  onMount(() => {

  });
  onDestroy(() => {
    unsub();
  })

  const deleteProjectConfirmed = async () => {
    loading = true;

    let params = {
      projectId: project.id,
      userToken: activeUser.userToken
    };

    let result;
    try {
      result = await postRequest(
        `${defaultConst.functionsUrl}/deleteProject`,
        params
      );
    } catch (e) {
    }
    loading = false;
    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
    } else {
      onCloseClick();
      show = false;
      addToast({title: $_("saved")});
    }

  };


</script>

<div class="add-event-modal">
  <Modal
      class="exampleModal"
      isOpen={show}
      fade={true}
      centered={true}
      toggle={onCloseClick}
      header={$_("project.delete_project")}
      size="lg"
  >
    {#if loading}
      <Spinner></Spinner>
    {:else}
      <div class="modal-content">
        <ModalBody>
          {$_('project.delete_modal_text')} {project.name}?
          <Button color="success" on:click={deleteProjectConfirmed} class="btn-sm">
            <i class="mdi mdi-check"/>
          </Button>
          <Button
              type="reset"
              color="danger"
              class="btn-sm"
              on:click={onCloseClick}
          >
            <i class="mdi mdi-close"/>
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" on:click={onCloseClick}>
            {$_('close')}
          </Button>
        </ModalFooter>
      </div>
    {/if}
  </Modal>
</div>
