<script>
  import {Col, Container, Row, Card, CardBody} from "sveltestrap";
  import {channels, getProjectUsers, projectUsers, showProjectSelectModal, toggleTrialRestrictionsModal, trialBar} from "../../helpers/store";
  import {_} from "svelte-i18n";
  import {onDestroy, onMount, tick} from "svelte";
  import UsersAndRolesModal from "../../Components/Modals/UsersAndRolesModal.svelte";
  import UsersDeleteModal from "../../Components/Modals/UsersDeleteModal.svelte";
  import SpinnerComponent from "../../Components/Spinners/SpinnerComponent.svelte";
  import TableUsers from "./TableUsers.svelte";
  import {navigate} from "svelte-routing";

  let activeUsers = [];

  let _clearInterval;
  let showUsersAndRolesModal = null;
  let showDeleteModal = false;
  let firsTimeLoad = true;
  let projectUserToDelete;
  let projectUserToUpdate;
  let textSearch = "";

  const unsub = projectUsers.subscribe((value) => {
    if (value && value.status === "ok") {
      activeUsers = value.result;
    }
  });

  onMount(() => {
    getProjectUsers().finally(()=>firsTimeLoad=false);
    _clearInterval = setInterval(getProjectUsers, 30000)
  });


  onDestroy(() => {
    _clearInterval && clearInterval(_clearInterval);
    unsub();
  });

  const onSearch = () => {
    return activeUsers.filter(user => user.email.toLowerCase().search(textSearch) !== -1);
  };
</script>

<div class="page-content users-and-roles px-0 px-sm-2">
  <Container fluid>
    <div class="header-title">{$_('users_and_roles.title')}</div>
    <Row>
      <Col xxl="7">
        <Card>
          <CardBody>
            {#if firsTimeLoad}
              <div class="d-flex align-items-center justify-content-center p-3">
                <SpinnerComponent/>
              </div>
            {:else}
              <Row class="mb-2">
                <Col sm={6} md={5}>
                  <div class="position-relative mb-2">
                    <input type="text"
                      class="border-1 rounded-2 text-muted form-control"
                      placeholder={$_('navbar.search.text')}
                      bind:value={textSearch}/>
                    <i class="bx bx-search-alt position-absolute font-size-20 text-muted top-0"/>
                  </div>
                </Col>
                <Col sm={6} md={7}>
                  <div class="text-sm-end">
                    <button
                      on:click={() => {
                          if($trialBar?.show){
                            toggleTrialRestrictionsModal(true);
                          }else{
                            showUsersAndRolesModal="addUser"}
                          }
                        }
                      type="button"
                      class="btn btn-success btn-lg waves-effect waves-light">
                      <i class="bx bx-user-plus fs-1 me-1 font-size-20 align-middle"/>
                      <span class="align-middle font-size-15">{$_('users.add_modal.title')}</span>
                    </button>
                  </div>
                </Col>
              </Row>
              <TableUsers
                data={textSearch ? onSearch() : activeUsers}
                projectUserToDelete={user => projectUserToDelete=user}
                showDeleteModal={() => showDeleteModal=true}
                projectUserToUpdate={user => projectUserToUpdate=user}
                showUpdateModal={() => showUsersAndRolesModal="updateChannel"}/>
            {/if}
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
  {#if showUsersAndRolesModal}
    <UsersAndRolesModal
      showModal={!!showUsersAndRolesModal}
      actionModal={showUsersAndRolesModal}
      onCloseClick={() => {showUsersAndRolesModal=null; projectUserToUpdate=null}}
      {...projectUserToUpdate}/>
  {/if}
  {#if showDeleteModal && projectUserToDelete}
    <UsersDeleteModal
      projectUser={projectUserToDelete}
      show={showDeleteModal}
      onCloseClick={() => showDeleteModal=false}/>
  {/if}
  <img src="/assets/images/icons/bg-phone-and-macbookpro-right.svg" alt="bg-phone-and-macbookpro" class="bg-img"/>
</div>

<style>
  input {
    padding-left: 40px;
  }

  .bx-search-alt {
    top: 0;
    left: 11px;
    line-height: 40px;
  }
</style>
