import {getSpecialRate, isUserCreatedAfter1July2024} from "./store";

let rates = {
        ru: {
            month: 2900,
            year: 28000,
            currency: 'RUB',
            org: '1msg_waba'
        },
        en: {
            month: 39,
            year: 360,
            currency: 'USD',
            org: '1msg_waba'
        },
        es: {
            month: 39,
            year: 360,
            currency: 'USD',
            org: '1msg_waba'
        }
    },
    payments = {
        ru:
            [
                {
                    id: 'yookassa',
                    name: 'Yookassa',
                    logo: `/assets/images/icons/yookassa-logo.png`,
                }
            ],
        en:
            [
                {
                    id: 'stripe',
                    name: 'Stripe',
                    logo: `/assets/images/icons/stripe-logo.png`,
                }
            ],
        es:
            [
                {
                    id: 'stripe',
                    name: 'Stripe',
                    logo: `/assets/images/icons/stripe-logo.png`,
                }
            ]
    };

export async function getRates(project, channel) {
    let ratesLocal = Object.assign(rates);
    if (project && project.specialRateId) {
        let specialRate = await getSpecialRate(project.specialRateId)
        if (specialRate) {
            let forbid = false;
            if (specialRate.flags) {
                for (let flag of specialRate.flags) {
                    if (!channel[flag]) forbid = true;
                }
            }
            if (!forbid) ratesLocal = Object.assign(rates, specialRate)
        }
    }

    let pricing = {
        'ru': [],
        'en': [],
        'es': []
    };

    for(let lang in pricing){
        for (let payment of payments[lang]) {
            if (lang === 'ru' && isUserCreatedAfter1July2024()) {
                pricing[lang].push({
                    payment: payment,
                    features: [
                        {active: true, text: 'subscription.api_only_and_all_inclusive_pros.1'},
                        {active: true, text: 'subscription.api_only_and_all_inclusive_pros.2'},
                        {active: true, text: 'subscription.api_only_and_all_inclusive_pros.3'},
                        {active: true, text: 'subscription.api_only_and_all_inclusive_pros.4'},
                        {active: false, text: 'subscription.api_only_and_all_inclusive_pros.5'},
                        {active: false, text: 'subscription.api_only_and_all_inclusive_pros.6'},
                        {active: false, text: 'subscription.api_only_and_all_inclusive_pros.7'}
                    ],
                    prices: [
                        {
                            title: 'subscription.api_only',
                            rate: 'subscription.monthly',
                            duration: 'subscription.per_month',
                            price: {
                                rate: '1 month',
                                value: 2900,
                                currency: 'RUB',
                                org: '1msg_basic'
                            },
                            payment: payment
                        },
                        {
                            title: 'subscription.api_only',
                            rate: 'subscription.annual',
                            discount: 'subscription.api_only_discount',
                            duration: 'subscription.per_year',
                            price: {
                                rate: '1 year',
                                value: 28000,
                                currency: 'RUB',
                                org: '1msg_basic'
                            },
                            payment: payment
                        }
                    ]
                }, {
                    payment: payment,
                    active: true,
                    features: [
                        {active: true, text: 'subscription.api_only_and_all_inclusive_pros.1'},
                        {active: true, text: 'subscription.api_only_and_all_inclusive_pros.2'},
                        {active: true, text: 'subscription.api_only_and_all_inclusive_pros.3'},
                        {active: true, text: 'subscription.api_only_and_all_inclusive_pros.4'},
                        {active: true, text: 'subscription.api_only_and_all_inclusive_pros.5'},
                        {active: true, text: 'subscription.api_only_and_all_inclusive_pros.6'},
                        {active: true, text: 'subscription.api_only_and_all_inclusive_pros.7'}
                    ],
                    prices: [
                        {
                            title: 'subscription.all_inclusive',
                            rate: 'subscription.monthly',
                            duration: 'subscription.per_month',
                            price: {
                                rate: '1 month',
                                value: 4900,
                                currency: 'RUB',
                                org: '1msg_full'
                            },
                            payment: payment
                        },
                        {
                            title: 'subscription.all_inclusive',
                            rate: 'subscription.annual',
                            discount: 'subscription.all_inclusive_discount',
                            duration: 'subscription.per_year',
                            price: {
                                rate: '1 year',
                                value: 49000,
                                currency: 'RUB',
                                org: '1msg_full'
                            },
                            payment: payment
                        }
                    ]
                })
           } else {
                pricing[lang].push({
                    payment: payment,
                    trial: ratesLocal[lang].trialDays,
                    prices: [
                        {
                            title: 'subscription.monthly',
                            description: 'subscription.monthly_description',
                            duration: 'subscription.per_month',
                            price: {
                                rate: '1 month',
                                value: ratesLocal[lang].month,
                                currency: ratesLocal[lang].currency,
                                org: ratesLocal[lang].org
                            },
                            payment: payment,
                            btn: 'subscription.subscribe',
                            features: [
                                "subscription.monthly_pros.1",
                                "subscription.monthly_pros.2",
                                "subscription.monthly_pros.3",
                                "subscription.monthly_pros.4",
                                "subscription.annual_pros.5",
                                "subscription.annual_pros.6"
                            ]
                        },
                        {
                            title: 'subscription.annual',
                            description: 'subscription.annual_description',
                            duration: 'subscription.per_year',
                            price: {
                                rate: '1 year',
                                value: ratesLocal[lang].year,
                                currency: ratesLocal[lang].currency,
                                org: ratesLocal[lang].org
                            },
                            payment: payment,
                            btn: 'subscription.subscribe',
                            features: [
                                "subscription.annual_pros.1",
                                "subscription.annual_pros.2",
                                "subscription.annual_pros.3",
                                "subscription.annual_pros.4",
                                "subscription.annual_pros.5",
                                "subscription.annual_pros.6"
                            ]
                        }
                    ]
                })
          }
        }
    }

    return pricing;
}
export function getPaymentProviders() {
    return payments;
}
