<script>
  import {onMount} from "svelte";
  import {channelsAdditionalInfo} from "../helpers/store";
  import {WabaTemplatesComponentTransformer} from "../helpers/WabaTemplatesComponentTransformer";
  import {Navigation} from 'swiper';
  import {Swiper, SwiperSlide} from 'swiper/svelte';

  export let template;
  export let channel;
  export let data;

  let localTemplate;
  let navigation ={
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  };

  function getLocalTemplate() {
    if (template) {
      localTemplate =  new WabaTemplatesComponentTransformer(template, data).fillVariablesWithData().processMarkdown().orderComponents().getResult()
    }
  }

  $:data && getLocalTemplate()
  $:template && getLocalTemplate()


  let buttons = [];
  let loading = false;
  let channelsAdditionalInfoLocal

  onMount(() => {
    buttons = template.components.find((item) => item.type === 'BUTTONS')?.buttons || []
  });


  const unsub = channelsAdditionalInfo.subscribe(value => {
    let reworkedValue = value;
    for (let i in reworkedValue) {
      if (reworkedValue[i].hasOwnProperty('id')) {
        reworkedValue[i].id = reworkedValue[i].id.split("@").shift();
      }
    }

    channelsAdditionalInfoLocal = reworkedValue
  });
</script>

<div class="whatsapp-chat">
  <div class="header-chat">
    <div class="head-home">
      <div class="info-avatar"><img src="{channelsAdditionalInfoLocal?.[channel.id]?.photo}" alt="avatar"/></div>
      <p>
        <span class="whatsapp-name">{channelsAdditionalInfoLocal?.[channel.id]?.about}</span><br/><small>Typically
        replies within an hour</small>
      </p>
    </div>
  </div>
  <div class="start-chat">
    <div class=" whatsapp-chat-body">
      <div class="whatsapp-chat-message-container">
        <div class="whatsapp-chat-message">
          <div class="whatsapp-chat-author">
            {channel?.waba_info?.waba_account?.name || 'Author'}
          </div>
          <div class="whatsapp-chat-text">
            {#each localTemplate.components as component}
              {#if (component.type == 'HEADER')}
                {#if component.format === 'TEXT'}
                  <div><strong>{component.text}</strong></div>
                {:else}
                  <div class="whatsapp-chat-media mb-2">
                    {#if component.format === 'IMAGE'}
                      <i class="bx bx-image text-secondary"/>
                    {/if}
                    {#if component.format === 'DOCUMENT'}
                      <i class="bx bx-file text-secondary"/>
                    {/if}
                    {#if component.format === 'VIDEO'}
                      <i class="bx bx-play-circle text-secondary"/>
                    {/if}
                  </div>
                {/if}
              {/if}
              {#if (component.type == 'BODY')}
                <div style="white-space: pre-wrap;">{@html component.text}</div>
              {/if}
              {#if (component.type == 'FOOTER')}
                <div class="font-size-12 mt-2 text-muted">
                  {component.text}
                </div>
              {/if}
            {/each}
          </div>
          <div class="whatsapp-chat-time">1:40</div>
        </div>
      </div>
      <div class="preview_buttons">
        {#each buttons as button}
          <div class="preview_button">
            {#if button.type === 'URL' || button.type === 'PHONE_NUMBER'}
              <i class="mdi mdi-{button.type === 'URL' ? 'open-in-new' : 'phone'} font-size-16 me-1"/>
            {/if}
            {#if button.type === 'OTP'}
              <i class="bx bx-copy-alt font-size-16 me-1"/>
            {/if}
            {button.text}
          </div>
        {/each}
      </div>
      {#each localTemplate.components as component}
        {#if component.type == 'CAROUSEL'}
          <Swiper slidesPerView={1} spaceBetween={10} {navigation} modules={[Navigation]} class="position-relative">
            {#each component.cards as card}
              <SwiperSlide>
                <div class="whatsapp-chat-message-container">
                  <div class="whatsapp-chat-carousel-card">
                    <div class="whatsapp-chat-text">
                      {#if card.components[0].type === 'HEADER'}
                        <div class="whatsapp-chat-media mb-2">
                          <i class="bx bx-{card.components[0].format === "IMAGE" ? 'image' : card.components[0].format === 'VIDEO' ? 'play-circle' : ''} text-secondary"/>
                        </div>
                      {/if}
                      <div>{@html card.components[1]?.text ?? ''}</div>
                    </div>
                  </div>
                </div>
                <div class="preview_buttons flex-column">
                  {#each card.components[2].buttons as button, j}
                    <div class="preview_button w-100">
                      {#if button.type !== 'QUICK_REPLY'}
                        <i class="mdi mdi-{button.type === 'URL' ? 'open-in-new' : 'phone'} font-size-16 me-1"/>
                      {/if}
                      {button.text}
                    </div>
                  {/each}
                </div>
              </SwiperSlide>
            {/each}
            <div slot="container-end" class="mt-2 whatsapp-chat-carousel-button">
              <button type="button" class="swiper-button-prev">
                <i class="mdi mdi-chevron-left"/>
              </button>
              <button type="button" class="swiper-button-next">
                <i class="mdi mdi-chevron-right"/>
              </button>
            </div>
          </Swiper>
        {/if}
      {/each}
    </div>
  </div>
</div>
