<script>
    let clazz = '';
    export { clazz as class };
</script>
<div class={`spinner-border text-secondary m-1 ${clazz || ''}`} role="status">
    <span class="sr-only">Loading...</span>
</div>
<style>
    .ssm{
        width: 1em;
        height: 1em;
        vertical-align: -0.4em;
    }
</style>
