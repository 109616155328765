<script>
  import {Input, FormGroup, Label, Button} from "sveltestrap";
  import SelectDropdown from "../../../../Components/SelectDropdown.svelte";

  export let name;
  export let values;
  export let channelStatusIsCloud;

  let codeArr = [
    "af", "sq", "ar", "az", "bn", "bg", "ca", "zh_CN",
    "zh_HK", "zh_TW", "hr", "cs", "da", "nl", "en",
    "en_GB", "en_US", "et", "fil", "fi", "fr", "de",
    "el", "gu", "he", "hi", "hu", "id", "ga", "it",
    "ja", "kn", "kk", "ko", "lo", "lv", "lt", "mk",
    "ms", "mr", "nb", "fa", "pl", "pt_BR", "pt_PT",
    "pa", "ro", "ru", "sr", "sk", "sl", "es", "es_AR",
    "es_ES", "es_MX", "sw", "sv", "ta", "te", "th",
    "tr", "uk", "ur", "uz", "vi"
  ];

  function selectHeader(type) {
    switch (type) {
      case "none":
        values.params = values.params.filter(item => item.type !== "header");
        break;
      case "text":
        values.params = values.params.filter(item => item.type !== "header");
        values.params = [{type: "header", parameters: [{type: "text", text: ""}]}, ...values.params];
        break;
      case "image":
        values.params = values.params.filter(item => item.type !== "header");
        values.params = [{type: "header", parameters: [{type: "image", image: {link: ""}}]}, ...values.params];
        break;
      case "document":
        values.params = values.params.filter(item => item.type !== "header");
        values.params = [{type: "header", parameters: [{type: "document", document: {link: ""}}]}, ...values.params];
        break;
      case "video":
        values.params = values.params.filter(item => item.type !== "header");
        values.params = [{type: "header", parameters: [{type: "video", video: {link: ""}}]}, ...values.params];
        break;
    }
  }

  function addBody() {
    if (values.params?.[0]?.type === "header") {
      values.params.splice(1, 0, {type: "body", parameters: [{type: "text", text: ""}]});
      values.params = values.params;
    } else {
      values.params = [{type: "body", parameters: [{type: "text", text: ""}]}, ...values.params]
    }
  }

  function addBodyParameter(index) {
    values.params[index].parameters = [...values.params[index].parameters, {type: "text", text: ""}];
  }

  function deleteBodyParameter(index, i) {
    if (values.params[index].parameters.length === 1) {
      values.params = values.params.filter(item => item.type !== "body");
    } else {
      values.params[index].parameters = values.params[index].parameters.filter((item, index) => index !== i);
    }
  }

  function addButton(type, index) {
    if (type === 'url') {
      values.params = [...values.params, {type: "button", sub_type: "url", index, parameters: [{type: "text", text: ""}]}]
    }
    if (type === 'catalog') {
      values.params = [...values.params, {type: "button", sub_type: "catalog", index, parameters: [{type: "action", action: {thumbnail_product_retailer_id: ""}}]}]
    }
  }

  function addButtonParameter(index, type) {
    if (type === 'url') {
      values.params[index].parameters = [...values.params[index].parameters, {type: "text", text: ""}];
    }
    if (type === 'catalog') {
      values.params[index].parameters = [...values.params[index].parameters, {type: "action", action: {thumbnail_product_retailer_id: ""}}];
    }
  }

  function deleteButtonParameter(type, index, i) {
    if (type === 'url') {
      values.params = values.params.filter((item, j) => j !== index);
    }
    if (type === 'catalog') {
      if (values.params[index].parameters.length === 1) {
        values.params = values.params.filter(item => item.type !== "button");
      } else {
        values.params[index].parameters = values.params[index].parameters.filter((item, j) => j !== i);
      }
    }
  }

  function addCarousel() {
    values.params = [...values.params, {type: "carousel", cards: [{card_index: 0, components: [
      {type: "header", parameters: [{type: "image", image: {link: ""}}]}
    ]}]}];
  }

  function addCarouselCard() {
    let carouselIndex = values.params.findIndex(item => item.type === "carousel");
    let headerParameters = [];
    let btnElements = [];
    values.params[carouselIndex].cards[0].components.forEach(item => {
      if (item.type === "button") {
        btnElements.push({
          type: "button",
          sub_type: "url",
          index: item.index,
          parameters: [
            {
              type: "text",
              text: ""
            }
          ]
        });
      }
    });
    if (values.params[carouselIndex].cards[0].components[0].parameters[0].type === 'image') {
      headerParameters = [{type: "image", image: {link: ""}}];
    } else if (values.params[carouselIndex].cards[0].components[0].parameters[0].type === 'video') {
      headerParameters = [{type: "video", video: {link: ""}}];
    }
    values.params[carouselIndex].cards = [...values.params[carouselIndex].cards, {
      card_index: values.params[carouselIndex].cards.length,
      components: [
        {type: "header", parameters: headerParameters},
        ...btnElements
      ]
    }]
  }

  function deleteCarouselCard(cardIndex) {
    let carouselIndex = values.params.findIndex(item => item.type === "carousel");
    if (values.params[carouselIndex].cards.length === 1) {
      values.params = values.params.filter(item => item.type !== "carousel");
    } else {
      values.params[carouselIndex].cards = values.params[carouselIndex].cards.filter((item, index) => index !== cardIndex);
      for (let i = 0; i < values.params[carouselIndex].cards.length; i++) {
        values.params[carouselIndex].cards[i].card_index = i;
      }
    }
  }

  function selectCarouselHeader(type) {
    let carouselIndex = values.params.findIndex(item => item.type === "carousel");
    switch (type) {
      case "image":
        for (let i = 0; i < values.params[carouselIndex].cards.length; i++) {
          values.params[carouselIndex].cards[i].components[0].parameters[0] = {
            type: "image",
            image: {
              link: values.params[carouselIndex].cards[i].components[0].parameters[0]?.video?.link ?? ""
            }
          };
        }
        break;
      case "video":
        for (let i = 0; i < values.params[carouselIndex].cards.length; i++) {
          values.params[carouselIndex].cards[i].components[0].parameters[0] = {
            type: "video",
            video: {
              link: values.params[carouselIndex].cards[i].components[0].parameters[0]?.image?.link ?? ""
            }
          };
        }
        break;
    }
  }

  function addCarouselButton(index) {
    let carouselIndex = values.params.findIndex(item => item.type === "carousel");
    for (let i = 0; i < values.params[carouselIndex].cards.length; i++) {
      values.params[carouselIndex].cards[i].components = [...values.params[carouselIndex].cards[i].components, {
        type: "button",
        sub_type: "url",
        index,
        parameters: [
          {
            type: "text",
            text: ""
          }
        ]
      }];
    }
  }

  function deleteCarouselButton(index) {
    let carouselIndex = values.params.findIndex(item => item.type === "carousel");
    for (let i = 0; i < values.params[carouselIndex].cards.length; i++) {
      values.params[carouselIndex].cards[i].components = values.params[carouselIndex].cards[i].components.filter(item => item.type !== "button" || item.index !== index);
    }
  }

  function addCarouselBodyParameter(index, cardIndex, componentIndex) {
    values.params[index].cards[cardIndex].components[componentIndex].parameters = [...values.params[index].cards[cardIndex].components[componentIndex].parameters, {type: "text", text: ""}];
  }

  function deleteCarouselBodyParameter(index, cardIndex, componentIndex, i) {
    if (values.params[index].cards[cardIndex].components[componentIndex].parameters.length === 1) {
      values.params[index].cards[cardIndex].components = values.params[index].cards[cardIndex].components.filter(item => item.type !== "body");
    } else {
      values.params[index].cards[cardIndex].components[componentIndex].parameters = values.params[index].cards[cardIndex].components[componentIndex].parameters.filter((item, indexPar) => indexPar !== i);
    }
  }

  function addCarouselBody(index, cardIndex) {
    values.params[index].cards[cardIndex].components = [...values.params[index].cards[cardIndex].components, {type: "body", parameters: [{type: "text", text: ""}]}];
  }
</script>

{#if name === "language"}
  <FormGroup>
    <Label class="fw-600">language</Label>
    <SelectDropdown type={true} bind:selected={values.language.code} list={codeArr}/>
  </FormGroup>
{:else if name === "params"}
  <FormGroup>
    <Label class="fw-600">header</Label>
    <SelectDropdown type={true} selected="none" change={selectHeader} list={["none", "text", "image", "document", "video"]}/>
  </FormGroup>
  {#each values.params as param}
    {#if param.type === 'header'}
      {#each param.parameters as parameter}
        <FormGroup>
          {#if parameter.type === 'text'}
            <Input bind:value={parameter.text} placeholder="text" type="text"/>
          {/if}
          {#if parameter.type === 'image'}
            <Input bind:value={parameter.image.link} placeholder="link" type="text"/>
          {/if}
          {#if parameter.type === 'video'}
            <Input bind:value={parameter.video.link} placeholder="link" type="text"/>
          {/if}
          {#if parameter.type === 'document'}
            <Input bind:value={parameter.document.link} placeholder="link" type="text"/>
          {/if}
        </FormGroup>
      {/each}
    {/if}
  {/each}
  {#if !values.params.find(item => item.type === "body")}
    <Label class="fw-600">body</Label>
    <FormGroup>
      <button type="button" class="btn addBtn rounded-circle" on:click={addBody}>+</button>
    </FormGroup>
  {:else}
    {#each values.params as param, index}
      {#if param.type === 'body'}
        <FormGroup>
          {#each param.parameters as parameter, i}
            <div class="d-flex">
              <FormGroup class="w-100 me-3">
                <Label class="fw-600">body {'{'}{'{'}{i + 1}{'}'}{'}'}</Label>
                <Input bind:value={parameter.text} type="text"/>
              </FormGroup>
              <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteBodyParameter(index, i)}>-</button>
            </div>
          {/each}
          <button type="button" class="btn addBtn rounded-circle" on:click={() => addBodyParameter(index)}>+</button>
        </FormGroup>
      {/if}
    {/each}
  {/if}
  {#if values.params.filter(item => item.type === "button")?.length < 2}
    {#if !values.params.find(item => item.type === "button" && item.sub_type === "catalog")}
      <Label class="fw-600">button</Label>
      <FormGroup class="d-flex flex-wrap gap-2">
        {#if !values.params.find(item => item.type === "button" && item.sub_type === "catalog")}
          {#if !values.params.find(item => item.type === "button" && item.sub_type === "url" && item.index === 0)}
            <Button type=button outline color=primary on:click={() => addButton('url', 0)}>button url 0</Button>
          {/if}
          {#if !values.params.find(item => item.type === "button" && item.sub_type === "url" && item.index === 1)}
            <Button type=button outline color=primary on:click={() => addButton('url', 1)}>button url 1</Button>
          {/if}
        {/if}
        {#if channelStatusIsCloud && !values.params.find(item => item.type === "button")}
          <Button type=button outline color=primary on:click={() => addButton('catalog', 0)}>button catalog</Button>
        {/if}
      </FormGroup>
    {/if}
  {/if}
  {#if values.params.filter(item => item.type === "button")?.length}
    {#each values.params as param, index}
      {#if param.type === 'button'}
        <FormGroup>
          {#if param?.sub_type === 'catalog'}
            {#each param.parameters as parameter, i}
              <div class="d-flex">
                <FormGroup class="w-100 me-3">
                  <Label class="fw-600">product id {i + 1}</Label>
                  <Input bind:value={parameter.action.thumbnail_product_retailer_id} type="text"/>
                </FormGroup>
                <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteButtonParameter('catalog', index, i)}>-</button>
              </div>
            {/each}
            <button type="button" class="btn addBtn rounded-circle" on:click={() => addButtonParameter(index, 'catalog')}>+</button>
          {:else}
            {#each param.parameters as parameter, i}
              <div class="d-flex">
                <div class="w-100 me-3">
                  <Label class="fw-600">button {'{'}{'{'}{i + 1}{'}'}{'}'}</Label>
                  <Input bind:value={parameter.text} type="text"/>
                </div>
                <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteButtonParameter('url', index)}>-</button>
              </div>
            {/each}
          {/if}
        </FormGroup>
      {/if}
    {/each}
  {/if}
  {#if !values.params.find(item => item.type === "carousel")}
    <Label class="fw-600">carousel</Label>
    <FormGroup>
      <button type="button" class="btn addBtn rounded-circle" on:click={addCarousel}>+</button>
    </FormGroup>
  {:else}
    {#each values.params as param, index}
      {#if param.type === 'carousel'}
        <Label class="fw-600">carousel</Label>
        <div class="border rounded p-3 mb-3">
          <FormGroup>
            <Label class="fw-600">header</Label>
            <SelectDropdown type={true} selected="image" change={(type) => selectCarouselHeader(type)} list={["image", "video"]}/>
          </FormGroup>
          <div>
            <Label class="fw-600">buttons</Label>
            <div class="d-flex gap-1 flex-wrap">
              {#if param.cards[0].components.find(item => item.type === "button" && item.index === 0)}
                <Button type=button outline color=danger on:click={() => deleteCarouselButton(0)}>
                  - button url index 0
                </Button>
              {:else}
                <Button type=button outline color=primary on:click={() => addCarouselButton(0)}>
                  + button url index 0
                </Button>
              {/if}
              {#if param.cards[0].components.find(item => item.type === "button" && item.index === 1)}
                <Button type=button outline color=danger on:click={() => deleteCarouselButton(1)}>
                  - button url index 1
                </Button>
              {:else}
                <Button type=button outline color=primary on:click={() => addCarouselButton(1)}>
                  + button url index 1
                </Button>
              {/if}
            </div>
          </div>
        </div>
        {#each param.cards as card, cardIndex}
          <div class="border rounded px-3 py-2 mb-3">
            <Label class="fw-600 w-100 d-flex gap-2">
              <span>card {cardIndex}</span>
              <button type="button" class="border-0 p-0 bg-transparent d-flex" on:click={() => deleteCarouselCard(cardIndex)}>
                <i class="bx bx-trash font-size-18 text-danger"/>
              </button>
            </Label>
            {#each card.components as component, componentIndex}
              {#if component.type === 'header'}
                <FormGroup>
                  <Label class="fw-600">header</Label>
                  {#if component.parameters[0].type === 'image'}
                    <Input bind:value={component.parameters[0].image.link} placeholder="link" type="text"/>
                  {/if}
                  {#if component.parameters[0].type === 'video'}
                    <Input bind:value={component.parameters[0].video.link} placeholder="link" type="text"/>
                  {/if}
                </FormGroup>
              {/if}
              {#if component.type === 'body'}
                <FormGroup>
                  {#each component.parameters as parameter, i}
                    <div class="d-flex">
                      <FormGroup class="w-100 me-3">
                        <Label class="fw-600">body {'{'}{'{'}{i + 1}{'}'}{'}'}</Label>
                        <Input bind:value={parameter.text} type="text"/>
                      </FormGroup>
                      <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteCarouselBodyParameter(index, cardIndex, componentIndex, i)}>-</button>
                    </div>
                  {/each}
                  <button type="button" class="btn addBtn rounded-circle" on:click={() => addCarouselBodyParameter(index, cardIndex, componentIndex)}>+</button>
                </FormGroup>
              {/if}
              {#if component.type === 'button'}
                <FormGroup class="w-100 me-3">
                  <Label class="fw-600">button {component.index} {'{'}{'{'}{1}{'}'}{'}'}</Label>
                  <Input bind:value={component.parameters[0].text} type="text"/>
                </FormGroup>
              {/if}
            {/each}
            {#if !card.components.find(item => item.type === "body")}
              <FormGroup>
                <Label class="fw-600">body</Label>
                <div>
                  <button type="button" class="btn addBtn rounded-circle" on:click={() => addCarouselBody(index, cardIndex)}>+</button>
                </div>
              </FormGroup>
            {/if}
          </div>
        {/each}
        <FormGroup>
          <button type="button" class="btn addBtn rounded-circle" on:click={addCarouselCard}>+</button>
        </FormGroup>
      {/if}
    {/each}
  {/if}
{/if}

<style>
  .deleteBtn, .addBtn {
    color: #495057 !important;
    width: 40px;
    height: 40px;
    background-color: #EFF2F7;
    box-shadow: none;
  }

  .deleteBtn {
    line-height: 16px;
    font-size: 22px;
    margin-top: 28px;
  }

  .addBtn {
    line-height: 20px;
    font-size: 20px;
  }
</style>