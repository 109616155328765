const languages = [  
  {
    label: "Russian",
    flag: '/assets/images/flags/russia.jpg',
    value: 'ru'
  },
  {
    label: "English",
    flag: '/assets/images/flags/us.jpg',
    value: 'en'
  },
  {
    label: "Spanish",
    flag: '/assets/images/flags/spain.jpg',
    value: 'es'
  }
]

export default languages;