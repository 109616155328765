<script>
    import {Container, Row, Col} from "sveltestrap";
    import {Link, navigate} from "svelte-routing";
    import {onMount, onDestroy} from "svelte";
    import {
        channels, paymentRecords, projects
    } from "../../helpers/store";
    import {layout} from "../../services/loaders";
    import {_} from "svelte-i18n";
    import {timer} from "../../helpers/utils";
    import Subscription from "./Subscription.svelte";
    import PaymentsTable from "./PaymentsTable.svelte";
    import ReceiptsModal from "../../Components/Modals/ReceiptsModal.svelte";
    import CustomizeSubscriptionModal from "../../Components/Modals/CustomizeSubscriptionModal.svelte";

    import {globalHistory} from "svelte-routing/src/history";

    // Pages Components
    export let params;
    // export let location;


    let userChannels, channel = false, paymentRecordsLocal = [], activeTab = '1',
        showPayments = false,
        receiptsModal = false,
        customizeModal = false,
        loading = false;

    const updateChannel = async (id) => {
        layout(true);
        let channelTmp;
        if (userChannels) {

            channelTmp = userChannels.find((item) => {
                return item.id.toString() === id.toString();
            });
            if (!channelTmp) {
                navigate("/billing");
                return;
            }
            if (channelTmp.projectId) {
                channelTmp.project = $projects.find((item) => {
                    return item.id === channelTmp.projectId;
                });
            }

        } else {
            channelTmp = false;
        }
        channel = channelTmp;
       // showPayments = paymentRecordsLocal.length && channel?.paymentProfileId;
        showPayments = paymentRecordsLocal.length;
        await timer(300);
        layout(false);
        return channelTmp;
    };

    onMount(async () => {
        // unsub = globalHistory.listen(({ location, action }) => {
        //   console.log(location, action);
        //   console.log("param in loc", params);

        //   // location.pathname;
        //   // navigate(pathname);
        // });
    });


    $: if (params.id) updateChannel(params.id);

    const unsub = channels.subscribe(async (value) => {
        if (value) {
            userChannels = value;
            updateChannel(params.id);
        }
    });
    const unsub2 = paymentRecords.subscribe(async (value) => {
        if (channel) {
            paymentRecordsLocal = value && value.filter((item) => {
                return item.channelId === channel.id
            }) || [];
            showPayments = paymentRecordsLocal.length;
        }

    });


    const json = obj => JSON.stringify(obj, null, 2);

    onDestroy(() => {
        unsub();
        unsub2();
    });
</script>

{#if channel}

    <div class="page-content">
        <Container fluid>
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <div class="header-title mb-0">
                        {$_("subscription.title")} #{channel.id}
                    </div>
                    <Link class="nav-link p-0" to="/billing/">
                        {$_("subscription.choose_different")}

                    </Link>
                </div>

                    <div>
                        {#if (channel.paymentProfileId)}
                        <button class="mt-2  me-2 btn btn-primary font-size-13  btn-lg waves-effect waves-light"
                                on:click={()=>{customizeModal = true}}
                                type="button">
                            <i class="mdi mdi-autorenew mdi-rotate-45 font-size-13 align-middle "></i>
                            {$_("customize.title")}
                        </button>
                        {/if}
                        <button class="mt-2 btn btn-white  font-size-13  btn-lg waves-effect waves-light"
                                on:click={()=>{receiptsModal = true}}
                                type="button">
                            <i class="bx bx-list-check font-size-18 align-middle"></i>
                            {$_("subscription.open_receipts")}
                        </button>
                    </div>


            </div>

            {#if receiptsModal}
                <ReceiptsModal show={receiptsModal} channel={channel}
                               toggleModal={() => {receiptsModal = !receiptsModal}}/>
            {/if}
            {#if (channel.paymentProfileId)}
                <Row class="mt-5 justify-content-center">
                    <Col md="6" lg="3">
                        <Subscription {channel}/>
                    </Col>
                    {#if (showPayments)}
                        <Col md="6" lg="9">
                            {#if (channel.stoppedComment)}
                                <div role="alert" class="alert alert-warning"
                                     style="">
                                    <h4 class="alert-heading">
                                        <i class="mdi mdi-alert-circle-outline me-2"></i>
                                        {$_('stopped.title')}
                                    </h4>
                                    <p>{$_('stopped.' + channel.stoppedComment)}</p>
                                </div>
                            {/if}
                            <PaymentsTable paymentRecords={paymentRecordsLocal}/>
                        </Col>
                    {/if}
                </Row>


                {#if customizeModal}
                    <CustomizeSubscriptionModal show={customizeModal} channel={channel}
                                   toggleModal={() => {customizeModal= !customizeModal}}/>
                {/if}
            {:else}
                <Container fluid>
                    {#if (channel.stoppedComment)}
                        <Row class="d-flex justify-content-end">
                            <div role="alert" class="alert alert-warning col-5"
                                 style="">
                                <h4 class="alert-heading">
                                    <i class="mdi mdi-alert-circle-outline me-2"></i>
                                    {$_('stopped.title')}
                                </h4>
                                <p>{$_('stopped.' + channel.stoppedComment)}</p>
                            </div>
                        </Row>
                    {/if}

                    <Row class="d-flex justify-content-center mt-3">
                        <Subscription {channel}/>
                    </Row>
                    {#if (showPayments)}
                        <Row class="mt-5 justify-content-center">

                                <PaymentsTable paymentRecords={paymentRecordsLocal}/>

                        </Row>
                    {/if}
                </Container>
            {/if}

        </Container>
    </div>
{/if}
