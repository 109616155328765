<script>
  import { createEventDispatcher, getContext } from "svelte";
  const dispatch = createEventDispatcher();
  const stateContext = getContext("state");

  export let buttons = [-2, -1, 0, 1, 2];
  export let count;
  export let page = 0;
  export let pageSize;
  export let serverSide = false;

  $: pageCount = Math.floor(count / pageSize);

  function onChange(event, page) {
    const state = stateContext.getState();
    const detail = {
      originalEvent: event,
      page,
      pageIndex: serverSide ? 0 : page * state.pageSize,
      pageSize: state.pageSize,
    };
    dispatch("pageChange", detail);

    if (detail.preventDefault !== true) {
      stateContext.setPage(detail.page, detail.pageIndex);
    }
  }
</script>

{#if pageCount > 0}
  <ul class="pagination pagination-rounded justify-content-end mb-2">
    <li class={page === 0 ? "page-item disabled" : "page-item "}>
      {#if page === 0}
        <div class="page-link" aria-label="First">
          <i class="bx bx-chevrons-left" />
        </div>
      {:else}
        <button type="button" on:click={(e) => onChange(e, 0)}
          class="page-link" aria-label="Previous">
          <i class="bx bx-chevron-left"/>
        </button>
      {/if}
    </li>
    {#each buttons as button}
      {#if page + button >= 0 && page + button <= pageCount}
        <li class="page-item">
          {#if page === page + button}
            <div class="page-link active" aria-label="Current">
              {page + button + 1}
            </div>
          {:else}
            <button type="button" class="page-link" aria-label="Previous" on:click={(e) => onChange(e, page + button)}>
              {page + button + 1}
            </button>
          {/if}
        </li>
      {/if}
    {/each}

    <li class="page-item">
      {#if page >= pageCount}
        <div class="page-link" aria-label="Last">
          <i class="bx bx-chevrons-right" />
        </div>
      {:else}
        <button type="button" class="page-link" aria-label="Last" on:click={(e) => onChange(e, pageCount)}>
          <i class="bx bx-chevron-right"/>
        </button>
      {/if}
    </li>
  </ul>
{/if}
<!-- 
<style>
  .active {
    background-color: rgb(150, 150, 235);
    color: white;
  }

  ul {
    flex: 1;
    float: right;
    list-style: none;
  }

  li {
    float: left;
  }

  button {
    background: transparent;
    border: 1px solid #ccc;
    padding: 5px 10px;
    margin-left: 3px;
    float: left;
    cursor: pointer;
  }
</style> -->
