<script>
  import {_} from 'svelte-i18n';
  import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip} from 'sveltestrap';
  import {user, balance, channels} from '../helpers/store';
  import {auth} from '../helpers/firebase';
  import {navigate, Link} from 'svelte-routing';
  import {onDestroy} from "svelte";
  import {getRequest} from "../helpers/utils";

  let profileUser, activeBalance;

  const unsub = user.subscribe(value => {
    profileUser = value;
  });
  const unsub2 = balance.subscribe(value => {
    activeBalance = value;
  });

  onDestroy(() => {
    unsub();
    unsub2();
    unsub3();
  })
  const handleProfile = () => {
    navigate('/profile');
  }

  const handleBilling = () => {
    navigate('/billing');
  }

  const handleLogOut = () => {
    auth.signOut().then(
      function () {
        // Sign-out successful.
        navigate('/login');
      },
      function (error) {
        // An error happened.
        console.warn('error on logout', error.message);
      }
    );
  };

  const unsub3 = channels.subscribe(async (value) => {
    if (value) {
      let channelTmp = value.find((item) => {
        return item.srv === 'trial'
      });

      if (channelTmp) {
        let sum = 0;
        if (channelTmp.apiUrl) {
          let result = await getRequest(`${channelTmp.apiUrl}statistics`, {
            token: channelTmp.token,
          });
          if (result?.business_initiated_count) {
            sum -= result?.business_initiated_count * 0.3;
          }
          if (result?.user_initiated_count) {
            sum -= result?.user_initiated_count * 0.1;
          }
        }
        if (sum < 0) {
          sum = 0;
        }
        activeBalance.balance = sum;
        activeBalance.trial = true;
      }
    }
  });

</script>

<style>
    .balance_tab{
        color: #545A6D;
    }

    .balance_tab_active_balance {
      max-width: 300px;
    }

    @media (max-width: 600px) {
      .balance_tab_active_balance {
        max-width: none;
      }
    }
</style>

{#if (activeBalance?.trial)}
  <div class="d-none d-md-flex align-items-center me-2 balance_tab">
    <i class="bx bx-wallet text-secondary font-size-18 me-2"/>
    <div class="font-size-12" style="color: #555b6d;">
      <div>{activeBalance?.balance || 0} USD</div>
    </div>
  </div>
{:else}
  <div class="d-none d-md-flex align-items-center me-2 balance_tab">
    <i class="bx bx-wallet font-size-18 me-2"/>
    <Link to="/billing" class="font-size-12" style="color: {(activeBalance?.balance?.toFixed(2) || 0) < 0 ? '#F46A6A' : '#555b6d'};">
      <span>{activeBalance?.balance?.toFixed(2) || 0} USD</span>
    </Link>
    {#if (activeBalance?.balance?.toFixed(2) || 0) < 0}
      <i class="bx bxs-error-circle font-size-18 ms-2" id="active-balance-error-profile" style="color: #F46A6A"/>
      <Tooltip target="active-balance-error-profile" placement="left">
        {$_('billing.balance.insufficient_funds_subtitle')}
      </Tooltip>
    {/if}
  </div>
{/if}

<Dropdown class="d-inline-block">
    <DropdownToggle class="btn header-item headerbtn d-flex align-items-center px-2 px-md-3"
        id="page-header-user-dropdown" tag="button" color="">
        {#if profileUser.photoURL}
            <img class="rounded-circle header-profile-user" src="{profileUser.photoURL}" alt="Header Avatar"/>
        {:else}
            <div class="rounded-circle header-profile-user d-inline-block">
              <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-15">
                {profileUser.email?.toUpperCase().charAt(0) ?? ''}
              </span>
            </div>
        {/if}
        <div class="d-none d-xl-flex flex-column align-items-start ms-1">
            <div class="font-size-13">
                {#if profileUser.name}
                  {profileUser.name}
                {:else}
                  {profileUser.email}
                {/if}
                <i class="mdi mdi-chevron-down ms-1"/>
            </div>
            {#if profileUser.isOperator}
              <span class="badge rounded badge-soft-primary font-size-11 top-0">
                {$_('navbar.dropdown.profile.operator')}
              </span>
            {:else}
              <span class="badge rounded badge-soft-success font-size-11 top-0">
                {$_('navbar.dropdown.profile.admin')}
              </span>
            {/if}
        </div>
    </DropdownToggle>

    <DropdownMenu class="dropdown-menu-end">
        {#if activeBalance?.trial}
          <div class="d-md-none d-flex align-items-center px-4 py-1 balance_tab">
            <i class="bx bx-wallet text-secondary font-size-18 me-2"/>
            <div class="font-size-12" style="color: #555b6d">
              <div class="text-truncate">{activeBalance?.balance || 0} USD</div>
              <div class="text-truncate">{$_('trial.balance_currency')}</div>
            </div>
          </div>
        {:else}
          <DropdownItem class="d-md-none d-flex align-items-center balance_tab" on:click={handleBilling} tag="a">
            <i class="bx bx-wallet font-size-18 me-2"/>
            <span class="font-size-12 text-truncate balance_tab_active_balance" style="color: {(activeBalance?.balance?.toFixed(2) || 0) < 0 ? '#F46A6A' : '#555b6d'};">
              {activeBalance?.balance?.toFixed(2) || 0} USD
            </span>
          </DropdownItem>
        {/if}
        <div class="dropdown-divider d-md-none"/>
        <DropdownItem on:click={handleProfile} tag="a">
            <i class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"/>
            <span class="align-middle" key="t-profile">{$_('navbar.dropdown.profile.list.profile')}</span>
        </DropdownItem>
        <!-- <DropdownItem tag="a" href={'#'}
            ><i class="mdi mdi-message-text-outline text-muted font-size-16 align-middle me-1" />
            <span class="align-middle" key="t-messages">{$_('navbar.dropdown.profile.list.mywallet')}</span>
        </DropdownItem>
        <DropdownItem tag="a" href={'#'}
            ><i class="mdi mdi-calendar-check-outline text-muted font-size-16 align-middle me-1" />
            <span class="align-middle" key="t-taskboard">{$_('navbar.dropdown.profile.list.settings')}</span>
        </DropdownItem> -->
        <div class="dropdown-divider"/>
        <DropdownItem tag="a" on:click={handleLogOut}
        ><i class="mdi mdi-logout text-muted font-size-16 align-middle me-1"/>
            <span class="align-middle" key="t-logout">{$_('navbar.dropdown.profile.list.logout')}</span>
        </DropdownItem>
    </DropdownMenu>
</Dropdown>
