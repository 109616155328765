<script>
  import {Button, Card, CardBody, Spinner, Row, Col} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import {Link, navigate} from 'svelte-routing';
  import {addToast} from "../../helpers/toast";
  import {appClient, chosenChannel} from "../../helpers/store";
  import {promisifyStore} from "../../helpers/utils";
  import {SegmentDAO} from "../../helpers/onetouch/SegmentDAO";
  import NoChannelsWrapper from "../../Components/NoChannelsWrapper.svelte";
  import ChooseTheChannel from "../../Components/Modals/ChooseTheChannelModal.svelte";
  import TableBotUsers from "./Components/TableBotUsers.svelte";
  import HeaderForm from "./Components/HeaderForm.svelte";
  import SpinnerComponent from "../../Components/Spinners/SpinnerComponent.svelte";
  import ImportBotUsersModal from "../../Components/ImportBotUsersModal.svelte";
  import AddNewContactModal from "../../Components/AddNewContactModal.svelte";

  export let params;

  let nameSegment = '';
  let segmentListUser = [];
  let getLoader = false;
  let createLoader = false;
  let botUsersLoader = false;

  let isOpenModalImportBotUsers = false;
  let isOpenModalAddNewContact = false;

  let chosenChannelLocal = null;
  const unsub3 = chosenChannel.subscribe(value => {
    if (value === null || value === "") {
      chosenChannelLocal = null
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        if (params?.id) {
          getLoader = true;
          promisifyStore(chosenChannel).then(() => {
            SegmentDAO.getOrLoad(params.id).then(data => {
              if (data?.errors) {
                console.error(data?.errors);
                navigate('/segments');
                return
              }
              nameSegment = $_("copy_of") + ' ' + data.name;
              segmentListUser = data.segment_list_user;
              getLoader = false;
            })
          })
        }
        chosenChannelLocal = value
      }
    }
  });

  onDestroy(() => {
    unsub3();
  });

  const Create = () => {
    const {shopId} = appClient.getCommonVars(chosenChannelLocal);
    let data = {
      shop_id: shopId,
      name: nameSegment,
      state:{},
      segment_list_user: segmentListUser
    };
    createLoader = true;
    SegmentDAO.save(data).then(async data => {
      if (data?.error || data.errors) {
        console.error(data?.error || data.errors);
        await addToast({
          title: $_("error"),
          message: $_("something_went_wrong")
        });
        createLoader = false;
        return
      }
      createLoader = false;
      setTimeout(()=>{addToast({title: $_("segments.segment_created")})},500);
      createLoader = false;
      navigate('/segments')
    })
  };
</script>

<NoChannelsWrapper>
<div class="page-content">
{#if !getLoader}
  <form on:submit|preventDefault={Create} class="container-fluid">
    <Row class="mb-4 align-items-center">
      <Col sm={12} md={8} class="mb-2 mb-md-0 font-size-20 fw-bold text-truncate">
        {$_('segments.create')}
      </Col>
      <Col sm={12} md={4}>
        <ol class="breadcrumb m-0 p-0 justify-content-md-end">
          <li class="breadcrumb-item">
            <Link to="/segments">{$_('segments.title')}</Link>
          </li>
          <li class="breadcrumb-item active">
            {$_('segments.create')}
          </li>
        </ol>
      </Col>
    </Row>
    <HeaderForm bind:name={nameSegment} isOpenModalImportContact={() => isOpenModalImportBotUsers=true}>
      <Button slot="btnRight" type="submit" color="success" class="py-2 px-4 d-inline-flex align-items-center gap-2" disabled={createLoader}>
        {#if createLoader}
          <Spinner size="sm"/>
        {/if}
        <span class="font-size-14">{$_('segments.create')}</span>
      </Button>
    </HeaderForm>
    <Card>
      <CardBody>
        <TableBotUsers bind:selectedBotUsers={segmentListUser} bind:botUsersLoader isOpenModalAddContact={() => isOpenModalAddNewContact=true}/>
        <div class="mt-3 text-end">
          <Button type="submit" color="success" class="py-2 px-4 d-inline-flex align-items-center gap-2" disabled={createLoader}>
            {#if createLoader}
              <Spinner size="sm"/>
            {/if}
            <span class="font-size-14">{$_('segments.create')}</span>
          </Button>
        </div>
      </CardBody>
    </Card>
  </form>
{/if}

{#if getLoader}
  <div class="w-100 h-100 d-flex align-items-center justify-content-center">
    <SpinnerComponent/>
  </div>
{/if}
</div>
</NoChannelsWrapper>
<ChooseTheChannel/>

<ImportBotUsersModal bind:isOpen={isOpenModalImportBotUsers} bind:botUsersLoader bind:selectedBotUsers={segmentListUser}/>

<AddNewContactModal {chosenChannelLocal} bind:isOpen={isOpenModalAddNewContact} bind:botUsersLoader/>
