<script>
  import {eventTracking} from "../../../helpers/utils";
  import {onDestroy, onMount, tick} from "svelte";
  import {
    user,
    chosenChannel,
    registerUserInApp,
    appClient,
    channels,
    showProjectSelectModal
  } from "../../../helpers/store";
  import ChooseTheChannel from "../../../Components/Modals/ChooseTheChannelModal.svelte";
  import Spinner from "../../../Components/Spinners/SpinnerComponent.svelte";
  import NoChannelsWrapper from "../../../Components/NoChannelsWrapper.svelte";
  import {navigate} from "svelte-routing";

  export let params;

  let activeUser;
  let addAdminLoading = false;
  let clientLocal = appClient
  let link;


  const registerEmulatorToken = async () => {
    if (!addAdminLoading) {
      addAdminLoading = true;
      try {
        await registerUserInApp(false);
      } catch (e) {
        addAdminLoading = false;
      }
      addAdminLoading = false;
    }
  };

  const unsub5 = user.subscribe((value) => {
    activeUser = value;
    if (value?.id && !value.appToken) {
      registerEmulatorToken()
    } else {
      addAdminLoading = false;
    }
  });

  let chosenChannelLocal = null;
  const unsub3 = chosenChannel.subscribe(async (value) => {
    if (value === null || value === "") {
      chosenChannelLocal = null;
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        addAdminLoading = true
        setTimeout(() => {
          addAdminLoading = false
        }, 1000)
      }
      chosenChannelLocal = value
      if(chosenChannelLocal&&!link)
        link = clientLocal.getConstructorUrl(chosenChannelLocal, params.id)
    }
  });



  onDestroy(() => {
    unsub5();
    unsub3();
  });

</script>

<NoChannelsWrapper option="1">
<div class="custom-pt70">
  {#if activeUser && chosenChannelLocal && !addAdminLoading && link}
    <iframe
      src={link}
      allow="camera *;microphone *;geolocation *"
      loading="lazy"
      class="d-flex w-100 h_minus40"
      title="emulator 1msg"
      frameborder="0">
      Your browser does not support frames!
    </iframe>
  {/if}
  {#if addAdminLoading}
    <div class="d-flex justify-content-center p-5">
      <Spinner size="sm"/>
    </div>
  {/if}
</div>
</NoChannelsWrapper>
<ChooseTheChannel/>

<style>
  iframe {
    height: calc(100vh - 70px)
  }
</style>
