<script>
  import {Dropdown, DropdownMenu, DropdownToggle} from "sveltestrap";
  import {_} from "svelte-i18n";

  export let channels;
  export let change;
  export let active = false;

  let selected = active;
  let isOpen = false;

  const scrollToEl = () => {
    const container = document.querySelector('.ChannelsDropdown .scrollbar');
    const el = document.getElementById(`ChannelsDropdown-${selected.id}`);
    if (el) {
      container.scrollTop = el.offsetTop - container.offsetTop;
    }
  };

  const selectChannel = (channel) => {
    selected = channel;
    change(channel);
    isOpen=false;
  };

  $: isOpen && setTimeout(scrollToEl, 1);
</script>

<div class="ChannelsDropdown">
  <Dropdown {isOpen} toggle={() => isOpen=!isOpen} class="d-flex w-100">
    <DropdownToggle tag="div" class="d-inline-block font-size-16 fw-500 text-truncate w-100 pointer" caret>
      <i class="mdi mdi-chevron-down d-inline-block d-sm-none"/>
      {#if selected}
        {selected.id} {selected.name}
      {:else}
        {$_('all_channels')}
      {/if}
      <i class="mdi mdi-chevron-down d-none d-sm-inline-block"/>
    </DropdownToggle>
    <DropdownMenu class="w-100 mt-2">
      <div class="scrollbar">
        <button
          type="button"
          class="dropdown-item text-truncate fw-500 font-size-14 pointer"
          on:click={() => selectChannel(false)}>
          {$_('SelectDropdown.select_a_channel')}
        </button>
        {#each channels as channel}
          <button
            type="button"
            class="dropdown-item text-truncate fw-500 font-size-14 pointer {selected.id === channel.id ? 'active': ''}"
            id="ChannelsDropdown-{channel.id}"
            on:click={() => selectChannel(channel)}>
            {channel.id} {channel.name}
          </button>
        {/each}
      </div>
    </DropdownMenu>
  </Dropdown>
</div>

<style>
  .ChannelsDropdown {
    max-width: 375px;
  }

  .ChannelsDropdown .scrollbar {
    max-height: 150px;
    overflow-y: auto;
  }

  .ChannelsDropdown .scrollbar::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  .ChannelsDropdown .scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .ChannelsDropdown .dropdown-item {
    padding: 7px 16px;
    color: #495057;
  }

  .ChannelsDropdown .dropdown-item:hover, .ChannelsDropdown .active {
    background-color: #f4f4f4;
  }
</style>