<script>
  import {Modal, ModalBody} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {
    addChannelFirstTimeHelper,
    channels, getOnetouchShops,
    paymentRecords,
    projects, user,
    toggleTrialRestrictionsModal
  } from "../../helpers/store";
  import {onDestroy} from "svelte";
  import SpinnerComponent from "../Spinners/SpinnerComponent.svelte";
  import {promisifyStore} from "../../helpers/utils.js";

  let show = false;
  let loading = false;

  function getLocalKey() {
    return `${$user.id}_last_trial_time_popup_showed`
  }

  function setLastTimeShowed() {
    localStorage.setItem(getLocalKey(), new Date().getTime().toString())
  }

  let projectsList, channelsShowModal;
  const unsub = projects.subscribe(async (value) => {
    projectsList = value;
    projectsList = projectsList
  });

  async function createChannel() {
    channelsShowModal = true;
    if (!loading) {
      let res = addChannelFirstTimeHelper()
      if (res) {
        loading = true;
        res.then(() => {
          show = false;
          setLastTimeShowed();
          loading = false
        }, () => {
          show = false;
          loading = false
        })
      } else {
        show = false;
        setLastTimeShowed();
      }
    }
  }

  const unsub2 = channels.subscribe(async (value) => {
    let startAddress = new URL(window.location.href);
    if (value && value?.length < 3) {
      const blockLocations = ["billing"];
      let channelTmp = value.find((item) => {
        return item.srv === 'trial'
      })
      if (channelTmp && (channelTmp.activeTill < Date.now() || channelTmp.stopped)) {
        const _localPaymentRecords = await promisifyStore(paymentRecords) ?? [];
        let _localShops;
        try {
          _localShops = await getOnetouchShops() ?? [];
        } catch (e) {
          _localShops = []
        }
        //here length is <2 as demo shop will be present here, and if not - then he do not have a trial instance and we just skip this part
        const noOnetouchOperatorShops = (_localShops === null || _localShops?.length < 2)
        if (!_localPaymentRecords?.length && noOnetouchOperatorShops) {
          let dateDiff = new Date().getTime() - (parseInt(localStorage.getItem(getLocalKey()) ?? 0, 10));
          //show modal only once per day
          if (Math.ceil(dateDiff / 1000 / 60 / 60 / 24) > 1)
            show = true;
        }
      } else if (channelTmp && blockLocations.includes(startAddress.pathname)) {
        toggleTrialRestrictionsModal(true);
      }
    }
  });

  onDestroy(() => {
    unsub();
    unsub2();
  });
</script>

<Modal autoFocus={true} backdrop={"static"} centered data-toggle="modal" fade={true} isOpen={show} role="dialog" scrollablesize="md">
  <ModalBody class="modal-trial">
    <div>
      <div class="text-center">
        <img alt="ready to start" src="/assets/images/rocket-screen.svg"/>
        <div class="mt-3">
          <strong class="fg-bs-gray-700 font-size-20">{@html $_('trial_ended.title')}</strong>
        </div>
        <div class="py-4 position-relative divider">
          <div class="mb-4">{$_('trial_ended.text1')}</div>
          <a class="btn btn-lg btn-success mb-1" href={$_("trial_ended.manager_url")} target="_blank"
             type="button">
            {$_("trial_ended.btn1")}
          </a>
          <div class="position-absolute top-100 start-50 translate-middle text-secondary font-size-14 p-1 bg-white">{$_('or')}</div>
        </div>
        <div class="pt-4">
          <div class="mb-4 text-secondary">{$_('trial_ended.text2')}</div>
          <button class="btn btn-lg btn-outline-success" on:click={createChannel} type="button">
            {$_("trial_ended.btn2")}
            {#if loading}
              <SpinnerComponent class="ssm"/>
            {/if}
          </button>
        </div>
      </div>
    </div>
  </ModalBody>
</Modal>

<style>
  :global(.modal-trial) {
    padding: 40px;
  }

  .divider {
    border-bottom: 1px solid #eff2f7 !important;
  }
</style>
