<script>
  import {Input, Button, Modal, ModalHeader, ModalBody, Form, FormGroup} from "sveltestrap";
  import TextAreaAutosize from "../../../Components/TextAreaAutosize.svelte";
  import {_} from "svelte-i18n";

  export let variables = [];
  export let text = '';
  export let required = false;
  export let updateState = () => {};

  let variableRegexp = /\{\{\d+\}\}/gm,
    showExamplesButton = false,
    openExamples = false,
    template = {
      body: {
        text: text,
      }
    };
  let thisTextArea;

  function checkForVariables() {
    let variableRegexp = /\{\{\d+}}/gm;
    let variable = false;
    if (template.body && template.body.text && template.body.text.match(variableRegexp)) {
        variable = true
    } else {
      template.body.examples = [];
    }
    showExamplesButton = variable;
  }

  function addVariable(model, field = 'text', max = 0, index = -1) {
    if (!template[model]) return;
    const old_value = index >= 0 ? template[model][index][field] : template[model][field];
    let new_value, variables = old_value.match(variableRegexp);
    if (variables && max && variables.length > max) {
        new_value = old_value.replace(/\{\{\d+\}\}/, (match, offset) => (offset + 1 >= max) ? "" : `{{${offset + 1}}}`);
    } else if (variables && variables.length) {
        new_value = old_value + `{{${variables.length + 1}}}`
    } else {
        new_value = old_value ? old_value + `{{1}}` : `{{1}}`;
    }

    if (index >= 0) template[model][index][field] = new_value
    else template[model][field] = new_value;
  }

  function addStyle(style) {
    const start = thisTextArea.selectionStart;
    const end = thisTextArea.selectionEnd;
    let selectedText = thisTextArea.value.substring(start, end);
    selectedText = selectedText ? selectedText : 'Text';

    switch (style) {
        case "bold": {
            template.body.text = `${thisTextArea.value.substring(0, start)}*${selectedText}*${thisTextArea.value.substring(end)}`
            break
        }
        case "italic": {
            template.body.text = `${thisTextArea.value.substring(0, start)}_${selectedText}_${thisTextArea.value.substring(end)}`
            break
        }
        case "strike": {
            template.body.text = `${thisTextArea.value.substring(0, start)}~${selectedText}~${thisTextArea.value.substring(end)}`
            break
        }
    }
  }

  function addExamples() {
    let matches = template.body.text.match(/\{\{\d+}}/gm);
    if (!template.body.examples) template.body.examples = [];
    if (matches) {
        let examples = [];
        for (let match of matches) {
            let pos = match.replace(/[\{\}]/g, '');
            pos--;
            examples[pos] = {name: match};
            if (template.body.examples[pos] && template.body.examples[pos].value) examples[pos].value = template.body.examples[pos].value;
        }
        template.body.examples = examples;
    } else {
      template.body.examples = [];
    }
  }

  function submitFormManagingVariables(e) {
    e.preventDefault();
    let elements = e.target.elements;
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].nodeName === "SELECT") {
        template.body.text = template.body.text.replaceAll('{{' + (i + 1) + '}}', `{{${elements[i].value}}}`);
      }
    }
    openExamples=false
  }

  $: if (template) {
    checkForVariables();
    addExamples();
    updateState(template.body.text);
  }
</script>

<div class="mb-4">
  <div class="fw-500 mb-2">{$_('auto_reply.creating_template.title')}</div>
  <TextAreaAutosize bind:value={template.body.text} {required} minRows={3} bind:thisTextArea/>
  <div class="mt-2">
      <span class="btn-group btn-group-sm" role="group" aria-label="Style text">
        <Button type="button" color="secondary" on:click={() => addStyle('bold')}><strong>bold</strong></Button>
        <Button type="button" color="secondary" on:click={() => addStyle('strike')}><del>strike</del></Button>
        <Button type="button" color="secondary" on:click={() => addStyle('italic')}><em>italic</em></Button>
      </span>
      <Button type="button" on:click={() => {addVariable('body')}} color="primary" size="sm">
          <i class="bx bx-plus font-size-12 align-middle"/> {$_('waba.template.add_variable')}
      </Button>
      {#if showExamplesButton}
        <button type="button" class="btn btn-link btn-sm custom-button_box-shadow-none" on:click={() => openExamples=true}>
          {$_('auto_reply.creating_template.managing_variables')}
        </button>
      {/if}
  </div>
</div>

<Modal isOpen={openExamples} toggle={() => openExamples=false} size="sm">
  <ModalHeader toggle={() => openExamples=false}>
    {$_('auto_reply.creating_template.managing_variables')}
  </ModalHeader>
  <ModalBody>
    <Form on:submit={submitFormManagingVariables}>
      {#each template.body.examples as example}
        <FormGroup class="d-flex align-items-center">
          <div class="fw-500 me-3 font-size-16">{example.name}</div>
          <Input type="select" required>
            {#each variables as variable}
              <option value={variable}>{$_(`auto_reply.creating_template.variables.${variable}`)}</option>
            {/each}
          </Input>
        </FormGroup>
      {/each}
      <div class="text-center">
        <button type="submit" class="btn btn-success custom-button-2">{$_('auto_reply.button_save')}</button>
      </div>
    </Form>
  </ModalBody>
</Modal>
