<script>
  import {Modal, ModalBody} from "sveltestrap";
  import {
    MESSAGE_DIRECTION_RECEIVED,
    MESSAGE_DIRECTION_SENT,
    MESSAGE_DIRECTION_SYSTEM,
    MESSAGE_CONTENT_TYPE_NOTE,
  } from '../../../../helpers/constants';
  import SpinnerComponent from "../../../../Components/Spinners/SpinnerComponent.svelte";
  import MessageContent from './elements/MessageContent.svelte';
  import MessageScheduled from "./elements/MessageScheduled/index.svelte";
  import SystemMessage from './elements/MessageContentTypes/SystemMessage.svelte';
  import {onDestroy, onMount} from "svelte";

  export let chosenChat;
  export let activeOperators;
  export let messages;
  export let messagesLoading;
  export let messageGetter;
  export let scheduledMessages;
  export let scheduledMessagesLoading;
  export let scheduledMessageGetter;
  export let replyMessage;
  export let isCloud;
  let upperLoader;
  let downLoader;

  let dateModal = {type: null, url: null, text: null};
  const viewModal = (type = null, url = null, text = null) => {
    dateModal = {type, url, text};
  };

  const observer = new IntersectionObserver(entries => {
    if (entries[0].isIntersecting && $messages.length) {
      if (!$messagesLoading && messageGetter) {
        messageGetter.getMessages(false)
      }
    }
  });

  const observerDown = new IntersectionObserver(entries => {
    if (entries[0].isIntersecting && $messages.length && $chosenChat.message_id) {
      if (!$messagesLoading && messageGetter) {
        messageGetter.getMessages(true)
      }
    }
  });

  onMount(() => {
    observer.observe(upperLoader);
    observerDown.observe(downLoader);
  });

  onDestroy(() => {
    observer.unobserve(upperLoader);
    observerDown.unobserve(downLoader);
  });
</script>

<ul class="m-0 py-3 h-100 overflow-auto d-flex flex-column-reverse MessageChat">
  {#if !$messagesLoading && !$scheduledMessagesLoading && !$chosenChat.message_id}
    <MessageScheduled {scheduledMessages} {scheduledMessageGetter} {viewModal} {activeOperators}/>
  {/if}
  <li bind:this={downLoader}
    class="d-flex align-items-center justify-content-center w-100 {$messagesLoading && $messages.length && $chosenChat.message_id ? 'h-100' : ''}">
    {#if $messagesLoading && $messages.length && $chosenChat.message_id}
      <SpinnerComponent/>
    {/if}
  </li>
  {#each $messages as message (message.id)}
    <li>
      {#if message.direction === MESSAGE_DIRECTION_RECEIVED}
        <MessageContent {message} {viewModal} direction={false} {replyMessage} {isCloud}/>
      {:else if message.direction === MESSAGE_DIRECTION_SENT}
        <MessageContent {message} {viewModal} {activeOperators} color={!message.profile_id ? "blue" : "green"} classes="ms-auto" {replyMessage} {isCloud}/>
      {:else if message.direction === MESSAGE_DIRECTION_SYSTEM}
        {#if message.content_type === MESSAGE_CONTENT_TYPE_NOTE}
          <MessageContent {message} {viewModal} {activeOperators} color="yellow" classes="ms-auto"/>
        {:else}
          <SystemMessage {message}/>
        {/if}
      {/if}
    </li>
  {/each}
  <li bind:this={upperLoader}
    class="d-flex align-items-center justify-content-center w-100 {$messagesLoading && $messages.length % messageGetter.messagesLimitPerLoad === 0 ? 'h-100' : ''}">
    {#if $messagesLoading && $messages.length % messageGetter.messagesLimitPerLoad === 0}
      <SpinnerComponent/>
    {/if}
  </li>
</ul>
{#if !!dateModal.url}
  <Modal isOpen={!!dateModal.url} toggle={viewModal} size="xl">
    <div slot="external" class="text-end">
      <i class="mdi mdi-close d-inline-block px-4 py-2 pointer text-white font-size-30" on:click={viewModal}/>
    </div>
    <ModalBody class="p-1">
      {#if dateModal.type === 'image'}
        <img src={dateModal.url} alt={dateModal.text} class="w-100 h-100 rounded-3"/>
      {:else if dateModal.type === 'video'}
        <video class="d-flex w-100 h-100 rounded-3" controls>
          <source src={dateModal.url}/>
          <track kind="captions"/>
        </video>
      {/if}
    </ModalBody>
  </Modal>
{/if}

<style>
  .MessageChat {
    list-style: none;
    padding-left: 20px;
    padding-right: 45px;
  }

  .MessageChat::-webkit-scrollbar {
    width: 5px;
  }

  .MessageChat li {
    clear: both;
  }
</style>
