<script>
  // import {onDestroy} from "svelte";
  import {Modal, ModalBody} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {AudioPlayer} from 'svelte-audio-player';
  import {
    MESSAGE_CONTENT_TYPE_TEXT,
    MESSAGE_CONTENT_TYPE_IMAGE,
    MESSAGE_CONTENT_TYPE_VOICE,
    MESSAGE_CONTENT_TYPE_FILE,
    MESSAGE_CONTENT_TYPE_VIDEO,
    MESSAGE_CONTENT_TYPE_LOCATION,
    MESSAGE_CONTENT_TYPE_INTERACTIVE_LOCATION_REQUEST_MESSAGE,
    MESSAGE_CONTENT_TYPE_CATALOG,
  } from '../../../../../../helpers/constants';
  // import SpinnerComponent from "../../../../../../Components/Spinners/SpinnerComponent.svelte";
  import Image from "../MessageContentTypes/Image.svelte";
  import Video from "../MessageContentTypes/Video.svelte";
  import File from '../MessageContentTypes/File.svelte';
  import Voice from '../MessageContentTypes/Voice.svelte';
  import Location from '../MessageContentTypes/Location.svelte';
  import Text from '../MessageContentTypes/Text.svelte';
  import Catalog from "../MessageContentTypes/Catalog.svelte";
  import Carousel from "../MessageContentTypes/Carousel.svelte";

  export let scheduledMessages;

  // export let scheduledMessagesLoading;
  // export let scheduledMessageGetter;
  export let activeOperators;
  // export let scheduledEdit;
  export let scheduledDelete;
  export let isOpen = false;

  // let downLoader;
  let search = '';
  let hideModal = false;

  const operator = (message) => $activeOperators?.find(({profile}) => message.profile_id === profile.id);

  const parseTemplate = (message) => {
    if (message.template_data) {
      message.template_data.forEach(({type, parameters, cards}) => {
        switch (type) {
          case 'header':
            if(parameters[0].type === 'text') {
              message.header = parameters[0].text
            }
            if(parameters[0].type === 'image') {
              message.image_url = parameters[0].image.link
            }
            if(parameters[0].type === 'document') {
              message.file_url = parameters[0].document.link
            }
            if(parameters[0].type === 'video') {
              message.video_url = parameters[0].video.link
            }
            break;
          case 'body':
            if(parameters[0].type === 'text') {
              message.text = parameters[0].text
            }
            break;
          case 'footer':
            if(parameters[0].type === 'text') {
              message.footer = parameters[0].text
            }
            break;
          case 'buttons':
            message.buttons = parameters;
            break;
          case 'CAROUSEL':
            message.carousel = cards;
            break;
        }
      });
      return message;
    } else {
      return message;
    }
  };

  // const observerDown = new IntersectionObserver(entries => {
  //   if (entries[0].isIntersecting && $scheduledMessages.length) {
  //     if (!$scheduledMessagesLoading && scheduledMessageGetter) {
  //       scheduledMessageGetter.getMessages()
  //     }
  //   }
  // });

  // $: if (downLoader) {
  //   observerDown.observe(downLoader);
  // }

  // onDestroy(() => {
  //   observerDown.unobserve(downLoader);
  // });

  const closeModal = () => {
    isOpen = false;
  };

  const onSearch = () => {
    return $scheduledMessages.filter(item => item.text.toLowerCase().includes(search.toLowerCase()));
  };

  let dateModal = {type: null, url: null, text: null};
  const viewModal = (type = null, url = null, text = null) => {
    hideModal = !hideModal;
    dateModal = {type, url, text};
  };
</script>

<Modal isOpen={isOpen} fade={true} toggle={closeModal} class={hideModal ? 'hideModalScheduled' : ''}>
<div class="modal-body Scheduled-modal">
  <div class="px-md-2">
    <div class="d-flex justify-content-between">
      <h4 class="font-size-18 fw-600 mb-3 me-3">{$_('scheduled.scheduled_messages')}</h4>
      <button type="button" class="btn-close" aria-label="Close" on:click={closeModal}/>
    </div>
    <div class="position-relative mb-3">
      <input type="text" placeholder={$_('search')} bind:value={search}
        class="form-control border-1 rounded-2 text-muted"/>
      <i class="bx bx-search-alt position-absolute font-size-20 text-muted top-0"/>
    </div>
    <ul class="px-0 m-0 overflow-auto Scheduled-modal-wrapper">
      {#each (search ? onSearch() : $scheduledMessages) as message, index (message.id)}
        <li class="pt-3 ps-md-2 pe-2 d-flex Scheduled-modal-message {$scheduledMessages.length - 1 === index ? '' : 'border-bottom'}">
          <div class="me-2 me-md-3 d-none d-sm-block">
            {#if operator(message).profile.avatar_url}
              <img src={operator(message).profile.avatar_url}
                class="avatar-sm rounded-circle object-fit-cover"
                alt={operator(message).profile.user.username}>
            {:else}
              <div class="avatar-sm">
                <span class="avatar-title rounded-circle text-white font-size-16">
                  {operator(message).profile.user.username.toUpperCase().charAt(0)}
                </span>
              </div>
            {/if}
          </div>
          <div class="position-relative overflow-hidden w-100">
            <div class="position-relative overflow-hidden w-100 pb-2">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <div class="font-size-15 fw-600 text-truncate me-3">
                  {operator(message).profile.user.username}
                </div>
                <div class="d-flex gap-2">
                  <!-- <button type="button" class="p-0 bg-white border-0 lh-1" on:click={() => scheduledEdit(message)}>
                    <i class="bx bx-edit-alt font-size-20 text-muted"/>
                  </button> -->
                  <button type="button" class="p-0 bg-white border-0 lh-1" on:click={() => scheduledDelete(message)}>
                    <i class="bx bx-trash font-size-20 text-muted"/>
                  </button>
                </div>
              </div>
              <div class="text-muted">
                {#if message.content_type === MESSAGE_CONTENT_TYPE_IMAGE || message.image_url}
                  <Image message={parseTemplate(message)} {viewModal} direction={true} scheduled={true} liteVersio={true}/>
                {:else if message.content_type === MESSAGE_CONTENT_TYPE_VIDEO || message.video_url}
                  <Video message={parseTemplate(message)} {viewModal} direction={true} scheduled={true} liteVersio={true}/>
                {:else if message.content_type === MESSAGE_CONTENT_TYPE_VOICE && message.file_url}
                  <AudioPlayer src={message.file_url}>
                    <Voice message={message} direction={true} scheduled={true} liteVersio={true}/>
                  </AudioPlayer>
                {:else if message.content_type === MESSAGE_CONTENT_TYPE_FILE || message.file_url}
                  <File message={parseTemplate(message)} direction={true} scheduled={true} liteVersio={true}/>
                {:else if message.content_type === MESSAGE_CONTENT_TYPE_LOCATION && message.text}
                  <Location {message} direction={true} scheduled={true} liteVersio={true}/>
                {:else if message.content_type === MESSAGE_CONTENT_TYPE_CATALOG}
                  <Catalog message={parseTemplate(message)} direction={true} scheduled={true} liteVersio={true}/>
                {:else if (message.content_type === MESSAGE_CONTENT_TYPE_TEXT || message?.content_type === MESSAGE_CONTENT_TYPE_INTERACTIVE_LOCATION_REQUEST_MESSAGE) && message.text}
                  <Text message={parseTemplate(message)} direction={true} scheduled={true} liteVersio={true}/>
                {/if}
                {#if parseTemplate(message)?.buttons}
                  <div class="d-flex flex-wrap mt-2">
                    {#each parseTemplate(message).buttons as btn}
                      {#if btn.type === 'URL'}
                        <a href={btn.url} target="_blank" rel="nofollow" class="d-inline-flex align-items-center justify-content-center pointer pe-2 text-muted" title={btn?.url}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                            <g clip-path="url(#clip0_987_13546)">
                              <path fill="#74788d"
                                d="M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_987_13546">
                                <rect width="16" height="16" fill="white"/>
                              </clipPath>
                            </defs>
                          </svg>
                          <span class="text-truncate font-size-14">{btn.text}</span>
                        </a>
                      {:else}
                        <div rel="nofollow" class="d-flex align-items-center justify-content-center pointer pe-2 text-muted" title={btn?.phone_number}>
                          {#if btn.type === 'PHONE_NUMBER'}
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                              <g clip-path="url(#clip0_987_13638)">
                                <path fill="#74788d"
                                  d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.7533 9.8 10.4933 9.86 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_987_13638">
                                  <rect width="16" height="16" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          {:else if btn.type === 'OTP'}
                            <i class="bx bx-copy-alt font-size-16 me-1"/>
                          {/if}
                          <span class="text-truncate font-size-14">{btn.text}</span>
                        </div>
                      {/if}
                    {/each}
                  </div>
                {/if}
                {#if message?.content_type === MESSAGE_CONTENT_TYPE_INTERACTIVE_LOCATION_REQUEST_MESSAGE}
                  <div class="d-flex flex-wrap mt-2">
                    <div rel="nofollow" class="d-flex align-items-center justify-content-center pointer pe-2 text-muted">
                      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                        <path fill="#74788d"
                          d="M12.0001 2C7.58908 2 4.00008 5.589 4.00008 9.995C3.97108 16.44 11.6961 21.784 12.0001 22C12.0001 22 20.0291 16.44 20.0001 10C20.0001 5.589 16.4111 2 12.0001 2ZM12.0001 14C9.79008 14 8.00008 12.21 8.00008 10C8.00008 7.79 9.79008 6 12.0001 6C14.2101 6 16.0001 7.79 16.0001 10C16.0001 12.21 14.2101 14 12.0001 14Z"/>
                      </svg>
                      <span class="text-truncate font-size-14">{$_('chat_inbox.MessageChat.send_location')}</span>
                    </div>
                  </div>
                {/if}
              </div>
            </div>
            {#if parseTemplate(message)?.carousel}
              <Carousel carousel={parseTemplate(message).carousel} {viewModal} scheduled={true} liteVersio={true}/>
            {/if}
          </div>
        </li>
      {/each}
      <!-- <li bind:this={downLoader} class="d-flex align-items-center justify-content-center py-1">
        {#if $scheduledMessagesLoading}
          <SpinnerComponent/>
        {/if}
      </li> -->
    </ul>
  </div>
</div>
</Modal>

{#if !!dateModal.url}
  <Modal isOpen={!!dateModal.url} toggle={viewModal} size="xl">
    <div slot="external" class="text-end">
      <i class="mdi mdi-close d-inline-block px-4 py-2 pointer text-white font-size-30" on:click={viewModal}/>
    </div>
    <ModalBody class="p-1">
      {#if dateModal.type === 'image'}
        <img src={dateModal.url} alt={dateModal.text} class="w-100 h-100 rounded-3"/>
      {:else if dateModal.type === 'video'}
        <video class="d-flex w-100 h-100 rounded-3" controls>
          <source src={dateModal.url}/>
          <track kind="captions"/>
        </video>
      {/if}
    </ModalBody>
  </Modal>
{/if}

<style>
  .Scheduled-modal input {
    padding-left: 40px;
  }

  .Scheduled-modal .bx-search-alt {
    top: 0;
    left: 11px;
    line-height: 40px;
  }

  .Scheduled-modal-wrapper {
    max-height: calc(100vh - 215px);
    min-height: 300px;
  }

  .Scheduled-modal-wrapper::-webkit-scrollbar {
    width: 3px;
  }

  .Scheduled-modal-wrapper::-webkit-scrollbar-thumb {
    background-color: #DBDBDD;
  }

  .Scheduled-modal-message .avatar-title {
    background-color: #0CA970;
  }

  :global(.hideModalScheduled) {
    opacity: 0;
  }
</style>
