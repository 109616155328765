<script>
  import {onMount} from "svelte";
  import {_} from "svelte-i18n";
  import {Row, Col, Button, FormGroup, Label, Input} from "sveltestrap";
  import {channels, chosenChannel} from "../../helpers/store";
  import {promisifyStore, postRequest, getRequest} from "../../helpers/utils";
  import PrettyfiedCode from "../../Components/PrettyfiedCode.svelte";
  import SelectDropdown from "../../Components/SelectDropdown.svelte";
  import TextAreaAutosize from "../../Components/TextAreaAutosize.svelte";
  import WhatsappTemplatesModal from "../../Components/Modals/WhatsappTemplatesModal2.svelte";
  import SpinnerComponent from "../../Components/Spinners/SpinnerComponent.svelte";

  let Response = '';
  let loader = false;

  let listChannel = [];
  let selectedChannel;
  let chosenChannelLocal;
  let sendToNumber = '';
  let disabledSendToNumber = false;
  let activeTab = 0;

  let messageBody = '';

  let templates = [];
  let chosenTemplate = null;
  let paramsTemplate = [];
  let isOpenTemplates = false;
  let loaderGetTemplates = false;

  $: selectedChannel && getTemplates();
  $: selectedChannel && checkTrial();

  function getTemplates() {
    loaderGetTemplates = true;
    const channel = listChannel.find(({value}) => value === selectedChannel);
    getRequest(`${chosenChannelLocal?.apiUrl}templates`, {token: channel?.token}).then(res => {
      if (res?.templates) {
        chosenTemplate = null;
        paramsTemplate = [];
        templates = res.templates.filter(item => item.status === 'approved');
      } else {
        templates = [];
        chosenTemplate = null;
        paramsTemplate = [];
      }
      loaderGetTemplates = false;
    });
  }

  function getParamsTemplate() {
    const params = [];
    chosenTemplate?.components?.map(item => {
      let parameters = [];
      if (item.type === 'HEADER') {
        switch (item.format) {
          case 'TEXT':
            item.text.match(/\{\{\d+}}/gm)?.map(() => parameters.push({type: 'text', text: ''}));
            break;
          case 'IMAGE':
            parameters.push({type: 'image', image: {link: ''}});
            break;
          case 'VIDEO':
            parameters.push({type: 'video', video: {link: ''}});
            break;
          case 'DOCUMENT':
            parameters.push({type: 'document', document: {link: ''}});
            break;
        }
        if (parameters.length) {
          params.push({type: 'header', parameters});
        }
      } else if (item.type === 'BODY') {
        if (chosenTemplate.category === "AUTHENTICATION") {
          params.push({type: 'body', parameters: [{type: 'text', text: ''}]});
        } else {
          item.text.match(/\{\{\d+}}/gm)?.map(() => parameters.push({type: 'text', text: ''}));
          if (parameters.length) {
            params.push({type: 'body', parameters});
          }
        }
      } else if (item.type === 'BUTTONS') {
        if (chosenTemplate.category === "AUTHENTICATION") {
          let btn = item.buttons.find(button => button.otp_type === "COPY_CODE");
          if (btn) {
            params.push({type: 'button', "sub_type": "url", parameters: [{type: 'text', text: ''}]})
          }
        } else {
          item.buttons.forEach((btn, index) => {
            if (btn.type === 'URL' && btn.url.match(/\{\{\d+}}/gm)) {
              params.push({type: 'button', index, sub_type: 'url', parameters: [{type: 'text', text: ''}]});
            }
            if (btn.type === 'CATALOG') {
              params.push({type: 'button', sub_type: 'CATALOG', index: 0, parameters: []})
            }
          });
        }
      }
    });
    paramsTemplate = params;
  }

  function addCatalogParameter() {
    let index = paramsTemplate.findIndex(item => item.type === 'button');
    paramsTemplate[index].parameters = [...paramsTemplate[index].parameters, {type: 'action', action: {thumbnail_product_retailer_id: ''}}];
  }

  function deleteCatalogParameter(i) {
    let index = paramsTemplate.findIndex(item => item.type === 'button');
    paramsTemplate[index].parameters = paramsTemplate[index].parameters.filter((val, index) => index !== i);
  }

  function checkTrial() {
    let channel = listChannel.find(({value}) => value === selectedChannel);
    if (channel?.sandbox_phone) {
      sendToNumber = channel.sandbox_phone;
      disabledSendToNumber = true;
    } else {
      disabledSendToNumber = false;
    }
  }

  onMount(() => {
    promisifyStore(channels).then(channelsData => {
      listChannel = Array.isArray(channelsData) && channelsData.length ? channelsData.reduce(
        (result, item) => {
          if (item.appData) {
            let trialData = {};
            if (item?.srv === 'trial') {
              trialData = {sandbox_phone: item.sandbox_phone};
            }
            result.push({
              data: item,
              value: item.id,
              token: item.token,
              label: `${item.id} ${item.name}`,
              ...trialData
            });
          }
          return result;
        },
        []
      ) : [];
    });

    promisifyStore(chosenChannel).then(chosenChannelData => {
      selectedChannel = chosenChannelData.id;
      chosenChannelLocal = chosenChannelData;
    });
  });

  function setChannellocal(channelData) {
    chosenChannelLocal = channelData.data;
  }

  function Submit() {
    loader = true;
    const channel = listChannel.find(({value}) => value === selectedChannel);
    if (activeTab === 0) {
      postRequest(`${chosenChannelLocal?.apiUrl}sendTemplate`, {
        token: channel.token,
        namespace: chosenTemplate.namespace,
        template: chosenTemplate.name,
        language: {policy: 'deterministic', code: chosenTemplate.language},
        params: paramsTemplate,
        phone: sendToNumber
      }).then(response => {
        Response = JSON.stringify(response, null, 2);
      }).catch(error => {
        Response = JSON.stringify(error, null, 2);
      }).finally(() => {
        loader = false;
      });
    } else if (activeTab === 1) {
      postRequest(`${chosenChannelLocal?.apiUrl}sendMessage`, {
        token: channel.token,
        phone: sendToNumber,
        body: messageBody
      }).then(response => {
        Response = JSON.stringify(response, null, 2);
      }).catch(error => {
        Response = JSON.stringify(error, null, 2);
      }).finally(() => {
        loader = false;
      });
    }
  }
</script>

<div class="fw-600 font-size-18 mb-4">{$_('TestingSendMessage.title')}</div>
<Row>
  <Col class="mb-3 mb-xl-0" xl={6}>
    <form on:submit|preventDefault={Submit}>
      <Row>
        <Col xl={6}>
          <FormGroup>
            <Label class="fw-600">{$_('TestingSendMessage.channel_selection')}</Label>
            <SelectDropdown bind:selected={selectedChannel} change={setChannellocal} list={listChannel} title='TestingSendMessage.channel_selection'/>
          </FormGroup>
        </Col>
        <Col xl={6}>
          <FormGroup>
            <Label class="fw-600">{$_('TestingSendMessage.phone_number')}</Label>
            <Input bind:value={sendToNumber} disabled={disabledSendToNumber} required type="text"/>
          </FormGroup>
        </Col>
      </Row>
      <ul class="list-inline Type mb-3">
        <li class="list-inline-item">
          <button on:click={() => activeTab = 0} type="button"
            class="font-size-16 border-0 p-0 bg-transparent {activeTab === 0 ? 'active' : 'text-muted'}">
            {$_('TestingSendMessage.template')}
          </button>
        </li>
        <li class="list-inline-item">
          <button on:click={() => activeTab = 1} type="button"
            class="font-size-16 border-0 p-0 bg-transparent {activeTab === 1 ? 'active' : 'text-muted'}">
            {$_('TestingSendMessage.message')}
          </button>
        </li>
      </ul>
      {#if activeTab === 0}
        <div class="position-relative">
          {#if loaderGetTemplates}
            <div
              class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center bg-white bg-opacity-75 SpinnerComponent">
              <SpinnerComponent/>
            </div>
          {/if}
          <div class="mb-3">
            {#if !chosenTemplate}
              <Button outline class="py-2 px-3 d-flex align-items-center"
                color="success" type="button" on:click={() => isOpenTemplates = true}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2">
                  <path fill="#23B16D"
                    d="M16 2V4H12V2H16ZM6 2V8H2V2H6ZM16 10V16H12V10H16ZM6 14V16H2V14H6ZM18 0H10V6H18V0ZM8 0H0V10H8V0ZM18 8H10V18H18V8ZM8 12H0V18H8V12Z"/>
                </svg>
                <span class="font-size-14">{$_('broadcast.chose_template')}</span>
              </Button>
            {:else}
              <div class="badges">
                <span class="badge font-size-13">
                  {chosenTemplate.name}
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <i on:click={() => {chosenTemplate = null; paramsTemplate = []}}
                    class="mdi mdi-close pointer ms-1"/>
                </span>
              </div>
            {/if}
            <WhatsappTemplatesModal bind:isOpenTemplates bind:templates bind:chosenTemplate callback={getParamsTemplate}/>
          </div>
          {#if chosenTemplate}
            <Label class="fw-600">{$_('TestingSendMessage.template_message')}</Label>
            <div class="mb-4">
              <div class="p-3 TemplateBG">
                {#each chosenTemplate.components as component}
                  {#if component.type == 'HEADER'}
                    {#if component.format === 'TEXT'}
                      <div class="mb-2 fw-600">{component.text}</div>
                    {:else}
                      <div class="mb-2 d-inline-flex align-items-center gap-2">
                        {#if component.format === "IMAGE"}
                          <i class="bx bx-image font-size-22 text-muted pb-1"/> {$_('broadcast.image')}
                        {:else if component.format === "VIDEO"}
                          <i class="bx bx-video font-size-22 text-muted pb-1"/> {$_('broadcast.video')}
                        {:else if component.format === "DOCUMENT"}
                          <i class="bx bx-file font-size-22 text-muted pb-1"/> {$_('broadcast.document')}
                        {/if}
                      </div>
                    {/if}
                  {/if}
                  {#if component.type == 'BODY'}
                    <div class="TemplateText">
                      {#if chosenTemplate.category !== "AUTHENTICATION"}
                        {component.text}
                      {:else}
                        {`{{1}} is your verification code.`}
                        {#if component?.add_security_recommendation}
                          {`For your security, do not share this code.`}
                        {/if}
                      {/if}
                    </div>
                  {/if}
                  {#if component.type == 'FOOTER'}
                    <div class="mt-2">
                      {#if chosenTemplate.category !== "AUTHENTICATION"}
                        {component.text}
                      {:else}
                        {`This code expires in ${component?.code_expiration_minutes} minutes`}
                      {/if}
                    </div>
                  {/if}
                {/each}
              </div>
              {#if (chosenTemplate.components.find((item) => item.type === 'BUTTONS')?.buttons || []).length}
                <div class="mt-2 d-flex flex-wrap justify-content-between gap-2">
                  {#each chosenTemplate.components.find((item) => item.type === 'BUTTONS')?.buttons || [] as button}
                    <div class="d-flex align-items-center justify-content-center py-1 px-3 flex-grow-1 TemplateButton">
                      {#if button.type === 'URL' || button.type === 'PHONE_NUMBER'}
                        <i class="mdi mdi-{button.type === 'URL' ? 'open-in-new' : 'phone'} font-size-16 me-1"/>
                      {/if}
                      {#if button.type === 'OTP'}
                        <i class="bx bx-copy-alt font-size-16 me-1"/>
                      {/if}
                      <span class="text-truncate">{button.text}</span>
                    </div>
                  {/each}
                </div>
              {/if}
            </div>
            <Row>
              {#each paramsTemplate as item}
                {#if item.type === 'header'}
                  <Col xl={12}>
                    {#each item.parameters as parameter, i}
                      <FormGroup>
                        {#if parameter.type === 'text'}
                          <Label class="fw-600">header {'{'}{'{'}{i + 1}{'}'}{'}'}</Label>
                          <Input bind:value={parameter.text} type="text" required/>
                        {/if}
                        {#if parameter.type === 'image'}
                          <Label class="fw-600">image</Label>
                          <Input bind:value={parameter.image.link} placeholder="link" type="text" required/>
                        {/if}
                        {#if parameter.type === 'video'}
                          <Label class="fw-600">video</Label>
                          <Input bind:value={parameter.video.link} placeholder="link" type="text" required/>
                        {/if}
                        {#if parameter.type === 'document'}
                          <Label class="fw-600">document</Label>
                          <Input bind:value={parameter.document.link} placeholder="link" type="text" required/>
                        {/if}
                      </FormGroup>
                    {/each}
                  </Col>
                {/if}
                {#if item.type === 'body'}
                  {#each item.parameters as parameter, i}
                    <Col xl={6}>
                      <FormGroup>
                        <Label class="fw-600">body {'{'}{'{'}{i + 1}{'}'}{'}'}</Label>
                        <Input bind:value={parameter.text} type="text" required/>
                      </FormGroup>
                    </Col>
                  {/each}
                {/if}
                {#if item.type === 'button'}
                  {#if item?.sub_type === 'CATALOG'}
                    {#each item.parameters as parameter, i}
                      <Col xl={12} class="d-flex">
                        <FormGroup class="w-100 me-3">
                          <Label class="fw-600">product id {i + 1}</Label>
                          <Input bind:value={parameter.action.thumbnail_product_retailer_id} type="text"/>
                        </FormGroup>
                        <button type=button class="btn deleteBtn rounded-circle" on:click={() => deleteCatalogParameter(i)}>-</button>
                      </Col>
                    {/each}
                    <Col xl={12} class="mb-3">
                      <Button type=button outline color=primary on:click={addCatalogParameter}>{$_('TestingSendMessage.add_catalog_parameters')}</Button>
                    </Col>
                  {:else}
                    {#each item.parameters as parameter, i}
                      <Col xl={12}>
                        <FormGroup>
                          <Label class="fw-600">button {'{'}{'{'}{i + 1}{'}'}{'}'}</Label>
                          <Input bind:value={parameter.text} type="text" required/>
                        </FormGroup>
                      </Col>
                    {/each}
                  {/if}
                {/if}
              {/each}
            </Row>
          {/if}
          <Button type="submit" color="success" class="py-2 px-4" disabled={chosenTemplate === null || loader}>
            <span class="font-size-14">{$_('send')}</span>
          </Button>
        </div>
      {/if}
      {#if activeTab === 1}
        <FormGroup>
          <Label class="fw-600">{$_('TestingSendMessage.message_body')}</Label>
          <div class="MessageBody rounded-2">
            <TextAreaAutosize bind:value={messageBody} classes="border-0 bg-transparent" required={true} minRows={8}/>
          </div>
        </FormGroup>
        <Button type="submit" color="success" class="py-2 px-4" disabled={loader}>
          <span class="font-size-14">{$_('send')}</span>
        </Button>
      {/if}
    </form>
  </Col>
  <Col xl={6}>
    <div class="mb-3">
      <Label class="fw-600">{$_('request')}</Label>
      {#if activeTab === 0}
        <PrettyfiedCode code={
`POST ${chosenChannelLocal?.apiUrl}sendTemplate
Content-Type: application/json
${JSON.stringify({
token: listChannel.find(({value}) => value === selectedChannel)?.token,
namespace: chosenTemplate?.namespace ?? '',
template: chosenTemplate?.name ?? '',
language: {policy: 'deterministic', code: chosenTemplate?.language ?? ''},
params: paramsTemplate,
phone: sendToNumber
}, null, 2)}`
        }/>
      {:else if activeTab === 1}
        <PrettyfiedCode code={
`POST ${chosenChannelLocal?.apiUrl}sendMessage
Content-Type: application/json
${JSON.stringify({
token: listChannel.find(({value}) => value === selectedChannel)?.token,
phone: sendToNumber,
body: messageBody
}, null, 2)}`
        }/>
      {/if}
    </div>
    <div>
      <Label class="fw-600">{$_('response')}</Label>
      <PrettyfiedCode code={loader ? $_('loader...') : Response}/>
    </div>
  </Col>
</Row>

<style>
  .TemplateBG {
    background-color: #EFF2F7;
  }

  .TemplateText {
    white-space: pre-wrap;
    word-break: break-word;
  }

  .TemplateButton {
    min-width: calc(50% - 4px);
    background-color: #EFF2F7;
  }

  .MessageBody {
    background-color: #EFF2F7;
  }

  button svg path {
    transition: .15s;
  }

  button:hover svg path {
    fill: #fff;
  }

  .badges {
    line-height: 3;
  }

  .badges .badge {
    line-height: 18px;
    color: #495057;
    padding: 7px 10px;
    background-color: #EFF2F7;
  }

  .Type .active {
    color: #23B16D;
    border-bottom: 1px solid #23B16D !important;
  }

  .SpinnerComponent {
    z-index: 1;
  }

  .deleteBtn {
    color: #495057 !important;
    min-width: 40px;
    height: 40px;
    background-color: #EFF2F7;
    box-shadow: none;
  }

  .deleteBtn {
    line-height: 16px;
    font-size: 22px;
    margin-top: 28px;
  }
</style>
