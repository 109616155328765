<script>
  import MessageInfo from "../MessageInfo.svelte";
  import Text from "./Text.svelte";
  import {_} from "svelte-i18n";

  export let message;
  export let viewModal;
  export let direction;
  export let scheduled = false;
  export let liteVersio = false;
</script>

<div class={!liteVersio ? "p-1" : ""}>
  {#if scheduled}
    <div class="{!liteVersio ? "videoScheduled" : ""} d-flex align-items-center gap-2 rounded-3 text-muted font-size-15 pointer"
      on:click={() => viewModal('video' , message.video_url)}>
      <i class="bx bx-video font-size-22"/> <span class="text-truncate">{$_('broadcast.video')}</span>
    </div>
    {#if !message.text}
      <div class="mt-1">
        <MessageInfo {message} {direction} {scheduled} classes={liteVersio ? "pe-0" : ""}/>
      </div>
    {/if}
  {:else}
    <div class="d-flex align-items-center justify-content-center overflow-hidden pointer rounded-3 position-relative"
      on:click={() => viewModal('video' , message.video_url)}>
      <video class="w-100">
        <source src={message.video_url}/>
        <track kind="captions">
      </video>
      <div class="d-flex align-items-center justify-content-center rounded-circle position-absolute text-light VideoPlay">
        <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" version="1.1" style="margin-left: 2px">
          <path fill="currentColor"
            d="M19.5,10.9 L6.5,3.4 C5.2,2.7 4.1,3.3 4.1,4.8 L4.1,19.8 C4.1,21.3 5.2,21.9 6.5,21.2 L19.5,13.7 C20.8,12.8 20.8,11.6 19.5,10.9 Z">
          </path>
        </svg>
      </div>
    </div>
    {#if !message.text}
      <div class="position-absolute bottom-0 end-0">
        <MessageInfo {message} {direction} {scheduled} classes="text-white mb-2" wrapperStyle="rounded p-1 bg-dark bg-opacity-75"/>
      </div>
    {/if}
  {/if}
</div>
{#if message.text}
  <Text {message} {direction} {scheduled} {liteVersio} classes={!liteVersio ? "pt-0" : "pt-2"}/>
{/if}

<style>
  video {
    height: 250px;
    object-fit: cover;
    background-color: #f6f6f6;
  }

  .VideoPlay {
    width: 50px;
    height: 50px;
    background-color: rgba(11,20,26,.35);
  }

  .videoScheduled {
    padding: 7px;
    background-color: rgba(255, 255, 255, 0.2);
  }
</style>
