import {writable} from "svelte/store";

const toasts = writable([]);
const addToast = async (toast) => {
  // Create a unique ID so we can easily find/remove it
  // if it is dismissible/has a timeout.
  const id = Math.floor(Math.random() * 10000);

  // Setup some sensible defaults for a toast.
  const defaults = {
    id,
    type: "info",
    dismissible: true,
    timeout: 3000,
  };

  let finalToast = {...defaults, ...toast};
  // Push the toast to the top of the list of Toasts
  toasts.update((all) => [finalToast, ...all]);

  // If toast is dismissible, dismiss it after "timeout" amount of time.

  if (finalToast.timeout) setTimeout(() => dismissToast(id), finalToast.timeout);
};

const dismissToast = (id) => {
  toasts.update((all) => all.filter((t) => t.id !== id));
};
export {toasts, addToast, dismissToast}