<script>
  import {Modal, ModalBody, ModalHeader, ModalFooter, Table} from "sveltestrap"
  import {_} from "svelte-i18n";

  export let isOpen = false;
  export let productItems = [];
  export let catalogId;
  export let businessId;
</script>

{#if isOpen}
  <Modal isOpen={isOpen} fade={true} toggle={() => isOpen = false} size="md" centered>
    <ModalHeader toggle={() => isOpen = false} class="border-bottom-0 pb-0">
      <span class="font-size-20 fw-bold">{$_('chat_inbox.MessageChat.modal_order.title')}</span>
    </ModalHeader>
    <ModalBody class="p-0">
      <div class="p-3">{$_('chat_inbox.MessageChat.modal_order.discription')}</div>
      <div class="table-responsive">
        <Table class="align-middle table-nowrap table-hover mb-0">
          <thead class="table-light">
            <tr>
              <th scope="col" style="width: 70px;">#</th>
              <th scope="col">{$_('chat_inbox.MessageChat.quantity')}</th>
              <th scope="col">{$_('chat_inbox.MessageChat.price')}</th>
              <th scope="col">{$_('chat_inbox.MessageChat.currency')}</th>
            </tr>
          </thead>
          <tbody>
            {#each productItems as item}
              <tr>
                <td>{item.product_retailer_id}</td>
                <td>{item.quantity}</td>
                <td>{item.item_price}</td>
                <td>{item.currency}</td>
              </tr>
            {/each}
          </tbody>
        </Table>
      </div>
    </ModalBody>
    <ModalFooter class="border-0">
      <a href="https://business.facebook.com/commerce/catalogs/{catalogId}/products?business_id={businessId}"
        target="_blank"
        class="font-size-14 btn btn-success">
        {$_('chat_inbox.MessageChat.view_products_on_FBBM')}
      </a>
    </ModalFooter>
  </Modal>
{/if}
