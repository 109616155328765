<script>
  import {Button, Modal, ModalBody, ModalHeader, Spinner, Table} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {appClient} from "../helpers/store";
  import {BotUsersDAO} from "../helpers/onetouch/BotUsersDAO";
  import {addToast} from "../helpers/toast";

  export let isOpen = false;
  export let botUsersLoader = false;
  export let selectedBotUsers = [];

  let loading = false;
  let isOpen2 = false;
  let hasChat = [];

  const closeModal = () => {
    isOpen2 = false;
    hasChat = [];
  };

  const deleteSelectBotUsers = () => {
    loading = true;
    appClient.deleteBotUsersList(selectedBotUsers).then(data => {
      if (data?.has_chat.length) {
        hasChat = data.has_chat;
        selectedBotUsers = hasChat.map(user => {
          return user.id
        });
        isOpen2 = true;
      } else {
        selectedBotUsers = [];
        addToast({title: $_("contacts.delete_report")});
      }
      botUsersLoader = true;
      BotUsersDAO.loadAll().then(() => botUsersLoader = false);
    }, err => {
      console.error(err);
      addToast({title: $_("error"), message: $_("something_went_wrong")});
    }).finally(() => {
      loading = false;
      isOpen = false;
    })
  };

  const deleteHasChat = () => {
    loading = true;
    let data = hasChat.map(user => {
      return user.id
    });
    appClient.deleteBotUsersList(data, true).then(() => {
      selectedBotUsers = [];
      botUsersLoader = true;
      BotUsersDAO.loadAll().then(() => botUsersLoader = false);
      addToast({title: $_("contacts.delete_report")});
    }, err => {
      console.error(err);
      addToast({title: $_("error"), message: $_("something_went_wrong")});
    }).finally(() => {
      loading = false;
      closeModal();
    })
  };
</script>

{#if isOpen}
  <Modal isOpen={isOpen} fade={true} toggle={() => isOpen = false} size="md" centered>
    <ModalHeader toggle={() => isOpen = false} class="border-bottom-0 pb-0 align-items-start">
      <span class="font-size-18">
        {$_("contacts.сonfirm_deleting")} <span class="text-success">{selectedBotUsers.length} {$_('contacts.contact')}</span>
      </span>
    </ModalHeader>
    <ModalBody>
      <div class="mb-4 font-size-14">
        {@html $_("contacts.сonfirm_deleting_info")}
      </div>
      <div class="text-center mb-2">
        <Button type="submit" color="danger" class="px-4 font-size-14"
          disabled={loading} on:click={deleteSelectBotUsers}>
          {#if loading}
            <Spinner size="sm"/>
          {/if}
          {$_("delete")}
        </Button>
      </div>
    </ModalBody>
  </Modal>
{/if}

{#if isOpen2}
  <Modal isOpen={isOpen2} fade={true} toggle={closeModal} size="md" centered>
    <ModalHeader toggle={closeModal} class="border-bottom-0 pb-0 align-items-start">
      {$_('contacts.delete_active_message')}
    </ModalHeader>
    <ModalBody>
      <div class="table-responsive mb-3">
        <Table class="align-middle mb-0">
          <thead class="table-light">
            <tr>
              <th style="width: 50%">{$_('contacts.table.name')}</th>
              <th style="width: 50%">{$_('contacts.table.phone_number')}</th>
            </tr>
          </thead>
        </Table>
        <div class="scroll-table-body overflow-auto">
          <Table class="mb-0 table-hover">
            <tbody>
              {#each hasChat as user}
                <tr class="font-size-15 fw-500">
                  <td style="width: 50%">{user.name}</td>
                  <td style="width: 50%">{user.phone}</td>
                </tr>
              {/each}
            </tbody>
          </Table>
        </div>
      </div>
      <Button type="submit" color="danger" class="py-2 px-4 d-inline-flex align-items-center gap-2"
        disabled={loading} on:click={deleteHasChat}>
        {#if loading}
          <Spinner size="sm"/>
        {/if}
        {$_('delete')}
      </Button>
    </ModalBody>
  </Modal>
{/if}

<style>
  .scroll-table-body {
    max-height: 500px;
  }

  .scroll-table-body::-webkit-scrollbar {
    width: 3px;
  }
</style>