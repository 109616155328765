<script>
  import {eventTracking} from "../../helpers/utils";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import {Col, Container, Row, Card, CardBody, Spinner} from "sveltestrap";
  import {user, registerUserInApp} from "../../helpers/store";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";

  let activeUser = {};
  let addAdminLoading = false;

  const unsub2 = user.subscribe((value) => {
    activeUser = value;
  });

  onDestroy(() => {
    unsub2();
  });

  const onAddAdmin = async () => {
    eventTracking('mobile_get_account');
    addAdminLoading = true;
    const res = await registerUserInApp();
    const noTokenWasThere = !activeUser.appToken
    if (!res || res.error) {
      addAdminLoading = false;
      contentModalToast = {
        class: 'text-danger',
        title: $_("error"),
        message: res.error || $_("something_went_wrong")
      };
    } else if (res.result.status === 'ok') {
      getProjectUsers();
      addAdminLoading = false;
      if (noTokenWasThere) {
        Swal.fire({
          title: $_('mobile_app.tab1.message_request_add_admin_success.title'),
          text: `${$_('mobile_app.tab1.message_request_add_admin_success.message')} “${activeUser.email}”`,
          showCancelButton: false,
          confirmButtonClass: "btn btn-success",
          icon: "success",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      }
    }
  };

</script>

<div class="page-content mobile-app px-0 px-sm-2">
  <Container fluid>
    <div class="header-title">{$_('mobile_app.title')}</div>
    <Row>
      <Col xxl="7">
        <Card>
          <CardBody >
            <p class="text-blue-granite">{$_('mobile_app.tab1.description')}</p>
            <div class="d-flex flex-column flex-sm-row ">
              <ul class="custom-ul mb-4">
                <li>
                  <i class="bx bx-right-arrow-circle"></i>
                  <div class="title mb-1">{$_('mobile_app.tab1.advantages.1.title')}</div>
                  <p class="description text-blue-granite">{$_('mobile_app.tab1.advantages.1.description')}</p>
                </li>
                <li>
                  <i class="bx bx-right-arrow-circle"></i>
                  <div class="title mb-1">{$_('mobile_app.tab1.advantages.2.title')}</div>
                  <p class="description text-blue-granite">{$_('mobile_app.tab1.advantages.2.description')}</p>
                </li>
                <li>
                  <i class="bx bx-right-arrow-circle"></i>
                  <div class="title mb-1">{$_('mobile_app.tab1.advantages.3.title')}</div>
                  <p class="description text-blue-granite">{$_('mobile_app.tab1.advantages.3.description')}</p>
                </li>
              </ul>
              <div class="text-center">
                <div class="fw-500 font-size-16 d-inline-block w-100">{$_('mobile_app.tab1.qr')}</div>
                <img src="/assets/images/app-qr.svg" alt="mobile app qr"/>
              </div>
            </div>
            <div class="d-flex gap-1 align-items-start flex-column d-md-block">
              {#if !activeUser.isOperator}
                <button type="button" class="btn btn-success custom-button" on:click={onAddAdmin}
                        disabled={addAdminLoading || (!!activeUser?.appToken && !!activeUser?.registrationMailSent)}>
                  {#if addAdminLoading}
                    <Spinner size="sm"/>
                  {/if}
                  {!!activeUser?.appToken && !!activeUser?.registrationMailSent ? $_('mobile_app.tab1.granted') : $_('mobile_app.tab1.button')}
                </button>
              {/if}
              <a href="https://play.google.com/store/apps/details?id=app.wabamsg.io" target="_blank"
                 rel="nofollow">
                <img src="/assets/images/icons/googleplay.png" alt="googleplay" class="btn-googleplay"/>
              </a>
              <!-- <a href="https://1msg.io/app.apk" target="_blank" rel="nofollow">-->
              <!--   <button type="button" class="btn btn-lg btn-outline-success custom-button">-->
              <!--     {$_('mobile_app.tab1.button2')}-->
              <!--   </button>-->
              <!-- </a>-->
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
  <img src="/assets/images/icons/bg-phone-right.svg" alt="bg-phone" class="bg-img"/>
</div>

<style>
  .btn-googleplay {
    width: 154px;
    height: 44px;
  }
</style>
