<script>
  import {eventTracking} from "../../../../helpers/utils";
  import {Input} from "sveltestrap";
  import {onDestroy, onMount} from "svelte";
  import {_} from "svelte-i18n";
  import {addToast} from "../../../../helpers/toast";
  import {getAutoReplies, patchUpdateAutoReplies} from "../../../../helpers/store";
  import CardWithToggle from "../../../../Components/CardWithToggle.svelte";
  import CreatingTemplate from "../CreatingTemplate.svelte";

  export let id;
  export let type_id;
  export let is_active = false;
  export let text = "";
  export let state = {};
  export let variables = [];
  export let chosenChannelLocal = '';

  let days_weeks = [
    {id: 0, label: 'auto_reply.card.outside_working.work_days.days.mon', checked: false},
    {id: 1, label: 'auto_reply.card.outside_working.work_days.days.tue', checked: false},
    {id: 2, label: 'auto_reply.card.outside_working.work_days.days.wed', checked: false},
    {id: 3, label: 'auto_reply.card.outside_working.work_days.days.thu', checked: false},
    {id: 4, label: 'auto_reply.card.outside_working.work_days.days.fri', checked: false},
    {id: 5, label: 'auto_reply.card.outside_working.work_days.days.sat', checked: false},
    {id: 6, label: 'auto_reply.card.outside_working.work_days.days.sun', checked: false}
  ];
  let outside_working = {};
  let loader = false;

  const lang = _.subscribe(() => {
    switch (localStorage.getItem('I18N_LANGUAGE')) {
      case "ru":
        days_weeks = days_weeks.sort((a, b) => a.id > b.id ? 1 : -1);
        break;
      case "en":
        days_weeks = days_weeks.sort((a, b) => a.id < b.id ? 1 : (a.id < 6 ? 1 : -1));
        break;
    }
  })

  onMount(() => {
    if (state.outside_working) {
      outside_working = {...state.outside_working, ...outside_working};
      days_weeks.forEach((day, i) => {
        if (outside_working[day.id]) {
          days_weeks[i].checked = true;
        }
      })
    }
  });

  onDestroy(() => {
    lang();
  });

  const changeOutsideWorking = (id, bool) => {
    if (bool) {
      outside_working[id] = {"start": "", "end": ""};
    } else {
      delete outside_working[id];
    }
  };

  const req = async (params) => {
    loader=true;
    let res  = await patchUpdateAutoReplies(id, params);

    if (!res || res.error || res.status === 'error') {
      loader=false;
      addToast({
        title: $_("error"),
        message: $_("something_went_wrong")
      });
    } else if (res.status === 'ok') {
      getAutoReplies(chosenChannelLocal).then(() => {
        loader=false;
        if (is_active) {
          eventTracking('autoreply_turned_on', {autoreplay_type: 'outside_hours'});
        }
        addToast({title: $_("auto_reply.req.success")});
      });
    }
  };

  const toggleIsActiveAutoReply = () => {
    req({is_active: !is_active});
  };

  const submit = (e) => {
    e.preventDefault();
    req({text, state: {outside_working}, type_id});
  };
</script>

<CardWithToggle title={$_("auto_reply.card.outside_working.title")} state={is_active} {loader} toggle={toggleIsActiveAutoReply}>
  <p class="description">{$_("auto_reply.card.outside_working.description")}</p>
  <form on:submit={submit}>
    <div class="custom-alert custom-alert-primary mb-3">
      <i class="mdi mdi-alert-circle-outline"></i>{$_(`auto_reply.card.outside_working.alert`)}
    </div>

    <div class="work-days">
      <div class="title">{$_('auto_reply.card.outside_working.work_days.title')}</div>
      <div class="days-weeks">
        {#each days_weeks as day}
          <div class="form-check">
            <input class="form-check-input"
              id="{day.id}-day"
              type="checkbox"
              bind:checked={day.checked}
              on:click={(e) => changeOutsideWorking(day.id, e.target.checked)}>
            <label class="form-check-label" for="{day.id}-day">
              {$_(day.label)}
            </label>
          </div>
        {/each}
      </div>
      {#if days_weeks.filter(day => day.checked).length}
        <div class="time-work">
          <div class="d-flex fw-500 font-size-13 mb-2 time-work__item">
            <div class="time-work__item-day"></div>
            <div class="time-work__item-time">{$_('auto_reply.card.outside_working.work_days.time_work.start')}</div>
            <div class="time-work__item-dash"></div>
            <div class="time-work__item-time">{$_('auto_reply.card.outside_working.work_days.time_work.end')}</div>
          </div>
          {#each days_weeks as day}
            {#if day.checked}
              <div class="d-flex align-items-center time-work__item">
                <div class="time-work__item-day fw-500">{$_(day.label)}</div>
                <div class="time-work__item-time">
                  <Input type="time" id="{day.id}-day-Start" bind:value={outside_working[day.id].start} required class="custom-input-time"/>
                </div>
                <div class="text-center font-size-16 time-work__item-dash">-</div>
                <div class="time-work__item-time">
                  <Input type="time" id="{day.id}-day-End" bind:value={outside_working[day.id].end} required class="custom-input-time"/>
                </div>
              </div>
            {/if}
          {/each}
        </div>
      {/if}
    </div>
    <CreatingTemplate {variables} text={text ? text : ''} required={true} updateState={(value) => text = value} />
    <div class="text-center">
      <button type="submit" class="btn btn-success custom-button-2">
        <span class="text-break">{$_("auto_reply.button_save")}</span>
      </button>
    </div>
  </form>
</CardWithToggle>

<style>
  .title {
    margin-bottom: 8px;
    font-weight: 500;
    color: #495057;
  }

  .description {
    margin-bottom: 8px;
    color: #75788B;
  }

  .work-days .days-weeks {
    display: inline-flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }

  .work-days .days-weeks .form-check:not(:last-child) {
    margin-right: 11px;
  }

  .work-days .time-work {
    margin-bottom: 24px;
  }

  .work-days .time-work__item:not(:last-child) {
    margin-bottom: 16px;
  }

  .work-days .time-work__item-day {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .work-days .time-work__item-time {
    flex: 0 0 auto;
    width: 43.12222%;
  }

  .work-days .time-work__item-dash {
    flex: 0 0 auto;
    width: 5.33333%;
  }
</style>