<script>
  import {onDestroy, onMount} from "svelte";
  import {currentPath} from "../helpers/store";
  import {navigate} from "svelte-routing";
  import {globalHistory} from "svelte-navigator";
  import {DEFAULT_ADMINISTRATOR_DASHBOARD, DEFAULT_OPERATOR_DASHBOARD} from "../common/constants";

  let unsub;
  onMount(() => {
    guardCheck();
  })

  onDestroy(() => {
    unsub&&unsub()
  })


  function navigateLocal(path) {
    navigate(path, {replace: true})
  }

  let startAddress = new URL(window.location.href);
  const guardCheck = () => {
    startAddress = new URL(window.location.href);
    if (localStorage.getItem("authUser") && !localStorage.getItem("isOperator")) {
      navigateLocal(DEFAULT_ADMINISTRATOR_DASHBOARD)
    } else if (localStorage.getItem("authUser") && localStorage.getItem("isOperator")) {
      navigateLocal(DEFAULT_OPERATOR_DASHBOARD)
    }
  }

</script>
<slot></slot>
