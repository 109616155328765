<script>
  import Time from "svelte-time";
  import {
    MESSAGE_CONTENT_TYPE_SYSTEM_INFO,
    MESSAGE_CONTENT_TYPE_SYSTEM_ERROR,
  } from '../../../../../../helpers/constants';
  import {_} from "svelte-i18n";

  export let message;

  let messageTime = message.ts_in_messenger * 1000;
  let type = '';
  let reg = /\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}/;

  if (message.content_type == MESSAGE_CONTENT_TYPE_SYSTEM_INFO) {
    type = 'info';
  } else if (message.content_type == MESSAGE_CONTENT_TYPE_SYSTEM_ERROR) {
    type = 'mistake';
  }
</script>

<div class="my-1 px-sm-4 d-flex justify-content-center">
  <div class="font-size-14 fw-400 d-inline-block SystemMessage {type}">
    {$_(message.text.replace(reg, ''))}
    {#if message.content_type == MESSAGE_CONTENT_TYPE_SYSTEM_INFO}
      <Time timestamp={messageTime} format="YYYY.MM.DD HH:mm"/>
    {/if}
    {#if message.text === 'Message failed to send because more than 24 hours have passed since the customer last replied to this number' ||
      message.text.includes('You can open 24-hours dialog window with /sendTemplate method to send files')}
      <div class="mt-1 pt-1 hint">
        {@html $_('chat_inbox.SystemMessage.1')} <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill="#586169"
            d="M19 5V7H15V5H19ZM9 5V11H5V5H9ZM19 13V19H15V13H19ZM9 17V19H5V17H9ZM21 3H13V9H21V3ZM11 3H3V13H11V3ZM21 11H13V21H21V11ZM11 15H3V21H11V15Z"/>
        </svg>
      </div>
    {/if}
  </div>
</div>

<style>
  .SystemMessage {
    white-space: pre-wrap;
    word-break: break-word;
    padding: 8px 12px;
    border-radius: 8px;
  }

  .SystemMessage.info {
    background-color: #ffeaad;
  }

  .SystemMessage.mistake {
    background-color: #ffa4a8;
  }

  .SystemMessage .hint {
    border-top: 1px solid rgba(73, 80, 87, 0.6) !important
  }
</style>
