<script>
  import {eventTracking} from "../../../helpers/utils";
  import {Container, Row, Col} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import ChooseTheChannel from "../../../Components/Modals/ChooseTheChannelModal.svelte";
  import {
    autoReplies,
    getAutoReplies,
    repliesVariables,
    getRepliesVariables,
    chosenChannel
  } from "../../../helpers/store";
  import {layout} from "../../../services/loaders";
  import Welcome from "./Type/Welcome.svelte";
  import Delayed from "./Type/Delayed.svelte";
  import OutsideWorking from "./Type/OutsideWorking.svelte";
  import SettingsGpt from "./Type/SettingsGpt.svelte";
  import NoChannelsWrapper from "../../../Components/NoChannelsWrapper.svelte";

  let arrAutoReplies = [];
  let arrRepliesVariables = [];

  let chosenChannelLocal = null;
  const unsub3 = chosenChannel.subscribe(async (value) => {
    if (value === null || value === "") {
      chosenChannelLocal = null;
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        layout(true);
        getAutoReplies(value).then(() => {
          getRepliesVariables(value).then(() =>{
            layout(false);
          });
        });
      }
      chosenChannelLocal = value;
    }
  });

  const unsubAutoReplies = autoReplies.subscribe((value) => {
    if (value && value.status === "ok") {
      if (!value.result.results.find(rep => rep.type_id === 4)) {
        value.result.results.push({type_id: 4, token: [''], text: ''})
      }
      arrAutoReplies = value.result.results;
    } else if (value && value.status === "error") {
      arrAutoReplies = [];
    }
  });

  const unsubRepliesVariables = repliesVariables.subscribe((value) => {
    if (value && value.status === "ok") {
      arrRepliesVariables = value.result.fields;
    } else if (value && value.status === "error") {
      arrRepliesVariables = [];
    }
  });

  onDestroy(() => {
    unsub3();
    unsubAutoReplies();
    unsubRepliesVariables();
  });

  eventTracking('autoreply_visited_page');
</script>

<NoChannelsWrapper option="2">
<div class="page-content px-0 px-sm-2">
  <Container fluid>
    <div class="header-title">{$_('auto_reply.title')}</div>
    <Row>
      {#each arrAutoReplies as autoReply}
        {#if autoReply.type_id === 1}
          <Col xl={6} xxl={4}>
            <Welcome {...autoReply} variables={arrRepliesVariables} {chosenChannelLocal} />
          </Col>
        {/if}
        {#if autoReply.type_id === 2}
          <Col xl={6} xxl={4}>
            <Delayed {...autoReply} variables={arrRepliesVariables} {chosenChannelLocal} />
          </Col>
        {/if}
        {#if autoReply.type_id === 3}
          <Col xl={6} xxl={4}>
            <OutsideWorking {...autoReply} variables={arrRepliesVariables} {chosenChannelLocal} />
          </Col>
        {/if}
        {#if autoReply.type_id === 4}
          <Col xl={6} xxl={4}>
            <SettingsGpt {...autoReply} {chosenChannelLocal} />
          </Col>
        {/if}
      {/each}
    </Row>
  </Container>
</div>
</NoChannelsWrapper>
<ChooseTheChannel/>
