<script>
  import {Button} from "sveltestrap";
  import {_} from "svelte-i18n";

  export let name;
  export let isOpenModalImportContact = () => {};
</script>

<div class="mb-4">
  <label for="segment_name" class="fw-600">
    {$_('segments.segment_name')}
  </label>
  <input type="text" bind:value={name} class="border-1 rounded-2 text-muted form-control mb-3" required/>
  <div class="d-flex align-items-center justify-content-between flex-wrap gap-3">
    <div class="d-flex align-items-center flex-wrap gap-3">
      <Button type="button" color="success" outline class="py-2 px-4" on:click={isOpenModalImportContact}>
        <span class="font-size-14">{$_('contacts.import_contact')}</span>
      </Button>
      <a href="/assets/files/contact-upload-1msg.xlsx" download class="link-offset-2">
        {$_('contacts.download_link')}
      </a>
    </div>
    <slot name="btnRight"/>
  </div>
</div>

<style>
  input {
    max-width: 430px;
  }

  a.link-offset-2 {
    text-decoration: underline !important;
    text-underline-offset: 0.125em!important;
  }
</style>