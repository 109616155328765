import {get, writable} from 'svelte/store';
import {BasicDAO} from "./BasicDAO";

/**
 * @typedef {Object} Tags
 * @property {number} id - The ID of the user tag.
 * @property {number} [shop_id] - The ID of the shop.
 * @property {string} name - The name of the user tag.
 */


/**
 *
 * @type {BasicDAO<Tags>}
 */
const TagsDAO = new BasicDAO(new writable([]), 'api/store/tags');
const tagsStore = TagsDAO.store;

export {
  TagsDAO,
  tagsStore
}
