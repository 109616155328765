<script>
  import {Col} from "sveltestrap";
  import {Link, navigate} from "svelte-routing";
  import {_} from "svelte-i18n";
  import ChatList from "./Components/ChatsList/index.svelte";
  import NoChannelsWrapper from "../../Components/NoChannelsWrapper.svelte";
  import ChooseTheChannel from "../../Components/Modals/ChooseTheChannelModal.svelte";
  import {onMount, onDestroy, tick} from "svelte";
  import {channels, chosenChannel, getOnetouchShops, showProjectSelectModal, user} from "../../helpers/store";
  import {getRequest, postRequest} from "../../helpers/utils";
  import {addToast} from "../../helpers/toast";
  import ErrorMessageWrapper from "./ErrorMessageWrapper.svelte";

  let channel = null, settings, serviceCoreWhatsappWebhook = false, changeSettingsLoader = false;

  onMount(() => navigate('/inbox'));

  const unsubChannels = channels.subscribe(async (channelsTmp) => {
    const check = async (data = null) => {
      if (channelsTmp?.length === 1 && (data === null || data?.length === 0) && channelsTmp[0]?.srv === 'trial' && !$user?.isOperator && !channelsTmp[0]?.sandbox_phone) {
        showProjectSelectModal.set(false);
        await tick();
        navigate('/trial', {replace: true});
      }
    }
    getOnetouchShops().then((data) => {
      check(data)
    }, err => {
      check()
    })
  });

  const unsubChosenChannel = chosenChannel.subscribe(async value => {
    serviceCoreWhatsappWebhook = false;
    if (value === null || value === "") {
      channel = null;
    } else {
      if (value.apiUrl) {
        let req = await getRequest(`${value.apiUrl}settings`, {token: value.token});
        if (req.webhookUrl == null) {
          req.webhookUrl = '';
        }
        if (typeof req.webhookUrl === 'string') {
          req.webhookUrl = [req.webhookUrl];
        }
        if (value?.appData?.bot_id) {
          serviceCoreWhatsappWebhook = !req.webhookUrl.find(item => item === `https://app.1msg.io/service/service_core/whatsapp/webhook/${value.appData.bot_id}`);
        }
        settings = req;
      }
      channel = value;
    }
  });

  const changeSettings = async () => {
    changeSettingsLoader = true;
    settings.webhookUrl = [`https://app.1msg.io/service/service_core/whatsapp/webhook/${channel.appData.bot_id}`, ...settings.webhookUrl];
    try {
      let result = await postRequest(`${channel.apiUrl}settings?token=${channel.token}`, settings);
      if (result && !result.error) {
        addToast({title: $_("saved")});
        serviceCoreWhatsappWebhook = false;
      } else {
        addToast({title: $_("error")});
      }
    } catch (e) {
      addToast({title: $_("error")});
    }
    changeSettingsLoader = false;
  };

  onDestroy(async () => {
    unsubChannels();
    unsubChosenChannel();
  });
</script>

<NoChannelsWrapper option="3" available={true}>
  <div class="ChatInbox {window.innerWidth > 300 ? '' : 'px-0'}">
    <div class="pe-0 container-fluid position-relative">
      <div class="row mb-2 mb-lg-4 pe-4 align-items-center Header">
        <Col class="font-size-20 fw-bold text-truncate">
          {$_('chat_inbox.title')}
        </Col>
        <Col>
          <ol class="breadcrumb m-0 p-0 justify-content-end">
            <li class="breadcrumb-item">
              <Link to="/inbox">1msg</Link>
            </li>
            <li class="breadcrumb-item active">
              {$_('chat_inbox.title')}
            </li>
          </ol>
        </Col>
      </div>
      {#if window.innerWidth > 300}
        <div class="grid">
          <ChatList/>
        </div>
      {:else}
        <div class="text-center font-size-14 text-muted py-3 px-4">
          {$_('chat_inbox.false_innerWidth')}
        </div>
      {/if}
      {#if $chosenChannel?.stopped}
        <ErrorMessageWrapper>
          {$_("chat_inbox.stopped_channel.title")} {$_(`chat_inbox.stopped_channel.${$chosenChannel.stoppedComment || 'default'}`)}
          {@html $_("chat_inbox.link_to_support")}
        </ErrorMessageWrapper>
      {/if}
      {#if !$chosenChannel?.stopped && serviceCoreWhatsappWebhook}
        <ErrorMessageWrapper>
          {$_('chat_inbox.warning_webhook')}
          <button class="text-decoration-underline border-0 p-0 bg-transparent text-white" on:click={changeSettings}>
            {$_('chat_inbox.install_webhook')}
            {#if changeSettingsLoader}
              <div role="status" class="ms-1 spinner-border-sm spinner-border"><span class="visually-hidden">Loading...</span></div>
            {/if}
          </button>
        </ErrorMessageWrapper>
      {/if}
    </div>
  </div>
</NoChannelsWrapper>
<ChooseTheChannel/>

<style>
  .ChatInbox {
    height: 100dvh;
    padding: calc(70px + 24px) 0 0 calc(24px / 2);
  }

  .ChatInbox .container-fluid {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ChatInbox .spinner-border-sm {
    width: 13px;
    height: 13px;
  }

  @media (min-width: 992px) {
    .ChatInbox .grid {
      display: grid;
      grid-template-columns: 370px auto;
      column-gap: 15px;
      height: calc(100% - 47px);
    }
  }

  @media (max-width: 767px) {
    .ChatInbox .Header {
      display: none;
    }

    .ChatInbox .container-fluid {
      padding-left: 0px;
    }
  }
</style>
