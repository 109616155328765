<script>
  import {_, locale} from "svelte-i18n";
  import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Row,
    Modal,
  } from "sveltestrap";
  import {postRequest, customNotifications} from "../helpers/utils";

  import {invites, user} from "../helpers/store";
  import {defaultConst} from "../common/constants";
  import {addToast} from "../helpers/toast";

  import {onDestroy, onMount} from "svelte";

  let userInvites = false;
  let showModal = false;
  let activeUser = {};
  let activeInvite;
  let lang;

  let allNotifications = [];

  const unsub1 = user.subscribe((value) => {
    activeUser = value;
    updateAllNotifications(activeUser);
  });

 /* const unsub2 = invites.subscribe((value) => {
    if (value) {
      userInvites = value.map((item) => {
        item.type = "invite";
      });
    }
    updateAllNotifications();
  });*/

  const unsub3 = locale.subscribe((value) => {
    if (value) {
      lang = value;
    }
  });

  function updateAllNotifications() {
    allNotifications = customNotifications(activeUser);
  //  allNotifications.concat(userInvites);
  }

  onDestroy(() => {
    unsub1();
   // unsub2();
    unsub3();
  })


  onMount(() => {
    if (localStorage.getItem("I18N_LANGUAGE")) {
      lang = localStorage.getItem("I18N_LANGUAGE");
    }
  })

  const openModal = (invite) => {
    if (invite) {
      activeInvite = invite;
      showModal = true;
    } else {
      activeInvite = {};
      showModal = false;
    }

  };

  const acceptInvite = async (invite) => {

    let result = await postRequest(
      `${defaultConst.functionsUrl}/acceptInvite`,
      {
        inviteId: activeInvite.id,
        id: activeUser.id,
      }
    );

    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
    } else {
      showModal = false;
      setTimeout(() => {
        addToast({
          title: $_("saved"),
          dismissible: true,
          timeout: 3_000,
        });
      }, 300);
    }
  };

  const declineInvite = async (invite) => {

    let result = await postRequest(
      `${defaultConst.functionsUrl}/declineInvite`,
      {
        inviteId: activeInvite.id,
        userToken: activeUser.userToken,
      }
    );

    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
    } else {
      showModal = false;
      setTimeout(() => {
        addToast({
          title: $_("saved"),
          dismissible: true,
          timeout: 3_000,
        });
      }, 300);
    }
  };
</script>

{#if allNotifications[lang]?.length}
  <Dropdown class="d-inline-block">
    <DropdownToggle class="header-item noti-icon headerbtn position-relative px-2 px-md-3" id="page-header-notifications-dropdown" tag="button" color="">
      <i class="bx bx-bell bx-tada"/>
      <span class="badge bg-danger rounded-pill">{allNotifications[lang].length}</span>
    </DropdownToggle>
    <DropdownMenu class="dropdown-menu-lg dropdown-menu-end p-0">
      <div class="p-3">
        <Row class="align-items-center">
          <div class="col">
            <h6 class="m-0">
              {$_("navbar.dropdown.invites_title", {values: {count: allNotifications[lang].length}})}
            </h6>
          </div>
        </Row>
      </div>
      <div data-simplebar style="max-height: 230px;">
        {#if (allNotifications[lang])}
          {#each allNotifications[lang] as notification}
            {#if (notification.type === 'invite')}
              <button type="button" on:click={() => openModal(notification)}
                class="text-reset notification-item d-block border-0 p-0 bg-transparent text-start active">
                <div class="d-flex">
                  <div class="avatar-xs flex-shrink-0 me-3">
                    <span class="avatar-title rounded-circle bg-primary text-white font-size-16">
                      {notification.senderName[0].toUpperCase()}
                    </span>
                  </div>
                  <div class="flex-1">
                    <h6 class="mt-0 mb-1" key="t-your-order">
                      {$_("navbar.dropdown.invite_anons", {values: {project: notification.projectName || "#" + notification.projectId}})}
                    </h6>
                    <div class="font-size-13 text-muted">
                      <p class="mb-1" key="t-grammer">
                        {$_("navbar.dropdown.invite_text", {values: {user: notification.senderName, project: notification.projectName || "#" + notification.projectId}})}
                      </p>
                    </div>
                  </div>
                </div>
              </button>
            {:else}
              <div class="d-flex p-2">
                <div class="avatar-xs flex-shrink-0 me-3">
                  <span class="avatar-title rounded-circle bg-primary text-white font-size-16">
                    {notification?.avatar || 'M'}
                  </span>
                </div>
                <div class="flex-1">
                  <h6 class="mt-0 mb-1" key="t-your-order">
                    {notification.title}
                  </h6>
                  <div class="font-size-13 text-muted">
                    <p class="mb-1" key="t-grammer">
                      {@html notification.text}
                    </p>
                  </div>
                </div>
              </div>
            {/if}
          {/each}
        {/if}
      </div>
    </DropdownMenu>
  </Dropdown>
  <Modal isOpen={showModal} toggle={openModal}>
    <div class="modal-header">
      <h5 class="modal-title mt-0" id="mySmallModalLabel">
        {$_("navbar.dropdown.invite_anons", {values: {project: activeInvite.projectName || "#" + activeInvite.projectId}})}
      </h5>
      <button on:click={openModal} type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{$_("navbar.dropdown.invite_text", {values: {user: activeInvite.senderName, project: activeInvite.projectName || "#" + activeInvite.projectId}})}</p>
      <p>{$_("navbar.dropdown.invite_join")}</p>
      <div class="mb-3 ">
        <button on:click={() => acceptInvite()} class="btn btn-primary btn-sm">{$_("yes")}</button>
        <button on:click={() => declineInvite()} class="btn btn-secondary btn-sm">{$_("no")}</button>
      </div>
    </div>
  </Modal>
{:else}
  <Dropdown class="d-inline-block">
    <DropdownToggle class="header-item noti-icon headerbtn position-relative px-2 px-md-3" id="page-header-notifications-dropdown" tag="button"  color="">
      <i class="bx bx-bell"/>
    </DropdownToggle>
    <DropdownMenu class="dropdown-menu-lg dropdown-menu-end p-0">
      <div class="p-3">
        <Row class="align-items-center">
          <div class="col">
            <h6 class="m-0">
              {$_("navbar.dropdown.no_invites_title")}
            </h6>
          </div>
        </Row>
      </div>
    </DropdownMenu>
  </Dropdown>
{/if}
