<script>
  import {Input, Card, CardBody, InputGroup, Button} from "sveltestrap";
  import {slide} from 'svelte/transition'
  import {user} from "../../helpers/store";
  import {_, locale} from "svelte-i18n";
  import {onMount, onDestroy} from "svelte";
  import {addToast} from "../../helpers/toast";
  import {postRequest} from "../../helpers/utils";
  import {defaultConst} from "../../common/constants";
  import {toolbar} from "../../services/loaders";
  import BillingDetails from "./BillingDetails.svelte";

  export let balance;
  export let modalBillingDetails;

  let properties = {};
  let state = 'show';
  let saving = false;
  let activeUser = false;
  let lang;

  const unsub = user.subscribe((value) => {
    if(!activeUser)  activeUser = value;
  });

  const startEdit = () => {
    properties = {
      name: balance?.name,
      description: balance?.description,
      phone: balance?.phone
    }
    state = 'edit';
  };

  const cancelEdit = () => {
    if (saving) return;
    properties = {
      name: balance?.name,
      description: balance?.description,
      phone: balance?.phone
    }
    state = 'show';
  };

  const changeProp = async () => {
    if (saving) return;
    saving = true;
    toolbar(saving)
    const params = {
      userToken: activeUser.userToken,
      uid: balance.ownerId,
      data: properties,
    };

    let result;
    try {
      result = await postRequest(`${defaultConst.paymentUrl}/update-balance-info`, params);
    } catch (e) {}

    saving = false;
    toolbar(saving)
    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
    } else {
      addToast({title: $_("saved")});
      cancelEdit();
    }
  };

  const unsub2 = locale.subscribe((value) => {
    if (value) lang = value;
  });

  onDestroy(() => {
    unsub();
    unsub2();
  });

  onMount(() => {
    if (localStorage.getItem("I18N_LANGUAGE")) {
      lang = localStorage.getItem("I18N_LANGUAGE");
    }
    cancelEdit();
  });
</script>

{#if lang !== 'ru'}
  <Card>
    <CardBody class="p-3">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="font-size-18 fw-600">
          {$_("billing.billing_info.title")}
        </div>
        <button type="button" class="border-0 p-0 bg-transparent color-inherit" on:click={startEdit}>
          <i class="bx bx-edit-alt font-size-20"/>
        </button>
      </div>
     <!-- <div>
        <div class="pl-0 my-2">
          <span class="fw-500 fg-bs-gray-700 font-size-14">{$_("billing.billing_info.name")} :</span>
          {#if (state == 'show')}
            <span class="fg-bs-gray-700-light">{balance?.name || "-"}</span>
          {/if}
        </div>
        {#if state == 'edit'}
          <div transition:slide>
            <InputGroup>
              <Input
                bind:value={properties.name}
                type="text"
                class="form-control"/>
            </InputGroup>
          </div>
        {/if}
      </div>
      <div>
        <div class="pl-0 my-2">
          <span class="fw-500 fg-bs-gray-700 font-size-14">{$_("billing.billing_info.phone")} :</span>
          {#if state == 'show'}
            <span class="fg-bs-gray-700-light">{balance?.phone || "-"}</span>
          {/if}
        </div>
        {#if state == 'edit'}
          <div transition:slide>
            <InputGroup>
              <Input
                bind:value={properties.phone}
                type="text"
                class="form-control"/>
            </InputGroup>
          </div>
        {/if}
      </div>-->
      <div>
        <div class="pl-0 my-2">
          <span class="fw-500 fg-bs-gray-700 font-size-14">{$_("billing.billing_info.description")} :</span>
          {#if (state == 'show')}
            <span class="fg-bs-gray-700-light">{balance?.description || "-"}</span>
          {/if}
        </div>
        {#if (state == 'edit')}
          <div transition:slide>
            <InputGroup>
              <Input bind:value={properties.description} type="text" class="form-control"/>
            </InputGroup>
          </div>
        {/if}
      </div>
      {#if (state == 'edit')}
        <div class="mt-3">
          <Button color="success" on:click={changeProp} class="btn-sm me-2 px-3">
            {#if saving}
              <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
            {/if}
            {$_("save")}
          </Button>
          <Button color="secondary" class="btn-sm px-3" on:click={cancelEdit}>
            {$_("cancel")}
          </Button>
        </div>
      {/if}
    </CardBody>
  </Card>
{:else if lang === 'ru'}
  <BillingDetails bind:modalBillingDetails/>
{/if}

<style>
  .color-inherit {
    color: inherit;
  }
</style>