// Define different message directions
export const MESSAGE_DIRECTION_RECEIVED = 1;
export const MESSAGE_DIRECTION_SENT = 2;
export const MESSAGE_DIRECTION_SYSTEM = 3;

// Define different message content types
export const MESSAGE_CONTENT_TYPE_LOADING = -1;
export const MESSAGE_CONTENT_TYPE_TEXT = 1;
export const MESSAGE_CONTENT_TYPE_IMAGE = 2;
export const MESSAGE_CONTENT_TYPE_CONTACT = 3;
export const MESSAGE_CONTENT_TYPE_VOICE = 4;
export const MESSAGE_CONTENT_TYPE_REMOVE = 5;
export const MESSAGE_CONTENT_TYPE_COMMAND = 6;
export const MESSAGE_CONTENT_TYPE_SYSTEM_INFO = 7;
export const MESSAGE_CONTENT_TYPE_FILE = 8;
export const MESSAGE_CONTENT_TYPE_INLINE = 9;
export const MESSAGE_CONTENT_TYPE_SYSTEM_ERROR = 10;
export const MESSAGE_CONTENT_TYPE_VIDEO = 11;
export const MESSAGE_CONTENT_TYPE_LOCATION = 12;
export const MESSAGE_CONTENT_TYPE_PRODUCT = 13;
export const MESSAGE_CONTENT_TYPE_FILE_AS_TEMPLATE = 14;
export const MESSAGE_CONTENT_TYPE_NOTE = 19;
export const MESSAGE_CONTENT_TYPE_ORDER = 20;
export const MESSAGE_CONTENT_TYPE_INTERACTIVE_BUTTON = 21
export const MESSAGE_CONTENT_TYPE_INTERACTIVE_LIST = 22
export const MESSAGE_CONTENT_TYPE_INTERACTIVE_LOCATION_REQUEST_MESSAGE = 23
export const MESSAGE_CONTENT_TYPE_INTERACTIVE_CATALOG_MESSAGE = 24
export const MESSAGE_CONTENT_TYPE_INTERACTIVE_PRODUCT = 25
export const MESSAGE_CONTENT_TYPE_INTERACTIVE_PRODUCT_LIST = 26
export const MESSAGE_CONTENT_TYPE_CATALOG = 27

// Define different message statuses
export const MESSAGE_STATUS_NEW = 1;
export const MESSAGE_STATUS_SENT = 3;
export const MESSAGE_STATUS_FAILED = 5;
export const MESSAGE_STATUS_DELIVERED = 10;
export const MESSAGE_STATUS_READ = 13;
export const MESSAGE_STATUS_DELETED = 15;
export const MESSAGE_STATUS_SCHEDULED = 16;
