<script>
  import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
  } from "sveltestrap";
  import {_} from "svelte-i18n";
  import {trialBar} from "../../helpers/store.js";

</script>
<div class="page-content">

  <div class="WhatsappLinkGenerator mx-auto d-flex flex-column justify-content-center  CustomMH100">
    <Container class="px-0 px-sm-2" fluid>
      <Card class="my-4">
        <CardBody>
          <div class="fw-600 font-size-20 mb-3">
            {$_('meta_documentation.title')}
          </div>
          <Row>
            <Col class="pe-lg-4" lg={6}>
              <div>
                {@html $_('meta_documentation.text')}

              </div>

              {#if $trialBar.show}
                <div class="text-white card bg-danger  w-100 p-2 m-2 mt-4 ms-0 card font-size-18 "><span><i
                    class="mdi ms-2 mdi-block-helper me-3"></i>{$_('meta_documentation.note')}</span></div>
              {/if}
            </Col>

            <div
                class="col-lg-6 bg-soft-green p-5 h-100 position-absolute top-0 end-0 d-none d-lg-flex justify-content-center rounded-end">
              <img alt="girl sitting at laptop" class="object-fit-contain"
                   src="/assets/images/companyInfo.svg"/>
            </div>
          </Row>
        </CardBody>
      </Card>
    </Container>
  </div>


</div>
<style>


    .WhatsappLinkGenerator {
        max-width: 1040px;
    }

    .WhatsappLinkGenerator .bg-soft-green {
        background-color: rgb(242, 247, 199);
    }
</style>
