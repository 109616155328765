<script>
  import {eventTracking} from "../../../../helpers/utils";
  import {Input} from "sveltestrap";
  import {onMount} from "svelte";
  import {_} from "svelte-i18n";
  import {addToast} from "../../../../helpers/toast";
  import {getAutoReplies, patchUpdateAutoReplies} from "../../../../helpers/store";
  import CardWithToggle from "../../../../Components/CardWithToggle.svelte";
  import CreatingTemplate from "../CreatingTemplate.svelte";

  export let id;
  export let type_id;
  export let is_active = false;
  export let text = "";
  export let state = {};
  export let variables = [];
  export let chosenChannelLocal = '';

  let is_repeat = false;
  let loader = false;

  onMount(() => {
    if (state.welcome) {
      is_repeat = state.welcome.is_repeat;
    }
  });

  const req = async (params) => {
    loader=true;
    let res  = await patchUpdateAutoReplies(id, params);

    if (!res || res.error || res.status === 'error') {
      loader=false;
      addToast({
        title: $_("error"),
        message: $_("something_went_wrong")
      });
    } else if (res.status === 'ok') {
      getAutoReplies(chosenChannelLocal).then(() => {
        loader=false;
        if (is_active) {
          eventTracking('autoreply_turned_on', {autoreplay_type: 'welcome_message'});
        }
        addToast({title: $_("auto_reply.req.success")});
      });
    }
  };

  const toggleIsActiveAutoReply = () => {
    req({is_active: !is_active});
  };

  const submit = (e) => {
    e.preventDefault();
    req({text, state: {welcome: {is_repeat}}, type_id});
  };
</script>

<CardWithToggle title={$_("auto_reply.card.welcome.title")} state={is_active} {loader} toggle={toggleIsActiveAutoReply}>
  <p class="description">{$_("auto_reply.card.welcome.description")}</p>
  <form on:submit={submit}>
    <Input
      type="checkbox"
      bind:checked={is_repeat}
      label={$_(`auto_reply.card.welcome.is_repeat`)}
      class="my-4"
    />
    <CreatingTemplate {variables} text={text ? text : ''} required={true} updateState={(value) => text = value} />
    <div class="text-center">
      <button type="submit" class="btn btn-success custom-button-2">
        <span class="text-break">{$_("auto_reply.button_save")}</span>
      </button>
    </div>
  </form>
</CardWithToggle>

<style>
  .description {
    margin-bottom: 8px;
    color: #75788B;
  }
</style>