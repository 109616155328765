<script>
  import {eventTracking} from "../../../../helpers/utils";
  import {Col, Input} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {addToast} from "../../../../helpers/toast";
  import {getAutoReplies, patchUpdateAutoReplies} from "../../../../helpers/store";
  import CardWithToggle from "../../../../Components/CardWithToggle.svelte";
  import CreatingTemplate from "../CreatingTemplate.svelte";
  import {user} from "../../../../helpers/store";

  export let id;
  export let type_id;
  export let is_active = false;
  export let text = "";
  export let state = {};
  export let variables = [];
  export let chosenChannelLocal = '';

  const convertMinutesToHoursAndMinutes = (min) => {
    let h = min / 60 ^ 0;
    let m = min % 60;
    return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}`;
  };

  let delay = state.delayed ? state.delayed.delay : 5;
  let delay2 = delay;
  let loader = false;

  const inputDelay = () => {
    if (delay2 < 1) {
      delay = 1;
    } else if (delay2 <= 1440) {
      delay = delay2;
    } else if (delay2 > 1440) {
      delay2 = delay;
    }
  };

  $: (delay2 || !delay2) && inputDelay();

  const req = async (params) => {
    loader=true;
    let res  = await patchUpdateAutoReplies(id, params);

    if (!res || res.error || res.status === 'error') {
      loader=false;
      addToast({
        title: $_("error"),
        message: $_("something_went_wrong")
      });
    } else if (res.status === 'ok') {
      getAutoReplies(chosenChannelLocal).then(() => {
        loader=false;
        if (is_active) {
          eventTracking('autoreply_turned_on', {autoreplay_type: 'delayed_message'});
        }
        addToast({title: $_("auto_reply.req.success")});
      });
    }
  };

  const toggleIsActiveAutoReply = () => {
    if ($user?.generalCompanyType === 1 && $user.userHintsHintBusiness_4 === false) {
      $user.userHintsHintBusiness_4 = true;
    }
    req({is_active: !is_active});
  };

  const submit = (e) => {
    e.preventDefault();
    req({text, state: {delayed: {delay: convertMinutesToHoursAndMinutes(delay)}}, type_id});
  };
</script>

<CardWithToggle title={$_("auto_reply.card.delayed.title")} state={is_active} {loader} toggle={toggleIsActiveAutoReply}>
  <p class="description">{$_("auto_reply.card.delayed.description")}</p>
  <form on:submit={submit}>
    <Col sm={6} class="my-4">
      <div class="title">
        {$_('auto_reply.card.delayed.delay_minutes_setting', {values:{time: convertMinutesToHoursAndMinutes(delay)}})}
      </div>
      <Input type="number" bind:value={delay2} required/>
    </Col>
    <CreatingTemplate {variables} text={text ? text : ''} required={true} updateState={(value) => text = value} />
    <div class="text-center">
      <button type="submit" class="btn btn-success custom-button-2">
        <span class="text-break">{$_("auto_reply.button_save")}</span>
      </button>
    </div>
  </form>
</CardWithToggle>

<style>
  .title {
    margin-bottom: 8px;
    font-weight: 500;
    color: #495057;
  }

  .description {
    margin-bottom: 8px;
    color: #75788B;
  }
</style>
