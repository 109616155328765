<script>

  import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'sveltestrap';

  import {setupI18n} from '../services/i18n';

  import languages from '../common/data/languages';

  export let selectedLang = localStorage.getItem("I18N_LANGUAGE") || "en";

  let lngImg = languages.find(item => selectedLang === item.value)?.['flag'];

  let isOpen = false;

  function handleLocaleChange(lang) {
    setupI18n({withLocale: lang});
    localStorage.setItem("I18N_LANGUAGE", lang);
    selectedLang = lang;
    lngImg = languages.find(item => selectedLang === item.value)?.['flag'];
  }

</script>

<Dropdown {isOpen} toggle={() => (isOpen = !isOpen)} class="d-inline-block">
  <DropdownToggle class="header-item headerbtn lang-btn ms-2 ms-md-3" tag="button" color="white">
    <img src={lngImg} alt="Skote" height="16" class="d-block"/>
    <i class="bx bx-chevron-down d-none d-md-inline-block"/>
  </DropdownToggle>
  <DropdownMenu class="language-switch dropdown-menu-end">
    {#each languages as language}
      <DropdownItem
          key={language.value}
          on:click={() => handleLocaleChange(language.value)}
          class={`notify-item language ${selectedLang === language.value ? 'active' : 'none'}`}
      >
        <img src={language.flag} alt="Skote" class="me-2" height="12"/>
        <span class="align-middle">
					{language.label}
				</span>
      </DropdownItem>
    {/each}
  </DropdownMenu>
</Dropdown>