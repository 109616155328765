<script>
  import {Col, Container, Row, Label, Input, Button} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onMount} from "svelte";
  import Spinner from "../../../Components/Spinners/SpinnerComponent.svelte";
  import get from 'lodash/get';
  import {appClient, loginWithFallback, user} from "../../../helpers/store";
  import {auth} from "../../../helpers/firebase";
  import {handleLogin, promisifyStore} from "../../../helpers/utils";

  let isPasswordRestore;
  let isInviteAccept;

  let passToken;
  let inviteToken;

  let isInviteLoading = false;
  let isPasswordSending = false;
  let isPasswordResetSuccess = false;
  let isPasswordTokenSending = false;

  let errorsPassword = {};
  let errorsAcceptingInvite = {};

  let email = '';
  let password = '';
  let passwordRepeat = '';


  const tokenKey = 'token';
  const inviteTokenKey = 'inviteToken';
  const emailKey = 'email';


  const acceptInvitation = (token) => {
    isInviteLoading = true;
    appClient.acceptInvite(token).then(data => {
      isInviteLoading = false;
      if (data?.status !== 'ok') {
        errorsAcceptingInvite = data?.error ?? data
        if (typeof errorsAcceptingInvite === "string") {
          errorsAcceptingInvite = {common: [errorsAcceptingInvite]};
        }
      }

    }, err => {
      isInviteLoading = false;
      errorsAcceptingInvite = err
    })
  }
  const sendAuthToken = (token) => {
    isPasswordTokenSending = true;
  }
  const handleLoginFlow = (data) => {
    isPasswordSending = false;
    handleLogin(false, null, null, data)
  }
  const tryLoggingInWithNewData = () => {
    loginWithFallback(email, password).then(({result}) => {
      if (result?.token) {
        auth.signInWithCustomToken(result?.token).then(() => {
          promisifyStore(user, user => !!user?.id && !!user?.email).then(handleLoginFlow, handleLoginFlow)
        })
      } else {
        isPasswordSending = false;
        alert("Can't automatically login. Please login manually with new password.")
      }
    }, err => {
      isPasswordSending = false;
      alert(err.message)
    })
  }

  const setPassword = () => {
    if (password === passwordRepeat && !isPasswordSending) {
      isPasswordSending = true;
      appClient.changePassword(password, passToken).then(data => {
        if (data?.status !== 'ok') {
          isPasswordSending = false;
          errorsPassword = data?.errors ?? data
        } else {
          //если это регистрация и мы не авторизованы тогда логиним юзера в систему
          if (isInviteAccept && isPasswordRestore && !localStorage.getItem("authUser")) {
            tryLoggingInWithNewData()
          } else {
            isPasswordResetSuccess = true;
            isPasswordSending = false;
          }
        }
      }, err => {
        isPasswordSending = false;
        errorsPassword = err
      })
    } else {
      errorsPassword = {common: ["errors.do_not_match"]};

    }
  }


  onMount(() => {
    const urlParams = new URLSearchParams(window.location.search);

    isPasswordRestore = urlParams.has(tokenKey)
    if (isPasswordRestore) {
      passToken = urlParams.get(tokenKey)
      sendAuthToken(passToken)
    }
    isInviteAccept = urlParams.has(inviteTokenKey)
    if (isInviteAccept) {
      inviteToken = urlParams.get(inviteTokenKey)
      acceptInvitation(inviteToken)
    }
    email = urlParams.has(emailKey) ? urlParams.get(emailKey) : ''
  })


</script>

<form  on:submit|preventDefault={setPassword}  class="form-horizontal">
  {#if isInviteAccept}

    {#if isInviteLoading}
      <Spinner size="sm"/>
      {$_('mobile_app.onboarding.accepting_invitation')}
    {:else if (errorsAcceptingInvite?.common || errorsAcceptingInvite?.jwt_token)}
      <div class="alert alert-warning text-center mb-4" role="alert">
        {$_('mobile_app.onboarding.accepting_invitation')}
        {$_(errorsAcceptingInvite?.common?.[0]) ?? ''}
        {$_(errorsAcceptingInvite?.jwt_token?.[0]) ?? ''}
      </div>
    {:else}
      <div class="alert alert-success text-center mb-4" role="alert">
        {$_('mobile_app.onboarding.successfully_accepted_invitation')}
      </div>
    {/if}

  {/if}

  {#if isPasswordRestore}
    <div class="mb-3">

        {#if isInviteAccept}
          <div>
            {$_('mobile_app.onboarding.create_new_password_1')}
            <b>{email}</b>{$_('mobile_app.onboarding.create_new_password_2')}
          </div>
        {:else }
          <div>{@html $_('mobile_app.onboarding.recover_password')}</div>
        {/if}
        <Label class="custom-register-label mt-3" for="password">
          {$_('auth.enter_password_text')}
        </Label>
        <Input
            type="password"
            class="form-control"
            id="password"
            placeholder={$_('auth.enter_password_placeholder')}
            bind:value={password}
        />
        <Label class="custom-register-label  mt-2" for="repeatPassword">
          {$_('auth.enter_password_again_text')}
        </Label>
        <Input
            type="password"
            class="form-control"
            id="repeatPassword"
            placeholder={$_('auth.enter_password_again_placeholder')}
            bind:value={passwordRepeat}
        />


    </div>
    {#if (errorsPassword?.common || errorsPassword?.token || errorsPassword?.password)}
      <div class="alert alert-warning text-center mb-4" role="alert">
        {$_(errorsPassword?.common?.[0]) ?? ''}
        {$_(errorsPassword?.token?.[0]) ?? ''}
        {$_(errorsPassword?.password?.[0]) ?? ''}
      </div>
    {/if}
    {#if isPasswordResetSuccess}
      <div class="alert alert-success text-center mb-4" role="alert">
        {$_('mobile_app.onboarding.successfully_reset_password')}
      </div>
    {/if}
    <div class="text-end">
      <Button type="submit" class="btn btn-success w-md waves-effect waves-light btn-lg"
              disabled={isPasswordSending||!password||!passwordRepeat}
              on:click={setPassword}>
        {isInviteAccept ? $_('auth.sign_up') : $_('auth.reset_btn')}
        {#if isPasswordSending}
          <Spinner class="ssm"/>
        {/if}
      </Button>
    </div>
  {/if}

</form>

<style>
    :global(.custom-register-label) {
        opacity: 0.5;
        font-weight: 500;
    }
</style>
