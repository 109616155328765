<script>
  import {eventTracking} from "../../../../helpers/utils";
  import {Modal, ModalHeader, ModalBody, Label, Input, Button, Spinner} from "sveltestrap";
  import {appClient, chosenChannel, toggleTrialRestrictionsModal} from "../../../../helpers/store";
  import {onDestroy} from "svelte";
  import {addToast} from "../../../../helpers/toast";
  import {_} from "svelte-i18n";
  import {chosenBotUserId, setChosenBotUserId} from "../../../../helpers/onetouch/BotUsersDAO";
  import {VirtualScroll} from "svelte-virtual-scroll-list";
  import ListItem from "./elements/ListItem.svelte";
  import ListItemSkeleton from "./elements/ListItemSkeleton.svelte";
  import SpinnerComponent from "../../../../Components/Spinners/SpinnerComponent.svelte";
  import { navigate} from "svelte-routing";

  export let chatsData;
  export let filter;
  export let chosenChat;
  export let blockedChatsData;
  export let operatorIdForFilter;
  export let unreadMessagesFilter;
  export let changeChosenChat;
  export let isSearchDisabled;
  /**
   * @type {boolean}
   */
  export let initialChatLoad;
  export let activeTab;
  export let activeOperators;

  let chosenChannelLocal = null;

  let isOpen = false;
  let loading = false;
  let vs = [];
  let filteredChatsNotAssigned = [];
  let filteredChatsMyOpen = [];
  let filteredChatsResolved = [];
  let keyLastChat = 0;

  let phone_contact = "", name_contact = "", newChatId = null, timeoutChat;

  $: filteredChatsNotAssigned.length && newChatId && redirectToChat();
  $: if(unreadMessagesFilter) {
    chatsData && operatorIdForFilter !== undefined && processChatTabs();
  } else {
    chatsData && operatorIdForFilter !== undefined && processChatTabs();
  };
  $: $chosenBotUserId && vs.length && findAndSetChat();
  $: if(filter) {
    const arr = [filteredChatsNotAssigned, filteredChatsMyOpen, filteredChatsResolved][activeTab];
    for (const item of arr) {
      if (item.message_id) break;
      keyLastChat = item.uniqueKey
    }
  };

  const unsub3 = chosenChannel.subscribe(async (value) => {
    if (value === null || value === "") {
      chosenChannelLocal = null;
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        chosenChannelLocal = value
      }
    }
  });

  onDestroy(() => {
    unsub3()
  });

  const sendAddNewContact = async () => {
    loading = true;
    const name = name_contact ? {name: name_contact.replace(/\s+/g, ' ').trim()} : {};
    await appClient.postAddNewContact({
      phone: phone_contact.replace(/[^+\d]/g, ''),
      ...name
    }, chosenChannelLocal).then(data => {
      if (data.errors || data.phone) {
        loading = false;
        addToast({title: $_("error"), message: data.errors || data.phone});
        return
      }
      newChatId = data.chat_id;
      timeoutChat = setTimeout(() => {
        newChatId = null;
        addToast({title: $_("chat_inbox.ChatsList.modal_adding_new_contact.redirect_mistake")});
        isOpen = false;
        loading = false
      }, 20000)
      eventTracking('inbox_triggered_action_chat', {action_type: 'add_new_contact'});
    }, err => {
      console.error(err);
      loading = false;
      addToast({title: $_("error"), message: $_("something_went_wrong")})
    })
  };

  function handleKeydown(event) {
    if (event.code === 'Escape') {
      changeChosenChat(null);
      chatsData && operatorIdForFilter !== undefined && processChatTabs();
    }
  }

  function redirectToChat() {
    const chatFindIndex = filteredChatsNotAssigned.findIndex(item => item.id === newChatId);
    if (chatFindIndex !== -1) {
      activeTab = 0;
      changeChosenChat(filteredChatsNotAssigned[chatFindIndex]);
      setTimeout(() => vs[0].scrollToIndex(0))
      clearTimeout(timeoutChat);
      newChatId = null;
      addToast({title: $_("chat_inbox.ChatsList.modal_adding_new_contact.new_user_added")});
      isOpen = false;
      loading = false;
    }
  }

  function processChatTabs() {
    const _filteredChatsNotAssigned = [];
    const _filteredChatsMyOpen = [];
    const _filteredChatsResolved = [];
    const forNotAssigned = chat => chat.assigned_to === null && chat.is_thread_open === true
    const forMyOpen = operatorIdForFilter ? chat => chat.assigned_to === operatorIdForFilter && chat.is_thread_open === true : chat => chat.assigned_to !== null && chat.is_thread_open === true
    const forResolved = chat => chat.is_thread_open === false
    for (let i = 0; i < chatsData.length; i++) {
      const item = chatsData[i]
      if (forNotAssigned(item)) {
        _filteredChatsNotAssigned.push(item)
      } else if (forMyOpen(item)) {
        _filteredChatsMyOpen.push(item)
      } else if (forResolved(item)) {
        _filteredChatsResolved.push(item)
      }
    }

    if (unreadMessagesFilter) {
      filteredChatsNotAssigned = _filteredChatsNotAssigned.filter(chat => (!chat.message_id && chat.last_message_time > chat.last_read_time) || $chosenChat.id === chat.id);
      filteredChatsMyOpen = _filteredChatsMyOpen.filter(chat => (!chat.message_id && chat.last_message_time > chat.last_read_time) || $chosenChat.id === chat.id);
      filteredChatsResolved = _filteredChatsResolved.filter(chat => (!chat.message_id && chat.last_message_time > chat.last_read_time) || $chosenChat.id === chat.id);
    } else {
      filteredChatsNotAssigned = _filteredChatsNotAssigned;
      filteredChatsMyOpen = _filteredChatsMyOpen
      filteredChatsResolved = _filteredChatsResolved;
    }

    if (activeTab === undefined || activeTab === 0) {
      if (filteredChatsNotAssigned.length) {
        activeTab = 0;
      } else if (filteredChatsMyOpen.length) {
        activeTab = 1;
      } else if (filteredChatsResolved.length) {
        activeTab = 2;
      } else {
        activeTab = 0;
      }
    }
  }

  function findAndSetChat() {
    const findSome = some => some.findIndex(item => item.bot_user_id === $chosenBotUserId)
    let chatFindIndex = findSome(filteredChatsNotAssigned);
    if (chatFindIndex === -1) {
      chatFindIndex = findSome(filteredChatsMyOpen)
      if (chatFindIndex === -1) {
        chatFindIndex =  findSome(filteredChatsResolved)
        activeTab = 2;
      }else{
        activeTab = 1;
      }
    }else{
      activeTab = 0;
    }
    changeChosenChat(chatsData.find(item => item.bot_user_id === $chosenBotUserId));
    setTimeout(() => vs[activeTab].scrollToIndex(chatFindIndex));
    setChosenBotUserId(null);
  }
</script>
<svelte:window on:keydown={handleKeydown}/>

<div class="position-relative List">
  <ul class="nav nav-pills position-relative overflow-auto flex-nowrap">
    <li class="nav-item">
      <a class="nav-link bg-transparent {activeTab === 0 ? 'active': ''}" href="#not-assigned"
        on:click|preventDefault={() => activeTab = 0}
        title="{$_('chat_inbox.Tab.not_assigned')} ({filteredChatsNotAssigned.length})">
        {$_('chat_inbox.Tab.not_assigned')} ({filteredChatsNotAssigned.length})
      </a>
    </li>
    {#if window.innerWidth > 768}
      <li class="nav-item">
        <a class="nav-link bg-transparent {activeTab === 1 ? 'active': ''}" href="#assigned"
          on:click|preventDefault={() => activeTab = 1}
          title="{$_(operatorIdForFilter === 0 ? 'chat_inbox.Tab.all_open' : 'chat_inbox.Tab.my_open')} ({filteredChatsMyOpen.length})">
          {$_(operatorIdForFilter === 0 ? 'chat_inbox.Tab.all_open' : 'chat_inbox.Tab.my_open')}
          ({filteredChatsMyOpen.length})
        </a>
      </li>
    {:else}
      <li class="nav-item">
        <a class="nav-link bg-transparent {activeTab === 1 ? 'active': ''}" href="#assigned"
          on:click|preventDefault={() => activeTab = 1}>
          {#if operatorIdForFilter === 0}
            {$_('chat_inbox.Tab.all_open')}
          {:else if operatorIdForFilter === appClient.currentUserOnetouch?.id}
            {$_('chat_inbox.Tab.my_open')}
          {:else}
            {$activeOperators.find(({profile}) => operatorIdForFilter === profile?.id)?.profile.user.username}
          {/if}
          ({filteredChatsMyOpen.length})
        </a>
      </li>
    {/if}
    <li class="nav-item">
      <a class="nav-link bg-transparent {activeTab === 2 ? 'active': ''}" href="#resolved"
        on:click|preventDefault={() => activeTab = 2}
        title="{$_('chat_inbox.Tab.resolved')} ({filteredChatsResolved.length})">
        {$_('chat_inbox.Tab.resolved')} ({filteredChatsResolved.length})
      </a>
    </li>
  </ul>

  {#each [filteredChatsNotAssigned, filteredChatsMyOpen, filteredChatsResolved] as listChats, i}
    <div class="ChatInbox-list {activeTab !== i ? 'd-none' : ''}">
      {#if listChats.length}
        <VirtualScroll key={!listChats[0]?.uniqueKey ? 'id' : 'uniqueKey'} let:data bind:this={vs[i]} estimateSize={72} data={listChats}>
          <ListItem
            {chosenChat}
            blockedChat={blockedChatsData.length && blockedChatsData.find(item => item.bot_user_id === data.bot_user_id)}
            chat={data}
            {filter}
            {changeChosenChat}
            line={filter && data.uniqueKey === keyLastChat}/>
        </VirtualScroll>
      {:else if initialChatLoad}
        <VirtualScroll key='id' estimateSize={72} data={[{id:1},{id:2},{id:3},{id:4},{id:5}]}>
          <ListItemSkeleton/>
        </VirtualScroll>
      {:else}
        <div>
          <div class="text-center font-size-14 text-muted py-3 px-4">
            {$_(`chat_inbox.Tab.no_chats.${i}`)}
          </div>
        </div>
      {/if}
    </div>
  {/each}

  {#if activeTab !== undefined}
    <div class="pointer rounded-circle d-flex align-items-center justify-content-center btn-add {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? ' start-0' : ''}" on:click={() => {
      if (chosenChannelLocal.srv === 'trial') {
        toggleTrialRestrictionsModal(true);
      } else {
        phone_contact="";
        name_contact="";
        isOpen=true
      }
    }}>
      <i class="bx bx-plus text-white"/>
    </div>
  {/if}

  {#if isSearchDisabled}
    <div class="d-flex align-items-center justify-content-center position-absolute top-0 start-0 h-100 w-100 WrapperSpinner">
      <SpinnerComponent/>
    </div>
  {/if}
</div>

{#if isOpen}
  <Modal isOpen={isOpen} fade={true} toggle={() => isOpen=false} size="md" centered>
    <ModalHeader toggle={() => isOpen=false} class="border-bottom-0 pb-0">
      <span class="font-size-20 fw-bold">
        {$_('chat_inbox.ChatsList.modal_adding_new_contact.adding_a_new_contact')}
      </span>
    </ModalHeader>
    <ModalBody>
      <form on:submit|preventDefault={sendAddNewContact}>
        <div class="form-group mb-3">
          <Label>{$_('chat_inbox.ChatsList.modal_adding_new_contact.phone_number')} *</Label>
          <Input type="text" bind:value={phone_contact} required
            placeholder={$_('chat_inbox.ChatsList.modal_adding_new_contact.phone')}/>
        </div>
        <div class="form-group mb-3">
          <Label>{$_('chat_inbox.ChatsList.modal_adding_new_contact.contact_name')}</Label>
          <Input type="text" bind:value={name_contact}
            placeholder={$_('chat_inbox.ChatsList.modal_adding_new_contact.name')}/>
        </div>
        <div class="d-flex flex-wrap gap-3">
          <Button type=submit color="success" class="py-2 px-3 d-flex align-items-center gap-2"
            disabled={phone_contact.length < 11 || loading}>
            {#if loading}
              <Spinner size="sm"/>
            {/if}
            <span class="font-size-14">{$_('chat_inbox.ChatsList.modal_adding_new_contact.send_in')}</span>
          </Button>
          <Button type="button" outline color="success" class="py-2 ps-3 pe-4 d-flex gap-2"
            on:click={()=>{isOpen=false;navigate('contacts')}}>
            <i class="bx bx-import font-size-18"/>
            <span class="font-size-14">{$_('contacts.import')}</span>
          </Button>
        </div>
      </form>
    </ModalBody>
  </Modal>
{/if}

<style>
  .List .nav::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 2px;
    background-color: #DBDBDD;
  }

  .List .nav-item {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .List .nav-link {
    padding: 6px 5px 12px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 16px;
    color: #495057;
    background-color: transparent;
    border-radius: 0;
    border-bottom: 2px solid #DBDBDD;
  }

  .List .nav-link.active {
    color: #23B16D;
    border-bottom: 2px solid #23B16D;
  }

  .List .btn-add {
    position: absolute;
    right: 3px;
    bottom: 17px;
    width: 50px;
    height: 50px;
    font-size: 28px;
    background-color: #0CA970;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .List .WrapperSpinner {
    background-color: #f6f6f6;
  }

  .List .nav::-webkit-scrollbar {
    height: 0px;
  }

  @media (max-width: 540px) {
    .List .nav-item {
      width: fit-content;
    }

    .List .nav-link {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
</style>
