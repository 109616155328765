<script>
  import UserHintsTypeWrapper from "./UserHintsTypeWrapper.svelte";
  import UserHintsStepComponent from "./UserHintsStepComponent.svelte";
  import {setContext} from "svelte";
  import {UserHintsHelper} from "./UserHintsHelper";
  import {channels, user, addChannelFirstTimeHelper} from "../../helpers/store";
  import {currentOpenHintStore} from "../../helpers/hints";
  import {_} from "svelte-i18n";
  import {segmentStore} from "../../helpers/onetouch/SegmentDAO";
  import {broadcastStore} from "../../helpers/onetouch/BroadcastDAO";
  import TipsBanner from "./TipsBanner.svelte";

  let show = false;

  //****************CHANGEABLE THING********************
  const hintName = "HintBusiness"; // should be unique
  //****************END CHANGEABLE THING********************


  /**
   * @type {UserHintsHelper} userHintsHelper
   */
  const userHintsHelper = new UserHintsHelper(hintName);
  setContext('UserHintsHelperContext', {
    UserHintsHelper: userHintsHelper
  });


  /******DEFINE WHEN COMPONENT SHOULD BE SHOWN********/
  $:if ($channels?.length && !$channels?.filter(channel => channel?.status !== 'trial' && channel?.srv !== 'trial').length) {
      show = true
  }
  /******END WHEN COMPONENT SHOULD BE SHOWN********/


  if ($user?.user_hints?.HintBusiness_4 === undefined) {
    $user.userHintsHintBusiness_4 = false;
  }

  if ($user?.user_hints?.HintBusiness_1 === undefined) {
    $user.userHintsHintBusiness_1 = false;
  }

  $:if ($currentOpenHintStore === hintName) { /// не трогать эту строку
    /******DEFINE WHEN STEPX IS READY********/


    if ($channels?.length && $channels?.find(channel => channel?.srv === 'trial')?.sandbox_phone) {
      userHintsHelper.changeDoneArray(0, true);
    }

    if ($user.userHintsHintBusiness_1) {
      userHintsHelper.changeDoneArray(1, true);
      delete $user.userHintsHintBusiness_1;
    }

    if($segmentStore.length && $broadcastStore.length && $broadcastStore.find(broadcast => broadcast.status !== 1)) {
      userHintsHelper.changeDoneArray(2, true);
    }

    if (window.location.pathname === '/roles') {
      userHintsHelper.changeDoneArray(3, true);
    }

    if ($user.userHintsHintBusiness_4) {
      userHintsHelper.changeDoneArray(4, true);
      delete $user.userHintsHintBusiness_4;
    }

    if (window.location.pathname === '/mobile-app') {
      userHintsHelper.changeDoneArray(5, true);
    }

    if ($channels?.length && $channels?.filter(channel => channel?.status !== 'trial' && channel?.srv !== 'trial').length) {
      userHintsHelper.changeDoneArray(6, true);
    }
    /*******END DEFINE*******/
  }
</script>

<UserHintsTypeWrapper {hintName} show={show && $user.generalCompanyType === 1}>
  <UserHintsStepComponent header={$_('hints.business.1.title')}>
    <div class="text-secondary">
      {@html $_('hints.business.1.contents')}
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent header={$_('hints.business.2.title')}>
    <div class="text-secondary">
      {@html $_('hints.business.2.contents')}
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent header={$_('hints.business.3.title')}>
    <div class="text-secondary">
      <p>
        {@html $_('hints.business.3.contents1')}
      </p> <p>
        {@html $_('hints.business.3.contents2')}
      </p>
      <p>
        {@html $_('hints.business.3.contents3')}
      </p>
      <p class="mb-0">
        {@html $_('hints.business.3.contents4')}
      </p>
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent header={$_('hints.business.4.title')}>
    <div class="text-secondary">
      {@html $_('hints.business.4.contents')}
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent header={$_('hints.business.5.title')}>
    <div class="text-secondary">
      <p>
        {@html $_('hints.business.5.contents1')}
      </p>
      <p>{@html $_('hints.business.5.contents2')}</p>
      <p class="mb-0">{@html $_('hints.business.5.contents3')}</p>
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent header={$_('hints.business.6.title')}>
    <div class="text-secondary">
      <p>
        {@html $_('hints.business.6.contents1')}
      </p>
      <p>{@html $_('hints.business.6.contents2')}</p>
      <p class="mb-0">{@html $_('hints.business.6.contents3')}</p>
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent header={$_('hints.business.7.title')}>
    <div class="text-secondary">
      <p>{$_('hints.business.7.contents1')}</p>
      <p>{$_('hints.business.7.contents2')}</p>
      <p>
        <span class='text-success pointer' on:click={addChannelFirstTimeHelper}>
          {$_('hints.business.7.contents3')}
        </span>
      </p>
      <p>{$_('hints.business.7.contents4')}</p>
      <p class="mb-0">{@html $_('hints.business.7.contents5')}</p>
    </div>
  </UserHintsStepComponent>
</UserHintsTypeWrapper>

{#if $currentOpenHintStore === hintName}
  <TipsBanner/>
{/if}
