<script>
  import AutomationUnavailableCard from "./AutomationUnavailableCard.svelte";
  import {channels, chosenChannel, currentPath, onetouchShops, user, paymentProfiles, getCorporateBillsSettings} from "../helpers/store";
  import {onDestroy} from "svelte";
  import {isUserRegisteredInOneTouch, showShops, UnsubTrain} from "../helpers/utils";
  import SpinnerComponent from "./Spinners/SpinnerComponent.svelte";
  import {_} from "svelte-i18n";

  export let option;
  export let enablePadding;
  export let available = false;

  let channelsList = null;
  let chosenChannelLocal = null;
  let isUserRegistered;
  let showOneTouchShops = false;
  let unsubTrain = new UnsubTrain();
  let paymentProfile;

  unsubTrain.add = channels.subscribe(async (value) => {
    channelsList = Array.isArray(value) ? value.reduce(
      (result, item) => {
        if (item.appData) {
          if (!result[item.projectId])
            result[item.projectId] = []
          result[item.projectId].push(item);
        }
        return result;
      },
      {}
    ) : null;
  });

  unsubTrain.add = chosenChannel.subscribe(async (value) => {
    paymentProfile = undefined;
    if (value === null || value === "") {
      chosenChannelLocal = null;
    } else {
      chosenChannelLocal = value;
      getCorporateBillsSettings(chosenChannelLocal.id).then(value => {
        if (value?.org) {
          paymentProfile = {org: value.org};
        }
      });
    }
  });

  $: if (chosenChannelLocal?.paymentProfileId && $paymentProfiles) {
    paymentProfile = $paymentProfiles.find((item) => {
        return item.id === chosenChannelLocal.paymentProfileId
    });
  }
  $:isUserRegistered = isUserRegisteredInOneTouch($user)
  $:showOneTouchShops = showShops($currentPath);
  onDestroy(() => {
    unsubTrain.unsub();
  });
</script>
{#if isUserRegistered === false}
  <div class="d-flex flex-column align-items-center justify-content-center custom-pt70 p-5 mt-3">
    <SpinnerComponent/> <div class="pt-2 text-muted text-center font-size-15">{$_('setting_up_cabinet')}</div>
  </div>
{:else}
  {#if available || paymentProfile?.org !== '1msg_basic'}
    {#if chosenChannelLocal}
      <slot></slot>
    {/if}
    {#if channelsList && !Object.keys(channelsList)?.length && (!showOneTouchShops || showOneTouchShops && !$onetouchShops?.length)}
      <AutomationUnavailableCard {option} {enablePadding}/>
    {/if}
  {:else}
    <AutomationUnavailableCard {option} {enablePadding} org='1msg_basic'/>
  {/if}
{/if}
