<script>
  import {Dropdown, DropdownMenu, DropdownToggle, DropdownItem} from "sveltestrap";
  import {_} from "svelte-i18n";

  export let error = false;
  export let required = false;
  export let disabled = false;
  export let type;
  export let change = () => {};
  /**
   * The list of items to populate the dropdown.
   *
   * @typedef {Array<Object>} DropdownItem
   * @property {string} label - The label or display text for the dropdown item.
   * @property {string} value - The value associated with the dropdown item.
   *
   * @type {DropdownItem[]}
   * @description
   * This array should contain objects representing the items to be displayed in the dropdown.
   * Each object should have a `label` property for the item's label or display text, and a `value` property
   * representing the value associated with the item. The `value` property should be unique for each item.
   * @example
   * // Usage example:
   * let list = [
   *   { label: 'Option A', value: 'a' },
   *   { label: 'Option B', value: 'b' },
   *   // ...
   * ];
   */
  export let list;
  export let selected;
  export let title;
</script>

{#if type}
  <div class="position-relative SelectDropdown">
    {#if required}
      <select class="form-control" required={!selected}/>
    {/if}
    <Dropdown>
      <DropdownToggle color="light" {disabled} caret
        class="form-control w-100 d-flex align-items-center justify-content-between {disabled ? 'disabled pe-none' : ''} {error ? 'Error' : ''}">
        <span class="text-truncate">
          {selected}
        </span>
        <i class="mdi mdi-chevron-down"/>
      </DropdownToggle>
      {#if list.length || $$slots?.default}
        <DropdownMenu class="w-100">
          <div class="scrollbar">
            <slot></slot>
            {#each list as item}
              <DropdownItem class="text-truncate {item === selected ? 'active' : ''}"
                on:click={() => {selected=item; change(item)}}>
                {@html item}
              </DropdownItem>
            {/each}
          </div>
        </DropdownMenu>
      {/if}
    </Dropdown>
  </div>
{:else}
  <div class="position-relative SelectDropdown">
    {#if required}
      <select class="form-control" required={!selected}/>
    {/if}
    <Dropdown>
      <DropdownToggle color="light" {disabled} caret
        class="form-control w-100 d-flex align-items-center justify-content-between {disabled ? 'disabled pe-none' : ''} {error ? 'Error' : ''}">
        <span class="text-truncate">
          {#if selected !== undefined && selected !== null}
            {@html list.find(item => item.value === selected)?.label}
          {:else}
            {$_(title)}
          {/if}
        </span>
        <i class="mdi mdi-chevron-down"/>
      </DropdownToggle>
      {#if list.length || $$slots?.default}
        <DropdownMenu class="w-100">
          <div class="scrollbar">
            <slot></slot>
            {#each list as item}
              <DropdownItem class="text-truncate {item.value === selected ? 'active' : ''}"
                on:click={() => {selected=item.value; change(item)}}>
                {@html item.label}
              </DropdownItem>
            {/each}
          </div>
        </DropdownMenu>
      {/if}
    </Dropdown>
  </div>
{/if}

<style>
    select {
        position: absolute;
        top: 0;
    }

    .SelectDropdown .scrollbar {
        max-height: 192px;
        overflow-y: auto;
    }

    .SelectDropdown .scrollbar::-webkit-scrollbar {
        width: 3px;
        background-color: transparent;
    }

    .SelectDropdown .scrollbar::-webkit-scrollbar-track {
        background-color: transparent;
    }
</style>
