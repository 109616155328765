<script>
    import Chart from 'chart.js/auto';
    import {_, locale} from "svelte-i18n";

    import {onDestroy, onMount} from "svelte";

    export let data;
    let chart;
    let datasetsLabels = ['channel.blocks.statistic.free_quantity',
        'channel.blocks.statistic.paid_quantity',
        'channel.blocks.statistic.user_initiated_quantity',
        'channel.blocks.statistic.business_initiated_quantity',
        'channel.blocks.statistic.marketing_quantity',
        'channel.blocks.statistic.service_quantity',
        'channel.blocks.statistic.utility_quantity',
    ];

    onMount(() => {
        let labels = [];
        let datasets = [{
            label: $_(datasetsLabels[0]),
            data: [],
            backgroundColor: "#5AE5EA",
            stack: 'Stack 0',
        },
            {
                label: $_(datasetsLabels[1]),
                data: [],
                backgroundColor: "#F449E5",
                stack: 'Stack 0',
            },
            {
                label: $_(datasetsLabels[2]),
                data: [],
                backgroundColor: "#4FD13A",
                stack: 'Stack 1',
            },
            {
                label: $_(datasetsLabels[3]),
                data: [],
                backgroundColor: "#076AFF",
                stack: 'Stack 2',
            },
            {
                label: $_(datasetsLabels[4]),
                data: [],
                backgroundColor: "#ff8b07",
                stack: 'Stack 3',
            },
            {
                label: $_(datasetsLabels[5]),
                data: [],
                backgroundColor: "#f7ff07",
                stack: 'Stack 4',
            },
            {
                label: $_(datasetsLabels[6]),
                data: [],
                backgroundColor: "#ff0777",
                stack: 'Stack 5',
            }
        ];

        data.reverse();
        for( let item of data){
            if(item.period_date){
                let ts = new Date(item.period_date);
                labels.push((ts.getMonth() + 1) + "." + ts.getFullYear());
                datasets[0].data.push(item.free_quantity);
                datasets[1].data.push(item.paid_quantity);
                datasets[2].data.push(item.user_initiated_quantity);
                datasets[3].data.push(item.business_initiated_quantity);
                datasets[4].data.push(item.marketing_quantity);
                datasets[5].data.push(item.service_quantity);
                datasets[6].data.push(item.utility_quantity);

            }

        }

        chart = new Chart(
            document.getElementById('stat_chart'),
            {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    plugins: {
                        title: {
                            display: false,
                            text: ''
                        },
                        legend: {
                            position: 'bottom'
                        }
                    },

                    responsive: true,
                    interaction: {
                        intersect: false,
                    },
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true
                        }
                    }
                }
            }
        );

    });

    const unsub = locale.subscribe((value) => {
        if (value) {
            if (chart) {
                chart.data.datasets.forEach((dataset, index) => {
                    dataset.label = $_(datasetsLabels[index])
                });
                chart.update();
            }
        }
    });

    onDestroy(() => {
        unsub();
    })


</script>

<div ><canvas id="stat_chart"></canvas></div>
