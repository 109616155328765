<script>
  import {Col, Container, Row, Card, CardBody} from "sveltestrap";
  import {Link} from "svelte-routing";
  import {channels, paymentRecords, corporateBills, corporate,
    balance, paymentProfiles, projects, addChannelFirstTimeHelper, trialBar} from "../../helpers/store";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import {getRequest, promisifyStore} from "../../helpers/utils";
  import Spinner from "../../Components/Spinners/SpinnerComponent.svelte";
  import ReceiptsModal from "../../Components/Modals/ReceiptsModal.svelte";
  import BillingInfo from "./BillingInfo.svelte";
  import PaymentsTable from "../ChannelPayment/PaymentsTable.svelte";
  import ChannelState from "./ChannelState.svelte";
  import ChannelsDropdown from "./ChannelsDropdown.svelte";
  import {layout} from "../../services/loaders";
  import ChannelsAddModal from "../../Components/Modals/ChannelsAddModal.svelte";

  let state = 'loading';
  let activeChannels = false;
  let activeChannel = false;
  let receiptsModal = false;
  let payments = [];
  let profiles = [];
  let projectsList = [];
  let activeBalance = false;
  let stats = false;
  let showModal = false;
  let trialBarLocal;
  let corporates = [];
  let modalBillingDetails = {
    isOpen: false,
    data: null
  };
  layout(true);

  const unsub = balance.subscribe(async (value) => {
    activeBalance = value;
    initState();
  });

  const unsub1 = channels.subscribe(async (value) => {
    if (value) {
      let tmpActiveChannels = value && value.filter((item) => {
        return true;
        // return item.waba_info && !item.stopped;
      }) || [];

      if (projectsList.length) {
        tmpActiveChannels.map(item => {
          item.project = projectsList.find(pr => {
            return pr.id === item.projectId
          })
          return item;
        })
      }

      if (activeChannels?.length !== tmpActiveChannels?.length) {
        activeChannels = tmpActiveChannels;
        await getStats();
      }
      initState();
    }
  });

  const unsub2 = paymentRecords.subscribe(async (value) => {
    if (value) {
      payments = value;
      initState();
    }
  });

  const unsub3 = paymentProfiles.subscribe(async (value) => {
    if (value) {
      profiles = value;
    }
  });

  const unsub4 = projects.subscribe(async (value) => {
    projectsList = value;
    projectsList = projectsList
    if (activeChannels.length) {
      activeChannels = activeChannels.map(item => {
        item.project = projectsList.find(pr => {
          return pr.id === item.projectId
        })
        return item;
      })
    }
  });

  const unsub5 = corporateBills.subscribe(value => {
    if (value) {
      corporates = value;
    }
  });

  async function getStats() {
    let promises = [];
    for (let channel of activeChannels) {
      promises.push(getRequest(`${channel.apiUrl}statistics2`, {
        token: channel.token,
      }).then((data) => {
        return {
          channel: channel.id,
          data
        }
      }));
    }
    let result = await Promise.all(promises);

    let tmpStats = {
      all: {total_price_calc: 0},
    };

    for (let stat of result) {
      if (stat.data.length) {
        tmpStats[stat.channel] = stat.data[stat.data.length - 1];
        Object.keys(tmpStats[stat.channel]).forEach(key => {
          tmpStats.all[key] = parseFloat(tmpStats[stat.channel][key]) + (tmpStats.all[key] || 0)
        })
        tmpStats[stat.channel].total_price_calc = tmpStats[stat.channel]?.marketing_price + tmpStats[stat.channel]?.authentication_price + tmpStats[stat.channel]?.utility_price
        tmpStats.all.total_price_calc += tmpStats[stat.channel].total_price_calc;
      }
    }
    stats = tmpStats;
  }

  async function initState() {
    if (state === 'loading') {
      if (activeBalance && activeChannels) {
        if (activeChannels.length === 1) {
          selectChannel(activeChannels[0]);
        } else {
          trialBarLocal = await promisifyStore(trialBar, data => data.show !== undefined);
          if (trialBarLocal.show) {
            //выбираем не триал инстанс для показа формы оплаты
            let searchChannel = activeChannels.find(item => item.srv !== 'trial')
            selectChannel(searchChannel);
          } else {
            state = activeChannels.length && 'all' || 'empty';
          }
        }
      }
      layout(false)
    }
  }

  onDestroy(() => {
    unsub();
    unsub1();
    unsub2();
    unsub3();
    unsub4();
    unsub5();
  });

  function selectChannel(channel) {
    activeChannel = channel;
    state = activeChannel && 'channel' || 'all';
  }

  function checkBillingDetails({url, params}) {
    if ($corporate) {
      return true;
    } else {
      modalBillingDetails = {
        isOpen: true,
        data: {url, params}
      }
      return false;
    }
  }
</script>

<div class="page-content billing">
  <Container fluid>
    <Row class="position-relative {$trialBar?.show ? 'reverse-the-reverse' : ''}">
      <Col lg="12" xl="4" xxl="3">
        {#if $trialBar?.show}
          <Card class="border border-danger">
            <CardBody class="p-3">
              <span class="h5 mt-0 mb-4 text-secondary card-title font-size-14">
                <i class="mdi mdi-alert-outline pe-2 text-danger font-size-18 fw-600"/>{$_('billing.balance.demo_warning')}
              </span>
            </CardBody>
          </Card>
        {/if}
        <Card class="card-{(activeBalance?.balance?.toFixed(2) || 0) < 0 ? 'red' : 'green'} overflow-hidden relative">
          <CardBody class="p-3">
            <div class="d-flex justify-content-between fg-bs-gray-700 mb-3">
              <div class="subtitle font-size-18 fw-600">
                {$_('billing.balance.title')}
              </div>
              <!-- <Dropdown group class="project-action font-size-22">
                <DropdownToggle tag="div"><i class="bx bx-cog"/>
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>{$_('subscribe')}</DropdownItem>
                </DropdownMenu>
              </Dropdown> -->
              <div class="billing_round_top"/>
            </div>
            {#if (activeBalance?.balance?.toFixed(2) || 0) < 0}
              <div class="mb-2">
                <div class="fg-red font-size-18 fw-600">
                  {$_('billing.balance.insufficient_funds')}
                </div>
                <div class="font-size-12">
                  {$_('billing.balance.insufficient_funds_subtitle')}
                </div>
              </div>
            {/if}
            <div>
              <h2 class="display-6 fg-{(activeBalance?.balance?.toFixed(2) || 0) < 0 ? 'red' : 'green'} fw-500">
                <span>$</span>{activeBalance?.balance.toFixed(2)}
              </h2>
            </div>
            {#if activeBalance?.balance < 0}
              <button type="button" on:click={() => receiptsModal = true}
                class="fg-{(activeBalance?.balance?.toFixed(2) || 0) < 0 ? 'red' : 'green'} font-size-16 text-decoration-underline fw-500 border-0 p-0 bg-transparent text-start d-block">
                {$_("subscription.open_receipts")}
              </button>
              {#if receiptsModal}
                <ReceiptsModal
                  show={receiptsModal}
                  uid={activeBalance.ownerId}
                  toggleModal={() => {receiptsModal = !receiptsModal}}/>
              {/if}
            {/if}
            <div>
              <Link to="/top-up-balance" class="fg-{(activeBalance?.balance?.toFixed(2) || 0) < 0 ? 'red' : 'green'} font-size-16 text-decoration-underline fw-500">
                {$_('billing.balance.top_up')}
              </Link>
            </div>
            <div class="billing_round_bottom"/>
          </CardBody>
        </Card>
        <Card>
          <CardBody class="p-3">
            <div class="subtitle font-size-16 fw-600 mb-2">
              {$_('billing.channel.hint2.title')}
            </div>
            <Row class="mb-2">
              <Col class="text-secondary font-size-12" xl="8">
                {$_('billing.channel.hint2.text')}
              </Col>
              <Col class="d-xl-flex justify-content-end align-items-end d-none" xl="4">
                <div>
                  <img alt="charging system" class="w-100 p-2 card-clock" src="assets/images/card-clock.svg"/>
                </div>
              </Col>
            </Row>
            <a class="fg-green text-decoration-underline font-size-13"
               href="https://help.1msg.io/1msg-knowledge-base/billing/tariffs-and-payments-june-update#dialog-fees"
               target="_blank">
              {$_('billing.channel.hint2.link')}
            </a>
          </CardBody>
        </Card>
        <BillingInfo balance={activeBalance} bind:modalBillingDetails/>
      </Col>
      <Col lg="12" xl="8" xxl="9">
        {#if state === 'all'}
          <div class="my-2">
            <ChannelsDropdown change={selectChannel} active={activeChannel} channels={activeChannels}/>
          </div>
          <Row>
            <Col md="12" lg="6" xl="6" xxl="3" class="d-flex">
              <Card class="bg-lavender text-primary w-100">
                <CardBody class="p-3">
                  <div class="d-flex align-items-center">
                    <i class="mdi mdi-chevron-up-circle-outline font-size-38 lh-1 pe-2"/>
                    <div class="font-size-14">{@html $_('billing.channel.choose_text')}</div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {#if stats}
              <Col md="12" lg="6" xl="6" xxl="3" class="d-flex">
                <Card class="w-100">
                  <CardBody class="fw-500 p-3">
                    <div class="font-size-14 pb-1">{$_('billing.stats.summary')}</div>
                    <div class="font-size-20 pt-2">$ {stats.all.total_price_calc || 0}</div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" lg="6" xl="6" xxl="3" class="d-flex">
                <Card class="w-100">
                  <CardBody class="fw-500 p-3">
                    <div class="font-size-14 pb-1">{$_('billing.stats.business')}</div>
                    <div class="font-size-20 pt-2"> {stats.all?.authentication_quantity + stats.all?.marketing_quantity + stats.all?.utility_quantity || 0}</div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" lg="6" xl="6" xxl="3" class="d-flex">
                <Card class="w-100">
                  <CardBody class="fw-500 p-3">
                    <div class="font-size-14 pb-1">{$_('billing.stats.user')}</div>
                    <div class="font-size-20 pt-2"> {stats.all?.service_quantity || 0}</div>
                  </CardBody>
                </Card>
              </Col>
            {:else}
              <Col class="d-flex align-items-center justify-content-center mb-4">
                <Spinner/>
              </Col>
            {/if}
          </Row>
          <PaymentsTable paymentRecords={payments} corporateBills={corporates}/>
        {:else if state === 'channel'}
          <ChannelState {payments} {profiles} changeState={selectChannel} {activeChannel} {activeChannels} statistic={stats} {corporates} {checkBillingDetails}/>
        {:else if state === 'empty'}
          <div class="d-flex align-items-center justify-content-center" style="height: 70vh">
            <div class="text-center">
              <img src="assets/images/table_rocket.svg" alt="no billing info"/>
              <div class="pt-2 fw-500 fg-bs-gray-700 font-size-14">{$_('billing.empty.hint')}</div>
              <button
                type="button"
                class="btn btn-success btn-lg waves-effect waves-light mt-3"
                on:click={()=>{addChannelFirstTimeHelper(()=>{showModal =true})}}>
                <i class="mdi mdi-plus me-1"/>
                {$_('channels.add_channel')}
              </button>
              <ChannelsAddModal
                  project={projectsList[0]}
                  show={showModal}
                  onCloseClick={() => {showModal =false}}
              />
            </div>
          </div>
        {/if}
      </Col>
    </Row>
  </Container>
</div>

<style>
    .card-clock {
        max-width: 103px;
    }

    :global(.reverse-the-reverse){
        flex-direction: row-reverse;
    }
    @media (max-width: 1200px) {
        :global(.reverse-the-reverse){
            flex-direction: column-reverse;
      }
    }
</style>
