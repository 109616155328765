<script>
  import {Navigation} from 'swiper';
  import {Swiper, SwiperSlide} from 'swiper/svelte';
  import {_} from "svelte-i18n";

  export let color;
  export let carousel;
  export let viewModal;
  export let scheduled = false;
  export let liteVersio = false;

  let carouselData = [];
  const urlRegex = /\b(https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const checkUrl = /\b(https?:\/\/)/;
  let navigation ={
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  };

  $: if (carousel) {
    carouselData = [];
    carousel?.forEach(({components}) => {
      let card = {};
      components.forEach(({type, parameters}) => {
        switch (type) {
          case 'header':
            if(parameters[0].type === 'image') {
              card.image_url = parameters[0].image.link
            }
            if(parameters[0].type === 'video') {
              card.video_url = parameters[0].video.link
            }
          break;
          case 'body':
            if(parameters[0].type === 'text') {
              card.text = parameters[0].text
            }
          break;
          case 'buttons':
            card.buttons = parameters;
          break;
        }
      });
      carouselData = [...carouselData, card];
    });
  }

  const parts = (text) => {
    return typeof text === "string" ? text?.trim()?.split(urlRegex) : [''];
  };

  const truncatedText = (text) => {
    return (typeof text === "string" ? text?.slice(0, 90) : '') + (text.length > 90 ? '...' : '');
  };
</script>

{#if liteVersio}
  <div class="mt-1 mb-2 CarouselScheduled">
    <Swiper slidesPerView={1} spaceBetween={10} {navigation} modules={[Navigation]} class="m-0">
      {#each carouselData as card}
        <SwiperSlide class="px-4">
          {#if card.image_url}
            <div class="d-flex align-items-center gap-2 rounded-3 text-muted font-size-15 pointer"
              on:click={() => viewModal('image' , card.image_url, card.text)}>
              <i class="bx bx-image font-size-22"/> <span class="text-truncate">{$_('broadcast.image')}</span>
            </div>
          {:else if card.video_url}
            <div class="d-flex align-items-center gap-2 rounded-3 text-muted font-size-15 pointer"
              on:click={() => viewModal('video' , card.video_url)}>
              <i class="bx bx-video font-size-22"/> <span class="text-truncate">{$_('broadcast.video')}</span>
            </div>
          {/if}
          <div class="text-muted pt-2 Text font-size-15 fw-500">
            {#each parts(truncatedText(card.text)) as part}
              {#if checkUrl.test(part)}
                <a href="{part}" target="_blank" rel="nofollow noopener">{part}</a>
              {:else}
                {part}
              {/if}
            {/each}
          </div>
          <div class="d-flex flex-wrap mt-2">
            {#each card.buttons as btn}
              <div class="d-flex align-items-center justify-content-center pe-2 text-muted" title={btn?.phone_number ?? btn?.url}>
                {#if btn.type === 'PHONE_NUMBER'}
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                    <g clip-path="url(#clip0_987_13638)">
                      <path fill="#74788d"
                        d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.7533 9.8 10.4933 9.86 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_987_13638">
                        <rect width="16" height="16" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                {:else if btn.type === 'URL'}
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                    <g clip-path="url(#clip0_987_13546)">
                      <path fill="#74788d"
                        d="M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_987_13546">
                        <rect width="16" height="16" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                {/if}
                <span class="text-truncate font-size-14">{btn.text}</span>
              </div>
            {/each}
          </div>
        </SwiperSlide>
      {/each}
      <div slot="container-end">
        <div class="swiper-button-prev font-size-30 position-absolute top-0 h-100 d-flex align-items-center pointer">
          <i class="mdi mdi-chevron-left text-muted"/>
        </div>
        <div class="swiper-button-next font-size-30 position-absolute top-0 end-0 h-100 d-flex align-items-center pointer">
          <i class="mdi mdi-chevron-right text-muted"/>
        </div>
      </div>
    </Swiper>
  </div>
{:else}
  <div class="mb-1 Carousel {color} d-flex justify-content-end position-relative ps-lg-2">
    <Swiper slidesPerView="auto" spaceBetween={0} {navigation} modules={[Navigation]} class="ms-lg-3 me-0 position-static">
      {#each carouselData as card}
        <SwiperSlide>
          <div class="d-flex flex-column Wrapper">
            <div class="p-1">
              {#if scheduled}
                {#if card.image_url}
                  <div class="imageScheduled d-flex align-items-center gap-2 rounded-3 text-muted font-size-15 pointer"
                    on:click={() => viewModal('image' , card.image_url, card.text)}>
                    <i class="bx bx-image font-size-22"/> <span class="text-truncate">{$_('broadcast.image')}</span>
                  </div>
                {:else if card.video_url}
                  <div class="videoScheduled d-flex align-items-center gap-2 rounded-3 text-muted font-size-15 pointer"
                    on:click={() => viewModal('video' , card.video_url)}>
                    <i class="bx bx-video font-size-22"/> <span class="text-truncate">{$_('broadcast.video')}</span>
                  </div>
                {/if}
              {:else}
                {#if card.image_url}
                  <div class="d-flex align-items-center justify-content-center overflow-hidden pointer rounded-3" on:click={() => viewModal('image' , card.image_url, card.text)}>
                    <img src={card.image_url} alt={card.text} class="w-100"/>
                  </div>
                {:else if card.video_url}
                  <div class="d-flex align-items-center justify-content-center overflow-hidden pointer rounded-3 position-relative" on:click={() => viewModal('video' , card.video_url)}>
                    <video class="w-100">
                      <source src={card.video_url}/>
                      <track kind="captions">
                    </video>
                    <div class="d-flex align-items-center justify-content-center rounded-circle position-absolute text-light VideoPlay">
                      <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" version="1.1" style="margin-left: 2px">
                        <path fill="currentColor"
                          d="M19.5,10.9 L6.5,3.4 C5.2,2.7 4.1,3.3 4.1,4.8 L4.1,19.8 C4.1,21.3 5.2,21.9 6.5,21.2 L19.5,13.7 C20.8,12.8 20.8,11.6 19.5,10.9 Z">
                        </path>
                      </svg>
                    </div>
                  </div>
                {/if}
              {/if}
            </div>
            <div class="font-size-16 Text">
              {#each parts(card.text) as part}
                {#if checkUrl.test(part)}
                  <a href="{part}" target="_blank" rel="nofollow noopener">{part}</a>
                {:else}
                  {part}
                {/if}
              {/each}
            </div>
          </div>
          <div class="Buttons">
            {#each card.buttons as btn}
              {#if btn.type === 'URL'}
                  <a href={btn.url} target="_blank" rel="nofollow" class="d-flex align-items-center justify-content-center px-3 flex-grow-1 pointer ButtonsBtn" title={btn.url}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                      <g clip-path="url(#clip0_987_13546)">
                        <path fill="#0096DE"
                          d="M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_987_13546">
                          <rect width="16" height="16" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    <span class="text-truncate font-size-14">{btn.text}</span>
                  </a>
                {:else}
                  <div rel="nofollow" class="d-flex align-items-center justify-content-center px-3 flex-grow-1 pointer ButtonsBtn" title={btn?.phone_number}>
                    {#if btn.type === 'PHONE_NUMBER'}
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                        <g clip-path="url(#clip0_987_13638)">
                          <path fill="#0096DE"
                            d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.7533 9.8 10.4933 9.86 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_987_13638">
                            <rect width="16" height="16" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    {/if}
                    <span class="text-truncate font-size-14">{btn.text}</span>
                  </div>
                {/if}
            {/each}
          </div>
        </SwiperSlide>
      {/each}
      <div slot="container-end">
        <div class="position-absolute top-0 h-100 d-none d-lg-flex align-items-center justify-content-center SwiperButton swiper-button-prev">
          <i class="mdi mdi-chevron-left rounded-circle d-flex align-items-center justify-content-center pointer"/>
        </div>
        <div class="position-absolute top-0 end-0 h-100 d-none d-lg-flex align-items-center justify-content-center SwiperButton swiper-button-next">
          <i class="mdi mdi-chevron-right rounded-circle d-flex align-items-center justify-content-center pointer"/>
        </div>
      </div>
    </Swiper>
  </div>
{/if}

<style>
  .Carousel.gray .Wrapper, .Carousel.gray .Buttons .ButtonsBtn {
    background-color: #EFF2F7;
  }

  .Carousel.green .Wrapper, .Carousel.green .Buttons .ButtonsBtn {
    background-color: #DCF8C6;
  }

  .Carousel.blue .Wrapper, .Carousel.blue .Buttons .ButtonsBtn {
    background-color: #dfeff7;
  }

  .Carousel.yellow .Wrapper, .Carousel.yellow .Buttons .ButtonsBtn {
    background-color: #fff9e3;
  }

  .Carousel .Wrapper {
    border-radius: 8px;
  }

  .Carousel img, .Carousel video {
    height: 150px;
    object-fit: cover;
    background-color: #f6f6f6;
  }

  .Carousel .VideoPlay {
    width: 50px;
    height: 50px;
    background-color: rgba(11,20,26,.35);
  }

  .Carousel .Text {
    white-space: pre-wrap;
    word-break: break-word;
    padding: 0px 12px 8px;
  }

  .Carousel .Buttons {
    margin: 0 -2px;
  }

  .Carousel .Buttons .ButtonsBtn {
    color: #0096DE;
    min-width: calc(50% - 4px);
    height: 32px;
    margin: 4px 2px 0 2px;
    border-radius: 8px;
  }

  .Carousel.green .Buttons .ButtonsBtn {
    color: #23B16D;
  }

  .Carousel.green .Buttons .ButtonsBtn path {
    fill: #23B16D;
  }

  .Carousel.light-green .Buttons .ButtonsBtn {
    color: #74788d;
  }

  .Carousel.light-green .Buttons .ButtonsBtn path {
    fill: #74788d;
  }

  .Carousel.light-green .Wrapper, .Carousel.light-green .Buttons .ButtonsBtn {
    background-color: #daf4eb;
  }

  .Carousel :global(.swiper-slide) {
    width: 265px !important;
  }

  .Carousel :global(.swiper-slide:not(:first-child)) {
    margin-left: 10px !important;
  }

  /* отступы слева и справа от стрелак */
  /* нужно убирать когда нет стрелок */
  /* .Carousel :global(.swiper-slide:first-child) {
    margin-left: 10px !important;
  }

  .Carousel :global(.swiper-slide:last-child) {
    margin-right: 10px !important;
  } */

  .Carousel .SwiperButton {
    z-index: 1;
    width: 0;
  }

  .Carousel .SwiperButton.swiper-button-prev {
    border-left: 2px solid #EFF2F7;
  }

  .Carousel .SwiperButton.swiper-button-next {
    border-right: 2px solid #EFF2F7;
  }

  .Carousel .SwiperButton.swiper-button-prev i, .Carousel .SwiperButton.swiper-button-next i {
    min-width: 48px;
    height: 48px;
    font-size: 30px;
    background-color: #fff;
    color: #23B16D;
    border: 2px solid #EFF2F7;
  }

  .Carousel :global(.SwiperButton.swiper-button-lock) {
    display: none !important;
  }

  @media (max-width: 992px) {
    .Carousel {
      margin-left: -20px;
      margin-right: -45px;
    }

    .Carousel :global(.swiper) {
      padding-left: 20px;
      padding-right: 45px;
    }
  }

  .Carousel .imageScheduled, .Carousel .videoScheduled {
    padding: 7px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .CarouselScheduled .swiper-button-prev, .CarouselScheduled .swiper-button-next {
    z-index: 1;
    width: 20px;
    overflow: hidden;
  }

  .CarouselScheduled .swiper-button-prev i {
    margin-left: -10px;
  }

  .CarouselScheduled :global(.swiper-button-disabled) {
    opacity: 0.5;
  }
</style>
