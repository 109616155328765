<script>
  import {Button, Modal} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {WabaTemplatesDAO, wabaTemplatesStore} from "../../helpers/onetouch/WabaTemplatesDAO";
  import {onDestroy, onMount} from "svelte";
  import AddTemplateData from "./AddTemplateData.svelte";
  import SpinnerComponent from "../../Components/Spinners/SpinnerComponent.svelte";
  import {Navigation} from 'swiper';
  import {Swiper, SwiperSlide} from 'swiper/svelte';

  export let isOpenTemplates = false;
  export let data;
  export let send;
  export let sendScheduled;

  /**
   * @type {WabaTemplate[]}
   */
  let wabaTemplatesStoreLocal;
  let wabaTemplatesStoreInitial;
  let chosenTemplate;
  let fullHeight = {};
  let search = '';
  let isOpenExample = false;
  let navigation ={
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  };

  onMount(() => {
    WabaTemplatesDAO.getOrLoadAll().then(() => {
      WabaTemplatesDAO.startAutoReload(60*1000);
    });
  });

  const sub = wabaTemplatesStore.subscribe((templates) => {
    if (Array.isArray(templates)) {
      for (let i = 0; i < templates.length; i++) {
        /**
         * @type WabaTemplate
         */
        const item = templates[i]
        if (fullHeight[item.id] !== undefined)
          continue;
        if (item.components?.length) {
          for (let j = 0; j < item.components.length; j++) {
            if (item.components[j].text?.length > 140) {
              fullHeight[item.id] = false;
              break;
            }
          }
        }
      }
    }
    wabaTemplatesStoreInitial = templates
    filterTemplates()
  });

  onDestroy(() => {
    sub();
    WabaTemplatesDAO.stopAutoReload();
  });

  function filterTemplates() {
    wabaTemplatesStoreLocal = wabaTemplatesStoreInitial.filter(item => item.status === 'approved' && (!search || item.name && item.name.indexOf(search) !== -1))
  }
</script>

{#if isOpenTemplates}
  <Modal isOpen={isOpenTemplates} fade={true} toggle={() => isOpenTemplates = false}>
    <div class="modal-body WhatsappTemplatesModal">
      <div class="px-2">
        <div class="d-flex justify-content-between">
          <h4 class="font-size-18 fw-600 mb-3 me-3">{$_('broadcast.whatsapp_templates')}</h4>
          <button type="button" class="btn-close" aria-label="Close" on:click={() => isOpenTemplates = false}/>
        </div>
        <div class="position-relative mb-3">
          <input type="text" placeholder={$_('search')} bind:value={search} on:input={filterTemplates}
            class="form-control border-1 rounded-2 text-muted"/>
          <i class="bx bx-search-alt position-absolute font-size-20 text-muted top-0"/>
        </div>
      </div>
      <div class="mb-3">
        {#if !wabaTemplatesStoreLocal}
          <div class="text-center">
            <SpinnerComponent/>
          </div>
        {:else}
          {#each wabaTemplatesStoreLocal as template}
            <div class="pt-2 px-2 Template">
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div on:click={() => {chosenTemplate = template; isOpenExample = true; isOpenTemplates = false}}>
                <div class="d-flex align-items-center justify-content-between gap-2 mb-2">
                  <div class="text-truncate font-size-15 fw-600">{template.name}</div>
                  <div class="font-size-15 fw-600 text-muted text-uppercase">{template.language}</div>
                </div>
                {#if template.components?.find(component => component.type === "BUTTONS")?.buttons[0].type === "CATALOG"}
                  <div class="mb-2">
                    <div class="text-muted font-size-15 d-flex gap-2">
                      <i class="bx bxs-grid-alt font-size-22"/> {$_('chat_inbox.MessageChat.catalog')}
                    </div>
                  </div>
                {/if}
                {#each template.components as component}
                  {#if component.type === "HEADER"}
                    {#if component.format === "TEXT"}
                      <div class="text-muted mb-2 Text font-size-15 fw-500">
                        {component.text}
                      </div>
                    {:else}
                      <div class="mb-2">
                        <div class="text-muted font-size-15 d-flex gap-2">
                          {#if component.format === "IMAGE"}
                            <i class="bx bx-image font-size-22"/> {$_('broadcast.image')}
                          {:else if component.format === "VIDEO"}
                            <i class="bx bx-video font-size-22"/> {$_('broadcast.video')}
                          {:else if component.format === "DOCUMENT"}
                            <i class="bx bx-file font-size-22"/> {$_('broadcast.document')}
                          <!-- {:else}
                            <i class="bx bx-microphone font-size-22"/> {$_('broadcast.audio')} -->
                          {/if}
                        </div>
                      </div>
                    {/if}
                  {:else if component.type === "BODY" || component.type === "FOOTER"}
                    <div class="text-muted mb-2 Text font-size-{component.type === "BODY" ? '15' : '14'} fw-500 {fullHeight[template.id] !== undefined? 'maxHeight140' : ''} {fullHeight[template.id] ? 'maxHeightMaxContent': ''}">
                      {#if template.category !== "AUTHENTICATION"}
                        {component.text ?? ''}
                      {:else}
                        {#if component.type === "BODY"}
                          {`{{1}} is your verification code.`}
                          {#if component?.add_security_recommendation}
                            {`For your security, do not share this code.`}
                          {/if}
                        {:else if component.type === "FOOTER"}
                          {`This code expires in ${component?.code_expiration_minutes} minutes`}
                        {/if}
                      {/if}
                    </div>
                  {:else if component.type === "BUTTONS"}
                    <div class="d-flex flex-wrap mb-2">
                      {#each component.buttons as btn}
                        <div class="d-flex align-items-center justify-content-center pe-2 text-muted" title={btn?.phone_number ?? btn?.url}>
                          {#if btn.type === 'PHONE_NUMBER'}
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                              <g clip-path="url(#clip0_987_13638)">
                                <path fill="#74788d"
                                  d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.7533 9.8 10.4933 9.86 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_987_13638">
                                  <rect width="16" height="16" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          {:else if btn.type === 'URL'}
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                              <g clip-path="url(#clip0_987_13546)">
                                <path fill="#74788d"
                                  d="M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_987_13546">
                                  <rect width="16" height="16" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          {:else if btn.type === 'OTP'}
                            <i class="bx bx-copy-alt font-size-16 me-1"/>
                          {/if}
                          <span class="text-truncate font-size-14">{btn.text}</span>
                        </div>
                      {/each}
                    </div>
                  {/if}
                {/each}
              </div>
              {#each template.components as component}
                {#if component.type === "CAROUSEL"}
                  <div class="mt-3 mb-2">
                    <Swiper slidesPerView={1} spaceBetween={10} {navigation} modules={[Navigation]}>
                      {#each component.cards as card}
                        <SwiperSlide class="px-4">
                          {#each card.components as component}
                            {#if component.type === "HEADER"}
                              <div class="mb-2">
                                <div class="text-muted font-size-15 d-flex gap-2">
                                  {#if component.format === "IMAGE"}
                                    <i class="bx bx-image font-size-22"/> {$_('broadcast.image')}
                                  {:else if component.format === "VIDEO"}
                                    <i class="bx bx-video font-size-22"/> {$_('broadcast.video')}
                                  {/if}
                                </div>
                              </div>
                            {:else if component.type === "BODY"}
                              <div class="text-muted mb-2 Text font-size-15 fw-500">
                                {component.text ?? ''}
                              </div>
                            {:else if component.type === "BUTTONS"}
                              <div class="d-flex flex-wrap">
                                {#each component.buttons as btn}
                                  <div class="d-flex align-items-center justify-content-center pe-2 text-muted" title={btn?.phone_number ?? btn?.url}>
                                    {#if btn.type === 'PHONE_NUMBER'}
                                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                                        <g clip-path="url(#clip0_987_13638)">
                                          <path fill="#74788d"
                                            d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.7533 9.8 10.4933 9.86 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"/>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_987_13638">
                                            <rect width="16" height="16" fill="white"/>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    {:else if btn.type === 'URL'}
                                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                                        <g clip-path="url(#clip0_987_13546)">
                                          <path fill="#74788d"
                                            d="M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z"/>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_987_13546">
                                            <rect width="16" height="16" fill="white"/>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    {/if}
                                    <span class="text-truncate font-size-14">{btn.text}</span>
                                  </div>
                                {/each}
                              </div>
                            {/if}
                          {/each}
                        </SwiperSlide>
                      {/each}
                      <div slot="container-end">
                        <div class="swiper-button-prev font-size-30 position-absolute top-0 h-100 d-flex align-items-center pointer">
                          <i class="mdi mdi-chevron-left text-muted"/>
                        </div>
                        <div class="swiper-button-next font-size-30 position-absolute top-0 end-0 h-100 d-flex align-items-center pointer">
                          <i class="mdi mdi-chevron-right text-muted"/>
                        </div>
                      </div>
                    </Swiper>
                  </div>
                {/if}
              {/each}
              {#if fullHeight[template.id] !== undefined}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div class="text-center pointer position-relative Arrow {fullHeight[template.id] ? 'Open': ''}"
                  on:click={() => fullHeight[template.id] = !fullHeight[template.id]}>
                  <i class="mdi mdi-chevron-down font-size-18 position-relative d-inline-block"/>
                </div>
              {/if}
              <div class="BorderBottom"/>
            </div>
          {/each}
        {/if}
      </div>
      <div class="px-2 text-end">
        <Button outline color="danger" class="py-2 px-4" on:click={() => isOpenTemplates = false}>
          <span class="font-size-14">{$_('cancel')}</span>
        </Button>
      </div>
    </div>
  </Modal>
{/if}

<AddTemplateData bind:data bind:show={isOpenExample} onClose={() => isOpenExample = false} {send} {sendScheduled} template={chosenTemplate}/>

<style>
  .WhatsappTemplatesModal input {
    padding-left: 40px;
  }

  .WhatsappTemplatesModal .bx-search-alt {
    top: 0;
    left: 11px;
    line-height: 40px;
  }

  .WhatsappTemplatesModal .BorderBottom {
    border-bottom: 1px solid #DBDCDD;
  }

  .WhatsappTemplatesModal .Template:hover {
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px #23B16D;
    border-radius: 4px;
  }

  .WhatsappTemplatesModal .Template:hover .BorderBottom {
    border-bottom-color: transparent;
  }

  .WhatsappTemplatesModal .maxHeight140 {
    max-height: 140px;
  }

  .WhatsappTemplatesModal .maxHeightMaxContent {
    max-height: max-content;
  }

  .WhatsappTemplatesModal .Text {
    white-space: pre-wrap;
    word-break: break-word;
    overflow-y: hidden;
    transition: .15s;
  }

  .WhatsappTemplatesModal .Arrow {
    margin-top: -15px;
  }

  .WhatsappTemplatesModal .Arrow::before {
    content: '';
    position: absolute;
    height: 63px;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50.52%, #FFFFFF 100%);
    bottom: 2px;
    left: 0;
  }

  .WhatsappTemplatesModal .Arrow.Open::before {
    display: none;
  }

  .WhatsappTemplatesModal .Arrow.Open .mdi-chevron-down {
    transform: rotate(180deg);
  }

  .WhatsappTemplatesModal .swiper-button-prev, .WhatsappTemplatesModal .swiper-button-next {
    z-index: 1;
    width: 20px;
    overflow: hidden;
  }

  .WhatsappTemplatesModal .swiper-button-prev i {
    margin-left: -10px;
  }

  .WhatsappTemplatesModal :global(.swiper-button-disabled) {
    opacity: 0.5;
  }
</style>
