<script>
  import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onetouchShops, chosenChannel, appClient} from "../../helpers/store";
  import {addToast} from "../../helpers/toast";
  import {layout} from "../../services/loaders";
  import {BroadcastDAO} from "../../helpers/onetouch/BroadcastDAO";

  let isOpen = false;
  let activeShop = null;
  let loader = false;

  const {shopId} = appClient.getCommonVars($chosenChannel);

  const closeModal = () => {
    if (loader) return;
    isOpen = false;
    activeShop = null;
  };

  const req = async () => {
    loader=true;
    const res = await appClient.mergeFlowBrodcast(activeShop.id, true, false, $chosenChannel);
    if (!res || res.error || res.status === 'error') {
      loader=false;
      addToast({
        title: $_("error"),
        message: $_("something_went_wrong")
      });
    } else if (res.status === 'ok') {
      loader=false;
      addToast({title: $_("CopyStoreSettings.success")});
      closeModal();
      layout(true);
      BroadcastDAO.stopAutoReload();
      BroadcastDAO.loadAll({
        limit: 1000000,
        ordering: '-id'
      }).then(() => {
        BroadcastDAO.startAutoReload(60*1000);
        layout(false);
      })
    }
  };
</script>

<button class="p-0 border-0 bg-transparent text-muted text-start"
  on:click={() => isOpen = true}>
  {$_('CopyStoreSettings.copy_settings')}
</button>

{#if isOpen}
<Modal {isOpen} toggle={closeModal} size="md" scrollable>
  <ModalHeader toggle={closeModal}>
    <span class="font-size-20 fw-bold">
      {$_('CopyStoreSettings.modal_title')}
    </span>
  </ModalHeader>
  <ModalBody class="p-0">
    <ul class="list-group">
      {#each $onetouchShops as item}
        <button class="rounded-0 border-start-0 border-end-0 text-truncate list-group-item list-group-item-action {activeShop?.id === item.id ? 'Active': ''}"
          disabled={item.id === shopId}
          type="button" on:click={() => activeShop = item}>
          {item.trade_name}
        </button>
      {/each}
    </ul>
  </ModalBody>
  <ModalFooter class="justify-content-center">
    <Button color="success" class="py-1 px-4 font-size-15"
      disabled={!activeShop} on:click={req}>
      {#if loader}
        <Spinner size="sm"/>
      {:else}
        {$_('copy')}
      {/if}
    </Button>
  </ModalFooter>
</Modal>
{/if}

<style>
  .list-group button:first-child {
    border-top: 0;
  }

  .list-group button:last-child {
    border-bottom: 0;
  }

  .Active {
    color: #495057;
    background-color: #f8f9fa;
  }
</style>
