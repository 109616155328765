<script>
  import Profile from "./Profile.svelte";
  import Search from "./Search.svelte";
  import MessageChatParent from "../MessageChat/MessageChatParent.svelte";
  import UserCard from "../UserCard/UserCard.svelte";
  import List from "./List.svelte";
  import {_} from "svelte-i18n";
  import {appClient, chosenChannel, user, channelsAdditionalInfo} from "../../../../helpers/store";
  import {timer} from "../../../../helpers/utils.js";
  import {onDestroy} from "svelte";
  import {startNatsListening, stopNatsListening} from "../../../../helpers/onetouch/NatsClientBuilder";
  import {writable} from "svelte/store";
  import ChatsDAO from "../../../../helpers/onetouch/ChatsDAO";

  let chosenChat;
  let chats;
  let blockedChats;

  let chosenChannelLocal = null;

  let activeOperators = writable([]);
  let activeOperator;

  let openWindowStore;

  /**
   * @type {ChatsDAO}  chatsGetter
   */
  let chatsGetter;

  //читаем первоначальный лист сообщений

  //смотрим есть ли натс, если есть - подписываемся, если нет - ставим интервальный запрос с временем
  /**
   * @type {Chat[]}  _localChatsData
   */
  let _localChatsData;
  let _localChatsFindData;
  /**
   * @type {Chat[]}  _localChatsData
   */
  let chatsData;
  let blockedChatsData;
  let filter;
  let isOpenUserCard = false;

  let operatorIdForFilter;
  let unreadMessagesFilter = false;
  let unsubPrev;

  let unsubPrev1;
  let isSearchDisabled = false;
  let initialChatLoad = true;

  let activeTab;

  let dataReplyMessage;
  let channelAdditionalInfo;

  const calculateChats = (localFilter = null) => {
    if (localFilter !== null) {
      filter = localFilter.toString().toLowerCase().trim()
    }
    if (filter) {
      chatsData = [];
      isSearchDisabled = true
      chatsGetter.findMessage(filter).then(data => {
        //if chats data is here - calculate result immediately. if not - wait for it
        if (data?.error === undefined) {
          if (_localChatsData) {
            let filteredChats = [];
            for (const chat of _localChatsData) {
              if (chat.name.toLowerCase().includes(filter) || chat.id_in_messenger.toLowerCase().includes(filter)) {
                filteredChats.push({...chat, uniqueKey: chat.id, message_id: undefined});
              }
              if (filteredChats.length === 5) break;
            }

            processFilter(data, filteredChats)
          } else {
            _localChatsFindData = data
          }
        }
      }, () => {
        isSearchDisabled = false
      })
    } else {
      chatsData = _localChatsData
    }
  }

  function clearLocalData() {
    _localChatsData = null;
    _localChatsFindData = null;
  }

  function processFilter(data = null, filteredChats = []) {
    const workData = data ?? _localChatsFindData;
    if (workData) {
      let list = workData.reduce(
        (acc, item) => {
          if (item.bot_user) {
            const foundChat = _localChatsData.find(chatItem => chatItem.id === item.chat);
            const localItem = {
              ...foundChat, ...{
                uniqueKey: Number(`${item.chat}${item.id}`),
                id: item.chat,
                message_id: item.id,
                shop_id: item.bot_user.shop_id,
                id_in_messenger: item.bot_user.messenger_user_id,
                url_in_messenger: '',
                name: item.bot_user.name,
                avatar_url: item.bot_user.avatar_url,
                last_message_time: item.ts_in_messenger,
                last_message_text: item.text.length < 95 ? item.text : item.text.slice(0, 95),
                message_direction: item.direction,
              }
            };
            acc.push(localItem);
          }
          return acc
        }, [])
      chatsData = [...filteredChats, ...list];
    } else {
      chatsData = [...filteredChats];
    }
    _localChatsFindData = null; //release data
    isSearchDisabled = false
  }

  const subToChat = () => {
    unsubPrev && unsubPrev();

    unsubPrev = chats.subscribe(data => {
      if ($user.userHintsHintCoder_1 === false && data[0]?.last_message_text === 'Hello world') {
        $user.userHintsHintCoder_1 = true;
      }
      _localChatsData = data
      if (!filter) {
        chatsData = _localChatsData
      } else if (_localChatsFindData) {
        processFilter()
      }
    })
    unsubPrev1 && unsubPrev1();
    unsubPrev1 = blockedChats.subscribe(data => {
      blockedChatsData = data
    })

  }
  const processChatList = () => {
    initialChatLoad = true;
    chatsGetter && chatsGetter.clear();
    chatsGetter && chatsGetter.stopListening();
    if (chosenChannelLocal) {
      chatsGetter = new ChatsDAO();
      chats = chatsGetter.chatsStore
      chosenChat = chatsGetter.activeChatStore
      openWindowStore = chatsGetter.openWindowStore
      blockedChats = chatsGetter.blockedChatsStore
      subToChat();
      chatsGetter.startListening(chosenChannelLocal).then(() => {
        initialChatLoad = false;
      });
    }
  }

  const unsub1 = chosenChannel.subscribe(async value => {
    if (value === null || value === "" || value === undefined) {
      await stopNatsListening()
      chosenChannelLocal = null
      clearLocalData()
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        clearLocalData()
        chosenChannelLocal = value

        //start chats loading after 10sec, if nats couldnt connect
        Promise.race([startNatsListening(chosenChannelLocal), timer(10000)]).then(processChatList)

        appClient.getActiveOperators(chosenChannelLocal).then(data => {
          if (data?.result?.results) {
            activeOperators.set(data.result.results);
            appClient.getCurrentUserOnetouch().then(currentUserOnetouch => {
              activeOperator = data?.result.results.find(({profile}) => currentUserOnetouch?.id === profile.id)
            })

          } else {
            activeOperators.set([])
          }
        }, err => {
          activeOperators.set([])
        })
      }
    }
  });

  const unsub2 = channelsAdditionalInfo.subscribe(value => {
    channelAdditionalInfo = value?.[chosenChannelLocal.id];
    if (channelAdditionalInfo?.error) {
      let fatal_error = channelAdditionalInfo?.error || false;
      console.error('fatal_error', fatal_error, typeof fatal_error)
    }
  });

  onDestroy(() => {
    unsub1();
    unsub2();
    if (chatsGetter) {
      chatsGetter.clear();
      chatsGetter.stopListening();
    }
    stopNatsListening();
  });

  const replyMessage = (data) => {
    dataReplyMessage = data;
  };

  const changeChosenChat = (chat) => {
    chatsGetter && chatsGetter.setActiveChat(chat);
    replyMessage(null);
  };
</script>

<div class="LeftBlock pe-md-4 pe-lg-0 {$chosenChat?.id ? 'd-none d-lg-block' : ''}">
  <Profile {activeOperator} {chosenChannelLocal}/>
  <Search {activeOperator} {activeOperators} bind:activeTab bind:operatorIdForFilter bind:unreadMessagesFilter
    disabled={isSearchDisabled} on:change={e => calculateChats(e.detail.localFilter)}/>
  <List {activeOperators} bind:activeTab {blockedChatsData} {changeChosenChat} {chatsData}
    {chosenChat} filter={!!filter} {initialChatLoad} {isSearchDisabled} {operatorIdForFilter} {unreadMessagesFilter}/>
</div>
{#if $chosenChat?.id}
  <div class="grid h_minus40">
    <MessageChatParent {blockedChatsData} {changeChosenChat} windowStore={openWindowStore} {chosenChat}
      {activeOperators} {chatsGetter} {activeOperator} toggleUserCard={() => isOpenUserCard=true} {dataReplyMessage} {replyMessage}
      isCloud={channelAdditionalInfo?.isCloud}/>
    <UserCard {chosenChat} {isOpenUserCard} toggleUserCard={() => isOpenUserCard=false}/>
  </div>
{:else if window.innerWidth > 991}
  <div class="d-flex align-items-center justify-content-center text-center">
    <div class="pb-5 px-3 mb-5">
      <img src="/assets/images/wait.svg" alt="wait for chat" class="mb-4 w-100"/>
      <div class="font-size-22 fw-500 pb-5 mb-5">
        {$_('chat_inbox.select_any_conversation_or_start_a_new_one')}
      </div>
    </div>
  </div>
{/if}

<style>
  .grid {
    display: grid;
    grid-template-columns: auto 314px;
    height: calc(100vh - 94px);
  }

  @media screen and (max-width: 1650px) {
    .grid {
      grid-template-columns: auto 0;
    }
  }

  @media (min-width: 768px) {
    .grid {
      height: calc(100vh - 132px);
    }
  }

  @media (min-width: 992px) {
    .grid {
      height: calc(100vh - 148px);
    }
  }

  @media (max-width: 767px) {
    .LeftBlock {
      padding-right: 12px;
    }
  }
</style>
