<script>
  import {Row, Col, Card, CardBody, CardTitle, Progress, Dropdown, DropdownMenu, DropdownToggle} from "sveltestrap";
  import {onMount, onDestroy} from "svelte";
  import {paymentProfiles} from "../../../helpers/store";
  import {_, locale} from "svelte-i18n";
  import {subscribeProcess} from "../../../helpers/subscribe";

  // Pages Components
  import YookassaModal from "../../../Components/Modals/YookassaModal.svelte";
  import {getRates} from "../../../helpers/getRates";
  import AdvanceBlock from "./Advance.svelte";
  import CheckBlock from "./Check.svelte";
  import GuaranteeBlock from "./Guarantee.svelte";
  import TrialBlock from "./Trial.svelte";
  import CorporateInvoiceHostingBtn from "../../../Components/CorporateInvoiceHostingBtn.svelte";

  export let channel;
  export let user;

  const partnerId = "BEBD6pPA";
  const requestedNumber = channel.phone;

  const queryParameters = {
    email: user.email,
    name: user.name,
    state: channel.id,
    // redirect_url: string
    partner: user.id + '_1msg_' + channel.id,
    //next: string
  };
  // console.log('queryParameters', queryParameters)
  let activeStep = !!channel.paymentProfileId || !!channel.noAutoCharge, maxStep = 0;
  let loading, lang, yooModal, subscribeParams, pricing = [], paymentProfile, paymentProfilesLocal;

  const baseUrl = 'https://hub.360dialog.com';

  let permissionUrl = requestedNumber
      ? `${baseUrl}/dashboard/app/${partnerId}/permissions?number=${requestedNumber}`
      : `${baseUrl}/dashboard/app/${partnerId}/permissions`;

  if (queryParameters) {
    let values = Object.values(queryParameters)
    Object.keys(queryParameters).forEach((k, idx) => {
      if (idx === 0 && !requestedNumber) {
        permissionUrl = permissionUrl + `?${k}=${values[idx]}`;
      } else {
        permissionUrl = permissionUrl + `&${k}=${values[idx]}`;
      }
    })
  }

  let blocks = [AdvanceBlock, CheckBlock, GuaranteeBlock, TrialBlock];

  let secondBlock = blocks[user.onboardingType && user.onboardingType < 4 && user.onboardingType || 0];

  const getUrlParameter = (params, name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(params);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  // const callbackAfterPopup = callbackObj => {
  //   console.log(callbackObj);
  // }

  async function updateProfile() {
    if (channel && channel.paymentProfileId) {
      paymentProfile = paymentProfilesLocal.find((item) => {
        return item.id === channel.paymentProfileId
      });
    }
    if (channel.project) {
      pricing = await getRates(channel.project, channel);
      if (!subscribeParams) {
        changePayment(pricing[lang][0].prices[0])
      }
    }
  }

  onMount(async () => {
    if (localStorage.getItem("I18N_LANGUAGE")) {
      lang = localStorage.getItem("I18N_LANGUAGE");
    }
    const params = window.location.search;
    const client = getUrlParameter(params, "client");
    const channels = getUrlParameter(params, "channels");
    const revokedChannels = getUrlParameter(params, 'revoked');

    if (client && channels) {
      const channelsArray = channels.substring(1, channels.length - 1).split(",");

      let callbackObj = {
        client: client,
        channels: channelsArray,
      };

      if (revokedChannels) {
        const revokedChannelsArray = revokedChannels.substring(1, revokedChannels.length - 1).split(',');
        callbackObj['revokedChannels'] = revokedChannelsArray;
      }
      // callbackAfterPopup(callbackObj);

      // remove search parameters from URL after fetching them
      // window.history.replaceState(null, "", window.location.pathname);
    }

    // send to parent (opener) window and close small window
    if (window.opener) {
      window.opener.postMessage(params);
      window.close();
    }
  });

  const unsub = locale.subscribe((value) => {
    if (value) lang = value;
  });

  const unsub2 = paymentProfiles.subscribe(async (value) => {
    paymentProfilesLocal = value;
    paymentProfile = {};
    await updateProfile();
  });

  onDestroy(() => {
    unsub();
    unsub2();
  });

  async function subscribe() {
    if (activeStep || channel.paymentProfileId) return;
    if (!subscribeParams || !subscribeParams.provider) return;
    if (loading) return;
    loading = true;
    let params = Object.assign({
      id: channel.id,
      userToken: user.token,
      currency: subscribeParams.currency,
      rate: subscribeParams.rate,
      org: subscribeParams.org,
      provider: subscribeParams.provider,
      success_url: window.location.href
    });

    let result = await subscribeProcess(params);
    window.dataLayer.push({
      'event': 'onboarding_create_subscription',
      'type': subscribeParams.provider,
      'plan': subscribeParams.rate,
      'currency': subscribeParams.currency
    });

    loading = false;
    if (params.provider === "yookassa" && result) {
      return toggleYoomodal(true, result)
    }
  }

  function toggleYoomodal(active = false, yooToken = false) {
    if (active && yooToken) {
      yooModal = true;
      const checkout = new window.YooMoneyCheckoutWidget({
        confirmation_token: yooToken, //Токен, который перед проведением оплаты нужно получить от ЮKassa
        return_url: `https://my.1msg.io/channels-payments`, //Ссылка на страницу завершения оплаты
        error_callback(error) {
          console.log(error);
          //Обработка ошибок инициализации
        }
      });

      //Отображение платежной формы в контейнере
      setTimeout(async () => {
        checkout.render('yookassa-form');
      }, 300);
    } else {
      yooModal = false;
    }
  }

  function changePayment(activePricing) {
    subscribeParams = {
      provider: activePricing.payment.id,
      rate: activePricing.price.rate,
      org: activePricing.price.org,
      currency: activePricing.price.currency
    }
  }
</script>

{#if lang == 'ru'}
  <Card>
    <CardBody class="p-4 p-sm-5">
      <CardTitle class="h5 text-center font-size-28 d-none d-sm-block">{$_('channel.meta.title')}</CardTitle>
      <CardTitle class="h5 text-center font-size-20 d-sm-none">{$_('channel.meta.title')}</CardTitle>
      <div class="col-md-10 col-lg-7 m-auto">
        <div class="position-relative mx-4 mt-5">
          <Progress value={100 * activeStep} color="light-green" class="fg-light-green" style="height: 2px"/>
          {#if activeStep == 0}
            <button class="position-absolute font-size-20 top-0 start-0 translate-middle btn btn-sm rounded-pill btn-primary"
              style={"width: 37px; height: 37px; line-height: 22px"}>
              1
            </button>
          {:else}
            <button class="position-absolute top-0 start-0 translate-middle double_circles_green">
              <i class="bx bx-check font-size-22"/>
            </button>
          {/if}
          <button class={"position-absolute font-size-20 top-0 start-100 translate-middle btn btn-sm rounded-pill " + (activeStep == 1 ? "btn-primary" : "bg-solitude-grey bg-soft")}
            style={"width: 37px; height: 37px; line-height: 22px"}>
            2
          </button>
        </div>
      </div>
      <div>
        <Row class="mt-2 mt-sm-3 justify-content-between">
          <Col sm={12} md={6}>
            {#if !channel.paymentProfileId}
              <div class="custom-w-75 mt-5 mx-auto bold text-center font-size-16">{@html $_('channel.meta.step0.text1')}</div>
              <div class="mt-4">
                {#if pricing && pricing[lang]}
                  {#each pricing[lang] as payment}
                    {#each payment.prices as pricing}
                      <div class="mb-3 mx-auto d-flex flex-column flex-sm-row align-items-center justify-content-between form-check payment-check {pricing.price.rate == subscribeParams?.rate && pricing.price.org == subscribeParams?.org && 'active'}"
                        on:click={() => changePayment(pricing)}>
                        <div>
                          <sup style="left: 0.25em;">{$_(pricing.price.currency)}</sup>
                          {pricing.price.value}/<span class="font-size-16">{$_(pricing.duration)}</span>
                        </div>
                        <div class="current-logo text-end col-4">
                          <img class="img-fluid" src={pricing.payment.logo} alt="logo">
                        </div>
                      </div>
                    {/each}
                  {/each}
                {/if}
                <div class="mt-5 text-center">
                  <div class="d-inline-flex flex-column gap-2 align-items-center">
                    <button type="button" class="btn btn-lucky-primary w-100" on:click={subscribe} disabled={loading}>
                      {#if loading}
                        <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                      {/if}
                      {$_('subscription.subscribe')}
                    </button>
                    {#if lang === 'ru'}
                      <CorporateInvoiceHostingBtn
                        uid={user.id}
                        rate={subscribeParams?.rate}
                        currency={subscribeParams?.currency}
                        org={subscribeParams?.org}
                        channelId={channel.id}
                        userToken={user.userToken}
                      />
                    {/if}
                  </div>
                  {#if lang === 'ru'}
                    <Dropdown class="mt-3">
                      <DropdownToggle tag="div" class="pointer d-inline-block text-primary text-wrap" caret>
                        <i class="mdi mdi-alert-circle-outline"/> {$_('channel.meta.step0.dropdown2.text1')}
                      </DropdownToggle>
                      <DropdownMenu class="p-3 mt-1">
                        <div class="text-wrap text-muted">
                          {@html $_('channel.meta.step0.dropdown2.text2')}
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  {/if}
                </div>
              </div>
            {:else}
              <div class="custom-w-75 mt-5 mx-auto bold text-center">
                {@html $_('channel.meta.step0.text2')}
              </div>
            {/if}
          </Col>
          <Col sm={12} md={6}>
            <div class="custom-w-75 mt-5 mx-auto bold text-center font-size-16">{@html $_('channel.meta.step1.text1')}</div>
            <div class="custom-w-75 my-4 mx-auto text-center font-size-14">{@html $_('channel.meta.step1.text2')}</div>
            <div class="mt-5 d-flex justify-content-center">
              <button disabled class="btn-meta bg-solitude-grey">{$_('channel.meta.btn')}</button>
            </div>
          </Col>
        </Row>
      </div>
    </CardBody>
  </Card>
{:else}
  <Card class="overflow-hidden">
    <div class="position-absolute bg-lavender d-none d-md-block" style="width: 38%; height: 100%"/>
    <CardBody class="p-4 p-sm-5">
      <CardTitle class="h5 text-center font-size-28 d-none d-sm-block">{$_('channel.meta.title')}</CardTitle>
      <CardTitle class="h5 text-center font-size-20 d-sm-none">{$_('channel.meta.title')}</CardTitle>
      <div class="col-md-10 col-lg-7 m-auto d-none d-sm-block">
        <div class="position-relative mx-4 mt-5"/>
      </div>
      <div>
        <Row class="mt-sm-3 justify-content-between">
          <Col sm={12} md={6}>
            {#if !channel.paymentProfileId}
              <div class="card custom-box-shadow card-sm-border-2 px-sm-4">
                <div class="custom-w-75 mt-3 mt-sm-5 mx-auto bold text-center font-size-16">
                  {#if (pricing?.[lang]?.[0].trial)}
                    {@html $_('channel.meta.step0.text1_trial')}
                  {:else}
                    {@html $_('channel.meta.step0.text1')}
                  {/if}
                </div>
                <div class="mt-4">
                  {#if (pricing && pricing[lang])}
                    {#each pricing[lang] as payment}
                      {#each payment.prices as pricing}
                        <div class="mb-3 mx-auto d-flex flex-column flex-sm-row align-items-center justify-content-between form-check payment-check  {pricing.payment.id == subscribeParams?.provider && pricing.price.rate == subscribeParams?.rate && 'active'}"
                          on:click={() => changePayment(pricing)}>
                          <div>
                            <sup style="left: 0.25em;">{$_(pricing.price.currency)}</sup>
                            {pricing.price.value}/<span class="font-size-16">{$_(pricing.duration)}</span>
                            {#if (payment?.trial)}
                              <span style="color: #a7abb0" class="font-size-12 d-block text-center d-sm-inline-block">
                                ({$_('subscription.trial', {values: {trial: payment?.trial}})})
                              </span>
                            {/if}
                          </div>
                          <div class="current-logo text-end col-3">
                            <img class="img-fluid" src={pricing.payment.logo} alt="logo">
                          </div>
                        </div>
                      {/each}
                    {/each}
                  {/if}
                  <div class="my-4 d-flex justify-content-center">
                    <button on:click={subscribe} class="btn btn-royal-blue px-3 px-sm-5">
                      {#if loading}
                        <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                      {/if}
                      {#if (pricing?.[lang]?.[0]?.trial)}
                        {$_('subscription.start_trial')}
                      {:else}
                        {$_('subscription.subscribe')}
                      {/if}
                    </button>
                  </div>
                </div>
              </div>
            {:else}
              <div class="custom-w-75 mt-5 mx-auto bold text-center">
                {@html $_('channel.meta.step0.text2')}
              </div>
            {/if}
          </Col>
          <svelte:component this={secondBlock}>
            <slot/>
          </svelte:component>
        </Row>
      </div>
    </CardBody>
  </Card>
{/if}

{#if !channel.paymentProfileId}
  {#if yooModal}
    <YookassaModal show={yooModal} {toggleYoomodal}/>
  {/if}
{/if}

<style>
  .vertical-progress {
    position: relative;
    padding-left: 45px;
    list-style: none;
  }
  .vertical-progress::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
  }
  .vertical-progress-item {
    position: relative;
    counter-increment: list;
  }
  .vertical-progress-item:not(:last-child) {
    padding-bottom: 20px;
  }
  .vertical-progress-item::before {
    display: inline-block;
    content: '';
    position: absolute;
    left: -30px;
    height: 100%;
    width: 10px;
  }
  .vertical-progress-item::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: -37px;
    width: 20px;
    height: 20px;
    border: 2px solid #CCC;
    border-radius: 50%;
    background-color: #FFF;
  }
  .vertical-progress-item.is-done::before {
    border-left: 2px solid #23B16D;
  }
  .vertical-progress-item:last-child:before {
    border-left: none!important;
  }
  .vertical-progress-item.is-done::after {
    content: counter(list);
    padding: 1px;
    width: 20px;
    height: 20px;
    top: -3px;
    left: -40px;
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    color: white;
    border: 2px solid #23B16D;
    background-color: #23B16D;
  }
  .vertical-progress-item.current::before {
    border-left: 2px solid #23B16D;
  }
  .vertical-progress-item.current::after {
    content: counter(list);
    padding-top: 1px;
    width: 25px;
    height: 25px;
    top: -4px;
    left: -40px;
    font-size: 14px;
    text-align: center;
    color: #23B16D;
    border: 2px solid #23B16D;
    background-color: white;
  }

  .check-bg{
    position: absolute;
    height: 160px;
    bottom: 50px;
    right: -60px;
    opacity: 0.2;
  }

  .custom-w-75 {
    width: 75%;
  }

  @media (max-width: 576px) {
    .custom-w-75 {
      width: 100%;
    }
  }

  @media (min-width: 576px) {
    .custom-box-shadow {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16) !important;
    }
  }
</style>
