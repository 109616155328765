<script>
  import {Card, CardBody, Col, Container, Row, Input} from "sveltestrap";
  import {_} from 'svelte-i18n';
  import {onDestroy} from "svelte";

  import {projects, channels, paymentProfiles, addChannelFirstTimeHelper} from "../../helpers/store";
  import ChannelsTable from "../../Components/Table/ChannelsTable.svelte";
  import ChannelsAddModal from "../../Components/Modals/ChannelsAddModal.svelte";

  let projectsList, channelsList, noChannels, showModal = false, profiles
  let searchText = '';

  const unsub1 = projects.subscribe(value => {
    projectsList = value;
  });

  const unsub2 = channels.subscribe(value => {
    noChannels = !value?.length;
    channelsList = Array.isArray(value) && value.length ? value.reduce(
      (result, item) => {
        if (!result[item.projectId]) result[item.projectId] = []
        result[item.projectId].push(item);
        return result;
      },
      {}
    ) : {};
  });

  const unsub3 = paymentProfiles.subscribe(value => {
    if(value) {
      let tmp = {};
      for (let profile of value) {
        tmp[profile.id] = profile;
      }
      profiles = tmp;
    }
  });

  const setShowModal = status => {
    showModal = status;
  };

  onDestroy(() => {
    unsub1();
    unsub2();
    unsub3();
  });
</script>

<div class="page-content px-0 px-sm-2">
    <Container fluid class="px-0 px-md-2">
        {#if noChannels}
            <Row class="justify-content-center align-items-center" style="height: calc(100vh - 94px - 60px);">
                <Col md={8} xl={6} xxl={5}>
                    <Card>
                        <CardBody>
                            <div class="p-4">
                                <div class="text-center">
                                    <div class="avatar-md mx-auto">
                                        <img src="/assets/images/rocket.svg" alt="rocket launch"/>
                                    </div>
                                    <div class="p-2 mt-4">
                                        <h4>{$_("channels.no_channels.title")}</h4>
                                        <p class="mb-4">{@html $_("channels.no_channels.text")}</p>
                                        <button type="button" class="btn btn-success btn-lg waves-effect waves-light"
                                            on:click={() => addChannelFirstTimeHelper(() => setShowModal(true))}>
                                            <i class="mdi mdi-plus me-1"/>
                                            {$_('channels.add_channel')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {#if projectsList?.length}
                <ChannelsAddModal project={projectsList[0]} show={showModal} onCloseClick={() => setShowModal(false)}/>
            {/if}
        {:else}
            <Card>
                <CardBody>
                    <div class="table-search">
                        <Input type="text" bind:value={searchText} placeholder={$_('search')}/>
                    </div>
                    {#if projectsList}
                        {#each projectsList as project}
                            {#if channelsList[project.id] && channelsList[project.id].length}
                                {#if channelsList[project.id]}
                                    <div class="mb-5">
                                        <ChannelsTable searchText={searchText} showSearch={false} {project} {profiles} channels={channelsList[project.id]}/>
                                    </div>
                                {/if}
                            {/if}
                        {/each}
                    {/if}
                </CardBody>
            </Card>
        {/if}
    </Container>
</div>
