<script>
  import {eventTracking} from "../../../../helpers/utils";
  import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Button, Tooltip} from "sveltestrap";
  import {_} from "svelte-i18n";
  import SelectDropdownUsers from "../../../../Components/SelectDropdownUsers.svelte";
  import MessageChat from "./MessageChat.svelte";
  import MessageChatForm from "./MessageChatForm.svelte";
  import ModalWindowOpenChatWindow from "./elements/Modal/ModalWindowOpenChatWindow.svelte";
  import MessagesDAO from "../../../../helpers/onetouch/MessagesDAO";
  import ScheduledMessagesDAO from "../../../../helpers/onetouch/ScheduledMessagesDAO";
  import {BotUsersDAO} from "../../../../helpers/onetouch/BotUsersDAO";
  import {chosenChannel, appClient, user} from "../../../../helpers/store";
  import {onDestroy, onMount} from "svelte";
  import {Loader} from "google-maps";
  import {layout} from "../../../../services/loaders";
  import {addToast} from "../../../../helpers/toast";

  export let blockedChatsData;
  export let changeChosenChat;
  export let chosenChat;
  export let activeOperators;
  export let activeOperator;
  export let toggleUserCard;
  export let windowStore;
  export let chatsGetter;
  export let dataReplyMessage;
  export let replyMessage;
  export let isCloud;
  let messageGetter, chosenChannelLocal, chosenChatLocal, messages, messagesLoading, unsubChosenChat,
    unsubChosenChannel, scheduledMessageGetter, scheduledMessages, scheduledMessagesLoading;

  $: chosenChat && subToChosenChat();
  $: chosenChannel && subToChosenChannel();
  $: checkBlock = blockedChatsData.length && blockedChatsData.find(item => item.bot_user_id === $chosenChat.bot_user_id);
  $: listOperators = $activeOperators.length ? $activeOperators.map(({profile}) => ({
    id: profile.id,
    avatar_url: profile.avatar_url,
    name: profile.user.username
  })) : [];
  $: if (activeOperator && activeOperator.type !== "shop_administrator" && $chosenChat.assigned_to !== null && $chosenChat.assigned_to !== activeOperator.profile.id && $chosenChat.is_thread_open === true) {
    chosenChat.set({})
  }

  onMount(async () => {
    const loader = new Loader('AIzaSyBRvzTEpqsPqBPROdVuqkPxGTlOTjnPTHw');
    await loader.load();
  });

  onDestroy(() => {
    unsubChosenChat();
    unsubChosenChannel();
    messageGetter.clearMessages();
    messageGetter.stopListeningForNewMessages();
    scheduledMessageGetter.clearMessages();
    scheduledMessageGetter.stopListeningForNewMessages();
  });


  function checkChat() {
    if (chosenChannelLocal && chosenChatLocal && (!messageGetter || messageGetter.chat.id !== chosenChatLocal?.id || chosenChatLocal?.uniqueKey !== messageGetter.chat?.uniqueKey)) {
      messageGetter && messageGetter.stopListeningForNewMessages();
      messageGetter = new MessagesDAO(chosenChatLocal);
      messages = messageGetter.messagesStore;
      messagesLoading = messageGetter.messagesloading;
      messageGetter.startListeningForNewMessages(chosenChannelLocal);
    }

    if (chosenChannelLocal && chosenChatLocal && (!scheduledMessageGetter || scheduledMessageGetter.chat.id !== chosenChatLocal?.id || chosenChatLocal?.uniqueKey !== scheduledMessageGetter.chat?.uniqueKey)) {
      scheduledMessageGetter && scheduledMessageGetter.stopListeningForNewMessages();
      scheduledMessageGetter = new ScheduledMessagesDAO(chosenChatLocal);
      scheduledMessages = scheduledMessageGetter.messagesStore;
      scheduledMessagesLoading = scheduledMessageGetter.messagesloading;
      scheduledMessageGetter.startListeningForNewMessages(chosenChannelLocal);
    }
  }

  function subToChosenChat() {
    unsubChosenChat && unsubChosenChat();
    unsubChosenChat = chosenChat.subscribe(
    /**
     *
     * @param {Chat|ChatMessageSearch} value
     * @returns {Promise<void>}
     */
    async (value) => {
      const _preChangedValue = chosenChatLocal;
      chosenChatLocal = value
      if (_preChangedValue?.id !== value?.id || _preChangedValue?.uniqueKey !== value?.uniqueKey) {
        if (messageGetter) {
          messageGetter.clearMessages()
          messageGetter.stopListeningForNewMessages()
        }
        if (scheduledMessageGetter) {
          scheduledMessageGetter.clearMessages()
          scheduledMessageGetter.stopListeningForNewMessages()
        }
        if (Object.keys(value).length !== 0) {
          checkChat()
        }
      }
    });
  }

  function subToChosenChannel() {
    unsubChosenChannel && unsubChosenChannel()
    unsubChosenChannel = chosenChannel.subscribe(async (value) => {

      if (value === null || value === "") {
        chosenChannelLocal = null;
      } else {
        if (chosenChannelLocal?.id !== value?.id) {
          chosenChannelLocal = value;
          checkChat();
        }
      }
    });
  }

  const blockOrUnblock = () => {
    layout(true)
    if (checkBlock) {
      chatsGetter.unBlockUser($chosenChat.bot_user_id, chosenChannelLocal).then(data => {
        layout(false)
      }, err => {
        console.error(err);
        layout(false)
      });
    } else {
      chatsGetter.blockUser($chosenChat.bot_user_id, chosenChannelLocal).then(data => {
        layout(false)
      }, err => {
        console.error(err);
        layout(false)
      });
    }
  };

  const deleteUser = () => {
    layout(true)
    BotUsersDAO.delete($chosenChat.bot_user_id).then(() => {
      layout(false)
    }, err => {
      console.error(err);
      layout(false)
    });
  };

  const operatorAssignment = (assigned_to) => {
    layout(true)
    appClient.patchBotChatUpdate($chosenChat.id, {assigned_to}).then(data => {
      if (data.status === "error") {
        addToast({
          title: $_("error"),
          message: data.errors[0] || $_("something_went_wrong")
        });
      } else {
        eventTracking('inbox_triggered_action_chat', {action_type: 'change_operator'});
      }
      layout(false)
    }, err => {
      console.error(err);
      addToast({
        title: $_("error"),
        message: $_("something_went_wrong")
      });
      layout(false)
    });
  };

  const closeChat = () => {
    appClient.patchBotChatUpdate($chosenChat.id, {is_thread_open: false}).then(() => {
      eventTracking('inbox_triggered_action_chat', {action_type: 'conversation_resolved'});
    }, err => {
      console.error(err);
    });
    if ($user.userHintsHintInbox_7 === false) {
      $user.userHintsHintInbox_7 = true;
    }
  };

  const clickSelectUsers = () => {
    if ($user.userHintsHintInbox_6 === false) {
      $user.userHintsHintInbox_6 = true;
    }
  };

  const clickDotsHorizontal = () => {
    if ($user.userHintsHintInbox_8 === false) {
      $user.userHintsHintInbox_8 = true;
    }
  };
</script>

<div class="card mb-0 overflow-hidden MessageChatParent">
  <div class="p-3 border-bottom">
    <Row class="align-items-center">
      <div class="col-5 UserName">
        <h5 class="font-size-16 fw-600 text-dark mb-1 text-truncate">
          {#if $chosenChat.name}
            {$chosenChat.name}
          {:else}
            &nbsp;
          {/if}
        </h5>
        <p class="font-size-14 text-muted mb-0 lh-1 text-truncate">
          {#if $chosenChat.bot_user?.messenger_user_id}
            {$chosenChat.bot_user?.messenger_user_id}
          {:else}
            &nbsp;
          {/if}
        </p>
      </div>
      <div class="col ClockChat gap-3">
        <Button color="light" class="p-0 shadow-none rounded-circle font-size-28 d-lg-none ChatInbox-roundButton"
          on:click={() => changeChosenChat(null)}>
          <i class='bx bx-chevron-left'/>
        </Button>
        <ModalWindowOpenChatWindow {chosenChat} {windowStore}/>
      </div>
      <div class="col col-md-10 col-xxl-5">
        <ul class="list-inline mb-0 d-flex align-items-center justify-content-end">
          <li class="list-inline-item">
            <SelectDropdownUsers
                activeUserId={$chosenChat.assigned_to}
                change={operatorAssignment}
                disabled={activeOperator?.type !== "shop_administrator" && !$chosenChat.is_thread_open}
                title="SelectDropdownUsers.unassigned"
                usersList={listOperators}
                clickDropdownToggle={clickSelectUsers}/>
          </li>
          {#if $chosenChat.assigned_to}
            <li class="list-inline-item d-none d-md-inline-block">
              <Button color="light" class="p-0 shadow-none rounded-circle ChatInbox-roundButton"
                id="chosen-chat-solved"
                disabled={!$chosenChat.is_thread_open}
                on:click={closeChat}>
                <i class="bx bx-check font-size-28"/>
              </Button>
              <Tooltip target="chosen-chat-solved" placement="left">
                {$_('chat_inbox.MessageChat.solved')}
              </Tooltip>
            </li>
          {/if}
          <li class="list-inline-item me-0">
            <Dropdown>
              <DropdownToggle caret class="p-0 shadow-none rounded-circle font-size-28 ChatInbox-roundButton"
                color="light" on:click={clickDotsHorizontal}>
                <i class="bx bx-dots-horizontal-rounded"/>
              </DropdownToggle>
              <DropdownMenu end>
                {#if $chosenChat.assigned_to}
                  <DropdownItem disabled={!$chosenChat.is_thread_open} on:click={closeChat}
                    class="d-flex d-md-none align-items-center gap-2 px-3 font-size-16">
                    <i class="bx bx-check font-size-18 ColorIcon"/> {$_('chat_inbox.MessageChat.solved')}
                  </DropdownItem>
                {/if}
                <DropdownItem on:click={toggleUserCard}
                  class="d-flex d-sm-none align-items-center gap-2 px-3 font-size-16">
                  <i class="bx bxs-user-detail font-size-18 ColorIcon"/> {$_('chat_inbox.MessageChat.info')}
                </DropdownItem>
                <DropdownItem on:click={blockOrUnblock}
                  class="d-flex align-items-center gap-2 px-3 font-size-16">
                  {#if checkBlock}
                    <i class="bx bx-lock-open-alt font-size-18 ColorIcon"/> {$_('chat_inbox.MessageChat.unblock_user')}
                  {:else}
                    <i class="bx bx-block font-size-18 ColorIcon"/> {$_('chat_inbox.MessageChat.block_user')}
                  {/if}
                </DropdownItem>
                <DropdownItem on:click={deleteUser}
                  class="d-flex align-items-center gap-2 px-3 font-size-16">
                  <i class="bx bx-trash font-size-18 ColorIcon"/> {$_('delete')}
                </DropdownItem>
                <!-- <DropdownItem class="d-flex align-items-center gap-2 px-3 font-size-16">
                  <i class="bx bx-star font-size-18 ColorIcon"/> {$_('chat_inbox.MessageChat.mark_as_favorite')}
                </DropdownItem> -->
              </DropdownMenu>
            </Dropdown>
          </li>
          <li class="list-inline-item ms-2 UserDetail">
            <Button class="p-0 shadow-none rounded-circle font-size-22 ChatInbox-roundButton"
              color="light" on:click={toggleUserCard}>
              <i class="bx bxs-user-detail"/>
            </Button>
          </li>
        </ul>
      </div>
    </Row>
  </div>
  <MessageChat {activeOperators} {chosenChat} {messageGetter} {messages} {messagesLoading}
    {scheduledMessageGetter} {scheduledMessages} {scheduledMessagesLoading} {replyMessage} {isCloud}/>
  <MessageChatForm {activeOperator} {chosenChannelLocal} {chosenChat} {messageGetter} {scheduledMessageGetter} {dataReplyMessage} {replyMessage}/>
</div>

<style>
  .MessageChatParent {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0;
  }

  .MessageChatParent .ColorIcon {
    color: #64748b;
  }

  .MessageChatParent .ClockChat {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 1650px) {
    .MessageChatParent .UserDetail {
      display: none;
    }
  }

  @media screen and (max-width: 1500px) {
    .MessageChatParent .UserName {
      display: none;
    }

    .MessageChatParent .ClockChat {
      justify-content: flex-start;
    }
  }

  @media (max-width: 576px) {
    .MessageChatParent .UserDetail {
      display: none;
    }
  }
</style>
