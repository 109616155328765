<script>
  import {
    Container,
    Row,
    Col,
    Card,
  } from "sveltestrap";
  import {_} from "svelte-i18n";
  import {navigate} from "svelte-routing";
  import {trialBar} from "../../helpers/store.js";

</script>

<div class="page-content BotLibrary px-0 px-sm-2">
  <Container fluid>
    <div class="font-size-20 fw-600 mb-4">{$_('documentation.title')}</div>
    <div class="bg-lavender text-primary w-75 p-2 card font-size-18 mb-4">{$_('documentation.posttitle')}</div>
    <Row>

      <Col lg={6} md={6} xl={4} xxl={4}>
        <Card on:click={()=>{navigate('/documentation/1msg')}}>
          <div class="card-body d-flex flex-column justify-content-between pointer" >
            <div>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div class="d-flex align-items-center gap-3 mb-3 ">
                <img alt="bot icon"
                     class=" w-25 IconBot" src="/assets/images/logo-1msg-dark-mini.svg"/>
                <div class="overflow-hidden">
                  <div class="fw-600 font-size-20 ">1Msg enhanced</div>
                </div>
              </div>
              <div class="ps-2 pt-3 d-flex flex-row  ">
                <div><i class="bx bx-plus lucky-green-fg font-size-20"/></div>
                <div class=" ps-2 text-muted mb-2  lh-1_8 ">
                  {@html $_('documentation.1msg_descr')}
                </div>
              </div>
              <div class="ps-2 pt-2 d-flex flex-row ">
                <div><i class="bx bx-minus font-size-20 minus"/></div>
                <div class=" ps-2 text-muted mb-2  lh-1_8 ">
                  {@html $_('documentation.1msg_minus')}
                </div>
              </div>
              <div class="text-center pt-3">
                <button class="btn   btn-outline-success w-50 " type="submit"><span
                    class="text-break ">
                                   {$_('documentation.read')}

                </span></button>
              </div>

            </div>

          </div>
        </Card>
      </Col>
      <Col lg={6} md={6} xl={4} xxl={4}>
        <Card  on:click={()=>{navigate('/documentation/meta')}}>
          <div class="card-body d-flex flex-column justify-content-between pointer">
            <div>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div class="d-flex align-items-center gap-3 mb-3 ">
                <img alt="bot icon"
                     class="rounded-circle w-25 IconBot" src="/assets/images/facebook.svg"/>
                <div class="overflow-hidden">
                  <div class="fw-600 font-size-20 ">Meta powered
                    {#if $trialBar.show}
                      <div>
                        ({$_('documentation.meta_title_demo')})</div>
                    {/if}
                  </div>
                </div>
              </div>
              <div class="ps-2 pt-3 d-flex flex-row  ">
                <div><i class="bx bx-plus lucky-green-fg font-size-20"/></div>
                <div class=" ps-2 text-muted mb-2 lh-1_8">
                  {@html $_('documentation.meta_descr')}
                </div>
              </div>
              <div class="ps-2 pt-2 d-flex flex-row ">
                <div><i class="bx bx-minus font-size-20 minus"/></div>
                <div class=" ps-2 text-muted mb-2 lh-1_8 ">
                  {@html $_('documentation.meta_minus')}
                </div>
              </div>
            </div>
            <div class="text-center pt-3">
              <button class="btn   btn-outline-success w-50 " type="submit"><span
                  class="text-break ">
                 {$_('documentation.read')}
                </span></button>
            </div>
          </div>
        </Card>
      </Col>


    </Row>

  </Container>
</div>
<style>
  .IconBot{
      max-width: 80px;
      max-height: 80px;
  }
  .lh-1_8 {
      line-height: 1.8;
  }
  .minus{
      color: #bf4848;
  }
</style>
