<script>
  import javaCode from "./Components/javaCode.svelte";
  import javascriptCode from "./Components/javascriptCode.svelte";
  import typescriptCode from "./Components/typescriptCode.svelte";
  import pythonCode from "./Components/pythonCode.svelte";
  import phpCode from "./Components/phpCode.svelte";
  import cCode from "./Components/cCode.svelte";

  export let key;

  const arr = [
    {key: 'java', component: javaCode},
    {key: 'javascript', component: javascriptCode},
    {key: 'typescript', component: typescriptCode},
    {key: 'python', component: pythonCode},
    {key: 'php', component: phpCode},
    {key: 'c', component: cCode},
    {key: 'c++', component: cCode},
    {key: 'c-sharp', component: cCode},
  ];
</script>

<div>
  <svelte:component this={arr?.find(val => val.key === key)?.component} {key}/>
</div>