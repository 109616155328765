<script>
  import MessageInfo from "../MessageInfo.svelte";
  import Text from "./Text.svelte";

  export let message;
  export let direction;
  export let scheduled = false;
  export let liteVersio = false;
</script>

<div class={!liteVersio ? "p-1" : ""}>
  <a href={message.file_url} target="_blank" rel="noopener noreferrer" download class="{!liteVersio ? "File" : "text-muted"} d-flex align-items-center gap-2 rounded-3">
    {#if !liteVersio}
      <svg min-width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="white" stroke="#D2D6D1" strokeWidth="0.4"
          d="M0.2 1.5C0.2 0.78203 0.78203 0.2 1.5 0.2H15.4798C15.824 0.2 16.1542 0.336514 16.3979 0.579603L21.4181 5.58717C21.6626 5.83106 21.8 6.16222 21.8 6.50757V25.5C21.8 26.218 21.218 26.8 20.5 26.8H1.5C0.78203 26.8 0.2 26.218 0.2 25.5V1.5Z"/>
        <mask id="path-2-inside-1_162_12547" fill="white">
          <path fillRule="evenodd" clipRule="evenodd"
            d="M22 6.49999H17C16.1716 6.49999 15.5 5.82841 15.5 4.99999V0.00012207C15.8899 0.00536923 16.2627 0.162264 16.5391 0.437989L21.5593 5.44556C21.8397 5.72519 21.998 6.10424 22 6.49999Z"/>
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" fill="#EFEFEF"
          d="M22 6.49999H17C16.1716 6.49999 15.5 5.82841 15.5 4.99999V0.00012207C15.8899 0.00536923 16.2627 0.162264 16.5391 0.437989L21.5593 5.44556C21.8397 5.72519 21.998 6.10424 22 6.49999Z"/>
        <path fill="#D2D6D1" mask="url(#path-2-inside-1_162_12547)"
          d="M22 6.49999V6.89999H22.402L22.4 6.49797L22 6.49999ZM15.5 0.00012207L15.5054 -0.399842L15.1 -0.405298V0.00012207H15.5ZM16.5391 0.437989L16.2566 0.721189L16.5391 0.437989ZM21.5593 5.44556L21.2768 5.72876L21.5593 5.44556ZM22 6.09999H17V6.89999H22V6.09999ZM17 6.09999C16.3925 6.09999 15.9 5.6075 15.9 4.99999H15.1C15.1 6.04933 15.9507 6.89999 17 6.89999V6.09999ZM15.9 4.99999V0.00012207H15.1V4.99999H15.9ZM15.4946 0.400086C15.7805 0.403934 16.0539 0.518991 16.2566 0.721189L16.8216 0.15479C16.4715 -0.194463 15.9992 -0.393195 15.5054 -0.399842L15.4946 0.400086ZM16.2566 0.721189L21.2768 5.72876L21.8418 5.16236L16.8216 0.15479L16.2566 0.721189ZM21.2768 5.72876C21.4824 5.93382 21.5985 6.21179 21.6 6.502L22.4 6.49797C22.3974 5.99669 22.1969 5.51656 21.8418 5.16236L21.2768 5.72876Z"/>
        <path d="M8.59998 9.1V9.9H15.9V9.1H8.59998Z" fill="#007AFF"/>
        <path d="M6.09998 18.1V18.9H13.4V18.1H6.09998Z" fill="#007AFF"/>
        <path d="M6.09998 12.1V12.9H15.9V12.1H6.09998Z" fill="#007AFF"/>
        <path d="M6.09998 15.1V15.9H15.9V15.1H6.09998Z" fill="#007AFF"/>
      </svg>
    {:else}
      <i class="bx bx-file font-size-22"/>
    {/if}
    <span class="text-truncate">{decodeURI(message.file_url.split('/').pop())}</span>
  </a>
  {#if !message.text}
    <div class="mt-1">
      <MessageInfo {message} {direction} {scheduled} classes={liteVersio ? "pe-0" : ""}/>
    </div>
  {/if}
</div>
{#if message.text}
  <Text {message} {direction} {scheduled} {liteVersio} classes={!liteVersio ? "pt-0" : "pt-2"}/>
{/if}

<style>
  a.File {
    padding: 7px;
    color: #495057;
    background-color: rgba(255, 255, 255, 0.2);
  }

  a svg {
    min-width: 22px;
  }
</style>
