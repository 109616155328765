<script>
    import Carousel from 'svelte-carousel';
    import {Col} from "sveltestrap";
    import {_} from "svelte-i18n";

    export let text;
</script>

<Col xl={7}>
    <div class="auth-full-bg">
        <div class="w-100">
            <div class="bg-overlay mx-auto relative overflow-hidden">
                <!-- <div class="ellipse">
                </div> -->
                <div class="d-flex h-100 flex-column justify-content-center">
                    <div class="text-container mx-auto row justify-content-center relative">
                        <div class="col-lg-12">
                            <div class="text-center">
                                <h1 class="mb-3 lucky-green-fg display-5 fw-bolder">
                                    {$_(text +'.features.title')}
                                </h1>
                                <div class="fs-5">
                                    {@html $_(text +'.features.text')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</Col>