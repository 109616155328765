<script>
  import {Modal, ModalBody, Button, Spinner} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import {AudioPlayer} from 'svelte-audio-player';
  import {addToast} from "../../../../../../helpers/toast";
  import {appClient, user} from "../../../../../../helpers/store";
  import SpinnerComponent from "../../../../../../Components/Spinners/SpinnerComponent.svelte";
  import TextAreaAutosize from "../../../../../../Components/TextAreaAutosize.svelte";
  import AudioFile from "./Components/AudioFile.svelte";
  import ModalScheduled from "./ModalScheduled.svelte";

  export let chosenChat;
  export let messageGetter;
  export let scheduledMessageGetter;
  export let file;
  export let dataReplyMessage;
  export let replyMessage;

  let fileCDN;

  let chosenChatLocal;
  let isOpen = false;
  let ModalScheduledIsOpen = false;

  let uploadingFile = false;
  let sendingMessage = false;

  let isImage, isVideo, isAudio = false;
  let text = '';

  const unsub2 = chosenChat.subscribe(value => {
    chosenChatLocal = value
  });

  const dropFile = () => {
    file = null;
    fileCDN = null;
    text = '';
    isImage = isVideo = isAudio = false;
  };

  const closeModal = () => {
    isOpen = uploadingFile = sendingMessage = false;
    dropFile();
  };

  const checkFile = () => {
    isImage = file.type.match('image.*');
    isVideo = file.type.match('video.*');
    isAudio = file.type.match('audio.*');

    if (isImage && ['image/png', 'image/jpeg', 'image/gif'].indexOf(file.type) === -1) {
      addToast({title: $_("chat_inbox.MessageChat.error.choose_image")});
      closeModal();
      return false
    } else if (isVideo && ['video/mp4', 'image/3gpp'].indexOf(file.type) === -1) {
      addToast({title: $_("chat_inbox.MessageChat.error.choose_video")});
      closeModal();
      return false
    }
    return true
  };

  const uploadFile = () => {
    let formData = new FormData();
    formData.append('file', file);
    uploadingFile = true;
    appClient.uploadToCdn(file.name, formData).then(
      ({result}) => {
        uploadingFile = false;
        if (!result.error && result.cdn_filepath) {
          fileCDN = result.cdn_filepath;
        } else {
          addToast({title: $_("chat_inbox.MessageChat.error.failed_to_upload")});
        }
      }, err => {
        uploadingFile = false;
      }
    )
    // postRequestDjango(`/cdn/upload/${file.name}/`, formData, {timeout: 30000}).then(
    //   },
    //   () => {
    //   },
    // );
  };

  const sendFile = () => {
    if (!sendingMessage) {
      sendingMessage = true;
      const data = {
        text: text ?? '',
      };
      const type = isImage && 'image' || isVideo && 'video' || isAudio && 'voice' || 'file';
      const fileType = isImage && 'image' || isVideo && 'video' || 'file';
      data[`${fileType}_url`] = fileCDN;

      messageGetter.sendFileMessage(data, type, dataReplyMessage?.message?.id_in_messenger);
      replyMessage(null);
      if ($user.userHintsHintInbox_2 === false) {
        $user.userHintsHintInbox_2 = true;
      }
      closeModal();
    }
  };

  const sendScheduledFile = (startsAt) => {
    if (!sendingMessage) {
      sendingMessage = true;
      const data = {
        text: text ?? '',
      };
      const type = isImage && 'image' || isVideo && 'video' || isAudio && 'voice' || 'file';
      const fileType = isImage && 'image' || isVideo && 'video' || 'file';
      data[`${fileType}_url`] = fileCDN;

      scheduledMessageGetter.sendFileMessage(data, type, startsAt);
      closeModal();
    }
  };

  $: if (file) {
    if (checkFile()) {
      isOpen = true;
      uploadFile()
    }
  }

  onDestroy(() => {
    unsub2();
    closeModal()
  });
</script>

<Modal isOpen={isOpen} fade={true} toggle={closeModal} size="md" centered>
  <ModalBody>
    <button type="button" class="btn-close float-end mb-1" aria-label="Close" on:click={closeModal}/>
    <div class="mb-3">
      {#if uploadingFile}
        <div class="d-flex align-items-center justify-content-center w-100 fileWrapper">
          <SpinnerComponent/>
        </div>
      {:else}
        {#if isImage}
          <div class="d-flex align-items-center justify-content-center w-100 fileWrapper">
            <img src={fileCDN} alt="img" class="d-block Imgfile"/>
          </div>
        {:else if isAudio}
          <div class="w-100">
            <AudioPlayer src={fileCDN}>
              <AudioFile/>
            </AudioPlayer>
          </div>
        {:else if isVideo}
          <div class="d-flex align-items-center justify-content-center w-100 fileWrapper">
            <video class="d-block Videofile" controls>
              <source src={fileCDN}/>
              <track kind="captions">
            </video>
          </div>
        {:else}
          <div class="d-flex align-items-center justify-content-center w-100 fileWrapper">
            <i class="bx bx-file-blank"/>
          </div>
        {/if}
      {/if}
    </div>
    {#if !uploadingFile}
      {#if isImage}
        <div class="w-100 rounded-2 wrapper mb-3">
          <div class="overflow-auto px-1 textarea-wrapper">
            <TextAreaAutosize
              on:enterpress={sendFile}
              bind:value={text}
              disabled={!chosenChatLocal?.id || uploadingFile || sendingMessage}
              placeholder={$_('chat_inbox.MessageChat.placeholder_message_reply')}
              minRows={2}
              classes="border-0 rounded-0 p-0 bg-transparent"
            />
          </div>
        </div>
      {/if}
      <div class="d-flex flex-wrap gap-2">
        <Button color="success" class="d-flex align-items-center gap-2 px-3"
          on:click={sendFile}
          disabled={!chosenChatLocal?.id || uploadingFile || sendingMessage}>
          <span class="font-size-18 d-none d-sm-inline-block">{$_('send')}</span>
          {#if sendingMessage}
            <Spinner size="sm"/>
          {:else}
            <i class="mdi mdi-send font-size-18 lh-1 d-inline-block d-sm-none"/>
            <i class="mdi mdi-send font-size-22 lh-1 d-none d-sm-inline-block"/>
          {/if}
        </Button>
        <Button color="success" outline class="px-3 text-uppercase d-flex align-items-center"
          on:click={() => {
            ModalScheduledIsOpen = true
            isOpen = false
          }}>
          <span class="font-size-13 d-none d-sm-inline-block">{$_('scheduled.schedule')}</span>
          <i class="bx bx-hourglass font-size-18 lh-1 d-inline-block d-sm-none"/>
        </Button>
      </div>
    {/if}
  </ModalBody>
</Modal>

<ModalScheduled send={sendScheduledFile} bind:isOpen={ModalScheduledIsOpen} callback={() => isOpen = true}/>

<style>
  .wrapper {
    padding: 9px 11px;
    background-color: #EFF2F7;
  }

  .textarea-wrapper {
    max-height: 200px;
  }

  .textarea-wrapper::-webkit-scrollbar {
    width: 0;
  }

  .btn-close {
    margin-top: -10px;
    margin-right: -10px;
  }

  .bx.bx-file-blank {
    font-size: 5rem;
  }

  .fileWrapper {
    min-height: 300px;
  }

  .Imgfile {
    max-width: 100%;
    max-height: 450px;
  }

  .Videofile {
    max-width: 100%;
    max-height: 450px;
  }
</style>
