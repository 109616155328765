<script>
  import {Button, Card, CardBody, Row, Col, Table, Spinner} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import {navigate} from 'svelte-routing';
  import {layout} from "../../services/loaders";
  import {chosenChannel} from "../../helpers/store";
  import {addToast} from "../../helpers/toast";
  import {BotUsersDAO, botUsersStore, setChosenBotUserId, getExportUsers} from "../../helpers/onetouch/BotUsersDAO";
  import {TagsDAO} from "../../helpers/onetouch/TagsDAO";
  import NoChannelsWrapper from "../../Components/NoChannelsWrapper.svelte";
  import ChooseTheChannel from "../../Components/Modals/ChooseTheChannelModal.svelte";
  import SelectDropdownFilter from "../../Components/SelectDropdownFilter.svelte";
  import ImportBotUsersModal from "../../Components/ImportBotUsersModal.svelte";
  import SpinnerComponent from "../../Components/Spinners/SpinnerComponent.svelte";
  import AddNewContactModal from "../../Components/AddNewContactModal.svelte";
  import DeleteBotUsersModal from "../../Components/DeleteBotUsersModal.svelte";

  let botUsersTags = null;
  let botUsersMap = null;
  let search = '';
  let botUsersLoader = false;
  let isOpenModalImportBotUsers = false;
  let isOpenModalAddNewContact = false;
  let isOpenModalDeleteBotUsers = false;
  let addAllBotUser = false;
  let selectedBotUsers = [];
  let chosenChannelLocal = null;
  let filterDate = '';
  let loadingExportUsers = false;

  const unsub2 = chosenChannel.subscribe(value => {
    if (value === null || value === "") {
      chosenChannelLocal = null;
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        botUsersTags = null;
        search = '';
        addAllBotUser = false;
        chosenChannelLocal = value
        layout(true);
        TagsDAO.getOrLoadAll().then(data => {
          botUsersTags = data.map(item => ({...item, checked: false}));
          const newMap = new Map();
          data.forEach(item => {
            newMap.set(item.id, item);
          });
          botUsersMap = newMap
        });
        BotUsersDAO.getOrLoadAll().then(() => layout(false));
      }
    }
  });

  onDestroy(() => {
    unsub2();
  });

  const formatDate = (data) => {
    return data ? data.slice(0, data.lastIndexOf('.')) : ''
  };

  const onSearch = () => {
    return $botUsersStore.filter(item => {
      if (search) {
        if (!item.name.toLowerCase().includes(search.toLowerCase()) && !item.messenger_user_id.toLowerCase().includes(search.toLowerCase())) {
          return false;
        }
      }
      if (filterDate) {
        if (item.created_at.slice(0, item.created_at.lastIndexOf(' ')) !== filterDate) {
          return false;
        }
      }
      return botUsersTags?.filter(({checked}) => checked === true).every(tag => item.store.tags.some(storeTag => storeTag.id === tag.id))
    });
  };

  const addBotUserId = (id) => {
    const i = selectedBotUsers.findIndex(botUserId => botUserId === id);
    if (i != -1) {
      selectedBotUsers = selectedBotUsers.filter((val, index) => index !== i)
      return;
    }
    selectedBotUsers = [...selectedBotUsers, id];
  };

  const addAllBotUserId = () => {
    if (addAllBotUser) {
      selectedBotUsers = botUsersList.map(({id}) => id);
    } else {
      selectedBotUsers = [];
    }
  };

  const checkSelectedBotUsers = () => {
    if (!botUsersList.length) {
      addAllBotUser = false;
      return;
    }
    if (selectedBotUsers.length === botUsersList.length) {
      addAllBotUser = botUsersList.every((user) => selectedBotUsers.find((id) => id === user.id));
    } else {
      addAllBotUser = false;
    }
  };

  $: botUsersList = (search || filterDate || botUsersTags?.find(({checked}) => checked === true)) ? onSearch() : $botUsersStore;
  $: botUsersList && checkSelectedBotUsers();

  function exportBotUsers() {
    loadingExportUsers = true;
    getExportUsers(chosenChannelLocal).then(res => {
      loadingExportUsers = false;
      if (res.ok) {
        res.blob().then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `contacts_${chosenChannelLocal.id}`;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
      } else {
        addToast({
          title: $_("error"),
          message: res.error || $_("something_went_wrong"),
          dismissible: true,
          timeout: 4_000
        });
      }
    });
  }
</script>

<NoChannelsWrapper available={true}>
  <div class="page-content px-0 px-sm-2">
    <div class="container-fluid">
      <Row class="mb-4 align-items-center">
        <Col class="mb-2 mb-md-0 font-size-20 fw-bold text-truncate" md={6} sm={12}>
          {$_("menuitems.contacts.text")}
        </Col>
        <Col class="d-flex justify-content-md-end align-items-start align-items-md-center flex-wrap flex-column flex-md-row gap-3" md={6} sm={12}>
            <a href="/assets/files/contact-upload-1msg.xlsx" download class="link-offset-2">
              {$_('contacts.download_link')}
            </a>
            <Button type=button outline color="success" class="py-2 ps-3 pe-4 d-flex gap-2"
              on:click={() => isOpenModalImportBotUsers = true}>
              <i class="bx bx-import font-size-18"/>
              <span class="font-size-14">{$_('contacts.import')}</span>
            </Button>
            <button type=button class="py-2 ps-3 pe-4 d-flex gap-2 btn btn-outline-primary"
              class:align-items-center={loadingExportUsers} on:click={exportBotUsers} disabled={loadingExportUsers}>
              {#if loadingExportUsers}
                <Spinner size="sm"/>
              {:else}
                <i class="bx bx-export font-size-18"/>
              {/if}
              <span class="font-size-14">{$_('contacts.export')}</span>
            </button>
        </Col>
      </Row>
      <Card>
        <CardBody>
          {#if botUsersTags}
            <Row>
              <Col lg={12} xl={6} class="mb-2">
                <div class="d-flex align-items-center">
                  <div class="position-relative w-100 Search">
                    <input type="text" class="text-muted form-control" placeholder={$_(`search_extended`)} bind:value={search}/>
                    <i class="bx bx-search-alt position-absolute font-size-20 text-muted top-0"/>
                  </div>
                </div>
              </Col>
              <Col lg={12} xl={6} class="mb-2 d-flex justify-content-xl-end align-items-start flex-wrap gap-3">
                <input type="date" bind:value={filterDate} class="text-muted form-control filterDate custom-input-calendar"/>
                <SelectDropdownFilter title='contacts.add_filter' bind:list={botUsersTags}/>
                <Button type="button" outline color="success" class="py-2 ps-3 pe-4 d-flex"
                  on:click={() => isOpenModalAddNewContact = true}>
                  <i class="bx bx-plus me-2 font-size-20"/>
                  <span class="font-size-14">{$_('contacts.add_contact')}</span>
                </Button>
              </Col>
            </Row>
            <div class="mb-2 badges">
              {#each botUsersTags as tag}
                {#if tag.checked}
                  <span class="badge">
                    {tag.name}
                    <i class="mdi mdi-close pointer" on:click={() => tag.checked = false}/>
                  </span>&nbsp;
                {/if}
              {/each}
            </div>
            <div class="position-relative">
              {#if botUsersLoader}
                <div class="position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center bg-white bg-opacity-75">
                  <SpinnerComponent/>
                </div>
              {/if}
              <div class="table-responsive position-relative">
                {#if selectedBotUsers.length}
                  <button class="border-0 rounded-circle d-flex align-items-center justify-content-center position-fixed deleteBtnContact"
                    on:click={() => isOpenModalDeleteBotUsers = true}>
                    <i class="bx bx-trash text-white"/>
                  </button>
                {/if}
                <Table class="align-middle mb-0 table-hover">
                  <thead class="table-light">
                  <tr>
                    <th style="width: 3%">
                      <input class="form-check-input" type="checkbox" bind:checked={addAllBotUser} on:change={addAllBotUserId}/>
                    </th>
                    <th style="width: 23%">{$_('contacts.table.name')}</th>
                    <th style="width: 23%">{$_('contacts.table.phone')}</th>
                    <th style="width: 23%">{$_('contacts.table.date')}</th>
                    <th style="width: 28%">{$_('contacts.table.tags')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {#if botUsersList.length}
                    {#each botUsersList as user}
                      <tr class="font-size-15 fw-500">
                        <td>
                          <input class="form-check-input" type="checkbox"
                            checked={!!selectedBotUsers.find(id => id === user.id)}
                            on:change={() => addBotUserId(user.id)}>
                        </td>
                        <td class="pointer" on:click={() => {setChosenBotUserId(user.id); navigate('/inbox')}}>
                            {user.name}
                        </td>
                        <td>{user.messenger_user_id}</td>
                        <td>{formatDate(user.created_at)}</td>
                        <td class="badges">
                          {#if user.store.tags?.length}
                            {#each user.store?.tags as tag}
                              <span class="badge">
                                {botUsersMap.get(tag.id).name ?? ''}
                              </span>&nbsp;
                            {/each}
                          {/if}
                        </td>
                      </tr>
                    {/each}
                  {/if}
                  </tbody>
                </Table>
              </div>
            </div>
          {/if}
        </CardBody>
      </Card>
    </div>
    <ImportBotUsersModal bind:botUsersLoader bind:isOpen={isOpenModalImportBotUsers}/>
    <AddNewContactModal bind:botUsersLoader bind:isOpen={isOpenModalAddNewContact} {chosenChannelLocal}/>
    <DeleteBotUsersModal bind:botUsersLoader bind:isOpen={isOpenModalDeleteBotUsers} bind:selectedBotUsers/>
  </div>
</NoChannelsWrapper>
<ChooseTheChannel/>

<style>
  .Search input {
    height: 39px;
    padding-left: 40px;
  }

  .bx-search-alt {
    top: 0;
    left: 11px;
    line-height: 40px;
  }

  a.link-offset-2 {
    text-decoration: underline !important;
    text-underline-offset: 0.125em!important;
  }

  .form-check-input:checked {
    background-color: #23B16D;
    border-color: #23B16D;
  }

  .form-check-input:focus {
    box-shadow: 0 0 0 0.15rem rgba(35, 177, 109, .25);
  }

  .badges {
    line-height: 2;
  }

  .badges .badge {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 7px;
    color: #495057;
    background-color: #EFF2F7;
  }

  .deleteBtnContact {
    left: 20px;
    bottom: 40px;
    width: 50px;
    height: 50px;
    font-size: 24px;
    background-color: #F46A6A;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    transition: .15s;
  }

  .deleteBtnContact:hover {
    background-color: #c35555;
  }

  .filterDate {
    height: 39px;
  }

  @media (min-width: 768px) {
    .Search {
      max-width: 500px;
    }

    .filterDate {
      max-width: 145px;
    }

    .deleteBtnContact {
      right: 40px;
      left: auto;
    }
  }
</style>
