import {appClient} from "../store";
import {unionBy} from "lodash/array";
import natsClient from "./NatsClient";

import {get, writable} from "svelte/store";
import {eventTracking} from "../utils";

export default class ScheduledMessagesDAO {
  /**
   * Represents a chat message.
   * @typedef {Object} ChatMessage
   * @property {number} id - The unique identifier for the message.
   * @property {number} bot_id - The identifier of the bot associated with the message.
   * @property {number} bot_user_id - Information about the bot user who sent the message.
   * @property {number} chat_id - The identifier of the chat where the message was sent.
   * @property {string} reply_id_in_messenger - The unique identifier for the replied message in the messenger.
   * @property {number} content_type - The content type of the message.
   * @property {string} text - The text content of the message.
   * @property {string} image_url - The URL of an associated image.
   * @property {string} file_url - The URL of an associated file.
   * @property {string} video_url - The URL of an associated video.
   * @property {string} created_at - The creation timestamp of the message.
   * @property {string} updated_at - The last update timestamp of the message.
   * @property {string} profile_id - The profile identifier.
   * @property {Object} template_data - Additional template data associated with the message.
   * @property {string} waba_template_id - The template identifier for WhatsApp Business API.
   */

  /**
   * Represents a step in a chat flow.
   * @typedef {Object} Step
   * @property {number} id - The unique identifier for the step.
   * @property {string} shop_id - The identifier of the shop associated with the step.
   * @property {Flow} flow - Information about the flow that contains this step.
   * @property {string} name - The name of the step.
   */

  /**
   * Represents a chat flow.
   * @typedef {Object} Flow
   * @property {number} id - The unique identifier for the flow.
   * @property {string} shop_id - The identifier of the shop associated with the flow.
   * @property {string} name - The name of the flow.
   */


  messagesStore;
  messagesSendingStore;
  /**
   * @type {boolean} indicate wether currently mesages are loading
   */
  messagesloading;

  pollInterval = 10000;
  // messagesLimitPerLoad = 20;
  // messagesOffsetPerLoad = 0;
  // messagesOffsetSearchPerLoad = 0;
  // mesagesSearchPerLoad = '';

  /**
   * @type {Chat|ChatMessageSearch} chat
   */
  chat;
  interval;
  channel;

  natsStatusSub;
  natsStatus;

  /**
   *
   * @param {Chat|ChatMessageSearch} chat
   */
  constructor(chat) {
    this.chat = chat
    this.initStores();
  }

  //переделать на бридж типаж как только натс научится пушить запросы через сокенты на новые данные
  checkAndSetReceivingMessagesType(oldNatsStatus, newNatsStatus) {
    if (oldNatsStatus) {
      this.unsubscribeWithNats();
    } else {
      this.unsubscribeWithHttp();
    }
    if (newNatsStatus) {
      this.subscribeWithNats();
    } else {
      this.subscribeWithHttp();
    }
  }

  clearMessages() {
    this.messagesStore.set([])
  }

  deleteMessage(id) {
    return appClient.deleteScheduledMessage(id).then(data => {
      return data
    }, err => {
      return err
    })
  }

  getCommonFilters() {
    let filters = {
      // limit: this.messagesLimitPerLoad,
      // offset: this.messagesOffsetPerLoad,
      chat_id: this.chat.id,
      // text__ilike: this.mesagesSearchPerLoad
    };
    return filters
  }

  getMessages() {
    this.messagesloading.set(true);
    return appClient.getScheduledMessages(this.getCommonFilters(), this.channel).then(({result}) => {
      result?.results && this.prepareMessages(result.results, true)
      this.messagesloading.set(false);
      return result
    })
  }

  getNatsSubKey = () => {
    return `scheduledMessagesDao.${this.chat.id}`
  }

  //сюда должна вкладываться команда когда мы захотим глобальные сторы использовать или испльзовать кеш
  initStores() {
    this.messagesStore = writable([]);
    this.messagesloading = writable(false);
  }

  prepareMessages(data, addBottom = false) {
    // if (data.length === this.messagesLimitPerLoad) {
    //   this.messagesOffsetPerLoad += this.messagesLimitPerLoad;
    // }
    if (data?.[0]?.chat_id === this.chat.id) {
      let localStoreMessages = get(this.messagesStore);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < localStoreMessages.length; j++) {
          if (localStoreMessages[j].id === data[i].id) {
            localStoreMessages[j] = data[i];
            data.splice(i, 1)
            break;
          }
        }
      }
      let newMessages = addBottom ? unionBy(localStoreMessages, data, item => item.id) : unionBy(data, localStoreMessages, item => item.id);
      newMessages.sort((left, right) => left?.starts_at - right?.starts_at);
      this.messagesStore.set(newMessages)
    }
  }

  deleteMessageNats(data) {
    if (data?.chat_id === this.chat.id) {
      let localStoreMessages = get(this.messagesStore);
      this.messagesStore.set(localStoreMessages.filter(item=>item.id!==data.id))
    }
  }

  prepareNatsData(subject, data) {
    if (subject.indexOf('post_scheduled_message') !== -1 || subject.indexOf('patch_scheduled_message') !== -1){
      Array.isArray(data) ? this.prepareMessages(data) : this.prepareMessages([data])
    }else if(subject.indexOf('delete_scheduled_message') !== -1){
      this.deleteMessageNats(data)
    }
  }

  async sendFileMessage(data, type, starts_at) {
    if (!this.chat?.id || !data) {
      return Promise.reject(false);
    }
    return appClient.sendScheduledMessage(this.chat.id, data, type, starts_at).then(data => {
      return data
    }, err => {
      return err
    })
  }

  async sendTemplateMessage(template_id, template_data, channel, starts_at) {
    return appClient.sendScheduledWabaTemplateMessage(this.chat.id, template_id, template_data, channel, starts_at).then(data => {
      return data
    }, err => {
      return err
    })
  }

  async sendTextMessage(text, contentType = 'text', starts_at) {
    const trimValue = String(text).trim();
    if (!this.chat?.id || !trimValue) {
      return Promise.reject(false);
    }
    const payload = {
      text: trimValue
    };
    return appClient.sendScheduledMessage(this.chat.id, payload, contentType, starts_at).then(data => {
      return data
    }, err => {
      return err
    })
  }

  startListeningForNewMessages(channel) {
    this.channel = channel;
    this.natsStatus = get(natsClient.getWatchingStore());
    this.natsStatusSub = natsClient.getWatchingStore().subscribe(status => {
      this.checkAndSetReceivingMessagesType(this.natsStatus, status);
      this.natsStatus = status;
    })
    this.getMessages()
    this.checkAndSetReceivingMessagesType(this.natsStatus, this.natsStatus);
  }

  stopListeningForNewMessages() {
    this.unsubscribeWithNats();
    this.unsubscribeWithHttp();
    try {
      this.natsStatusSub && this.natsStatusSub()
    } catch (e) {
    }
  }

  subscribeWithHttp = () => {
    this.interval = setInterval(() => {
      this.getMessages()
    }, this.pollInterval)
  }

  subscribeWithNats = () => {
    natsClient.addHandler({id: this.getNatsSubKey(), handleObject: this})
  }

  unsubscribeWithHttp = () => {
    this.interval && clearInterval(this.interval)
  }

  unsubscribeWithNats = () => {
    natsClient.deleteHandler(this.getNatsSubKey())
  }
}
