<script>
  import {_} from 'svelte-i18n';
  import {Dropdown, DropdownToggle, DropdownMenu, Button, Tooltip} from 'sveltestrap';
  import {
    channels,
    chosenChannel,
    recentChannels,
    projects,
    setChosenChannel,
    showProjectSelectModal, currentPath, appClient, onetouchShops, getOnetouchShops
  } from "../helpers/store";
  import {onDestroy} from "svelte";
  import {showShops, UnsubTrain, copyText} from "../helpers/utils";
  import {addToast} from "../helpers/toast";
  import {navigate} from "svelte-routing";

  let chosenChannelLocal;
  let recentChannelsLocal, channelsList, channelsListFull, oneTouchShops = [];
  let projectsList = {};
  let showOneTouchShops = false;
  let oneTouchShopsLoading = false;

  let correctPage = false;

  let unsubTrain = new UnsubTrain();

  const filterRecent = () => {
    if (!showOneTouchShops && recentChannelsLocal?.length) {
      //у каналов вантача которые будут попадать в рисент имя и идшники одни и теже
      recentChannelsLocal = recentChannelsLocal.filter(item => item.id !== item.name)
    }
  }
  // const filterBlankProjects = () => {
    // if () {
    //   //у каналов вантача которые будут попадать в рисент имя и идшники одни и теже
    // }
  // }

  const filterOneTouchShops = () => {
    if ( oneTouchShops?.length && channelsListFull?.length) {
      oneTouchShops = oneTouchShops.filter(item => !Array.isArray(channelsListFull) || !channelsListFull.find(list => appClient.getCommonVars(item)?.shopId === appClient.getCommonVars(list)?.shopId))
    }
    filterRecent()
  }

  const clearTouchShopsFromSelection = () => {
    const currentShopId = appClient.getCommonVars(chosenChannelLocal)?.shopId
    if (!showOneTouchShops && currentShopId && oneTouchShops && oneTouchShops.find(item => currentShopId === appClient.getCommonVars(item)?.shopId)) {
      setChosenChannel(null)
    }
  }

  const parseId = (tradeName) => {
    if (!tradeName) return ''
    const first = tradeName.indexOf('#') + 1;
    const second = tradeName.indexOf('_', first)
    return tradeName.toString().slice(first, second)
  }

  unsubTrain.add = onetouchShops.subscribe(async (value) => {
    oneTouchShops = Array.isArray(value) ? value.reduce((acc, item) => {
      const name = parseId(item?.trade_name)
      const someVar = {appData: {shop_id: item?.id, dev_shop_id: item?.id}, id: name, name}
      acc.push(someVar);
      return acc
    }, []) : value;
    filterOneTouchShops()
  })

  unsubTrain.add = currentPath.subscribe(async (value) => {
    correctPage = !!['automation', 'leads', 'inbox', 'contacts', 'routing', 'segments', 'broadcast', 'testing'].find(el => value.indexOf(el) !== -1);
    showOneTouchShops = showShops(value)
    if (showOneTouchShops) {
      oneTouchShopsLoading = true;
      getOnetouchShops().then(() => {
        oneTouchShopsLoading = false
      }, () => {
        oneTouchShopsLoading = false
      })
    }
  })

  unsubTrain.add = chosenChannel.subscribe(async (value) => {
    chosenChannelLocal = value;
  })

  unsubTrain.add= recentChannels.subscribe(async (value) => {
    recentChannelsLocal = [...value];
    if (recentChannelsLocal.length) {
      recentChannelsLocal.reverse();
      recentChannelsLocal.shift();
    }
    filterRecent()
  })

  unsubTrain.add = projects.subscribe(async (value) => {
    projectsList = Array.isArray(value) && value.length ? value.reduce(
      (result, item) => {
        result[item.id] = item
        return result;
      },
      {}
    ) : {};
    projectsList = projectsList
  })

  unsubTrain.add = channels.subscribe(async (value) => {
    channelsListFull = value
    filterOneTouchShops()
    let length = 0;
    channelsList = Array.isArray(value) && value.length ? value.reduce(
      (result, item) => {
        if (!result[item.projectId] && length < 3) {
          length++;
          result[item.projectId] = []
        }
        if (item.appData && length < 3 && result[item.projectId]?.length < 4)
          result[item.projectId].push(item);
        if (item.appData && result[item.projectId]?.length === 4) {
          result[item.projectId].push({...item, ...{link: true}});
        }
        return result;
      },
      {}
    ) : {};
    channelsList = Object.values(channelsList)
    channelsList.sort((a, b) => b.length - a.length)
    let showProjectsCount = 4;
    if (showOneTouchShops) {
      showProjectsCount = 3;
    }
    if (channelsList.length > showProjectsCount) {
      channelsList = channelsList.slice(0, showProjectsCount - 1)
      channelsList = channelsList
    }
  })

  onDestroy(() => {
    clearTouchShopsFromSelection()
    unsubTrain.unsub()
  })

  const setCurrentChannel = (channel) => {
    setChosenChannel(channel)
    isOpen = false
  };

  const showModalToChoose = (e) => {
    e.preventDefault();
    showProjectSelectModal.set(true);
    isOpen = false
  };

  let isOpen = false;

  const copyToClipoard = (text) => {
    copyText(text);
    addToast({title: 'copied', message: text, dismissible: true, timeout: 2_000})
  };
</script>

{#if (correctPage||showOneTouchShops) && chosenChannelLocal}
  <div class="d-flex align-items-center">
    <button type="button" class="p-1 border-0 text-start rounded-2 font-size-14 text-truncate MobileProjectsBtn d-lg-none"
      on:click={() => isOpen = !isOpen}>
      <i class="mdi mdi-chevron-down"/> <span class="MobileProjectsBtn-id">{chosenChannelLocal?.id}&nbsp;</span>
    </button>
    <button type="button" id="copy_to_clipoard_channel"
      class="p-0 border-0 bg-transparent me-1 d-none d-lg-inline-block"
      on:click={() => copyToClipoard(chosenChannelLocal?.id)}>
      <i class="bx bx-copy-alt font-size-16 text-muted opacity-75 align-text-top custom-hover-success"/>
    </button>
    <Tooltip target="copy_to_clipoard_channel" placement="bottom">
      {$_('ProjectsDropdown.tooltip_copy_channel')}
    </Tooltip>
  </div>
  <Dropdown {isOpen} toggle={() => isOpen = !isOpen} class="ProjectsDropdown">
    <DropdownToggle class="btn header-item headerbtn d-none d-lg-inline-block" id="page-header-projects-dropdown" tag="button" color="">
      <div class="font-size-18 fw-500 d-inline-flex gap-2 NameProject">
        <div class="text-success">{chosenChannelLocal?.id}</div>
        <div class="text-truncate">{chosenChannelLocal?.name || '-'}</div>
      </div>
      <i class="mdi mdi-chevron-down"/>
    </DropdownToggle>
    <DropdownMenu start class="p-3 overflow-auto">
      <div class="ProjectsDropdown__row flex-wrap flex-md-nowrap">
        <div class="ProjectsDropdown__col">
          <div class="ProjectsDropdown__col__line d-flex">
            <div>
              <Button on:click={showModalToChoose} outline color="primary">
                <i class="bx bx-grid-small custom-icon-btn-grid-small me-1"/>
                <span class="font-size-14">{$_('ProjectsDropdown.button')}</span>
              </Button>
            </div>
            <div>
              <button type="button" class="font-size-20 pe-1 border-0 bg-transparent" on:click={()=>{navigate('/channels')}}>
                <i class="bx bx-cog settings-icon"/>
              </button>
            </div>
          </div>
          {#if recentChannelsLocal.length}
            <div class="ProjectsDropdown__col__line font-size-14 fw-500">{$_('ProjectsDropdown.recent')}</div>
          {/if}
          {#each recentChannelsLocal as channel}
            {#if channel.name}
              <button type="button" class="ProjectsDropdown__col__line ProjectsDropdown__col__line-link d-block p-0 border-0 bg-transparent text-start"
                on:click={() => setCurrentChannel(channel)}>
                {channel.name}
              </button>
            {/if}
          {/each}
        </div>
        {#if showOneTouchShops && oneTouchShops?.length}
          <div class="ProjectsDropdown__col">
            <div class="ProjectsDropdown__col__line font-size-14 fw-500">{$_('ProjectsDropdown.operator')}</div>
            {#each oneTouchShops as channel}
              {#if !channel.link}
                <button type="button" class="ProjectsDropdown__col__line ProjectsDropdown__col__line-link d-block p-0 border-0 bg-transparent text-start"
                  on:click={() => setCurrentChannel(channel)}>
                  {channel?.name || '-'}
                </button>
              {:else if channel.link}
                <button type="button" class="ProjectsDropdown__col__line d-block p-0 border-0 bg-transparent text-primary text-start"
                  on:click={showModalToChoose}>
                  <i class="mdi mdi-arrow-right"/> <span>{$_('ProjectsDropdown.link')}</span>
                </button>
              {/if}
            {/each}
          </div>
        {/if}
        {#if channelsList}
          {#each channelsList as channels}
            <div class="ProjectsDropdown__col">
              {#each channels as channel,i}
                {#if channel.projectId && i === 0}
                  <div class="ProjectsDropdown__col__line font-size-14 fw-500">
                    {projectsList?.[channel.projectId]?.name ?? ''}
                  </div>
                {/if}
                {#if !channel.link}
                  <button type="button" class="ProjectsDropdown__col__line ProjectsDropdown__col__line-link d-block p-0 border-0 bg-transparent text-start"
                    on:click={() => setCurrentChannel(channel)}>
                    {channel?.name || '-'}
                  </button>
                {:else if channel.link}
                  <button type="button" class="ProjectsDropdown__col__line d-block p-0 border-0 bg-transparent text-primary text-start"
                    on:click={showModalToChoose}>
                    <i class="mdi mdi-arrow-right"/> <span>{$_('ProjectsDropdown.link')}</span>
                  </button>
                {/if}
              {/each}
            </div>
          {/each}
        {/if}
      </div>
    </DropdownMenu>
  </Dropdown>
  {#if isOpen}
    <div class="ProjectsDropdown-overlay"/>
  {/if}
{/if}

<style>
  .MobileProjectsBtn {
    max-width: 220px;
    color: inherit;
    background-color: #DAF4EB;
  }

  .NameProject {
    max-width: 400px;
  }

  @media (max-width: 1400px) {
    .NameProject {
      max-width: 250px;
    }
  }

  @media (max-width: 440px) {
    .MobileProjectsBtn {
      max-width: 90px;
    }
  }

  @media (max-width: 350px) {
    .MobileProjectsBtn {
      max-width: 50px;
    }
  }

  @media (max-width: 320px) {
    .MobileProjectsBtn-id {
      display: none;
    }
  }

  .settings-icon{
      color: #556ee6;
      vertical-align: middle;
  }
</style>
