<script>
  import {Input, FormGroup, Label, Spinner} from "sveltestrap";
  import {getRequest} from "../../../../helpers/utils";

  export let values;
  export let chosenChannelLocal;
  export let loader;
  let error = '';

  const initSettings = async () => {
    loader = true;
    error = '';
    values.webhookUrl = [''];
    let requestSettings = await getRequest(`${chosenChannelLocal?.apiUrl}settings`, {token: chosenChannelLocal?.token});
    if (requestSettings.error) {
      error = requestSettings.error;
    } else {
      if (requestSettings.webhookUrl == null) {
        requestSettings.webhookUrl = '';
      }

      if (typeof requestSettings.webhookUrl === 'string') {
        requestSettings.webhookUrl = [requestSettings.webhookUrl];
      }

      values.webhookUrl = requestSettings.webhookUrl;
    }
    loader = false;
  };

  $: if (chosenChannelLocal) {
    initSettings();
  }

  const deleteWebhookUrl = (index) => {
    let arr = [...values.webhookUrl];
    arr.splice(index, 1);
    values.webhookUrl = arr;
  };
</script>

<Label class="fw-600">webhookUrl</Label>
{#if error}
  <FormGroup>
    {JSON.stringify(error, null, 2)}
  </FormGroup>
{:else}
  {#if !loader}
    {#each values?.webhookUrl as url, i}
      <FormGroup class="d-flex align-items-center gap-3">
        <Input type="text" bind:value={url}/>
        <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteWebhookUrl(i)} disabled={values?.webhookUrl.length === 1}>-</button>
      </FormGroup>
    {/each}
    {#if values.webhookUrl.length < 5}
      <button type="button" class="btn addBtn rounded-circle" on:click={() => {values.webhookUrl = [...values.webhookUrl, ""]}}>+</button>
    {/if}
  {:else}
    <FormGroup>
      <Spinner size="sm"/>
    </FormGroup>
  {/if}
{/if}

<style>
  .deleteBtn, .addBtn {
    color: #495057 !important;
    width: 40px;
    min-width: 40px;
    height: 40px;
    background-color: #EFF2F7;
    box-shadow: none;
  }

  .deleteBtn {
    line-height: 16px;
    font-size: 22px;
  }

  .addBtn {
    line-height: 20px;
    font-size: 20px;
  }
</style>
