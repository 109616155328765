<script>
  import {auth, loginViaGoogle, loginViaGit, loginViaFacebook, loginViaTwitter} from "../../helpers/firebase.js";
  import {navigate} from "svelte-routing";
  import {user} from "../../helpers/store.js";

  import {handleLogin, promisifyStore} from "../../helpers/utils";

  export let handling;
  export let errorDuringLogin;


  const googleAuth = () => {
    handling = true;
    loginViaGoogle()
      .then(data => {
        if (data.additionalUserInfo.isNewUser) {
          try {
            window.dataLayer.push({'event': 'onboarding_create_account', "type": "google"});
          } catch (e) {
          }
        }
      }).then(() => {
      promisifyStore(user, user => !!user?.id && (user?.verificationNeeded !== undefined || user?.trackVerification !== undefined)).then(data => {
        handleLogin(false, null, null, data)
      }, errorDuringLogin)
    })
      .catch(errorDuringLogin);
  };


  const gitAuth = () => {
    loginViaGit()
      .then().then(() => {
      navigate("/")
    })
      .catch((error) => alert(error.message));
  };
  const facebookAuth = () => {
    loginViaFacebook()
      .then().then(() => {
      navigate("/")
    })
      .catch((error) => alert(error.message));
  };
  const twitterAuth = () => {
    loginViaTwitter()
      .then().then(() => {
      navigate("/")
    })
      .catch((error) => alert(error.message));
  };
</script>

<ul class="list-inline">
  <li
      class="list-inline-item align-bottom"
      on:click={googleAuth}
  >
    <a
        href={"#"}
        class="social-list-item bg-danger text-white border-0 d-flex align-items-center justify-content-center"
    >
      <i class="mdi mdi-google"/>
    </a>
  </li>
  <li class="list-inline-item align-bottom"
      on:click={facebookAuth}
  >
    <a
        href={"#"}
        class="social-list-item bg-primary text-white border-primary"
    >
      <i class="mdi mdi-facebook font-size-18" />
    </a>
  </li>

</ul>

