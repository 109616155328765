<script>
  import {onMount} from "svelte";
  import {_} from "svelte-i18n";
  import {Row, Col, Button, FormGroup, Label, Input, Spinner} from "sveltestrap";
  import {channels, chosenChannel} from "../../helpers/store";
  import {promisifyStore, postRequest, getRequest} from "../../helpers/utils";

  import PrettyfiedCode from "../../Components/PrettyfiedCode.svelte";
  import SelectDropdown from "../../Components/SelectDropdown.svelte";
  import SelectOperation from "./Components/SelectOperation.svelte";
  import TextAreaAutosize from "../../Components/TextAreaAutosize.svelte";
  import SendList from "./Components/fields/SendList.svelte";
  import SendButton from "./Components/fields/SendButton.svelte";
  import SendContact from "./Components/fields/SendContact.svelte";
  import SendTemplate from "./Components/fields/SendTemplate.svelte";
  import AddTemplate from "./Components/fields/AddTemplate.svelte";
  import Commerce from "./Components/fields/Commerce.svelte";
  import SendProduct from "./Components/fields/sendProduct.svelte";
  import Settings from "./Components/fields/Settings.svelte";

  let channelList = [];
  let selectedChannel;
  let chosenChannelLocal;
  let currentOperation;
  let operationList;
  let values = {};

  let Response = '';
  let loader = false;
  let loader2 = false;

  const PAIRS = [
    {type: "text", name: "chatId"},
    {type: "text", name: "phone"}
  ];

  const PATH_FOR_PAIRS = [
    "/sendList", "/sendButton", "/sendLocationRequest", "/sendFile", "/sendMessage",
    "/sendLocation", "/sendContact", "/sendTemplate", "/sendProduct",
  ];

  let channelStatusIsCloud = null;
  let channelStatusIsCloudLoader = false;

  onMount(async () => {
    promisifyStore(channels).then(channelsData => {
      channelList = Array.isArray(channelsData) && channelsData.length ? channelsData.reduce(
        (result, item) => {
          if (item.appData) {
            let trialData = {};
            if (item?.srv === 'trial') {
              trialData = {sandbox_phone: item.sandbox_phone};
            }
            result.push({
              data: item,
              value: item.id,
              token: item.token,
              label: `${item.id} ${item.name}`,
              ...trialData
            });
          }
          return result;
        },
        []
      ) : [];
    });

    promisifyStore(chosenChannel).then(chosenChannelData => {
      selectedChannel = chosenChannelData.id;
      chosenChannelLocal = chosenChannelData;
    });

    setLocalValues((await import('./Method.js')))
  });

  function setChannellocal(channelData) {
    chosenChannelLocal = channelData.data;
  }

  function setLocalValues(obj) {
    operationList = obj.method;
    currentOperation = operationList[0]?.operations[0];
    onOperationChange();
  }

  function onOperationChange() {
    if (currentOperation) {
      values = {};
      if (currentOperation.parameters) {
        for (let i = 0; i < currentOperation.parameters.length; i++) {
          values = parseParameter(values, currentOperation.parameters[i]);
        }
        if (PATH_FOR_PAIRS.indexOf(currentOperation.path) !== -1) {
          values[PAIRS[0].name] = "";
        }
      }
    }
  }

  function parseParameter(values, parameter) {
    if (parameter.type === "array") {
      let items = [];
      for (let i = 0; i < parameter.items.length; i++) {
        items.push(parseParameter({}, parameter.items[i]));
      }
      return {...values, [parameter.name]: items};
    } else if (parameter.type === "object") {
      if (parameter.name) {
        let item = {};
        for(let j = 0; j < parameter.properties.length; j++) {
          item = parseParameter(item, parameter.properties[j]);
        }
        values = {...values, [parameter.name]: item};
      } else {
        for(let j = 0; j < parameter.properties.length; j++) {
          values = parseParameter(values, parameter.properties[j]);
        }
      }
      return values;
    } else {
      let initialValue = "";
      if (parameter.type === "number") {
        initialValue = 0;
      }
      return {...values, [parameter.name]: parameter.value ?? initialValue};
    }
  }

  function submit() {
    loader = true;
    const channel = channelList.find(({value}) => value === selectedChannel);
    if (currentOperation.httpMethod === "get") {
      getRequest(`${chosenChannelLocal?.apiUrl}${currentOperation?.path.slice(1)}`, {
        token: channel.token,
        ...values
      }).then(response => {
        Response = JSON.stringify(response, null, 2);
      }).catch(error => {
        Response = JSON.stringify(error, null, 2);
      }).finally(() => {
        loader = false;
      });
    } else if (currentOperation.httpMethod === "post") {
      postRequest(`${chosenChannelLocal?.apiUrl}${currentOperation?.path.slice(1)}`, {
        token: channel.token,
        ...values
      }).then(response => {
        Response = JSON.stringify(response, null, 2);
      }).catch(error => {
        Response = JSON.stringify(error, null, 2);
      }).finally(() => {
        loader = false;
      });
    }
  }

  function getRequestStatus() {
    channelStatusIsCloudLoader = true;
    const channel = channelList.find(({value}) => value === selectedChannel);
    getRequest(`${chosenChannelLocal?.apiUrl}status`, {token: channel.token})
      .then(response => {
        channelStatusIsCloud = !!response?.isCloud;
      }).catch(e => {
        channelStatusIsCloud = false;
      }).finally(() => {
        channelStatusIsCloudLoader = false;
      });
  }

  $: if(selectedChannel) {
    getRequestStatus()
  }
</script>

<Row>
  <Col xl={6} class="mb-3 mb-xl-0">
    <FormGroup>
      <Label class="fw-600">{$_('TestingTestRequests.channel_selection')}</Label>
      <SelectDropdown bind:selected={selectedChannel} list={channelList} change={setChannellocal}
        title='TestingTestRequests.channel_selection'/>
    </FormGroup>
    <FormGroup>
      <Label class="fw-600">{$_('TestingTestRequests.method_selection')}</Label>
      <SelectOperation bind:selected={currentOperation} list={operationList} disabled={loader || loader2}
        callback={onOperationChange} title='TestingTestRequests.method_selection'/>
    </FormGroup>
    <form on:submit|preventDefault={submit}>
      {#if currentOperation?.parameters?.length}
        {#each currentOperation.parameters as param}
          <FormGroup>
            {#if param.type !== "checkbox" && param.type !== "array" && param.type !== "object"}
              <Label class="fw-600">{param.name}</Label>
            {/if}
            {#if param.type === "array" || param.type === "object"}
              {#if currentOperation.path === "/sendList"}
                <SendList bind:values/>
              {/if}
              {#if currentOperation.path === "/sendButton"}
                <SendButton bind:values/>
              {/if}
              {#if currentOperation.path === "/sendContact"}
                <SendContact bind:values/>
              {/if}
              {#if currentOperation.path === "/sendTemplate"}
                <SendTemplate name={param.name} bind:values {channelStatusIsCloud}/>
              {/if}
              {#if currentOperation.path === "/addTemplate"}
                <AddTemplate bind:values {channelStatusIsCloud}/>
              {/if}
              {#if currentOperation.path === "/commerce"}
                <Commerce bind:values/>
              {/if}
              {#if currentOperation.path === "/sendProduct"}
                <SendProduct bind:values/>
              {/if}
              {#if currentOperation.path === "/settings"}
                <Settings bind:values {chosenChannelLocal} bind:loader={loader2}/>
              {/if}
              {#if currentOperation.path === "/webhook"}
                <Settings bind:values {chosenChannelLocal} bind:loader={loader2}/>
              {/if}
            {:else if param.type === "textarea"}
              <div class="rounded-2 bg-light">
                <TextAreaAutosize classes="border-0 bg-transparent" name={param.name} bind:value={values[param.name]} minRows={4}/>
              </div>
            {:else if param.type === "select"}
              {#if currentOperation.path === "/me"}
                {#if !channelStatusIsCloudLoader}
                  <SelectDropdown type={true} bind:selected={values[param.name]} list={channelStatusIsCloud ? param.enumV2 : param.enum}/>
                {:else}
                  <div><Spinner size="sm"/></div>
                {/if}
              {:else}
                <SelectDropdown type={true} bind:selected={values[param.name]} list={param.enum}/>
              {/if}
            {:else if param.type === "checkbox"}
              <div class="d-flex align-items-center">
                <Input type="checkbox" id={param.name} bind:checked={values[param.name]}/>
                <Label class="fw-600 m-0" for={param.name}>{param.name}</Label>
              </div>
            {:else}
              <Input type={param.type} name={param.name} bind:value={values[param.name]}/>
            {/if}
          </FormGroup>
        {/each}
        {#if PATH_FOR_PAIRS.indexOf(currentOperation.path) !== -1}
          <ul class="list-inline Type mb-2">
             <li class="list-inline-item">
              <button type="button" class="font-size-16 border-0 p-0 bg-transparent {PAIRS[0].name in values ? 'active' : 'text-muted'}"
                on:click={() => {delete values[PAIRS[1].name]; values[PAIRS[0].name] = ""}}>
                {PAIRS[0].name}
              </button>
            </li>
           <li class="list-inline-item">
              <button type="button" class="font-size-16 border-0 p-0 bg-transparent {PAIRS[1].name in values ? 'active' : 'text-muted'}"
                on:click={() => {delete values[PAIRS[0].name]; values[PAIRS[1].name] = ""}}>
                {PAIRS[1].name}
              </button>
            </li>
          </ul>
          <FormGroup>
            {#if PAIRS[0].name in values}
              <Input type={PAIRS[0].type} bind:value={values[PAIRS[0].name]}/>
            {/if}
            {#if PAIRS[1].name in values}
              <Input type={PAIRS[1].type} bind:value={values[PAIRS[1].name]}/>
            {/if}
          </FormGroup>
        {/if}
      {/if}
      <Button class="py-2 px-4" color="success" disabled={loader || loader2} type="submit">
        <span class="font-size-14">{$_('send')}</span>
      </Button>
    </form>
  </Col>
  <Col xl={6}>
    <div class="mb-3">
      <Label class="fw-600">{$_('request')}</Label>
        <PrettyfiedCode code={
`${currentOperation?.httpMethod.toUpperCase() ?? ''} ${chosenChannelLocal?.apiUrl}${currentOperation?.path.slice(1)}
Content-Type: application/json
Body: ${JSON.stringify({token: channelList.find(({value}) => value === selectedChannel)?.token, ...values}, null, 2)}
        `}/>
    </div>
    <div>
      <Label class="fw-600">{$_('response')}</Label>
      <PrettyfiedCode code={loader ? $_('loader...') : Response}/>
    </div>
  </Col>
</Row>

<style>
  .Type .active {
    color: #23B16D;
    border-bottom: 1px solid #23B16D !important;
  }
</style>
