<script>
  import {Button, Row, Col, Table} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import {chosenChannel} from "../../../helpers/store";
  import SpinnerComponent from "../../../Components/Spinners/SpinnerComponent.svelte";
  import SelectDropdownFilter from "../../../Components/SelectDropdownFilter.svelte";
  import {BotUsersDAO} from "../../../helpers/onetouch/BotUsersDAO";
  import {TagsDAO} from "../../../helpers/onetouch/TagsDAO";

  export let selectedBotUsers = [];
  export let botUsersLoader = false;
  export let isOpenModalAddContact = () => {};

  let botUsersMap = null;
  let chosenChannelLocal = null;
  let botUsersTags = null;
  let search = '';
  let addAllBotUser = false;
  let filterDate = '';
  let botUsersList = [];

  const unsub3 = chosenChannel.subscribe(value => {
    if (value === null || value === "") {
      chosenChannelLocal = null;
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        botUsersTags = null;
        search = '';
        addAllBotUser = false;
        chosenChannelLocal = value
        TagsDAO.getOrLoadAll().then(data => {
          botUsersTags = data.map(item => ({...item, checked: false}));

          const newMap = new Map();
          data.forEach(item => {
            newMap.set(item.id, item);
          });
          botUsersMap = newMap
        });
        botUsersLoader = true;
        BotUsersDAO.getOrLoadAll().then(data => {
          let list = data?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          if (selectedBotUsers) {
            let selectedList = [];
            for (let i = 0; i < list.length; i++) {
              const found = selectedBotUsers.some(item => item.bot_user_id === list[i].id);
              if (found) {
                selectedList.push(...list.splice(i, 1));
                i--;
              }
            }
            list.unshift(...selectedList);
          }
          botUsersList = list;
          botUsersLoader = false;
        });
      }
    }
  });

  onDestroy(() => {
    unsub3();
  });

  const formatDate = (data) => {
    return data ? data.slice(0, data.lastIndexOf('.')) : ''
  };

  const onSearch = () => {
    const searchThis = search.indexOf(', ')!==-1?customSplitWithMap(', ',search):search.trim().toLowerCase()
    return botUsersList.filter(item => {
      if (searchThis) {
        if(typeof searchThis === "string"){
          if (!item.name.toLowerCase().includes(searchThis) && !item.messenger_user_id.toLowerCase().includes(searchThis)) {
            return false;
          }
        } else {
          if (!searchThis.get(item.name.toLowerCase()) && !searchThis.get(item.messenger_user_id.toLowerCase()) ) {
            return false;
          }
        }
      }
      if (filterDate) {
        if (item.created_at.slice(0, item.created_at.lastIndexOf(' ')) !== filterDate) {
          return false;
        }
      }
      return botUsersTags?.filter(({checked}) => checked === true).every(tag => item.store.tags.some(storeTag => storeTag.id === tag.id))
    });
  };

  function customSplitWithMap(pattern, str) {
    const parsedMap = new Map();
    let startIndex = 0;
    let currentIndex = str.indexOf(pattern);

    while (currentIndex !== -1) {
      const word = str.substring(startIndex, currentIndex);
      parsedMap.set(word.toLowerCase().trim(), true);
      startIndex = currentIndex + pattern.length;
      currentIndex = str.indexOf(pattern, startIndex);
    }

    parsedMap.set(str.substring(startIndex).toLowerCase().trim(), true);
    return parsedMap;
  }

  const addBotUserId = (id) => {
    const i = selectedBotUsers.findIndex(({bot_user_id}) => bot_user_id === id);
    if (i != -1) {
      selectedBotUsers = selectedBotUsers.filter((value, index) => index !== i)
      return;
    }
    selectedBotUsers = [...selectedBotUsers, {bot_user_id: id}];
  };

  const addAllBotUserId = () => {
    if (addAllBotUser) {
      selectedBotUsers = botUsersListView.map(({id}) => {
        return {bot_user_id: id}
      });
    } else {
      selectedBotUsers = [];
    }
  };

  const checkSelectedBotUsers = () => {
    if (!botUsersListView.length) {
      addAllBotUser = false;
      return;
    }
    if (selectedBotUsers.length === botUsersListView.length) {
      addAllBotUser = botUsersListView.every(({id}) => selectedBotUsers.find(({bot_user_id}) => bot_user_id === id));
    } else {
      addAllBotUser = false;
    }
  };

  $: botUsersListView = (search || filterDate || botUsersTags?.find(({checked}) => checked === true)) ? onSearch() : botUsersList;
  $: botUsersListView && checkSelectedBotUsers();
</script>

{#if botUsersTags}
  <Row>
    <Col md={12} lg={6} class="mb-2">
      <div class="d-flex flex-wrap align-items-center gap-3">
        <div class="position-relative w-100 Search">
          <input type="text" class="text-muted form-control" placeholder={$_(`search_extended`)} bind:value={search}/>
          <i class="bx bx-search-alt position-absolute font-size-20 text-muted top-0"/>
        </div>
        <div class="fw-600">
          {$_('contacts.select')}: <span class="text-success">{selectedBotUsers.length} {$_('contacts.contact')}</span>
        </div>
      </div>
    </Col>
    <Col md={12} lg={6} class="mb-2 d-flex justify-content-lg-end align-items-start flex-wrap gap-3">
      <input type="date" bind:value={filterDate} class="text-muted form-control filterDate custom-input-calendar"/>
      <SelectDropdownFilter title={$_('contacts.add_filter')} bind:list={botUsersTags}/>
      <Button type="button" outline color="success" class="py-2 ps-3 pe-4 d-flex" on:click={isOpenModalAddContact}>
        <i class="bx bx-plus me-2 font-size-20"/>
        <span class="font-size-14">{$_('contacts.add_contact')}</span>
      </Button>
    </Col>
  </Row>
  <div class="mb-2 badges">
    {#each botUsersTags as tag}
      {#if tag.checked}
        <span class="badge">
          {tag.name}
          <i class="mdi mdi-close pointer" on:click={() => tag.checked = false}/>
        </span>&nbsp;
      {/if}
    {/each}
  </div>
  <div class="position-relative">
    {#if botUsersLoader}
      <div class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center bg-white bg-opacity-75">
        <SpinnerComponent/>
      </div>
    {/if}
    <div class="table-responsive">
      <Table class="align-middle mb-0">
        <thead class="table-light">
        <tr>
          <th style="width: 3%">
            <input class="form-check-input" type="checkbox" bind:checked={addAllBotUser} on:change={addAllBotUserId}>
          </th>
          <th style="width: 23%">{$_('contacts.table.name')}</th>
          <th style="width: 23%">{$_('contacts.table.phone')}</th>
          <th style="width: 23%">{$_('contacts.table.date')}</th>
          <th style="width: 28%">{$_('contacts.table.tags')}</th>
        </tr>
        </thead>
        <tbody>
        {#if botUsersListView.length}
          {#each botUsersListView as user}
            <tr class="font-size-15 fw-500">
              <td>
                <input class="form-check-input" type="checkbox"
                  checked={!!selectedBotUsers.find(({bot_user_id}) => bot_user_id === user.id)}
                  on:change={() => addBotUserId(user.id)}>
              </td>
              <td>{user.name}</td>
              <td>{user.messenger_user_id}</td>
              <td>{formatDate(user.created_at)}</td>
              <td class="badges">
                {#if user.store?.tags?.length}
                  {#each user.store.tags as tag}
                    <span class="badge">
                      {botUsersMap.get(tag.id).name ?? ''}
                    </span>&nbsp;
                  {/each}
                {/if}
              </td>
            </tr>
          {/each}
        {/if}
        </tbody>
      </Table>
    </div>
  </div>
{/if}
{#if !botUsersTags}
  <div class="w-100 h-100 d-flex align-items-center justify-content-center">
    <SpinnerComponent/>
  </div>
{/if}

<style>
  .Search input {
    height: 39px;
    padding-left: 40px;
  }

  .bx-search-alt {
    top: 0;
    left: 11px;
    line-height: 40px;
  }

  .form-check-input:checked {
    background-color: #23B16D;
    border-color: #23B16D;
  }

  .form-check-input:focus {
    box-shadow: 0 0 0 0.15rem rgba(35, 177, 109, .25);
  }

  .badges {
    line-height: 2;
  }

  .badges .badge {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 7px;
    color: #495057;
    background-color: #EFF2F7;
  }

  .filterDate {
    height: 39px;
  }

  @media (min-width: 768px) {
    .Search {
      max-width: 500px;
    }

    .filterDate {
      max-width: 145px;
    }
  }
</style>
