<script>
  import {Container, Row, Col, Card, CardBody, CardTitle, Button, Spinner} from "sveltestrap";
  import {Link} from "svelte-routing";
  import {_} from "svelte-i18n";
  import QRCode from "../../Components/Qr.svelte";
  import {channels, user, projects, addChannel} from "../../helpers/store";
  import { onDestroy, tick} from "svelte";
  import {UnsubTrain,  postRequest, getRequest, retryPromiseFunction} from "../../helpers/utils";
  import {layout} from "../../services/loaders";
  import {addToast} from "../../helpers/toast";
  // import introJs from "intro.js";
  // import "intro.js/introjs.css"

  let channel, activeUser, projectsList, settings;
  let webhook = '';
  let webhookSaving = false;
  let unsubTrain = new UnsubTrain();

  unsubTrain.add = channels.subscribe(value => {
    if (value) {
      channel = value.find(item => item.srv === 'trial')
      if (!settings && channel) initSettings()
    }
  });

  unsubTrain.add = user.subscribe(value => {
    if (value) {
      activeUser = value
    }
  });

  unsubTrain.add = projects.subscribe(value => {
    if (value) {
      projectsList = value;
    }
  });

  async function changeSettings() {
    if (webhookSaving) return
    webhookSaving = true;
    settings.webhookUrl = settings.webhookUrl.split(',').filter(item => item.indexOf("app.1msg.io") !== -1)
    if (webhook)
      settings.webhookUrl.push(webhook)
    settings.webhookUrl = settings.webhookUrl.join(',')
    try {

      let result = await postRequest(
        `${channel.apiUrl}settings?token=${channel.token}`,
        settings
      );
      webhookSaving = false;
      if (result && !result.error) {
        await addToast({title: $_("saved")});
        console.log(result)
        settings = result;
        webhook = settings.webhookUrl.split(',').filter(item => item.indexOf("app.1msg.io") === -1).join(',')
      } else {

        await addToast({title: $_("error")});
      }
    } catch (e) {
      webhookSaving = false;
      await addToast({title: $_("error")});
    }
  }

  async function initSettings() {
    if (channel.apiUrl) {
      let requestSettings = await getRequest(`${channel.apiUrl}settings`, {
        token: channel.token,
      });

      let blocked = ["oldMessagesPeriod"];
      let tmp = {webhookUrl: ''};
      for (let prop in requestSettings) {
        if (blocked.includes(prop)) continue;
        tmp[prop] = requestSettings[prop];
      }
      settings = tmp;
      webhook = settings.webhookUrl.split(',').filter(item => item.indexOf("app.1msg.io") === -1).join(',')
    } else {
      settings = {}
    }
  }

  // afterUpdate(() => {
  //   if (channel && activeUser?.creationTS > 1687862981000) {
  //     let trialIntro = getCookie(`trialIntro_${activeUser.id}`);
  //     if (!trialIntro) {
  //       setTimeout(() => {
  //         introJs().setOptions({
  //           steps: [{
  //             element: document.querySelector('.trial_card .card-body'),
  //             title: $_('trial.intro.step1.title'),
  //             intro: $_('trial.intro.step1.text')
  //           }, {
  //             element: document.querySelector('.whatsapp_link'),
  //             title: $_('trial.intro.step2.title'),
  //             intro: $_('trial.intro.step2.text')
  //           }, {
  //             element: document.querySelector('.trial_qr'),
  //             title: $_('trial.intro.step3.title'),
  //             intro: $_('trial.intro.step3.text')
  //           }
  //           ],
  //           nextLabel: $_('trial.intro.next'),
  //           prevLabel: $_('trial.intro.prev'),
  //           doneLabel: $_('trial.intro.done')
  //         }).start();
  //         setCookie(`trialIntro_${activeUser.id}`, true, 1000 * 60 * 60 * 24 * 10);
  //       }, 3000)
  //     }
  //   }
  // });

  let trialRequest = false;

  async function toTrial() {
    layout({text: 'trial.setting_up'})
    if (!activeUser.trialCreated && !activeUser.isOperator) {
      try {
        await Promise.allSettled(
          [
            retryPromiseFunction(() => addChannel(projectsList[0]?.id, '', '', true, false, false), 5, 500),
            retryPromiseFunction(() => addChannel(projectsList[0]?.id, '', '', false, true, false), 3, 500)
          ])
      } catch (e) {
      }
      layout(false);
    }
    await tick();
  }

  $: if (!trialRequest && activeUser && !activeUser.trialCreated && projectsList?.[0]?.id) {
    trialRequest = true;
    toTrial();
  }

  onDestroy(() => {
    unsubTrain.unsub();
  });
</script>

{#if channel}
  <div class="page-content px-0 px-sm-2">
    <Container fluid class="px-0 px-md-2">
      <div class="position-absolute d-none d-lg-flex justify-content-between w-100 px-5 pt-5 bg-yellow bg-soft initiate_header">
        <img src="/assets/images/girl_trial.svg" alt="header" style="width: 16%"/>
        <img src="/assets/images/boy_trial.svg" alt="header" style="width: 18%"/>
      </div>
      <div class="trial_card mx-auto">
        <Card>
          <CardBody class="p-3 p-sm-5">
            {#if !channel?.sandbox_phone}
              <CardTitle class="h5 text-center font-size-28 d-none d-sm-block">{$_('trial.title')}</CardTitle>
              <CardTitle class="h5 text-center font-size-20 d-sm-none">{$_('trial.title')}</CardTitle>
              <Row class="mt-4 flex-column-reverse flex-md-row">
                <Col sm={12} xl={6} class="pe-sm-0">
                  <div class="d-sm-flex justify-content-center">
                    <div class="m-3 me-4">
                      <div class="trial_qr text-center">
                        <QRCode value={`https://api.whatsapp.com/send/?phone=6531743440&text=Hi+1msg%2C+activate+the+demo+account+${channel.token}&type=phone_number&app_absent=0`} width="145" height="145"/>
                      </div>
                    </div>
                    <div class="mb-3 mt-3 w-100 ps-sm-1">
                      <div class="fw-500 font-size-16 fg-bs-gray-700">
                        {@html $_('trial.qr_title')}
                      </div>
                      <div class="mt-2 fg-bs-gray-600">
                        {@html $_('trial.qr_text')}
                      </div>
                    </div>
                    <div class="d-none d-xl-block border-end pe-5">
                      <div class="position-absolute top-50 start-100 translate-middle bg-white fg-bs-gray-600">
                        {$_('or')}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} xl={6} class="ps-sm-5">
                  <div class="d-sm-flex justify-content-center">
                    <div class="my-3 me-4 ms-4 ms-sm-0 text-center">
                      <img src="/assets/images/WhatsAppPhone.svg" alt="phone"/>
                    </div>
                    <div class="mb-3 ps-1 me-3 mt-3 w-100">
                      <div class="fw-500 font-size-16 fg-bs-gray-700">
                        {$_('trial.phone_title')}
                      </div>
                      <div class="mt-2 fg-bs-gray-600 phoneText">
                        {@html $_('trial.phone_text', {values: {token: channel.token}})}
                      </div>
                    </div>
                  </div>
                  <div class="d-block d-md-none border-end">
                    <div class="h5 text-center font-size-28">
                      {$_('or')}
                    </div>
                  </div>
                </Col>
              </Row>
              <div class="mx-sm-3 mt-4">
                {@html $_('trial.hint_text')}
              </div>
            {:else}
              <div class="border rounded border-transparent-green p-3 mb-3 mb-sm-4">
                <div class="mb-3 mt-2 semi-bold font-size-16">
                  <i class="bx bx-check-circle fg-green pe-2 font-size-20"/> {@html $_('trial.step1.text1')}
                </div>
                <div class="mb-3 mb-sm-0 d-sm-flex justify-content-between align-items-start pt-2">
                  <div class="d-flex">
                    <i class="mdi mdi-arrow-right-thin-circle-outline font-size-16 fg-green pe-3 d-none d-sm-inline-block"/>
                    <div>
                      <div class="semi-bold font-size-16">
                        {$_('trial.step1.phone')}
                      </div>
                      <div class="font-size-14 py-2">
                        {$_('trial.step1.phone_hint')}
                      </div>
                    </div>
                  </div>
                  <div class="semi-bold font-size-16">
                    {channel.sandbox_phone}
                  </div>
                </div>
                <div class="mb-3 mb-sm-0 d-sm-flex justify-content-between align-items-start pt-2">
                  <div class="d-flex">
                    <i class="mdi mdi-arrow-right-thin-circle-outline font-size-16 fg-green pe-3 d-none d-sm-inline-block"/>
                    <div>
                      <div class="semi-bold font-size-16">
                        {$_('trial.step1.sandbox')}
                      </div>
                      <div class="font-size-14 py-2">
                        {$_('trial.step1.sandbox_hint')}
                      </div>
                    </div>
                  </div>
                  <div class="semi-bold font-size-16">
                    6531743440
                  </div>
                </div>
                <div class="mb-3 mb-sm-0 d-sm-flex justify-content-between align-items-start pt-2">
                  <div class="d-flex">
                    <i class="mdi mdi-arrow-right-thin-circle-outline font-size-16 fg-green pe-3 d-none d-sm-inline-block"/>
                    <div>
                      <div class="semi-bold font-size-16">
                        {$_('trial.step1.api_url')}
                      </div>
                      <div class="font-size-14 py-2">
                        {$_('trial.step1.api_url_hint')}
                      </div>
                    </div>
                  </div>
                  <div class="semi-bold font-size-16">
                    {channel.apiUrl}
                  </div>
                </div>
                <div class="mb-3 mb-sm-0 d-sm-flex justify-content-between align-items-start pt-2">
                  <div class="d-flex">
                    <i class="mdi mdi-arrow-right-thin-circle-outline font-size-16 fg-green pe-3 d-none d-sm-inline-block"/>
                    <div>
                      <div class="semi-bold font-size-16">
                        {$_('trial.step1.token')}
                      </div>
                      <div class="font-size-14 py-2">
                        {$_('trial.step1.token_hint')} {$_('trial.step1.docs_hint')}
                        <Link to="/documentation" class="text-success">
                          {$_('trial.step1.docs')}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="semi-bold font-size-16">
                    {channel.token}
                  </div>
                </div>
                <div class="mb-3 mb-md-0 d-md-flex justify-content-between align-items-start pt-2">
                  <div class="d-flex">
                    <i class="mdi mdi-arrow-right-thin-circle-outline font-size-16 fg-green pe-3 d-none d-sm-inline-block"/>
                    <div>
                      <div class="semi-bold font-size-16">
                        {$_('trial.step1.webhook_url')}
                      </div>
                      <div class="font-size-14 py-2">
                        {$_('trial.step1.webhook_hint')}
                      </div>
                    </div>
                  </div>
                  <form data-parsley-validate="" on:submit|preventDefault={changeSettings} class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center gap-3">
                    <input type="text" bind:value={webhook} id="webhook-url" class="form-control"/>
                    <Button class="btn btn-success btn-lg">
                      {$_('save')}
                      {#if webhookSaving}
                        <Spinner size="sm"/>
                      {/if}
                    </Button>
                  </form>
                </div>
              </div>
              <div class="text-center">
                <Link to="/inbox" class="btn btn-lucky-green wide px-3 px-sm-5">
                  {$_('trial.continue')}
                </Link>
              </div>
              <div class="text-center mt-4">
                <Container fluid>
                  <Row class="justify-content-center">
                    <Col lg={6} md={8} xl={4} xxl={4}>
                      <Card on:click={()=> window.open(`https://1msg.io/blog/`, "_blank")}>
                        <div class="card-body d-flex flex-column pointer border-transparent-green border">
                          <div>
                            <div class=" align-items-center gap-2 mb-3 text-center">
                              <div class="overflow-hidden text-center">
                                <div class="fw-600 font-size-20 text-center ">{$_('trial.read_blog_title')}</div>
                              </div>
                            </div>
                            <div class="ps-2  d-flex flex-row  ">
                              {$_('trial.read_blog_description')}
                            </div>
                            <div class="text-center pt-3">
                              <button class="btn btn-outline-success w-50" type="button">
                                <span class="text-break">
                                   {$_('documentation.read')}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col lg={6} md={8} xl={4} xxl={4}>
                      <Card on:click={() => window.open($_('menuitems.help_center.href'), "_blank")}>
                        <div class="card-body d-flex flex-column pointer border-transparent-green border">
                          <div>
                            <div class="align-items-center gap-2 mb-3 text-center">
                              <div class="overflow-hidden text-center">
                                <div class="fw-600 font-size-20 text-center ">{$_('trial.read_knowledge_base_title')}</div>
                              </div>
                            </div>
                            <div class="ps-2 d-flex flex-row">
                              {$_('trial.read_knowledge_base_description')}
                            </div>
                            <div class="text-center pt-3">
                              <button class="btn btn-outline-success w-50" type="button">
                                <span class="text-break">
                                   {$_('documentation.read')}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
            {/if}
          </CardBody>
        </Card>
      </div>
    </Container>
  </div>
{/if}

<style>
    .trial_card {
        max-width: 1200px;
    }

    .phoneText {
        white-space: pre-wrap;
        word-break: break-word;
    }
</style>
