<script>
  import {Offcanvas, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {navigate} from "svelte-routing";
  import {user, trialBar} from "../../helpers/store";
  import {
    closeHintsWindow,
    currentOpenHintStore,
    isOpenHintStore,
    minimizeHintsWindow, openHintsWindow,
    openHintsWindowForcefully,
  } from "../../helpers/hints";
  import HintBusiness from "./HintBusiness.svelte";
  import HintCoder from "./HintCoder.svelte";
  import HintInbox from "./HintInbox.svelte";

  let isOpen = false;

  function chooseAndCloseSelect(name) {
    openHintsWindowForcefully(name)
    isOpen = false;
  }

</script>
{#if $currentOpenHintStore}
  <div class="d-flex align-items-center pointer" on:click={openHintsWindow}>
    <svg class="ms-3" width="38" height="22" viewBox="0 0 38 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="27" cy="11" r="11" fill="#74788D"/>
      <path fill="white"
            d="M27 5C24.7944 5 23 6.68225 23 8.75H24.6C24.6 7.5095 25.6768 6.5 27 6.5C28.3232 6.5 29.4 7.5095 29.4 8.75C29.4 9.55175 29.0368 9.84875 28.2152 10.4412C27.9096 10.6618 27.5648 10.9108 27.2344 11.2198C26.1848 12.203 26.196 13.1728 26.2 13.25V14.75H27.8V13.2432C27.8 13.2253 27.8184 12.7925 28.3656 12.2802C28.6216 12.0402 28.9112 11.8317 29.1904 11.63C30.0384 11.018 31 10.325 31 8.75C31 6.68225 29.2056 5 27 5ZM26.2 15.5H27.8V17H26.2V15.5Z"/>
      <path d="M1 22V0H-1V22H1Z" fill="#DBDCDD" mask="url(#path-1-inside-1_4032_529)"/>
    </svg>
  </div>
{:else}
  <Dropdown {isOpen} toggle={() =>isOpen=!isOpen} class="d-flex">
    <DropdownToggle class="d-flex align-items-center pointer" tag="div">
      <svg class="ms-3" width="38" height="22" viewBox="0 0 38 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="27" cy="11" r="11" fill="#74788D"/>
        <path fill="white"
              d="M27 5C24.7944 5 23 6.68225 23 8.75H24.6C24.6 7.5095 25.6768 6.5 27 6.5C28.3232 6.5 29.4 7.5095 29.4 8.75C29.4 9.55175 29.0368 9.84875 28.2152 10.4412C27.9096 10.6618 27.5648 10.9108 27.2344 11.2198C26.1848 12.203 26.196 13.1728 26.2 13.25V14.75H27.8V13.2432C27.8 13.2253 27.8184 12.7925 28.3656 12.2802C28.6216 12.0402 28.9112 11.8317 29.1904 11.63C30.0384 11.018 31 10.325 31 8.75C31 6.68225 29.2056 5 27 5ZM26.2 15.5H27.8V17H26.2V15.5Z"/>
        <path d="M1 22V0H-1V22H1Z" fill="#DBDCDD" mask="url(#path-1-inside-1_4032_529)"/>
      </svg>
    </DropdownToggle>
    <DropdownMenu end>
      <DropdownItem header>{$_("hints.title")}</DropdownItem>
      <DropdownItem key={0} on:click={()=>{chooseAndCloseSelect('HintCoder')}}>
        {$_('hints.coder.btn')}
      </DropdownItem>
      <DropdownItem key={1} on:click={()=>{chooseAndCloseSelect('HintBusiness')}}>
        {$_('hints.business.btn')}
      </DropdownItem>
      <DropdownItem key={2} on:click={()=>{chooseAndCloseSelect('HintInbox')}}>
        {$_('hints.inbox.btn')}
      </DropdownItem>
    </DropdownMenu>
  </Dropdown>
{/if}
<Offcanvas
    backdrop={false}
    class="Hints"
    isOpen={$isOpenHintStore && $currentOpenHintStore}
    placement="end"
    scroll={true}>
  <slot slot="header">
    <div class="Text me-2">{$_(`${$currentOpenHintStore}.name`)}</div>
    <div class="d-flex align-items-center gap-1">
      <button aria-label="Minimize" class="btn-minimize" on:click={minimizeHintsWindow} type="button"/>
      <button aria-label="Close" class="btn-close" on:click={closeHintsWindow} type="button"/>
    </div>
    <div
        class="position-absolute d-flex align-items-center bg-white {isOpenHintStore && $currentOpenHintStore?'HintsLabelIcon':''} {$trialBar?.show ? 'HintsLabelIconAlert_bar' : ''}">
      <svg fill="none" height="22" viewBox="0 0 38 22" width="38" xmlns="http://www.w3.org/2000/svg">
        <circle cx="27" cy="11" fill="#74788D" r="11"/>
        <path d="M27 5C24.7944 5 23 6.68225 23 8.75H24.6C24.6 7.5095 25.6768 6.5 27 6.5C28.3232 6.5 29.4 7.5095 29.4 8.75C29.4 9.55175 29.0368 9.84875 28.2152 10.4412C27.9096 10.6618 27.5648 10.9108 27.2344 11.2198C26.1848 12.203 26.196 13.1728 26.2 13.25V14.75H27.8V13.2432C27.8 13.2253 27.8184 12.7925 28.3656 12.2802C28.6216 12.0402 28.9112 11.8317 29.1904 11.63C30.0384 11.018 31 10.325 31 8.75C31 6.68225 29.2056 5 27 5ZM26.2 15.5H27.8V17H26.2V15.5Z"
              fill="white"/>
      </svg>
    </div>
  </slot>
  <HintCoder/>
  <HintBusiness/>
  <HintInbox/>
</Offcanvas>

<style>
    .btn-minimize {
        box-sizing: content-box;
        width: 1em;
        height: 1em;
        padding: 0.25em 0.25em;
        color: #000;
        background: transparent url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="150" height="150" viewBox="0 0 20 20"%3E%3Cpath fill="black" fill-rule="evenodd" d="M1 10a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Z" clip-rule="evenodd"%2F%3E%3C%2Fsvg%3E') center/1em auto no-repeat;
        border: 0;
        border-radius: 0.25rem;
        opacity: .5;
    }

    .btn-minimize:hover {
        color: #000;
        text-decoration: none;
        opacity: .75;
    }

    .Text {
        white-space: pre-wrap;
        word-break: break-word;
    }

    .HintsLabelIcon {
        top: 0;
        left: -51px;
        width: 51px;
        height: 70px;
        border-left: 4px solid #f6f6f6;
        border-top: 4px solid #f6f6f6;
        border-bottom: 4px solid #f6f6f6;
        border-bottom-left-radius: 40px;
        border-top-left-radius: 40px;
    }

    .HintsLabelIconAlert_bar {
        top: 41px;
    }
</style>
