import {get, writable} from 'svelte/store';
import {BasicDAO} from "./BasicDAO";
import {appClient} from '../store';

/**
 * @typedef {Object} BotUsers
 * @property {number} id - The ID of the object.
 * @property {number} shop_id - The ID of the shop.
 * @property {string} created_at - The creation date and time of the object.
 * @property {string} updated_at - The last update date and time of the object.
 * @property {Object} bot - The bot associated with the object.
 * @property {string} bot.name - The name of the bot.
 * @property {number} bot.bot_type - The type of the bot.
 * @property {number} bot.id - The ID of the bot.
 * @property {string} messenger_user_id - The ID of the messenger user.
 * @property {Object} state - The state object.
 * @property {string} lang_code - The language code.
 * @property {string} name - The name.
 * @property {?string} avatar_url - The URL of the avatar.
 * @property {?string} messenger_user_url - The URL of the messenger user.
 * @property {Object} store - The store information.
 * @property {number} store.id - The ID of the store.
 * @property {Array<Object>} store.active_flows - The active flows associated with the store.
 * @property {number} store.active_flows[].flow_id - The ID of the active flow.
 * @property {number} store.active_flows[].step_id - The ID of the active step.
 * @property {string} store.name - The name of the store.
 * @property {string} store.id_in_messenger - The ID of the store in the messenger.
 * @property {string} store.phone - The phone number of the store.
 * @property {?string} store.avatar_url - The URL of the store's avatar.
 * @property {string} store.lang_code - The language code of the store.
 * @property {string} store.start_parameter - The start parameter of the store.
 * @property {boolean} store.subscribed_to_bot - Indicates if the store is subscribed to the bot.
 * @property {Array<Object>} store.tags - The tags associated with the store.
 * @property {number} store.tags[].id - The ID of the tag.
 * @property {number} store.tags[].shop_id - The ID of the shop.
 * @property {string} store.tags[].name - The name of the tag.
 * @property {Object} store.user_vars - The user variables of the store.
 * @property {Array<Object>} store.saved_bank_cards - The saved bank cards of the store.
 */

/**
 * @type {BasicDAO<BotUsers>}
 */
const BotUsersDAO = new BasicDAO(new writable([]), 'api/bot/bot_users_v2');
const botUsersStore = BotUsersDAO.store;
let chosenBotUserId = writable(null);

const setChosenBotUserId = (id) => {
  chosenBotUserId.set(id);
};

const getExportUsers = (channel) => {
  const {url, shopId} = appClient.getCommonVars(channel);
  return appClient.getRequest(`${url}/api/bot/export_users/`, {shop_id: shopId}, true).then(data => {
    return data
  }, err => {
    return err
  });
};

export {
  BotUsersDAO,
  botUsersStore,
  chosenBotUserId,
  setChosenBotUserId,
  getExportUsers
}
