<script>
  import MessageInfo from "../MessageInfo.svelte";
  import Spinner from "../../../../../../Components/Spinners/SpinnerComponent.svelte";

  export let message;
  export let classes = '';
  export let direction;
  export let scheduled = false;

  const urlRegex = /\b(https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const checkUrl = /\b(https?:\/\/)/;
  const parts = (text) => {
    return String(text).trim()?.split(urlRegex) ?? [''];
  };
</script>

<div class="Wrapper {classes}">

  <div class="font-size-16 Text">
    {#if message.text}
      {#each parts(message.text) as part}
        {#if checkUrl.test(part)}
          <a href="{part}" target="_blank" rel="nofollow noopener">{part}</a>
        {:else}
          {part}
        {/if}
      {/each}
    {/if}
    <Spinner class="ssm"/>
    {#if !message.footer}
      <MessageInfo {message} {direction} {scheduled}/>
    {/if}
  </div>
  {#if message?.footer}
    <div class="TextFooter font-size-14 pt-1 Text">
      {#each parts(message.footer) as part}
        {#if checkUrl.test(part)}
          <a href="{part}" target="_blank" rel="nofollow noopener">{part}</a>
        {:else}
          {part}
        {/if}
      {/each}
      <MessageInfo {message} {direction} {scheduled}/>
    </div>
  {/if}
</div>

<style>
    .Wrapper {
        padding: 8px 12px;
    }

    .Text {
        white-space: pre-wrap;
        word-break: break-word;
    }

    .TextFooter {
        color: rgba(0, 0, 0, 0.4);
    }
</style>
