<script>
  import {Link, navigate} from "svelte-routing";
  import {Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "sveltestrap";
  import {_, date} from "svelte-i18n";


  export let project;


  const goToProject = () => {
    navigate(`/project/${project.id}`);
  }





</script>



<Card class="card-project projects" >
  <CardBody>
    <div class="d-flex align-items-start" on:click={goToProject}>
      <div class="avatar-md me-4">
            <span class="avatar-title rounded-circle bg-light text-danger font-size-16">
              {#if project.pic}
                <img src={project.pic} alt="" height="30"/>
              {:else}
                <img
                    src="/assets/images/projects/{project.creationTS % 15}.svg"
                    alt=""
                />
              {/if}
            </span>
      </div>

      <div class="flex-grow-1 overflow-hidden mt-4">
        <!--              <p class="subtitle">{project.description || ''}</p>-->
        <p class="subtitle">{$_('project.text')}</p>
        <h5 class="title">
          <Link to={`/project/${project.id}`} class="text-dark">
            {project.name}
          </Link>
        </h5>
        <p class="status text-success">{$_('active')}</p>
        <!--              <p class="status text-danger">Не активный</p>-->
        <!--                <p class="time">До 12 июня 2022</p>-->
      </div>
    </div>
  </CardBody>
</Card>
