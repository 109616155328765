<script>
    import {Row, Col, Card, CardBody} from "sveltestrap";
    import {onMount, onDestroy} from "svelte";
    import {paymentProfiles, user, channels, isUserCreatedAfter1July2024} from "../../helpers/store";
    import YookassaModal from "../../Components/Modals/YookassaModal.svelte";
    import UnsubscribeModal from "../../Components/Modals/UnsubscribeModal.svelte";
    import {defaultConst} from "../../common/constants";
    import {getRates, getPaymentProviders} from "../../helpers/getRates";
    import {_, date, locale} from "svelte-i18n";
    import CardPricing from "./CardPricing.svelte"
    import {postRequest} from "../../helpers/utils";
    import {addToast} from "../../helpers/toast";

    export let channel;
    export let location;
    export let checkBillingDetails;
    export let rateIndex = 0;
    export let corporateBillsSettingsOrg;

    let paymentProfilesLocal,
        paymentProfile = {},
        activeUser,
        payments = getPaymentProviders(),
        lang = 'en',
        loading = false,
        subscriptionTranslations,
        // activeTab = payments[lang][0].id,
        pricings = {
            'ru': [],
            'en': [],
            'es': []
        },
        yooModal = false,
        unsubModal = false;

    onMount(async () => {
        if (localStorage.getItem("I18N_LANGUAGE")) {
            lang = localStorage.getItem("I18N_LANGUAGE");
        }
    });

    async function updatePricing() {
        if (channel) {
            pricings = await getRates(channel.project, channel)
            // activeTab = payments[lang][0].id;
        }
    }

    async function updateProfile() {
        if (channel && channel.paymentProfileId) {
            paymentProfile = paymentProfilesLocal.find((item) => {
                return item.id === channel.paymentProfileId
            });
            if (paymentProfile) {
                if (paymentProfile.rate === '1 year') {
                    subscriptionTranslations = {
                        title: 'subscription.annual',
                        description: 'subscription.annual_description',
                        duration: 'subscription.per_year'
                    }
                } else {
                    subscriptionTranslations = {
                        title: 'subscription.monthly',
                        description: 'subscription.monthly_description',
                        duration: 'subscription.per_month'
                    }
                }
            }
        }
        updatePricing();
    }

    const unsub = paymentProfiles.subscribe(async (value) => {
        paymentProfilesLocal = value;
        paymentProfile = {};
        await updateProfile();
    });
    const unsub1 = user.subscribe((value) => {
        activeUser = value;
    });
    const unsub2 = channels.subscribe(async (value) => {
        let channelTmp = value.find((item) => {
            return item.id === channel.id
        });
        if (channelTmp !== channel) {
            channel = channelTmp;
            await updateProfile();
        }
    });
    const unsub3 = locale.subscribe((value) => {
        if (value) {
            lang = value;
            updatePricing();
        }
    });

    async function changeCard() {
        if (loading) return;
        if (!paymentProfile.paymentSystem) {
            return addToast({
                title: $_("error"),
                message: $_("something_went_wrong")
            });
        }
        loading = 'card';
        let params = {id: channel.id, token: activeUser.token, provider: paymentProfile.paymentSystem};

        let result = await postRequest(
            `${defaultConst.paymentUrl}/change-subscription-card/${params.provider}`,
            params
        );

        loading = false;
        if (!result || result.error) {
            addToast({
                title: $_("error"),
                message: result.error || $_("something_went_wrong")
            });
        } else {
            if (params.provider === "stripe" && result.data) {
                /*dist key - pk_test_51IOpMyHmQaZbF6i57IjzAfWC1ZwzRYz5WJT60aN2JHqzmlYI0xKqxrV7brxDrcsGLFznTZ8pcQKCpcgPKp0tBAix00dGGgIeCM*/
                /*msg key - pk_test_51LxyfcKzRtXrTd5YD6ALzzTrGaIGApiy2re0JF06lLJvIydFVZXtvQyv6dF88QiL50jXr5oezTRPIfiB1cWFF1ub00CDuBDmRe*/
                const stripe = Stripe('pk_live_51LxyfcKzRtXrTd5YEIZtN0Q18Q4NZL22WbhPAs4HT7771JN635eKuOyYgfqnNSOyLLbR3935Um7NEUgWOfCclsz600orXT85ou');
                if (result.data) {
                    return stripe.redirectToCheckout({sessionId: result.data});
                }
            } else if (params.provider === "yookassa" && result.data) {
                return toggleYoomodal(true, result.data)
            }
            addToast({
                title: $_("error"),
                message: $_("something_went_wrong")
            });
        }
    }

    async function unsubscribe() {
        if (loading) {
            addToast({
                title: $_("info"),
                message: $_("operation_is_running")
            });
            return;
        }
        loading = 'unsubscribe';
        let params = {id: channel.id, profile: channel.paymentProfileId};

        let result = await postRequest(
            `${defaultConst.paymentUrl}/unsubscribe/${channel.id}`,
            params
        );

        loading = false;
        if (!result || result.error) {
            addToast({
                title: $_("error"),
                message: result.error || $_("something_went_wrong")
            });
        } else {
            addToast({title: $_("saved")});
        }
    }

    function toggleYoomodal(active = false, yooToken = false) {
        if (active && yooToken) {
            yooModal = true;
            const checkout = new window.YooMoneyCheckoutWidget({
                confirmation_token: yooToken, //Токен, который перед проведением оплаты нужно получить от ЮKassa
                return_url: `https://my.1msg.io/channels-payments`, //Ссылка на страницу завершения оплаты
                error_callback(error) {
                    console.log(error);
                    //Обработка ошибок инициализации
                }
            });

            //Отображение платежной формы в контейнере
            setTimeout(async () => {
                checkout.render('yookassa-form');
            }, 300);
        } else {
            yooModal = false;
        }
    }

    onDestroy(() => {
        unsub();
        unsub1();
        unsub2();
        unsub3();
    });

    $: if(channel) {
      updateProfile();
    }
</script>

{#if channel}
    {#if paymentProfile && paymentProfile.active}
        <Card class="plan-box w-100">
            <CardBody class="p-3">
                <div class="d-flex">
                    <div class="flex-grow-1">
                        <div class="mb-2">
                            <h5 class="me-3 m-0 me-3">{$_(subscriptionTranslations.title)}</h5>
                            {#if paymentProfile?.subscriptionStatus}
                                <div class="mt-2 d-inline-block font-size-12 {paymentProfile.subscriptionStatus}_style">
                                    <i class="mdi"/>
                                    {$_('subscription.status.' + paymentProfile.subscriptionStatus)}
                                </div>
                            {/if}
                        </div>
                        <p class="text-muted m-0">{$_(subscriptionTranslations.description)}</p>
                    </div>
                    <div class="ms-3">
                        <div class="current-logo text-center">
                            <img src={`/assets/images/icons/${paymentProfile.paymentSystem}-logo.png`} alt="logo"/>
                        </div>
                    </div>
                </div>
                <div class="py-4">
                    <h2 class="text-primary display-5 fw-500 subscription_price">
                        <div class="sup">
                            <div class=" text-royal-blue text-soft"> {$_(paymentProfile.currency)}</div>
                        </div>{" "}
                        {paymentProfile.amount}
                        <span class="font-size-13 text-royal-blue text-soft">/{" "}{$_(subscriptionTranslations.duration)}</span>
                    </h2>
                </div>
                <div class="subscription-details">
                    {#if (paymentProfile.customer?.name)}
                        <div class="bold font-size-18 mb-2">
                            {paymentProfile.customer.name}
                        </div>
                    {/if}
                    {#if (paymentProfile.customer?.email)}
                        <div class="font-size-14 mb-3">
                            {paymentProfile.customer.email}
                        </div>
                    {/if}
                    <Row class="mb-2">
                        <Col class="font-size-14 fw-500 d-flex align-items-center">
                            {$_("subscription.subscribed")}
                        </Col>
                        <Col>
                            <div class="rounded p-1 text-center {location == 'billing' && 'bg-lavender text-primary' || 'text-lucky-green text-soft bg-lucky-green bg-soft'}">
                                {$date(paymentProfile.startTs, {format: "medium"})}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col class="font-size-14 fw-500 d-flex align-items-center">
                            {$_("subscription.nextPayment")}
                        </Col>
                        <Col>
                            <div class="rounded p-1 text-center {location == 'billing' && 'bg-lavender text-primary' || 'text-lucky-green text-soft bg-lucky-green bg-soft'}">
                                {$date((paymentProfile.nextPayment || channel.activeTill), {format: "medium"})}
                            </div>
                        </Col>
                    </Row>
                </div>
                {#if location !== 'billing'}
                    <Row class="justify-content-end">
                        <button class="btn btn-lg btn-outline-success rounded" on:click={() => unsubModal = true} type="button">
                            {#if loading == 'unsubscribe'}
                                <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                            {/if}
                            {$_("subscription.unsubscribe")}
                        </button>
                        <button class="mt-2 btn btn-success btn-lg waves-effect waves-light rounded" on:click={changeCard} type="button">
                            {#if loading == 'card'}
                                <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                            {/if}
                            {$_("subscription.change_card")}
                        </button>
                    </Row>
                {:else}
                    <div class="d-flex flex-column flex-md-row flex-lg-column flex-xxxl-row justify-content-between">
                        <button class="mt-2 btn btn-primary btn-lg waves-effect waves-light rounded" on:click={changeCard} type="button">
                            {#if loading == 'card'}
                                <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                            {/if}
                            {$_("subscription.change_card")}
                        </button>
                        <button class="mt-2 btn btn-lg btn-outline-primary rounded" on:click={() => unsubModal = true} type="button">
                            {#if loading == 'unsubscribe'}
                                <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                            {/if}
                            {$_("subscription.unsubscribe")}
                        </button>
                    </div>
                {/if}
            </CardBody>
        </Card>
        {#if paymentProfile.paymentSystem == 'yookassa'}
            {#if yooModal}
               <YookassaModal show={yooModal} {toggleYoomodal}/>
            {/if}
        {/if}
        {#if unsubModal}
            <UnsubscribeModal show={unsubModal} {channel} profile={paymentProfile.id} toggleModal={() => unsubModal = !unsubModal}/>
        {/if}
    {:else}
        <!-- <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
             {#each payments[lang] as payment}
                  <li class="nav-item" role="presentation">
                      <button class={activeTab === payment.id ? ' nav-link active' : 'nav-link'}
                      on:click={()=>{activeTab= payment.id}}
                      type="button" role="tab"
                      > {payment.name}
                      </button>
                  </li>
             {/each}
         </ul>-->
        <!-- <div class="tab-content" id="pills-tabContent">
            <TabContent> -->
                <!-- {#each pricings[lang] as payments} -->
                    <!-- <TabPane tabId={payments.payment.id} class="{activeTab === payments.payment.id ? 'active': ''}"> -->
                        <!-- <Row class="tab-pane fade show active {location !== 'billing' && 'justify-content-center' || 'justify-content-start '}" id="payment" role="tabpanel" aria-labelledby="pills-tab-payment"> -->
                            <!-- {#each payments.prices as pricing}
                                <Col lg="12" xl="6" xxl="4" class="d-flex">
                                    <CardPricing {pricing} user={activeUser} {channel} {checkBillingDetails} {lang}/>
                                </Col>
                            {/each} -->
                        <!-- </Row> -->
                    <!-- </TabPane> -->
                <!-- {/each} -->
            <!-- </TabContent> -->
        <!-- </div> -->
        {#if lang === 'ru' && isUserCreatedAfter1July2024()}
            {#if corporateBillsSettingsOrg}
                {#each pricings[lang] as payments}
                    {#each payments.prices.filter(({price}) => price.org === corporateBillsSettingsOrg) as pricing}
                        <Col lg="12" xl="6" xxl="4" class="d-flex">
                            <CardPricing {pricing} features={payments.features} marker={payments.active} user={activeUser} {channel} {checkBillingDetails} {lang}/>
                        </Col>
                    {/each}
                {/each}
            {:else}
             {#each pricings[lang] as payments}
                <Col lg="12" xxl="6" class="d-flex">
                    <CardPricing pricing={payments.prices[rateIndex]} features={payments.features} marker={payments.active} user={activeUser} {channel} {checkBillingDetails} {lang}/>
                </Col>
             {/each}
            {/if}
        {:else}
            {#each pricings[lang] as payments}
                {#each payments.prices as pricing}
                    <Col lg="12" xl="6" xxl="4" class="d-flex">
                        <CardPricing {pricing} user={activeUser} {channel} {checkBillingDetails} {lang}/>
                    </Col>
                {/each}
            {/each}
        {/if}
    {/if}
{/if}

<style>
    .subscription_price{
        display: flex;
        align-items: baseline;
        position: relative;
    }

    .subscription_price .sup{
        position: relative;
        width: 20px;
    }
    .subscription_price .sup div{
        font-size: 24px;
        position: absolute;
        top: -48px;
    }

    .active_style{
        padding: 2px 8px;
        color: #23B16D;
        background: #DAF4EB;
        border-radius: 4px;
    }

    .active_style > i::before{
        content: "\f012c";
    }

    .trailing_style{
        padding: 2px 8px;
        color: #556EE6;
        background: #E0E5FA;
    }
    .trailing_style > i::before{
        content: "\f0152";
    }

    .past_due_style, .unpaid_style{
        border-radius: 4px;
        padding: 2px 8px;
        background: #FDE1E1;
        color:  #F46A6A;
    }
    .past_due_style > i::before,
    .unpaid_style > i::before {
        content: "\f05d6";
    }
</style>
