<script>
  import {Spinner} from 'sveltestrap';

  export let title;
  export let state = false;
  export let loader = false;
  export let toggle = () => {};

  let checked = state;

  $: if (checked || state) {
    checked = state;
  }
</script>

<div class="card CardWithToggle">
  <div class="card-header {state ? 'active' : ''}">
    <span class="text-uppercase">{title}</span>
    <label class="custom-switch">
      <input type="checkbox" class="color-blue" bind:checked={checked} on:change={toggle}/>
      <div class="custom-switch-slider">
        <div class="custom-switch-knob"/>
      </div>
    </label>
  </div>
  <div class="card-body">
    <slot></slot>
  </div>
  {#if loader}
    <div class="position-absolute w-100 h-100 start-0 top-0 d-flex align-items-center justify-content-center bg-white bg-opacity-50">
      <Spinner/>
    </div>
  {/if}
</div>

<style>
  .CardWithToggle .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    font-size: 16px;
    background-color: #EEEEEE;
  }

  .CardWithToggle .card-header.active {
    background-color: #EEF1FD !important;
  }

  .CardWithToggle .card-body {
    padding: 16px 20px;
  }
</style>