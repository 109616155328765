import {_} from "svelte-i18n";
import {get} from "svelte/store";
import {
  DEFAULT_ADMINISTRATOR_DASHBOARD,
  DEFAULT_OPERATOR_DASHBOARD,
  DEFAULT_LOGIN_PAGE,
  defaultConst
} from "../common/constants";
import {navigate} from "svelte-routing";
import * as amplitude from '@amplitude/analytics-browser';
import {
  MESSAGE_STATUS_SCHEDULED,
  MESSAGE_STATUS_DELETED,
  MESSAGE_STATUS_DELIVERED,
  MESSAGE_STATUS_FAILED,
  MESSAGE_STATUS_NEW,
  MESSAGE_STATUS_READ,
  MESSAGE_STATUS_SENT
} from "./constants";


/**
 * Retry a promise function for a specified number of attempts with a delay between each attempt.
 *
 * @param {function} promiseFunction - The promise function to be retried.
 * @param {number} [maxAttempts=3] - The maximum number of attempts to retry the promise function.
 * @param {number} [delayBetweenAttempts=0] - The delay in milliseconds between each attempt.
 * @returns {Promise} - A Promise that resolves to the result of the promise function if it succeeds within the specified number of attempts, or rejects with the last error if it fails.
 */
export function retryPromiseFunction(promiseFunction, maxAttempts = 3, delayBetweenAttempts = 0) {
  return new Promise(async (resolve, reject) => {
    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      try {
        const result = await promiseFunction();
        resolve(result);
        return; // Exit the loop if successful
      } catch (error) {
        if (attempt < maxAttempts) {
          // Retry after a delay
          if (delayBetweenAttempts)
            await new Promise(resolve => setTimeout(resolve, delayBetweenAttempts));
        } else {
          reject(error);
        }
      }
    }
  });
}

/**
 *
 * @param {UserFirestore|null} user
 * @returns {null|undefined|boolean}
 */
export function isUserRegisteredInOneTouch(user) {
  if (user === null || user === undefined) return user;
  return !!(user.id && (user.appToken || user.devToken))
}

/**
 *
 * @param {string} value - current path from svelte routing
 * @returns {boolean}
 */
export function showShops(value) {
  return window.location.pathname === "/" || !!['inbox'].find(el => value.indexOf(el) !== -1);
}

export function handleLogin(rememberMe, email, password, user = null) {
  if (rememberMe) {
    localStorage.setItem('remember_me', '1');
    localStorage.setItem('remember_me_login', email);
    localStorage.setItem('remember_me_password', password);
  } else {
    localStorage.setItem('remember_me', '');
    localStorage.setItem('remember_me_login', '');
    localStorage.setItem('remember_me_password', '');
  }
  if (user) {
    if (user.isOperator) {
      navigate(DEFAULT_OPERATOR_DASHBOARD);
      // } else if (user.verificationNeeded) {
      //   navigate("/account-info")
    } else if (!user.trialCreated) {
      navigate("/trial")
    } else {
      navigate(DEFAULT_ADMINISTRATOR_DASHBOARD);
    }
  } else {
    navigate(DEFAULT_LOGIN_PAGE) // /account-info
  }
}

export function copyText(text) {
  var textArea = document.createElement("textarea");

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if the element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a
  // flash, so some of these are just precautions. However in
  // Internet Explorer the element is visible whilst the popup
  // box asking the user for permission for the web page to
  // copy to the clipboard.
  //

  // Place in the top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0;

  // Clean up any borders.
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  // Avoid flash of the white box if rendered for any reason.
  textArea.style.background = 'transparent';


  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';

  } catch (err) {

  }

  document.body.removeChild(textArea);
}


export async function postRequest(url, params, raw = false) {
  try {
    let rawResponse = await fetch(
      url,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
        method: "POST",
      }
    );
    if (raw)
      return rawResponse;
    return rawResponse.json();
  } catch (err) {
    return {error: get(_)("no_request")};
  }
}


export async function getRequest(link, params, raw = false, additionalParams = null) {
  try {
    let url = new URL(link);
    url.search = new URLSearchParams(params).toString();

    let rawResponse = await fetch(url, additionalParams)
    if (raw)
      return rawResponse;
    return rawResponse.json();
  } catch (err) {
    return {error: get(_)('no_request'), code: 'failed_to_fetch'};
  }
}

export async function getRequestFile(link, params) {
  try {
    let url = new URL(link);
    url.search = new URLSearchParams(params).toString();

    return fetch(url)
      .then(response => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();

            function pump() {
              return reader.read().then(({done, value}) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          }
        })
      })
      // Create a new response out of the stream
      .then(stream => new Response(stream))
      // Create an object URL for the response
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .catch(err => console.error(err));
  } catch (err) {
    console.error(link);
    console.error(err);
    return {error: get(_)('no_request')};
  }
}

export async function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })
};

export async function uploadProjectAvatar(projectId, file) {
  let base64File = await toBase64(file);
  return await postRequest(`${defaultConst.functionsUrl}/uploadFile`, {
    id: `project_${projectId}`,
    filename: `${projectId}_avatar`,
    file: base64File
  });

}

export async function timer(t) {
  return new Promise(res => setTimeout(res, t))
};

export function customNotifications(user) {
  let result = [];
  if (user?.creationTS > 1684751636000) {
    result = {
      en: [{
        avatar: 'M',
        title: "Free personalized help",
        text: "Avoid common pitfalls and start using WhatsApp API in the most effective way for the needs of your business. Save your time and effort! <br/><a href=\"https://chatting.page/1msg\" target=\"_blank\">Get in touch with us</a>"
      }],
      ru: [{
        avatar: 'M',
        title: "Мы хотим помочь!",
        text: "Воспользуйтесь нашим опытом, чтобы избежать распространенных ошибок и начать использовать WhatsApp API наиболее эффективным образом. Экономьте свое время и усилия!<br/><a href=\"https://chatting.page/1msg\" target=\"_blank\">Свяжитесь с нами</a>"
      }],
      es: [{
        avatar: 'M',
        title: "Ayuda personalizada gratuita",
        text: "Evite los errores más comunes y empiece a utilizar WhatsApp API de la forma más eficaz para las necesidades de su empresa. Ahorre tiempo y esfuerzo.<br/><a href=\"https://chatting.page/1msg\" target=\"_blank\">Programar una Consulta</a>"
      }]
    };
  }

  return result;
}

export function alertNotifications(user) {
  let result = [];
  // if(user?.migrated){
  //   result.push({
  //     en: {
  //       title: "Change API URL ",
  //       text: "Don't forget to update the API URL to the new one from 1msg"
  //     },
  //     ru: {
  //       title: "API URL ",
  //       text: "Не забудьте заменить API URL"
  //     }

  //   })
  // }
  return result;
}

class UnsubTrainItem {
  /**
   * @type {import('svelte/store').Unsubscriber}
   */
  value;
  next;

  /**
   *
   * @param {import('svelte/store').Unsubscriber}
   */
  constructor(value) {
    this.value = value
  }
}

export class UnsubTrain {
  /**
   * @type {UnsubTrainItem}
   */
  first;
  /**
   * @type {UnsubTrainItem}
   */
  last;
  /**
   * @type {import('svelte/store').Unsubscriber}
   */
  _add;

  get add() {
    return this._add;
  }

  /**
   *
   * @param {import('svelte/store').Unsubscriber} value - new value to be added
   */
  set add(value) {
    const newItem = new UnsubTrainItem(value);
    if (this.last) {
      this.last.next = newItem;
      this.last = newItem
    }
    if (!this.first) {
      this.first = newItem;
      this.last = newItem
    }
    this._add = value;
  }

  unsub() {
    let iterator = this.first
    if (iterator) {
      while (iterator?.next !== undefined) {
        iterator.value && iterator.value()
        iterator = iterator.next
      }
      iterator.value && iterator.value()
    }
    this.first = null;
    this.last = null;
  }
}


export function generateRandomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function avatarNumber(str = "00", count = 20) {
  const res = (str.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '').charCodeAt(0) % count).toString()
  return !!res && res !== "NaN" ? res.length === 1 ? `0${res}` : res : '00';
}

export function setCookie(name, value, extime) {
  const d = new Date();
  d.setTime(d.getTime() + extime);
  let expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function promisifyStore(store, comp = null) {
  return comp ? new PromisifyStore(store).setComparator(comp).listen() : new PromisifyStore(store).listen()
}

export function eventTracking(event, properties = {}) {
  amplitude.track(event, properties);
}

export function getMessageClassForStatus(status) {
  let res
  switch (status) {
    case MESSAGE_STATUS_NEW:
      res = 'time font-size-14';
      break;
    case MESSAGE_STATUS_SENT:
      res = 'check font-size-20';
      break;
    case MESSAGE_STATUS_FAILED:
      res = 'error font-size-14 text-danger';
      break;
    case MESSAGE_STATUS_DELIVERED:
      res = 'check-double font-size-20';
      break;
    case MESSAGE_STATUS_READ:
      res = 'check-double font-size-20 text-success';
      break;
    case MESSAGE_STATUS_DELETED:
      res = 'trash font-size-14';
      break;
    case MESSAGE_STATUS_SCHEDULED:
      res = 'hourglass font-size-12';
      break;
    default:
      res = '';
      break;
  }
  return res;
}

class PromisifyStore {
  sub;
  store;
  res;
  rej;
  minuteTimeout = 60000;
  timeout;
  promise = new Promise((res, rej) => {
    this.res = res;
    this.rej = rej
  })

  constructor(store) {
    this.store = store;

  }

  comparator = (value) => !!value

  listen() {
    this.sub = this.store.subscribe(value => {
      if (this.comparator(value)) {
        this.unsub()
        this.res(value)
      }
    })
    this.timeout = setTimeout(() => {
      this.unsub()
      this.rej(false)
    }, this.minuteTimeout)
    return this.promise
  }

  setComparator(someFunc) {
    this.comparator = someFunc;
    return this
  }

  unsub() {
    this.sub && this.sub()
    this.timeout && clearTimeout(this.timeout)
  }


}
