<script>
  import {Button, ButtonToolbar, Card, CardBody, Modal, ModalBody, Spinner, Row, Col} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import {navigate} from 'svelte-routing';
  import {chosenChannel, onetouchShops} from "../../helpers/store";
  import {layout} from "../../services/loaders";
  import {addToast} from "../../helpers/toast";
  import {promisifyStore} from "../../helpers/utils";
  import {BroadcastDAO, reqBroadcastCancel, broadcastStore} from "../../helpers/onetouch/BroadcastDAO";
  import NoChannelsWrapper from "../../Components/NoChannelsWrapper.svelte";
  import ChooseTheChannel from "../../Components/Modals/ChooseTheChannelModal.svelte";
  import TableWithPagination from "../../Components/TableWithPagination.svelte";
  import BroadcastDate from "./Components/elements/BroadcastDate.svelte";
  import CopyStoreSettings from "./CopyStoreSettings.svelte";

  let chosenChannelLocal;

  let broadcastLoader = true;
  let search = '';

  let chosenDeleteBroadcast = null;
  let loadingDeleteBroadcast = false;

  let chosenPauseBroadcast = null;
  let loadingPauseBroadcast = false;

  const unsub = chosenChannel.subscribe(value => {
    if (value === null || value === "") {
      chosenChannelLocal = null
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        broadcastLoader = true;
        layout(broadcastLoader);
        search = '';
        chosenChannelLocal = value;
        promisifyStore(chosenChannel).then(() => {
          BroadcastDAO.getOrLoadAll({
            limit: 1000000,
            ordering: '-id'
          }).then(() => {
            BroadcastDAO.startAutoReload(60*1000, {
              limit: 1000000,
              ordering: '-id'
            });
            broadcastLoader = false;
            layout(broadcastLoader)
          })
        })
      }
    }
  });

  onDestroy(() => {
    unsub();
    BroadcastDAO.stopAutoReload();
  });

  const onSearch = () => {
    return $broadcastStore.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
  };

  const copyBroadcast = broadcast => {
    let copyBroadcast = JSON.parse(JSON.stringify(broadcast));
    delete copyBroadcast.id;
    copyBroadcast.name = $_('copy_of') + ' ' + broadcast.name;
    copyBroadcast.status = 1;
    copyBroadcast.waba_template_id = broadcast?.waba_template?.id;

    BroadcastDAO.save(copyBroadcast).then(data => {
      navigate(`/broadcast/${data.id}`)
    })
  };

  const deleteRequestBroadcast = () => {
    loadingDeleteBroadcast = true;
    BroadcastDAO.delete(chosenDeleteBroadcast.id, {}, true).then(() => {
      addToast({title: $_('broadcast.delete_success')});
      chosenDeleteBroadcast = null;
      loadingDeleteBroadcast = false
    })
  };

  const pauseRequestBroadcast = (broadcast) => {
    loadingPauseBroadcast = true;
    reqBroadcastCancel(broadcast.id, chosenChannelLocal).then(data => {
      if (data.status === "ok") {
        addToast({title: $_('broadcast.pause_success')});
        BroadcastDAO.load(broadcast.id)
      } else {
        addToast({
          title: $_("error"),
          message: $_("something_went_wrong")
        });
      }
      loadingPauseBroadcast = false;
      chosenPauseBroadcast = null;
    });
  };

  const pauseBroadcast = (broadcast) => {
    if (broadcast.status === 3) {
      chosenPauseBroadcast = broadcast;
    }
    if (broadcast.status === 2) {
      pauseRequestBroadcast(broadcast);
    }
  };
</script>

<NoChannelsWrapper option="4">
<div class="page-content px-0 px-sm-2">
  {#if !broadcastLoader && $broadcastStore?.length}
    <div class="container-fluid">
      <div class="mb-4">
        <span class="font-size-20 fw-bold me-2">{$_('broadcast.title')}</span> {#if $onetouchShops.length > 1}<CopyStoreSettings/>{/if}
      </div>
      <Card>
        <CardBody>
          <Row class="flex-column-reverse flex-md-row">
            <Col md={8} class="mb-3 d-flex flex-wrap align-items-center gap-3">
              <div class="position-relative w-100">
                <input type="text" placeholder={$_('search')} bind:value={search}
                  class="border-1 rounded-2 text-muted form-control Search"/>
                <i class="bx bx-search-alt position-absolute font-size-20 text-muted top-0"/>
              </div>
            </Col>
            <Col md={4} class="mb-3 d-flex justify-content-md-end align-items-start flex-wrap gap-3">
              <Button color="success" class="py-2 px-3 d-flex" on:click={() => navigate('/broadcast/add')}>
                <i class="bx bx-plus me-2 font-size-20"/>
                <span class="font-size-14">{$_('broadcast.add')}</span>
              </Button>
            </Col>
          </Row>
          <TableWithPagination>
            <tr slot="thead">
              <th class="text-center" style="width: 14%">{$_('broadcast.broadcast_name')}</th>
              <th class="text-center" style="width: 14%">{$_('broadcast.table.channel')}</th>
              <th class="text-center" style="width: 12%">{$_('broadcast.table.status')}</th>
              <th class="text-center" style="width: 12%">{$_('broadcast.table.recipients')}</th>
              <th class="text-center" style="width: 12%">{$_('broadcast.table.delivered_sent')}</th>
              <th class="text-center" style="width: 12%">{$_('broadcast.table.open_rate')}</th>
              <th class="text-center" style="width: 12%">{$_('broadcast.table.answer_rate')}</th>
              <th class="text-center" style="width: 12%">{$_('broadcast.table.action')}</th>
            </tr>
            <tbody slot="tbody">
              {#each (search ? onSearch() : $broadcastStore) as broadcast}
                <tr class="font-size-15 fw-500">
                  <td class="text-center pointer" title={broadcast.name}
                    on:click={() => navigate(`/broadcast/${broadcast.id}`)}>
                    <h6 class="font-size-15 mb-1 mx-auto text-truncate NameBroadcast">
                      {#if broadcast.name}
                        {broadcast.name}
                      {:else}
                        &nbsp;
                      {/if}
                    </h6>
                    <BroadcastDate {broadcast}/>
                  </td>
                  <td class="text-center">
                    <h6 class="font-size-15 mb-1">{chosenChannelLocal?.name}</h6>
                    <span class="font-size-13">{chosenChannelLocal?.phone}</span>
                  </td>
                  <td class="text-center">
                    {#if broadcast.status === 1}
                      <span class="badge font-size-13 badge-soft-warning">
                        {$_('broadcast.table.draft')}
                      </span>
                    {/if}
                    {#if broadcast.status === 2}
                      <span class="badge font-size-13 badge-soft-info">
                        {$_('broadcast.table.planned')}
                      </span>
                    {/if}
                    {#if broadcast.status === 3}
                      <span class="badge font-size-13 badge-soft-primary">
                        {$_('broadcast.table.in_progress')}
                      </span>
                    {/if}
                    {#if broadcast.status === 4}
                      <span class="badge font-size-13 badge-soft-success">
                        {$_('broadcast.table.completed')}
                      </span>
                    {/if}
                  </td>
                  <td class="text-center">
                    {#if broadcast.to_be_sent_count}
                      {broadcast.to_be_sent_count}
                    {:else}
                      {broadcast?.segment_users_count ? broadcast?.segment_users_count : 0}
                    {/if}
                  </td>
                  <td class="text-center">
                    {broadcast.delivered_count ? broadcast.delivered_count : 0}
                    /
                    {broadcast.sent_count ? broadcast.sent_count : 0}
                  </td>
                  <td class="text-center">
                    {broadcast.read_count && broadcast.delivered_count ? parseFloat(((broadcast.read_count / broadcast.delivered_count) * 100).toFixed(1)) : 0}%
                  </td>
                  <td class="text-center">
                    {broadcast.write_count && broadcast.delivered_count ? parseFloat(((broadcast.write_count / broadcast.delivered_count) * 100).toFixed(1)) : 0}%
                  </td>
                  <td>
                    <ButtonToolbar class="justify-content-center align-items-center flex-nowrap gap-4">
                      <button class="d-flex p-0 border-0 bg-transparent font-size-24"
                        title={$_('broadcast.pause')}
                        disabled={!(broadcast.status === 2 || broadcast.status === 3)}
                        on:click={() => pauseBroadcast(broadcast)}>
                        <i class="mdi mdi-pause"/>
                      </button>
                      <button class="d-flex p-0 border-0 bg-transparent font-size-24"
                        title={$_('broadcast.edit')}
                        on:click={() => navigate(`/broadcast/${broadcast.id}`)}>
                        <i class="bx bx-edit"/>
                      </button>
                      <button class="d-flex p-0 border-0 bg-transparent font-size-24"
                        title={$_('broadcast.copy')}
                        on:click={() => copyBroadcast(broadcast)}>
                        <i class="bx bx-copy-alt"/>
                      </button>
                      <button class="d-flex p-0 border-0 bg-transparent font-size-24"
                        title={$_('broadcast.delete')}
                        on:click={() => chosenDeleteBroadcast = broadcast}
                        disabled={!(broadcast.status === 1 || broadcast.status === 2)}>
                        <i class="bx bx-trash"/>
                      </button>
                    </ButtonToolbar>
                  </td>
                </tr>
              {/each}
            </tbody>
          </TableWithPagination>
        </CardBody>
      </Card>
    </div>
  {/if}
  {#if chosenDeleteBroadcast}
    <Modal isOpen={chosenDeleteBroadcast} fade={true} toggle={() => {
      if (!loadingDeleteBroadcast) chosenDeleteBroadcast = null
    }} centered>
      <ModalBody>
        <h2 class="fw-bold mb-3 text-center">
          {$_('broadcast.delete')}
        </h2>
        <div class="mb-3 text-center">
          {@html $_('broadcast.delete_text', {values:{broadcast:`"${chosenDeleteBroadcast.name}"`}})}
        </div>
        <div class="text-center">
          <span class="d-inline-block me-3 font-size-14 pointer text-success"
            on:click={() => {if (!loadingDeleteBroadcast) chosenDeleteBroadcast = null}}>
            {$_('no')}
          </span>
          <Button color="danger" class="font-size-16 text-uppercase py-1 px-4"
            disabled={loadingDeleteBroadcast} on:click={deleteRequestBroadcast}>
            {#if loadingDeleteBroadcast}
              <Spinner size="sm"/>
            {:else}
              {$_('yes')}
            {/if}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  {/if}
  {#if chosenPauseBroadcast}
    <Modal isOpen={chosenPauseBroadcast} fade={true} toggle={() => {
      if (!loadingPauseBroadcast) chosenPauseBroadcast = null
    }} centered>
      <ModalBody>
        <h2 class="fw-bold mb-3 text-center">
          {$_('broadcast.pause')}
        </h2>
        <div class="mb-3 text-center">
          {$_('broadcast.pause_text')}
        </div>
        <div class="text-center">
          <span class="d-inline-block me-3 font-size-14 pointer text-success"
            on:click={() => {if (!loadingPauseBroadcast) chosenPauseBroadcast = null}}>
            {$_('no')}
          </span>
          <Button color="danger" class="font-size-16 text-uppercase py-1 px-4"
            disabled={loadingPauseBroadcast} on:click={() => pauseRequestBroadcast(chosenPauseBroadcast)}>
            {#if loadingPauseBroadcast}
              <Spinner size="sm"/>
            {:else}
              {$_('yes')}
            {/if}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  {/if}
  {#if !broadcastLoader && !$broadcastStore?.length}
    <div class="d-flex align-items-center justify-content-center text-center noBroadcast">
      <div class="p-3">
        <img src="/assets/images/wait_broadcast.svg" alt="wait for broadcast" class="mb-4 w-100"/>
        <div class="mb-2 font-size-22 fw-600">
          {$_('broadcast.no')}
        </div>
        <div class="font-size-20 mb-4 text-muted">
          {$_('broadcast.create_text')}
        </div>
        <Button color="success" class="d-flex align-items-center gap-2 px-3 font-size-18 mx-auto"
          on:click={() => navigate('/broadcast/add')}>
          <i class="bx bx-plus lh-1"/>
          {$_('broadcast.add')}
        </Button>
      </div>
    </div>
  {/if}
</div>
</NoChannelsWrapper>
<ChooseTheChannel/>

<style>
  .Search {
    padding-left: 40px;
    max-width: 500px;
  }

  .bx-search-alt {
    top: 0;
    left: 11px;
    line-height: 40px;
  }

  .noBroadcast {
    height: calc(100vh - 94px - 60px);
  }

  .noBroadcast img {
    max-width: 498px;
    min-height: 300px;
  }

  .NameBroadcast {
    max-width: 200px;
  }

  tbody tr, tbody button {
    color: #74788D;
  }

  tbody button:disabled {
    opacity: .5;
  }

  tbody button:not(:disabled):hover {
    color: #23B16D;
  }
</style>
