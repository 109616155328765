<script>
  import Time from "svelte-time";
  import {getMessageClassForStatus} from "../../../../../helpers/utils";

  export let message;
  export let classes = '';
  export let wrapperStyle='';
  export let direction;
  export let scheduled = false;

  let currentDate;
  let messageTime;
  let messageDate;

  let iconStatus = '';
  const status = () => {
    if (scheduled) {
      iconStatus = getMessageClassForStatus(16);
    } else {
      iconStatus = getMessageClassForStatus(message.status);
    }
  };

  $: if(message) {
    status();
    currentDate = new Date();
    messageTime = (scheduled ? message.starts_at : message.ts_in_messenger) * 1000;
    messageDate = (scheduled ? message.starts_at : message.ts_in_messenger) ? new Date(messageTime) : new Date();
  };
</script>

{#if (currentDate.getDate() === messageDate.getDate() && (currentDate - messageDate) < 24 * 60 * 60 * 1000)}
  <span class="time">
    <span dir="auto">
      <Time timestamp={messageTime} format="HH:mm"/> {#if direction && iconStatus}<i class="bx bx-{iconStatus}"/>{/if}
    </span>
    <div class="inner {classes}" title={messageDate}>
      <span dir="auto" class={wrapperStyle}>
        <Time timestamp={messageTime} format="HH:mm"/> {#if direction && iconStatus}<i class="bx bx-{iconStatus}"/>{/if}
      </span>
    </div>
  </span>
{:else}
  <span class="time">
    <span dir="auto">
      <Time timestamp={messageTime} format="{currentDate.getFullYear() === messageDate.getFullYear() ? 'D MMM' : 'YYYY.MM.DD'} HH:mm"/> {#if direction && iconStatus}<i class="bx bx-{iconStatus}"/>{/if}
    </span>
    <div class="inner {classes}" title={messageDate}>
      <span dir="auto" class={wrapperStyle}>
        <Time timestamp={messageTime} format="{currentDate.getFullYear() === messageDate.getFullYear() ? 'D MMM' : 'YYYY.MM.DD'} HH:mm"/> {#if direction && iconStatus}<i class="bx bx-{iconStatus}"/>{/if}
      </span>
    </div>
  </span>
{/if}

<style>
  .time {
    direction: ltr;
    display: inline-flex;
    float: right;
    font-size: 11px;
    height: 11px;
    margin-left: -4px;
    padding-right: 8px;
    line-height: 1;
    pointer-events: none;
    user-select: none;
    vertical-align: middle;
    visibility: hidden;
  }

  .time .inner {
    position: absolute;
    right: 0;
    bottom: 4px;
    display: flex;
    align-items: center;
    height: 11px;
    padding: inherit;
    line-height: 1;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.4);
    pointer-events: all;
    visibility: visible;
  }

  .time i {
    line-height: 11px;
    vertical-align: bottom;
  }
</style>
