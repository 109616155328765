<script>
    import {Link, navigate} from "svelte-routing";
    import {
        Card,
        CardBody,
        Col,
        Container,
        Row,
        Label,
        Input,
        Form,
        Button,
    } from "sveltestrap";
    import SideBlock from "../../Components/SideBlock.svelte";
    import {auth} from "../../helpers/firebase";
    import {_} from "svelte-i18n";
    import {DEFAULT_LOGIN_PAGE} from "../../common/constants";

    let email = "";
    const handleLoginForm = () => {
        auth.sendPasswordResetEmail(email, {url: location.protocol + '//' + location.hostname + '/login'})
            .then(data => {
                navigate(DEFAULT_LOGIN_PAGE);
            })
            .catch((error) => alert(error.message));
    };

</script>

<div>
    <Container fluid class="p-0">
        <Row class="g-0">
            <SideBlock text="auth"/>
            <Col xl={5}>
                <div class="account-pages">
                    <div class="account-container">
                        <div class="logo-block">
                            <img src="assets/images/logo-1msg-dark.svg" alt="logo" class="logo">
                        </div>
                        <div class="p-2">
                            <div class="alert alert-success text-center mb-4" role="alert">
                                {$_("auth.forgot_text")}
                            </div>

                            <div class="form-horizontal">
                                <div class="mb-3">
                                    <Label for="useremail" class="form-label">Email</Label>
                                    <Input
                                            type="email"
                                            class="form-control"
                                            id="useremail"
                                            placeholder={$_('auth.enter_email_placeholder')}
                                            bind:value={email}
                                    />
                                </div>

                                <div class="text-end">
                                    <button type="submit"
                                            on:click={handleLoginForm}
                                            class="btn btn-success w-md waves-effect waves-light btn-lg">{$_('auth.reset_btn')}</button>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 text-center">
                            <p>
                                {$_("auth.remember_it")}
                                <Link to={DEFAULT_LOGIN_PAGE} class="fw-medium text-success">
                                    {$_("auth.sign_in_here")}
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
</div>
