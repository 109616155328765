<script>
	import {createEventDispatcher} from "svelte";

	export let thisTextArea;
	export let value = '';
	export let placeholder = '';
	export let required = false;
	export let minRows = 1;
	export let classes = '';
	export let disabled = false;
	export let name = '';
	export let change = () => {};
	export let maxlength = '';
	export let minlength = '';
	export let pattern = '';

	const dispatch = createEventDispatcher();

	const onMessageTextareaKeyPress = (event) => {
		if (event.charCode === 13 && !event.shiftKey) {
			event.preventDefault();
			event.stopPropagation();

			dispatch('enterpress',{});
		}
	};

	$: minHeight = `${1 + minRows * 1.2}em`;
</script>

<div class="textAreaAutosize">
	<textarea class="form-control {classes}" {pattern} {maxlength} {minlength} on:keypress={onMessageTextareaKeyPress}
	bind:value {name} {placeholder} {disabled} {required} on:change={change} bind:this={thisTextArea}/>
	<div class="form-control text-break {classes}" style="min-height: {minHeight}">
		{#each (value ? value : '').split(/\n/g) as text}
			{text} <br/>
		{/each}
	</div>
</div>

<style>
	.textAreaAutosize {
		position: relative;
	}

	.textAreaAutosize div, .textAreaAutosize textarea {
		word-wrap: break-word;
		height: 100% !important;
		padding: 10px 0.75rem;
		font-weight: 400;
		color: #75788B;
	}

	.textAreaAutosize div {
		visibility: hidden;
	}

	.textAreaAutosize textarea {
		position: absolute;
		resize: none;
		overflow: hidden;
	}
</style>
