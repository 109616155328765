<script>
  import {Col} from "sveltestrap";
  import ABTestingComponent from "../../../Components/ABTestingComponent.svelte";
  import Zero from "./ProgressBarComponent/zero.svelte";
  import one from "./ProgressBarComponent/one.svelte";
</script>

<Col class="pt-5 pt-xl-0 ps-xl-5" lg={12} xl={6}>
  <div class="ps-xl-3 position-relative">
    <Zero />
  </div>
  <img src="/assets/images/ProgressBar_bg_icon.svg" class="check-bg" alt="icon"/>
</Col>

<style>
  .check-bg{
    position: absolute;
    height: 160px;
    bottom: 50px;
    right: -35px;
  }
</style>


