<script>
  import {Input, Label, FormGroup} from "sveltestrap";

  export let values;
  let row = {
    "id": "",
    "title": "",
    "description": ""
  };

  function addSections() {
    values.sections = [...values.sections, {title: "", rows: [{...row}]}]
  }

  function deleteSections(i) {
    values.sections = values.sections.filter((item, index) => index !== i);
  }

  function addRow(i) {
    values.sections[i].rows = [...values.sections[i].rows, {...row}]
  }

  function deleteRow(i, j) {
    values.sections[i].rows = values.sections[i].rows.filter((item, index) => index !== j);
  }
</script>

{#each values.sections as section, i}
  <FormGroup class="mb-3 d-flex">
    <div class="w-100 me-3">
      <FormGroup>
        <Label class="fw-600">title</Label>
        <Input type="text" bind:value={section.title}/>
      </FormGroup>
      {#each section.rows as row, j}
        <div class="d-flex">
          <div class="w-100 me-3">
            <FormGroup>
              <Label class="fw-600">id</Label>
              <Input type="text" bind:value={row.id}/>
            </FormGroup>
            <FormGroup>
              <Label class="fw-600">title</Label>
              <Input type="text" bind:value={row.title}/>
            </FormGroup>
            <FormGroup>
              <Label class="fw-600">description</Label>
              <Input type="text" bind:value={row.description}/>
            </FormGroup>
          </div>
          <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteRow(i, j)}>-</button>
        </div>
        {#if values.sections[i].rows.length !== j+1}
          <div class="mb-3 mt-2 line bg-success"/>
        {/if}
      {/each}
      <button type="button" class="btn addBtn rounded-circle" on:click={() => addRow(i)}>+</button>
    </div>
    <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteSections(i)}>-</button>
  </FormGroup>
  {#if values.sections.length !== i+1}
    <div class="mb-3 mt-2 line bg-success"/>
  {/if}
{/each}
<button type="button" class="btn addBtn rounded-circle" on:click={addSections}>+</button>

<style>
  .line {
    height: 2px;
  }

  .deleteBtn, .addBtn {
    color: #495057 !important;
    width: 40px;
    height: 40px;
    background-color: #EFF2F7;
    box-shadow: none;
  }

  .deleteBtn {
    line-height: 16px;
    font-size: 22px;
    margin-top: 28px;
  }

  .addBtn {
    line-height: 20px;
    font-size: 20px;
  }
</style>