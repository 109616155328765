<script>
  import {Modal, ModalBody, ModalFooter, ModalHeader, Button} from "sveltestrap";
  import {onMount, getContext, onDestroy} from "svelte";
  import {_, date} from "svelte-i18n";
  import {defaultConst} from "../../common/constants";
  import {addToast} from "../../helpers/toast";
  import {postRequest} from "../../helpers/utils";

  import {Link} from "svelte-routing";

  import Spinner from "../Spinners/SpinnerComponent.svelte";

  export let show;
  export let onCloseClick;
  export let profile;

  let loading = false;
  let amount = 0;

  const selectPaymentSystems = {
    RUB: [
            {
               value: 'stripe',
               name: 'Stripe'
             }, {
               value: 'yookassa',
               name: 'Yookassa'
             }
      ],
      USD: [
              {
                 value: 'stripe',
                 name: 'Stripe'
               }
            ],
  };

  const availableCurrencies = [
    {
      value: 'USD',
      name: 'USD'
    }, {
      value: 'RUB',
      name: 'RUB'
    }
  ]
  let paymentSystemSelect = selectPaymentSystems;
  let payment;
  let paymentSystem;
  onMount(() => {
      payment = paymentSystemSelect[profile.currency][0]
  });
  onDestroy(() => {

  })



  const submitForm = async () => {

    loading = true;

    let params = {
      amount: amount,
      paymentProfileId: profile.id,
      provider: payment,
      userToken: activeUser.userToken,
    };
    let result = await postRequest(
      `https://payment-dev.1msg.io/balance-payment`,
      params
    );

    loading = false;
    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong")
      });
    } else {
      onCloseClick();
      show = false;
      addToast({title: $_("saved")});

      if(payment === "stripe" && result.id){
          const stripe = Stripe('pk_live_51LxyfcKzRtXrTd5YEIZtN0Q18Q4NZL22WbhPAs4HT7771JN635eKuOyYgfqnNSOyLLbR3935Um7NEUgWOfCclsz600orXT85ou');
          if(result.id){
               return stripe.redirectToCheckout({sessionId: result.id});
          }
      }else if(payment === "yookassa"  && result.url){
            return window.location = result.url;
      }
      addToast({
              title: $_("error"),
              message: $_("something_went_wrong")
            });
    }
  };
</script>

<div class="add-event-modal">
  <Modal
      isOpen={show}
      fade={true}
      toggle={onCloseClick}
      class="modal-replehish" scrollable centered
  >
    <ModalBody>
      {#if loading}
        <Spinner/>
      {:else}
         <div class="form-group horizontal">
          <label for="amount">amount ({$_(profile.currency)})</label>
          <input type="number" bind:value={amount} id="amount" class="form-control">
        </div>
        <div class="form-group horizontal">
          <label for="payment">Платежная система:</label>
          <select type="select" name="payment" id="payment" bind:value={payment}>
            {#each selectPaymentSystems[profile.currency][0]['value'] as availablePayment}
              <option value="{availablePayment.value}">{availablePayment.name}</option>
            {/each}
          </select>
        </div>

          <div class="subscription-wrapper">
            Перейти к оплате
            <Button color="primary" size="lg" block on:click={submitForm}>Добавить</Button>
          </div>


      {/if}
    </ModalBody>


  </Modal>
</div>
