<script>
    import {Modal, ModalHeader, ModalBody} from "sveltestrap";
    import {_, date} from "svelte-i18n";
    import {defaultConst} from "../../common/constants";
    import {postRequest} from "../../helpers/utils";
    import {onMount} from "svelte";
    import Spinner from "../Spinners/SpinnerComponent.svelte";

    export let show;
    export let toggleModal;
    export let channel = false;
    export let uid = false;
    export let data = false;

    let loading = true;
    let receipts = [];

    onMount(async () => {
      if(!data) {
        if(channel) {
          try {
            let result = await postRequest(`${defaultConst.paymentUrl}/unpaid-invoices`, {id: channel.id});
            receipts = result.result || [];
          } catch (e) {}
        } else if(uid) {
          try {
            let result = await postRequest(`${defaultConst.paymentUrl}/unpaid-invoices-balance`, {uid: uid});
            receipts = result.result || [];
          } catch (e) {}
        }
        loading = false;
      }
    });

    async function pay(receipt) {
        let result = await postRequest(`${defaultConst.paymentUrl}/waba-receipt-payment/`, {id: receipt.id});
        if (result.url) {
            window.location = result.url;
        } else {
            console.log('err result', result)
        }
    }
</script>

<Modal isOpen={show} fade={true} size="md" toggle={toggleModal} class="modal-add receipts" scrollable centered>
    <ModalHeader toggle={toggleModal}>
        <span class="text-primary">{$_('receipts.open_receipts')}</span>
    </ModalHeader>
    <ModalBody>
        {#if loading}
            <div class="text-center">
                <Spinner/>
            </div>
        {:else}
            <div class="modal-content">
                {#if receipts.length}
                    <table class="w-100 text-center">
                        <thead class="table-light">
                            <tr>
                                <th class="align-middle">{$_("receipts.amount")} </th>
                                <th class="align-middle">{$_("receipts.date")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {#each receipts as receipt}
                            <tr>
                                <td class="p-1 td-link">
                                    {receipt.amount}{receipt.currency}
                                </td>
                                <td class="td-link">
                                    {$date(receipt.date, {format: "medium"})}
                                </td>
                                {#if (receipt.url)}
                                    <td>
                                        <a target="_blank" href={receipt.url}>{$_("receipts.pay")}</a>
                                    </td>
                                {:else}
                                    <td>
                                        <button type="button" class="border-0 p-0 bg-transparent text-start text-primary" on:click={() => pay(receipt)}>
                                            {$_("receipts.pay")}
                                        </button>
                                    </td>
                                {/if}
                            </tr>
                        {/each}
                        </tbody>
                    </table>
                {:else}
                    <p class="p-3 m-0 text-center">{$_("receipts.no_items")}</p>
                {/if}
            </div>
        {/if}
    </ModalBody>
</Modal>
