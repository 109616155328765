const languages = [
    {"name": "Afrikaans", "code": "af"},
    {"name": "Albanian", "code": "sq"},
    {"name": "Arabic", "code": "ar"},
    {"name": "Azerbaijani", "code": "az"},
    {"name": "Bengali", "code": "bn"},
    {"name": "Bulgarian", "code": "bg"},
    {"name": "Catalan", "code": "ca"},
    {"name": "Chinese (CHN)", "code": "zh_CN"},
    {"name": "Chinese (HKG)", "code": "zh_HK"},
    {"name": "Chinese (TAI)", "code": "zh_TW"},
    {"name": "Croatian", "code": "hr"},
    {"name": "Czech", "code": "cs"},
    {"name": "Danish", "code": "da"},
    {"name": "Dutch", "code": "nl"},
    {"name": "English", "code": "en"},
    {"name": "English (UK)", "code": "en_GB"},
    {"name": "English (US)", "code": "en_US"},
    {"name": "Estonian", "code": "et"},
    {"name": "Filipino", "code": "fil"},
    {"name": "Finnish", "code": "fi"},
    {"name": "French", "code": "fr"},
    {"name": "Georgian", "code": "ka"},
    {"name": "German", "code": "de"},
    {"name": "Greek", "code": "el"},
    {"name": "Gujarati", "code": "gu"},
    {"name": "Hausa", "code": "ha"},
    {"name": "Hebrew", "code": "he"},
    {"name": "Hindi", "code": "hi"},
    {"name": "Hungarian", "code": "hu"},
    {"name": "Indonesian", "code": "id"},
    {"name": "Irish", "code": "ga"},
    {"name": "Italian", "code": "it"},
    {"name": "Japanese", "code": "ja"},
    {"name": "Kannada", "code": "kn"},
    {"name": "Kazakh", "code": "kk"},
    {"name": "Kinyarwanda", "code": "rw_RW"},
    {"name": "Korean", "code": "ko"},
    {"name": "Kyrgyz (Kyrgyzstan)", "code": "ky_KG"},
    {"name": "Lao", "code": "lo"},
    {"name": "Latvian", "code": "lv"},
    {"name": "Lithuanian", "code": "lt"},
    {"name": "Macedonian", "code": "mk"},
    {"name": "Malay", "code": "ms"},
    {"name": "Malayalam", "code": "ml"},
    {"name": "Marathi", "code": "mr"},
    {"name": "Norwegian", "code": "nb"},
    {"name": "Persian", "code": "fa"},
    {"name": "Polish", "code": "pl"},
    {"name": "Portuguese (BR)", "code": "pt_BR"},
    {"name": "Portuguese (POR)", "code": "pt_PT"},
    {"name": "Punjabi", "code": "pa"},
    {"name": "Romanian", "code": "ro"},
    {"name": "Russian", "code": "ru"},
    {"name": "Serbian", "code": "sr"},
    {"name": "Slovak", "code": "sk"},
    {"name": "Slovenian", "code": "sl"},
    {"name": "Spanish", "code": "es"},
    {"name": "Spanish (ARG)", "code": "es_AR"},
    {"name": "Spanish (SPA)", "code": "es_ES"},
    {"name": "Spanish (MEX)", "code": "es_MX"},
    {"name": "Swahili", "code": "sw"},
    {"name": "Swedish", "code": "sv"},
    {"name": "Tamil", "code": "ta"},
    {"name": "Telugu", "code": "te"},
    {"name": "Thai", "code": "th"},
    {"name": "Turkish", "code": "tr"},
    {"name": "Ukrainian", "code": "uk"},
    {"name": "Urdu", "code": "ur"},
    {"name": "Uzbek", "code": "uz"},
    {"name": "Vietnamese", "code": "vi"},
    {"name": "Zulu", "code": "zu"}
]

export {languages}