import {get, writable} from 'svelte/store';
import {BasicDAO} from "./BasicDAO";

/**
 * Template component.
 * @typedef {Object} TemplateComponent
 * @property {string} type - Type of the component. Enum: "BODY"|"HEADER"|"FOOTER"|"BUTTONS"
 * @property {string} format - Format of the component. Enum: "TEXT"|"IMAGE"|"DOCUMENT"|"VIDEO" (Only for HEADER type)
 * @property {string} text - Text content of the component.
 * @property {object} example - Example object.
 * @property {Array} buttons - Array of objects representing buttons.
 * @property {string} language - Language of the template. Enum: "af"|"sq"|"ar"|"az"|"bn"|"bg"|"ca"|"zh_CN"|"zh_HK"|"zh_TW"|"hr"|"cs"|"da"|"nl"|"en"|"en_GB"|"en_US"|"et"|"fil"|"fi"|"fr"|"de"|"el"|"gu"|"he"|"hi"|"hu"|"id"|"ga"|"it"|"ja"|"kn"|"kk"|"ko"|"lo"|"lv"|"lt"|"mk"|"ms"|"mr"|"nb"|"fa"|"pl"|"pt_BR"|"pt_PT"|"pa"|"ro"|"ru"|"sr"|"sk"|"sl"|"es"|"es_AR"|"es_ES"|"es_MX"|"sw"|"sv"|"ta"|"te"|"th"|"tr"|"uk"|"ur"|"uz"|"vi"
 */

/**
 * Array of template components.
 * @typedef {Array<TemplateComponent>} TemplateComponentsProp
 */

/**
 Represents a data object with information about a broadcast.
 /**
 * @typedef {Object} WabaTemplate
 * @property {number} id - The ID of the template.
 * @property {string} shop_id - The ID of the shop.
 * @property {string} bot - The bot name.
 * @property {string} name - The name of the template.
 * @property {string} namespace - The namespace of the template.
 * @property {string} language - The language of the template.
 * @property {string} text - The text content of the template.
 * @property {string} category - The category of the template.
 * @property {TemplateComponentsProp} components - The components of the template.
 * @property {string} status - The status of the template.
 * @property {string} rejected_reason - The reason for rejection of the template.
 * @property {Array<Parameter>} parameters - The parameters of the template.
 * @property {string} created_at - The creation timestamp of the template.
 */

/**
 * @typedef {Object} Parameter
 * @property {number} id - The ID of the parameter.
 * @property {string} name - The name of the parameter.
 * @property {string} slug - The slug of the parameter.
 * @property {string} component_type - The component type of the parameter.
 * @property {number} type - The type of the parameter.
 * @property {number} button_type - The button type of the parameter.
 * @property {number} index - The index of the parameter.
 */




/**
 *
 * @type {BasicDAO<WabaTemplate>}
 */
const WabaTemplatesDAO = new BasicDAO(new writable([]), 'api/bot/waba_templates');
const wabaTemplatesStore = WabaTemplatesDAO.store;

export {
  WabaTemplatesDAO,
  wabaTemplatesStore
}
