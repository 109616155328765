<script>
  import {Button} from "sveltestrap";
  import {_} from "svelte-i18n";
  import MessageContent from '../MessageContent.svelte';
  import ModalScheduledMessages from "./ModalScheduledMessages.svelte";

  export let scheduledMessages;
  export let scheduledMessageGetter;
  export let viewModal;
  export let activeOperators = undefined;

  let isOpenModalSchedules = false;

  const scheduledEdit = (message) => {
    console.log('edit');
  };

  const scheduledDelete = (message) => {
    scheduledMessageGetter.deleteMessage(message.id);
  };

  $: if ($scheduledMessages.length === 0 && isOpenModalSchedules) {
    isOpenModalSchedules = false;
  }
</script>

{#if $scheduledMessages.length}
<li>
  <div class="d-flex align-items-center justify-content-center Scheduled-title">
    <div class="text-uppercase d-flex align-items-center gap-2 px-3 py-2 text-muted">
      <i class="bx bx-calendar font-size-20"/>
      <span class="font-size-14 d-none d-sm-inline-block">{$_('scheduled.title')}</span>
    </div>
  </div>
  <MessageContent {activeOperators} color="light-green" classes="ms-auto" message={$scheduledMessages[0]} scheduled={true} {scheduledDelete} {scheduledEdit} {viewModal}/>
  {#if $scheduledMessages[1]}
    <Button color="success" outline class="d-flex align-items-center ms-auto mt-2 gap-2 py-1 px-2 rounded-pill"
      on:click={() => isOpenModalSchedules = true}>
      <span class="font-size-12 px-1">{$_('scheduled.view_schedules')}</span>
    </Button>
  {/if}
</li>
{/if}

<ModalScheduledMessages bind:isOpen={isOpenModalSchedules} {scheduledMessages} {activeOperators} {scheduledDelete}/>

<style>
  .Scheduled-title::before, .Scheduled-title::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #EFF2F7;
  }
</style>
