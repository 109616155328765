<script>
  import {dismissToast, toasts} from "../../helpers/toast";
</script>

{#if $toasts}
  <section class="toast-container position-fixed bottom-0 end-0 p-2 p-lg-3">
    {#each $toasts as toast (toast.id)}
      {#if toast?.type === 'warning'}
        <div class="me-1 toastAlert-warning toast show" role="alert">
          <div class="px-3 py-2 position-relative">
            <button aria-label="Close" class="btn-close position-absolute" on:click={() => dismissToast(toast.id)}/>
            {#if toast.title}
              <div class="mb-1 me-3 font-size-18">{toast.title}</div>
            {/if}
            {#if toast.message}
              <div class="mb-2" class:me-3={!toast.title}>{@html toast.message}</div>
            {/if}
          </div>
        </div>
      {:else}
        <div class="me-1 toast show" role="alert">
          <div class="toast-header">
            <strong class="me-auto">
              {#if toast.title} {toast.title} {/if}
            </strong>
            <button aria-label="Close" class="btn-close" on:click={() => dismissToast(toast.id)}/>
          </div>
          <div class="toast-body">
            {#if toast.message} {@html toast.message} {/if}
          </div>
        </div>
      {/if}
    {/each}
  </section>
{/if}

<style>
  .toast-container {
    z-index: 2000;
  }

  .toastAlert-warning {
    color: #916c2e;
    background-color: #fcf0db;
    border-color: #fbe9c9;
  }

  .toastAlert-warning .btn-close {
    right: 8px;
  }
</style>
