<script>
  import UserHintsTypeWrapper from "./UserHintsTypeWrapper.svelte";
  import UserHintsStepComponent from "./UserHintsStepComponent.svelte";
  import {setContext} from "svelte";
  import {UserHintsHelper} from "./UserHintsHelper";
  import {channels, user, addChannelFirstTimeHelper} from "../../helpers/store";
  import {currentOpenHintStore} from "../../helpers/hints";
  import {_} from "svelte-i18n";
  import {Link} from "svelte-routing";
  import PrettyfiedCode from "../PrettyfiedCode.svelte";
  import TipsBanner from "./TipsBanner.svelte";

  let show = false;

  //****************CHANGEABLE THING********************
  const hintName = "HintCoder"; // should be unique
  //****************END CHANGEABLE THING********************


  /**
   * @type {UserHintsHelper} userHintsHelper
   */
  const userHintsHelper = new UserHintsHelper(hintName);
  setContext('UserHintsHelperContext', {
    UserHintsHelper: userHintsHelper
  });

  let openFlagWritableArray = userHintsHelper.openFlagWritableArray;

  /******DEFINE WHEN COMPONENT SHOULD BE SHOWN********/
  $:if ($channels?.length && !$channels?.filter(channel => channel?.status !== 'trial' && channel?.srv !== 'trial').length) {
    show = true
  }
  /******END WHEN COMPONENT SHOULD BE SHOWN********/

  if ($user?.user_hints?.HintCoder_1 === undefined) {
    $user.userHintsHintCoder_1 = false;
  }


  $:if ($currentOpenHintStore === hintName) { /// не трогать эту строку

    /******DEFINE WHEN STEPX IS READY********/
    if ($channels?.length && $channels?.find(channel => channel?.srv === 'trial')?.sandbox_phone) {
      userHintsHelper.changeDoneArray(0, true);
    }

    if ($user?.userHintsHintCoder_1) {
      userHintsHelper.changeDoneArray(1, true);
      delete $user.userHintsHintCoder_1;
    }


    if (window.location.pathname === '/sdk-generator') {
      userHintsHelper.changeDoneArray(4, true);
    }

    if (window.location.pathname === '/documentation') {
      userHintsHelper.changeDoneArray(5, true);
    }

    if ($channels?.length && $channels?.filter(channel => channel?.status !== 'trial' && channel?.srv !== 'trial').length) {
      userHintsHelper.changeDoneArray(8, true);
    }
    /*******END DEFINE*******/
  }
</script>

<UserHintsTypeWrapper {hintName} show={show && $user.generalCompanyType === 0}>
  <UserHintsStepComponent header={$_('hints.coder.1.title')}>
    <div class="text-secondary">
      {@html $_('hints.coder.1.contents')}
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent autoDoneOnOpen={true} header={$_('hints.coder.1.webhook.title')}>
    <div class="text-secondary">
      {@html $_('hints.coder.1.webhook.contents')}
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent autoDoneOnOpen={true} header={$_('hints.coder.1.2.title')}>
    <div class="text-secondary">
      {@html $_('hints.coder.1.2.contents')}
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent header={$_('hints.coder.1.3.title')}>
    <div class="text-secondary">
      {@html $_('hints.coder.1.3.contents')}
    </div>
  </UserHintsStepComponent>

  <UserHintsStepComponent header={$_('hints.coder.2.title')}>
    <div class="text-secondary">
      <p>{@html $_('hints.coder.2.contents1')}</p>
      <p>
        {@html $_('hints.coder.2.contents2')}
      </p>
      <p>
        {#if $channels?.length}
          <PrettyfiedCode code={
`POST https://sandbox.1msg.io/${$channels?.find(channel => channel?.srv === 'trial')?.id ?? '-'}/sendMessage
Content-Type: application/json
${JSON.stringify({
token: $channels?.find(channel => channel?.srv === 'trial')?.token ?? '',
body: 'Hello world',
phone: $channels?.find(channel => channel?.srv === 'trial')?.sandbox_phone ?? ''
}, null, 2)}`
          }/>
        {/if}
      </p>
      <p class="mb-0">
        {@html $_('hints.coder.2.contents3')}
      </p>
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent header={$_('hints.coder.2_1.title')}>
    <div class="text-secondary">
      <p>
        {@html $_('hints.coder.2_1.contents1')}
      </p>
      <p class="mb-0">
        {@html $_('hints.coder.2_1.contents2')}
      </p>
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent autoDoneOnOpen={true} header={$_('hints.coder.4.title')}>
    <div class="text-secondary">
      <p>
        {@html $_('hints.coder.4.contents1')}
        <Link to="/inbox">{@html $_('hints.coder.4.contents2')}</Link>
        {@html $_('hints.coder.4.contents3')}
        <Link to="/mobile-app">{@html $_('hints.coder.4.contents4')}</Link>
        {@html $_('hints.coder.4.contents5')}
      </p>
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent autoDoneOnOpen={true} header={$_('hints.coder.3.title')}>
    <div class="text-secondary">
      {@html $_('hints.coder.3.contents1')}
      <a href="https://help.1msg.io/1msg-knowledge-base/" target="_blank">{$_('hints.coder.3.contents2')}</a>
      <br/>
      <br/>
      <p class="mb-0">{@html $_('hints.coder.3.contents3')}</p>
    </div>
  </UserHintsStepComponent>
  <UserHintsStepComponent header={$_('hints.coder.5.title')}>
    <div class="text-secondary">
      <p>{$_('hints.coder.5.contents1')}</p>
      <p>{$_('hints.coder.5.contents2')}</p>
      <p>
        <span class='text-success pointer' on:click={addChannelFirstTimeHelper}>
          {$_('hints.coder.5.contents3')}
        </span>
      </p>
      <p>{$_('hints.business.7.contents4')}</p>
      <p class="mb-0">{@html $_('hints.coder.5.contents5')}</p>
    </div>
  </UserHintsStepComponent>
</UserHintsTypeWrapper>

{#if $currentOpenHintStore === hintName}
  <TipsBanner/>
{/if}
