<script>
  import {Col, Card, CardBody, Container, Row, Modal, ModalBody, Button} from "sveltestrap";
  import {Link, navigate} from "svelte-routing";
  import {_} from "svelte-i18n";
  // import {postRequest} from "../helpers/utils";
  // import {defaultConst} from "../common/constants";
  // import {addToast} from "../helpers/toast";
  import {projects, user, showProjectSelectModal, addChannel} from "../helpers/store";
  import {onDestroy, tick} from "svelte";
  import {layout} from "../services/loaders";

  export let option;
  export let org;

  let activeUser = {}, projectsList;

  const unsub = user.subscribe((value) => {
    activeUser = value;
  });

  const unsub1 = projects.subscribe(async (value) => {
    projectsList = value;
    projectsList = projectsList
  })

  let videoModal = false;

  function toggleVideoModal() {
    videoModal = !videoModal;
  }

  async function toTrial(){
    layout(true);
    if (!activeUser.trialCreated) {
      try {
        await Promise.allSettled([addChannel(projectsList[0]?.id, '', '', true), addChannel(projectsList[0]?.id, '', '', false, true)])
      } catch (e) {
      }
    }

    layout(false);
    showProjectSelectModal.set(false);
    await tick();
    navigate('/trial');
  }

  onDestroy(() => {
    unsub();
    unsub1();
  });
</script>

<div class="page-content">
  {#if option}
    <Container fluid>
      <div class="header-title">{$_(`automation.unavailable.option.${option}.title`)}</div>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <p class="text-blue-granite">
                {$_(`automation.unavailable.option.${option}.description`)}
              </p>
              <div class="d-flex gap-4 flex-column flex-sm-row mb-4">
                {#if option !== "4"}
                  <div class="d-inline-block VideoElement">
                    <div class="p-2 border rounded-2">
                      <div class="rounded-2 position-relative overflow-hidden pointer VideoPreview" on:click={toggleVideoModal}>
                        <div class="position-absolute top-0 start-0 w-100 h-100 Dark"/>
                        <div class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
                          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" class="bg-white rounded-circle">
                            <path fill-rule="evenodd" clip-rule="evenodd" fill="#34ba73"
                              d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60ZM38.279 34.451C41.3587 32.4852 41.3587 27.9876 38.279 26.0218L30.3673 20.9718C27.039 18.8473 22.6771 21.2378 22.6771 25.1864V35.2864C22.6771 39.2349 27.039 41.6254 30.3673 39.501L38.279 34.451Z"/>
                          </svg>
                        </div>
                        {#if option === "1"}
                          <img src="/assets/images/flow-bg-video.jpg" alt="flow-bg-video" class="w-100 h-100"/>
                        {:else if (option === "2")}
                          <img src="/assets/images/reply-bg-video.jpg" alt="reply-bg-video" class="w-100 h-100">
                        {:else if (option === "3")}
                          <img src="/assets/images/chat-bg-video.jpg" alt="chat-bg-video" class="w-100 h-100"/>
                        {/if}
                      </div>
                      <div class="mt-2 text-muted">
                        {$_('automation.unavailable.video_text')}
                      </div>
                      <div class="mt-2 text-primary pointer" on:click={toggleVideoModal}>
                        {$_('automation.unavailable.video_watch')}
                        <i class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-1"/>
                      </div>
                    </div>
                  </div>
                {/if}
                <div class="w-100">
                  <ul class="custom-ul">
                    <li>
                      <i class="bx bx-right-arrow-circle"/>
                      <div class="title mb-1">
                        {$_(`automation.unavailable.option.${option}.advantages.1.title`)}
                      </div>
                      <p class="description text-blue-granite">
                        {$_(`automation.unavailable.option.${option}.advantages.1.description`)}
                      </p>
                    </li>
                    <li>
                      <i class="bx bx-right-arrow-circle"/>
                      <div class="title mb-1">
                        {$_(`automation.unavailable.option.${option}.advantages.2.title`)}
                      </div>
                      <p class="description text-blue-granite">
                        {$_(`automation.unavailable.option.${option}.advantages.2.description`)}
                      </p>
                    </li>
                    <li>
                      <i class="bx bx-right-arrow-circle"/>
                      <div class="title mb-1">
                        {$_(`automation.unavailable.option.${option}.advantages.3.title`)}
                      </div>
                      <p class="description text-blue-granite">
                        {$_(`automation.unavailable.option.${option}.advantages.3.description`)}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <!--<div class="custom-alert custom-alert-primary mb-3">
                  <i class="mdi mdi-alert-circle-outline"/>{@html $_(`automation.unavailable.option.alert`)}
              </div>-->
              {#if org === '1msg_basic'}
                <p class="rounded-1 badge-soft-primary font-size-13 px-3 py-2">
                  {$_('automation.unavailable.option.alert2')}
                </p>
                <div class="d-flex gap-1 align-items-start flex-column d-md-block">
                  <Link to="/support" class="custom-button px-4 d-inline-flex align-items-center btn btn-primary">
                    {$_(`automation.unavailable.option.btn.3`)}
                  </Link>
                </div>
              {:else}
                <div class="d-flex gap-1 align-items-start flex-column d-md-block">
                  <Button color="success" on:click={toTrial} disabled={!projectsList?.length}
                    class="custom-button px-3 d-flex align-items-center">
                    {$_(`automation.unavailable.option.btn.1`)} <i class="ms-2 bx bx-right-arrow-alt font-size-18"/>
                  </Button>
                </div>
              {/if}
            </CardBody>
          </Card>
        </Col>
        <div class="col-5 p-0 BGimg">
          {#if option === "1"}
              <img src="/assets/images/flow-screen.svg" alt="bg-phone" class="d-block position-relative ms-auto"/>
          {:else if (option === "2")}
              <img src="/assets/images/reply-screen.svg" alt="bg-phone" class="d-block position-relative ms-auto"/>
          {:else if (option === "3" || option === "4")}
              <img src="/assets/images/chat-screen.svg" alt="bg-phone" class="d-block position-relative ms-auto"/>
          {/if}
        </div>
      </Row>
    </Container>
  {:else}
    <div class="row justify-content-center align-items-center unavailableCard">
      <Col md={8} xl={6} xxl={5}>
        <Card>
          <CardBody>
            <div class="p-4">
              <div class="text-center">
                <div class="avatar-md mx-auto">
                  <img src="/assets/images/icons/box-1.svg" alt="box"/>
                </div>
                <div class="p-2 mt-4">
                  {#if org === '1msg_basic'}
                    <h4>{$_('automation.unavailable.option.alert2')}</h4>
                    <div class="d-flex justify-content-center mt-4">
                      <Link to="/support" class="custom-button px-4 d-inline-flex align-items-center btn btn-primary">
                        {$_(`automation.unavailable.option.btn.3`)}
                      </Link>
                    </div>
                  {:else}
                    <h4>{$_("automation.unavailable.title")}</h4>
                    <p class="mb-4">{@html $_("automation.unavailable.text")}</p>
                  {/if}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  {/if}
</div>

{#if videoModal}
  <Modal isOpen={videoModal} role="dialog" size="md" autoFocus={true} centered>
    <div class="modal-content border-bottom-0">
      <div class="modal-header">
        <button type="button" class="btn-close" on:click={toggleVideoModal}
          data-bs-dismiss="modal" aria-label="Close"/>
      </div>
      <ModalBody class="embed-responsive">
        {#if option === "1"}
          <iframe class="img-fluid" width="560" height="315" src="https://www.youtube-nocookie.com/embed/Up1t_r221u8"
            title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen/>
        {:else if (option === "2")}
          <iframe class="img-fluid"  width="560" height="315" src="https://www.youtube-nocookie.com/embed/51yQgi06i_M"
            title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen/>
        {:else if (option === "3")}
          <iframe class="img-fluid" width="560" height="315" src="https://www.youtube-nocookie.com/embed/sMR8YjeWjx0"
            title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen/>
        {/if}
      </ModalBody>
    </div>
  </Modal>
{/if}

<style>
  iframe.img-fluid {
    min-height: 300px
  }

  .BGimg {
    display: none;
  }

  .BGimg img {
    right: -12px;
  }

  @media screen and (min-width: 1500px) {
    .BGimg {
      display: inline;
    }
  }

  /* .unavailableCard {
    height: calc(100vh - 94px - 60px);
  } */

  .VideoPreview {
    height: 145px;
    background-color: #EFF2F7;
  }

  .VideoPreview img {
    object-fit: cover;
  }

  .VideoPreview .Dark {
    background-color: #495057;
    opacity: 0.1;
  }

  .VideoPreview:hover .Dark {
    opacity: 0.2;
  }

  @media (min-width: 576px) {
    .VideoElement {
      min-width: 235px;
    }
  }
</style>
