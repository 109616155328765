<script>
  import {Dropdown, DropdownMenu, DropdownToggle} from "sveltestrap";
  import {_} from "svelte-i18n";

  export let required = false;
  export let list;
  export let title;
  export let badgeColor = "PrimaryColor";
  export let checkedKey = 'checked';
  export let disabledKey = false;

  let isOpen = false;
  let select_all = false;
  let select_required = true;
  let disabled_all = false;

  const check = () => {
    let arr = list.filter(item => disabledKey ? item[disabledKey] === false : true);
    disabled_all = !arr.length;
    select_all = !!arr.length && arr.every(item => item[checkedKey] === true);
    if (required) {
      select_required = !!list.length && !list.find(item => item[checkedKey] === true);
    }
  };

  const toggle = () => {
    list = list.map(item => ({...item, [checkedKey]: (disabledKey ? !item[disabledKey] : true) ? select_all : item[checkedKey]}));
    check();
  };

  $: if (isOpen) {
    check();
  }
</script>

<div class="position-relative SelectDropdownCheckbox">
  {#if required}
    <select class="form-control" required={select_required}/>
  {/if}
  <Dropdown autoClose={false} {isOpen} toggle={() => isOpen = !isOpen}>
    <DropdownToggle color="light" class="form-control w-100 d-flex align-items-center justify-content-between" caret>
      {$_(title)} <i class="mdi mdi-chevron-down"/>
    </DropdownToggle>
    {#if list.length}
      <DropdownMenu class="w-100">
        <div class="scrollbar">
          <div>
            {#if list.length > 1}
              <label class="dropdown-item m-0">
                <div class="form-check text-truncate ps-4">
                  <input class="form-check-input" type="checkbox" bind:checked={select_all} on:change={toggle} disabled={disabled_all}/>
                  <span class="form-check-label">{$_('SelectDropdownCheckbox.select_all')}</span>
                </div>
              </label>
            {/if}
            {#each list as item}
              <label class="dropdown-item m-0" title={item.label}>
                <div class="form-check text-truncate ps-4">
                  <input class="form-check-input" type="checkbox" bind:checked={item[checkedKey]} on:change={check} disabled={disabledKey ? item[disabledKey] : false}/>
                  <span class="form-check-label">{item.label}</span>
                </div>
              </label>
            {/each}
          </div>
        </div>
      </DropdownMenu>
    {/if}
  </Dropdown>
  <div class="badges">
    {#each list as item}
      {#if item[checkedKey]}
        <span class="badge {badgeColor}">
          {item.label} <i class="mdi mdi-close pointer" on:click={() => {item[checkedKey] = false; check()}}/>
        </span>&nbsp;
      {/if}
    {/each}
  </div>
</div>

<style>
  select {
    position: absolute;
    top: 0;
  }

  .SelectDropdownCheckbox .scrollbar {
    max-height: 300px;
    overflow-y: auto;
  }

  .SelectDropdownCheckbox .scrollbar::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  .SelectDropdownCheckbox .scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .SelectDropdownCheckbox .badges {
    line-height: 1.8;
  }

  .SelectDropdownCheckbox .badges .badge {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    padding: 3px 6px 3px 8px;
  }

  .SelectDropdownCheckbox .badges .badge.PrimaryColor {
    color: #556ee6;
    background-color: rgba(85, 110, 230, 0.180392);
  }
  
  .SelectDropdownCheckbox .badges .badge.DangerColor {
    color: #F46A6A;
    background-color: rgba(230, 85, 85, 0.18);
  }
</style>