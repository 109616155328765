<script>
  import {Input, FormGroup, Label} from "sveltestrap";

  export let values;
</script>

<FormGroup>
  <div class="d-flex align-items-center">
    <Input type="checkbox" id="is_cart_enabled" bind:checked={values.params.is_cart_enabled}/>
    <Label class="fw-600 m-0" for="is_cart_enabled">is_cart_enabled</Label>
  </div>
</FormGroup>
<FormGroup>
  <div class="d-flex align-items-center">
    <Input type="checkbox" id="is_catalog_visible" bind:checked={values.params.is_catalog_visible}/>
    <Label class="fw-600 m-0" for="is_catalog_visible">is_catalog_visible</Label>
  </div>
</FormGroup>