<script>
  import {_} from "svelte-i18n";
  import WhatsappTemplatesModal from "../../../../../Components/Modals/WhatsappTemplatesModal.svelte";

  export let sendTemplateMessage;
  export let sendScheduledTemplateMessage;
  export let disabled = false;

  let data = {};
  let isOpenTemplates = false;

  const send = () => {
    sendTemplateMessage(data.waba_template_id, data.template_data)
  };

  const sendScheduled = (startsAt) => {
    sendScheduledTemplateMessage(data.waba_template_id, data.template_data, startsAt)
  };
</script>

<button type="button" title={$_('chat_inbox.MessageChat.template')}
  class="d-flex border-0 bg-transparent p-1 rounded-circle pointer {isOpenTemplates ? 'active': ''} {disabled ? 'disabled' : ''}"
  on:click={() => isOpenTemplates = true} {disabled}>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="black" fill-opacity="0.45"
      d="M19 5V7H15V5H19ZM9 5V11H5V5H9ZM19 13V19H15V13H19ZM9 17V19H5V17H9ZM21 3H13V9H21V3ZM11 3H3V13H11V3ZM21 11H13V21H21V11ZM11 15H3V21H11V15Z"/>
  </svg>
</button>

<WhatsappTemplatesModal bind:isOpenTemplates bind:data {send} {sendScheduled}/>

<style>
  .active {
    background: rgba(11,20,26,0.1) !important;
  }

  .disabled {
    opacity: 0.5;
  }
</style>