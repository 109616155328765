<script>
  import {Button, Modal, ModalBody, ModalHeader, Label, Input, Spinner} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {BotUsersDAO} from "../helpers/onetouch/BotUsersDAO";
  import {addToast} from "../helpers/toast";
  import {appClient} from "../helpers/store";

  export let chosenChannelLocal;
  export let isOpen = false;
  export let botUsersLoader;
  export let close = () => {};

  let loading = false;
  let phone_contact = "", name_contact = "";

  const sendAddNewContact = async () => {
    loading = true;
    const name = name_contact ? {name: name_contact.replace(/\s+/g, ' ').trim()} : {};
    await appClient.postAddNewContact({
      phone: phone_contact.replace(/[^+\d]/g, ''),
      ...name
    }, chosenChannelLocal).then(data => {
      if (data.errors || data.phone) {
        loading = false;
        addToast({title: $_("error"), message: data.errors || data.phone});
        return
      }

      botUsersLoader = true;
      BotUsersDAO.loadAll().then(() => botUsersLoader = false);
      addToast({title: $_("segments.new_contact_added")});
      closeModal();
      loading = false;
    }, err => {
      console.error(err);
      loading = false;
      addToast({title: $_("error"), message: $_("something_went_wrong")})
    })
  };

  const closeModal = () => {
    isOpen = false;
    close();
    phone_contact = "";
    name_contact = "";
  };
</script>

{#if isOpen}
  <Modal isOpen={isOpen} fade={true} toggle={closeModal} size="md" centered>
    <ModalHeader toggle={closeModal} class="border-bottom-0 pb-0">
      <span class="font-size-20 fw-bold">
        {$_('chat_inbox.ChatsList.modal_adding_new_contact.adding_a_new_contact')}
      </span>
    </ModalHeader>
    <ModalBody>
      <form on:submit|preventDefault={sendAddNewContact}>
        <div class="form-group mb-3">
          <Label>{$_('chat_inbox.ChatsList.modal_adding_new_contact.phone_number')} *</Label>
          <Input type="text" bind:value={phone_contact} required
            placeholder={$_('chat_inbox.ChatsList.modal_adding_new_contact.phone')}/>
        </div>
        <div class="form-group mb-3">
          <Label>{$_('chat_inbox.ChatsList.modal_adding_new_contact.contact_name')}</Label>
          <Input type="text" bind:value={name_contact}
            placeholder={$_('chat_inbox.ChatsList.modal_adding_new_contact.name')}/>
        </div>
        <Button type=submit color="success" class="py-2 px-3 d-flex align-items-center gap-2"
          disabled={phone_contact.length < 11 || loading}>
          {#if loading}
            <Spinner size="sm"/>
          {/if}
          <span class="font-size-14">{$_('save')}</span>
        </Button>
      </form>
    </ModalBody>
  </Modal>
{/if}
