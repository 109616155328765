<script>
  import {_} from "svelte-i18n";
  import {
    MESSAGE_CONTENT_TYPE_IMAGE, MESSAGE_CONTENT_TYPE_CONTACT,
    MESSAGE_CONTENT_TYPE_VOICE, MESSAGE_CONTENT_TYPE_FILE,
    MESSAGE_CONTENT_TYPE_VIDEO, MESSAGE_CONTENT_TYPE_LOCATION,
    MESSAGE_CONTENT_TYPE_PRODUCT, MESSAGE_CONTENT_TYPE_ORDER,
  } from '../../../../../helpers/constants';
  import {AudioPlayer} from 'svelte-audio-player';
  import Voice from "./MessageContentTypes/Voice.svelte";

  export let data;
  export let close;
  export let chosenChat;

  function parseLocation() {
    const jsonObject = typeof data.message.text === 'string' ? JSON.parse(data.message.text) : data.message.text;
    return jsonObject?.name;
  }

  function parseContact() {
    const jsonObject = typeof data.message.text === 'string' ? JSON.parse(data.message.text) : data.message.text;
    return jsonObject.contacts;
  }

  function parseOrder() {
    const jsonObject = typeof data.message.text === 'string' ? JSON.parse(data.message.text) : data.message.text;
    return jsonObject?.meta?.product_items;
  }

  function parseProduct() {
    const jsonObject = typeof data.message.text === 'string' ? JSON.parse(data.message.text) : data.message.text;
    const product = jsonObject?.meta?.product;
    const sections = jsonObject?.sections;
    const text = jsonObject?.body;
    if (product) {
      return 'Product:' + product?.product_retailer_id;
    } else if (sections || text) {
      return text;
    }
  }
</script>

{#if data}
  <div class="d-flex align-items-center justify-content-between mb-3 Reply">
    <div class="d-flex align-items-center">
      <i class="bx bxs-share me-2 text-success font-size-24"/>
      <div class="border-start border-2 border-success ps-2 d-flex align-items-center gap-2">
        {#if data.message.content_type === MESSAGE_CONTENT_TYPE_IMAGE || data.message.image_url}
          <div class="rounded-2 Reply-media d-flex align-items-center justify-content-center text-muted">
            <img src={data.message.image_url} alt="photo" class="w-100 h-100 object-fit-cover"/>
          </div>
        {:else if data.message.content_type === MESSAGE_CONTENT_TYPE_VIDEO || data.message.video_url}
          <div class="rounded-2 Reply-media d-flex align-items-center justify-content-center text-muted">
            <video class="w-100 h-100 object-fit-cover">
              <source src={data.message.video_url}/>
            </video>
          </div>
        {:else if data.message.content_type === MESSAGE_CONTENT_TYPE_VOICE && data.message.file_url}
          <div class="rounded-2 Reply-media d-flex align-items-center justify-content-center text-muted">
            <i class="bx bx-headphone font-size-25"/>
          </div>
        {:else if data.message.content_type === MESSAGE_CONTENT_TYPE_FILE || data.message.file_url}
          <div class="rounded-2 Reply-media d-flex align-items-center justify-content-center text-muted">
            <i class="bx bx-file font-size-25"/>
          </div>
        {:else if data.message.content_type === MESSAGE_CONTENT_TYPE_LOCATION}
          <div class="rounded-2 Reply-media d-flex align-items-center justify-content-center text-muted">
            <i class="bx bxs-map font-size-25"/>
          </div>
        {:else if data.message.content_type === MESSAGE_CONTENT_TYPE_CONTACT}
          <div class="rounded-2 Reply-media d-flex align-items-center justify-content-center text-muted">
            <i class="bx bxs-user font-size-25"/>
          </div>
        {:else if data.message.content_type === MESSAGE_CONTENT_TYPE_ORDER}
          <div class="rounded-2 Reply-media d-flex align-items-center justify-content-center text-muted">
            <svg width="23" height="23" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1259_14593)">
                <path fill="#74788d"
                  d="M4.95825 12.75C4.17909 12.75 3.54867 13.3875 3.54867 14.1667C3.54867 14.9458 4.17909 15.5833 4.95825 15.5833C5.73742 15.5833 6.37492 14.9458 6.37492 14.1667C6.37492 13.3875 5.73742 12.75 4.95825 12.75ZM0.708252 1.41666V2.83332H2.12492L4.67492 8.20957L3.71867 9.94499C3.60534 10.1433 3.54159 10.3771 3.54159 10.625C3.54159 11.4042 4.17909 12.0417 4.95825 12.0417H13.4583V10.625H5.25575C5.15659 10.625 5.07867 10.5471 5.07867 10.4479L5.09992 10.3629L5.73742 9.20832H11.0145C11.5458 9.20832 12.0133 8.91791 12.2541 8.47874L14.7899 3.88166C14.8466 3.78249 14.8749 3.66207 14.8749 3.54166C14.8749 3.15207 14.5562 2.83332 14.1666 2.83332H3.69034L3.0245 1.41666H0.708252ZM12.0416 12.75C11.2624 12.75 10.632 13.3875 10.632 14.1667C10.632 14.9458 11.2624 15.5833 12.0416 15.5833C12.8208 15.5833 13.4583 14.9458 13.4583 14.1667C13.4583 13.3875 12.8208 12.75 12.0416 12.75Z"/>
              </g>
              <defs>
                <clipPath id="clip0_1259_14593">
                  <rect width="17" height="17" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        {/if}
        <div class="pe-2">
          <div class="text-success fw-600 text-break Reply-name">
            {#if data.direction}
              {!data.operator ? $_('chat_inbox.MessageChat.your_bot') : data.operator.profile.user.username}
            {:else}
              {$chosenChat.name}
            {/if}
          </div>
          <div class="text-muted text-break Reply-text">
            {#if data.message.content_type === MESSAGE_CONTENT_TYPE_LOCATION}
              {parseLocation() ?? $_('broadcast.location')}
            {:else if data.message.content_type === MESSAGE_CONTENT_TYPE_CONTACT}
              {#if parseContact().length <= 1}
                {parseContact()[0].name.formatted_name}
              {:else}
                {parseContact().length} {$_('broadcast.сontact')}
              {/if}
            {:else if data.message.content_type === MESSAGE_CONTENT_TYPE_ORDER}
              {parseOrder()?.length} product
            {:else if data.message.content_type === MESSAGE_CONTENT_TYPE_PRODUCT}
              {parseProduct()}
            {:else if data.message.text}
              {data.message.text}
            {:else}
              {data.message.content_type === MESSAGE_CONTENT_TYPE_IMAGE ? $_('broadcast.image') : ''}
              {data.message.content_type === MESSAGE_CONTENT_TYPE_VIDEO ? $_('broadcast.video') : ''}
              {#if data.message.content_type === MESSAGE_CONTENT_TYPE_VOICE}
                <AudioPlayer src={data.message.file_url}>
                  <Voice message={data.message} typeLastTime={true}/>
                </AudioPlayer>
              {/if}
              {data.message.content_type === MESSAGE_CONTENT_TYPE_FILE ? decodeURI(data.message.file_url.split('/').pop()) : ''}
            {/if}
          </div>
        </div>
      </div>
    </div>
    <button type="button" class="btn-close p-2 Reply-closeBtn" on:click={() => close(null)}/>
  </div>
{/if}

<style>
  .Reply-media {
    width: 42px;
    height: 42px;
    min-width: 42px;
    min-height: 42px;
    overflow: hidden;
    background-color: #EFF2F7;
  }
  .Reply-name {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 21px;
  }

  .Reply-text {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 42px;
  }

  .Reply-closeBtn {
    min-width: 1em;
  }
</style>
