<script>
  import {
    Col
  } from "sveltestrap";
  import {Link, navigate} from "svelte-routing";
  import {onMount, onDestroy} from "svelte";
  import {_, locale} from "svelte-i18n";


</script>
<style>
    :global(.card-border-2) {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16) !important;
    }


    .vertical-progress {
        position: relative;
        padding-left: 45px;
        list-style: none;
    }
    .vertical-progress::before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: 15px;
        width: 10px;
        height: 100%;
    }
    .vertical-progress-item {
        position: relative;
        counter-increment: list;
    }
    .vertical-progress-item:not(:last-child) {
        padding-bottom: 20px;
    }
    .vertical-progress-item::before {
        display: inline-block;
        content: '';
        position: absolute;
        left: -30px;
        height: 100%;
        width: 10px;
    }
    .vertical-progress-item::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: -37px;
        width: 20px;
        height: 20px;
        border: 2px solid #CCC;
        border-radius: 50%;
        background-color: #FFF;
    }
    .vertical-progress-item.is-done::before {
        border-left: 2px solid #23B16D;
    }
    .vertical-progress-item:last-child:before {
        border-left: none!important;
    }
    .vertical-progress-item.is-done::after {
        content: counter(list);
        padding: 1px;
        width: 20px;
        height: 20px;
        top: -3px;
        left: -40px;
        font-size: 14px;
        text-align: center;
        line-height: 16px;
        color: white;
        border: 2px solid #23B16D;
        background-color: #23B16D;
    }
    /* .vertical-progress-item.current::before {
        border-left: 2px solid #23B16D;
    }
    .vertical-progress-item.current::after {
        content: counter(list);
        padding-top: 1px;
        width: 25px;
        height: 25px;
        top: -4px;
        left: -40px;
        font-size: 14px;
        text-align: center;
        color: #23B16D;
        border: 2px solid #23B16D;
        background-color: white;
    }
    .vertical-progress strong {
        display: block;
    } */

    .check-bg{
        position: absolute;
        height: 160px;
        bottom: 50px;
        right: -60px;
        opacity: 0.2;
    }
</style>


<Col class="ps-sm-5" sm={12} md={6}>
    <div class="d-flex align-items-center">
        <img src="/assets/images/check-rounded-decagram.svg" alt="check"/>
        <div class="bold font-size-16 ms-2">
            {@html $_('channel.meta.step_check.text1')}
        </div>
    </div>
    <div class="my-4 font-size-14 text-secondary">{@html $_('channel.meta.step_check.text2')}</div>
    <ul class="vertical-progress text-secondary">
        {#each {length: 5} as t, i}
            <li class="vertical-progress-item is-done ">{@html $_(`channel.meta.step_check.list.${i}`)}</li>
        {/each}
    </ul>

    <img src="/assets/images/check-rounded-decagram.svg" class="check-bg" alt="check"/>
</Col>





