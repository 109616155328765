<script>
  import {Button, Modal, ModalBody} from "sveltestrap";
  import {_} from "svelte-i18n";
  import SpinnerComponent from "../Spinners/SpinnerComponent.svelte";

  export let isOpenTemplates = false;
  export let templates = [];
  export let chosenTemplate = null;
  export let callback = () => {};

  /**
   * @type {WabaTemplate[]}
   */
  let wabaTemplatesStoreLocal;
  let wabaTemplatesStoreInitial;
  let fullHeight = {};
  let search = '';

  $: if (templates) {
    if (Array.isArray(templates)) {
      for (let i = 0; i < templates.length; i++) {
        /**
         * @type WabaTemplate
         */
        const item = templates[i];
        if (fullHeight[`${item?.name}_${item?.language}`] !== undefined)
          continue;
        if (item.components?.length) {
          for (let j = 0; j < item.components.length; j++) {
            if (item.components[j].text?.length > 140) {
              fullHeight[`${item?.name}_${item?.language}`] = false;
              break;
            }
          }
        }
      }
    }
    wabaTemplatesStoreInitial = templates;
    filterTemplates();
  }

  function filterTemplates() {
    wabaTemplatesStoreLocal = wabaTemplatesStoreInitial.filter(item => item.status === 'approved' && (!search || item.name && item.name.indexOf(search) !== -1))
  }
</script>

{#if isOpenTemplates}
  <Modal isOpen={isOpenTemplates} fade={true} toggle={() => isOpenTemplates = false}>
    <ModalBody>
      <div class="px-2">
        <h4 class="font-size-18 fw-600 mb-3">{$_('broadcast.whatsapp_templates')}</h4>
        <div class="position-relative mb-3">
          <input type="text" placeholder={$_('search')} bind:value={search} on:input={filterTemplates}
            class="form-control border-1 rounded-2 text-muted"/>
          <i class="bx bx-search-alt position-absolute font-size-20 text-muted top-0"/>
        </div>
      </div>
      <div class="mb-3">
        {#if !wabaTemplatesStoreLocal}
          <div class="text-center">
            <SpinnerComponent/>
          </div>
        {:else}
          {#each wabaTemplatesStoreLocal as template}
            <div class="pt-2 px-2 Template {`${chosenTemplate?.name}_${chosenTemplate?.language}` === `${template?.name}_${template?.language}` ? 'active' : ''}">
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div on:click={() => {chosenTemplate = template; isOpenTemplates = false; callback()}}>
                <div class="d-flex align-items-center justify-content-between gap-2 mb-2">
                  <div class="text-truncate font-size-15 fw-600">{template.name}</div>
                  <div class="font-size-15 fw-600 text-muted text-uppercase">{template.language}</div>
                </div>
                {#each template.components as component}
                  {#if component.type === "HEADER"}
                    {#if component.format === "TEXT"}
                      <div class="text-muted Text font-size-15 fw-500">
                        {component.text}
                      </div>
                    {:else}
                      <div class="pt-1 mb-2">
                        <div class="text-muted font-size-15 d-inline-flex align-items-center gap-2 me-2">
                          {#if component.format === "IMAGE"}
                            <i class="bx bx-image font-size-22"/> {$_('broadcast.image')}
                          {:else if component.format === "VIDEO"}
                            <i class="bx bx-video font-size-22"/> {$_('broadcast.video')}
                          {:else if component.format === "DOCUMENT"}
                            <i class="bx bx-file font-size-22"/> {$_('broadcast.document')}
                          <!-- {:else}
                            <i class="bx bx-microphone font-size-22"/> {$_('broadcast.audio')} -->
                          {/if}
                        </div>
                      </div>
                    {/if}
                  {:else if component.type === "BODY" || component.type === "FOOTER"}
                    <div class="text-muted Text font-size-15 fw-500 {fullHeight[`${template?.name}_${template?.language}`] !== undefined? 'maxHeight140' : ''} {fullHeight[`${template?.name}_${template?.language}`] ? 'maxHeightMaxContent': ''}">
                      {#if template.category !== "AUTHENTICATION"}
                        {component.text ?? ''}
                      {:else}
                        {#if component.type === "BODY"}
                          {`{{1}} is your verification code.`}
                          {#if component?.add_security_recommendation}
                            {`For your security, do not share this code.`}
                          {/if}
                        {:else if component.type === "FOOTER"}
                          {`This code expires in ${component?.code_expiration_minutes} minutes`}
                        {/if}
                      {/if}
                    </div>
                  {:else if component.type === "BUTTONS"}
                    {#if component.buttons}
                      <div class="d-flex flex-wrap mb-3">
                        {#each component.buttons as btn}
                          <div class="d-flex align-items-center justify-content-center pe-2 text-muted" title={btn?.phone_number ?? btn?.url}>
                            {#if btn.type === 'PHONE_NUMBER'}
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                                <g clip-path="url(#clip0_987_13638)">
                                  <path fill="#74788d"
                                    d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.7533 9.8 10.4933 9.86 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"/>
                                </g>
                                <defs>
                                  <clipPath id="clip0_987_13638">
                                    <rect width="16" height="16" fill="white"/>
                                  </clipPath>
                                </defs>
                              </svg>
                            {:else if btn.type === 'URL'}
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                                <g clip-path="url(#clip0_987_13546)">
                                  <path fill="#74788d"
                                    d="M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z"/>
                                </g>
                                <defs>
                                  <clipPath id="clip0_987_13546">
                                    <rect width="16" height="16" fill="white"/>
                                  </clipPath>
                                </defs>
                              </svg>
                            {:else if btn.type === 'OTP'}
                              <i class="bx bx-copy-alt font-size-16 me-1"/>
                            {/if}
                            <span class="text-truncate font-size-14">{btn.text}</span>
                          </div>
                        {/each}
                      </div>
                    {/if}
                  {/if}
                {/each}
              </div>
              {#if fullHeight[`${template?.name}_${template?.language}`] !== undefined}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div class="text-center pointer position-relative Arrow {fullHeight[`${template?.name}_${template?.language}`] ? 'Open': ''}"
                  on:click={() => fullHeight[`${template?.name}_${template?.language}`] = !fullHeight[`${template?.name}_${template?.language}`]}>
                  <i class="mdi mdi-chevron-down font-size-18 position-relative d-inline-block"/>
                </div>
              {/if}
              <div class="BorderBottom"/>
            </div>
          {/each}
        {/if}
      </div>
      <div class="px-2 text-end">
        <Button outline color="danger" class="py-2 px-4" on:click={() => isOpenTemplates = false}>
          <span class="font-size-14">{$_('cancel')}</span>
        </Button>
      </div>
    </ModalBody>
  </Modal>
{/if}

<style>
  input {
    padding-left: 40px;
  }

  .bx-search-alt {
    top: 0;
    left: 11px;
    line-height: 40px;
  }

  .BorderBottom {
    border-bottom: 1px solid #DBDCDD;
  }

  .Template.active {
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px #23B16D;
    border-radius: 4px;
  }

  .Template:hover {
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px #23B16D;
    border-radius: 4px;
  }

  .Template:hover .BorderBottom {
    border-bottom-color: transparent;
  }

  .maxHeight140 {
    max-height: 140px;
  }

  .maxHeightMaxContent {
    max-height: max-content;
  }

  .Text {
    white-space: pre-wrap;
    word-break: break-word;
    overflow-y: hidden;
    padding-bottom: 15px;
    transition: .15s;
  }

  .Arrow {
    margin-top: -15px;
  }

  .Arrow::before {
    content: '';
    position: absolute;
    height: 63px;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50.52%, #FFFFFF 100%);
    bottom: 2px;
    left: 0;
  }

  .Arrow.Open::before {
    display: none;
  }

  .Arrow.Open .mdi-chevron-down {
    transform: rotate(180deg);
  }
</style>