export const DEFAULT_LOGIN_PAGE = "/login"
export const DEFAULT_REGISTER_PAGE = "/register"
export const DEFAULT_OPERATOR_DASHBOARD = "/inbox"
export const DEFAULT_ADMINISTRATOR_DASHBOARD = "/inbox"

export const defaultConst = {
    functionsUrl: "https://us-central1-msg-37f84.cloudfunctions.net",
    paymentUrl: "https://payment.1msg.io",
    //paymentUrl: "https://payment-dev.1msg.io",
    //paymentUrl: "http://localhost:3000",
    appUrl: "https://app.1msg.io",
    natsUrl: "app.1msg.io",
    emulatorUrl: "https://emulator.1msg.io",
    servicePrometheusAuthorization: "adminadmin4f9546173146:drZaR2WuAp4f9546173146"
  };

export const STATUSES = {
    NOT_INIT: 0,
    INIT: 1,
    LAUNCH: 2,
    QR: 3,
    AUTH: 4,
    ERROR: 5
}

