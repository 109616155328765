<script>
  import Highlight from "svelte-highlight";
  import language from "svelte-highlight/languages/c";
  import "svelte-highlight/styles/atom-one-dark-reasonable.css";

  export let key;
</script>

<!-- {#if key === 'c'}

{/if}

{#if key === 'c++'}

{/if} -->

{#if key === 'c-sharp'}
<Highlight {language} code={`using System.Collections.Generic;
using System.Diagnostics;
using OneMessage.io.Api;
using OneMessage.io.Client;
using OneMessage.io.Model;

Configuration config = new Configuration();
config.BasePath = "https://api.1msg.io/YOUR_INSTANCE_NUMBER";
// Configure API key authorization: token
config.ApiKey.Add("token", "YOUR_API_KEY");
// Uncomment below to setup prefix (e.g. Bearer) for API key, if needed
// config.ApiKeyPrefix.Add("token", "Bearer");

var apiInstance = new ChannelApi(config);

try
{
    // GetMe
    GetMe result = apiInstance.GetMe();
    Debug.WriteLine(result);
}
catch (ApiException e)
{
    Debug.Print("Exception when calling ChannelApi.GetMe: " + e.Message );
    Debug.Print("Status Code: "+ e.ErrorCode);
    Debug.Print(e.StackTrace);
}

`}/>
{/if}
