<script>
  import MessageInfo from "../MessageInfo.svelte";
  import Text from "./Text.svelte";
  import {_} from "svelte-i18n";

  export let message;
  export let viewModal;
  export let direction;
  export let scheduled = false;
  export let liteVersio = false;
</script>

<div class={!liteVersio ? "p-1" : ""}>
  {#if scheduled}
    <div class="{!liteVersio ? "imageScheduled" : ""} d-flex align-items-center gap-2 rounded-3 text-muted font-size-15 pointer"
      on:click={() => viewModal('image' , message.image_url, message.text)}>
      <i class="bx bx-image font-size-22"/> <span class="text-truncate">{$_('broadcast.image')}</span>
    </div>
    {#if !message.text}
      <div class="mt-1">
        <MessageInfo {message} {direction} {scheduled} classes={liteVersio ? "pe-0" : ""}/>
      </div>
    {/if}
  {:else}
    <div class="d-flex align-items-center justify-content-center overflow-hidden pointer rounded-3"
      on:click={() => viewModal('image' , message.image_url, message.text)}>
      <img src={message.image_url} alt={message.text} class="w-100"/>
    </div>
    {#if !message.text}
      <div class="position-absolute bottom-0 end-0">
        <MessageInfo {message} {direction} {scheduled} classes="text-white mb-2" wrapperStyle="rounded p-1 bg-dark bg-opacity-75"/>
      </div>
    {/if}
  {/if}
</div>
{#if message.text}
  <Text {message} {direction} {scheduled} {liteVersio} classes={!liteVersio ? "pt-0" : "pt-2"}/>
{/if}

<style>
  img {
    height: 250px;
    object-fit: cover;
    background-color: #f6f6f6;
  }

  .imageScheduled {
    padding: 7px;
    background-color: rgba(255, 255, 255, 0.2);
  }
</style>
