import {channels, getOnetouchShops, paymentRecords, showProjectSelectModal, user} from "../helpers/store";
import {tick} from "svelte";
import {navigate} from "svelte-routing";
import {promisifyStore} from "../helpers/utils";

export default function userTrialGuard() {
  return channels.subscribe(async (channelsTmp) => {
    if (channelsTmp?.length < 3) {
      const trialChannel = channelsTmp && channelsTmp.find(item => item.srv === 'trial')
      if (trialChannel && !trialChannel.sandbox_phone) {
        const _localPaymentRecords = await promisifyStore(paymentRecords) ?? [];
        const _localUser = await promisifyStore(user) ?? [];
        let _localShops;
        try {
          _localShops = await getOnetouchShops() ?? [];
        } catch (e) {
          _localShops = []
        }
        //here length is <2 as demo shop will be present here, and if not - then he do not have a trial instance and we just skip this part
        const noOnetouchOperatorShops = (_localShops === null || _localShops?.length < 2)
        if (!_localPaymentRecords?.length && noOnetouchOperatorShops && !_localUser?.isOperator) {
          showProjectSelectModal.set(false);
          await tick();
          navigate('/trial', {replace: true});
        }
      }
    }
  })
}
