<script>
  import {Modal, ModalBody, Button} from "sveltestrap";
  import {_} from "svelte-i18n";
  import Time from "svelte-time";

  export let send = () => {};
  export let callback = () => {};
  export let isOpen = false;

  let datetime;

  const formatDate = (currentDate = new Date()) => {
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // добавляем 1, так как месяцы в JavaScript начинаются с 0
    const day = ('0' + currentDate.getDate()).slice(-2);
    const hours = ('0' + currentDate.getHours()).slice(-2);
    const minutes = ('0' + currentDate.getMinutes()).slice(-2);

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    return formattedDate;
  };

  const addTimeToDate = (minutes = 0, hours = 0, days = 0) => {
    const newDate = new Date(datetime);
    newDate.setMinutes(newDate.getMinutes() + minutes);
    newDate.setHours(newDate.getHours() + hours);
    newDate.setDate(newDate.getDate() + days);
    datetime = formatDate(newDate);
  };

  $: if (isOpen) {
    datetime = formatDate();
    addTimeToDate(5);
  }

  const closeModal = () => {
    isOpen = false;
    callback();
  };

  const req = () => {
    send(new Date(datetime).getTime() / 1000);
    isOpen = false;
  };
</script>

<Modal centered fade={true} isOpen={isOpen} size="md" toggle={closeModal}>
  <ModalBody>
    <button aria-label="Close" class="btn-close float-end mb-1" on:click={closeModal} type="button"/>
    <div class="mb-4">
      <div class="d-flex flex-wrap gap-2 mb-3">
        <button class="btn  btn-outline-success p-0 position-relative DatetimeLocal">
          <div class="DatetimeLocal-title">{$_('scheduled.select_date_and_time')}</div>
          <input bind:value={datetime}
            class="p-0 position-absolute start-0 top-0 border-0"
            type="datetime-local"/>
        </button>
        <button class="btn border-dashed btn-outline-success p-2 position-relative DatetimeLocal"
          on:click={() => addTimeToDate(5)}>{$_('scheduled.5_minutes')}</button>
        <button class="btn border-dashed btn-outline-success p-2 position-relative DatetimeLocal"
          on:click={() => addTimeToDate(0, 1)}>{$_('scheduled.1_hours')}</button>
<!--        <button class="btn border-dashed btn-outline-success p-2 position-relative DatetimeLocal"-->
<!--          on:click={() => addTimeToDate(0, 0, 1)}>{$_('scheduled.1_days')}</button>-->
      </div>
      <div class="font-size-20 px-1">
        <h4 class="font-size-18 fw-600 mb-3">{$_('scheduled.schedule_label')}</h4>
        <Time format="YYYY.MM.DD HH:mm" timestamp={datetime}/>
      </div>
    </div>
    <Button class="px-3 pe-2 text-uppercase" color="success"
      on:click={req}>
      <span class="font-size-12">{$_('scheduled.schedule')}</span>
      <i class="bx ps-2 bx-calendar align-schedule align-bottom font-size-20"/>
    </Button>
  </ModalBody>
</Modal>

<style>
  .btn-close {
    margin-top: -10px;
    margin-right: -10px;
  }

  .DatetimeLocal:focus-within {
    color: #fff;
    background-color: #2ca67a;
    border-color: #2a9c72;
    box-shadow: 0 0 0 0.15rem rgba(82, 204, 160, .5);
  }

  .DatetimeLocal-title {
    padding: .47rem .75rem;
  }

  .DatetimeLocal input {
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  .DatetimeLocal input::-webkit-calendar-picker-indicator {
    width: 100%;
    height: 35.5px;
    cursor: pointer;
  }

  .border-dashed {
    border-style: dashed;
  }
</style>
