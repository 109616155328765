import {get, writable} from 'svelte/store';
import {BasicDAO} from "./BasicDAO";
import {appClient} from "../store";


/**

 Represents a data object with information about a broadcast.
 @typedef {Object} BroadcastData
 @property {number} id - The ID of the broadcast.
 @property {string} shop_id - The ID of the shop as a string.
 @property {number} starts_at - The start date and time as a string.
 @property {string} name - The name of the broadcast.
 @property {number} could_not_send_count - The count of messages that could not be sent.
 @property {number} read_count - The count of messages that have been read.
 @property {number} sent_count - The count of messages that have been successfully sent.
 @property {number} to_be_sent_count - The count of messages that are yet to be sent.
 @property {number} write_count - The count of messages that have been written back to.
 @property {number} segment_id - The ID of the segment.
 @property {SegmentData=} segment - The segment information.
 @property {Object=} template_data - The template data.
 @property {number} waba_template_id - The WABA template ID.
 @property {Object=} waba_template - The WABA template.
 @property {number} waba_template.id - The ID of the WABA template.
 @property {string} waba_template.name - The name of the WABA template.
 @property {string} waba_template.language - The language of the WABA template.
 @property {Object} waba_template.components - The components of the WABA template.
 @property {string} waba_template.category - The category of the WABA template.
 @property {string} waba_template.text - The text content of the WABA template.
 @property {BroadcastStatus} status - The status of the broadcast.
 @property {Tags[]} tags - The tags to add at the end of broadcast to user.
 */



/**
 * @enum {BroadcastStatus}
 */
let BroadcastStatus = {
  DRAFT : 1,
  PLANNED : 2,
  IN_PROGRESS : 3,
  COMPLETED : 4
}

/**
 *
 * @type {BasicDAO<BroadcastData>}
 */
const BroadcastDAO = new BasicDAO(new writable([]), 'api/broadcast/broadcast_v2');

const reqBroadcastCancel = (broadcast_id, channel) => {
  const {url, shopId} = appClient.getCommonVars(channel);
  return appClient.postRequest(`${url}/api/broadcast/broadcast_v2/cancel/`, {shop_id: shopId, broadcast_id})
}
/**
 * @type {import('svelte/store').Readable<BroadcastData[]>} store
 */
const broadcastStore = BroadcastDAO.store;
export {
  BroadcastDAO,
  reqBroadcastCancel,
  broadcastStore,
  BroadcastStatus
}
