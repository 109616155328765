<script>
  import {onMount} from "svelte";
  import {Container, Card, CardBody} from "sveltestrap";
  import {_} from "svelte-i18n";
  import NoChannelsWrapper from "../../Components/NoChannelsWrapper.svelte";
  import ChooseTheChannel from "../../Components/Modals/ChooseTheChannelModal.svelte";
  import TestingSendMessage from "./SendMessage.svelte";
  import TestingWebhookSimulate from "./WebhookSimulate.svelte";
  import TestRequests from "./TestRequestsV2.svelte";
  import {navigate} from "svelte-routing";


  export let params;
  let localRoutes = [
    {path: "send-message", component: TestingSendMessage, name: 'send-message'},
    {path: "test-requests", component: TestRequests, name: 'test-requests'},
    {path: "webhook-simulate", component: TestingWebhookSimulate, name: 'webhook-simulate'},
  ];

  onMount(() => {
    if(!localRoutes.find(({path}) => window.location.pathname.indexOf(path) !== -1)) {
      navigate(`/testing/send-message`);
    }
  });

  $:params && checkLocalRoutes()

  function checkLocalRoutes() {
    for (let i = 0; i < localRoutes.length; i++) {
      if (params.param === localRoutes[i].path) {
        localRoutes[i].active = true;
        localRoutes = localRoutes;
      } else {
        if (localRoutes[i].hasOwnProperty('active')) {
          delete localRoutes[i].active;
          localRoutes = localRoutes;
        }
      }
    }
  }

  const navigateLocal = (path) => {
    navigate(`/testing/${path}`)
  };
</script>

<NoChannelsWrapper available={true}>
  <div class="page-content">
    <Container fluid>
      <div class="header-title">{$_('menuitems.sandbox')}</div>
      <Card>
        <CardBody>
          <div class="row mx-0 mb-3 Steps">
            {#each localRoutes as route}
              <div class:active={route.active} on:click={()=>{navigateLocal(route.path)}}
                class="col-xl-4 py-3 px-4 d-flex align-items-center pointer Step">
                <span class="fw-600 font-size-15 text-truncate">
                  {$_(`sandbox.${route.name}`)}
                </span>
              </div>
            {/each}
          </div>
          {#each localRoutes as route}
            {#if params.param === route.path}
              <svelte:component params={{...route.params}} this={route.component}/>
            {/if}
          {/each}
        </CardBody>
      </Card>
    </Container>
  </div>
</NoChannelsWrapper>
<ChooseTheChannel/>

<style>
  .Steps .Step {
    background-color: #DAF4EB80;
  }

  .Steps .active.Step {
    background-color: #DAF4EB;
  }
</style>
