<script>
    import {Link, navigate} from "svelte-routing";
    import { Col, Container, Row, Form, Label, Input, Button } from "sveltestrap";
    import SideBlock from "../../Components/SideBlock.svelte";
    import {_, locale} from "svelte-i18n";
    import {onMount} from "svelte";
    import {auth} from "../../helpers/firebase";
    import {postRequest} from "../../helpers/utils";
    import {DEFAULT_ADMINISTRATOR_DASHBOARD, defaultConst} from "../../common/constants";
    import {addToast} from "../../helpers/toast";
    import Spinner from "../../Components/Spinners/SpinnerComponent.svelte";
    import {setupI18n} from "../../services/i18n";

    // Pages Components

    let params = {}, saving, password, email, emailProblem = false, emailInput, status,
        passwordType = "password", agreement = true;

    async function generatePassword(){
        if(!email) return;
        saving = true;

        let request = {
            email: email,
            token: params.token,
        };
        if(params.email !== email){
            request.email = params.email;
            request.new_email = email;
        }
        let result = await postRequest(
            `${defaultConst.functionsUrl}/migrate`,
            request
        );

        saving = false;
        if (!result || result.error || !result.result.token) {
            addToast({
                title: $_("error"),
                message: result.error || $_("something_went_wrong"),
                dismissible: true,
                timeout: 4_000,
            });
        } else {
            await auth.signInWithCustomToken(result.result.token).then().then(() => {
                navigate(DEFAULT_ADMINISTRATOR_DASHBOARD)
            })
            return;
            addToast({title: $_("saved")});
            status = 1;
        }
    };

    async function loginViaPassword(){
        auth.signInWithEmailAndPassword(email, password)
            .then().then(() => {
            navigate(DEFAULT_ADMINISTRATOR_DASHBOARD)
        })
            .catch((error) => alert(error.message));
    }

    async function setEmailProblem(){
        emailProblem = true;
        console.log(emailInput)
        setTimeout(() => {emailInput.focus()}, 20);
        /*что-то тут для поддержки*/

    }

    onMount(async () => {
        let lang = navigator.language || navigator.userLanguage;
        lang = (lang === 'ru-RU' && 'ru' || 'en');
        localStorage.setItem("I18N_LANGUAGE", lang);
        await setupI18n({withLocale: lang});

        let urlParams = new URLSearchParams(window.location.search);
        params = {
            email: urlParams.get('email'),
            token: urlParams.get('token'),
        }
        email = params.email;
    });
</script>

<div>
    <Container fluid class="p-0">
        <Row class="g-0">
            <SideBlock text="chatapi" />

            <Col xl={5}>
                <div class="account-pages  p-md-5 p-4">

                    <div class="account-container">
                        <div class="logo-block">
                            <img src="/assets/images/logo-1msg-dark.svg" alt="logo" class="logo">
                        </div>
                        {#if (status == 1)}
                            <div class="my-auto">
                                <!--<div>
                                    <h5 class="text-primary"> {$_('chatapi.auth.title')}</h5>
                                    <p class="text-muted"> {$_('chatapi.auth.subtitle')}</p>
                                </div>-->


                                <div class="mt-4">
                                    <form class="needs-validation form-horizontal" novalidate
                                          on:submit|preventDefault={loginViaPassword}>
                                        <div class="mb-3">
                                            <Label for="useremail" class="form-label">Email</Label>
                                            <Input
                                                    type="email"
                                                    class="form-control"
                                                    id="useremail"
                                                    value={email}
                                                    placeholder={$_('auth.enter_email_placeholder')}
                                                    required
                                            />
                                            <div class="invalid-feedback">Please Enter Email</div>
                                        </div>


                                        <div class="mb-3">
                                            <Label for="userpassword" class="form-label"
                                            > {$_('auth.password')}</Label
                                            >
                                            <div class="input-group auth-pass-inputgroup">
                                                <Input
                                                        class="form-control"
                                                        id="userpassword"
                                                        placeholder={$_('auth.enter_password_placeholder')}
                                                        aria-label="Password"
                                                        aria-describedby="password-addon"
                                                        bind:value={password}
                                                        bind:type={passwordType}
                                                        required
                                                />
                                                <Button
                                                        on:click={()=>{passwordType=passwordType==='password'?'text':'password'}}
                                                        color="light"
                                                        type="button"
                                                        id="password-addon"
                                                        class="btn-eye"
                                                >
                                                    <i
                                                            class="mdi mdi-eye-outline"
                                                    />
                                                </Button>
                                            </div>
                                            <div class="invalid-feedback">Please Enter Password</div>
                                        </div>

                                        <div class="form-check">
                                            <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    id="agreement-check2"
                                                    required
                                                    bind:checked={agreement}
                                            />
                                            <Label
                                                    class="form-check-label"
                                                    for="agreement-check2"
                                            >
                                                {@html $_('chatapi.auth.agreement')}

                                            </Label>
                                        </div>

                                        <div class="mt-4 d-grid">
                                            <button
                                                    color="primary"
                                                    class="btn btn-success w-md btn-lg"
                                                    type="submit">{$_('auth.login_btn')}
                                            </button>
                                        </div>

                                    </form>

                                </div>

                            </div>
                        {:else if (params.email && params.token)}
                            <div class="my-auto">
                                <div>
                                    <!-- <h5 class="text-primary"> {$_('chatapi.title')}</h5>-->
                                    <p class="list-group-item-info p-3 rounded  font-size-12"> {$_('chatapi.hint_text')}</p>
                                </div>


                                <div class="mt-4">
                                    <form class="needs-validation form-horizontal" novalidate
                                          on:submit|preventDefault={generatePassword}>
                                        <div class="mb-3">
                                            <Label for="useremail" class="form-label">Email</Label>
                                            <input
                                                    type="email"
                                                    class="form-control"
                                                    id="useremail"
                                                    bind:value={email}
                                                    placeholder="Email"
                                                    disabled={!emailProblem}
                                                    bind:this={emailInput}
                                            />
                                            <div class="invalid-feedback">Please Enter Email</div>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    id="agreement-check"
                                                    required
                                                    bind:checked={agreement}
                                            />
                                            <Label
                                                    class="form-check-label"
                                                    for="agreement-check"
                                            >
                                                {@html $_('chatapi.auth.agreement')}

                                            </Label>
                                        </div>
                                        <div class="mt-4 text-center">
                                            <button
                                                    type="submit"
                                                    class="btn btn-success w-md btn-lg"
                                            >
                                                {#if saving}
                                                    <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                {/if}
                                                {$_('chatapi.generate_pass')}
                                            </button>
                                            <div class="mt-5 text-center">
                                                <button type="button" class="text-muted border-0 p-0 bg-transparent" on:click={setEmailProblem}>
                                                    {@html $_('chatapi.email_problem')}
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>

                            </div>
                        {:else}
                            <div class="text-center">

                                <Spinner/>
                            </div>
                        {/if}

                    </div>

                </div>
            </Col>
        </Row>
    </Container>
</div>
