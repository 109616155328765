<script>
    import {Modal, ModalHeader, ModalBody} from "sveltestrap";
    import {_} from "svelte-i18n";
    export let show;
    export let toggleYoomodal;


</script>

<Modal isOpen={show}
       fade={true}

       size="md"
       class="modal-add channels"
       scrollable
       centered
>

    <ModalHeader on:click={toggleYoomodal}>

        <span class="text-success">{$_('subscription.subscribe')}</span>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                on:click={toggleYoomodal}
        />
    </ModalHeader>

    <ModalBody>
        <div class="container-fluid">
            <div id="yookassa-form"></div>
        </div>
    </ModalBody>

</Modal>