<script>
  import {onMount} from 'svelte';
  import {_} from "svelte-i18n";
  import QRCode from 'easyqrcodejs'

  export let value;
  export let width = 200;
  export let height = 200;

  let node;

  onMount(() => {
    const options = {text: value, width, height, quietZone: 0};
    new QRCode(node, options);
  });
</script>

<div bind:this={node} class="d-flex myQrCanvas"/>