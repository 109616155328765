<script>
  import {_} from "svelte-i18n";
  import MessageInfo from "../MessageInfo.svelte";
  import Text from "./Text.svelte";

  export let message;
  export let direction;

  const jsonObject = typeof message.text === 'string' ? JSON.parse(message.text) : message.text;
  const product = jsonObject?.meta?.product;
  const sections = jsonObject?.sections;
  const text = jsonObject?.body;
</script>

{#if product}
  <div class="p-1">
    <div class="rounded-3 WrapperProduct">
      <span class="text-truncate font-size-16 fw-600 text-muted">
        Product: {product?.product_retailer_id}
      </span>
    </div>
    <div class="position-relative Info">
      <MessageInfo {message} {direction}/>
    </div>
    <a href="https://business.facebook.com/commerce/catalogs/{product?.catalog_id}/products" target="_blank"
      class="d-flex align-items-center justify-content-center w-100 px-3 py-2 pointer ProductBtn">
      <span class="text-truncate font-size-16">
        {$_('chat_inbox.MessageChat.view_product')}
      </span>
    </a>
  </div>
{:else if sections}
  <div class="font-size-16 position-relative {direction ? 'pt-0': ''} WrapperListMessage Text">
    {text}
    <MessageInfo {message} {direction}/>
  </div>
  <div class="pt-1 WrapperListMessage BorderTop">
    <ul type="disc" class="mb-0 ps-3 font-size-14">
      {#each sections[0].rows as section}
        <li class="Text">{section.title}</li>
      {/each}
    </ul>
  </div>
{:else if text}
  <Text message={{...message, text}} {direction}/>
{/if}

<style>
  .WrapperProduct {
    padding: 10px 12px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .Info {
    height: 20px;
  }

  .ProductBtn {
    color: #0096DE;
    border-top: 0.8px solid #DBDBDD;
  }

  .WrapperListMessage {
    padding: 8px 12px;
  }

  ul li:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  .BorderTop {
    border-top: 0.8px solid #DBDBDD;
  }

  .Text {
    white-space: pre-wrap;
    word-break: break-word;
  }
</style>