<script>
  import firebase from "firebase/app";
  import {Col, Container, Row} from "sveltestrap";
  //Import Breadcrumb
  import Breadcrumb from "../../common/Breadcrumb.svelte";
  //Import Cards

  import {Link} from "svelte-routing";
  import CardProject from "./CardProject.svelte";
  import {Card} from "sveltestrap";
  import {alerts, projects, user} from "../../helpers/store";
  import {_, locale} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import {toolbar} from "../../services/loaders";
  import Pagination from "../../Components/Pagination/Pagination.svelte";
  import {addToast} from "../../helpers/toast";
  import {postRequest} from "../../helpers/utils";
  import {defaultConst} from "../../common/constants";
  import ChooseTheChannel from "../../Components/Modals/ChooseTheChannelModal.svelte";

  const colors = ["bg-success", "bg-info", "bg-warning", "bg-danger"];

  let projectsList = [];
  let adding = false;
  let alert = false;
  let lang;
  let paginationParams = {
    page: 0,
    pageSize: 9,
  };

  let activeUser = {};
  const unsub = user.subscribe((value) => {
    activeUser = value;
  });

  const unsub1 = projects.subscribe(async (local) => {
    let value = local;
    if (Array.isArray(value)) {
      value.sort((a, b) =>
        a.creationTS - b.creationTS
      );
    }
    let tmpProjects = [];
    for (let i in value) {
      let project = Object.assign({}, value[i]);
      let projectToUpdate = projectsList.find(
        (item) => item.id === project.id
      );
      project.status = "Card needed";
      project.color = colors[3];

      if (project.card) {
        project.status = "Active";
        project.color = colors[1];
        project.activeTill = new Date(parseInt(project.trial));
      }

      if (
        !projectToUpdate ||
        (projectToUpdate.users &&
          JSON.stringify(projectToUpdate.users) !==
          JSON.stringify(project.users))
      ) {
        let promises = [];
        let max = project.users.length < 3 ? project.users.length : 3;

        for (let i = 0; i < max; i++) {
          const uid = project.users[i];
          promises.push(
            firebase.firestore().collection("users").doc(uid).get()
          );
        }
        promises = await Promise.all(promises);
        let teamArr = [];
        for (let j in promises) {
          let value = promises[j].data();
          let tmp = {
            name: value.name || value.email,
          };
          if (value.avatar) {
            tmp.avatar = value.avatar;
          } else {
            value.color = colors[Math.floor(Math.random() * 4)];
          }

          teamArr.push(tmp);
        }

        project.teamToShow = teamArr;
        project.channels = project.channels ? Object.values(project.channels) : [];

      }
      tmpProjects.push(project);
    }
    projectsList = tmpProjects;
    paginationParams = Object.assign(paginationParams, {
      count: projectsList.length,
    });
  });

  const unsub2 = alerts.subscribe((value) => {
    activeUser = value;
    if(value?.alertChannel) {
      alert = value?.alertChannel
    }else{
      alert = false;
    }
    console.log('alert',alert)
  });

  onDestroy(() => {
    unsub2();
  });

  const addProject = async () => {
    if (adding) return;
    adding = true;
    toolbar(adding);
    let params = {
      projectName: `New project # ${projectsList.length}`,
      userToken: activeUser.userToken,
    };
    let result;

    try {
      result = await postRequest(
        `${defaultConst.functionsUrl}/createProject`,
        params
      );
    } catch (e) {
    }
    adding = false;
    toolbar(adding);
    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong"),
        dismissible: true,
        timeout: 4_000,
      });
    } else {
      addToast({title: $_("saved")});
    }
  };
</script>

<div class="page-content projects-type2">
  <Container fluid>
    {#if !alert}
      <div class="info-container-type2 mb-5">
        <div class="info-chat">
          <Row>
            <Col md={8} lg={8} xs="{12}">

              <div class="info-content font-weight-normal">
                <h1 class="pb-3">{@html $_("projects.text_header")}</h1>
                {@html $_("projects.text_text")}
              </div>
            </Col>
            <Col class="d-none d-md-block text-center" md={4} lg={4}>
              <img src="/assets/images/girl-dashboard.svg" alt="info-girl" class="info-img"/>
            </Col>
          </Row>
        </div>
      </div>
    {/if}
    <Row class="projects-cards">
      <Col  xl="4" class=" mb-4">
        <Card class="add-project">
          <Link on:click={addProject}>
            <div class="add">
              + {$_("projects.add")}
            </div>
          </Link>
        </Card>
      </Col>
      {#each projectsList as project}
        <Col xl="4" class=" mb-4">
          <CardProject project={project}/>
        </Col>
      {/each}
    </Row>

    <!--        <Row>-->
    <!--            <Col lg={12}>-->
    <!--                <Pagination {...paginationParams} />-->
    <!--            </Col>-->
    <!--        </Row>-->

<!--   <ChooseTheChannel />-->

  </Container>
</div>
