<script>
  import {getAudioContext} from 'svelte-audio-player';
  import {toggle, toHHMMSS} from 'svelte-audio-player/utils';
  import MessageInfo from '../MessageInfo.svelte';

  export let message;
  export let direction;
  export let scheduled = false;
  export let liteVersio = false;
  export let typeLastTime = false;
  export let typeReply = false;

  const PLAYBACK_SPEEDS = [1, 1.5, 2];
  const {playing, playbackRate, paused, currentTime, duration} = getAudioContext();

  let speedIndex = 0;
  const handlePlaybackSpeedClick = () => {
    $playbackRate = PLAYBACK_SPEEDS[++speedIndex % PLAYBACK_SPEEDS.length];
  };
</script>

{#if typeLastTime}
  {toHHMMSS($duration)}
{:else if typeReply}
  <div class="d-flex align-items-center gap-1 {!direction ? 'flex-row-reverse' : ''}">
    <div class="pe-1">
      <i class="bx bx-headphone font-size-25"/>
    </div>
    <div class="pointer font-size-18 {direction ? 'ps-1 pe-2' : 'ps-2 pe-1'}" on:click={() => toggle(paused)}>
      {#if $playing}
        <i class="fas fa-pause d-block"/>
      {:else}
        <i class="fas fa-play d-block"/>
      {/if}
    </div>
    <div class="w-100 d-flex flex-column">
      <input
        bind:value={$currentTime}
        min={0}
        max={$duration}
        step={0.01}
        type="range"
        class="w-100 mt-2"
        style={`background-size: ${($currentTime * 100) / $duration}% 100%`}
      />
      <div class="d-flex justify-content-between mt-2">
        <span class="font-size-11 Time">{toHHMMSS($currentTime)}</span>
        <span class="font-size-11 Time">{toHHMMSS($duration)}</span>
      </div>
    </div>
  </div>
{:else}
  <div class={!liteVersio ? "p-1" : ""}>
    <div class="d-flex align-items-center gap-2 rounded-3 mb-2 {direction ? 'flex-row-reverse' : ''} {!liteVersio ? "Wrapper" : ""}">
      <div class="w-100">
        <div class="d-flex align-items-center gap-1">
          <div class="pointer font-size-18 ps-1 pe-2" on:click={() => toggle(paused)}>
            {#if $playing}
              <i class="fas fa-pause d-block"/>
            {:else}
              <i class="fas fa-play d-block"/>
            {/if}
          </div>
          <div class="w-100 d-flex flex-column">
              <input
                bind:value={$currentTime}
                min={0}
                max={$duration}
                step={0.01}
                type="range"
                class="w-100 mt-2"
                style={`background-size: ${($currentTime * 100) / $duration}% 100%`}
              />
            <div class="d-flex justify-content-between mt-2">
              <span class="font-size-11 Time">{toHHMMSS($currentTime)}</span>
              <span class="font-size-11 Time">{toHHMMSS($duration)}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="pointer Icon" on:click={handlePlaybackSpeedClick}>
        <span class="avatar-title rounded-circle font-size-16 position-relative">
          <i class="bx bx-headphone text-white"/>
          <span class="position-absolute font-size-11">{$playbackRate}x</span>
        </span>
      </div>
    </div>
    <MessageInfo {message} {direction} {scheduled} classes={liteVersio ? "pe-0" : ""}/>
  </div>
{/if}

<style>
  .Wrapper {
    padding: 3px 5px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .Time {
    color: rgba(0, 0, 0, 0.4);
  }

  .Icon {
    min-width: 38px;
    height: 38px;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }

  .Icon>span>span {
    visibility: hidden;
  }

  .Icon:hover i {
    visibility: hidden;
  }

  .Icon:hover>span>span {
    visibility: visible;
  }

  .avatar-title {
    background-color: #0CA970;
  }

  input {
    appearance: none;
    height: 6px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-image: linear-gradient(#495057, #495057);
    background-size: 0 100%;
    background-repeat: no-repeat;
  }

  input::-webkit-slider-thumb {
    appearance: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #495057;
    cursor: ew-resize;
    transition: background .3s ease-in-out;
  }

  input::-moz-range-thumb {
    appearance: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #495057;
    cursor: ew-resize;
    transition: background .3s ease-in-out;
  }

  input::-ms-thumb {
    appearance: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #495057;
    cursor: ew-resize;
    transition: background .3s ease-in-out;
  }

  input::-webkit-slider-thumb:hover {
    background: #495057;
  }

  input::-moz-range-thumb:hover {
    background: #495057;
  }

  input::-ms-thumb:hover {
    background: #495057;
  }

  input::-webkit-slider-runnable-track  {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input::-moz-range-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input::-ms-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
</style>
