<script>
  import {appClient} from '../../../../helpers/store';
  import {addToast} from "../../../../helpers/toast";
  import {_} from "svelte-i18n";

  export let chosenChannelLocal;
  export let activeOperator;

  let isOnline
  let loadedOperator

  $:isOnline = activeOperator?.is_online;
  $:loadedOperator = !!activeOperator && Object.keys(activeOperator)?.length


  const toggleIsOnline = () => {

    isOnline = !isOnline
    appClient.patchOperatorIsOnline(isOnline, chosenChannelLocal).then(data => {
      if (data.status === "ok") {
        activeOperator.is_online = isOnline;
        addToast({title: isOnline ? 'Online' : 'Offline'});
      } else {
        isOnline = !isOnline
        addToast({
          title: $_("error"),
          message: $_("something_went_wrong")
        });
      }
    }, err => {
      isOnline = !isOnline
      console.error(err);
      addToast({
        title: $_("error"),
        message: $_("something_went_wrong")
      });
    })
  };
</script>

<div class="p-3 mb-2 mb-lg-3 card Profile">
  <div class="d-flex mb-3">
    <div class="flex-shrink-0 align-self-center me-3 {!loadedOperator ? 'skeleton-wave' : ''}">
      {#if activeOperator?.profile.avatar_url}
        <img src="{activeOperator?.profile.avatar_url}"
          class="avatar-sm rounded-circle object-fit-cover" alt="avatar"/>
      {:else}
        <div class="avatar-sm d-inline-block">
        <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16">
          {activeOperator?.profile.user.email.toUpperCase().charAt(0) || ''}
        </span>
        </div>
      {/if}
    </div>
    <div class="flex-grow-1 overflow-hidden">
      <div class="d-flex me-3 mb-1 {!loadedOperator ? 'skeleton-wave' : ''}">
        <div class="text-truncate font-size-18 mb-0 pe-3"
          title={activeOperator?.profile.user.username || '-'}>
          {activeOperator?.profile.user.username || '-'}
        </div>
        <label class="form-check form-switch form-switch-sm IsOnline">
          <input bind:checked={isOnline} class="form-check-input"
            on:change={toggleIsOnline} type="checkbox"/>
          <div class="form-check-label font-size-14">
            {isOnline ? 'Online' : 'Offline'}
          </div>
        </label>
      </div>
      <div class="text-truncate font-size-14 text-muted me-3 {!loadedOperator ? 'skeleton-wave' : ''}"
        title={activeOperator?.profile.user.email}>
        {activeOperator?.profile.user.email || ''}
      </div>
    </div>
    <div>
      <a href="/profile" target="_blank">
        <h3 class="font-size-24">
          <i class="bx bx-edit-alt"/>
        </h3>
      </a>
    </div>
  </div>
  <div>
    <div class="text-truncate">
      {#if chosenChannelLocal?.phone}
        <span class="bold">{$_('chat_inbox.Profile.connected_number')} :</span>
      {/if}
      <span class="text-muted" title={chosenChannelLocal?.phone ? chosenChannelLocal.phone : '-'}>
        {#if chosenChannelLocal?.phone}
          {chosenChannelLocal?.phone}
        {:else}
          &nbsp;
        {/if}
      </span>
    </div>
    <!-- <div class="mb-2">
      <span class="bold">{$_('chat_inbox.Profile.role')} :</span>
      <span class="text-muted">{.role}</span>
    </div> -->
  </div>
</div>

<style>
  .Profile .bx.bx-edit-alt:hover {
    color: #23B16D;
  }

  .Profile .IsOnline {
    margin-top: 2px;
  }

  .Profile .IsOnline .form-check-label {
    color: rgba(0, 0, 0, 0.25);
  }

  .Profile .IsOnline .form-check-input:checked {
    border-color: #23B16D;
    background-color: #23B16D;
  }

  .Profile .skeleton-wave {
    position: relative;
    overflow: hidden;
    background-color: #f1f1f1; /* Skeleton background color */
    z-index: 2; /* Ensures the skeleton is positioned above other elements */
  }

  .Profile .skeleton-wave::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.5), transparent); /* Wave gradient colors */
    animation: wave-animation 1.5s infinite linear;
    z-index: 3; /* Ensures the wave animation is positioned above the underlying content */
  }

  /* Add the following CSS to hide the underlying content */
  .Profile .skeleton-wave::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f1f1f1; /* Skeleton background color */
    z-index: 1; /* Ensures the overlay is positioned above other elements */
  }

  @keyframes wave-animation {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(180%);
    }
  }
</style>
