<script>
  import MessageInfo from "../MessageInfo.svelte";
  import Text from "./Text.svelte";
  import GoogleMapsWrapper from "../GoogleMapsWrapper.svelte";
  import {_} from "svelte-i18n";

  export let message;
  export let direction;
  export let scheduled = false;
  export let liteVersio = false;

  const jsonObject = typeof message.text === 'string' ? JSON.parse(message.text) : message.text;
  let {body, name} = jsonObject;
  let localMessage;

  body = body.split(';');

  $:message && parseMessageToLocal()

  function parseMessageToLocal() {
    if (name && message) {
      localMessage = JSON.parse(JSON.stringify(message))
      localMessage.text = name;
    }
  }

  const LatLng = {
    lat: Number(body[0]),
    lng: Number(body[1])
  };
</script>

<div class={!liteVersio ? "p-1" : ""}>
  {#if scheduled}
    <a href="https://maps.google.com/maps?q={LatLng.lat}%2C{LatLng.lng}&z=17" rel="noopener noreferrer" target="_blank"
      class="{!liteVersio ? "locationScheduled" : ""} d-flex align-items-center gap-2 rounded-3 text-muted font-size-15">
      <i class="bx bxs-map font-size-22"/> <span class="text-truncate">{$_('broadcast.location')}</span>
    </a>
    {#if !name}
      <div class="mt-1">
        <MessageInfo {message} {direction} {scheduled} classes={liteVersio ? "pe-0" : ""}/>
      </div>
    {/if}
  {:else}
    <a class="Location d-block overflow-hidden rounded-3" rel="noopener noreferrer" target="_blank"
      href="https://maps.google.com/maps?q={LatLng.lat}%2C{LatLng.lng}&z=17">
      <GoogleMapsWrapper
        enableMapMarkersOnClick={false}
        height="190px"
        initMarker={LatLng}
        mapOptions={{
        zoom: 15,
        gestureHandling: "none",
        fullscreenControl: false,
        clickableIcons: false,
        keyboardShortcuts: false,
        draggableCursor: "pointer",
        disableDefaultUI: false
      }}/>
    </a>
    {#if !name}
      <div class="position-absolute bottom-0 end-0">
        <MessageInfo {message} {direction} {scheduled}
          classes="text-white mb-2" wrapperStyle="rounded p-1 bg-dark bg-opacity-75"/>
      </div>
    {/if}
  {/if}
</div>
{#if localMessage?.text}
  <Text message={localMessage} {direction} {scheduled} {liteVersio} classes={!liteVersio ? "pt-0" : "pt-2"}/>
{/if}

<style>
  a.Location {
    height: 160px;
    background-color: #f6f6f6;
  }

  a.locationScheduled {
    padding: 7px;
    background-color: rgba(255, 255, 255, 0.2);
  }
</style>
