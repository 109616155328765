<script>
  import {Col, Row, Card, CardBody} from "sveltestrap";
  import {_, locale} from "svelte-i18n";
  import {onMount, onDestroy} from "svelte";
  import PaymentsTable from "../ChannelPayment/PaymentsTable.svelte";
  import Subscription from "../ChannelPayment/Subscription.svelte";
  import ReceiptsModal from "../../Components/Modals/ReceiptsModal.svelte";
  import ChannelsDropdown from "./ChannelsDropdown.svelte";
  import CustomizeSubscriptionModal from "../../Components/Modals/CustomizeSubscriptionModal.svelte";
  import {trialBar, isUserCreatedAfter1July2024, getCorporateBillsSettings} from "../../helpers/store";
  import Spinner from "../../Components/Spinners/SpinnerComponent.svelte";

  export let activeChannel;
  export let changeState;
  export let activeChannels;
  export let statistic;
  export let payments;
  export let profiles;
  export let corporates;
  export let checkBillingDetails;

  let activePayments = [], profile = false, receiptsModal = false, customizeModal = false, activeCorporates = [], lang;
  let rateIndex = 0;
  let corporateBillsSettingsLoader = true, corporateBillsSettings;

  onMount(() => {
    if (localStorage.getItem("I18N_LANGUAGE")) {
      lang = localStorage.getItem("I18N_LANGUAGE");
    }
  });

  const unsubLocale = locale.subscribe((value) => {
    if (value) lang = value;
  });

  onDestroy(() => {
    unsubLocale();
  });

  function init() {
    activePayments = payments && payments.filter(item => {
      return item.channelId === activeChannel.id;
    }) || []

    profile = profiles && activeChannel.paymentProfileId && profiles.find((item) => {
      return item.id === activeChannel.paymentProfileId && item.active;
    }) || false

    activeCorporates = corporates && corporates.filter(item => {
      return item.channelId === activeChannel.id;
    }) || []

    corporateBillsSettingsLoader = true;
    getCorporateBillsSettings(activeChannel.id).then(val => {
      corporateBillsSettings = val;
      corporateBillsSettingsLoader = false;
    });
  }

  $:if (payments || corporates || profiles) {
    init();
  }

  function selectChannel(selected) {
    if (!selected) {
      return changeState(selected);
    }
    activeChannel = selected;
    init();
  }
</script>

{#if !$trialBar.show}
  <Row class="mb-3">
    <Col xl="12" xxl="6" class="d-flex align-items-end">
      <div class="w-100 mb-2 mb-xxl-1">
        <ChannelsDropdown change={selectChannel} active={activeChannel} channels={activeChannels}/>
      </div>
    </Col>
    <div class="col-xl-12 col-xxl-6 d-flex flex-column flex-md-row ChannelState-btn">
      {#if profile}
        <button class="me-md-2 font-size-13 btn btn-lg btn-outline-primary rounded"
          on:click={() => customizeModal = true} type="button">
          <i class="mdi mdi-autorenew mdi-rotate-45 font-size-18 lh-1 align-middle"/>
          {$_("customize.title")}
        </button>
      {/if}
      <button class="mt-2 mt-md-0 btn btn-outline-primary font-size-13 btn-lg rounded"
        on:click={() => receiptsModal = true} type="button">
        <i class="bx bx-list-check font-size-18 lh-1 align-middle"/>
        {$_("subscription.open_receipts")}
      </button>
      {#if receiptsModal}
        <ReceiptsModal
          show={receiptsModal}
          channel={activeChannel}
          toggleModal={() => receiptsModal = !receiptsModal}/>
      {/if}
      {#if customizeModal}
        <CustomizeSubscriptionModal
          show={customizeModal}
          channel={activeChannel}
          toggleModal={() => customizeModal= !customizeModal}/>
      {/if}
    </div>
  </Row>
{/if}

{#if profile}
  <Row>
    <Col xl="12" xxl="4" class="d-flex">
      <Subscription location="billing" channel={activeChannel} {checkBillingDetails}/>
    </Col>
    {#if statistic}
      <Col xl="12" xxl="4" class="d-flex flex-column justify-content-between">
        <Card class="fw-500 h-100">
          <CardBody class="d-flex align-items-center justify-content-between p-3">
            <div class="pe-2">
              <div class="font-size-14 pb-1">{$_('billing.stats.summary')}</div>
              <div class="font-size-20 pt-2">$ {statistic[activeChannel.id]?.total_price_calc || '-'}</div>
            </div>
            <div class="bx-border-circle fg-transparent-green border-0 avatar-sm">
              <i class="bx bx-file fg-green p-2 font-size-25"/>
            </div>
          </CardBody>
        </Card>
        <Card class="fw-500 h-100">
          <CardBody class="d-flex align-items-center justify-content-between p-3">
            <div class="pe-2">
              <div class="font-size-14 pb-1">{$_('billing.stats.business')}</div>
              <div class="font-size-20 pt-2">
                {statistic[activeChannel.id]?.marketing_quantity + statistic[activeChannel.id]?.authentication_quantity + statistic[activeChannel.id]?.utility_quantity || '-'}
              </div>
            </div>
            <div class="bx-border-circle fg-transparent-green border-0 avatar-sm">
              <i class="dripicons-suitcase fg-green p-2 font-size-25 lh-48"/>
            </div>
          </CardBody>
        </Card>
        <Card class="fw-500 h-100">
          <CardBody class="d-flex align-items-center justify-content-between p-3">
            <div class="pe-2">
              <div class="font-size-14 pb-1">{$_('billing.stats.user')}</div>
              <div class="font-size-20 pt-2">
                {statistic[activeChannel.id]?.service_quantity || '-'}
              </div>
            </div>
            <div class="bx-border-circle fg-transparent-green border-0 avatar-sm">
              <i class="mdi mdi-account-outline fg-green p-2 font-size-25"/>
            </div>
          </CardBody>
        </Card>
      </Col>
    {:else}
      <Col class="d-flex align-items-center justify-content-center mb-4">
        <Spinner/>
      </Col>
    {/if}
    <Col xl="12" xxl="4" class="d-flex flex-column justify-content-between">
      <Card class="bg-lavender h-100">
        <CardBody class="p-3">
          <div class="subtitle font-size-16 fw-600 text-primary mb-2">
            {$_('billing.channel.hint1.title')}
          </div>
          <div class="fg-bs-gray-700 font-size-12 mb-2" style="max-width: calc(100% - 80px);">
            {$_('billing.channel.hint1.text')}
          </div>
          <a href="https://help.1msg.io/1msg-knowledge-base/billing/tariffs-and-payments-june-update" target="_blank"
              class="text-primary text-decoration-underline font-size-13">
            {$_('billing.channel.hint1.link')}
          </a>
          <div class="position-absolute question_man">
            <img src="assets/images/question_man.svg" alt="subscription info"/>
          </div>
        </CardBody>
      </Card>
      <Card class="card-green h-100">
        <CardBody class="p-3">
          <div class="subtitle font-size-16 fw-600 fg-green mb-2">
            {$_('billing.channel.hint4.title')}
          </div>
          <div class="fg-bs-gray-700 font-size-12 mb-2" style="max-width: calc(100% - 70px);">
            {$_('billing.channel.hint4.text')}
          </div>
          <a href="https://help.1msg.io/1msg-knowledge-base/billing/tariffs-and-payments-june-update#threshold-charge-principe"
              target="_blank"
              class="fg-green text-decoration-underline font-size-13">
            {$_('billing.channel.hint4.link')}
          </a>
          <div class="position-absolute question_back_rotate">
            <img src="assets/images/question mark.svg" class="img-fit" alt="question mark"/>
          </div>
          <div class="position-absolute question_back">
            <img src="assets/images/question mark.svg" class="img-fit" alt="question mark"/>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
{:else}
  <Row>
    {#if lang === 'ru' && isUserCreatedAfter1July2024()}
      <Col lg="12" xl="6" xxl="4" class="d-flex">
        <Card class="bg-lavender w-100">
          <CardBody class="p-4">
            <div class="subtitle font-size-16 fw-600 text-primary mb-2">
              Тарифный план
            </div>
            <div class="font-size-14 mb-3">
              Выберите тарифный план, который подойдет лучше всего под ваши цели.
            </div>
            <div class="font-size-14 mb-2">
              Любой тариф включает:
            </div>
            <ul class="ms-0 ps-0 mb-5 TariffPlan-list">
              <li class="mb-2 position-relative">
                <i class="bx bx-check font-size-24 text-primary position-absolute start-0"/>
                <span class="font-size-14">Доступ к CLOUD API</span>
              </li>
              <li class="mb-2 position-relative">
                <i class="bx bx-check font-size-24 text-primary position-absolute start-0"/>
                <span class="font-size-14">1000 входящих диалогов в месяц бесплатно</span>
              </li>
              <li class="mb-2 position-relative">
                <i class="bx bx-check font-size-24 text-primary position-absolute start-0"/>
                <span class="font-size-14">Пишите первым</span>
              </li>
              <li class="position-relative">
                <i class="bx bx-check font-size-24 text-primary position-absolute start-0"/>
                <span class="font-size-14">Заботливая поддержка</span>
              </li>
            </ul>
            <div class="text-center">
              <img src="assets/images/board-girl.svg" alt="subscription info"/>
            </div>
          </CardBody>
        </Card>
      </Col>
      {#if corporateBillsSettingsLoader}
        <Col class="d-flex align-items-center justify-content-center mb-4">
          <Spinner/>
        </Col>
      {:else}
        {#if corporateBillsSettings === undefined}
          <Col>
            <Row>
              <div class="col-12">
                <div class="d-inline-flex gap-3 mb-3 TogglePayments">
                  <button type=button class="font-size-16 fw-600 rounded border-0 px-3 py-1 {rateIndex === 0 ? 'text-success active' : 'text-muted bg-transparent'}"
                    on:click={() => rateIndex = 0}>
                    Месяц
                  </button>
                  <button type=button class="font-size-16 fw-600 rounded border-0 px-3 py-1 {rateIndex === 1 ? 'text-success active' : 'text-muted bg-transparent'}"
                    on:click={() => rateIndex = 1}>
                    Год
                  </button>
                </div>
              </div>
              <Subscription location="billing" channel={activeChannel} {checkBillingDetails} {rateIndex}/>
            </Row>
          </Col>
        {:else}
          <Subscription location="billing" channel={activeChannel} {checkBillingDetails} corporateBillsSettingsOrg={corporateBillsSettings.org}/>
        {/if}
      {/if}
    {:else}
      <Col lg="12" xl="6" xxl="4" class="d-flex">
        <Card class="bg-lavender w-100">
          <CardBody class="p-3">
            <div class="subtitle font-size-16 fw-600 text-primary mb-2">
              {$_('billing.channel.hint3.title')}
            </div>
            <div class="font-size-14 mb-5">
              {$_('billing.channel.hint3.text')}
            </div>
            <div class="text-center">
              <img src="assets/images/board-girl.svg" alt="subscription info"/>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Subscription location="billing" channel={activeChannel} {checkBillingDetails}/>
    {/if}
  </Row>
{/if}

<PaymentsTable paymentRecords={activePayments} corporateBills={activeCorporates}/>

<style>
  @media (min-width: 1500px) {
    .ChannelState-btn {
      justify-content: flex-end;
    }
  }

  .question_back {
    bottom: 40%;
    height: 35px;
    right: 60px;
    transform: rotate(334deg)
  }

  .question_back_rotate {
    bottom: 15%;
    right: 18px;
    height: 55px;
  }

  .question_man {
    bottom: 0;
    right: 10px;
  }

  .lh-48 {
    line-height: 48px;
  }

  .TariffPlan-list {
    list-style-type: none;
  }

  .TariffPlan-list li {
    padding-left: 35px;
  }

  .TariffPlan-list i {
    top: -3px;
  }

  .TogglePayments .active {
      background-color: #DAF4EB;
  }
</style>
