<script>
  import {Input, FormGroup, Label} from "sveltestrap";
  import SelectDropdown from "../../../../Components/SelectDropdown.svelte";

  export let values;

  function typeChange(type) {
    switch (type) {
      case "none":
        delete values.header;
        values = values;
        break;
      case "text":
        delete values.header;
        values = {header: {type, text: ""}, ...values};
        break;
      case "document":
        delete values.header;
        values = {header: {type, document: {link: ""}}, ...values};
        break;
      case "video":
        delete values.header;
        values = {header: {type, video: {link: ""}}, ...values};
        break;
      case "image":
        delete values.header;
        values = {header: {type, image: {link: ""}}, ...values};
        break;
    }
  }

  function addSections() {
    values.sections = [...values.sections, {type: "reply", reply: {id: "", title: ""}}]
  }

  function deleteSection(i) {
    values.sections = values.sections.filter((item, index) => index !== i);
  }
</script>

<FormGroup>
  <Label class="fw-600">header</Label>
  <SelectDropdown type={true} selected='none' change={typeChange} list={['none', 'text', 'document', 'video', 'image']}/>
</FormGroup>
<FormGroup>
  {#if values?.header?.type === "text"}
    <Input type="text" bind:value={values.header.text}/>
  {:else if values?.header?.type === "document"}
    <Input type="url" bind:value={values.header.document.link}/>
  {:else if values?.header?.type === "video"}
    <Input type="url" bind:value={values.header.video.link}/>
  {:else if values?.header?.type === "image"}
    <Input type="url" bind:value={values.header.image.link}/>
  {/if}
</FormGroup>
{#each values.sections as section, i}
  <div class="d-flex">
    <div class="w-100 me-3">
      <FormGroup>
        <Label class="fw-600">id</Label>
        <Input type="text" bind:value={section.reply.id}/>
      </FormGroup>
      <FormGroup>
        <Label class="fw-600">title</Label>
        <Input type="text" bind:value={section.reply.title}/>
      </FormGroup>
    </div>
    <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteSection(i)}>-</button>
  </div>
  {#if values.sections.length !== i+1}
    <div class="mb-3 mt-2 line bg-success"/>
  {/if}
{/each}
<button type="button" class="btn addBtn rounded-circle" on:click={addSections}>+</button>

<style>
  .line {
    height: 2px;
  }

  .deleteBtn, .addBtn {
    color: #495057 !important;
    width: 40px;
    height: 40px;
    background-color: #EFF2F7;
    box-shadow: none;
  }

  .deleteBtn {
    line-height: 16px;
    font-size: 22px;
    margin-top: 28px;
  }

  .addBtn {
    line-height: 20px;
    font-size: 20px;
  }
</style>