<script>
  import {Modal, ModalBody, ModalFooter, Button} from "sveltestrap";
  import {onMount, getContext, onDestroy} from "svelte";
  import {_, date} from "svelte-i18n";
  import {fade} from "svelte/transition";

  import {channels, projects, user} from "../../helpers/store";
  import {defaultConst} from "../../common/constants";
  import {addToast} from "../../helpers/toast";
  import {postRequest} from "../../helpers/utils";

  import Table, {
    Pagination,
    Search,
    Sort,
  } from "../../Components/Table/Table.svelte";
  import {sortNumber, sortString} from "../../Components/Table/sorting";
  import Spinner from "../Spinners/SpinnerComponent.svelte";


  export let channel;
  export let show;
  export let onCloseClick = () => {
  };


  let activeUser = {};

  const unsub = user.subscribe((value) => {
    activeUser = value;
  });

  let userChannels = [];
  let userProjects = [];
  let moveChannel = false;
  let page = 0; //first page
  let addBtn = true; //first page
  let loading = false;

  onMount(() => {


  });
  onDestroy(() => {
    unsub();
  })

  const deleteChannel = async () => {
    loading = true;
    let params;
    let result;
    if (channel.paymentProfileId) {
      try {
        let params = {id: channel.id, profile: channel.paymentProfileId, unsubscribeComment: 'channel delete'};
        await postRequest(
                `${defaultConst.paymentUrl}/unsubscribe/${channel.id}`,
                params
        );
      } catch (e) {}
    }

    params = {
      channelId: channel.id,
      userToken: activeUser.userToken,
    }
    try {
      result = await postRequest(
        `${defaultConst.functionsUrl}/deleteChannel`,
        params
      );
    } catch (e) {
    }
    loading = false;
    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong")
      });
    } else {
      onCloseClick();
      show = false;
      addToast({title: $_("deleted")});
    }
  };


</script>

<div class="add-event-modal">
  <Modal
      class="exampleModal"
      isOpen={show}
      fade={true}
      centered={true}
      toggle={onCloseClick}
      header={$_("channel.delete_channel_header")}
  >
    {#if loading}
      <Spinner></Spinner>
    {:else}
      <div class="modal-content">
        <ModalBody>
          {$_('channel.delete_modal_text')} {channel.id}?
          <Button color="success" on:click={deleteChannel} class="btn-sm">
            <i class="mdi mdi-check"/>
          </Button>
          <Button
              type="reset"
              color="danger"
              class="btn-sm"
              on:click={onCloseClick}
          >
            <i class="mdi mdi-close"/>
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" on:click={onCloseClick}>
            {$_('close')}
          </Button>
        </ModalFooter>
      </div>
    {/if}
  </Modal>
</div>
