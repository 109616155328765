<script>
  import {onMount} from "svelte";
  import {_} from "svelte-i18n";
  import {Container, Row, Col, Button, Card, CardBody, FormGroup, Label} from "sveltestrap";
  import {channels, chosenChannel, user} from "../../helpers/store";
  import {promisifyStore, postRequest} from "../../helpers/utils";
  import {defaultConst} from "../../common/constants";
  import NoChannelsWrapper from "../../Components/NoChannelsWrapper.svelte";
  import PrettyfiedCode from "../../Components/PrettyfiedCode.svelte";
  import SelectDropdown from "../../Components/SelectDropdown.svelte";

  let Response = ' ';
  let loader = false;

  let listChannel = [];
  let selectedChannel;

  let selectedWebhookTypeIndex = 0;
  let dataWebhookType;

  onMount(() => {
    promisifyStore(channels).then(channelsData => {
      listChannel = Array.isArray(channelsData) && channelsData.length ? channelsData.reduce(
        (result, item) => {
          if (item.appData) {
            let trialData = {};
            if (item?.srv === 'trial') {
              trialData = {sandbox_phone: item.sandbox_phone};
            }
            result.push(
              {
                value: item.id,
                phone: item.phone,
                label: `${item.id} ${item.name}`,
                ...trialData
              }
            );
          }
          return result;
        },
        []
      ) : [];
    });

    promisifyStore(chosenChannel).then(chosenChannelData => {
      selectedChannel = chosenChannelData?.id;
    });
  });

  $: if (selectedChannel || selectedWebhookTypeIndex) {
    const channel = listChannel.find(({value}) => value === selectedChannel);
    let parameters = {};

    if (channel?.sandbox_phone) {
      parameters = {
        author: '6531743440@c.us',
        senderName: '6531743440@c.us',
        chatName: channel.sandbox_phone,
        chatId: `${channel.sandbox_phone}@c.us`
      };
    } else {
      parameters = {
        author: `${channel.phone}@c.us`,
        senderName: `${channel.phone}@c.us`,
        chatName: 6531743440,
        chatId: '6531743440@c.us'
      };
    }

    const listWebhookType = [
      {
        messages: [
          {
            id: 'gBGGeVMQeRMfAgnmqi1qd3KV2IA',
            body: 'Ok!',
            self: 1,
            type: 'chat',
            fromMe: true,
            caption: null,
            isForwarded: false,
            time: '1695202015',
            ...parameters
          }
        ],
        instanceId: channel.value
      },
      {
        messages: [
          {
            id: 'gBGGeVMQeRMfAgnmqi1qd3KV2IA',
            body: "Achetez des vetements\r\nAllons'y!\r\n",
            self: 1,
            type: 'chat',
            fromMe: true,
            caption: null,
            isForwarded: false,
            time: '1695202199',
            ...parameters
          }
        ],
        instanceId: channel.value
      },
      {
        ack: [
          {
            id: 'gBGGeVMQeRMfAgnmqi1qd3KV2IA',
            status: 'sent',
            chatId: parameters.chatId
          }
        ],
        instanceId: channel.value
      },
      {
        ack: [
          {
            id: 'gBGGeVMQeRMfAgnmqi1qd3KV2IA',
            status: 'delivered',
            chatId: parameters.chatId
          }
        ],
        instanceId: channel.value
      },
      {
        ack: [
          {
            id: 'gBGGeVMQeRMfAgnmqi1qd3KV2IA',
            status: 'read',
            chatId: parameters.chatId
          }
        ],
        instanceId: channel.value
      }
    ];

    dataWebhookType = listWebhookType[selectedWebhookTypeIndex];
  }

  const Submit = () => {
    loader = true;

    const params = {
      userToken: $user?.userToken,
      channelId: selectedChannel,
      data: dataWebhookType
    };

    postRequest(`${defaultConst.functionsUrl}/checkHook`, params).then(response => {
      Response = JSON.stringify(response, null, 2);
      loader = false;
    });
  };
</script>

<!-- <NoChannelsWrapper>
  <div class="page-content">
    <Container fluid> -->
      <!-- <div class="header-title">{$_('TestingWebhookSimulate.title')}</div> -->
      <div class="fw-600 font-size-18 mb-4">{$_('TestingWebhookSimulate.title')}</div>
      <!-- <Card>
        <CardBody> -->
          <Row>
            <Col xl={6} class="mb-3 mb-xl-0">
              <form on:submit|preventDefault={Submit}>
                <FormGroup>
                  <Label class="fw-600">{$_('TestingWebhookSimulate.channel_selection')}</Label>
                  <SelectDropdown bind:selected={selectedChannel}
                    list={listChannel} title='TestingWebhookSimulate.channel_selection'/>
                </FormGroup>
                <FormGroup>
                  <Label class="fw-600">{$_('TestingWebhookSimulate.webhook_type_selection')}</Label>
                  <SelectDropdown bind:selected={selectedWebhookTypeIndex}
                    list={[
                      {value: 0, label: $_('TestingWebhookSimulate.webhook_type.message')},
                      {value: 1, label: $_('TestingWebhookSimulate.webhook_type.template')},
                      {value: 2, label: $_('TestingWebhookSimulate.webhook_type.ack_sent')},
                      {value: 3, label: $_('TestingWebhookSimulate.webhook_type.ack_delivered')},
                      {value: 4, label: $_('TestingWebhookSimulate.webhook_type.ack_read')},
                    ]} title='TestingWebhookSimulate.webhook_type_selection'/>
                </FormGroup>
                <Button type="submit" color="success" class="py-2 px-4" disabled={loader}>
                  <span class="font-size-14">{$_('send')}</span>
                </Button>
              </form>
            </Col>
            <Col xl={6}>
              <div class="mb-3">
                <Label class="fw-600">Webhook</Label>
                <PrettyfiedCode code={JSON.stringify(dataWebhookType, null, 2)}/>
              </div>
              <div>
                <Label class="fw-600">{$_('response')}</Label>
                <PrettyfiedCode code={loader ? $_('loader...') : Response}/>
              </div>
            </Col>
          </Row>
        <!-- </CardBody>
      </Card> -->
    <!-- </Container>
  </div>
</NoChannelsWrapper> -->
