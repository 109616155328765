<script>
  import {Modal, ModalBody, Row, Col, Button, Label} from "sveltestrap";
  import {_} from "svelte-i18n";
  import Spinner from "../Spinners/SpinnerComponent.svelte";
  import MessagePreview from "../MessagePreview.svelte";
  import {chosenChannel} from "../../helpers/store";
  import {WabaTemplatesComponentTransformer} from "../../helpers/WabaTemplatesComponentTransformer";
  import ModalScheduled from "../../pages/Inbox/Components/MessageChat/elements/Modal/ModalScheduled.svelte";

  /**
   * @type {BroadcastData}
   */
  export let data;
  export let show;

  /**
   * @type {WabaTemplate}
   */
  export let template;
  export let onClose;
  export let send;

  export let sendScheduled;
  let ModalScheduledIsOpen = false;

  let ready = true;

  let fieldsToFillIn = {};
  let fieldsTypes = {};

  let fieldsAreFilled = false;

  $:template && calculateFields();
  $:data && calculateFields();
  $:fieldsToFillIn && areAllFieldsFilledIn();

  function calculateFields() {
    if (template?.components) {
      const {
        newFields,
        newFieldsTypes
      } = new WabaTemplatesComponentTransformer(template, data?.template_data).getComponentsVariablesMeta();
      for (let newFieldsKey in newFields) {
        if (data.template_data?.[newFieldsKey]) {
          if (newFieldsKey === 'carousel') {
            for (let i = 0; i < data.template_data[newFieldsKey].length; i++) {
              const element = data.template_data[newFieldsKey][i];
              if (newFields[newFieldsKey][i]) {
                for (let cardKey in newFields[newFieldsKey][i]) {
                  newFields[newFieldsKey][i][cardKey] = element[cardKey];
                }
              }
            }
          } else {
            newFields[newFieldsKey] = data.template_data[newFieldsKey];
          }
        }
      }
      fieldsToFillIn = newFields;
      fieldsTypes = newFieldsTypes;
      //если заполнять нечего, делаем автовыбор
      if (!sendScheduled && show && newFields && Object.keys(newFields).length === 0) {
        save();
      }
    } else {
      fieldsToFillIn = {};
      fieldsTypes = {};
    }
  }

  function areAllFieldsFilledIn() {
    let res = true;
    if (fieldsToFillIn) {
      for (let fieldsToFillInKey in fieldsToFillIn) {
        if (!fieldsToFillIn[fieldsToFillInKey]) {
          res = false;
          break;
        }
      }
      for (let i = 0; i < fieldsToFillIn?.carousel?.length; i++) {
        const element = fieldsToFillIn.carousel[i];
        for (let fieldsToFillInCarouselKey in element) {
          if (!element[fieldsToFillInCarouselKey]) {
            res = false;
            break;
          }
        }
      }
    }
    fieldsAreFilled = res;
  }

  function save() {
    show = false;
    data.waba_template = template;
    data.waba_template_id = template.id;
    data.template_data = fieldsToFillIn;
    send && send();
  }

  function saveScheduled(startsAt) {
    data.waba_template = template;
    data.waba_template_id = template.id;
    data.template_data = fieldsToFillIn;
    sendScheduled(startsAt);
  }

  function generateRandomCode(length) {
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let code = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters[randomIndex];
    }

    return code;
  }

  function generateCode() {
    const code = generateRandomCode(6);
    fieldsToFillIn.body_1 = fieldsToFillIn.button_url_0 = code;
  }
</script>

{#if show}
  <Modal isOpen={show} fade={true} toggle={onClose} size="lg">
    <ModalBody>
      <h4 class="font-size-18 fw-600 mb-3">
        {$_("waba.template.add")}
        {#if (template.name)}&nbsp;"{template.name}"{/if}
      </h4>
      {#if ready}
        <Row>
          <Col md="6" xs="12" sm="12">
            {#if template.category === "AUTHENTICATION"}
              <Button color="success" class="py-1 mb-4" on:click={generateCode}>
                <span class="font-size-14">
                  {$_("waba.generate_code")}
                </span>
              </Button>
            {/if}
            <form id="form_add_template_data" on:submit|preventDefault={(e) => {
              if (e.submitter.id === 'send_save') {
                save();
              } else if (e.submitter.id === 'schedule') {
                ModalScheduledIsOpen = true; show = false;
              }
            }}>
              {#each Object.entries(fieldsToFillIn) as [key]}
                {#if fieldsTypes[key] === "HEADER"}
                  <div class="mb-4">
                    <Label class="fw-600">Header*</Label>
                    {#if key.includes("header_")}
                      <input placeholder={key} class="form-control mb-2" name="header_example_{key}" bind:value={fieldsToFillIn[key]} type="text" required/>
                    {:else}
                      <input placeholder={key} class="form-control mb-2" name="header_example_{key}" bind:value={fieldsToFillIn[key]} type="url" required/>
                    {/if}
                  </div>
                {:else if fieldsTypes[key] === "BODY"}
                  <div class="mb-4">
                    <Label class="fw-600">Body*</Label>
                    <input placeholder={key} class="form-control mb-2" name="body_example_{key}" bind:value={fieldsToFillIn[key]} type="text" required/>
                  </div>
                {:else if fieldsTypes[key] === "BUTTONS"}
                  <Label class="fw-600">Button*</Label>
                  {#if template.category === "AUTHENTICATION" && key === "button_url_0"}
                    <input placeholder="button_code_0" class="form-control mb-2" bind:value={fieldsToFillIn[key]} required/>
                  {:else}
                    <input placeholder={key} class="form-control mb-2" bind:value={fieldsToFillIn[key]} required/>
                  {/if}
                {:else if key === "carousel"}
                  {#each fieldsToFillIn.carousel as card, i}
                    <div class="mb-4">
                      <Label class="fw-600">Card {i+1}</Label>
                      {#each Object.entries(card) as [key]}
                        {#if fieldsTypes.carousel[i][key] === "HEADER"}
                          <div class="mb-4">
                            <Label class="fw-600">Header*</Label>
                            <input placeholder={key} class="form-control mb-2" name="header_example_{i}_{key}" bind:value={card[key]} type="url" required/>
                          </div>
                        {:else if fieldsTypes.carousel[i][key] === "BODY"}
                          <div class="mb-4">
                            <Label class="fw-600">Body*</Label>
                            <input placeholder={key} class="form-control mb-2" name="body_example_{i}_{key}" bind:value={card[key]} type="text" required/>
                          </div>
                        {:else if fieldsTypes.carousel[i][key] === "BUTTONS"}
                          <Label class="fw-600">Button*</Label>
                          <input placeholder={key} class="form-control mb-2" name="button_example_{i}_{key}" bind:value={card[key]} type="text" required/>
                        {/if}
                      {/each}
                    </div>
                  {/each}
                {/if}
              {/each}
            </form>
          </Col>
          <Col md="6" class="d-none d-md-block">
            <div class="center pt-0">
              <MessagePreview data={fieldsToFillIn} {template} channel={$chosenChannel}/>
            </div>
          </Col>
        </Row>
      {:else}
        <Spinner/>
      {/if}
      <div class="mt-3">
        <Button color="success" class="py-2 px-4 font-size-14" disabled={!fieldsAreFilled} form="form_add_template_data" id="send_save">
          {send ? $_('send') : $_('save')}
        </Button>
        {#if sendScheduled}
          <Button color="success" outline class="px-3 font-size-14" disabled={!fieldsAreFilled} form="form_add_template_data" id="schedule">
            {$_('scheduled.schedule')}
          </Button>
        {/if}
        <Button outline color="danger" class="py-2 px-4 font-size-14" on:click={onClose}>
          {$_('cancel')}
        </Button>
      </div>
    </ModalBody>
  </Modal>
{/if}

{#if sendScheduled}
  <ModalScheduled send={saveScheduled} bind:isOpen={ModalScheduledIsOpen} callback={() => show = true}/>
{/if}