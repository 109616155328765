<script>
  import {Input, Label, FormGroup} from "sveltestrap";
  import SelectDropdown from "../../../../Components/SelectDropdown.svelte";

  export let values;

  function addContact() {
    values.contacts = [...values.contacts, {
      name: {
        formatted_name: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        suffix: "",
        prefix: ""
      },
      birthday: "",
      addresses: [
        {
          street: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          country_code: "",
          type: "HOME"
        }
      ],
      emails: [
        {
          email: "",
          type: "HOME"
        }
      ],
      org: {
        company: "",
        department: "",
        title: ""
      },
      phones: [
        {
          phone: "",
          type: "HOME",
          wa_id: ""
        }
      ],
      urls: [
        {
          url: "",
          type: "HOME"
        }
      ]
    }];
  }

  function deleteContact(i) {
    values.contacts = values.contacts.filter((item, index) => index !== i);
  }

  function addAdresse(i) {
    values.contacts[i].addresses = [...values.contacts[i].addresses, {
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      country_code: "",
      type: "HOME"
    }];
  }

  function deleteAdress(i, j) {
    values.contacts[i].addresses = values.contacts[i].addresses.filter((item, index) => index !== j);
  }

  function addEmails(i) {
    values.contacts[i].emails = [...values.contacts[i].emails, {
      email: "",
      type: "HOME"
    }];
  }

  function deleteEmails(i, j) {
    values.contacts[i].emails = values.contacts[i].emails.filter((item, index) => index !== j);
  }

  function addPhones(i) {
    values.contacts[i].phones = [...values.contacts[i].phones, {
      phone: "",
      type: "HOME",
      wa_id: ""
    }];
  }

  function deletePhones(i, j) {
    values.contacts[i].phones = values.contacts[i].phones.filter((item, index) => index !== j);
  }

  function addUrls(i) {
    values.contacts[i].urls = [...values.contacts[i].urls, {
      url: "",
      type: "HOME"
    }];
  }

  function deleteUrls(i, j) {
    values.contacts[i].urls = values.contacts[i].urls.filter((item, index) => index !== j);
  }
</script>

{#each values.contacts as contact, i}
  <div class="d-flex">
    <div class="w-100 me-3">
      <FormGroup>
        <Label class="fw-600">formatted_name</Label>
        <Input type="text" bind:value={contact.name.formatted_name}/>
      </FormGroup>
      <FormGroup>
        <Label class="fw-600">first_name</Label>
        <Input type="text" bind:value={contact.name.first_name}/>
      </FormGroup>
      <FormGroup>
        <Label class="fw-600">last_name</Label>
        <Input type="text" bind:value={contact.name.last_name}/>
      </FormGroup>
      <FormGroup>
        <Label class="fw-600">middle_name</Label>
        <Input type="text" bind:value={contact.name.middle_name}/>
      </FormGroup>
      <FormGroup>
        <Label class="fw-600">suffix</Label>
        <Input type="text" bind:value={contact.name.suffix}/>
      </FormGroup>
      <FormGroup>
        <Label class="fw-600">prefix</Label>
        <Input type="text" bind:value={contact.name.prefix}/>
      </FormGroup>
      <FormGroup>
        <Label class="fw-600">birthday</Label>
        <Input type="date" bind:value={contact.birthday} class="custom-input-calendar"/>
      </FormGroup>
      <FormGroup>
        {#each contact.addresses as addresse, j}
          <div class="d-flex">
            <div class="w-100 me-3">
              <FormGroup>
                <Label class="fw-600">street</Label>
                <Input type="text" bind:value={addresse.street}/>
              </FormGroup>
              <FormGroup>
                <Label class="fw-600">city</Label>
                <Input type="text" bind:value={addresse.city}/>
              </FormGroup>
              <FormGroup>
                <Label class="fw-600">state</Label>
                <Input type="text" bind:value={addresse.state}/>
              </FormGroup>
              <FormGroup>
                <Label class="fw-600">zip</Label>
                <Input type="text" bind:value={addresse.zip}/>
              </FormGroup>
              <FormGroup>
                <Label class="fw-600">country</Label>
                <Input type="text" bind:value={addresse.country}/>
              </FormGroup>
              <FormGroup>
                <Label class="fw-600">country_code</Label>
                <Input type="text" bind:value={addresse.country_code}/>
              </FormGroup>
              <FormGroup>
                <Label class="fw-600">type</Label>
                <SelectDropdown type={true} bind:selected={addresse.type} list={['HOME', 'WORK']}/>
              </FormGroup>
            </div>
            <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteAdress(i, j)}>-</button>
          </div>
          {#if contact.addresses.length !== j+1}
            <div class="mb-3 mt-2 line bg-success"/>
          {/if}
        {/each}
        <button type="button" class="btn addBtn rounded-circle" on:click={() => addAdresse(i)}>+</button>
      </FormGroup>
      <FormGroup>
        {#each contact.emails as emails, j}
          <div class="d-flex">
            <div class="w-100 me-3">
              <FormGroup>
                <Label class="fw-600">email</Label>
                <Input type="email" bind:value={emails.email}/>
              </FormGroup>
              <FormGroup>
                <Label class="fw-600">type</Label>
                <SelectDropdown type={true} bind:selected={emails.type} list={['HOME', 'WORK']}/>
              </FormGroup>
            </div>
            <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteEmails(i, j)}>-</button>
          </div>
          {#if contact.emails.length !== j+1}
            <div class="mb-3 mt-2 line bg-success"/>
          {/if}
        {/each}
        <button type="button" class="btn addBtn rounded-circle" on:click={() => addEmails(i)}>+</button>
      </FormGroup>
      <FormGroup>
        <Label class="fw-600">company</Label>
        <Input type="text" bind:value={contact.org.company}/>
      </FormGroup>
      <FormGroup>
        <Label class="fw-600">department</Label>
        <Input type="text" bind:value={contact.org.department}/>
      </FormGroup>
      <FormGroup>
        <Label class="fw-600">title</Label>
        <Input type="text" bind:value={contact.org.title}/>
      </FormGroup>
      <FormGroup>
        {#each contact.phones as phones, j}
          <div class="d-flex">
            <div class="w-100 me-3">
              <FormGroup>
                <Label class="fw-600">phone</Label>
                <Input type="text" bind:value={phones.phone}/>
              </FormGroup>
              <FormGroup>
                <Label class="fw-600">type</Label>
                <SelectDropdown type={true} bind:selected={phones.type} list={['HOME', 'WORK', 'CELL', 'MAIN', 'IPHONE']}/>
              </FormGroup>
              <FormGroup>
                <Label class="fw-600">wa_id</Label>
                <Input type="text" bind:value={phones.wa_id}/>
              </FormGroup>
            </div>
            <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deletePhones(i, j)}>-</button>
          </div>
          {#if contact.phones.length !== j+1}
            <div class="mb-3 mt-2 line bg-success"/>
          {/if}
        {/each}
        <button type="button" class="btn addBtn rounded-circle" on:click={() => addPhones(i)}>+</button>
      </FormGroup>
      <FormGroup>
        {#each contact.urls as urls, j}
          <div class="d-flex">
            <div class="w-100 me-3">
              <FormGroup>
                <Label class="fw-600">url</Label>
                <Input type="url" bind:value={urls.url}/>
              </FormGroup>
              <FormGroup>
                <Label class="fw-600">type</Label>
                <SelectDropdown type={true} bind:selected={urls.type} list={['HOME', 'WORK']}/>
              </FormGroup>
            </div>
            <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteUrls(i, j)}>-</button>
          </div>
          {#if contact.urls.length !== j+1}
            <div class="mb-3 mt-2 line bg-success"/>
          {/if}
        {/each}
        <button type="button" class="btn addBtn rounded-circle" on:click={() => addUrls(i)}>+</button>
      </FormGroup>
    </div>
    <button type="button" class="btn deleteBtn rounded-circle" on:click={() => deleteContact(i)}>-</button> 
  </div>
  {#if values.contacts.length !== i+1}
    <div class="mb-3 mt-2 line bg-success"/>
  {/if}
{/each}
<button type="button" class="btn addBtn rounded-circle" on:click={addContact}>+</button>

<style>
  .line {
    height: 2px;
  }

  .deleteBtn, .addBtn {
    color: #495057 !important;
    width: 40px;
    height: 40px;
    background-color: #EFF2F7;
    box-shadow: none;
  }

  .deleteBtn {
    line-height: 16px;
    font-size: 22px;
    margin-top: 28px;
  }

  .addBtn {
    line-height: 20px;
    font-size: 20px;
  }
</style>