<script>
  import {Modal, ModalBody} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";

  export let chosenChat;
  export let windowStore;

  let chosenChatLocal;
  let windowStoreLocal;
  let isOpen = false;
  let remainingTime = null;
  const msInHour = 36e5;

  function recountTime() {
    const windowStoreLocalElement = windowStoreLocal?.[chosenChatLocal?.id];
    if (windowStoreLocalElement !== undefined && windowStoreLocalElement !== null) {
      remainingTime = Math.floor((windowStoreLocalElement - Date.now()) / msInHour)
      if (remainingTime < 0) remainingTime = 0;
    } else {
      remainingTime = null
    }

  }

  const unsub3 = chosenChat.subscribe(async (value) => {
    chosenChatLocal = value
    recountTime();
  });

  const unsub2 = windowStore.subscribe(async (value) => {
    windowStoreLocal = value
    recountTime();
  });

  onDestroy(() => {
    unsub2();
    unsub3();
  });
</script>
{#if remainingTime !== null}
  <div class="pointer clock" on:click={() => isOpen=true}>{remainingTime}</div>
  {#if isOpen}
    <Modal isOpen={isOpen} fade={true} toggle={() => isOpen=false} size="md" centered>
      <ModalBody>
        <button type="button" class="btn-close float-end" aria-label="Close" on:click={() => isOpen=false}></button>
        {$_('chat_inbox.MessageChat.alertClock.message')}
      </ModalBody>
    </Modal>
  {/if}
{/if}

<style>
    .btn-close {
        margin-top: -10px;
        margin-right: -10px;
    }

  .clock {
    width: 28px;
    height: 28px;
    font-size: 8px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: #495057;
    background-image: url("/assets/images/icons/clock.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
</style>
