<script>
  import {_} from "svelte-i18n";
  import {
    MESSAGE_CONTENT_TYPE_TEXT,
    MESSAGE_CONTENT_TYPE_IMAGE, MESSAGE_CONTENT_TYPE_CONTACT,
    MESSAGE_CONTENT_TYPE_VOICE, MESSAGE_CONTENT_TYPE_FILE,
    MESSAGE_CONTENT_TYPE_VIDEO, MESSAGE_CONTENT_TYPE_LOCATION,
    MESSAGE_CONTENT_TYPE_PRODUCT, MESSAGE_CONTENT_TYPE_ORDER,
    MESSAGE_CONTENT_TYPE_CATALOG
  } from '../../../../../../helpers/constants';
  import {AudioPlayer} from 'svelte-audio-player';
  import Voice from "./Voice.svelte";
  import ModalOrder from "../Modal/ModalOrder.svelte";

  export let message;
  export let direction;
  export let viewModal;

  let LatLng = {lat: 0, lng: 0};

  let businessId = '';
  let catalogId = '';
  let productItems = '';
  let preliminaryPrice = '';
  let isOpenOrder = false;

  function parseLocation() {
    const jsonObject = typeof message.reply_text === 'string' ? JSON.parse(message.reply_text) : message.reply_text;
    let {body, name} = jsonObject;
    body = body.split(';');
    LatLng = {lat: Number(body[0]), lng: Number(body[1])};
    return name;
  }

  function parseContact() {
    const jsonObject = typeof message.reply_text === 'string' ? JSON.parse(message.reply_text) : message.reply_text;
    return jsonObject.contacts;
  }

  function parseOrder() {
    const jsonObject = typeof message.reply_text === 'string' ? JSON.parse(message.reply_text) : message.reply_text;
    businessId = jsonObject?.meta?.business_id;
    catalogId = jsonObject?.meta?.catalog_id;
    productItems = jsonObject?.meta?.product_items;
    preliminaryPrice = productItems?.reduce((acc, cur) => acc + cur.item_price, 0);
    return productItems;
  }

  function parseProduct() {
    const jsonObject = typeof message.reply_text === 'string' ? JSON.parse(message.reply_text) : message.reply_text;
    const product = jsonObject?.meta?.product;
    const sections = jsonObject?.sections;
    const text = jsonObject?.body;
    if (product) {
      return 'Product:' + product?.product_retailer_id;
    } else if (sections || text) {
      return text;
    }
  }
</script>

<div class="Reply pb-1 {direction ? 'pt-1' : ''}">
  <div class="border-start border-2 border-success ps-2 d-flex align-items-center gap-2 w-100 {!direction ? 'flex-row-reverse' : ''}">
    {#if message.reply_type === MESSAGE_CONTENT_TYPE_IMAGE || message.reply_image_url}
      <div class="rounded-2 Media d-flex align-items-center justify-content-center pointer bg-primary bg-soft"
        on:click={() => viewModal('image' , message.reply_image_url, message.reply_text)}>
        <img src={message.reply_image_url} alt="photo" class="w-100 h-100 object-fit-cover"/>
      </div>
    {:else if message.reply_type === MESSAGE_CONTENT_TYPE_VIDEO || message.reply_video_url}
      <div class="rounded-2 Media d-flex align-items-center justify-content-center pointer bg-primary bg-soft"
        on:click={() => viewModal('video' , message.reply_video_url)}>
        <video class="w-100 h-100 object-fit-cover">
          <source src={message.reply_video_url}/>
        </video>
      </div>
    {:else if message.reply_type === MESSAGE_CONTENT_TYPE_FILE || message.reply_type !== MESSAGE_CONTENT_TYPE_VOICE && message.reply_file_url}
      <a href={message.reply_file_url} target="_blank" rel="noopener noreferrer" download class="rounded-2 Media d-flex align-items-center justify-content-center">
        <i class="bx bx-file font-size-25"/>
      </a>
    {:else if message.reply_type === MESSAGE_CONTENT_TYPE_LOCATION}
      <a href="https://maps.google.com/maps?q={LatLng.lat}%2C{LatLng.lng}&z=17" rel="noopener noreferrer" target="_blank"
        class="rounded-2 Media d-flex align-items-center justify-content-center">
        <i class="bx bxs-map font-size-25"/>
      </a>
    {:else if message.reply_type === MESSAGE_CONTENT_TYPE_ORDER}
      <div class="rounded-2 Media d-flex align-items-center justify-content-center pointer" on:click={() => {if (productItems?.length) isOpenOrder=true}}>
        <svg width="23" height="23" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1259_14593)">
            <path fill="#495057"
              d="M4.95825 12.75C4.17909 12.75 3.54867 13.3875 3.54867 14.1667C3.54867 14.9458 4.17909 15.5833 4.95825 15.5833C5.73742 15.5833 6.37492 14.9458 6.37492 14.1667C6.37492 13.3875 5.73742 12.75 4.95825 12.75ZM0.708252 1.41666V2.83332H2.12492L4.67492 8.20957L3.71867 9.94499C3.60534 10.1433 3.54159 10.3771 3.54159 10.625C3.54159 11.4042 4.17909 12.0417 4.95825 12.0417H13.4583V10.625H5.25575C5.15659 10.625 5.07867 10.5471 5.07867 10.4479L5.09992 10.3629L5.73742 9.20832H11.0145C11.5458 9.20832 12.0133 8.91791 12.2541 8.47874L14.7899 3.88166C14.8466 3.78249 14.8749 3.66207 14.8749 3.54166C14.8749 3.15207 14.5562 2.83332 14.1666 2.83332H3.69034L3.0245 1.41666H0.708252ZM12.0416 12.75C11.2624 12.75 10.632 13.3875 10.632 14.1667C10.632 14.9458 11.2624 15.5833 12.0416 15.5833C12.8208 15.5833 13.4583 14.9458 13.4583 14.1667C13.4583 13.3875 12.8208 12.75 12.0416 12.75Z"/>
          </g>
          <defs>
            <clipPath id="clip0_1259_14593">
              <rect width="17" height="17" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    {/if}
    <div class="w-100 overflow-hidden">
      <div class="text-success fw-600 text-truncate" title={message.reply_name === 'Your Bot' ? $_('chat_inbox.MessageChat.your_bot') : message.reply_name}>
        {message.reply_name === 'Your Bot' ? $_('chat_inbox.MessageChat.your_bot') : message.reply_name}
      </div>
      {#if message.reply_type === MESSAGE_CONTENT_TYPE_VOICE && message.reply_file_url}
        <AudioPlayer src={message.reply_file_url}>
          <Voice {direction} typeReply={true}/>
        </AudioPlayer>
      {:else}
        <div class="text-muted Text" title={
          message.reply_type === MESSAGE_CONTENT_TYPE_LOCATION ? parseLocation() ?? '' :
          message.reply_type === MESSAGE_CONTENT_TYPE_TEXT
          || message.reply_type === MESSAGE_CONTENT_TYPE_IMAGE
          || message.reply_type === MESSAGE_CONTENT_TYPE_VIDEO
          || message.reply_type === MESSAGE_CONTENT_TYPE_CATALOG ? message.reply_text : ''
        }>
          {#if message.reply_type === MESSAGE_CONTENT_TYPE_LOCATION}
            {parseLocation() ?? $_('broadcast.location')}
          {:else if message.reply_type === MESSAGE_CONTENT_TYPE_CONTACT}
            {#if parseContact().length <= 1}
              {parseContact()[0].name.formatted_name}
            {:else}
              {parseContact().length} {$_('broadcast.сontact')}
            {/if}
          {:else if message.reply_type === MESSAGE_CONTENT_TYPE_ORDER}
            {parseOrder()?.length} product
            {#if preliminaryPrice}
              {preliminaryPrice} {productItems[0]?.currency}
            {/if}
          {:else if message.reply_type === MESSAGE_CONTENT_TYPE_PRODUCT}
            {parseProduct()}
          {:else if message.reply_text}
            {message.reply_text}
          {:else}
            {message.reply_type === MESSAGE_CONTENT_TYPE_IMAGE ? $_('broadcast.image') : ''}
            {message.reply_type === MESSAGE_CONTENT_TYPE_VIDEO ? $_('broadcast.video') : ''}
            {message.reply_type === MESSAGE_CONTENT_TYPE_FILE ? decodeURI(message.reply_file_url.split('/').pop()) : ''}
          {/if}
        </div>
      {/if}
    </div>
  </div>
</div>

<ModalOrder bind:isOpen={isOpenOrder} {productItems} {catalogId} {businessId}/>

<style>
  .Reply {
    padding: 8px 12px;
  }

  .Reply .Media {
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    overflow: hidden;
  }

  .Reply a.Media {
    color: #495057;
  }

  .Reply .Text {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 63px;
  }
</style>
