<script>
  import {Modal, ModalBody, ModalFooter, ModalHeader, Button} from "sveltestrap";
  import {onMount, getContext, onDestroy} from "svelte";
  import {_, date} from "svelte-i18n";
  import {defaultConst} from "../../common/constants";
  import {addToast} from "../../helpers/toast";
  import {postRequest} from "../../helpers/utils";

  import {Link} from "svelte-routing";

  import Spinner from "../Spinners/SpinnerComponent.svelte";


  export let show;
  export let onCloseClick;
  export let profileType = 'subscription';
  export let activeUser = {};

  let loading = false;


  let name = "New payment profile";

  const selectPaymentSystems = [
    {
      value: 'stripe',
      name: 'Stripe'
    }, {
      value: 'yookassa',
      name: 'Yookassa'
    }
  ]
  const availableCurrencies = [
    {
      value: 'USD',
      name: 'USD'
    }, {
      value: 'RUB',
      name: 'RUB'
    }
  ]
  let paymentSystemSelect = selectPaymentSystems[0]['value'];
  let currency = availableCurrencies[0]['value'];
  onMount(() => {

  });
  onDestroy(() => {

  })

  const addProfile = async () => {

    loading = true;

    let params = {
      name: name,
      type: profileType,
      currency: currency,
      paymentSystem: paymentSystemSelect,
      userToken: activeUser.userToken,
    };
    let result = await postRequest(
      `${defaultConst.functionsUrl}/createPaymentProfile`,
      params
    );

    loading = false;
    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong")
      });
    } else {
      onCloseClick();
      show = false;
      addToast({title: $_("saved")});
    }
  };
</script>

<div class="add-event-modal">
  <Modal
      isOpen={show}
      fade={true}
      toggle={onCloseClick}
      header={profileType==="subscription"?$_("paymentProfile.add_subscription"):$_("paymentProfile.add_balance")}
      class="modal-add" scrollable centered
  >
    <ModalBody>
      {#if loading}
        <Spinner/>
      {:else}
        <div class="form-group horizontal">
          <label for="name">Название asd подписки:</label>
          <input type="text" bind:value={name} id="name" class="form-control">
        </div>
        <div class="form-group horizontal">
          <label for="currency">Валюта:</label>
          <select type="select" name="currency" id="currency" bind:value={currency}>
            {#each availableCurrencies as availableCurrency}
              <option value="{availableCurrency.value}">{availableCurrency.name}</option>
            {/each}
          </select>
        </div>
        {#if profileType === 'subscription'}
          <div class="subscription-wrapper">
            Перейти куда то там чтобы настроить что-то там
            <Button color="primary" size="lg" block on:click={addProfile}>Добавить</Button>
          </div>
        {/if}
        {#if profileType === 'balance'}
          <div class="balance-wrapper">
            <div class="d-flex justify-content-center">
              <div class="switch-modal">
                <div class="switch-btn">
                  <img src="/assets/images/icons/stripe-logo.png" alt="logo">
                </div>
                <div class="switch-btn active">
                  <img src="/assets/images/icons/logo-ukassa.png" alt="logo">
                </div>
              </div>
            </div>
            <div class="card-pay ">
              <div class="ukassa">
                <div class="form-group">
                  <label for="card">Номер карты</label>
                  <input type="text" id="card" placeholder="Введите номер карты" class="form-control">
                </div>

                <div class="d-flex align-items-center justify-content-between mt-3">
                  <div class="form-group validity">
                    <label for="">Срок действия</label>
                    <div class="d-flex align-items-center justify-content-between">
                      <input type="text" id="mm" placeholder="ММ" class="form-control input-small">
                      <span class="mx-2 font-size-16">/</span>
                      <input type="text" id="yy" placeholder="ГГ" class="form-control input-small">
                    </div>
                  </div>
                  <div class="form-group validity">
                    <label for="cvc">Код</label>
                    <input type="text" id="cvc" placeholder="CVC" class="form-control input-small">
                  </div>
                </div>

                <p class="info with-icon"><i class="bx bxs-info-circle"></i> <span>Заплатив здесь, <Link to="#!1">Вы разрешаете автосписания</Link></span>
                </p>
              </div>
              <Button color="primary" size="lg" block on:click={addProfile}>Добавить</Button>
              <p class="info text-center mb-0">Нажимая кнопку, Вы принимаете
                <Link to="#!1">условия сервиса</Link>
              </p>

            </div>

          </div>
        {/if}
      {/if}
    </ModalBody>


  </Modal>
</div>
