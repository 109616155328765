<script>
  import {Link,} from "svelte-routing";
  import {Col, Container, Row, Card, CardBody} from "sveltestrap";
  import SideBlock from "../../Components/SideBlock.svelte";
  import {_} from "svelte-i18n";
  import {user} from "../../helpers/store";
  import LayoutUnauth from "../../layoutes/LayoutUnauth.svelte";
  import Layout from "../../layoutes/Layout.svelte";
  import AcceptInviteOrRegisterForm from "./Сomponents/AcceptInviteOrRegisterForm.svelte";
  import {DEFAULT_LOGIN_PAGE} from "../../common/constants";


  const checkStorage = () => localStorage.getItem("authUser")
</script>
<div>
  {#if !$user?.id && !checkStorage()}
    <LayoutUnauth>
      <div>
        <Container fluid class="p-0">
          <Row class="g-0">
            <SideBlock text="auth"/>
            <Col xl={5}>
              <div class="account-pages">
                <div class="account-container">
                  <div class="logo-block">
                    <img src="/assets/images/logo-1msg-dark.svg" alt="logo" class="logo">
                  </div>
                  <div class="p-2">
                    <AcceptInviteOrRegisterForm></AcceptInviteOrRegisterForm>
                  </div>
                  <div class="mt-5 text-center">
                    <p>
                      <Link to={DEFAULT_LOGIN_PAGE} class="fw-medium text-success">
                        {$_("auth.sign_in_here")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </LayoutUnauth>
  {/if}
  {#if $user?.id || checkStorage()}
    <Layout>
      <div class="page-content mobile-app">
        <Col xl="6" lg="6">
          <Card class="channel-mini-card">
            <CardBody>
              <AcceptInviteOrRegisterForm></AcceptInviteOrRegisterForm>
            </CardBody>
          </Card>
        </Col>
      </div>
    </Layout>
  {/if}
</div>

